import {
  SET_CREATE_ORGANISATION_ERRORS,
  SET_CREATE_ORGANISATION_LOADING,
  SET_USER_ORGANISATION,
  SET_USER_ORGANISATION_PEOPLE,
  SET_USER_ORGANISATION_PEOPLE_PAGES,
  SET_USER_ORGANISATION_PEOPLE_PAGE,
  SET_USER_ORGANISATION_PEOPLE_PER_PAGE,
  SET_USER_ORGANISATION_PEOPLE_ADD,
  SET_ORGANISATION_PERSON_RESPONSIBLE,
  SET_ORGANISATION_DASHBOARD_COURSES,
  SET_ORGANISATION_DASHBOARD,
  SET_ORGANISATION_COURSE_COMPLETION,
  SET_ORGANISATION_COURSE_COMPLETION_USERS,
  SET_ORGANISATION_PROFILE_COURSES,
  SET_ORGANISATION_COURSES,
  SET_ORGANISATION_COURSES_PAGES,
  SET_ORGANISATION_COURSES_PAGE,
  SET_ORGANISATION_COURSES_PER_PAGE,
  SET_ORGANISATION_COURSES_BAR,
} from '../constants';

const initialDashboard = {
  courses: [],
};

const initialState = {
  profile: {},
  list: [],
  people: {
    items: [],
    page: 1,
    pages: 1,
    perPage: 10,
  },
  peopleToAdd: [],
  personResponsible: null,
  errors: {},
  loading: false,
  dashboard: initialDashboard,
  profileCourses: [],
  courseCompletion: {
    users: [],
    course: {},
  },
  courses: {
    courseTemplates: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    published: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    completed: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    drafts: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    submittedTemplates: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    pending: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    bar: {
      courseTemplates: 0,
      published: 0,
      completed: 0,
      drafts: 0,
      submittedTemplates: 0,
      pending: 0,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ORGANISATION:
      return { ...state, profile: action.payload };
    case SET_CREATE_ORGANISATION_LOADING:
      return { ...state, loading: action.payload };
    case SET_CREATE_ORGANISATION_ERRORS:
      return { ...state, errors: action.payload };
    case SET_USER_ORGANISATION_PEOPLE:
      return {
        ...state,
        people: {
          ...state.people,
          items: action.payload,
        },
      };
    case SET_USER_ORGANISATION_PEOPLE_PAGES:
      return {
        ...state,
        people: {
          ...state.people,
          pages: action.payload,
        },
      };
    case SET_USER_ORGANISATION_PEOPLE_PAGE:
      return {
        ...state,
        people: {
          ...state.people,
          page: action.payload,
        },
      };
    case SET_USER_ORGANISATION_PEOPLE_PER_PAGE:
      return {
        ...state,
        people: {
          ...state.people,
          perPage: action.payload,
        },
      };
    case SET_USER_ORGANISATION_PEOPLE_ADD:
      return { ...state, peopleToAdd: action.payload };
    case SET_ORGANISATION_PERSON_RESPONSIBLE:
      return { ...state, personResponsible: action.payload };
    case SET_ORGANISATION_DASHBOARD:
      return {
        ...state,
        dashboard: {
          ...initialDashboard,
          ...action.payload,
        },
      };
    case SET_ORGANISATION_DASHBOARD_COURSES:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          courses: action.payload,
        },
      };
    case SET_ORGANISATION_PROFILE_COURSES:
      return {
        ...state,
        profileCourses: action.payload,
      };
    case SET_ORGANISATION_COURSE_COMPLETION:
      return {
        ...state,
        courseCompletion: action.payload,
      };
    case SET_ORGANISATION_COURSE_COMPLETION_USERS:
      return {
        ...state,
        courseCompletion: {
          ...state.courseCompletion,
          users: action.payload,
        },
      };
    case SET_ORGANISATION_COURSES:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            items: action.payload,
          },
        },
      };
    case SET_ORGANISATION_COURSES_PAGES:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            pages: action.payload,
          },
        },
      };
    case SET_ORGANISATION_COURSES_PAGE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            page: action.payload,
          },
        },
      };
    case SET_ORGANISATION_COURSES_PER_PAGE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            perPage: action.payload,
          },
        },
      };
    case SET_ORGANISATION_COURSES_BAR:
      return {
        ...state,
        courses: {
          ...state.courses,
          bar: action.payload,
        },
      };
    default:
      return state;
  }
};
