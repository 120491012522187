import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  UserList,
  IconText,
  Map,
  HeaderBrand,
  Breadcrumbs,
  BoxList,
} from 'components';
import { loadListViewCreator, removeListViewCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import { getAvatar, globalPropTypes, newLineToBr, parseUrlTitle } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useAuthLock } from 'hooks';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.organisations',
    url: '/organisations',
  },
];

const View = ({ loadView, removeView, view, history }) => {
  const { t } = useTranslation();
  const { id, name } = useParams();
  const [renderAuthLock] = useAuthLock(history);

  const {
    title,
    description,
    phone,
    email,
    website,
    photo,
    place,
    lat,
    lng,
    organizationRoles,
    courses,
    coursesUpcoming,
    coursesRunning,
  } = view;

  useEffect(() => {
    loadView(id, history);

    return () => {
      removeView();
    };
  }, []);

  useEffect(() => {
    if (!name && view && view.title) {
      history.replace({ pathname: `/organisations/${id}/${parseUrlTitle(view.title)}` });
    }
  }, [view]);

  const renderContactDetails = () => (
    <Padding all="20" className="bg-gray-100">
      <Margin bottom="16">
        <Heading as="h3" size="h6">
          {t('page.singleOrganisation.contactUs')}
        </Heading>
      </Margin>

      {phone && (
        <Margin bottom="12">
          <a href={`tel:${phone}`}>{phone}</a>
        </Margin>
      )}
      {email && (
        <Margin bottom="12">
          <a href={`mailto:${email}`} className="link link--16 word-break">
            {email}
          </a>
        </Margin>
      )}
      {website && (
        <a href={website} className="link link--16 word-break">
          {website}
        </a>
      )}
    </Padding>
  );

  const renderPlace = () => {
    if (!place) return null;

    return (
      <Padding all="20">
        <IconText top icon="marker" text={place} />
      </Padding>
    );
  };

  const renderMap = () => {
    if (!lat || !lng) return null;

    return <Map lat={lat} lng={lng} />;
  };

  const renderUserList = (_name, users) => {
    if (!_name || !users || !users.length) return null;

    return (
      <Margin top="32">
        <UserList title={_name} users={users} />
      </Margin>
    );
  };

  return (
    <>
      <PageContent>
        {renderAuthLock()}
        <Container>
          <Breadcrumbs items={breadcrumbs} />
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom="24">
                <HeaderBrand thumbnail={getAvatar(photo, 'organisation')}>
                  <Heading as="h1" size="h1" firstLine>
                    {title}
                  </Heading>
                </HeaderBrand>
              </Margin>
              {description && (
                <Margin bottom={{ xs: 32, xl: 48 }}>
                  <Cms>
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
                  </Cms>
                </Margin>
              )}

              {coursesUpcoming && coursesUpcoming.length > 0 && (
                <Margin bottom={{ xs: 32, xl: 48 }}>
                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleOrganisation.coursesTitleUpcoming')}
                    </Heading>
                    <Link to="/training" className="link uppercase">
                      {t('global.seeAll')}
                    </Link>
                  </HeadingWrapper>
                  <BoxList
                    type="course"
                    items={coursesUpcoming}
                    alert={t('global.noResultsCourses')}
                  />
                </Margin>
              )}
              {coursesRunning && coursesRunning.length > 0 && (
                <Margin bottom={{ xs: 32, xl: 48 }}>
                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleOrganisation.coursesTitleRunning')}
                    </Heading>
                    <Link to="/training" className="link uppercase">
                      {t('global.seeAll')}
                    </Link>
                  </HeadingWrapper>
                  <BoxList
                    type="course"
                    items={coursesRunning}
                    alert={t('global.noResultsCourses')}
                  />
                </Margin>
              )}
              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.singleOrganisation.coursesTitle')}
                  </Heading>
                  <Link to="/training" className="link uppercase">
                    {t('global.seeAll')}
                  </Link>
                </HeadingWrapper>
                <BoxList type="course" items={courses} alert={t('global.noResultsCourses')} />
              </Margin>
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              <div className="border-xl">
                {renderContactDetails()}
                {renderPlace()}
                {renderMap()}
              </div>
              {organizationRoles && !!organizationRoles.length ? (
                <Margin top="32">
                  {organizationRoles.map((item) => (
                    <Margin top="32">
                      <UserList title={`${item.name}:`} users={item.items} />
                    </Margin>
                  ))}
                </Margin>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

View.propTypes = {
  loadView: PropTypes.func.isRequired,
  removeView: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  view: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    photo: PropTypes.string,
    place: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    organizationRoles: globalPropTypes.UserListPropsTypes,
    courses: PropTypes.arrayOf(PropTypes.shape({})),
    coursesUpcoming: PropTypes.arrayOf(PropTypes.shape({})),
    coursesRunning: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

View.defaultProps = {
  view: {
    description: '',
    phone: '',
    email: '',
    website: '',
    photo: '',
    place: '',
    lat: '',
    lng: '',
    courses: [],
    coursesUpcoming: [],
    coursesRunning: [],
  },
};

const mapStateToProps = ({
  list: {
    organisations: { view },
  },
}) => ({
  view,
});

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload, history) => dispatch(loadListViewCreator('organisations', payload, history)),
  removeView: () => dispatch(removeListViewCreator('organisations')),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
