import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH } from '..';

export const register = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'register'), {
    headers: null,
    body,
  });
};

export const validate = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'register', 'validate', 'first-step'), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};

export const resend = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'register', 'resend-activation-link'), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};
