import styled, { css } from 'styled-components';
import { pl } from 'styled-components-spacing/dist/cjs/Padding';

export const DropdownIndicator = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 2px 2px 0;
   
  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.colors.secondary};
    `}

  svg {
    width: 20px;
    height: 20px;

    ${({ theme }) =>
      theme &&
      css`
        fill: ${theme.colors.white};
      `}
  }
`;

export default styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0;

  cursor: pointer;

  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.colors.gray[100]};

      .accessibility &:focus,
      &:focus {
        outline: 1px solid ${theme.colors.secondary};
        outline-offset: -1px;
      }
    `}

  ${({ theme, disabled }) =>
    theme &&
    disabled &&
    css`
      background-color: ${theme.colors.gray[200]};
      cursor: not-allowed !important;

      ${DropdownIndicator} {
        background-color: ${theme.colors.secondaries.lighter};
      }
    `}  

  ${({ small }) =>
    small &&
    css`
      height: 40px;

      ${DropdownIndicator} {
        width: 40px;
        height: 40px;
      }
    `}
`;

export const InputValue = styled.p`
  ${pl(16)};

  flex: 1;
  display: flex;
  align-items: center;

  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('16')};
      font-weight: ${theme.font.weight.regular};
      color: ${theme.colors.gray[500]};
    `
  }
`;
