import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Cms } from 'components';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { ItemWrapper, TitleWrapper, ContentWrapper, Arrow } from './Faq.styled';

const Faq = ({ title, children }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const childrenMap = (child, index) => {
    return React.cloneElement(child, {
      ...child.props,
      active: index === activeIndex,
      setActiveIndex: () => setActiveIndex(index === activeIndex ? null : index),
    });
  };

  const childrens = React.Children.map(children, childrenMap);

  return (
    <div>
      <Margin bottom="24">
        <Heading as="h3" size="h5" noLine>
          {title}
        </Heading>
      </Margin>
      <div>{childrens}</div>
    </div>
  );
};

Faq.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

Faq.defaultProps = {
  title: '',
};

const Item = ({ active, setActiveIndex, children }) => {
  const childrenMap = (child) => {
    if (child.type.displayName === 'title') {
      return React.cloneElement(child, { setActiveIndex });
    }

    return React.cloneElement(child, null);
  };

  const childrens = React.Children.map(children, childrenMap);

  return <ItemWrapper active={active}>{childrens}</ItemWrapper>;
};

Item.propTypes = {
  active: PropTypes.bool,
  setActiveIndex: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

Item.defaultProps = {
  active: false,
  setActiveIndex: () => {},
};

const Title = ({ setActiveIndex, children }) => {
  return (
    <TitleWrapper onClick={setActiveIndex}>
      {children}
      <Arrow>
        <ReactSVG src={iconList.arrowDown} />
      </Arrow>
    </TitleWrapper>
  );
};

Title.displayName = 'title';

Title.propTypes = {
  setActiveIndex: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

Title.defaultProps = {
  setActiveIndex: () => {},
};

const Content = ({ children }) => {
  return (
    <ContentWrapper>
      <Cms>{children}</Cms>
    </ContentWrapper>
  );
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

Faq.Item = Item;
Faq.Title = Title;
Faq.Content = Content;

export default Faq;
