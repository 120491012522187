import styled, { css } from 'styled-components';
import { mt, py, pt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { mr } from 'styled-components-spacing/dist/cjs/Margin';

export default styled.footer`
  ${({ theme }) =>
    theme &&
    css`
      ${py(16)};
      ${pt(32)};
      
      height: 100%;
      text-align: center;
      color: ${theme.colors.white};
      background-color: ${theme.colors.tertiaries.darker};

      ${breakpoint('lg')`
        ${pt(64)};

        text-align: left;
      `}
    `}
`;

export const LogoLink = styled.a`
  width: 170px;
  height: 40px;
  display: block;

  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 126px;
  margin: 0 auto;

  ${mt(32)}

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;

    ${({ theme }) =>
      theme &&
      css`
        border-color: ${theme.colors.gray[500]};

        svg {
          width: 14px;
          height: 14px;
          fill: ${theme.colors.gray[500]};
        }
      `}
  }
`;

export const ContactInformations = styled.div`
  ${mt(32)}
  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('14')};
      line-height: 16px;
      p {
        ${mt(8)}
        svg {
          display: none;
          width: 14px;
          height: 18px;
          fill: ${theme.colors.secondaries.default};
          ${mr(8)}
        }
        strong {
          font-weight: ${theme.font.weight.bold};
        }
        ${breakpoint('lg')`
          display: flex;
          align-items: flex-start;
          svg { display: block; }
        `}
      }
      a {
        color: ${theme.colors.gray[500]};
      }
      ${breakpoint('lg')`
        ${mt(0)}
      `}
    `}
`;

export const Navigation = styled.div`
  ${mt(32)}
  ${({ theme }) =>
    theme &&
    css`
      ul {
        ${mt(20)}
      }

      li {
        ${mt(12)}
      }

      a {
        font-size: ${theme.font.size.pixel('14')};
        color: ${theme.colors.gray[500]};
      }

      ${breakpoint('lg')`
        ${mt(0)}
      `}
    `}
`;

export const Rights = styled.div`
  ${mt(32)}
  ${pt(20)}
  border-top: 1px solid #32394a;

  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('12')};
      line-height: 16px;
      color: ${theme.colors.gray[400]};
    `}
`;
