import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Brand from 'components/Navbar/Brand/Brand';
import List from 'components/Navbar/List/List';
import { IpmaShopButton, MyLibrary } from 'shared';
import { ButtonIcon } from 'components';
import { globalPropTypes, isLoading } from 'helpers';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { connectAzureCreator } from 'store/actionsCreators';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { Wrapper, Top, Middle, Bottom, Divider } from './Menu.styled';

const Menu = ({
  open,
  closeFn,
  globalRoutes,
  userRoutes,
  connectAzure,
  connectAzureLoading,
  openHelpCenter,
  openCisModal,
  appType,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper open={open}>
      <Top>
        <Brand as={Link} to="/" onClick={closeFn} />
      </Top>
      <Middle>
        <List items={globalRoutes} closeFn={closeFn} />
        <Divider />
        <List
          items={userRoutes.links}
          closeFn={closeFn}
          extra={(Item, ItemLink) => (
            <>
              {appType === 'user' ? (
                <>
                  {/* <Item>
                    <MyLibrary mobile />
                  </Item> */}
                  <Item>
                    <ItemLink
                      as="button"
                      type="button"
                      onClick={connectAzure}
                      disabled={connectAzureLoading}
                    >
                      {t('global.myWorkspacesButton')}
                    </ItemLink>
                  </Item>
                  <Item>
                    <ItemLink as={NavLink} to={{ pathname: '/auth/logout' }}>
                      {t('global.logOut')}
                    </ItemLink>
                  </Item>
                </>
              ) : null}
              {appType === 'ma' || appType === 'ipma' ? (
                <Item>
                  <ItemLink as="button" type="button" onClick={() => openCisModal('ma')}>
                    {t('global.cis')}
                  </ItemLink>
                </Item>
              ) : null}
              {appType === 'ma' || appType === 'ipma' || appType === 'yc' ? (
                <Item>
                  <ItemLink as="button" type="button" onClick={() => openHelpCenter('ma')}>
                    {t('global.helpCenterButton')}
                  </ItemLink>
                </Item>
              ) : null}
            </>
          )}
        />
      </Middle>
      <Bottom>
        <ButtonIcon
          type="button"
          tertiary
          left="true"
          large="true"
          onClick={() => openHelpCenter('public')}
          style={{ letterSpacing: '0.7px' }}
        >
          {t('global.helpCenterButton')}
        </ButtonIcon>
        {appType !== 'guest' && <IpmaShopButton mobile />}
        {userRoutes.actions.map((action, index) => (
          <ButtonIcon
            key={index}
            as={Link}
            onClick={closeFn}
            to={action.path}
            icon={action.icon}
            color={action.color}
            bg={action.bg}
            left="true"
            large="true"
          >
            {action.title}
          </ButtonIcon>
        ))}
      </Bottom>
    </Wrapper>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
  closeFn: PropTypes.func.isRequired,
  globalRoutes: globalPropTypes.GlobalRoutesPropsTypes.isRequired,
  userRoutes: globalPropTypes.UserRoutesPropsTypes.isRequired,
  connectAzure: PropTypes.func.isRequired,
  connectAzureLoading: PropTypes.bool.isRequired,
  openHelpCenter: PropTypes.func.isRequired,
  openCisModal: PropTypes.func.isRequired,
  appType: PropTypes.string.isRequired,
};

Menu.defaultProps = {
  open: false,
};

const mapStateToProps = ({ loading }) => ({
  connectAzureLoading: isLoading(loading, 'CONNECT_AZURE'),
});

const mapDispatchToProps = (dispatch) => ({
  connectAzure: () => dispatch(connectAzureCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
