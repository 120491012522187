import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Header,
  PageContent,
  Container,
  Alerts,
  FiltersControls,
  Table,
  Pagination,
  Spinner,
  ButtonAction,
  Alert,
} from 'components';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import {
  loadIpmaAwardsListCreator,
  setIpmaAwardsListPageCreator,
  setIpmaAwardsListPerPageCreator,
} from 'store/actionsCreators';
import { checkPermissions, isLoading, globalPropTypes } from 'helpers';
import { Float } from './Awards.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.awards',
    url: '/ipma/awards',
  },
];

const Awards = ({
  items,
  page,
  pages,
  perPage,
  loading,
  loadAwards,
  setPage,
  setPerPage,
  userPermissions,
  history,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadAwards();
  }, [perPage, page, loadAwards]);

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ipma.awards.title')} />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="ipma" />
          </Margin>
          <Margin>
            {checkPermissions(
              {
                roles: ['ipma_award_issue_badge_award_list'],
              },
              userPermissions,
            ) ? (
              <>
                <FiltersControls
                  perPageSelected={perPage}
                  perPageOnChange={({ value }) => setPerPage(value)}
                />
                <Table tablebreakpoint="lg">
                  <Table.Head>
                    <Table.Row>
                      <Table.Heading>{t('page.ipma.awards.awardName')}</Table.Heading>
                      <Table.Heading />
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={2}>
                          <Spinner />
                        </Table.Cell>
                      </Table.Row>
                    ) : null}
                    {!loading && !items.length ? (
                      <Table.Row>
                        <Table.Cell colSpan={2}>{t('global.noResultsFound')}</Table.Cell>
                      </Table.Row>
                    ) : null}
                    {!loading && items.length
                      ? items.map((award) => (
                          <Table.Row key={award.name}>
                            <Table.Cell>
                              <Table.Label>{t('page.ipma.awards.awardName')}:</Table.Label>
                              {award.name}
                            </Table.Cell>
                            <Table.Cell>
                              <Float>
                                <ButtonAction
                                  onClick={() =>
                                    history.push({
                                      pathname: `/ipma/award/${award.id}/issue-badge`,
                                      state: { awardName: award.name },
                                    })
                                  }
                                  title={t('global.issueBadges')}
                                  icon="award"
                                />
                              </Float>
                            </Table.Cell>
                          </Table.Row>
                        ))
                      : null}
                  </Table.Body>
                </Table>
                <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
              </>
            ) : (
              <Alert type="info">{t('page.forbidden.subtitle')}</Alert>
            )}
          </Margin>
        </Container>
      </PageContent>
    </>
  );
};

Awards.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  loadAwards: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

const mapStateToProps = ({ ipma, loading, user }) => ({
  ...ipma.awards.list,
  loading: isLoading(loading, 'IPMA'),
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadAwards: () => dispatch(loadIpmaAwardsListCreator()),
  setPage: (payload) => dispatch(setIpmaAwardsListPageCreator(payload)),
  setPerPage: (payload) => dispatch(setIpmaAwardsListPerPageCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Awards);
