import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { mb, mr, my, pl } from 'styled-components-spacing';

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      line-height: 1.4;
      
      ${breakpoint('xl')`
        ${css`
          line-height: 1.6;
        `}
      `}
      
      * {
        word-break: break-word;
      }
      
      p {
        margin-bottom: 8px;
      }
      
      a {
        color: #949799;
        font-weight: ${theme.font.weight.bold};
      }
      
      .text-18 {
        font-size: ${theme.font.size.pixel('18')};
      }
      
      ul {
        > li {
          ${pl(16)};
          ${mb(4)};

          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 6px;
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 2px;
            background-color: ${theme.colors.secondary};
          }
          
          ${breakpoint('xl')`
            ${css`
              ${pl(20)};
          
              &::before {
                width: 12px;
                height: 12px;
              }
            `}
          `}
        }
      }
      
      ol {
        counter-reset: item;

        ${breakpoint('xl')`
          ${css`
            list-style-position: outside;
          `}
        `}

        > li {
          ${mb(4)};

          display: block;
          list-style-type: none;
          counter-increment: item;
          position: relative;
          
          &::before {
            ${mr(4)};
  
            display: inline-block;
            font-weight: ${theme.font.weight.bold};
            text-align: right;
            content: counter(item) ".";
          }

          ${breakpoint('xl')`
            ${css`
              ${pl(24)};

              &::before {
                position: absolute;
                top: 0;
                transform: translate3d(calc(-100% - 8px), 0, 0);
                content: counter(item);
              }
            `}
          `}
        }
      }
    `
  }
`;

export const GroupWrapper = styled.div`
  ${my(24)};

  ${breakpoint('xl')`
    ${css`
      ${my(32)};
    `}
  `}
`;

export const GroupHeading = styled.div`
  ${mb(16)};
  position: relative;
`;

export const GroupNumber = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      display: none;

      ${breakpoint('xl')`
        ${css`
          display: inline-block;
          font-size: ${theme.font.size.pixel('36')};
          font-weight: ${theme.font.weight.bold};
          color: ${theme.colors.gray['600']};
          
          position: absolute;
          right: 100%;
          top: -12px;
          transform: translate3d(-16px, 0, 0);
        `}
      `}
    `
  }
`;

export const HeadingNumber = styled.span`
   display: inline-block;

   ${breakpoint('xl')`
    ${css`
      display: none;
    `}
  `}
`;