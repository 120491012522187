import React from 'react';
import { connect } from 'react-redux';
import { Margin } from 'styled-components-spacing';
import { Heading } from 'components';
import { InnerText } from './HowToFill.styled';

const HowToFill = () => {
  return (
    <Margin bottom="24">
      <Margin bottom="8">
        <Heading size="h4">How to fill the report</Heading>
      </Margin>
      <InnerText>
        Dear MA representative,
        <br /> <br />
        The report should be filled in with reliable data that your MA has approved and in an
        accurate manner. The report will be used for IPMA internal use only. The data will be the
        basis for invoicing the annual membership fee (based on the number of members you uploaded),
        and it will measure the activity and evolution of your MA over time. It will also help to
        calculate the number of votes you will have at the Council of Delegates meeting.
        <br />
        Number of corporate partners are the number of organisations MAs have agreements with.
        <br /> <br />
        The report should be filled by <b>January 31, 2024.</b>
        <br /> <br />
        Thank you for your contribution.
      </InnerText>
    </Margin>
  );
};

HowToFill.defaultProps = {
  errors: {},
};

HowToFill.propTypes = {};

export default connect(null, null)(HowToFill);
