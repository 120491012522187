import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { resetPasswordCreator } from 'store/actionsCreators';
import clearErrors from 'helpers/clearErrors';

import { Margin } from 'styled-components-spacing';
import { Button, Col, Field, Heading, Password, Row } from 'components';
import { useTranslation } from 'react-i18next';

const Spacer = ({ children }) => <Margin top={{ xs: 12, md: 0 }}>{children}</Margin>;

function ResetStep({ history, match, resetPassword }) {
  const { t } = useTranslation();
  const {
    params: { token },
  } = match;
  const [state, setState] = useState({ password: '', repassword: '' });
  const [errors, setErrors] = useState({});

  const handleChange = (name) => (e) => {
    setState({ ...state, [name]: e.target.value });
    setErrors(clearErrors(errors, name));
  };

  const submit = () => {
    resetPassword({ password: state }, token, (payload) => setErrors(payload), history);
  };

  return (
    <div>
      <Row halign="justify" valign="center">
        <Col size={{ xs: 3 / 4 }}>
          <Heading as="h2">{t('forgot.resetPasswordHeading')}</Heading>
          <Margin as="p" top={{ xs: 24 }} bottom={{ xs: 12, md: 24 }}>
            {t('forgot.resetPasswordDesc')}
          </Margin>
        </Col>
      </Row>
      <form>
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field icon="lock" error={errors.password}>
                <Password
                  placeholder={t('forms.newPasswordPlaceholder')}
                  value={state.password}
                  onChange={handleChange('password')}
                />
              </Field>
            </Spacer>
          </Col>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Spacer>
              <Field icon="lock" error={errors.repassword}>
                <Password
                  placeholder={t('forms.rePasswordPlaceholder')}
                  value={state.repassword}
                  onChange={handleChange('repassword')}
                />
              </Field>
            </Spacer>
          </Col>
        </Row>
        <Row>
          <Col size={{ xs: 1 / 1 }}>
            <Margin top={{ xs: 24 }}>
              <Button auto="md" primary type="button" onClick={submit}>
                {t('forgot.resetPassword')}
              </Button>
            </Margin>
          </Col>
        </Row>
      </form>
    </div>
  );
}

ResetStep.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ token: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (payload, token, setErrors, history) =>
    dispatch(resetPasswordCreator(payload, token, setErrors, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetStep);
