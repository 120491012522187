import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';

import boldIcon from '../../assets/icons/B.png';
import italicIcon from '../../assets/icons/I.png';
import underlineIcon from '../../assets/icons/U.png';
import leftIcon from '../../assets/icons/lft.png';
import centerIcon from '../../assets/icons/center.png';
import rightIcon from '../../assets/icons/right.png';

function Editor({ defaultValue, onChange, placeholder }) {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultValue))),
  );

  const handleBlur = () => {
    const contentState = editorState.getCurrentContent();
    onChange(convertToRaw(contentState));
  };

  return (
    <DraftEditor
      toolbar={{
        options: ['inline', 'fontSize', 'textAlign'],
        inline: {
          options: ['bold', 'italic', 'underline'],
          bold: { icon: boldIcon },
          italic: { icon: italicIcon },
          underline: { icon: underlineIcon },
        },
        textAlign: {
          options: ['left', 'center', 'right'],
          left: { icon: leftIcon },
          center: { icon: centerIcon },
          right: { icon: rightIcon },
        },
      }}
      defaultEditorState={editorState}
      onEditorStateChange={setEditorState}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
  );
}

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

Editor.defaultProps = {
  placeholder: '',
  defaultValue: '',
};

export default Editor;
