import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper, Item } from './Breadcrumbs.styled';

const Breadcrumbs = ({ items }) => {
  const { t } = useTranslation();
  if (!items.length) return null;
  return (
    <Wrapper>
      <Item>
        <Link to="/">{t('navigation.breadcrumbs.home')}</Link>
      </Item>
      {items.map((item, index) => {
        const title = item.param ? t(item.title, item.param) : t(item.title);
        return <Item key={index}>{item.url ? <Link to={item.url}>{title}</Link> : title}</Item>;
      })}
    </Wrapper>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Breadcrumbs;
