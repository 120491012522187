import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

const useExactRedirect = (routeMatch, to) => {
  const match = useRouteMatch(routeMatch);
  const redirect = match && match.isExact;

  const renderExactRedirect = () => (redirect && to ? <Redirect to={to} /> : null);

  return [renderExactRedirect];
};

export default useExactRedirect;
