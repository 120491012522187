import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import {
  Col,
  Container,
  Heading,
  HeadingWrapper,
  PageContent,
  Row,
  UserDetails,
  Chart,
  IconText,
  BoxList,
} from 'components';
import { Margin, Padding } from 'styled-components-spacing';
import { getAvatar } from 'helpers';
import { useTranslation } from 'react-i18next';
import { loadOrganisationDashboardCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';

const Dashboard = ({ loadDashboard, dashboard }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    loadDashboard(id);
  }, []);

  const {
    name,
    phone,
    email,
    website,
    photo,
    members,
    chartCourses,
    chartPeople,
    courses,
  } = dashboard;

  const renderCourses = () => (
    <>
      <HeadingWrapper>
        <Heading as="h2" size="h4">
          {t('courses.upcoming')}
        </Heading>
        <Link to="/training" className="link uppercase">
          {t('global.seeAll')}
        </Link>
      </HeadingWrapper>
      <BoxList type="course" items={courses} alert={t('global.noResultsCourses')} />
    </>
  );

  return (
    <PageContent>
      <Container>
        <UserDetails thumbnail={getAvatar(photo, 'organisation')}>
          {name && (
            <Margin bottom="16">
              <Heading as="h1" size="h4">
                {name}
              </Heading>
            </Margin>
          )}

          {phone && (
            <Margin bottom="12">
              <a href={`tel:${phone}`}>{phone}</a>
            </Margin>
          )}

          {email && (
            <Margin bottom="12">
              <a href={`mailto:${email}`} className="link link--16 word-break">
                {email}
              </a>
            </Margin>
          )}

          {website && (
            <Margin bottom="12">
              <a href={website} className="link link--16 word-break">
                {website}
              </a>
            </Margin>
          )}

          {members && (
            <Margin top="24">
              <strong className="text-tertiary">
                <IconText icon="users" text={`${members} ${t('global.members')}`} big />
              </strong>
            </Margin>
          )}
        </UserDetails>
      </Container>
      <Margin as={Container} top={{ xs: 32, md: 42 }}>
        <Row>
          <Col size={{ xl: 2 / 3 }}>{renderCourses()}</Col>

          <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
            {chartCourses && !!chartCourses.length && (
              <Margin bottom="24" className="border-xl">
                <Padding all={{ xl: 20 }}>
                  <Chart title={t('charts.courses')} items={chartCourses} color="#026d93" />
                </Padding>
              </Margin>
            )}
            {chartPeople && !!chartPeople.length && (
              <Margin bottom="24" className="border-xl">
                <Padding all={{ xl: 20 }}>
                  <Chart title={t('charts.people')} items={chartPeople} color="#026d93" />
                </Padding>
              </Margin>
            )}
          </Margin>
        </Row>
      </Margin>
    </PageContent>
  );
};

Dashboard.propTypes = {
  loadDashboard: PropTypes.func.isRequired,
  dashboard: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    photo: PropTypes.string,
    members: PropTypes.string,
    chartCourses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
    ),
    chartPeople: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
    ),
    courses: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Dashboard.defaultProps = {
  dashboard: {
    name: '',
    phone: '',
    email: '',
    website: '',
    photo: '',
    members: '0',
    chartCourses: [],
    chartPeople: [],
    courses: [],
  },
};

const mapStateToProps = ({ organisation: { dashboard } }) => ({
  dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  loadDashboard: (payload) => dispatch(loadOrganisationDashboardCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
