import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  display: inline-block;
  width: 98px;
  height: 22px;
  flex-shrink: 0;

  ${({ large }) => large && css`
    width: 160px;
    height: 40px;
  `}
`;