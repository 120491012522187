import React from 'react';
import { connect } from 'react-redux';
import { Row, Button, Col, Container, Header, PageContent, Alerts } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Margin } from 'styled-components-spacing';
import { checkPermissions, globalPropTypes } from 'helpers';
import { BoldText } from './RegOffice.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.office',
    url: '/ipma/reg-office-hub',
  },
];

const RegOffice = ({ userPermissions }) => {
  const { t } = useTranslation();

  const customButton = (to, content) => (
    <Button
      as={Link}
      to={to}
      style={{ width: 'auto', letterSpacing: '1.4px', padding: '18px 40px' }}
    >
      {content}
    </Button>
  );

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ipma.office.title')} />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="ipma" />
          </Margin>

          <Col>
            <Margin bottom={{ xs: 32 }}>
              <Margin bottom={{ xs: 12 }}>
                <Row>
                  <BoldText>
                    Welcome to <b>REG Office</b> section!
                  </BoldText>
                </Row>
              </Margin>
              <Row>
                <BoldText>
                  You can <b>manage courses, organisations and reports</b> from here.
                </BoldText>
              </Row>
            </Margin>

            <Row>
              {checkPermissions(
                {
                  roles: ['ipma_manage_course'],
                },
                userPermissions,
              ) ? (
                <>
                  <Margin>
                    {customButton(
                      '/ipma/reg-office/courses-programmes',
                      t('page.ipma.office.acceptCoursesProgrammes'),
                    )}
                  </Margin>
                  <Margin left={{ sm: 32 }} top={{ xs: 16, sm: 0 }}>
                    {customButton('/ipma/reg-office-table', t('page.ipma.officeTable.name'))}
                  </Margin>
                </>
              ) : null}
            </Row>
          </Col>
        </Container>
      </PageContent>
    </>
  );
};

RegOffice.propTypes = {
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

const mapStateToProps = ({ user }) => ({
  userPermissions: user.userPermissions,
});

export default connect(mapStateToProps, null)(RegOffice);
