import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Wrapper, Item, Content, Label } from './FiltersControlsYcProjects.styled';

const FiltersControlsYcProjects = ({
  perPageConfig,
  perPageSelected,
  perPageOnChange,
  sortByConfig,
  sortBySelected,
  sortByOnChange,
}) => {
  const { t } = useTranslation();
  if (!sortByOnChange && !perPageOnChange) return null;

  return (
    <Wrapper>
      {sortByOnChange && (
        <Item>
          <Label>{t('global.sortBy')}</Label>
          <Content>
            <Select
              small
              onChange={sortByOnChange}
              value={find(sortByConfig.options, { value: sortBySelected }) || null}
              options={sortByConfig.options}
              name={sortByConfig.name}
            />
          </Content>
        </Item>
      )}

      {perPageOnChange && (
        <Item>
          <Label>{t('global.perPage')}</Label>
          <Content short>
            <Select
              small
              onChange={perPageOnChange}
              value={find(perPageConfig.options, { value: perPageSelected }) || null}
              options={perPageConfig.options}
              name={perPageConfig.name}
            />
          </Content>
        </Item>
      )}
    </Wrapper>
  );
};

FiltersControlsYcProjects.propTypes = {
  perPageConfig: PropTypes.shape({}),
  perPageSelected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  perPageOnChange: PropTypes.func,
  sortByConfig: PropTypes.shape({}),
  sortBySelected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sortByOnChange: PropTypes.func,
};

FiltersControlsYcProjects.defaultProps = {
  perPageConfig: {
    name: 'perPage',
    options: [
      { label: 1, value: 1 },
      { label: 10, value: 10 },
      { label: 20, value: 20 },
      { label: 50, value: 50 },
      { label: 100, value: 100 },
    ],
  },
  perPageSelected: 10,
  perPageOnChange: null,
  sortByConfig: {
    name: 'sortBy',
    options: [
      { label: 'Start date: descending', value: 'dateFrom.desc' },
      { label: 'Start date: ascending', value: 'dateFrom.asc' },
      { label: 'End date: descending', value: 'dateTo.desc' },
      { label: 'End date: ascending', value: 'dateTo.asc' },
      { label: 'Name: descending', value: 'name.desc' },
      { label: 'Name: ascending', value: 'name.asc' },
    ],
  },
  sortBySelected: 'dateFrom.desc',
  sortByOnChange: null,
};

export default FiltersControlsYcProjects;
