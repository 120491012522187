import styled, { css } from 'styled-components';
import { py, px, mr, mb } from 'styled-components-spacing';

export const Wrapper = styled.div`
  margin-bottom: -8px;
`;
export const Tag = styled.p`
  ${({ theme }) => theme && css`
    ${py(8)};
    ${px(16)};
    ${mr(12)};
    ${mb(8)};

    display: inline-block;
    font-size: ${theme.font.size.pixel(14)};
    background-color: ${theme.colors.gray[100]};
    border-radius: 2px;
  `}
`;