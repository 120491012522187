import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components';
import { Wrapper, GroupWrapper, GroupHeading, GroupNumber, HeadingNumber } from './Cms.styled';

const Cms = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

Cms.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

const Group = ({ number, title, as, children }) => {
  return (
    <GroupWrapper>
      {title && (
        <GroupHeading>
          {number && <GroupNumber>{number}</GroupNumber>}
          <Heading as={as} size="h4" noLine>
            {number && <HeadingNumber>{number}.&nbsp;</HeadingNumber>}
            {title}
          </Heading>
        </GroupHeading>
      )}
      {children}
    </GroupWrapper>
  );
};

Group.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

Group.defaultProps = {
  number: null,
  title: null,
  as: 'h3',
};

Cms.Group = Group;

export default Cms;
