import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Table } from 'components';

export const FlexContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;

    ${breakpoint('lg')`
        ${css`
          flex-direction: row;
        `}
    `}
  `}
`;

export const PointsCircle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    aspect-ratio: 1/1;
    border: 25px solid ${theme.colors.secondary};
    border-radius: 50%;
    color: #424242;

    div:nth-child(2) {
      font-weight: 700;
      font-size: 40px;
    }
    div:nth-child(1),
    div:nth-child(3) {
      font-weight: 500;
      font-size: 20px;
    }

    ${breakpoint('sm')`
        ${css`
          width: 350px;
        `}
    `}
  `}
`;

export const SubTotals = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    height: min-content;
    align-self: center;
    justify-content: center;

    ${breakpoint('lg')`
        ${css`
          justify-content: initial;
        `}
    `}
  `}
`;

export const FullWidth = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${breakpoint('lg')`
    ${css`
      justify-content: initial;
    `}
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 65%;
      height: 10px;
      background-color: ${theme.colors.secondary};
      top: 50%;
      left: 17.5%;
      transform: translateY(-50%);
      border-radius: 15px;

      ${breakpoint('lg')`
        ${css`
          left: 8%;
        `}
      `}
    }
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    border-radius: 16px;
    box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.2);
    padding: 24px 20px 20px;
    position: relative;
    color: #424242;
    width: 90%;
    height: fit-content;
    letter-spacing: 0.5px;

    * {
      width: 100%;
      display: block;
      text-align: center;
    }

    div {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    span:nth-child(3) {
      font-size: 32px;
      font-weight: 400;
    }
    span:nth-child(2),
    span:nth-child(4) {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1px;
    }

    ${breakpoint('sm')`
        ${css`
          width: 40%;
        `}
    `}
  `}
`;

export const GroupByButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledRow = styled(Table.Row)`
  &:nth-of-type(even) {
    background-color: unset;
  }
  background-color: ${({ isOdd }) => (isOdd ? '#fff' : '#fbfbfb')} !important;

  > td {
    vertical-align: middle;
    font-size: 14px !important;
  }
  > td:first-child {
    font-size: 16px !important;
  }
`;

export const StyledCell = styled(Table.Cell)`
  font-weight: 500 !important;
  color: rgb(79, 78, 92) !important;
  text-align: left !important;

  ${breakpoint('lg')`
        ${css`
          text-align: center !important;
        `}
    `}
`;

export const NameCell = styled(StyledCell)`
  width: max-content !important;
  ${breakpoint('lg')`
        ${css`
          width: 500px !important;
          max-width: 500px !important;
          text-align: left !important;
        `}
    `}
`;

export const ActionButtonsFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
