import styled, { css, createGlobalStyle } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => theme && css`
    body  {
      padding-top: calc(${theme.navbar.mobileHeight} + ${theme.navbar.alertMobileHeight});

      ${breakpoint(theme.navbar.breakpoint)`
        ${css`
          padding-top: 0;
        `}
      `}
    }
  `}
`;

export const Wrapper = styled.nav`
  ${({ theme }) => theme && css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(${theme.navbar.mobileHeight} + ${theme.navbar.alertMobileHeight});
    background-color: #fff;
    box-shadow: 0 0 24px rgba(79, 78, 92, 0.32);
    z-index: ${theme.zindex.navbar};
  
    ${breakpoint(theme.navbar.breakpoint)`
      ${css`
        position: static;
        height: auto;
      `}
    `}
  `}
`;
