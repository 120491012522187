import React from 'react';
import PropTypes from 'prop-types';

import StyledAlert from './ResendAlert.styled';

function ResendAlert({ type, children }) {
  return (
    <StyledAlert resend type={type}>
      {children}
    </StyledAlert>
  );
}

ResendAlert.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success', 'empty']).isRequired,
  children: PropTypes.node.isRequired,
};

export default ResendAlert;
