import React, { useEffect, useReducer } from 'react';
import {
  PageContent,
  Container,
  Header,
  Alerts,
  Heading,
  Field,
  Row,
  Col,
  Select,
  Checkbox,
  Datepicker,
  Button,
  Spinner,
} from 'components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  loadOrganisationCoursePublishCreator,
  applyOrganisationCoursePublishCreator,
  loadGlobalCourseAttributesCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { globalPropTypes, isLoading } from 'helpers';
import { Margin } from 'styled-components-spacing';
import { find } from 'lodash';
import {
  initialState,
  reducer,
  setCourseCreator,
  setFieldsCreator,
  setFieldCreator,
  setErrorsCreator,
} from './reducer';

const Publish = ({
  loading,
  loadCourse,
  loadCourseAttributes,
  history,
  countries,
  timezones,
  submitForm,
}) => {
  const { id } = useParams();
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.organisation.courses',
      url: `/organisation/${organizationId}/courses`,
    },
    {
      title: 'navigation.breadcrumbs.organisation.publishCourse',
      url: '',
    },
  ];

  useEffect(() => {
    loadCourseAttributes();

    loadCourse(id, history, (course, fields) => {
      dispatch(setCourseCreator(course));
      dispatch(setFieldsCreator(fields));
    });
  }, []);

  const handleOnSubmit = (type) => {
    submitForm(
      { type, course: state.course, fields: state.fields, organizationId },
      history,
      (payload) => dispatch(setErrorsCreator(payload)),
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch(setFieldCreator({ name, value }));
  };

  const handleChangeCustom = (name) => (e) => {
    const value = e.value || e;
    dispatch(setFieldCreator({ name, value }));
  };

  const handleChangeCustomCheckbox = (name) => (e) => {
    dispatch(setFieldCreator({ name, value: e }));
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('courses.publishCourseTitle')}
        description={state.course.name}
      />
      <PageContent>
        <Container>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Alerts type="organisation" />

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="16">
                  <Heading size="h5">{t('forms.basicInformation')}</Heading>
                </Margin>

                <Row>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      label={t('forms.dateLabel')}
                      id="startDate"
                      required
                      value={state.fields.startDate}
                      error={state.errors.startDate}
                      clear={() => handleChangeCustom('startDate')('')}
                      disabled={state.fields.onDemand}
                    >
                      <Datepicker
                        disabled={state.fields.onDemand}
                        id="startDate"
                        name="startDate"
                        onChange={handleChangeCustom('startDate')}
                        placeholderText={t('forms.dateFromPlaceholder')}
                        selected={state.fields.startDate || null}
                        dateFormat="dd/MM/yyyy"
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="12" top={{ lg: 22 }} as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="endDate"
                      value={state.fields.endDate}
                      error={state.errors.endDate}
                      clear={() => handleChangeCustom('endDate')('')}
                      disabled={state.fields.onDemand}
                    >
                      <Datepicker
                        disabled={state.fields.onDemand}
                        id="endDate"
                        name="endDate"
                        onChange={handleChangeCustom('endDate')}
                        placeholderText={t('forms.dateToPlaceholder')}
                        selected={state.fields.endDate || null}
                        dateFormat="dd/MM/yyyy"
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="12" top={{ lg: 38 }} as={Col} size={{ lg: 1 / 4 }}>
                    <Field id="onDemand" error={state.errors.onDemand}>
                      <Checkbox
                        onChange={handleChangeCustomCheckbox('onDemand')}
                        selected={state.fields.onDemand}
                      >
                        {t('global.onDemand')}
                      </Checkbox>
                    </Field>
                  </Margin>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="timezone"
                      error={state.errors.timezone}
                      required
                      label={t('forms.timezoneLabel')}
                      value={find(timezones, { value: state.fields.timezone }) || null}
                      clear={() => handleChangeCustom('timezone')('')}
                    >
                      <Select
                        id="timezone"
                        name="timezone"
                        placeholder={t('forms.timezonePlaceholder')}
                        value={find(timezones, { value: state.fields.timezone }) || null}
                        onChange={handleChangeCustom('timezone')}
                        options={timezones}
                      />
                    </Field>
                  </Margin>
                </Row>
              </Margin>

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="16">
                  <Heading size="h5">{t('forms.contactData')}</Heading>
                </Margin>

                <Row>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="line1"
                      error={state.errors['address.line1']}
                      required
                      label={t('forms.addressLine1Label')}
                    >
                      <input
                        id="line1"
                        placeholder={t('forms.addressLinePlaceholder')}
                        name="line1"
                        defaultValue={state.fields.line1}
                        onBlur={handleChange}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="line2"
                      error={state.errors['address.line2']}
                      label={t('forms.addressLine2Label')}
                    >
                      <input
                        id="line2"
                        placeholder={`${t('forms.addressLinePlaceholder')} (${t(
                          'forms.addressSecondLinePlaceholder',
                        )})`}
                        name="line2"
                        defaultValue={state.fields.line2}
                        onBlur={handleChange}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="city"
                      error={state.errors['address.city']}
                      required
                      label={t('forms.cityLabel')}
                    >
                      <input
                        id="city"
                        placeholder={t('forms.cityPlaceholder')}
                        name="city"
                        defaultValue={state.fields.city}
                        onBlur={handleChange}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="postCode"
                      error={state.errors['address.postCode']}
                      required
                      label={t('forms.postCodeLabel')}
                    >
                      <input
                        id="postCode"
                        placeholder={t('forms.postCodePlaceholder')}
                        name="postCode"
                        defaultValue={state.fields.postCode}
                        onBlur={handleChange}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                </Row>

                <Row>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="country"
                      error={state.errors['address.country']}
                      required
                      label={t('forms.countryLabel')}
                      value={find(countries, { value: state.fields.country }) || null}
                      clear={() => handleChangeCustom('country')('')}
                      disabled={state.fields.doesntApply}
                    >
                      <Select
                        id="country"
                        name="country"
                        placeholder={t('forms.countryPlaceholder')}
                        value={find(countries, { value: state.fields.country }) || null}
                        onChange={handleChangeCustom('country')}
                        options={countries}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                </Row>

                <Row>
                  <Margin as={Col}>
                    <Field id="doesntApply" error={state.errors.doesntApply}>
                      <Checkbox
                        onChange={handleChangeCustomCheckbox('doesntApply')}
                        selected={state.fields.doesntApply}
                      >
                        {t('forms.doesntApply')}
                      </Checkbox>
                    </Field>
                  </Margin>
                </Row>
              </Margin>

              <Row>
                <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
                  <Margin bottom={{ xs: 12, md: 0 }}>
                    <Button type="button" onClick={() => handleOnSubmit('publish')}>
                      {t('courses.saveAndPublishCourse')}
                    </Button>
                  </Margin>
                </Col>
                <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
                  <Button type="button" onClick={() => handleOnSubmit('draft')} bordered>
                    {t('global.saveAsDraftButton')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </PageContent>
    </>
  );
};

Publish.propTypes = {
  loading: PropTypes.bool,
  countries: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes).isRequired,
  timezones: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes).isRequired,
  loadCourse: PropTypes.func.isRequired,
  loadCourseAttributes: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  submitForm: PropTypes.func.isRequired,
};

Publish.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ loading, globals }) => ({
  loading: isLoading(loading, 'APPLY_ORGANISATION_COURSE_PUBLISH'),
  countries: globals.countries,
  timezones: globals.timezones,
});

const mapDispatchToProps = (dispatch) => ({
  loadCourse: (payload, history, cb) =>
    dispatch(loadOrganisationCoursePublishCreator(payload, history, cb)),
  loadCourseAttributes: () => dispatch(loadGlobalCourseAttributesCreator()),
  submitForm: (payload, history, setErrors) =>
    dispatch(applyOrganisationCoursePublishCreator(payload, history, setErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Publish);
