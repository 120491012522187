import { takeLatest, call, put, select, cancelled } from 'redux-saga/effects';
import moment from 'moment';

import * as cpdService from 'api/services/cpd';
import {
  setNewLoadingCreator,
  removeLoadingCreator,
  setUserCpdListCreator,
  setNewAlertCreator,
  setCpdCategoriesCreator,
  setMaCpdRequestsBarCreator,
  setMaCpdRequestsCreator,
  setMaCpdRequestsStatusCreator,
  loadMaCpdRequestsBarCreator,
  loadMaCpdRequestsCreator,
  setCpdRolesCreator,
  setMaCpdBatchesCreator,
  setMaCpdBatchesListCreator,
  loadUserCpdListCreator,
  loadCpdCategoriesCreator,
} from 'store/actionsCreators';
import {
  LOAD_USER_CPD_LIST,
  USER_APPLY_FOR_CPD,
  LOAD_CPD_CATEGORIES,
  LOAD_MA_CPD_REQUESTS_BAR,
  LOAD_MA_CPD_REQUESTS,
  ACCEPT_CPD_REQUEST,
  REJECT_CPD_REQUEST,
  SEND_USER_CPD_FOR_VERIFICATION,
  LOAD_MA_CPD_BATCH_REQUESTS,
  LOAD_MA_CPD_BATCH_REQUESTS_LIST,
  SAVE_USER_BATCH_CPDS,
  EXPORT_USER_BATCH_CPD,
  EXPORT_USER_CPD_CSV,
} from 'store/constants';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import { parseBoxesList } from '../helpers';
import { LOAD_CPD_ROLES } from '../store/constants';

function* loadUserCpd() {
  try {
    yield put(setNewLoadingCreator({ type: 'CPD_LIST' }));
    const response = yield call(cpdService.getCpdList);
    if (response.ok) {
      const {
        results: {
          result: { data, points },
        },
      } = yield response.json();

      const pointsTypes = {
        total: points,
        formal: 0,
        nonFormal: 0,
        manual: 0,
        automatic: 0,
      };

      const buckets = {};

      if (data) {
        data.forEach((item) => {
          if (item.manualPoints) {
            if (item.addType === 'manual') {
              pointsTypes.manual += item.manualPoints;
            } else {
              pointsTypes.automatic += item.manualPoints;
            }

            if (
              item.category &&
              moment(item.dateOfAttendance) <= moment() &&
              moment(item.dateOfAttendance) >= moment().subtract(5, 'year')
            ) {
              if (item.category.isFormal) {
                pointsTypes.formal += item.manualPoints;
              } else {
                pointsTypes.nonFormal += item.manualPoints;
              }
            }
          }

          if (item.manualPoints && item.category) {
            buckets[item.category.name] = {
              isFormal: item.category.isFormal,
              label: item.category.label,
              list:
                buckets[item.category.name] && buckets[item.category.name].list
                  ? [...buckets[item.category.name].list, item]
                  : [item],
            };
          }
        });
      }

      yield put(setUserCpdListCreator({ pointsTypes, data, buckets }));
    }
    yield put(removeLoadingCreator({ type: 'CPD_LIST' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'CPD_LIST' }));
    console.log('[GET] user cpd list error: ', error);
  }
}

function* createUserCpdApplication({ payload, closeModal, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'CPD_APPLICATION' }));
    const response = yield call(cpdService.createUserCpdApplication, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'myCPD',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      closeModal();
    }
    yield put(removeLoadingCreator({ type: 'CPD_APPLICATION' }));
    yield put(loadUserCpdListCreator());
    yield put(loadCpdCategoriesCreator());
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'CPD_APPLICATION' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create project role application error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'CPD_APPLICATION' }));
  }
}

function* loadCpdCategories() {
  try {
    put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(cpdService.getCpdCategories);
    if (response.ok) {
      const {
        results: { result: categories },
      } = yield response.json();
      if (!isEmpty(categories) && !isEmpty(categories[0])) {
        const all = categories[0].map((item) => ({ ...item, value: item.id }));
        const formal = all.filter((item) => item.isFormal);
        const nonFormal = all.filter((item) => !item.isFormal);
        yield put(setCpdCategoriesCreator({ all, formal, nonFormal }));
      }
    }
  } catch (error) {
    console.log('[GET] cpd categories error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadMaCpdBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(cpdService.getCpdBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setMaCpdRequestsBarCreator({ ...bar }));
    }
  } catch (error) {
    console.log('[GET] ma events bar error: ', error);
  } finally {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadCpdPending({ payload }) {
  try {
    if (payload && payload.status) yield put(setMaCpdRequestsStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const { page, perPage, status } = yield select(({ cpd }) => cpd.certificationBody.list);
    const response = yield call(cpdService.getCpdPendingList, {
      page,
      maxResult: perPage,
      status,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      const items = parseBoxesList('cpd-pending', result.items);
      yield put(loadMaCpdRequestsBarCreator({ withoutLoader: true }));
      yield put(
        setMaCpdRequestsCreator({
          items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
  } catch (error) {
    console.log('[GET] ma people users error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'MA' }));
  }
}

function* acceptPendingCpd({ payload: { id, cpdNumber, comment, status } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(cpdService.acceptCpd, {
      pendingCpd: id,
      points: cpdNumber,
      comment,
    });
    if (response.ok) {
      yield put(loadMaCpdRequestsCreator({ status }));

      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ma accept course error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* rejectPendingCpd({ payload: { id, comment, status } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(cpdService.rejectCpd, {
      pendingCpd: id,
      comment,
    });
    if (response.ok) {
      yield put(loadMaCpdRequestsCreator({ status }));

      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ma accept course error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadCpdRoles() {
  try {
    put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(cpdService.cpdRoles);
    if (response.ok) {
      const {
        results: { result: roles },
      } = yield response.json();

      yield put(setCpdRolesCreator(roles));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] cpd roles error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* sendForVerification({ payload, closeModal, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'CPD_APPLICATION' }));

    const response = yield call(cpdService.sendCpdForVerification, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      closeModal();
    }
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ma accept course error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'CPD_APPLICATION' }));
  }
}

function* loadCpdBatchRequests() {
  try {
    yield put(setMaCpdBatchesListCreator({ items: [], requestInfo: {} }));
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const { page, perPage } = yield select(({ cpd }) => cpd.certificationBodyBatch.list);
    const response = yield call(cpdService.listBatchesByMa, {
      page,
      maxResult: perPage,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(
        setMaCpdBatchesCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
  } catch (error) {
    console.log('[GET] ma people users error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'MA' }));
  }
}

function* loadCpdBatchRequestList({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(cpdService.getUserBatchDetails, payload);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(setMaCpdBatchesListCreator(result));
    }
  } catch (error) {
    console.log('[GET] ma people users error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'MA' }));
  }
}

function* saveUserBatch({ payload: { history, id, items } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(cpdService.saveUserBatchCpds, id, items);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/ma/cpd-requests');
    }
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ma accept course error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* exportBatchCsv({ payload: { id, filename } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(cpdService.exportBatchCsv, id);
    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc applications zip error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* exportUserCsv({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(cpdService.exportUserCpdCsv, payload);
    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const filename = `cpd_export_${moment().format('DD/MM/YYYY')}.csv`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc applications zip error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

export default function* watchCpd() {
  yield takeLatest(LOAD_USER_CPD_LIST, loadUserCpd);
  yield takeLatest(USER_APPLY_FOR_CPD, createUserCpdApplication);
  yield takeLatest(LOAD_CPD_CATEGORIES, loadCpdCategories);
  yield takeLatest(LOAD_MA_CPD_REQUESTS_BAR, loadMaCpdBar);
  yield takeLatest(LOAD_MA_CPD_REQUESTS, loadCpdPending);
  yield takeLatest(ACCEPT_CPD_REQUEST, acceptPendingCpd);
  yield takeLatest(REJECT_CPD_REQUEST, rejectPendingCpd);
  yield takeLatest(LOAD_CPD_ROLES, loadCpdRoles);
  yield takeLatest(SEND_USER_CPD_FOR_VERIFICATION, sendForVerification);
  yield takeLatest(LOAD_MA_CPD_BATCH_REQUESTS, loadCpdBatchRequests);
  yield takeLatest(LOAD_MA_CPD_BATCH_REQUESTS_LIST, loadCpdBatchRequestList);
  yield takeLatest(SAVE_USER_BATCH_CPDS, saveUserBatch);
  yield takeLatest(EXPORT_USER_BATCH_CPD, exportBatchCsv);
  yield takeLatest(EXPORT_USER_CPD_CSV, exportUserCsv);
}
