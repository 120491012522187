import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => theme && css`
    display: flex;
    align-items: center;
    
    > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 14px;
      height: 14px;
      pointer-events: none;
      margin-right: 6px;
    }
    
    ${({ inline }) => inline && css `
      display: inline-block;
  
      div {
        display: inline-block;
      }
      
      svg {
        margin-right: 2px;
      }
    `}    
    
    ${({ top }) => top && css `
      align-items: flex-start;
    `}
    
    ${({ big }) => big && css `
      
      svg {
        width: 26px;
        height: 26px;
      }
    `}
  `}
`;