export const defaultState = {
  data: {
    name: '',
    nationalName: '',
    mainLanguage: null,
    secondaryLanguage: null,
    dateFrom: null,
    dateTo: null,
    timezone: null,
    price: '',
    currency: null,
    imageFile: null,
    modeOfDelivery: null,
    youngCrewEvent: null,
    address: {
      line1: '',
      line2: '',
      postCode: '',
      city: '',
      country: '',
    },
    phone: '',
    email: '',
    website: '',
    shortDescription: '',
    fullDescription: '',
    tags: [],
    eventCustomLinks: [{ label: '', url: '' }],
    ticketsLink: '',
    contactEmail: '',
    venueName: '',
    facebookLink: '',
    linkedinLink: '',
    twitterLink: '',
    instagramLink: '',
    youtubeLink: '',
    cpdRoles: [
      {
        role: null,
        points: null,
      },
    ],
  },
  errors: {},
};

const UPDATE_FIELD = 'UPDATE_FIELD';
const SET_ERRORS = 'SET_ERRORS';
const SET_INIT_DATA = 'SET_INIT_DATA';

export const updateFieldCreator = (payload) => ({ type: UPDATE_FIELD, payload });
export const setErrorsCreator = (payload) => ({ type: SET_ERRORS, payload });
export const setInitDataCreator = (payload) => ({ type: SET_INIT_DATA, payload });

export default function (state = defaultState, action) {
  if (action.type === UPDATE_FIELD) {
    return { ...state, data: { ...state.data, [action.payload.name]: action.payload.value } };
  }

  if (action.type === SET_ERRORS) {
    return { ...state, errors: action.payload };
  }

  if (action.type === SET_INIT_DATA) {
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload,
        address: { ...state.data.address, ...action.payload.address },
      },
    };
  }

  return state;
}
