import { get } from 'lodash';

export default (item) => {
  const userPrefix = item.user ? 'user.' : '';

  return {
    firstName: get(item, `${userPrefix}firstName`),
    lastName: get(item, `${userPrefix}lastName`),
    photo: item.photo ? item.photo : get(item, `${userPrefix}userProfile.photo`),
    uniqueUrl: get(item, `${userPrefix}uniqueUrl`),
  };
};
