import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  UserList,
  IconText,
  Map,
  HeaderBrand,
  Breadcrumbs,
  Alerts,
  BoxList,
} from 'components';
import { loadGlobalAboutIpmaCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import { getAvatar, newLineToBr } from 'helpers';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.about',
    url: '',
  },
];

const AboutIpma = ({ view, usersRoles, loadView }) => {
  const { t } = useTranslation();

  const { title, description, photo, events } = view;

  useEffect(() => {
    loadView();
  }, []);

  const renderContactDetails = () => (
    <Padding all="20" className="bg-gray-100">
      <Margin bottom="16">
        <Heading as="h3" size="h6">
          {t('page.aboutIpma.contact')}:
        </Heading>
      </Margin>

      {view.phone && (
        <Margin bottom="12">
          <a href={`tel:${view.phone}`}>{view.phone}</a>
        </Margin>
      )}
      {view.email && (
        <Margin bottom="12">
          <a href={`mailto:${view.email}`} className="link link--16 word-break">
            {view.email}
          </a>
        </Margin>
      )}
      {view.website && (
        <a href={view.website} className="link link--16 word-break">
          {view.website}
        </a>
      )}
    </Padding>
  );

  const renderPlace = () => {
    if (!view.place) return null;

    return (
      <Padding all="20">
        <IconText top icon="marker" text={view.place} />
      </Padding>
    );
  };

  const renderMap = () => {
    if (!view.lat || !view.lng) return null;

    return <Map lat={view.lat} lng={view.lng} />;
  };

  const renderContact = () => (
    <>
      {view && view && view.place && view.phone ? (
        <Margin bottom="32">
          <div className="border-xl">
            {renderContactDetails()}
            {renderPlace()}
            {renderMap()}
          </div>
        </Margin>
      ) : null}
    </>
  );

  return (
    <>
      <PageContent>
        <Container>
          <Breadcrumbs items={breadcrumbs} />
          <Alerts type="ma" />
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom="24">
                <HeaderBrand thumbnail={getAvatar(photo, 'ma')}>
                  <Heading as="h1" size="h1" firstLine>
                    {title}
                  </Heading>
                </HeaderBrand>
              </Margin>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                {description && (
                  <Cms>
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
                  </Cms>
                )}
              </Margin>

              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.singleMa.eventsTitle')}
                  </Heading>
                  <Link to="/events" className="link uppercase">
                    {t('global.seeAll')}
                  </Link>
                </HeadingWrapper>
                <BoxList type="event" items={events} alert={t('global.noResultsEvents')} />
              </Margin>
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              {renderContact()}

              {usersRoles && !!usersRoles.length ? (
                <Margin top="32">
                  {usersRoles.map((item) => (
                    <Margin top="32">
                      <UserList title={`${item.name}:`} users={item.items} />
                    </Margin>
                  ))}
                </Margin>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

AboutIpma.propTypes = {
  loadView: PropTypes.func.isRequired,
  view: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    photo: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.shape({})),
    place: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
  }),
  usersRoles: PropTypes.shape([]),
};

AboutIpma.defaultProps = {
  view: {
    title: '',
    description: '',
    shortDescription: '',
    phone: '',
    email: '',
    website: '',
    photo: '',
    place: '',
    lat: '',
    lng: '',
    usersRole: [],
    events: [],
  },
  usersRoles: [],
};

const mapStateToProps = ({ globals: { aboutIpma } }) => ({
  view: aboutIpma.ipma,
  usersRoles: aboutIpma.people,
});

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload) => dispatch(loadGlobalAboutIpmaCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutIpma);
