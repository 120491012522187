import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BoxList,
  FiltersControls,
  Pagination,
  Spinner,
  PrivateComponent,
  ButtonAction,
  Modal,
} from 'components';
import {
  loadIpmaProjectsCreator,
  setIpmaProjectsPageCreator,
  setIpmaProjectsPerPageCreator,
  removeIpmaProjectCreator,
} from 'store/actionsCreators';
import { isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Running = ({
  projects,
  page,
  pages,
  perPage,
  loading,
  loadProjects,
  setPage,
  setPerPage,
  deleteProject,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(null);

  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadProjects({ status: 'running' });
  }, [perPage, page, loadProjects]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="project"
            items={projects}
            alert={t('global.noResultsFound')}
            customRenderActions={({ item }) => (
              <>
                <PrivateComponent
                  permissions={{
                    roles: ['ipma_project_manage_project'],
                  }}
                >
                  <ButtonAction
                    icon="edit"
                    title={t('global.editButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => history.push(`/ipma/project/${item.id}/edit`)}
                  />
                </PrivateComponent>
                <PrivateComponent
                  permissions={{
                    roles: ['ipma_project_manage_project'],
                  }}
                >
                  <ButtonAction
                    icon="list"
                    title={t('page.yc.projects.manageRoles')}
                    verticalviewbreakpoint="xl"
                    onClick={() =>
                      history.push({
                        pathname: `/ipma/project-roles/${item.id}`,
                        state: { projectName: item.name },
                      })
                    }
                  />
                </PrivateComponent>
                <PrivateComponent
                  permissions={{
                    roles: ['ipma_project_manage_project'],
                  }}
                >
                  <ButtonAction
                    icon="closeCircle"
                    title={t('global.remove')}
                    verticalviewbreakpoint="xl"
                    onClick={() => setDeleteModal(item.id)}
                  />
                </PrivateComponent>
              </>
            )}
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </>
      ) : null}
      <Modal
        heading={t('modals.headings.areYouSure')}
        description={t('page.yc.projects.removeProjectDesc')}
        isOpen={deleteModal}
        confirm={() => {
          deleteProject(deleteModal);
          setDeleteModal(null);
        }}
        cancel={() => setDeleteModal(null)}
      />
    </>
  );
};

Running.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadProjects: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ipma, loading }) => ({
  projects: ipma.projects.list.items,
  page: ipma.projects.list.page,
  pages: ipma.projects.list.pages,
  perPage: ipma.projects.list.perPage,
  loading: isLoading(loading, 'IPMA'),
});
const mapDispatchToProps = (dispatch) => ({
  loadProjects: (payload) => dispatch(loadIpmaProjectsCreator(payload)),
  setPage: (payload) => dispatch(setIpmaProjectsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setIpmaProjectsPerPageCreator(payload)),
  deleteProject: (id) => dispatch(removeIpmaProjectCreator(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Running);
