import React from 'react';
import PropTypes from 'prop-types';
import { getAvatar, isValidSocialUrl } from 'helpers';
import { Margin } from 'styled-components-spacing';
import { iconList } from 'assets';
import { ReactSVG } from 'react-svg';
import { Row } from 'components';
import {
  Wrapper,
  Thumbnail,
  Content,
  SocialWrapper,
  InstagramButton,
  TwitterButton,
  FacebookButton,
  LinkedinButton,
  TikTokButton,
} from './UserOverview.styled';

const UserOverview = ({
  thumbnail,
  name,
  phone,
  twitter,
  facebook,
  instagram,
  linkedin,
  tiktok,
}) => {
  return (
    <Wrapper>
      <Thumbnail>
        <img src={getAvatar(thumbnail, 'profile')} alt="user avatar" />
      </Thumbnail>
      <Content>
        {name && <p className="font-weight-bold">{name}</p>}

        {phone && (
          <Margin top="8">
            <a href={`tel:${phone}`}>{phone}</a>
          </Margin>
        )}
        <Row>
          <SocialWrapper top="16">
            {twitter ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(twitter)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterButton>
                  <ReactSVG src={iconList.twitter} />
                </TwitterButton>
              </SocialWrapper>
            ) : null}
            {instagram ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(instagram)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramButton>
                  <ReactSVG src={iconList.instagram} />
                </InstagramButton>
              </SocialWrapper>
            ) : null}
            {facebook ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(facebook)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookButton>
                  <ReactSVG src={iconList.facebookSquare} />
                </FacebookButton>
              </SocialWrapper>
            ) : null}
            {linkedin ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(linkedin)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinButton>
                  <ReactSVG src={iconList.linkedin} />
                </LinkedinButton>
              </SocialWrapper>
            ) : null}
            {tiktok ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(tiktok)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TikTokButton>
                  <ReactSVG src={iconList.tiktokWhite} />
                </TikTokButton>
              </SocialWrapper>
            ) : null}
          </SocialWrapper>
        </Row>
      </Content>
    </Wrapper>
  );
};

UserOverview.propTypes = {
  thumbnail: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  linkedin: PropTypes.string,
  tiktok: PropTypes.string,
};

UserOverview.defaultProps = {
  thumbnail: '',
  name: '',
  phone: '',
  twitter: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  tiktok: '',
};

export default UserOverview;
