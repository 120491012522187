import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  UserList,
  IconText,
  Map,
  Breadcrumbs,
  ProfileHeader,
  BoxList,
  PrivateComponent,
} from 'components';
import noAvatar from 'assets/images/no_avatar.jpg';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { globalPropTypes, newLineToBr } from '../../../../helpers';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.profile',
    url: '/ma/profile/view',
  },
];

const View = ({ profile, usersRole, upcomingEvents }) => {
  const { t } = useTranslation();
  if (!profile) return null;

  const parseAddress = (profileAddress) => {
    let address = '';
    if (profileAddress.line1) {
      address += profileAddress.line1;
    }

    if (profileAddress.line2) {
      address += `, ${profileAddress.line2}`;
    }

    if (profileAddress.city) {
      address += `, ${profileAddress.city}`;
    }

    if (profileAddress.postCode) {
      address += `, ${profileAddress.postCode}`;
    }

    if (profileAddress.country && profileAddress.country.label) {
      address += `, ${profileAddress.country.label}`;
    }

    return address;
  };

  return (
    <>
      <PageContent>
        <Container>
          <Breadcrumbs items={breadcrumbs} />
          <Margin bottom="24">
            <ProfileHeader
              title={profile.name}
              image={profile.photo ? profile.photo : noAvatar}
              actions={({ Button }) => (
                <>
                  <PrivateComponent
                    permissions={{
                      roles: ['ma_my_ma_edit_ma_profil'],
                    }}
                  >
                    <Margin bottom={{ xs: 16 }}>
                      <Button as={Link} to="/ma/profile/edit">
                        {t('page.ma.profile.editProfileButton')}
                      </Button>
                    </Margin>
                  </PrivateComponent>
                  <Button as={Link} to="/ma/people">
                    {t('page.ma.profile.manageMembers')}
                  </Button>
                </>
              )}
            />
          </Margin>
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                <Cms>
                  <p dangerouslySetInnerHTML={{ __html: newLineToBr(profile.fullDescription) }} />
                </Cms>
              </Margin>

              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.ma.profile.individualMemberships')}
                  </Heading>
                </HeadingWrapper>
                <Cms>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: newLineToBr(profile.individualMembershipsDescription),
                    }}
                  />
                </Cms>
                {profile.individualMembershipsWebsite ? (
                  <p>
                    <a
                      href={'//' + profile.individualMembershipsWebsite}
                      className="link link--16 word-break"
                    >
                      {profile.individualMembershipsWebsite}
                    </a>
                  </p>
                ) : null}
              </Margin>

              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.ma.profile.organisationMemberships')}
                  </Heading>
                </HeadingWrapper>
                <Cms>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: newLineToBr(profile.organisationMembershipsDescription),
                    }}
                  />
                </Cms>
                {profile.organisationMembershipsWebsite ? (
                  <p>
                    <a
                      href={'//' + profile.organisationMembershipsWebsite}
                      className="link link--16 word-break"
                    >
                      {profile.organisationMembershipsWebsite}
                    </a>
                  </p>
                ) : null}
              </Margin>

              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.ma.profile.eventsTitle')}
                  </Heading>
                  <Link to="/events" className="link uppercase">
                    {t('global.seeAll')}
                  </Link>
                </HeadingWrapper>
                <BoxList type="event" items={upcomingEvents} alert={t('global.noResultsEvents')} />
              </Margin>
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              <div className="border-xl">
                <Padding all="20" className="bg-gray-100">
                  <Margin bottom="16">
                    <Heading as="h3" size="h6">
                      {t('page.ma.profile.contactUs')}
                    </Heading>
                  </Margin>
                  {profile.telephone ? (
                    <Margin bottom="12">
                      <a href={`tel:${profile.telephone}`}>{profile.telephone}</a>
                    </Margin>
                  ) : null}
                  {profile.email ? (
                    <Margin bottom="12">
                      <a href={`mailto:${profile.email}`} className="link link--16 word-break">
                        {profile.email}
                      </a>
                    </Margin>
                  ) : null}
                  {profile.website ? (
                    <a href={profile.website} className="link link--16 word-break">
                      {profile.website}
                    </a>
                  ) : null}
                </Padding>
                <Padding all="20">
                  <IconText top icon="marker" text={parseAddress(profile.address)} />
                </Padding>
                {profile.address.lat && profile.address.lang ? (
                  <Map lat={profile.address.lat} lng={profile.address.lang} />
                ) : null}
              </div>
              {profile.cbAddress ? (
                <>
                  {profile.cbAddress.line1 ? (
                    <Margin top="32">
                      <div className="border-xl">
                        <Padding all="20" className="bg-gray-100">
                          <Margin bottom="16">
                            <Heading as="h3" size="h6">
                              {t('page.ma.profile.contactUsCb')}
                            </Heading>
                          </Margin>
                          {profile.cbTelephone ? (
                            <Margin bottom="12">
                              <a href={`tel:${profile.cbTelephone}`}>{profile.cbTelephone}</a>
                            </Margin>
                          ) : null}
                          {profile.cbEmail ? (
                            <Margin bottom="12">
                              <a
                                href={`mailto:${profile.cbEmail}`}
                                className="link link--16 word-break"
                              >
                                {profile.cbEmail}
                              </a>
                            </Margin>
                          ) : null}
                          {profile.cbWebsite ? (
                            <a href={profile.cbWebsite} className="link link--16 word-break">
                              {profile.cbWebsite}
                            </a>
                          ) : null}
                        </Padding>

                        <Padding all="20">
                          <IconText top icon="marker" text={parseAddress(profile.cbAddress)} />
                        </Padding>
                        {profile.cbAddress.lat && profile.cbAddress.lang ? (
                          <Map lat={profile.cbAddress.lat} lng={profile.cbAddress.lang} />
                        ) : null}
                      </div>
                    </Margin>
                  ) : null}
                </>
              ) : null}

              {usersRole && !!usersRole.length ? (
                <Margin top="32">
                  {usersRole.map((item) => (
                    <Margin top="32">
                      <UserList title={`${item.name}:`} users={item.items} />
                    </Margin>
                  ))}
                </Margin>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

View.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
      lat: PropTypes.number,
      lang: PropTypes.number,
    }),
    cbAddress: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
      lat: PropTypes.number,
      lang: PropTypes.number,
    }),
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    cbTelephone: PropTypes.string,
    cbEmail: PropTypes.string,
    cbWebsite: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
    individualMembershipsDescription: PropTypes.string,
    organisationMembershipsDescription: PropTypes.string,
  }),
  usersRole: globalPropTypes.UserListPropsTypes,
  upcomingEvents: PropTypes.arrayOf(PropTypes.shape({})),
};
View.defaultProps = {
  profile: null,
  usersRole: [],
  upcomingEvents: [],
};

const mapStateToProps = ({ ma }) => ({
  profile: ma.profile.data,
  usersRole: ma.profile.usersRole,
  upcomingEvents: ma.profile.upcomingEvents,
});

export default connect(mapStateToProps, null)(View);
