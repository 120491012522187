import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Heading, Field, FileInput, Button, Modal, Table } from 'components';
import { Action } from 'components/Modal/Modal.styled';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import { UploadForm, PreviewForm, FlexColumn, TabsContainer, Tab } from './CsvUpload.styled';

const DelimInput = ({ delim, handleDelimChange }) => {
  const { t } = useTranslation();
  return (
    <Row halign="center">
      <Col size={{ xs: 2 / 3 }}>
        <Field label={t('global.uploadCsvComponent.delimiterInput')} required>
          <input
            id="delimiter"
            key="deli-input"
            type="text"
            placeholder={t('global.uploadCsvComponent.delimiterInputPlaceholder')}
            value={delim}
            onChange={handleDelimChange}
            // eslint-disable-next-line
            autoFocus
          />
        </Field>
      </Col>
    </Row>
  );
};

const CsvUpload = ({ title, buttonLable, fieldLable, onSubmit }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedTab, setSelectedTab] = useState('text');
  const [csvFile, setCsvFile] = useState(null);
  const [csvText, setCsvText] = useState('');
  const [delim, setDelim] = useState(',');
  const [error, setError] = useState('');

  const submit = () => {
    if (!csvFile) {
      setError(t('global.uploadCsvComponent.errorNotEmpty'));
      return;
    }
    setOpenModal(false);
    onSubmit(csvFile, delim);
  };

  const processCsvRaw = () => {
    const headers = csvText.slice(0, csvText.indexOf('\n'));
    const rows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');

    return (
      <FlexColumn>
        <span>{headers}</span>
        {rows.map((row) => (
          <span>{row}</span>
        ))}
      </FlexColumn>
    );
  };

  const processCsvTable = () => {
    const headers = csvText.slice(0, csvText.indexOf('\n')).split(delim);
    const rows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');

    const newArray = rows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => ({ ...obj, [header]: values[i] }), {});
      return eachObject;
    });

    return (
      <>
        <Table tablebreakpoint="lg">
          <Table.Head>
            <Table.Row>
              {headers.map((header) => (
                <Table.Heading>{header}</Table.Heading>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {newArray.map((row, i) => (
              <Table.Row key={i}>
                {Object.keys(row).map((key) => (
                  <Table.Cell>
                    <Table.Label>{key}:</Table.Label>
                    {row[key]}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  };

  const onFileInputChange = (e) => {
    setError(null);

    const file = e.target.files[0];
    const filename = file.name.split('.').pop();

    if (filename.toLowerCase() !== 'csv') {
      return setError(t('global.uploadCsvComponent.errorNotCsv'));
    }

    const reader = new FileReader();
    reader.onload = (_e) => {
      const text = _e.target.result.replaceAll('\r\n', '\n').replaceAll('\r', '\n').trim();
      setCsvText(text);
    };
    reader.readAsText(file);

    return setCsvFile(file);
  };

  const handleDelimChange = (e) => {
    e.preventDefault();
    setDelim(e.target.value);
  };

  const clearFileInput = () => {
    setCsvFile(null);
    setCsvText('');
    setSelectedTab('text');
    setError('');
  };

  const renderForm = () => (
    <form onSubmit={(e) => e.preventDefault()}>
      <Margin top="24">
        <Row halign="center">
          <Col size={{ xs: 2 / 3 }}>
            <Field
              id="upload-csv"
              label={fieldLable}
              required
              error={error}
              description={t('global.uploadCsvInfo')}
            >
              <FileInput
                id="exprt-cv"
                placeholder={t('global.uploadPlaceholder')}
                fileName={csvFile ? csvFile.name || csvFile.fileName : ''}
                clear={() => clearFileInput()}
                onChange={onFileInputChange}
              />
            </Field>
          </Col>
        </Row>
      </Margin>
      <Margin top="12">
        <DelimInput delim={delim} handleDelimChange={handleDelimChange} />
      </Margin>
      <Margin top="12">
        <Row halign="center">
          <Col size={{ xs: 2 / 3 }}>
            <Action onClick={() => setOpenPreview(true)} disabled={csvFile === null}>
              {t('global.preview')}
            </Action>
          </Col>
        </Row>
      </Margin>
      <Margin top="12">
        <Row halign="center">
          <Col size={{ xs: 2 / 3 }}>
            <Action onClick={submit}>{t('global.upload')}</Action>
          </Col>
        </Row>
      </Margin>
      <Margin top="12">
        <Row halign="center">
          <Col size={{ xs: 2 / 3 }}>
            <Action onClick={() => setOpenModal(false)}>{t('global.closeButton')}</Action>
          </Col>
        </Row>
      </Margin>
    </form>
  );

  const renderPreview = () => (
    <>
      <TabsContainer>
        <Tab selected={selectedTab === 'text'} onClick={() => setSelectedTab('text')}>
          {t('global.uploadCsvComponent.tabText')}
        </Tab>
        <Tab selected={selectedTab === 'table'} onClick={() => setSelectedTab('table')}>
          {t('global.uploadCsvComponent.tabTable')}
        </Tab>
      </TabsContainer>
      <PreviewForm>
        {selectedTab === 'text' ? processCsvRaw() : null}
        {selectedTab === 'table' ? processCsvTable() : null}
        <Margin top="24">
          {selectedTab === 'table' ? (
            <DelimInput delim={delim} handleDelimChange={handleDelimChange} />
          ) : null}
          <Row halign="center">
            <Col size={{ xs: 2 / 3 }}>
              <Action onClick={() => setOpenPreview(false)}>{t('global.closeButton')}</Action>
            </Col>
          </Row>
        </Margin>
      </PreviewForm>
    </>
  );

  const renderUploadModal = () => (
    <UploadForm>
      <Heading as="h4" noLine center>
        {title}
      </Heading>
      {renderForm()}
    </UploadForm>
  );

  return (
    <>
      <Button secondary onClick={() => setOpenModal(true)}>
        {buttonLable || title}
      </Button>
      <Modal
        isOpen={openModal}
        customContent={renderUploadModal}
        onRequestClose={() => setOpenModal(false)}
      />
      <Modal
        isOpen={openPreview}
        customContent={renderPreview}
        onRequestClose={() => setOpenPreview(false)}
        autoWidth
      />
    </>
  );
};

CsvUpload.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLable: PropTypes.string,
  fieldLable: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

DelimInput.propTypes = {
  delim: PropTypes.string.isRequired,
  handleDelimChange: PropTypes.func.isRequired,
};

CsvUpload.defaultProps = {
  buttonLable: null,
};

export default CsvUpload;
