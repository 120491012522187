import styled, { css } from 'styled-components';
import { mt } from 'styled-components-spacing';

export const ProfileDeleteForm = styled.form`
  ${({ theme }) =>
  theme &&
  css`
    color: ${theme.colors.gray[500]};
  `}
`;

export const PrivacyHeading = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel(18)};
      font-weight: ${theme.font.weight.bold};
      line-height: 23px;
      color: ${theme.colors.gray[500]};
    `}
`;
export const PrivacyDescription = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(8)};

      line-height: 21px;
      color: ${theme.colors.gray[500]};
    `}
`;
