import React from 'react';
import PropTypes from 'prop-types';

import StyledCheckbox, { Input } from './Checkbox.styled';

function Checkbox(props) {
  const { selected, disabled, onChange, children } = props;
  return (
    <StyledCheckbox
      disabled={disabled}
      onClick={!disabled ? () => onChange(!selected) : undefined}
      type="button"
    >
      <Input selected={selected}>
        {selected ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.4 9.4">
            <path d="M13.2.21a.69.69 0 00-1 0l-7.5 7.5-3.5-3.5a.69.69 0 00-1 0 .69.69 0 000 1l4 4a.7.7 0 00.49.2.74.74 0 00.5-.2l8-8a.71.71 0 00.01-1z" />
          </svg>
        ) : null}
      </Input>
      {children}
    </StyledCheckbox>
  );
}

Checkbox.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.elementType,
};

Checkbox.defaultProps = {
  disabled: false,
  onChange: ([value]) => value,
  selected: false,
  children: null,
};

export default Checkbox;
