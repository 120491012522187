import styled, { css } from 'styled-components';

export const Wrapper = styled.article`
  ${({ theme }) =>
    theme &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: -16px;
      margin-right: -16px;
      
      > * {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 32px;
      }
    `}
`;
