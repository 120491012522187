import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isLoading } from 'helpers';
import { Margin } from 'styled-components-spacing';
import {
  Header,
  PageContent,
  Container,
  Alerts,
  FiltersControls,
  Pagination,
  Spinner,
  Table,
  Alert,
  ButtonAction,
} from 'components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  loadMaCpdBatchesCreator,
  setMaCpdBatchesPageCreator,
  setMaCpdBatchesPerPageCreator,
} from 'store/actionsCreators';
import { StyledRow, NameCell } from './CpdRequests.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.cpdRequests',
    url: '/ma/cpd-requests',
  },
];

const CpdRequests = ({ cpds, page, pages, perPage, loading, loadCpds, setPage, setPerPage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadCpds();
  }, [perPage, page, loadCpds]);

  console.log(cpds);
  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ma.cpdRequests.title')} />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="ma" />
          </Margin>

          <FiltersControls
            perPageSelected={perPage}
            perPageOnChange={({ value }) => setPerPage(value)}
          />
          {loading ? <Spinner /> : null}
          {!loading ? (
            <>
              <Table tablebreakpoint="lg">
                <Table.Head>
                  <Table.Row>
                    <Table.Heading>Username</Table.Heading>
                    <Table.Heading>Email</Table.Heading>
                    <Table.Heading>Request date</Table.Heading>
                    <Table.Heading />
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={4}>
                        <Spinner />
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && !cpds.length ? (
                    <Table.Row>
                      <Table.Cell colSpan={4}>
                        <Alert type="info">{t('global.noResultsFound')}</Alert>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && cpds.length
                    ? cpds.map((item, index) => (
                        <>
                          <StyledRow key={item.id} isOdd={(index + 1) % 2 !== 0}>
                            <NameCell>
                              <Table.Label>Username:</Table.Label>
                              {`${item.user.firstName} ${item.user.lastName}`}
                            </NameCell>
                            <Table.Cell>
                              <Table.Label>Email:</Table.Label>
                              {item.user.email}
                            </Table.Cell>
                            <Table.Cell>
                              <Table.Label>Request date:</Table.Label>
                              {moment(item.requestDate).format('DD/MM/YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                              <ButtonAction
                                title="Review CPD hours"
                                onClick={() =>
                                  history.push({
                                    pathname: `/ma/cpd-request/${item.id}`,
                                  })
                                }
                              />
                            </Table.Cell>
                          </StyledRow>
                        </>
                      ))
                    : null}
                </Table.Body>
              </Table>
              <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
            </>
          ) : null}
        </Container>
      </PageContent>
    </>
  );
};

CpdRequests.propTypes = {
  cpds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadCpds: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cpd, loading }) => ({
  cpds: cpd.certificationBodyBatch.list.items,
  page: cpd.certificationBodyBatch.list.page,
  pages: cpd.certificationBodyBatch.list.pages,
  perPage: cpd.certificationBodyBatch.list.perPage,
  loading: isLoading(loading, 'MA'),
});

const mapDispatchToProps = (dispatch) => ({
  loadCpds: (payload) => dispatch(loadMaCpdBatchesCreator(payload)),
  setPage: (payload) => dispatch(setMaCpdBatchesPageCreator(payload)),
  setPerPage: (payload) => dispatch(setMaCpdBatchesPerPageCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CpdRequests);
