import PropTypes from 'prop-types';
import { Table, Head, Body, Row, Cell, Label } from './Table.styled';
import Heading from './components/Heading';

Table.Head = Head;
Table.Body = Body;
Table.Row = Row;
Table.Heading = Heading;
Table.Cell = Cell;
Table.Label = Label;

Table.propTypes = {
  tablebreakpoint: PropTypes.string,
};

Table.defaultProps = {
  tablebreakpoint: 'xl',
};

export default Table;
