import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ipmaShopSsoCreator } from 'store/actionsCreators';

const IpmaShopSso = ({ redirectToShop, history }) => {
  useEffect(() => {
    redirectToShop(history);
  });
  return <></>;
};

IpmaShopSso.propTypes = {
  redirectToShop: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  redirectToShop: (history) => dispatch(ipmaShopSsoCreator(history)),
});

export default connect(null, mapDispatchToProps)(IpmaShopSso);
