import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  PageContent,
  Container,
  UserDetails,
  Heading,
  IconText,
  Row,
  Col,
  Alert,
  Pagination,
  FiltersControls,
  Spinner,
} from 'components';
import { Margin } from 'styled-components-spacing';
import {
  loadSearchCreator,
  setSearchPageCreator,
  setSearchPerPageCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';

const Users = ({ loading, searchValue, loadList, setPage, setPerPage, list }) => {
  const { t } = useTranslation();

  const { items, page, pages, perPage } = list;

  useEffect(() => {
    loadList(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    loadList(searchValue);
  }, [perPage]);

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading && !items.length ? <Alert type="info">{t('global.noResultsFound')}</Alert> : null}
        {!loading && items.length ? (
          <>
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <Row>
              {items.map((item, index) => (
                <Margin as={Col} size={{ xl: 1 / 2 }} bottom={{ xs: 16, sm: 24 }} key={index}>
                  <UserDetails url={item.url} thumbnail={item.thumbnail} short shadow>
                    <Margin bottom="16">
                      <Heading as="h3" size="h6">
                        {item.name}
                      </Heading>
                    </Margin>
                    {item.place && (
                      <Margin bottom="8" className="text-tertiary font-weight-medium">
                        <IconText icon="marker" text={item.place} />
                      </Margin>
                    )}
                    {item.job && (
                      <Margin bottom="8">
                        <p className="font-weight-medium">{item.job}</p>
                      </Margin>
                    )}
                    <Margin top="16">
                      <Link
                        to={{ pathname: item.url, state: { authModal: true } }}
                        className="link link--16"
                      >
                        {t('global.seeDetails')}
                      </Link>
                    </Margin>
                  </UserDetails>
                </Margin>
              ))}
            </Row>
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList(searchValue);
              }}
            />
          </>
        ) : null}
      </Container>
    </PageContent>
  );
};

Users.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  list: globalPropTypes.ListPropsTypes.isRequired,
};

Users.defaultProps = {
  loading: false,
  searchValue: '',
};

const mapStateToProps = ({ loading, search: { users: list } }) => ({
  loading: isLoading(loading, 'LOAD_SEARCH_USERS'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: (payload) => dispatch(loadSearchCreator('users', payload)),
  setPage: (payload) => dispatch(setSearchPageCreator('users', payload)),
  setPerPage: (payload) => dispatch(setSearchPerPageCreator('users', payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
