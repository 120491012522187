import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Pagination,
  FiltersControls,
  BoxList,
  Spinner,
  Alerts,
  Modal,
} from 'components';
import {
  cancelOrganisationCourseCreator,
  loadOrganisationCoursesCreator,
  setOrganisationCoursesPageCreator,
  setOrganisationCoursesPerPageCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const Published = ({ loading, loadList, setPage, setPerPage, list, cancelCourse }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [actionModal, setActionModal] = useState(null);

  const { items, page, pages, perPage } = list;

  useEffect(() => {
    setPage(1);
    loadList(id);
  }, [perPage]);

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <Alerts type="organisation" />
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <BoxList
              type="course"
              items={items}
              alert={t('global.noResultsCourses')}
              customRenderActions={({ item, ButtonAction }) => (
                <>
                  <ButtonAction
                    icon="lockClose"
                    title={t('global.cancel')}
                    verticalviewbreakpoint="xl"
                    onClick={() =>
                      setActionModal({
                        id: item.id,
                      })
                    }
                  />
                  {item.cpdRec ? (
                    <ButtonAction
                      icon="checkRectangle"
                      title={t('global.seeParticipants')}
                      verticalviewbreakpoint="xl"
                      as={Link}
                      to={`/organisation/${id}/courses/completion/${item.id}`}
                    />
                  ) : null}
                </>
              )}
            />
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList(id);
              }}
            />
          </>
        ) : null}
      </Container>
      <Modal
        heading={t('modals.headings.areYouSure')}
        isOpen={actionModal}
        confirm={() => {
          cancelCourse(actionModal);
          setActionModal(null);
        }}
        cancel={() => setActionModal(null)}
      />
    </PageContent>
  );
};

Published.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  cancelCourse: PropTypes.func.isRequired,
  list: globalPropTypes.ListPropsTypes.isRequired,
};

Published.defaultProps = {
  loading: false,
};

const mapStateToProps = ({
  loading,
  organisation: {
    courses: { published: list },
  },
}) => ({
  loading: isLoading(loading, 'LOAD_ORGANISATION_COURSES_PUBLISHED'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: (organization) => dispatch(loadOrganisationCoursesCreator('published', organization)),
  setPage: (payload) => dispatch(setOrganisationCoursesPageCreator('published', payload)),
  setPerPage: (payload) => dispatch(setOrganisationCoursesPerPageCreator('published', payload)),
  cancelCourse: (payload) => dispatch(cancelOrganisationCourseCreator('published', payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Published);
