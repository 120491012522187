import styled, { css } from 'styled-components';
import { mr } from 'styled-components-spacing/dist/cjs/Margin';

export default styled.div`
  display: flex;
`;

export const Step = styled.button`
  ${mr(12)}

  width: 40px;
  height: 40px;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.bold};
      color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};

      &:hover {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      }

      svg {
        width: 18px;
        height: 16px;
        margin-top: 2px;
        fill: ${theme.colors.white};
      }
    `}

  &:last-of-type {
    ${mr(0)}
  }

  ${({ theme, active }) =>
    theme &&
    active &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    `}
`;
