import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ButtonIcon, Field } from 'components';
import { Wrapper } from './Search.styled';

const Search = ({ closeFn, small, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { watch, register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    closeFn();
    history.push(`/search?s=${data.s}`);
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)} {...props}>
      <Field small={small}>
        <input
          type="text"
          placeholder={t('global.searchPlaceholder')}
          name="s"
          ref={register}
          required
          autoComplete="off"
        />
      </Field>
      <ButtonIcon
        icon="search"
        secondary
        rounded
        large={!small}
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        disabled={!watch('s')}
      />
    </Wrapper>
  );
};

Search.propTypes = {
  closeFn: PropTypes.func,
  small: PropTypes.bool,
};

Search.defaultProps = {
  closeFn: () => {},
  small: false,
};

export default Search;
