import React, { useEffect, useReducer } from 'react';
import LayoutImage from 'layouts/LayoutImage/LayoutImage';
import { Row, Col, Heading, Button, Field, Select, Checkbox, Spinner } from 'components';
import { Margin } from 'styled-components-spacing';
import PropTypes from 'prop-types';
import {
  submitPageContactFromCreator,
  loadGlobalContactSubjectsCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  reducer,
  initialState,
  setFieldCreator,
  setErrorsCreator,
  setSubjectsCreator,
} from './reducer';

const Contact = ({ loading, submitForm, history, loadGlobalContactSubjects, contactSubjects }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    loadGlobalContactSubjects();
  }, []);

  useEffect(() => {
    dispatch(setSubjectsCreator(contactSubjects));
  }, [contactSubjects]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    let token = null;

    if (process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
      token = await executeRecaptcha('contact');
    }

    submitForm({ ...state.fields, recaptcha: token }, history, (payload) =>
      dispatch(setErrorsCreator(payload)),
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch(setFieldCreator({ name, value }));
  };

  const handleChangeCustom = (name) => (e) => {
    dispatch(setFieldCreator({ name, value: e.value }));
  };

  const handleChangeCustomCheckbox = (name) => (e) => {
    dispatch(setFieldCreator({ name, value: e }));
  };

  return (
    <LayoutImage>
      <Heading as="h1" size="h2">
        {t('page.contact.title')}
      </Heading>
      <Margin top="20">
        <p>{t('page.contact.subtitle')}</p>
      </Margin>
      <Margin top="24">
        {loading ? (
          <Spinner />
        ) : (
          <form onSubmit={handleOnSubmit}>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Margin bottom="12">
                  <Field id="name" error={state.errors.name}>
                    <input
                      id="name"
                      placeholder={t('page.contact.namePlaceholder')}
                      name="name"
                      value={state.fields.name}
                      onChange={handleChange}
                    />
                  </Field>
                </Margin>
                <Row>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 2 }}>
                    <Field id="email" error={state.errors.email}>
                      <input
                        id="email"
                        placeholder={t('page.contact.emailPlaceholder')}
                        name="email"
                        value={state.fields.email}
                        onChange={handleChange}
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="12" as={Col} size={{ lg: 1 / 2 }}>
                    <Field id="telephone" error={state.errors.telephone}>
                      <input
                        id="telephone"
                        placeholder={t('page.contact.phonePlaceholder')}
                        name="telephone"
                        value={state.fields.telephone}
                        onChange={handleChange}
                      />
                    </Field>
                  </Margin>
                </Row>
                <Margin bottom="12">
                  <Field
                    id="subject"
                    error={state.errors.subject}
                    value={find(state.subjects, { value: state.fields.subject }) || null}
                    clear={() => handleChangeCustom('subject')('')}
                  >
                    <Select
                      id="subject"
                      name="subject"
                      placeholder={t('page.contact.subjectPlaceholder')}
                      value={find(state.subjects, { value: state.fields.subject }) || null}
                      onChange={handleChangeCustom('subject')}
                      options={state.subjects}
                    />
                  </Field>
                </Margin>
              </Col>
              <Col size={{ md: 1 / 2 }}>
                <Margin bottom="12">
                  <Field id="message" error={state.errors.message}>
                    <textarea
                      id="message"
                      placeholder={t('page.contact.messagePlaceholder')}
                      name="message"
                      value={state.fields.message}
                      onChange={handleChange}
                      style={{ minHeight: '168px' }}
                    />
                  </Field>
                </Margin>
              </Col>
            </Row>
            <Margin top="12">
              <Field id="agreement" error={state.errors.agreement}>
                <Checkbox
                  onChange={handleChangeCustomCheckbox('agreement')}
                  selected={state.fields.agreement}
                >
                  {t('page.contact.agreement')}
                </Checkbox>
              </Field>
            </Margin>
            <Margin top="24">
              <Row>
                <Col size={{ md: 1 / 3, xl: 1 / 4 }}>
                  <Button type="submit">{t('page.contact.submitButton')}</Button>
                </Col>
              </Row>
            </Margin>
          </form>
        )}
      </Margin>
    </LayoutImage>
  );
};

Contact.propTypes = {
  submitForm: PropTypes.func.isRequired,
  loadGlobalContactSubjects: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  contactSubjects: PropTypes.arrayOf(PropTypes.shape({})),
};

Contact.defaultProps = {
  loading: false,
  contactSubjects: [],
};

const mapStateToProps = ({ loading, globals: { contactSubjects } }) => ({
  loading: isLoading(loading, 'SUBMIT_PAGE_CONTACT_FORM'),
  contactSubjects,
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: (payload, history, setErrors) =>
    dispatch(submitPageContactFromCreator(payload, history, setErrors)),
  loadGlobalContactSubjects: () => dispatch(loadGlobalContactSubjectsCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
