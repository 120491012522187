import {
  SET_USER_CPD_LIST,
  SET_CPD_CATEGORIES,
  SET_CPD_ROLES,
  SET_MA_CPD_REQUESTS,
  SET_MA_CPD_REQUESTS_BAR,
  SET_MA_CPD_REQUESTS_PAGE,
  SET_MA_CPD_REQUESTS_PER_PAGE,
  SET_MA_CPD_REQUESTS_STATUS,
  SET_MA_CPD_BATCH_REQUESTS,
  SET_MA_CPD_BATCH_REQUESTS_PAGE,
  SET_MA_CPD_BATCH_REQUESTS_PER_PAGE,
  SET_MA_CPD_BATCH_REQUESTS_LIST,
} from '../constants';

const initialState = {
  userCpd: {
    points: {
      total: 0,
      formal: 0,
      nonFormal: 0,
      manual: 0,
      automatic: 0,
    },
    list: [],
    buckets: {},
  },
  categories: {
    all: [],
    formal: [],
    nonFormal: [],
  },
  certificationBody: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      status: 'pending',
    },
    bar: {
      pending: 0,
      accepted: 0,
      rejected: 0,
    },
  },
  certificationBodyBatch: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
    },
    batchCpds: [],
    batchCpdsInfo: {},
  },
  cpdRoles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CPD_LIST:
      return {
        ...state,
        userCpd: {
          points: action.payload.pointsTypes,
          list: action.payload.data,
          buckets: action.payload.buckets,
        },
      };
    case SET_CPD_CATEGORIES:
      return {
        ...state,
        categories: {
          all: action.payload.all,
          formal: action.payload.formal,
          nonFormal: action.payload.nonFormal,
        },
      };
    case SET_MA_CPD_REQUESTS:
      return {
        ...state,
        certificationBody: {
          ...state.certificationBody,
          list: {
            ...state.certificationBody.list,
            ...action.payload,
          },
        },
      };
    case SET_MA_CPD_REQUESTS_BAR:
      return {
        ...state,
        certificationBody: {
          ...state.certificationBody,
          bar: action.payload,
        },
      };
    case SET_MA_CPD_REQUESTS_PAGE:
      return {
        ...state,
        certificationBody: {
          ...state.certificationBody,
          list: { ...state.certificationBody.list, page: action.payload },
        },
      };
    case SET_MA_CPD_REQUESTS_PER_PAGE:
      return {
        ...state,
        certificationBody: {
          ...state.certificationBody,
          list: { ...state.certificationBody.list, perPage: action.payload },
        },
      };
    case SET_MA_CPD_REQUESTS_STATUS:
      return {
        ...state,
        certificationBody: {
          ...state.certificationBody,
          list: {
            ...state.certificationBody.list,
            page:
              state.certificationBody.list.status !== action.payload
                ? 1
                : state.certificationBody.list.page,
            status: action.payload,
          },
        },
      };
    case SET_CPD_ROLES:
      return {
        ...state,
        cpdRoles: [...action.payload],
      };
    case SET_MA_CPD_BATCH_REQUESTS:
      return {
        ...state,
        certificationBodyBatch: {
          ...state.certificationBodyBatch,
          list: {
            ...state.certificationBodyBatch.list,
            ...action.payload,
          },
        },
      };
    case SET_MA_CPD_BATCH_REQUESTS_PAGE:
      return {
        ...state,
        certificationBodyBatch: {
          ...state.certificationBodyBatch,
          list: { ...state.certificationBodyBatch.list, page: action.payload },
        },
      };
    case SET_MA_CPD_BATCH_REQUESTS_PER_PAGE:
      return {
        ...state,
        certificationBodyBatch: {
          ...state.certificationBodyBatch,
          list: { ...state.certificationBodyBatch.list, perPage: action.payload },
        },
      };
    case SET_MA_CPD_BATCH_REQUESTS_LIST:
      return {
        ...state,
        certificationBodyBatch: {
          ...state.certificationBodyBatch,
          batchCpds: action.payload.items,
          batchCpdsInfo: action.payload.requestInfo,
        },
      };
    default:
      return state;
  }
};
