import styled, { css } from 'styled-components';
import { p, px, mb, mt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled.div`
  ${breakpoint('md')`
    display: flex;
    align-items: center;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${mb(24)};

  ${breakpoint('md')`
    flex-direction: row;
    align-items: center;
  `}
`;

export const Thumbnail = styled.div`
  ${({ theme }) => theme && css`
    ${p(8)};

    flex: 0 0 180px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    height: 150px;
    background-color: #fff;
    
    border: 1px solid ${theme.colors.gray['100']};
    
    img {
      max-height: 100%;
    }
    
    ${breakpoint('md')`
      ${css`
        order: -1;
      `}
    `}
  `}
`;