import React from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import { Wrapper } from './Flag.styled';

const Flag = ({ country, countryCode }) => {
  return (
    <Wrapper>
      <ReactCountryFlag
        svg
        countryCode={countryCode}
        style={{
          width: 20,
          height: 12,
          marginRight: 6,
        }}
      />
      {country}
    </Wrapper>
  );
};

Flag.propTypes = {
  country: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default Flag;
