import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FiltersControls, Pagination, Spinner, Modal, BoxList } from 'components';
import {
  loadYcEventsCreator,
  setYcEventsPageCreator,
  setYcEventsPerPageCreator,
  reopenYcEventCreator,
} from 'store/actionsCreators';
import { checkPermissions, globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';

const Cancelled = ({
  events,
  page,
  pages,
  perPage,
  loading,
  loadEvents,
  setPage,
  setPerPage,
  reopenEvent,
  userPermissions,
}) => {
  const { t } = useTranslation();
  const [reopenConfirmModal, setReopenConfirmModal] = useState(null);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadEvents({ status: 'cancelled' });
  }, [perPage, page]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="event"
            items={events}
            alert={t('global.noResultsFound')}
            customRenderActions={
              checkPermissions(
                {
                  roles: ['yc_events_reopen_event'],
                },
                userPermissions,
              )
                ? ({ item, ButtonAction }) => (
                    <ButtonAction
                      icon="lockOpen"
                      title={t('global.reopen')}
                      verticalviewbreakpoint="xl"
                      onClick={() => setReopenConfirmModal(item.id)}
                    />
                  )
                : null
            }
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
          <Modal
            heading={t('page.yc.eventsCancelled.modalReopenHeading')}
            description={t('page.yc.eventsCancelled.modalReopenDesc')}
            isOpen={!!reopenConfirmModal}
            confirm={() => reopenEvent(reopenConfirmModal) && setReopenConfirmModal(null)}
            cancel={() => setReopenConfirmModal(null)}
          />
        </>
      ) : null}
    </>
  );
};

Cancelled.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadEvents: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  reopenEvent: PropTypes.func.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

const mapStateToProps = ({ yc, loading, user }) => ({
  events: yc.events.list.items,
  page: yc.events.list.page,
  pages: yc.events.list.pages,
  perPage: yc.events.list.perPage,
  loading: isLoading(loading, 'YC'),
  userPermissions: user.userPermissions,
});
const mapDispatchToProps = (dispatch) => ({
  loadEvents: (payload) => dispatch(loadYcEventsCreator(payload)),
  setPage: (payload) => dispatch(setYcEventsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setYcEventsPerPageCreator(payload)),
  reopenEvent: (payload) => dispatch(reopenYcEventCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cancelled);
