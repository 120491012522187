import Home from 'containers/app/Home/Home';
import Contact from 'containers/app/Contact/Contact';
import ThankYou from 'containers/app/ThankYou/ThankYou';
import Notifications from 'containers/app/Notifications/Notifications';
import PrivacyPolicy from 'containers/app/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from 'containers/app/CookiePolicy/CookiePolicy';
import TermsOfService from 'containers/app/TermsOfService/TermsOfService';
import Search from 'containers/app/Search/Search';
import SearchUsers from 'containers/app/Search/Users/Users';
import SearchEvents from 'containers/app/Search/Events/Events';
import SearchCourses from 'containers/app/Search/Courses/Courses';
import SearchOrganisations from 'containers/app/Search/Organisations/Organisations';
import SearchMa from 'containers/app/Search/Ma/Ma';
import SearchYc from 'containers/app/Search/Yc/Yc';
import CoursesList from 'containers/app/CoursesList/CoursesList';
import CoursesListView from 'containers/app/CoursesList/View/View';
import EventsList from 'containers/app/EventsList/EventsList';
import EventsListView from 'containers/app/EventsList/View/View';
import MaList from 'containers/app/MaList/MaList';
import MaListView from 'containers/app/MaList/View/View';
import OrganisationsList from 'containers/app/OrganisationsList/OrganisationsList';
import OrganisationsListView from 'containers/app/OrganisationsList/View/View';
import PublicUserProfile from 'containers/app/UserProfile/UserProfile';
import IpmaShopSso from 'containers/app/IpmaShopSso/IpmaShopSso';
import YcList from 'containers/app/YcList/YcList';
import YcListView from 'containers/app/YcList/View/View';
import ProjectsList from 'containers/app/ProjectsList/ProjectsList';
import ProjectsListView from 'containers/app/ProjectsList/View/View';
import ProjectRoleView from 'containers/app/ProjectsList/ViewRole/ViewRole';
import AboutIpma from 'containers/app/AboutIpma/AboutIpma';

const HOME = 'appHome';
const CONTACT = 'appContact';
const CONTACT_THANKYOU = 'appContactThankYou';
const PRIVACY_POLICY = 'appPrivacyPolicy';
const COOKIE_POLICY = 'appCookiePolicy';
const TERMS_OF_SERVICE = 'appTermsOfService';
const NOTIFICATIONS = 'appNotifications';
const SEARCH = 'appSearch';
const SEARCH_USERS = 'appSearchUsers';
const SEARCH_EVENTS = 'appSearchEvents';
const SEARCH_COURSES = 'appCearchCourses';
const SEARCH_ORGANISATIONS = 'appSearchOrganisations';
const SEARCH_MA = 'appSearchMa';
const SEARCH_YC = 'appSearchYc';
const COURSES_LIST = 'appCoursesList';
const COURSES_LIST_VIEW = 'appCoursesListView';
const EVENTS_LIST = 'appEventsList';
const EVENTS_LIST_VIEW = 'appEventsListView';
const MA_LIST = 'appMaList';
const MA_LIST_VIEW = 'appMaListView';
const ORGANISATIONS_LIST = 'appOrganisationsList';
const ORGANISATIONS_LIST_VIEW = 'appOrganisationsListView';
const PUBLIC_USER_PROFILE = 'appPublicUserProfile';
const IPMA_SHOP_SSO = 'appIpmaShopSso';
const YC_LIST = 'appYcList';
const YC_LIST_VIEW = 'appYcListView';
const PROJECTS_LIST = 'appProjectsList';
const PROJECTS_LIST_VIEW = 'appProjectsListView';
const PROJECT_ROLE_VIEW = 'appProjectRoleListView';
const ABOUT_IPMA = 'appAboutIpma';

export default [
  {
    key: HOME,
    path: '/',
    component: Home,
    exact: true,
    withAuth: false,
  },
  {
    key: CONTACT,
    path: '/contact',
    component: Contact,
    exact: true,
    withAuth: false,
  },
  {
    key: CONTACT_THANKYOU,
    path: '/contact/thank-you',
    component: ThankYou,
    exact: false,
    withAuth: false,
  },
  {
    key: PRIVACY_POLICY,
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: false,
    withAuth: false,
  },
  {
    key: COOKIE_POLICY,
    path: '/cookie-policy',
    component: CookiePolicy,
    exact: false,
    withAuth: false,
  },
  {
    key: TERMS_OF_SERVICE,
    path: '/terms-of-service',
    component: TermsOfService,
    exact: false,
    withAuth: false,
  },
  {
    key: NOTIFICATIONS,
    path: '/notifications',
    component: Notifications,
    exact: false,
    withAuth: false,
  },
  {
    key: SEARCH,
    path: '/search',
    component: Search,
    exact: false,
    withAuth: false,
    routes: [
      {
        key: SEARCH_USERS,
        path: '/search/user-profiles',
        component: SearchUsers,
        exact: false,
        withAuth: false,
      },
      {
        key: SEARCH_EVENTS,
        path: '/search/events',
        component: SearchEvents,
        exact: false,
        withAuth: false,
      },
      {
        key: SEARCH_COURSES,
        path: '/search/courses',
        component: SearchCourses,
        exact: false,
        withAuth: false,
      },
      {
        key: SEARCH_ORGANISATIONS,
        path: '/search/organisations',
        component: SearchOrganisations,
        exact: false,
        withAuth: false,
      },
      {
        key: SEARCH_MA,
        path: '/search/ma',
        component: SearchMa,
        exact: false,
        withAuth: false,
      },
      {
        key: SEARCH_YC,
        path: '/search/YC',
        component: SearchYc,
        exact: false,
        withAuth: false,
      },
    ],
  },
  {
    key: COURSES_LIST,
    path: '/training',
    component: CoursesList,
    exact: true,
    withAuth: false,
  },
  {
    key: COURSES_LIST_VIEW,
    path: ['/training/:id', '/training/:id/:name'],
    component: CoursesListView,
    exact: true,
    withAuth: false,
  },
  {
    key: EVENTS_LIST,
    path: '/events',
    component: EventsList,
    exact: true,
    withAuth: false,
  },
  {
    key: EVENTS_LIST_VIEW,
    path: ['/events/:id', '/events/:id/:name'],
    component: EventsListView,
    exact: true,
    withAuth: false,
  },
  {
    key: MA_LIST,
    path: '/ipma-associations',
    component: MaList,
    exact: true,
    withAuth: false,
  },
  {
    key: MA_LIST_VIEW,
    path: ['/ipma-associations/:id', '/ipma-associations/:id/:name'],
    component: MaListView,
    exact: true,
    withAuth: false,
  },
  {
    key: ORGANISATIONS_LIST,
    path: '/organisations',
    component: OrganisationsList,
    exact: true,
    withAuth: false,
  },
  {
    key: ORGANISATIONS_LIST_VIEW,
    path: ['/organisations/:id', '/organisations/:id/:name'],
    component: OrganisationsListView,
    exact: true,
    withAuth: false,
  },
  {
    key: PUBLIC_USER_PROFILE,
    path: '/user-profile/:id',
    component: PublicUserProfile,
    exact: true,
    withAuth: false,
  },
  {
    key: IPMA_SHOP_SSO,
    path: '/ipma-shop',
    component: IpmaShopSso,
    exact: false,
    withAuth: false,
  },
  {
    key: YC_LIST,
    path: '/ipma-young-crews',
    component: YcList,
    exact: true,
    withAuth: false,
  },
  {
    key: YC_LIST_VIEW,
    path: ['/ipma-young-crews/:id', '/ipma-young-crews/:id/:name'],
    component: YcListView,
    exact: true,
    withAuth: false,
  },
  {
    key: PROJECTS_LIST,
    path: '/projects',
    component: ProjectsList,
    exact: true,
    withAuth: false,
  },
  {
    key: PROJECTS_LIST_VIEW,
    path: ['/projects/:id', '/projects/:id/:name'],
    component: ProjectsListView,
    exact: true,
    withAuth: false,
  },
  {
    key: PROJECT_ROLE_VIEW,
    path: ['/project-role/:id', '/project-role/:id/:name'],
    component: ProjectRoleView,
    exact: true,
    withAuth: false,
  },
  {
    key: ABOUT_IPMA,
    path: '/about',
    component: AboutIpma,
    exact: true,
    withAuth: false,
  },
];
