import {
  SET_IPMA_DASHBOARD,
  SET_IPMA_PROFILE,
  SET_IPMA_PROFILE_USERS_ROLE,
  SET_IPMA_PROFILE_UPCOMING_EVENTS,
  SET_UPDATE_IPMA_PROFILE_ERRORS,
  SET_IPMA_PEOPLE_BAR,
  SET_IPMA_PEOPLE_USERS,
  SET_IPMA_PEOPLE_USERS_SORT,
  SET_IPMA_PEOPLE_USERS_PAGE,
  SET_IPMA_PEOPLE_USERS_PER_PAGE,
  SET_IPMA_PEOPLE_ADD_LIST_USERS,
  SET_IPMA_EVENTS,
  SET_IPMA_EVENTS_BAR,
  SET_IPMA_EVENTS_PAGE,
  SET_IPMA_EVENTS_PER_PAGE,
  SET_IPMA_EVENTS_STATUS,
  SET_IPMA_EVENT_PARTICIPANTS,
  SET_IPMA_EVENT_PARTICIPANTS_PAGE,
  SET_IPMA_EVENT_PARTICIPANTS_PER_PAGE,
  SET_IPMA_AWARD_PARTICIPANTS,
  SET_IPMA_AWARD_PARTICIPANTS_PAGE,
  SET_IPMA_AWARD_PARTICIPANTS_PER_PAGE,
  SET_IPMA_AWARDS_LIST,
  SET_IPMA_AWARDS_LIST_PAGE,
  SET_IPMA_AWARDS_LIST_PER_PAGE,
  SET_IPMA_REG_OFFICE_COURSES,
  SET_IPMA_REG_OFFICE_COURSES_BAR,
  SET_IPMA_REG_OFFICE_COURSES_PAGE,
  SET_IPMA_REG_OFFICE_COURSES_PER_PAGE,
  SET_IPMA_REG_OFFICE_COURSES_STATUS,
  SET_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  SET_IPMA_REG_OFFICE_GOOGLE_LINK,
  SET_IPMA_REG_OFFICE_STAT_TABLE,
  SET_IPMA_REPORT_LIST,
  SET_IPMA_REPORTS_FILTERS,
  SET_IPMA_PROJECTS,
  SET_IPMA_PROJECT,
  SET_IPMA_PROJECTS_BAR,
  SET_IPMA_PROJECTS_PAGE,
  SET_IPMA_PROJECTS_PER_PAGE,
  SET_IPMA_PROJECTS_STATUS,
  SET_IPMA_PROJECT_ROLES,
  SET_IPMA_PROJECT_ROLE,
  SET_IPMA_PROJECT_ROLES_BAR,
  SET_IPMA_PROJECT_ROLES_PAGE,
  SET_IPMA_PROJECT_ROLES_PER_PAGE,
  SET_IPMA_PROJECT_ROLES_STATUS,
  SET_IPMA_PROJECT_ROLE_APPLICATIONS,
  SET_IPMA_PROJECT_ROLE_APPLICATION_BAR,
  SET_IPMA_PROJECT_ROLE_APPLICATION_PAGE,
  SET_IPMA_PROJECT_ROLE_APPLICATION_PER_PAGE,
  SET_IPMA_PROJECT_ROLE_APPLICATION_STATUS,
} from '../constants';

const initialState = {
  profile: {
    data: null,
    usersRole: {},
    errors: {},
    upcomingEvents: [],
  },
  people: {
    users: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    bar: {
      users: 0,
    },
    add: {
      data: [],
    },
  },
  events: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      status: 'ongoing',
    },
    bar: {
      ongoing: 0,
      completed: 0,
      cancelled: 0,
      duplicate: 0,
    },
  },
  issueBadges: {
    items: [],
    page: 1,
    pages: 1,
    perPage: 10,
  },
  awards: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
    },
    participants: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
    },
  },
  regOfficeCourses: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      status: 'pending',
    },
    bar: {
      waitingForAcceptance: 0,
      accepted: 0,
      canceled: 0,
    },
    regLogos: [],
    googleLink: '',
  },
  regOfficeTable: {
    items: [],
  },
  projects: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      status: 'planning',
    },
    view: {},
    bar: {
      planning: 0,
      running: 0,
      completed: 0,
    },
  },
  projectRoles: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      status: 'open',
    },
    view: {},
    bar: {
      open: 0,
      closed: 0,
      archived: 0,
    },
  },
  applications: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      status: 'waiting',
    },
    bar: {
      open: 0,
      closed: 0,
      archived: 0,
    },
  },
  dashboard: {},
  reports: [],
  reportsFilters: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IPMA_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case SET_IPMA_PROFILE:
      return { ...state, profile: { ...state.profile, data: action.payload } };
    case SET_IPMA_PROFILE_USERS_ROLE:
      return { ...state, profile: { ...state.profile, usersRole: action.payload } };
    case SET_IPMA_PROFILE_UPCOMING_EVENTS:
      return { ...state, profile: { ...state.profile, upcomingEvents: action.payload } };
    case SET_UPDATE_IPMA_PROFILE_ERRORS:
      return { ...state, profile: { ...state.profile, errors: action.payload } };
    case SET_IPMA_PEOPLE_BAR:
      return { ...state, people: { ...state.people, bar: action.payload } };
    case SET_IPMA_PEOPLE_USERS:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, ...action.payload } },
      };
    case SET_IPMA_PEOPLE_USERS_SORT:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, sort: action.payload } },
      };
    case SET_IPMA_PEOPLE_USERS_PAGE:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, page: action.payload } },
      };
    case SET_IPMA_PEOPLE_USERS_PER_PAGE:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, perPage: action.payload } },
      };
    case SET_IPMA_PEOPLE_ADD_LIST_USERS:
      return {
        ...state,
        people: { ...state.people, add: { ...state.people.add, data: action.payload } },
      };
    case SET_IPMA_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          list: {
            ...state.events.list,
            ...action.payload,
          },
        },
      };
    case SET_IPMA_EVENTS_BAR:
      return {
        ...state,
        events: {
          ...state.events,
          bar: action.payload,
        },
      };
    case SET_IPMA_EVENTS_PAGE:
      return {
        ...state,
        events: { ...state.events, list: { ...state.events.list, page: action.payload } },
      };
    case SET_IPMA_EVENTS_PER_PAGE:
      return {
        ...state,
        events: { ...state.events, list: { ...state.events.list, perPage: action.payload } },
      };
    case SET_IPMA_EVENTS_STATUS:
      return {
        ...state,
        events: {
          ...state.events,
          list: {
            ...state.events.list,
            page: state.events.list.status !== action.payload ? 1 : state.events.list.page,
            status: action.payload,
          },
        },
      };
    case SET_IPMA_EVENT_PARTICIPANTS:
      return {
        ...state,
        issueBadges: { ...state.issueBadges, ...action.payload },
      };
    case SET_IPMA_EVENT_PARTICIPANTS_PAGE:
      return {
        ...state,
        issueBadges: { ...state.issueBadges, page: action.payload },
      };
    case SET_IPMA_EVENT_PARTICIPANTS_PER_PAGE:
      return {
        ...state,
        issueBadges: { ...state.issueBadges, perPage: action.payload },
      };
    case SET_IPMA_AWARDS_LIST:
      return {
        ...state,
        awards: {
          ...state.awards,
          list: {
            ...state.awards.list,
            ...action.payload,
          },
        },
      };
    case SET_IPMA_AWARDS_LIST_PAGE:
      return {
        ...state,
        awards: {
          ...state.awards,
          list: {
            ...state.awards.list,
            page: action.payload,
          },
        },
      };
    case SET_IPMA_AWARDS_LIST_PER_PAGE:
      return {
        ...state,
        awards: {
          ...state.awards,
          list: {
            ...state.awards.list,
            perPage: action.payload,
          },
        },
      };
    case SET_IPMA_AWARD_PARTICIPANTS:
      return {
        ...state,
        awards: {
          ...state.awards,
          participants: { ...state.awards.participants, ...action.payload },
        },
      };
    case SET_IPMA_AWARD_PARTICIPANTS_PAGE:
      return {
        ...state,
        awards: {
          ...state.awards,
          participants: { ...state.awards.participants, page: action.payload },
        },
      };
    case SET_IPMA_AWARD_PARTICIPANTS_PER_PAGE:
      return {
        ...state,
        awards: {
          ...state.awards,
          participants: { ...state.awards.participants, perPage: action.payload },
        },
      };
    case SET_IPMA_REG_OFFICE_COURSES:
      return {
        ...state,
        regOfficeCourses: {
          ...state.regOfficeCourses,
          list: {
            ...state.regOfficeCourses.list,
            ...action.payload,
          },
        },
      };
    case SET_IPMA_REG_OFFICE_COURSES_BAR:
      return {
        ...state,
        regOfficeCourses: {
          ...state.regOfficeCourses,
          bar: action.payload,
        },
      };
    case SET_IPMA_REG_OFFICE_COURSES_PAGE:
      return {
        ...state,
        regOfficeCourses: {
          ...state.regOfficeCourses,
          list: { ...state.regOfficeCourses.list, page: action.payload },
        },
      };
    case SET_IPMA_REG_OFFICE_COURSES_PER_PAGE:
      return {
        ...state,
        regOfficeCourses: {
          ...state.regOfficeCourses,
          list: { ...state.regOfficeCourses.list, perPage: action.payload },
        },
      };
    case SET_IPMA_REG_OFFICE_COURSES_STATUS:
      return {
        ...state,
        regOfficeCourses: {
          ...state.regOfficeCourses,
          list: {
            ...state.regOfficeCourses.list,
            page:
              state.regOfficeCourses.list.status !== action.payload
                ? 1
                : state.regOfficeCourses.list.page,
            status: action.payload,
          },
        },
      };
    case SET_IPMA_REG_OFFICE_COURSE_REG_LOGOS:
      return {
        ...state,
        regOfficeCourses: {
          ...state.regOfficeCourses,
          regLogos: action.payload,
        },
      };
    case SET_IPMA_REG_OFFICE_GOOGLE_LINK:
      return {
        ...state,
        regOfficeCourses: {
          ...state.regOfficeCourses,
          googleLink: action.payload,
        },
      };
    case SET_IPMA_REG_OFFICE_STAT_TABLE:
      return {
        ...state,
        regOfficeTable: {
          ...state.regOfficeTable,
          items: action.payload,
        },
      };
    case SET_IPMA_REPORT_LIST:
      return {
        ...state,
        reports: action.payload,
      };
    case SET_IPMA_REPORTS_FILTERS:
      return {
        ...state,
        reportsFilters: action.payload,
      };
    case SET_IPMA_PROJECTS:
      return {
        ...state,
        projects: {
          ...state.projects,
          list: {
            ...state.projects.list,
            ...action.payload,
          },
        },
      };
    case SET_IPMA_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          view: action.payload,
        },
      };
    case SET_IPMA_PROJECTS_BAR:
      return {
        ...state,
        projects: {
          ...state.projects,
          bar: action.payload,
        },
      };
    case SET_IPMA_PROJECTS_PAGE:
      return {
        ...state,
        projects: { ...state.projects, list: { ...state.projects.list, page: action.payload } },
      };
    case SET_IPMA_PROJECTS_PER_PAGE:
      return {
        ...state,
        projects: { ...state.projects, list: { ...state.projects.list, perPage: action.payload } },
      };
    case SET_IPMA_PROJECTS_STATUS:
      return {
        ...state,
        projects: {
          ...state.projects,
          list: {
            ...state.projects.list,
            page: state.projects.list.status !== action.payload ? 1 : state.projects.list.page,
            status: action.payload,
          },
        },
      };
    case SET_IPMA_PROJECT_ROLES:
      return {
        ...state,
        projectRoles: {
          ...state.projectRoles,
          list: {
            ...state.projectRoles.list,
            ...action.payload,
          },
        },
      };
    case SET_IPMA_PROJECT_ROLE:
      return {
        ...state,
        projectRoles: {
          ...state.projectRoles,
          view: action.payload,
        },
      };
    case SET_IPMA_PROJECT_ROLES_BAR:
      return {
        ...state,
        projectRoles: {
          ...state.projectRoles,
          bar: action.payload,
        },
      };
    case SET_IPMA_PROJECT_ROLES_PAGE:
      return {
        ...state,
        projectRoles: {
          ...state.projectRoles,
          list: { ...state.projectRoles.list, page: action.payload },
        },
      };
    case SET_IPMA_PROJECT_ROLES_PER_PAGE:
      return {
        ...state,
        projectRoles: {
          ...state.projectRoles,
          list: { ...state.projectRoles.list, perPage: action.payload },
        },
      };
    case SET_IPMA_PROJECT_ROLES_STATUS:
      return {
        ...state,
        projectRoles: {
          ...state.projectRoles,
          list: {
            ...state.projectRoles.list,
            page:
              state.projectRoles.list.status !== action.payload ? 1 : state.projectRoles.list.page,
            status: action.payload,
          },
        },
      };
    case SET_IPMA_PROJECT_ROLE_APPLICATIONS:
      return {
        ...state,
        applications: {
          ...state.applications,
          list: {
            ...state.applications.list,
            ...action.payload,
          },
        },
      };
    case SET_IPMA_PROJECT_ROLE_APPLICATION_BAR:
      return {
        ...state,
        applications: {
          ...state.applications,
          bar: action.payload,
        },
      };
    case SET_IPMA_PROJECT_ROLE_APPLICATION_PAGE:
      return {
        ...state,
        applications: {
          ...state.applications,
          list: { ...state.applications.list, page: action.payload },
        },
      };
    case SET_IPMA_PROJECT_ROLE_APPLICATION_PER_PAGE:
      return {
        ...state,
        applications: {
          ...state.applications,
          list: { ...state.applications.list, perPage: action.payload },
        },
      };
    case SET_IPMA_PROJECT_ROLE_APPLICATION_STATUS:
      return {
        ...state,
        applications: {
          ...state.applications,
          list: {
            ...state.applications.list,
            page:
              state.applications.list.status !== action.payload ? 1 : state.applications.list.page,
            status: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
