import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Col, Modal, Row, Heading, Download, Spinner } from 'components';
import ModalContent, { Action } from 'components/Modal/Modal.styled';
import { useTranslation } from 'react-i18next';
import * as appService from 'api/services/app';

const HelpCenter = ({ openType, closeFn }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        if (openType) {
          const response =
            openType === 'ma'
              ? await appService.helpCenterMa()
              : await appService.helpCenterPublic();

          if (response.ok) {
            const {
              results: { result },
            } = await response.json();

            setFiles(result);
          }
        }
      } catch (error) {
        setFiles([]);
        closeFn();
      } finally {
        setLoading(false);
      }
    })();
  }, [openType, closeFn]);

  const renderContent = () => (
    <ModalContent>
      <Heading as="h4" noLine center>
        {t('global.helpCenterModalTitle')}
      </Heading>
      <Margin top="48">
        {loading && (
          <Margin bottom="48">
            <Spinner />
          </Margin>
        )}
        {!loading && !!files.length ? (
          <Row>
            {files.map((file) => (
              <Margin as={Col} bottom="48" size={{ md: 1 / 2 }}>
                <Download src={file.file} title={file.fileName} download />
              </Margin>
            ))}
          </Row>
        ) : null}
      </Margin>
      <Margin>
        <Row halign="center">
          <Col size={{ xs: 1 / 2 }}>
            <Action onClick={closeFn}>{t('global.closeButton')}</Action>
          </Col>
        </Row>
      </Margin>
    </ModalContent>
  );

  return <Modal isOpen={!!openType} customContent={renderContent} onRequestClose={closeFn} />;
};

HelpCenter.propTypes = {
  openType: PropTypes.oneOf([null, 'public', 'private']),
  closeFn: PropTypes.func.isRequired,
};

HelpCenter.defaultProps = {
  openType: null,
};

export default HelpCenter;
