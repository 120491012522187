import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ForgotStep from 'containers/auth/ResetPassword/steps/ForgotStep';
import ResetStep from 'containers/auth/ResetPassword/steps/ResetStep';
import SummaryStep from 'containers/auth/ResetPassword/steps/SummaryStep';

function ResetPassword() {
  return (
    <div>
      <Switch>
        <Route exact path="/auth/password" component={ForgotStep} />
        <Route path="/auth/password/reset/:token" component={ResetStep} />
        <Route path="/auth/password/summary" component={SummaryStep} />
      </Switch>
    </div>
  )
};

export default ResetPassword;
