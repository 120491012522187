import colors from './colors';
import breakpoints from './breakpoints';
import { fontSizes, fontWeights, fontFamilies } from './fonts';
import spacing from './spacing';
import grid from './grid';
import navbar from './navbar';
import zindex from './zindex';

export default {
  colors,
  breakpoints,
  font: {
    size: fontSizes,
    weight: fontWeights,
    family: fontFamilies,
  },
  spacing,
  grid,
  navbar,
  zindex,
};
