import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Modal, Heading, FormCreator, Spinner } from 'components';
import { exportUserCpdCsvCreator } from 'store/actionsCreators';
import { useFormFields, isLoading } from 'helpers';
import { SubmitButton, ModalContentCustom } from './ExportCpd.styled';

const ModalForm = ({ exportCsv, categories }) => {
  const [formFields, handleFormFieldChange] = useFormFields({
    categories: '',
    startDate: '',
    endDate: '',
    errors: {},
  });

  const formDetails = {
    'row-1': {
      startDate: {
        type: 'datetime',
        id: 'f-startDate',
        label: 'Start date',
        placeholder: 'Select start date...',
        required: true,
      },
      endDate: {
        type: 'datetime',
        id: 'f-startDate',
        label: 'End date',
        placeholder: 'Select end date...',
        required: true,
      },
    },
    'row-2': {
      categories: {
        type: 'checkbox',
        id: 'f-categories',
        label: 'Categories to export',
        options: categories ? categories.all : [],
      },
    },
  };

  const submit = () => {
    exportCsv({
      dateFrom: formFields.startDate,
      dateTo: formFields.endDate,
      categories: formFields.categories,
    });
  };

  return (
    <>
      <FormCreator
        formFields={formFields}
        formDetails={formDetails}
        errors={formFields.errors}
        handleFormFieldChange={handleFormFieldChange}
        buttonLabel="Send"
        onSubmit={submit}
        hideButton
      />
      <SubmitButton onClick={submit}>Export</SubmitButton>
    </>
  );
};

const ExportCpd = ({ open, loading, closeFn, exportCsv, categories }) => {
  const renderContent = () => (
    <ModalContentCustom>
      <Heading as="h4" noLine center>
        Export your CPD hours
      </Heading>
      {loading ? (
        <Spinner />
      ) : (
        <Margin top="32">
          <ModalForm exportCsv={exportCsv} categories={categories} />
        </Margin>
      )}
    </ModalContentCustom>
  );

  return (
    <Modal
      isOpen={open}
      customContent={renderContent}
      onRequestClose={closeFn}
      autoWidth
      customWidth="70%"
      overflowType="initial"
    />
  );
};

ExportCpd.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  closeFn: PropTypes.func.isRequired,
  exportCsv: PropTypes.func.isRequired,
  categories: PropTypes.objectOf({}).isRequired,
};

ModalForm.propTypes = {
  exportCsv: PropTypes.func.isRequired,
  categories: PropTypes.objectOf({}).isRequired,
};

ExportCpd.defaultProps = {
  open: false,
};

const mapStateToProps = ({ user: { appType }, loading }) => ({
  loading: isLoading(loading, 'CPD_EXPORT'),
  appType,
});

const mapDispatchToProps = (dispatch) => ({
  exportCsv: (payload) => dispatch(exportUserCpdCsvCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportCpd);
