import {
  SET_REGISTRATION_USER_DATA,
  SET_REGISTER_ERRORS,
  GET_REGISTRATION_USER_DATA,
} from '../constants';

const initialState = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    dob: null,
    password: '',
    repassword: '',
    country: null,
    ma: null,
    youngCrewMember: null,
    terms: true,
  },
  errors: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REGISTRATION_USER_DATA:
      return { ...state, user: action.payload, errors: {} };
    case SET_REGISTER_ERRORS:
      return { ...state, errors: action.payload };
    case GET_REGISTRATION_USER_DATA:
      return { ...state };
    default:
      return state;
  }
};
