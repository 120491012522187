import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Action } from 'components/Modal/Modal.styled';

export const TextContainer = styled.div`
  font-size: 20px;
  ${breakpoint('lg')`
    ${css`
      font-size: 18px;
    `}
  `}
`;

export const StyledAction = styled(Action)`
  ${({ theme }) =>
    css`
      width: 100%;
      padding: 12px 16px;
      height: 100%;

      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      font-family: inherit;
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.semibold};

      color: ${theme.colors.white};
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.primary};

      border-width: 2px;
      border-style: solid;
      border-radius: 2px;

      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: ${theme.colors.white};
        border-color: ${theme.colors.primaries.darker};
        background-color: ${theme.colors.primaries.darker};
      }
    `}
`;

export const CouponWrapper = styled.a`
  ${({ theme }) => theme && css`
    margin-top: 12px;
    display: flex;
    justify-content: center;
  `}
`;

export const CouponStyle = styled.a`
  ${({ theme }) => theme && css`
    font-size: 20px;
    color: white;
    background: grey;
    border: solid 1px grey;
    border-radius: 5px;
    padding: 4px;
  `}
`;

export const Button = styled.a`
  ${({ theme }) => theme && css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: black;
    cursor: pointer;
    margin-top: 5px
  `}
`;