import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BoxList,
  Button,
  Col,
  Container,
  Heading,
  HeadingWrapper,
  Row,
  ShortBox,
  Slider,
} from 'components';
import { Margin } from 'styled-components-spacing';
import Sidebar from 'containers/app/Home/Sidebar/Sidebar';
import { Content, News } from 'containers/app/Home/Home.styled';
import PropTypes from 'prop-types';
import { loadPageHomeCreator, loadHomeSliderCreator } from 'store/actionsCreators';

const Home = ({ loadPage, loadSlider, view, slides }) => {
  useEffect(() => {
    loadPage();
    loadSlider();
  }, []);

  const { t } = useTranslation();

  const { events, courses, organisations, ma } = view;

  const renderEvents = () => (
    <Margin bottom="64" top={{ xl: 24 }}>
      <HeadingWrapper>
        <Heading as="h2" size="h4">
          {t('page.home.eventsTitle')}
        </Heading>
        <Link to={{ pathname: '/events', state: { authModal: false } }} className="link uppercase">
          {t('global.seeAll')}
        </Link>
      </HeadingWrapper>
      <BoxList type="event" items={events} alert={t('global.noResultsEvents')} />
    </Margin>
  );

  const renderCourses = () => (
    <>
      <HeadingWrapper>
        <Heading as="h2" size="h4">
          {t('page.home.coursesTitle')}
        </Heading>
        <Link
          to={{ pathname: '/training', state: { authModal: false } }}
          className="link uppercase"
        >
          {t('global.seeAll')}
        </Link>
      </HeadingWrapper>
      <BoxList type="course" items={courses} alert={t('global.noResultsCourses')} />
    </>
  );

  return (
    <>
      <Slider items={slides} />
      <Content>
        <Container>
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              {renderEvents()}
              {renderCourses()}
            </Col>
            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              <Sidebar organisations={organisations} />
            </Margin>
          </Row>
        </Container>
      </Content>
      {ma && ma.length > 0 ? (
        <News>
          <Container>
            <Margin bottom="32">
              <Heading as="h2" color="#fff" center>
                {t('page.home.maTitle')}
              </Heading>
            </Margin>
            <Row>
              {ma.map((item, index) => (
                <Col key={index} size={{ xl: 1 / 2 }}>
                  <Margin bottom="16">
                    <ShortBox
                      thumbnail={item.thumbnail}
                      title={item.title}
                      url={item.url}
                      description={item.description}
                      news
                    />
                  </Margin>
                </Col>
              ))}
            </Row>
            <Margin top="16" as={Row}>
              <Col size={{ xl: 2 / 5 }} />
              <Col size={{ xl: 1 / 5 }}>
                <Button
                  as={Link}
                  to={{ pathname: '/ipma-associations', state: { authModal: false } }}
                >
                  {t('page.home.maButton')}
                </Button>
              </Col>
            </Margin>
          </Container>
        </News>
      ) : null}
    </>
  );
};

Home.propTypes = {
  loadPage: PropTypes.func.isRequired,
  loadSlider: PropTypes.func.isRequired,
  view: PropTypes.shape({
    events: PropTypes.arrayOf(PropTypes.shape({})),
    courses: PropTypes.arrayOf(PropTypes.shape({})),
    organisations: PropTypes.arrayOf(PropTypes.shape({})),
    ma: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      photo: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
};

Home.defaultProps = {
  view: {},
};

const mapStateToProps = ({ page: { home }, home: { slider: slides } }) => ({
  view: home,
  slides,
});

const mapDispatchToProps = (dispatch) => ({
  loadPage: () => dispatch(loadPageHomeCreator()),
  loadSlider: () => dispatch(loadHomeSliderCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
