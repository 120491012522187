import styled, { css } from 'styled-components';
import { mb, mr, ml } from 'styled-components-spacing';

export const Wrapper = styled.div`
  ${mb('16')};

  text-align: right;
`;

export const Item = styled.div`
  ${ml('16')};
  ${mb('8')};

  text-align: left;
  display: inline-flex;
  align-items: center;
`;

export const Content = styled.div`
  min-width: 220px;

  ${({ short }) => short && css`
    min-width: 120px;
  `}
`;

export const Label = styled.p`
  ${({ theme }) => theme && css`
    ${mr('8')};
    
    color: #949799;
    font-size: ${theme.font.size.pixel('14')};
    font-weight: ${theme.font.weight.semibold};
    text-align: right;
  `}
`;