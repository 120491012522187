import styled, { css } from 'styled-components';
import { p, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import headerImageDesktop from 'assets/images/header_desktop_light.png';

export const SubmitButton = styled.button`
  ${({ theme, active }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    padding: 13px 26px;
    border-radius: 30px;
    background-color: ${theme.colors.primaries.default};
    width: 100%;
    transition: all 0.2s;
    ${!active &&
    css`
      &:hover {
        background-color: ${theme.colors.primaries.darker};
      }
    `}
  `}
`;
export const Success = styled.div`
  background-image: url(${headerImageDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  width: 100%;
  height: 140px;
  position: absolute;
  bottom: 0;
  z-index: -1;
`;

export const ModalContentCustom = styled.div`
  text-align: center;
  overflow: hidden;
  ${px(16)};
  ${py(24)};

  ${breakpoint('md')`
    ${css`
      ${p(42)};
    `}
  `}

  p {
    ${({ theme }) =>
      theme &&
      css`
        font-size: ${theme.font.size.pixel('16')};
        line-height: 24px;
      `}
  }
`;
