import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Pagination,
  FiltersControls,
  BoxList,
  Spinner,
  Alerts,
  Modal,
} from 'components';
import {
  declineUserFromCourseCreator,
  loadUserCoursesCreator,
  setUserCoursesPageCreator,
  setUserCoursesPerPageCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';

const Upcoming = ({ loading, loadList, setPage, setPerPage, list, declineUserFromCourse }) => {
  const { t } = useTranslation();
  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  const { items, page, pages, perPage } = list;

  useEffect(() => {
    setPage(1);
    loadList();
  }, [perPage]);

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <Alerts type="user" />
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <BoxList
              type="course"
              items={items}
              alert={t('global.noResultsCourses')}
              customRenderActions={({ item, ButtonAction }) => (
                <>
                  <ButtonAction
                    icon="lockClose"
                    title={t('courses.signOutOfTheCourse')}
                    onClick={() => setOpenConfirmModal(item.id)}
                  />
                </>
              )}
            />
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList();
              }}
            />
            <Modal
              heading={t('modals.headings.areYouSure')}
              isOpen={openConfirmModal}
              confirm={() => {
                declineUserFromCourse(openConfirmModal);
                setOpenConfirmModal(null);
              }}
              cancel={() => setOpenConfirmModal(null)}
            />
          </>
        ) : null}
      </Container>
    </PageContent>
  );
};

Upcoming.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  list: globalPropTypes.ListPropsTypes.isRequired,
  declineUserFromCourse: PropTypes.func.isRequired,
};

Upcoming.defaultProps = {
  loading: false,
};

const mapStateToProps = ({
  loading,
  user: {
    courses: { upcoming: list },
  },
}) => ({
  loading: isLoading(loading, 'LOAD_USER_COURSES_UPCOMING'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: () => dispatch(loadUserCoursesCreator('upcoming')),
  setPage: (payload) => dispatch(setUserCoursesPageCreator('upcoming', payload)),
  setPerPage: (payload) => dispatch(setUserCoursesPerPageCreator('upcoming', payload)),
  declineUserFromCourse: (payload) => dispatch(declineUserFromCourseCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upcoming);
