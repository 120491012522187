import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { globalPropTypes } from 'helpers';

import { Header, Tabs } from 'components';
import { loadOrganisationCoursesBarCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';

const Courses = ({ routes, loadBar, bar, userPermissions }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    loadBar({ organization: id });
  }, []);

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.organisation.courses',
      url: `/organisation/${id}/courses`,
    },
  ];

  const initialTabs = [
    {
      title: 'navigation.tabs.organisation.courses.courseTemplates',
      path: `/organisation/${id}/courses/course-templates`,
      key: 'courseTemplates',
    },
    {
      title: 'navigation.tabs.organisation.courses.published',
      path: `/organisation/${id}/courses/published`,
      key: 'published',
    },
    {
      title: 'navigation.tabs.organisation.courses.completed',
      path: `/organisation/${id}/courses/completed`,
      key: 'completed',
    },
    {
      title: 'navigation.tabs.organisation.courses.drafts',
      path: `/organisation/${id}/courses/drafts`,
      key: 'drafts',
    },
    {
      title: 'navigation.tabs.organisation.courses.submittedTemplates',
      path: `/organisation/${id}/courses/submitted-templates`,
      key: 'submittedTemplates',
    },
    {
      title: 'navigation.tabs.organisation.courses.pending',
      path: `/organisation/${id}/courses/pending`,
      key: 'pending',
    },
  ];

  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect(`/organisation/${id}/courses`, firstTabPath);

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.organisation.courses.title')}
        renderActions={({ Button: ButtonRender }) => (
          <ButtonRender secondary as={Link} to={`/organisation/${id}/courses/create`}>
            {t('page.organisation.courses.titleButton')}
          </ButtonRender>
        )}
      />
      <Tabs items={tabs} />
      {renderExactRedirect()}
      {renderSubRoutes()}
    </>
  );
};

Courses.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  loadBar: PropTypes.func.isRequired,
  bar: PropTypes.shape({
    courseTemplates: PropTypes.number,
    published: PropTypes.number,
    completed: PropTypes.number,
    drafts: PropTypes.number,
    submittedTemplates: PropTypes.number,
    pending: PropTypes.number,
  }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

Courses.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ organisation, user }) => ({
  bar: organisation.courses.bar,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadBar: (payload) => dispatch(loadOrganisationCoursesBarCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
