import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Button,
  Col,
  Datepicker,
  Field,
  FileInput,
  Row,
  Select,
  Radio,
  Checkbox,
} from 'components';
import { Margin } from 'styled-components-spacing/dist/cjs/Margin';
import { useTranslation } from 'react-i18next';

import {
  PrivacyRadioLabel,
  FormPrivacyDescription,
  VerticalRow,
} from 'containers/user/ProfileEdit/ProfileEdit.styled';

const FormCreator = ({
  formFields,
  formDetails,
  errors,
  defaultGap,
  breakpoint,
  buttonLabel,
  hideButton,
  handleFormFieldChange,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const resetError = (field) => ({
    ...formFields.errors,
    [field]: null,
  });

  const handleSubmit = () => {
    onSubmit();
  };

  const handleInputChange = (field) => (e) => {
    handleFormFieldChange(
      {
        ...formFields,
        [field]: e.target.value,
        errors: resetError(field),
      },
      true,
    );
  };

  const handleSelectChange = (field) => (selected) => {
    handleFormFieldChange(
      {
        ...formFields,
        [field]: selected,
        errors: resetError(field),
      },
      true,
    );
  };

  const handleCheckboxChange = (field, _id) => (value) => {
    handleFormFieldChange(
      {
        ...formFields,
        [field]: value
          ? [...formFields[field], _id]
          : formFields[field].filter((item) => item !== _id),
        errors: resetError(field),
      },
      true,
    );
  };

  const handleFileChange = (field) => (e) => {
    const files = e ? e.target.files : null;
    handleFormFieldChange(
      {
        ...formFields,
        [field]: files && files.length ? files[0] : null,
        errors: {
          ...formFields.errors,
          [field]: null,
        },
      },
      true,
    );
  };

  const renderInput = (
    value,
    name,
    placeholder,
    label,
    icon,
    required,
    disabled,
    error,
    description,
  ) => (
    <Field
      icon={icon}
      id={`f-${name}`}
      label={label}
      error={error}
      required={required}
      description={description}
    >
      <input
        id={name}
        name={name}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange(name)}
        disabled={disabled}
      />
    </Field>
  );

  const renderInputNumber = (
    value,
    name,
    placeholder,
    label,
    icon,
    required,
    disabled,
    error,
    description,
  ) => (
    <Field
      icon={icon}
      id={`f-${name}`}
      label={label}
      error={error}
      required={required}
      description={description}
    >
      <input
        id={name}
        name={name}
        type="number"
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange(name)}
        disabled={disabled}
      />
    </Field>
  );

  const renderTextArea = (
    value,
    name,
    placeholder,
    label,
    _,
    required,
    disabled,
    error,
    description,
  ) => (
    <Field
      id={`f-${name}`}
      label={label}
      error={error}
      required={required}
      description={description}
    >
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={handleInputChange(name)}
        disabled={disabled}
      />
    </Field>
  );

  const renderSelect = (
    value,
    name,
    placeholder,
    label,
    _,
    required,
    disabled,
    error,
    description,
    options,
    formatGroupLabel,
    menuPortalTarget,
  ) => {
    return (
      <Field
        id={`f-${name}`}
        label={label}
        error={error}
        required={required}
        clear={() => handleSelectChange(name)(null)}
        description={description}
      >
        <Select
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleSelectChange(name)}
          options={options}
          disabled={disabled}
          formatGroupLabel={formatGroupLabel}
          getOptionValue={(option) => option.value || option.id || option.name}
          menuPortalTarget={menuPortalTarget}
        />
      </Field>
    );
  };

  const renderRadioSelect = (
    value,
    name,
    _,
    label,
    __,
    required,
    disabled,
    error,
    description,
    options,
    appendLabelLink,
  ) => {
    return (
      <Field
        id={`f-${name}`}
        label={label}
        error={error}
        required={required}
        description={description}
        descriptionUpper
        appendLabelLink={appendLabelLink}
      >
        <Margin top="8" as={Row} style={{ width: '100%' }}>
          {options.map((option) => (
            <Col key={`${name}-radio-${option.value}`} size={{ xs: 1 / 2, md: 1 / 4 }}>
              <Margin bottom="8">
                <Radio
                  onClick={() => handleSelectChange(name)(option.value)}
                  selected={value === option.value}
                  disabled={disabled}
                >
                  {option.label}
                </Radio>
              </Margin>
            </Col>
          ))}
        </Margin>
      </Field>
    );
  };

  const renderCheckboxSelect = (
    value,
    name,
    _,
    label,
    __,
    required,
    disabled,
    error,
    description,
    options,
  ) => {
    return (
      <Field
        id={`f-${name}`}
        label={label}
        error={error}
        required={required}
        description={description}
        descriptionUpper
      >
        <Margin top="8" as={Row} style={{ width: '100%' }}>
          {options.map((option) => (
            <Col
              key={`${name}-checkbox-${option.value}`}
              size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}
            >
              <Margin bottom="8">
                <Checkbox
                  onChange={handleCheckboxChange(name, option.value)}
                  selected={value.includes(option.value)}
                  disabled={disabled}
                >
                  {option.label}
                </Checkbox>
              </Margin>
            </Col>
          ))}
        </Margin>
      </Field>
    );
  };

  const renderDatePicker = (
    value,
    name,
    placeholder,
    label,
    _,
    required,
    disabled,
    error,
    description,
  ) => (
    <Field
      id={`f-${name}`}
      label={label}
      error={error}
      required={required}
      clear={() => handleSelectChange(name)(null)}
      description={description}
    >
      <Datepicker
        id={name}
        name={name}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        selected={value}
        onChange={handleSelectChange(name)}
        disabled={disabled}
      />
    </Field>
  );

  const renderFileUpload = (
    value,
    name,
    placeholder,
    label,
    _,
    required,
    disabled,
    error,
    description,
  ) => {
    return (
      <Field
        id={`f-${name}`}
        label={label}
        error={error}
        required={required}
        description={description || t('global.uploadFileInfo')}
      >
        <FileInput
          id={name}
          placeholder={placeholder}
          fileName={value ? value.name || value.fileName : ''}
          onChange={handleFileChange(name)}
          clear={() => handleFileChange(name)('')}
          disabled={disabled}
        />
      </Field>
    );
  };

  const renderImageUpload = (
    value,
    name,
    placeholder,
    label,
    _,
    required,
    disabled,
    error,
    description,
  ) => {
    return (
      <Field
        id={`f-${name}`}
        label={label}
        error={error}
        required={required}
        description={description || t('global.uploadImageInfo')}
      >
        <FileInput
          id={name}
          placeholder={placeholder}
          fileName={value ? value.name || value.fileName || value : ''}
          onChange={handleFileChange(name)}
          clear={() => handleFileChange(name)('')}
          disabled={disabled}
        />
      </Field>
    );
  };

  const renderPrivacyRadioSelect = (
    value,
    name,
    _,
    label,
    __,
    required,
    disabled,
    error,
    description,
    options,
  ) => {
    return (
      <Field
        id={`f-${name}`}
        label={label}
        error={error}
        required={required}
        description={description}
      >
        <Margin left="8" top="8" as={Row} style={{ width: '100%' }}>
          {options.map((option) => (
            <VerticalRow
              key={`${name}-radio-${option.value}`}
              size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}
            >
              <Margin left="20" bottom="8">
                <Radio
                  onClick={() => handleSelectChange(name)(option.value)}
                  selected={value ? value === option.value : false}
                  disabled={disabled}
                >
                  <Margin left={{ xs: 8 }}>
                    <PrivacyRadioLabel>{option.heading}</PrivacyRadioLabel>
                    <FormPrivacyDescription>{option.label}</FormPrivacyDescription>
                  </Margin>
                </Radio>
              </Margin>
            </VerticalRow>
          ))}
        </Margin>
      </Field>
    );
  };

  const renderHeading = (size, text) => <Heading size={size}>{text}</Heading>;

  const renderCol = (key, size, marginBottom, marginTop, component) => (
    <Margin bottom={marginBottom} top={marginTop} as={Col} size={{ [breakpoint]: size }} key={key}>
      {component}
    </Margin>
  );

  const renderFields = (row) => {
    const defaultSize = 1 / Object.keys(formDetails[row]).length;
    let component = null;

    return Object.keys(formDetails[row]).map((field) => {
      const params = [
        formFields[field],
        field,
        formDetails[row][field].placeholder,
        formDetails[row][field].label,
        formDetails[row][field].icon,
        formDetails[row][field].required,
        !!formDetails[row][field].disabled,
        errors[field],
        formDetails[row][field].description,
      ];

      switch (formDetails[row][field].type) {
        case 'heading':
          component = renderHeading(formDetails[row][field].textSize, formDetails[row][field].text);
          break;
        case 'input':
          component = renderInput(...params);
          break;
        case 'input-number':
          component = renderInputNumber(...params);
          break;
        case 'textarea':
          component = renderTextArea(...params);
          break;
        case 'select':
          component = renderSelect(
            ...params,
            formDetails[row][field].options,
            formDetails[row][field].formatGroupLabel,
            formDetails[row][field].menuPortalTarget,
          );
          break;
        case 'radio':
          component = renderRadioSelect(
            ...params,
            formDetails[row][field].options,
            formDetails[row][field].appendLabelLink,
          );
          break;
        case 'privacyRadio':
          component = renderPrivacyRadioSelect(...params, formDetails[row][field].options);
          break;
        case 'checkbox':
          component = renderCheckboxSelect(...params, formDetails[row][field].options);
          break;
        case 'datetime':
          component = renderDatePicker(...params);
          break;
        case 'fileupload':
          component = renderFileUpload(...params);
          break;
        case 'imageUpload':
          component = renderImageUpload(...params);
          break;
        default:
          component = <></>;
      }
      return renderCol(
        `k-${row}-${field}`,
        formDetails[row][field].size || defaultSize,
        formDetails[row][field].marginBottom || defaultGap,
        formDetails[row][field].marginTop,
        component,
      );
    });
  };

  const renderRows = () => {
    return Object.keys(formDetails).map((row) => {
      return <Row key={`k-${row}`}>{renderFields(row)}</Row>;
    });
  };

  return (
    <form style={{ width: '100%' }}>
      {renderRows()}
      {!hideButton ? (
        <Row>
          <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
            <Margin bottom={{ xs: 12, md: 0 }}>
              <Button type="button" onClick={handleSubmit}>
                {buttonLabel || t('global.submit')}
              </Button>
            </Margin>
          </Col>
        </Row>
      ) : null}
    </form>
  );
};

FormCreator.propTypes = {
  formFields: PropTypes.shape({
    errors: PropTypes.shape({}),
  }).isRequired,
  formDetails: PropTypes.shape([]).isRequired,
  errors: PropTypes.shape([]),
  defaultGap: PropTypes.number,
  breakpoint: PropTypes.string,
  buttonLabel: PropTypes.string,
  hideButton: PropTypes.bool,
  handleFormFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

FormCreator.defaultProps = {
  errors: {},
  defaultGap: 20,
  breakpoint: 'lg',
  buttonLabel: '',
  hideButton: false,
};

export default FormCreator;
