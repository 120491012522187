import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return [
    {
      value: true,
      label: t('global.yes'),
    },
    {
      value: false,
      label: t('global.no'),
    },
  ];
};
