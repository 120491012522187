import styled, { css } from 'styled-components';
import { pl, pr } from 'styled-components-spacing';

export const Wrapper = styled.label`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.colors.gray[100]};
      ${pl(16)}
    `};

  ${({ theme, remove }) =>
    theme &&
    remove &&
    css`
      justify-content: space-between;
      margin-bottom: 8px;
      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    `};

  input {
    width: 1px;
    height: 1px;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Text = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      flex: 1;
      font-size: ${theme.font.size.pixel(16)};
      font-weight: ${theme.font.weight.regular};
      color: ${theme.colors.gray[500]};
      ${pr(16)}
    `}
`;

export const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.colors.secondary};

      svg {
        width: 20px;
        height: 20px;
        fill: white;
      }
    `}
`;
