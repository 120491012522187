import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { Heading } from 'components';
import { getAvatar } from 'helpers';
import { Wrapper, Thumbnail, Content, Description } from './ShortBox.styled';

const ShortBox = ({ thumbnail, url, title, description, news }) => {
  const [isTrucated, setIsTruncated] = useState(false);

  const renderDetailsLink = () => (
    <>
      ... <Link to={url}>see details</Link>
    </>
  );

  return (
    <Wrapper news={news}>
      <Thumbnail>
        <img src={getAvatar(thumbnail)} alt="" />
      </Thumbnail>
      <Content>
        <Heading size="h6" as="h3" noLine color={news ? '#fff' : null}>
          <Link to={url}>{title}</Link>
        </Heading>
        {news ? (
          <Description>
            <Truncate
              lines={3}
              onTruncate={(truncated) => setIsTruncated(truncated)}
              ellipsis={renderDetailsLink()}
            >
              {description}
            </Truncate>
            {!isTrucated && renderDetailsLink()}
          </Description>
        ) : (
          <Description>
            <Link to={url}>see details</Link>
          </Description>
        )}
      </Content>
    </Wrapper>
  );
};

ShortBox.propTypes = {
  thumbnail: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })])
    .isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  news: PropTypes.bool,
};

ShortBox.defaultProps = {
  thumbnail: '',
  description: '',
  news: false,
};

export default ShortBox;
