export const initialState = {
  course: {
    name: '',
    perspectiveCompetencies: [],
    peopleCompetencies: [],
    practiceCompetencies: [],
    customCompetencies: [],
  },
  fields: {
    name: '',
    startDate: '',
    endDate: '',
    onDemand: false,
    timezone: '',
    line1: '',
    line2: '',
    city: '',
    postCode: '',
    country: '',
    doesntApply: false,
    shortDescription: '',
    description: '',
    programmeDescription: null,
    tags: [],
    perspectiveCompetencies: [],
    peopleCompetencies: [],
    practiceCompetencies: [],
    customCompetencies: [],
    duration: '',
    learningOutcomes: [],
  },
  errors: {},
};

const SET_COURSE = 'SET_COURSE';
const SET_FIELD = 'SET_FIELD';
const SET_FIELDS = 'SET_FIELDS';
const SET_ERRORS = 'SET_ERRORS';
const SET_DURATION_FROM_COMPETENCIES = 'SET_DURATION_FROM_COMPETENCIES';
const ADD_LEARNING_OUTCOMES = 'ADD_LEARNING_OUTCOMES';
const SET_LEARNING_OUTCOMES = 'SET_LEARNING_OUTCOMES';
const REMOVE_LEARNING_OUTCOMES = 'REMOVE_LEARNING_OUTCOMES';

export const setCourseCreator = (payload) => ({ type: SET_COURSE, payload });
export const setFieldCreator = (payload) => ({ type: SET_FIELD, payload });
export const setFieldsCreator = (payload) => ({ type: SET_FIELDS, payload });
export const setErrorsCreator = (payload) => ({ type: SET_ERRORS, payload });
export const setDurationFromCompetenciesCreator = () => ({ type: SET_DURATION_FROM_COMPETENCIES });
export const addLearningOutcomesCreator = () => ({ type: ADD_LEARNING_OUTCOMES });
export const setLearningOutcomesCreator = (payload) => ({ type: SET_LEARNING_OUTCOMES, payload });
export const removeLearningOutcomesCreator = (payload) => ({
  type: REMOVE_LEARNING_OUTCOMES,
  payload,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE:
      return {
        ...state,
        course: action.payload,
      };
    case SET_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
      };
    case SET_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload,
        },
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_DURATION_FROM_COMPETENCIES:
      return {
        ...state,
        fields: {
          ...state.fields,
          duration: parseFloat(
            [
              ...state.fields.perspectiveCompetencies,
              ...state.fields.peopleCompetencies,
              ...state.fields.practiceCompetencies,
              ...state.fields.customCompetencies,
            ].length * 2,
          ).toFixed(2),
        },
      };
    case ADD_LEARNING_OUTCOMES:
      return {
        ...state,
        fields: {
          ...state.fields,
          learningOutcomes: [...state.fields.learningOutcomes, ''],
        },
      };
    case SET_LEARNING_OUTCOMES:
      state.fields.learningOutcomes.splice(action.payload.index, 1, action.payload.value);

      return {
        ...state,
        fields: {
          ...state.fields,
          learningOutcomes: state.fields.learningOutcomes,
        },
      };
    case REMOVE_LEARNING_OUTCOMES:
      state.fields.learningOutcomes.splice(action.payload, 1);

      return {
        ...state,
        fields: {
          ...state.fields,
          learningOutcomes: state.fields.learningOutcomes,
        },
      };
    default:
      return state;
  }
};
