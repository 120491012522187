import styled, { css } from 'styled-components';
import { py } from 'styled-components-spacing';
import bgNews from 'assets/images/see_where_we_are.jpg';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  ${py(42)};

  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${bgNews});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${breakpoint('xl')`
    ${css`
      ${py(64)};
    `}
  `}
`;
