import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Tags, IconText, Flag, Row, Col } from 'components';
import { Paragraph } from '../Box/Box.styled';

const BoxList = ({
  type,
  items,
  alert,
  customRenderInfo,
  customRenderActions,
  renderActionsFull,
  renderActionsLeft,
  headerAs,
}) => {
  if (!items || !items.length) {
    return alert ? <Alert type="empty">{alert}</Alert> : null;
  }

  const renderInfo = (item) => {
    if (typeof customRenderInfo === 'function') {
      return (components) => customRenderInfo({ item, ...components });
    }

    switch (type) {
      case 'event':
        return () => (
          <>
            {item.date && <Paragraph>{item.date}</Paragraph>}
            {item.place && <IconText icon="marker" text={item.place} />}
            {item.country && item.countryCode && (
              <Flag country={item.country} countryCode={item.countryCode} />
            )}
          </>
        );
      case 'course':
        return () => (
          <>
            {item.date && <Paragraph>{item.date}</Paragraph>}
            {item.place && <IconText icon="marker" text={item.place} />}
            {item.country && item.countryCode && (
              <Flag country={item.country} countryCode={item.countryCode} />
            )}
          </>
        );
      case 'courseNoDate':
        return () => (
          <>
            {item.place && <IconText icon="marker" text={item.place} />}
            {item.country && item.countryCode && (
              <Flag country={item.country} countryCode={item.countryCode} />
            )}
          </>
        );
      case 'organisation':
        return () => (
          <>
            {item.place && <IconText icon="marker" text={item.place} />}
            {item.user && <IconText icon="user" text={item.user} />}
            {item.courses && (
              <IconText icon="checkRectangle" text={`Courses and programmes (${item.courses})`} />
            )}
          </>
        );
      case 'ma':
        return () => (
          <>
            {item.place && <IconText icon="marker" text={item.place} />}
            {item.events && <IconText icon="checkRectangle" text={`Events (${item.events})`} />}
          </>
        );
      case 'yc':
        return () => (
          <>
            {item.place && <IconText icon="marker" text={item.place} />}
            {item.events && <IconText icon="checkRectangle" text={`Events (${item.events})`} />}
          </>
        );
      case 'project':
        return () => (
          <>
            {item.date && <Paragraph>{item.date}</Paragraph>}
            {item.place && <IconText icon="marker" text={item.place} />}
            {item.country && item.countryCode && item.countryCode !== 'ON' && (
              <Flag country={item.country} countryCode={item.countryCode} />
            )}
          </>
        );
      case 'project-role':
        return () => (
          <>
            {item.occupation && <IconText icon="user" text={item.occupation} />}
            {item.careerLevel && <IconText icon="steps" text={item.careerLevel} />}
            {item.ycProjectRoleType && <IconText icon="badge" text={item.ycProjectRoleType} />}
            {item.skills && (
              <Col>
                <Row>
                  <Tags tags={item.skills} />
                </Row>
              </Col>
            )}
          </>
        );
      case 'cpd-pending':
        return () => (
          <>
            {item.user && <IconText icon="user" text={item.user} />}
            {item.points && <IconText icon="trophy" text={item.points} />}
            {item.category && <IconText icon="badge" text={item.category} />}
            {item.isFormal && <IconText icon="checkRectangle" text={item.isFormal} />}
            {item.createdAt && <IconText icon="calendar" text={item.createdAt} />}
          </>
        );
      default:
        return null;
    }
  };

  const renderActions = (item) => {
    if (typeof customRenderActions === 'function') {
      return (components) => customRenderActions({ item, ...components });
    }

    return null;
  };
  return (
    <>
      {items.map((item, index) => (
        <Box
          key={index}
          thumbnail={item.thumbnail}
          title={item.title}
          subtitle={item.subtitle}
          url={item.url}
          type={type}
          description={item.description}
          price={item.price || null}
          projectOpen={item.projectOpen || null}
          projectStatus={item.projectStatus || null}
          positionTimeStatus={item.positionTimeStatus || null}
          courseStatus={item.courseGeneralStatus}
          renderInfo={renderInfo(item)}
          renderActions={renderActions(item)}
          renderActionsFull={renderActionsFull || null}
          renderActionsLeft={renderActionsLeft || null}
          headerAs={headerAs}
        />
      ))}
    </>
  );
};

BoxList.propTypes = {
  type: PropTypes.oneOf(['event', 'course', 'organisation', 'ma', 'yc']).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      url: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      description: PropTypes.string,
      price: PropTypes.string,
      projectOpen: PropTypes.string,
      projectStatus: PropTypes.string,
      positionTimeStatus: PropTypes.string,
      courseStatus: PropTypes.string,
      date: PropTypes.string,
      place: PropTypes.string,
      country: PropTypes.string,
      countryCode: PropTypes.string,
      courses: PropTypes.string,
      events: PropTypes.string,
    }),
  ),
  alert: PropTypes.string,
  customRenderInfo: PropTypes.func,
  customRenderActions: PropTypes.func,
  renderActionsFull: PropTypes.bool,
  renderActionsLeft: PropTypes.bool,
  headerAs: PropTypes.string,
};

BoxList.defaultProps = {
  items: [],
  alert: '',
  customRenderInfo: null,
  customRenderActions: null,
  renderActionsFull: null,
  renderActionsLeft: null,
  headerAs: 'h3',
};

export default BoxList;
