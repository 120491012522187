import React from 'react';
import PropTypes from 'prop-types';

import StyledAlert from './Alert.styled';

function Alert({ type, children }) {
  return <StyledAlert type={type}>{children}</StyledAlert>;
}

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success', 'empty']).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default Alert;
