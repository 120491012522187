import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme }) => theme && css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: ${theme.colors.primary};
    border: 2px solid ${theme.colors.primary};
    overflow-wrap: anywhere;
    
    > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
      pointer-events: none;
    }
    
    ${({ large }) => large && css`
      width: 48px;
      height: 48px;
    `}
    
    ${({ rounded }) => rounded && css`
      border-radius: 2px;
    `}  
    
    ${({ left }) => left && css`
      justify-content: start;
    `}   

    ${({ outline }) => outline && css`
      background-color: transparent;
      color: ${theme.colors.primary};
      
      svg {
        fill: ${theme.colors.primary};
      }
    `}  
    
    ${({ content }) => content && css`
      width: 100%;
      height: auto;
      min-height: 40px;
      padding: 4px 12px;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      
      svg {
        margin-right: 12px;
      }
      
      ${({ large }) => large && css`
        min-height: 48px;
      `}  
    `}
    
    ${({ secondary }) => secondary && css`
      background-color: ${theme.colors.secondary};
      border-color: ${theme.colors.secondary};

      ${({ outline }) => outline && css`
        background-color: transparent;
        color: ${theme.colors.secondary};
        
        svg {
          fill: ${theme.colors.secondary};
        }
      `}  
    `}
    
    ${({ tertiary }) => tertiary && css`
      background-color: ${theme.colors.tertiary};
      border-color: ${theme.colors.tertiary};

      ${({ outline }) => outline && css`
        background-color: transparent;
        color: ${theme.colors.tertiary};
        
        svg {
          fill: ${theme.colors.tertiary};
        }
      `}  
    `}
    
    ${({ color }) => color && css`
      color: ${color};
      
      svg {
        fill: ${color};
      }

      ${({ outline }) => outline && css`
        color: ${color};
      `}  
    `}
    
    ${({ bg }) => bg && css`
      background-color: ${bg};
      border-color: ${bg};
      
      ${({ outline }) => outline && css`
        background-color: transparent;
      `}  
    `}
    
    ${({ disabled }) => disabled && css`
      &:hover {
        cursor: not-allowed;
      }
    `}           
  `}
`;
