export default {
  white: '#fff',

  gray: {
    100: '#f8f8f8',
    200: '#ededed',
    300: '#d3d3d6',
    400: '#b5b5b5',
    500: '#7d7d7d',
    600: '#515151',
    700: '#1d1c22',
  },

  primaries: {
    default: '#ec1d24',
    darker: '#ab1410',
  },

  get primary() {
    return this.primaries.default;
  },

  secondaries: {
    default: '#f59c00',
    lighter: '#f2bc5f',
    darker: '#cc8914',
    new: '#005858',
  },

  get secondary() {
    return this.secondaries.default;
  },

  tertiaries: {
    default: '#4f4e5c',
    darker: '#152338',
  },

  get tertiary() {
    return this.tertiaries.default;
  },

  alerts: {
    error: {
      text: '#7a142c',
      background: '#ffede1',
    },
    warning: {
      text: '#7a5a0d',
      background: '#fffada',
    },
    info: {
      text: '#053879',
      background: '#e8ffff',
    },
    success: {
      text: '#227211',
      background: '#f3fee0',
    },
    empty: {
      text: '#7d7d7d',
      background: '#ededed',
    },
  },
};
