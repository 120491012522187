import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSubRoutes, useExactRedirect } from 'hooks';
import { globalPropTypes } from 'helpers';

import { loadMaProfileCreator } from 'store/actionsCreators';

const Profile = ({ routes, getMaProfile }) => {
  useEffect(() => {
    getMaProfile();
  }, []);
  const [renderSubRoutes] = useSubRoutes(routes);
  const [renderExactRedirect] = useExactRedirect('/ma/profile', '/ma/profile/view');

  return (
    <>
      {renderSubRoutes()}
      {renderExactRedirect()}
    </>
  );
};

Profile.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  getMaProfile: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  routes: [],
};

const mapDispatchToProps = (dispatch) => ({
  getMaProfile: () => dispatch(loadMaProfileCreator()),
});

export default connect(null, mapDispatchToProps)(Profile);
