import React from 'react';
import PropTypes from 'prop-types';
import { Padding } from 'styled-components-spacing';
import { Button, Col, Heading, Row } from 'components';
import { getAvatar } from 'helpers';
import { Content, Thumbnail } from './ProfileHeader.styled';

function ProfileHeader({ title, image, actions }) {
  return (
    <Row valign="center">
      <Col size={{ xs: 1 / 1, md: 4 / 6 }}>
        <Content>
          <Thumbnail>
            <img src={getAvatar(image, 'profile')} alt="Organisation logo" />
          </Thumbnail>
          <Padding left={{ md: 24 }} right={{ md: 24 }}>
            <Heading size="h2">{title}</Heading>
          </Padding>
        </Content>
      </Col>
      <Col size={{ xs: 1 / 1, md: 2 / 6 }}>{actions({ Button })}</Col>
    </Row>
  );
}

ProfileHeader.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  actions: PropTypes.func,
};

ProfileHeader.defaultProps = {
  actions: null,
};

export default ProfileHeader;
