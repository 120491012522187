import { get } from 'lodash';

export const getSelectedAssessorByIndex = (selectedAssessors, index, idOnly = false) => {
  const assessor = get(selectedAssessors, index) || null;
  return assessor && idOnly ? assessor.value : assessor;
};

export const getFilteredAssessors = (assesors, selectedAssessors) => {
  return assesors.filter((a) => !selectedAssessors.some((sa) => sa.value === a.value));
};
