import React from 'react';
import PropTypes from 'prop-types';
import { getAvatar, isValidSocialUrl } from 'helpers';
import { Margin } from 'styled-components-spacing';
import { Link } from 'react-router-dom';
import { Button, Row } from 'components';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { iconList } from 'assets';
import { ReactSVG } from 'react-svg';
import {
  Wrapper,
  Thumbnail,
  Content,
  SocialWrapper,
  InstagramButton,
  TwitterButton,
  FacebookButton,
  LinkedinButton,
  TikTokButton,
} from './UserOverview.styled';

const UserOverview = ({ thumbnail, name, phone, email, userProfile }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Thumbnail>
        <img src={getAvatar(thumbnail, 'profile')} alt="user avatar" />
      </Thumbnail>
      <Content>
        {name && <p className="font-weight-bold">{name}</p>}

        {phone && (
          <Margin top="8">
            <a href={`tel:${phone}`}>{phone}</a>
          </Margin>
        )}

        {email && (
          <Margin top="8">
            <a href={`mailto:${email}`} className="link word-break">
              {email}
            </a>
          </Margin>
        )}

        <div className="user-overview-link">
          <Link className="link uppercase" to="/user/profile/edit">
            {t('page.user.profile.editProfileButton')}
          </Link>
        </div>
        <div className="user-overview-btn">
          <Button as={Link} to="/user/profile/edit">
            {t('page.user.profile.editProfileButton')}
          </Button>
        </div>
        <Row>
          <SocialWrapper top="16">
            {userProfile.twitter ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(userProfile.twitter)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterButton>
                  <ReactSVG src={iconList.twitter} />
                </TwitterButton>
              </SocialWrapper>
            ) : null}
            {userProfile.instagram ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(userProfile.instagram)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramButton>
                  <ReactSVG src={iconList.instagram} />
                </InstagramButton>
              </SocialWrapper>
            ) : null}
            {userProfile.facebook ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(userProfile.facebook)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookButton>
                  <ReactSVG src={iconList.facebookSquare} />
                </FacebookButton>
              </SocialWrapper>
            ) : null}
            {userProfile.linkedin ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(userProfile.linkedin)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinButton>
                  <ReactSVG src={iconList.linkedin} />
                </LinkedinButton>
              </SocialWrapper>
            ) : null}
            {userProfile.tiktok ? (
              <SocialWrapper
                as="a"
                href={isValidSocialUrl(userProfile.tiktok)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TikTokButton>
                  <ReactSVG src={iconList.tiktokWhite} />
                </TikTokButton>
              </SocialWrapper>
            ) : null}
          </SocialWrapper>
        </Row>
      </Content>
    </Wrapper>
  );
};

UserOverview.propTypes = {
  thumbnail: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  userProfile: PropTypes.shape({
    company: PropTypes.string,
    countryOfOrigin: PropTypes.shape({ name: PropTypes.string.isRequired }),
    industry: PropTypes.shape({ name: PropTypes.string.isRequired }),
    telephone: PropTypes.string,
    proffesionalTitle: PropTypes.shape({ name: PropTypes.number.isRequired }),
    jobTitle: PropTypes.shape({ name: PropTypes.number.isRequired }),
    tags: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.number.isRequired })),
    profilePrivacy: PropTypes.shape({ name: PropTypes.number.isRequired }),
    gender: PropTypes.string,
    shortBio: PropTypes.string,
    photo: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    twitter: PropTypes.string,
    tiktok: PropTypes.string,
    linkedin: PropTypes.string,
  }).isRequired,
};

UserOverview.defaultProps = {
  thumbnail: '',
  name: '',
  phone: '',
  email: '',
};

const mapStateToProps = ({ user }) => ({
  userProfile: user.user.userProfile,
});

export default connect(mapStateToProps)(UserOverview);
