import styled, { css } from 'styled-components';
import { mt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  ${({ open }) => open && css`
    > ${Content} {
      display: block;
    }

    > ${Action} {
      ${Arrow} {
        svg {
          transform-origin: center;
          transform: rotate(180deg);
        }
      }
    }
  `}
  
  ${({ openbreakpoint }) => openbreakpoint && css`
    ${breakpoint(openbreakpoint)`
      ${css`
        > ${Content} {
          display: block;
        }
    
        > ${Action} {
          > ${Arrow} {
            display: none;
          }
        }
      `}
    `}
  `}
`;

export const Action = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Arrow = styled.span`
  ${({ theme }) => theme && css`
    align-self: flex-end;
    margin-bottom: 4px;
    padding-left: 8px;

    svg {
      width: 10px;
      height: 6px;
      fill: ${theme.colors.secondary};
      pointer-events: none;
    }
  `}
`;

export const Content = styled.div`
  ${mt(32)};

  display: none;
`;