export const initialState = {
  fields: {
    name: '',
    email: '',
    telephone: '',
    subject: '',
    message: '',
    agreement: false,
  },
  errors: {},
  subjects: [],
};

const SET_FIELD = 'SET_FIELD';
const SET_ERRORS = 'SET_ERRORS';
const SET_SUBJECTS = 'SET_SUBJECTS';

export const setFieldCreator = (payload) => ({ type: SET_FIELD, payload });
export const setErrorsCreator = (payload) => ({ type: SET_ERRORS, payload });
export const setSubjectsCreator = (payload) => ({ type: SET_SUBJECTS, payload });

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
      };
    default:
      return state;
  }
};
