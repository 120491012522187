import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import { Wrapper, Icon, Text } from './FileInput.styled';
import { iconList as icons } from '../../assets';

const FileInput = React.forwardRef(({ placeholder, fileName, clear, ...res }, ref) => {
  if (fileName) {
    return (
      <Wrapper as={'div'} remove>
        <span>{fileName}</span>
        <Icon onClick={clear}>
          <ReactSVG src={icons.close} />
        </Icon>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <input type="file" {...res} ref={ref} />
      <Text>{fileName && fileName.length ? fileName : placeholder}</Text>
      <Icon>
        <ReactSVG src={icons.file} />
      </Icon>
    </Wrapper>
  );
});

FileInput.propTypes = {
  placeholder: PropTypes.string,
  fileName: PropTypes.string,
  clear: PropTypes.func.isRequired,
};

FileInput.defaultProps = {
  placeholder: '',
  fileName: null,
};

export default FileInput;
