export const defaultState = {
  id: '',
  name: '',
  initials: '',
  country: null,
  imageFile: null,
  address: {
    line1: '',
    line2: '',
    postCode: '',
    city: '',
    country: null,
  },
  telephone: '',
  email: '',
  website: '',
  shortDescription: '',
  fullDescription: '',
  individualMembershipsDescription: '',
  organisationMembershipsDescription: '',
  cbSameAsContactData: '',
  cbName: '',
  cbInitials: '',
  cbTelephone: '',
  cbEmail: '',
  cbWebsite: '',
  cbAddress: {
    line1: '',
    line2: '',
    postCode: '',
    city: '',
    country: null,
  },
  ycName: '',
  ycInitials: '',
  ycTelephone: '',
  ycEmail: '',
  ycWebsite: '',
  ycAddress: {
    line1: '',
    line2: '',
    postCode: '',
    city: '',
    country: null,
  },
};

const SET_EDIT_DATA = 'SET_EDIT_DATA';
const UPDATE_FIELD = 'UPDATE_FIELD';
const SET_CB_CONTACT = 'SET_CB_CONTACT';
const SET_YC_CONTACT = 'SET_YC_CONTACT';

export const setEditDataCreator = (payload) => ({ type: SET_EDIT_DATA, payload });
export const updateFieldCreator = (payload) => ({ type: UPDATE_FIELD, payload });
export const setCbContactCreator = (payload) => ({ type: SET_CB_CONTACT, payload });
export const setYcContactCreator = (payload) => ({ type: SET_YC_CONTACT, payload });

export default function (state = defaultState, action) {
  if (action.type === SET_EDIT_DATA) {
    return {
      ...state,
      ...action.payload,
      address: { ...state.address, ...action.payload.address },
      cbAddress: { ...state.cbAddress, ...action.payload.cbAddress },
      ycAddress: { ...state.ycAddress, ...action.payload.ycAddress },
    };
  }

  if (action.type === UPDATE_FIELD) {
    return { ...state, [action.payload.name]: action.payload.value };
  }

  if (action.type === SET_CB_CONTACT) {
    return {
      ...state,
      ...action.payload,
      cbAddress: { ...state.cbAddress, ...action.payload.cbAddress },
    };
  }

  if (action.type === SET_YC_CONTACT) {
    return {
      ...state,
      ...action.payload,
      ycAddress: { ...state.ycAddress, ...action.payload.ycAddress },
    };
  }

  return state;
}
