import React from 'react';
import { Container, Header, Cms } from 'components';
import { Margin } from 'styled-components-spacing';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.cookiePolicy',
    url: '',
  },
];

const CookiePolicy = () => {
  return (
    <>
      <Header breadcrumbs={breadcrumbs} title="Cookie Policy" />
      <Margin vertical={{ xs: 32, md: 48 }}>
        <Container>
          <Cms>
            <p>
              A Cookie is a small piece of data (text file) that a website asks your browser to
              store on your device.
              <br /> A Cookie doesn’t harm your device and it cannot be used to distribute malware.
            </p>
            <p>
              With Cookies IPMA can improve the user experience, but also use them to measure
              traffic in its online services, to modify content based on browsing actions and
              previous visits, or for advertising purposes.
            </p>
            <p>
              Your browser is likely configured to automatically accept Cookies, but you can change
              the options, as well as delete the Cookies in your browser&#39;s settings.
            </p>
          </Cms>
        </Container>
      </Margin>
    </>
  );
};

export default CookiePolicy;
