import theme from '../../theme';

const config = {
  container: () => ({
    position: 'relative',
    width: '100%',
  }),
  control: (prev, state) => {
    const { small } = state.selectProps;
    const config = {};

    if (small) {
      config.height = 40;
    }

    return {
      ...prev,
      backgroundColor: state.isDisabled ? theme.colors.gray[200] : theme.colors.gray[100],
      borderWidth: 0,
      borderRadius: 2,
      boxShadow: 'none',
      outline: state.isFocused ? `1px solid ${theme.colors.secondary}` : '1px solid transparent',
      outlineOffset: -1,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      '&:hover': {
        boxShadow: 0,
      },
      ...config,
    };
  },
  valueContainer: (prev, state) => {
    const { small } = state.selectProps;
    const config = {};

    if (small) {
      config.height = 40;
    }

    return {
      ...prev,
      height: state.isMulti ? 'auto' : 48,
      paddingTop: theme.spacing[8],
      paddingBottom: theme.spacing[8],
      paddingRight: 0,
      paddingLeft: theme.spacing[16],
      flexWrap: state.isMulti ? 'wrap' : 'nowrap',
      ...config,
    };
  },
  input: (prev) => {
    return {
      ...prev,
      padding: 0,
      margin: 0,
      input: {
        height: 'auto',
      },
    };
  },
  placeholder: () => ({
    fontSize: theme.font.size.pixel('16'),
    fontWeight: theme.font.weight.regular,
    color: theme.colors.gray[500],
  }),
  singleValue: () => ({
    fontSize: theme.font.size.pixel('16'),
    fontWeight: theme.font.weight.regular,
    color: theme.colors.gray[500],
    padding: 0,
  }),
  dropdownIndicator: (prev, state) => {
    const { small } = state.selectProps;
    const config = {};

    if (small) {
      config.width = 40;
      config.minHeight = 40;
    }

    return {
      ...prev,
      width: 48,
      height: '100%',
      minHeight: 48,
      backgroundColor: state.isDisabled ? theme.colors.secondaries.lighter : theme.colors.secondary,
      padding: 0,
      display: 'flex',
      borderRadius: '0 2px 2px 0',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        fill: theme.colors.white,
        width: 20,
        height: 20,
      },
      ...config,
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (prev) => ({
    ...prev,
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.secondary,
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: 'none',
    zIndex: 10,
  }),
  menuPortal: (prev) => ({
    ...prev,
    zIndex: 100,
  }),
  menuList: (prev) => ({
    ...prev,
    padding: 0,
  }),
  option: (prev, state) => ({
    ...prev,
    padding: '12px 0 12px 16px',
    backgroundColor:
      state.isActive || state.isSelected ? theme.colors.secondary : theme.colors.gray[100],
    color: state.isActive || state.isSelected ? theme.colors.white : theme.colors.gray[500],
    '&:hover': {
      backgroundColor: theme.colors.secondary,
      color: theme.colors.white,
    },
  }),
  multiValueLabel: (prev) => ({
    ...prev,
    maxWidth: 250,
  }),
  group: (prev) => ({
    ...prev,
    padding: 0,
  }),
  groupHeading: (prev) => ({
    ...prev,
    paddingTop: '4px',
    paddingBottom: '4px',
    backgroundColor: theme.colors.secondary,
    color: '#fff',
    marginBottom: '0',
  }),
};

export default config;
