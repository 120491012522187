import {
  SET_GLOBAL_COUNTRIES,
  SET_GLOBAL_AVAILABLE_MA,
  SET_GLOBAL_AVAILABLE_YC,
  SET_GLOBAL_JOBS_TITLES,
  SET_GLOBAL_PROFILE_PRIVACY,
  SET_GLOBAL_GENDER,
  SET_GLOBAL_COURSE_DELIVERY_MODES,
  SET_GLOBAL_COURSE_CLOUD_TAGS,
  SET_GLOBAL_COURSE_PRACTICE_COMPETENCIES,
  SET_GLOBAL_COURSE_PEOPLE_COMPETENCIES,
  SET_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES,
  SET_GLOBAL_COURSE_INSTRUCTION_FORMATS,
  SET_GLOBAL_COURSE_COURSE_TYPES,
  SET_GLOBAL_LANGUAGES,
  SET_GLOBAL_MA_ROLES,
  SET_GLOBAL_CURRENCIES,
  SET_GLOBAL_EVENT_DELIVERY_MODE,
  SET_GLOBAL_TIMEZONES,
  SET_GLOBAL_CONTACT_SUBJECTS,
  SET_GLOBAL_INDUSTRIES,
  SET_GLOBAL_ORGANISATION_ROLES,
  SET_GLOBAL_PROFESSIONAL_TITLES,
  SET_GLOBAL_IPMA_ROLES,
  SET_GLOBAL_YC_ROLES,
  SET_GLOBAL_PROJECT_CAREERLEVEL,
  SET_GLOBAL_PROJECT_SKILLS,
  SET_GLOBAL_PROJECT_ROLES,
  SET_GLOBAL_ABOUT_IPMA,
  SET_GLOBAL_AVAILABLE_CB,
} from '../constants';

const initialState = {
  countries: [],
  availableMA: [],
  availableCB: [],
  availableYC: [],
  professionalTitles: [],
  jobsTitles: [],
  profilePrivacy: [],
  gender: [],
  languages: [],
  currencies: [],
  timezones: [],
  course: {
    deliveryModes: [],
    courseTypes: [],
    instructionFormats: [],
    perspectiveCompetencies: [],
    peopleCompetencies: [],
    practiceCompetencies: [],
    cloudTags: [],
  },
  ma: {
    roles: [],
  },
  organisation: {
    roles: [],
  },
  ipma: {
    roles: [],
  },
  yc: {
    roles: [],
  },
  event: {
    deliveryModes: [],
  },
  contactSubjects: [],
  industries: [],
  projectCareerLevels: [],
  projectSkills: [],
  projectRoles: [],
  aboutIpma: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_COUNTRIES:
      return { ...state, countries: action.payload };
    case SET_GLOBAL_AVAILABLE_MA:
      return { ...state, availableMA: action.payload };
    case SET_GLOBAL_AVAILABLE_CB:
      return { ...state, availableCB: action.payload };
    case SET_GLOBAL_AVAILABLE_YC:
      return { ...state, availableYC: action.payload };
    case SET_GLOBAL_PROFESSIONAL_TITLES:
      return { ...state, professionalTitles: action.payload };
    case SET_GLOBAL_JOBS_TITLES:
      return { ...state, jobsTitles: action.payload };
    case SET_GLOBAL_PROFILE_PRIVACY:
      return { ...state, profilePrivacy: action.payload };
    case SET_GLOBAL_GENDER:
      return { ...state, gender: action.payload };
    case SET_GLOBAL_LANGUAGES:
      return { ...state, languages: action.payload };
    case SET_GLOBAL_COURSE_DELIVERY_MODES:
      return { ...state, course: { ...state.course, deliveryModes: action.payload } };
    case SET_GLOBAL_COURSE_COURSE_TYPES:
      return { ...state, course: { ...state.course, courseTypes: action.payload } };
    case SET_GLOBAL_COURSE_INSTRUCTION_FORMATS:
      return { ...state, course: { ...state.course, instructionFormats: action.payload } };
    case SET_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES:
      return { ...state, course: { ...state.course, perspectiveCompetencies: action.payload } };
    case SET_GLOBAL_COURSE_PEOPLE_COMPETENCIES:
      return { ...state, course: { ...state.course, peopleCompetencies: action.payload } };
    case SET_GLOBAL_COURSE_PRACTICE_COMPETENCIES:
      return { ...state, course: { ...state.course, practiceCompetencies: action.payload } };
    case SET_GLOBAL_COURSE_CLOUD_TAGS:
      return { ...state, course: { ...state.course, cloudTags: action.payload } };
    case SET_GLOBAL_MA_ROLES:
      return { ...state, ma: { ...state.ma, roles: action.payload } };
    case SET_GLOBAL_IPMA_ROLES:
      return { ...state, ipma: { ...state.ipma, roles: action.payload } };
    case SET_GLOBAL_YC_ROLES:
      return { ...state, yc: { ...state.yc, roles: action.payload } };
    case SET_GLOBAL_ORGANISATION_ROLES:
      return { ...state, organisation: { ...state.organisation, roles: action.payload } };
    case SET_GLOBAL_CURRENCIES:
      return { ...state, currencies: action.payload };
    case SET_GLOBAL_EVENT_DELIVERY_MODE:
      return { ...state, event: { ...state.event, deliveryModes: action.payload } };
    case SET_GLOBAL_TIMEZONES:
      return { ...state, timezones: action.payload };
    case SET_GLOBAL_CONTACT_SUBJECTS:
      return { ...state, contactSubjects: action.payload };
    case SET_GLOBAL_INDUSTRIES:
      return { ...state, industries: action.payload };
    case SET_GLOBAL_PROJECT_CAREERLEVEL:
      return { ...state, projectCareerLevels: action.payload };
    case SET_GLOBAL_PROJECT_SKILLS:
      return { ...state, projectSkills: action.payload };
    case SET_GLOBAL_PROJECT_ROLES:
      return { ...state, projectRoles: action.payload };
    case SET_GLOBAL_ABOUT_IPMA:
      return { ...state, aboutIpma: action.payload };
    default:
      return state;
  }
};
