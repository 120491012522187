import Yc from '../containers/yc/Yc';
import Dashboard from '../containers/yc/Dashboard/Dashboard';
import Profile from '../containers/yc/Profile/Profile';
import Edit from '../containers/yc/Profile/Edit/Edit';
import View from '../containers/yc/Profile/View/View';
import People from '../containers/yc/People/People';
import Users from '../containers/yc/People/Users/Users';
import List from '../containers/yc/People/List/List';
import Add from '../containers/yc/Add/Add';
import Events from '../containers/yc/Events/Events';
import Create from '../containers/yc/Events/Create/Create';
import EventEdit from '../containers/yc/Events/Edit/Edit';
import Ongoing from '../containers/yc/Events/Ongoing/Ongoing';
import Completed from '../containers/yc/Events/Completed/Completed';
import Cancelled from '../containers/yc/Events/Cancelled/Cancelled';
import Duplicated from '../containers/yc/Events/Duplicated/Duplicated';
import Projects from '../containers/yc/Projects/Projects';
import ProjectsPlanning from '../containers/yc/Projects/Planning/Planning';
import ProjectsRunning from '../containers/yc/Projects/Running/Running';
import ProjectsCompleted from '../containers/yc/Projects/Completed/Completed';
import ProjectsCreate from '../containers/yc/Projects/Create/Create';
import ProjectsEdit from '../containers/yc/Projects/Edit/Edit';
import ProjectRoles from '../containers/yc/ProjectRoles/ProjectRoles';
import ProjectRolesOpen from '../containers/yc/ProjectRoles/Open/Open';
import ProjectRolesClosed from '../containers/yc/ProjectRoles/Closed/Closed';
import ProjectRolesArchived from '../containers/yc/ProjectRoles/Archived/Archived';
import ProjectRolesCreate from '../containers/yc/ProjectRoles/Create/Create';
import ProjectRolesEdit from '../containers/yc/ProjectRoles/Edit/Edit';
import Applications from '../containers/yc/Applications/Applications';
import ApplicationsWaiting from '../containers/yc/Applications/Waiting/Waiting';
import ApplicationsAccepted from '../containers/yc/Applications/Accepted/Accepted';
import ApplicationsRejected from '../containers/yc/Applications/Rejected/Rejected';
import ApplicationsAutoRejected from '../containers/yc/Applications/AutoRejected/AutoRejected';
import ApplicationsOnHold from '../containers/yc/Applications/OnHold/OnHold';
import YoungCrew from '../containers/yc/People/YcMembers/List';

const YC = 'ycBase';
const DASHBOARD = 'ycDashboard';
const PROFILE = 'ycProfile';
const PROFILE_EDIT = 'ycProfilEdit';
const PROFILE_VIEW = 'ycProfileView';
const PEOPLE = 'ycPeople';
const USERS_LIST = 'ycUsersList';
const PEOPLE_LIST = 'ycPeopleList';
const PEOPLE_ADD = 'ycPeopleAdd';
const EVENTS = 'ycEvents';
const EVENTS_ONGOING = 'ycEventsOngoing';
const EVENTS_COMPLETED = 'ycEventsCompleted';
const EVENTS_CANCELLED = 'ycEventsCancelled';
const EVENTS_DUPLICATED = 'ycEventsDuplicated';
const EVENTS_CREATE = 'ycEventsCreate';
const EVENTS_EDIT = 'ycEventsEdit';
const PROJECTS = 'ycProjects';
const PROJECTS_PLANNING = 'ycProjectsPlaning';
const PROJECTS_RUNNING = 'ycProjectsRunning';
const PROJECTS_COMPLETED = 'ycProjectsCompleted';
const PROJECTS_CREATE = 'ycProjectsCreate';
const PROJECTS_EDIT = 'ycProjectsEdit';
const PROJECT_ROLES = 'ycProjectRoles';
const PROJECT_ROLES_OPEN = 'ycProjectRolesOpen';
const PROJECT_ROLES_CLOSED = 'ycProjectRolesClosed';
const PROJECT_ROLES_ARCHIVED = 'ycProjectRolesArchived';
const PROJECT_ROLES_CREATE = 'ycProjectRolesCreate';
const PROJECT_ROLES_EDIT = 'ycProjectRolesEdit';
const APPLICATIONS = 'ycApplications';
const APPLICATIONS_WAITING = 'ycApplicationsWaiting';
const APPLICATIONS_ACCEPTED = 'ycApplicationsAccepted';
const APPLICATIONS_REJECTED = 'ycApplicationsRejected';
const APPLICATIONS_AUTO_REJECTED = 'ycApplicationsAutoRejected';
const APPLICATIONS_ON_HOLD = 'ycApplicationsOnHold';
const PEOPLE_YC = 'ycPeopleYc';

export default [
  {
    key: YC,
    path: '/yc',
    component: Yc,
    exact: false,
    withAuth: true,
    permissions: {
      appType: 'yc',
    },
    routes: [
      {
        key: DASHBOARD,
        path: '/yc',
        component: Dashboard,
        exact: true,
        withAuth: false,
      },
      {
        key: PROFILE,
        path: '/yc/profile',
        component: Profile,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: PROFILE_EDIT,
            path: '/yc/profile/edit',
            component: Edit,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['yc_edit_yc_profile'],
            },
          },
          {
            key: PROFILE_VIEW,
            path: '/yc/profile/view',
            component: View,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['yc_profile'],
            },
          },
        ],
      },
      {
        key: PEOPLE_ADD,
        path: '/yc/people/add',
        component: Add,
        exact: false,
        withAuth: false,
      },
      {
        key: PEOPLE,
        path: '/yc/people',
        component: People,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: USERS_LIST,
            path: '/yc/people/users',
            component: Users,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['yc_manage_members_yc_user_list'],
            },
          },
          {
            key: PEOPLE_LIST,
            path: '/yc/people/list',
            component: List,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['yc_manage_members_people'],
            },
          },
          {
            key: PEOPLE_YC,
            path: '/yc/people/members',
            component: YoungCrew,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['ma_manage_members_people'],
            },
          },
        ],
      },
      {
        key: EVENTS,
        path: '/yc/events',
        component: Events,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: EVENTS_ONGOING,
            path: '/yc/events/ongoing',
            component: Ongoing,
            exact: false,
            withAuth: false,
          },
          {
            key: EVENTS_COMPLETED,
            path: '/yc/events/completed',
            component: Completed,
            exact: false,
            withAuth: false,
          },
          {
            key: EVENTS_CANCELLED,
            path: '/yc/events/cancelled',
            component: Cancelled,
            exact: false,
            withAuth: false,
          },
          {
            key: EVENTS_DUPLICATED,
            path: '/yc/events/duplicated',
            component: Duplicated,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: EVENTS_CREATE,
        path: '/yc/event/create/:id',
        component: Create,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['yc_events_create_event'],
        },
      },
      {
        key: EVENTS_CREATE,
        path: '/yc/event/create',
        component: Create,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['yc_events_create_event'],
        },
      },
      {
        key: EVENTS_EDIT,
        path: '/yc/event/:id/edit',
        component: EventEdit,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['yc_events_edit_event'],
        },
      },
      {
        key: PROJECTS,
        path: '/yc/projects',
        component: Projects,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['yc_project_manage'],
        },
        routes: [
          {
            key: PROJECTS_PLANNING,
            path: '/yc/projects/planning',
            component: ProjectsPlanning,
            exact: false,
            withAuth: false,
          },
          {
            key: PROJECTS_RUNNING,
            path: '/yc/projects/running',
            component: ProjectsRunning,
            exact: false,
            withAuth: false,
          },
          {
            key: PROJECTS_COMPLETED,
            path: '/yc/projects/completed',
            component: ProjectsCompleted,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: PROJECTS_CREATE,
        path: '/yc/project/create',
        component: ProjectsCreate,
        exact: false,
        withAuth: false,
      },
      {
        key: PROJECTS_EDIT,
        path: '/yc/project/:id/edit',
        component: ProjectsEdit,
        exact: false,
        withAuth: false,
      },
      {
        key: PROJECT_ROLES,
        path: '/yc/project-roles/:id',
        component: ProjectRoles,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: PROJECT_ROLES_OPEN,
            path: '/yc/project-roles/:id/open',
            component: ProjectRolesOpen,
            exact: false,
            withAuth: false,
          },
          {
            key: PROJECT_ROLES_CLOSED,
            path: '/yc/project-roles/:id/closed',
            component: ProjectRolesClosed,
            exact: false,
            withAuth: false,
          },
          {
            key: PROJECT_ROLES_ARCHIVED,
            path: '/yc/project-roles/:id/archived',
            component: ProjectRolesArchived,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: PROJECT_ROLES_CREATE,
        path: '/yc/project-role/:id/create',
        component: ProjectRolesCreate,
        exact: false,
        withAuth: false,
      },
      {
        key: PROJECT_ROLES_EDIT,
        path: '/yc/project-role/:id/edit/:roleId',
        component: ProjectRolesEdit,
        exact: false,
        withAuth: false,
      },
      {
        key: APPLICATIONS,
        path: '/yc/applications/:id',
        component: Applications,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: APPLICATIONS_WAITING,
            path: '/yc/applications/:id/waiting',
            component: ApplicationsWaiting,
            exact: false,
            withAuth: false,
          },
          {
            key: APPLICATIONS_ACCEPTED,
            path: '/yc/applications/:id/accepted',
            component: ApplicationsAccepted,
            exact: false,
            withAuth: false,
          },
          {
            key: APPLICATIONS_REJECTED,
            path: '/yc/applications/:id/rejected',
            component: ApplicationsRejected,
            exact: false,
            withAuth: false,
          },
          {
            key: APPLICATIONS_AUTO_REJECTED,
            path: '/yc/applications/:id/auto-rejected',
            component: ApplicationsAutoRejected,
            exact: false,
            withAuth: false,
          },
          {
            key: APPLICATIONS_ON_HOLD,
            path: '/yc/applications/:id/on-hold',
            component: ApplicationsOnHold,
            exact: false,
            withAuth: false,
          },
        ],
      },
    ],
  },
];
