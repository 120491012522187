import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';

import reducer from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  const store = createStore(
    (state, action) => {
      if (action.type === 'RESET_APP') {
        state = undefined;
      }

      return reducer(state, action);
    },
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducer', () => {
      // eslint-disable-next-line
      const nextRootReducer = require('./reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
};
