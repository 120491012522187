import {
  SET_MY_LOCAL_LIBRARY
} from '../constants';

const initialState = {
  libraryList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_LOCAL_LIBRARY:
      return {
        ...state,
        libraryList: action.payload.all ?? [],
      };
    default:
      return state;
  }
};
