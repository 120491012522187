import { useMemo } from 'react';
import { checkPermissions, findRoute, getFlatRoutes } from 'helpers';
import { get, isEmpty } from 'lodash';

const usePermissionTabs = (initialTabs, routes, userPermissions, bar = {}) => {
  const flatRoutes = useMemo(() => getFlatRoutes(routes), routes);
  const tabs = initialTabs.filter((tab) => {
    const routeData = findRoute(flatRoutes, tab.path);
    return routeData ? checkPermissions(routeData.permissions, userPermissions) : true;
  });

  const combineTabs = useMemo(
    () =>
      tabs.map((tab) => {
        tab.count = get(bar, `${tab.key}`) || 0;
        return tab;
      }),
    [bar],
  );

  return [combineTabs, !isEmpty(tabs) ? tabs[0].path : null];
};

export default usePermissionTabs;
