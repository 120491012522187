import { takeLatest, call, put, select, cancelled } from 'redux-saga/effects';

import * as courseService from 'api/services/course';

import {
  setNewLoadingCreator,
  removeLoadingCreator,
  setCreateCourseErrorsCreator,
  setNewAlertCreator,
} from 'store/actionsCreators';
import {
  CREATE_COURSE,
  CREATE_SUBCOURSE,
  LOAD_SUBCOURSE_PARENT,
  UPDATE_COURSE_DRAFT,
  UPDATE_SUBMITTED_TEMPLATE_COURSE,
} from 'store/constants';
import { get, isEmpty, map } from 'lodash';
import i18next from 'i18next';
import { parseDate } from 'helpers';
import { parseNestedErrors } from '../helpers/parseErrors';

function* createCourse({ payload, asDraft, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const type = asDraft ? 'drafts' : 'submittedTemplates';
    const response = yield call(courseService.create, payload, type);
    if (response.ok) {
      const {
        results: {
          result: { entity: course },
        },
      } = yield response.json();
      // TODO set course response to correct reducer
      if (asDraft) {
        yield put(
          setNewAlertCreator({
            type: 'organisation',
            status: 'success',
            text: i18next
              .t('courses.replace.createDraftCourseSuccess')
              .replace(':courseName', course.name || ''),
          }),
        );
      } else {
        yield put(
          setNewAlertCreator({
            type: 'organisation',
            status: 'success',
            text: i18next
              .t('courses.replace.createSuccessCourse')
              .replace(':courseName', course.name || ''),
          }),
        );
      }
      const currentOrganisation = yield select(({ user }) => user.currentOrganisation);
      history.push(`/organisation/${currentOrganisation}/courses`);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      yield put(setCreateCourseErrorsCreator(parseNestedErrors(errors)));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );

    console.log('[POST] create course error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* updateCourseDraft({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(courseService.updateDraft, payload);
    if (response.ok) {
      const {
        results: {
          result: { entity: course },
        },
      } = yield response.json();

      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'success',
          text: i18next.t('courses.replace.draftSuccessCourse').replace(':courseName', course.name),
        }),
      );
      const currentOrganisation = yield select(({ user }) => user.currentOrganisation);
      history.push(`/organisation/${currentOrganisation}/courses/drafts`);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      yield put(setCreateCourseErrorsCreator(parseNestedErrors(errors)));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );

    console.log('[POST] update course draft error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* updateSubmittedTemplateCourse({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(courseService.updateSubmittedTemplateCourse, payload);
    if (response.ok) {
      const {
        results: {
          result: { entity: course },
        },
      } = yield response.json();

      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'success',
          text: i18next
            .t('courses.replace.submittedTemplateCourseUpdateSuccess')
            .replace(':courseName', course.name),
        }),
      );
      history.push(
        `/organisation/${window.localStorage.getItem(
          'currentOrganization',
        )}/courses/submitted-templates`,
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      yield put(setCreateCourseErrorsCreator(parseNestedErrors(errors)));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] update submitted template course error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadSubcourseParent({ payload: id, history, cb }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(courseService.getSubcourseParent, id);

    if (response.ok) {
      const {
        results: { result: item },
      } = yield response.json();

      const mapChoose = (n) => ({
        label: n.name,
        value: n.id,
      });

      const mapChooseLabelAsValue = (n) => ({
        label: n.name,
        value: n.name,
      });

      const mapChooseId = (n) => n.id;
      const mapChooseIdAsLabel = (n) => n.name;

      const course = {
        id: item.id,
        name: item.name || '',
        perspectiveCompetencies: map(item.competency, mapChoose),
        peopleCompetencies: map(item.peopleCompetency, mapChoose),
        practiceCompetencies: map(item.practiceCompetency, mapChoose),
        customCompetencies: map(item.customCourseCompetencies, mapChooseLabelAsValue),
      };

      const fields = {
        name: '',
        startDate: item.startDate ? new Date(item.startDate) : '',
        endDate: item.endDate ? new Date(item.endDate) : '',
        timezone: get(item, 'timezone.id') || '',
        line1: get(item, 'address.line1') || '',
        line2: get(item, 'address.line2') || '',
        city: get(item, 'address.city') || '',
        postCode: get(item, 'address.postCode') || '',
        country: get(item, 'address.country.id') || '',
        shortDescription: item.shortDescription || '',
        description: item.description || '',
        programmeDescription: item.programmeDescription || '',
        tags: map(item.tags, mapChooseId),
        perspectiveCompetencies: map(item.competency, mapChooseId),
        peopleCompetencies: map(item.peopleCompetency, mapChooseId),
        practiceCompetencies: map(item.practiceCompetency, mapChooseId),
        customCompetencies: map(item.customCourseCompetencies, mapChooseIdAsLabel),
        duration: item.duration ? parseFloat(item.duration).toFixed(2) : '',
        learningOutcomes: map(item.courseLearningOutcomes, (n) => n.description),
      };

      cb(course, fields);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (error.response) {
      const { code, errors } = yield error.response.json();

      if (code === 400 && !isEmpty(errors)) {
        history.push(
          `/organisation/${window.localStorage.getItem(
            'currentOrganization',
          )}/courses/course-templates`,
        );

        yield put(
          setNewAlertCreator({
            type: 'organisation',
            status: 'error',
            text: errors[0],
          }),
        );
      }
    }

    console.log('[GET] load subcourse parent error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* createSubcourse({ payload, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CREATE_SUBCOURSE' }));

    const { course, fields } = payload;

    const body = new FormData();

    body.append('parent', course.id);
    body.append('name', fields.name);

    body.append('timezone', fields.timezone);
    body.append('doesntApply', fields.doesntApply ? 1 : 0);
    body.append('onDemand', fields.onDemand);

    body.append(
      'startDate',
      fields.onDemand ? '' : parseDate(fields.startDate, 'YYYY-MM-DDTHH:mm'),
    );
    body.append('endDate', fields.onDemand ? '' : parseDate(fields.endDate, 'YYYY-MM-DDTHH:mm'));

    if (!fields.doesntApply) {
      body.append('address[line1]', fields.line1);
      body.append('address[line2]', fields.line2);
      body.append('address[city]', fields.city);
      body.append('address[postCode]', fields.postCode);
      body.append('address[country]', fields.country);
    }

    body.append('shortDescription', fields.shortDescription);
    body.append('description', fields.description);
    body.append('programmeDescription', fields.programmeDescription);
    body.append('duration', fields.duration);

    fields.tags.forEach((item, index) => {
      body.append(`tags[${index}]`, item);
    });

    fields.perspectiveCompetencies.forEach((item, index) => {
      body.append(`competency[${index}]`, item);
    });

    fields.peopleCompetencies.forEach((item, index) => {
      body.append(`peopleCompetency[${index}]`, item);
    });

    fields.practiceCompetencies.forEach((item, index) => {
      body.append(`practiceCompetency[${index}]`, item);
    });

    fields.customCompetencies.forEach((item, index) => {
      body.append(`customCourseCompetencies[${index}][name]`, item);
    });

    fields.learningOutcomes.forEach((item, index) => {
      body.append(`courseLearningOutcomes[${index}][description]`, item);
    });

    const response = yield call(courseService.createSubcourse, body);

    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'success',
          text: i18next
            .t('courses.replace.publishSuccessCourse')
            .replace(':courseName', course.name),
        }),
      );
      const currentOrganisation = yield select(({ user }) => user.currentOrganisation);
      history.push(`/organisation/${currentOrganisation}courses/published`);
    }
    yield put(removeLoadingCreator({ type: 'CREATE_SUBCOURSE' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'CREATE_SUBCOURSE' }));
    if (error.response) {
      const { errors } = yield error.response.json();
      if (!isEmpty(errors)) {
        setErrors(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        );
      }
    } else {
      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'error',
          text: i18next.t('global.error'),
        }),
      );
    }

    console.log('[POST] create subcourse error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'CREATE_SUBCOURSE' }));
  }
}

export default function* watchCourse() {
  yield takeLatest(CREATE_COURSE, createCourse);
  yield takeLatest(LOAD_SUBCOURSE_PARENT, loadSubcourseParent);
  yield takeLatest(CREATE_SUBCOURSE, createSubcourse);
  yield takeLatest(UPDATE_COURSE_DRAFT, updateCourseDraft);
  yield takeLatest(UPDATE_SUBMITTED_TEMPLATE_COURSE, updateSubmittedTemplateCourse);
}
