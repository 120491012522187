import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components';
import { useTranslation } from 'react-i18next';
import { Wrapper, Content, Button, CouponStyle } from './ThumbnailCardCoupon.styled';

const ThumbnailCardCoupon = ({ coupon, link }) => {
  const { t } = useTranslation();

  const gotoShop = () => {
    window.open(link, '_blank').focus();
  };

  return (
    <Wrapper>
      <Content>
        <CouponStyle as="h3" size="h6">
          {coupon}
        </CouponStyle>
        <Button onClick={gotoShop}>{t('page.user.dashboard.gotoShop')}</Button>
      </Content>
    </Wrapper>
  );
};

ThumbnailCardCoupon.propTypes = {
  coupon: PropTypes.string,
  link: PropTypes.string,
};

ThumbnailCardCoupon.defaultProps = {
  coupon: '',
  link: '',
};

export default ThumbnailCardCoupon;
