import styled, { css } from 'styled-components';
import { mx, mt } from 'styled-components-spacing';

export const Container = styled.div`
  ${({ styled }) =>
    styled &&
    css`
      display: flex;
      justify-content: center;
      ${mt(32)}
    `}
`;

export const List = styled.ul`
  display: flex;
  margin: 0 -6px;
`;

export const ListItem = styled.li`
  ${({ theme }) =>
    theme &&
    css`
      ${mx(6)}
      button {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 32px;
        height: 32px;
        background-color: ${theme.colors.gray[100]};
        border-radius: 2px;

        font-size: ${theme.font.size.pixel(18)};
        font-weight: ${theme.font.weight.bold};
        color: ${theme.colors.tertiary};

        > div > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          width: 9px;
          height: 13px;
        }
      }
    `}

    ${({ theme, disabled }) =>
      theme &&
      disabled &&
      css`
        opacity: 0.7;
        button {
          cursor: not-allowed !important;
        }
      `}

    ${({ theme, active }) =>
      theme &&
      active &&
      css`
        button {
          background-color: ${theme.colors.secondary};
          color: white;
        }
      `}
`;
