import styled, { css } from 'styled-components';
import { p, pb, py, mt, mb, ml, mr, mx } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';
import HeadingBase from 'components/Heading/Heading.styled';

export const Wrapper = styled.article`
  min-height: 150px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};

  ${breakpoint('md')`
    ${css`
      ${mb(24)};

      display: flex;
      box-shadow: 0 0 24px 0 rgba(79, 78, 92, 0.16);
      border-bottom: none;
      border-radius: 2px;
      background-color: #fff;

      &:last-of-type {
        ${mb(0)};
      }
    `}
  `}

  ${({ renderActionsFull }) =>
    renderActionsFull &&
    css`
      ${breakpoint('md')`
      ${css`
        display: block;
      `}
    `}

      ${breakpoint('lg')`
      ${css`
        display: flex;
      `}
    `}
    `}
`;

export const Content = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${py(16)};
      flex: 1;

      ${breakpoint('md')`
        ${css`
          ${p(16)};
        `}
      `}
    `}
`;

export const Thumbnail = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      display: none;

      img {
        max-height: 100%;
      }

      ${breakpoint('md')`
        ${css`
          ${p(16)};

          flex: 0 0 180px;
          max-width: 180px;
          height: 180px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 2px solid ${theme.colors.gray[100]};
          overflow: hidden;
        `}
      `}
    `}
`;

export const Description = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(16)};

      font-size: ${theme.font.size.pixel('14')};
      line-height: 18px;
      color: ${theme.colors.gray[500]};

      a {
        font-weight: ${theme.font.weight.semibold};
        color: ${theme.colors.primaries.darker};
      }
    `}
`;

export const InlineContent = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(16)};

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: -6px;
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.semibold};
      color: ${theme.colors.tertiary};

      > * {
        ${mr(32)};
        ${mb(6)};
      }

      svg {
        fill: ${theme.colors.tertiary};
      }
    `}
`;

export const InlineContentPrice = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(16)};

      display: flex;
      align-items: center;
      justify-content: space-between;

      ${InlineContent} {
        ${mt(0)};
      }

      ${Price} {
        margin-bottom: -6px;

        ${breakpoint('md')`
          ${css`
            display: none;
          `}
        `}
      }
    `}
`;

export const ActionContent = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${pb(16)};

      display: flex;
      align-items: center;
      justify-content: center;

      ${breakpoint('md')`
        ${css`
          ${p(16)};

          background-color: #fbfbfb;
          flex: 0 0 200px;
        `}
      `}

      ${breakpoint('xl')`
        ${css`
          flex: 0 0 408px;
        `}
      `}
    `}
  
    ${({ renderActionsFull }) =>
      renderActionsFull &&
      css`
        background-color: transparent !important;

        ${breakpoint('lg')`
        ${css`
          background-color: #fdfdfd !important;
          flex: 0 0 360px;
        `}
      `}

        ${breakpoint('xl')`
        ${css`
          flex: 0 0 408px;
        `}
      `}
      `}
    
    ${({ renderActionsLeft }) =>
      renderActionsLeft &&
      css`
        ${ActionContentBox} {
          ${breakpoint('xl')`
          ${css`
            justify-content: start;
          `}
        `}
        }
      `}
`;

export const ActionContentBox = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: -16px;

      > * {
        ${mx(4)};
        ${mb(16)};
      }

      ${breakpoint('xl')`
        ${css`
          justify-content: center;

          > * {
            ${mx(24)};
          }
        `}
      `}
    `}
`;

export const ActionContentItemFull = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      flex: 1 1 100%;

      ${breakpoint('xl')`
        ${css`
          flex-basis: auto;
        `}
      `}
    `}
`;

export const Price = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('16')};
      font-weight: ${theme.font.weight.bold};
      color: ${theme.colors.secondary};
      white-space: nowrap;
    `}
`;

export const BoxStatus = styled.div`
  ${({ theme, color }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('16')};
      font-weight: ${theme.font.weight.bold};
      white-space: nowrap;

      ${
        color === 'green' &&
        css`
          color: ${theme.colors.alerts.success.text};
        `
      }

      ${
        color === 'red' &&
        css`
          color: ${theme.colors.alerts.error.text};
        `
      }

      ${
        color === 'orange' &&
        css`
          color: ${theme.colors.secondaries.default};
        `
      }

      ${
        color === 'blue' &&
        css`
          color: ${theme.colors.alerts.info.text};
        `
      }
    `}
`;

export const Heading = styled(HeadingBase)`
  ${({ theme }) =>
    theme &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;

      ${Price} {
        display: none;

        ${breakpoint('md')`
          ${css`
            ${ml(16)};

            display: inline-block;
          `}
        `}
      }
    `}
`;

export const Subtitle = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('12')};
      font-weight: ${theme.font.weight.bold};
      white-space: nowrap;
    `}
`;

export const Paragraph = styled.p``;
