import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH, withAuthorizationHeader } from '..';

export const GLOBAL_COUNTRIES = 'countries';
export const GLOBAL_AVAILABLE_MA = 'availableMa';
export const GLOBAL_AVAILABLE_MA_ALL = 'availableMaAll';
export const GLOBAL_AVAILABLE_YC = 'availableYc';
export const GLOBAL_PROFESSIONAL_TITLES = 'professionalTitles';
export const GLOBAL_JOBS_TITLES = 'jobsTitles';
export const GLOBAL_PROFILE_PRIVACY = 'profilePrivacy';
export const GLOBAL_GENDER = 'gender';
export const GLOBAL_LANGUAGES = 'languages';
export const GLOBAL_DELIVERY_MODES = 'deliveryModes';
export const GLOBAL_COURSE_TYPES = 'courseTypes';
export const GLOBAL_INSTRUCTION_FORMATS = 'instructionFormats';
export const GLOBAL_PERSPECTIVE_COMPETENCIES = 'perspectiveCompetencies';
export const GLOBAL_PEOPLE_COMPETENCIES = 'peopleCompetencies';
export const GLOBAL_PRACTICE_COMPETENCIES = 'practiceCompetencies';
export const GLOBAL_CLOUD_TAGS = 'cloudTags';
export const GLOBAL_MA_ROLES = 'roles';
export const GLOBAL_YC_ROLES = 'ycRoles';
export const GLOBAL_IPMA_ROLES = 'ipmaRoles';
export const GLOBAL_ORGANISATION_ROLES = 'organisationRoles';
export const GLOBAL_CURRENCIES = 'currencies';
export const GLOBAL_EVENT_DELIVERY_MODES = 'eventDeliveryMode';
export const GLOBAL_TIMEZONE = 'timezone';
export const GLOBAL_CONTACT_SUBJECTS = 'contactSubjects';
export const GLOBAL_INDUSTRIES = 'industries';
export const GLOBAL_PROJECT_CAREERLEVEL = 'projectCareerLevel';
export const GLOBAL_PROJECT_SKILLS = 'projectSkills';
export const GLOBAL_PROJECT_ROLES = 'projectRoles';
export const GLOBAL_ABOUT_IPMA = 'aboutIpma';

const endpointUrlsList = {
  countries: urljoin(BASE_PATH, 'public', 'countries', 'list-countries'),
  availableMa: urljoin(BASE_PATH, 'public', 'member-association', 'available-member-associations'),
  availableMaAll: urljoin(
    BASE_PATH,
    'public',
    'member-association',
    'available-member-associations?all=true',
  ),
  availableYc: urljoin(BASE_PATH, 'public', 'yc', 'available-young-crews'),
  professionalTitles: urljoin(BASE_PATH, 'public', 'proffesional-title', 'list-proffesional-title'),
  jobsTitles: urljoin(BASE_PATH, 'public', 'job-title', 'list-job-title'),
  profilePrivacy: urljoin(BASE_PATH, 'public', 'privacy-policy', 'list-privacy-policy'),
  gender: urljoin(BASE_PATH, 'public', 'gender', 'list-gender'),
  languages: urljoin(BASE_PATH, 'public', 'languages', 'list-languages'),
  deliveryModes: urljoin(BASE_PATH, 'course', 'attribute', 'delivery-mode'),
  courseTypes: urljoin(BASE_PATH, 'course', 'attribute', 'course-type'),
  instructionFormats: urljoin(BASE_PATH, 'course', 'attribute', 'instruction-format'),
  perspectiveCompetencies: urljoin(BASE_PATH, 'course', 'attribute', 'competency'),
  peopleCompetencies: urljoin(BASE_PATH, 'course', 'attribute', 'people-competency'),
  practiceCompetencies: urljoin(BASE_PATH, 'course', 'attribute', 'practice-competency'),
  cloudTags: urljoin(BASE_PATH, 'course', 'attribute', 'tag-cloud'),
  roles: urljoin(BASE_PATH, 'role', 'list'),
  ycRoles: urljoin(BASE_PATH, 'yc', 'role', 'list'),
  ipmaRoles: urljoin(BASE_PATH, 'ipma', 'role', 'list'),
  organisationRoles: urljoin(BASE_PATH, 'organization', 'management', 'list-roles'),
  currencies: urljoin(BASE_PATH, 'public', 'currency', 'list-currency'),
  eventDeliveryMode: urljoin(
    BASE_PATH,
    'public',
    'event-mode-of-delivery',
    'list-event-mode-of-delivery',
  ),
  timezone: urljoin(BASE_PATH, 'public', 'timezone', 'list-timezone'),
  contactSubjects: urljoin(BASE_PATH, 'public', 'contact-form', 'list-subjects'),
  industries: urljoin(BASE_PATH, 'public', 'industry', 'list-industry'),
  projectCareerLevel: urljoin(BASE_PATH, 'public', 'ycproject', 'careerlevel'),
  projectSkills: urljoin(BASE_PATH, 'public', 'ycproject', 'skills'),
  projectRoles: urljoin(BASE_PATH, 'public', 'ycproject', 'roles'),
  aboutIpma: urljoin(BASE_PATH, 'aboutipma'),
};

export const getEntity = async (entity, withAuth = false) =>
  ky.get(endpointUrlsList[entity], {
    headers: withAuth ? withAuthorizationHeader() : {},
  });
