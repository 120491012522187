import styled, { css } from 'styled-components';
import Grid from 'styled-components-grid';

const Row = styled(Grid)`
  margin-left: ${({ theme }) => `-${theme.grid.gutter / 2}px`};
  margin-right: ${({ theme }) => `-${theme.grid.gutter / 2}px`};

  ${({ wide }) =>
    wide &&
    css`
      flex: 1;
    `}
`;

export default Row;
