import React from 'react';
import PropTypes from 'prop-types';

import ClearableContent, { ClearButton } from './Clearable.styled';

function Clearable({ clear, disabled }) {
  return (
    <ClearableContent className="input-special">
      <ClearButton type="button" onClick={clear} disabled={disabled}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.4 13.4">
          <path d="M13.2 12.21a.71.71 0 010 1 .74.74 0 01-.5.2.7.7 0 01-.49-.2L6.7 7.69 1.2 13.2a.74.74 0 01-.5.2.7.7 0 01-.49-.2.69.69 0 010-1l5.5-5.5-5.5-5.5a.69.69 0 010-1 .69.69 0 011 0l5.5 5.5 5.5-5.49a.69.69 0 011 0 .71.71 0 010 1L7.69 6.7z" />
        </svg>
      </ClearButton>
    </ClearableContent>
  );
}

Clearable.propTypes = {
  clear: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Clearable.defaultProps = {
  disabled: false,
};

export default Clearable;
