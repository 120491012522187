import styled, { css } from 'styled-components';
import { p, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { Row } from 'components';
import { ReactSVG } from 'react-svg';

export const UploadFiles = styled.div`
  text-align: center;
  ${px(16)};
  ${py(24)};

  ${breakpoint('md')`
    ${css`
      ${p(42)};
    `}
  `}
`;

export const ContentRow = styled(Row)`
  text-align: left;
`;

export const ToUploadList = styled.ul`
  margin: 16px 0;
`;

export const File = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  word-break: break-word;
  :last-child {
    margin-bottom: 0;
  }
`;

export const RemoveIcon = styled(ReactSVG)`
  margin-right: 10px;
  min-width: 16px;
  width: 16px;
  cursor: pointer;
  fill: ${({ theme }) => `${theme.colors.primaries.default}`};
`;

export const DownloadIcon = styled(RemoveIcon)`
  fill: #222222;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.gray['600']};
  font-weight: 600;

  &::after,
  ::before {
    content: '';
    border: 1px solid ${({ theme }) => theme.colors.gray['600']};
    flex: 1;
    min-width: 25px;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
`;

export const UploadError = styled.div`
  color: ${({ theme }) => theme.colors.primaries.darker};
  margin-bottom: 8px;
`;
