import PropTypes from 'prop-types';
import { useSubRoutes } from 'hooks';
import { globalPropTypes } from 'helpers';

const Ma = ({ routes }) => {
  const [renderSubRoutes] = useSubRoutes(routes);

  return renderSubRoutes();
};

Ma.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
};

Ma.defaultProps = {
  routes: [],
};

export default Ma;
