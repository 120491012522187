import styled, { css } from 'styled-components';

export const Wrapper = styled.article`
  ${({ theme }) =>
  theme &&
    css`
    `}
`;

export const Image = styled.img`
  ${({ theme }) =>
  theme &&
    css`
      width: 168px;
    `}
`;
