import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ButtonIcon, Field, Row, Col } from 'components';
import { Wrapper, StyledRow } from './SearchMain.styled';

const SearchMain = ({ closeFn, small, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { watch, register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    closeFn();
    history.push(`/search?s=${data.s}`);
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)} {...props}>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <StyledRow>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              padding: '0px',
            }}
          >
            <Field small={small}>
              <input
                type="text"
                placeholder={t('global.searchPlaceholder')}
                name="s"
                ref={register}
                required
                autoComplete="off"
                style={{ paddingLeft: '10px', outline: 'none', border: 'none' }}
              />
            </Field>
            <ButtonIcon
              icon="searchNew"
              secondary
              rounded
              style={{ background: 'transparent', color: 'black', border: 'none' }}
              large={!small}
              disabled={!watch('s')}
            />
          </Col>
        </StyledRow>
      </Row>
    </Wrapper>
  );
};

SearchMain.propTypes = {
  closeFn: PropTypes.func,
  small: PropTypes.bool,
};

SearchMain.defaultProps = {
  closeFn: () => {},
  small: false,
};

export default SearchMain;
