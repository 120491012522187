import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Container, Row, Col, Heading, Button } from 'components';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Wrapper, Content, Description, Action } from './HeaderCustom.styled';

const HeaderCustom = ({ breadcrumbs, title, description, renderActions }) => {
  const renderActionsComponents = { Button };

  const renderActionContent = () => {
    if (typeof renderActions === 'function') {
      return (
        <Col>
          <Action>
            <Margin top={{ xs: 12 }}>{renderActions(renderActionsComponents)}</Margin>
          </Action>
        </Col>
      );
    }

    return null;
  };

  return (
    <Wrapper>
      <Container>
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
        <Content>
          <Row valign="center">
            <Col>
              <Heading as="h1" color="#fff" center>
                {title}
              </Heading>
              {description && <Description>{description}</Description>}
            </Col>
            {renderActionContent()}
          </Row>
        </Content>
      </Container>
    </Wrapper>
  );
};

HeaderCustom.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string,
  description: PropTypes.string,
  renderActions: PropTypes.func,
};

HeaderCustom.defaultProps = {
  breadcrumbs: null,
  title: '',
  description: '',
  renderActions: null,
};

export default HeaderCustom;
