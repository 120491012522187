import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { Wrapper, Tag, Input, Label, Icon } from './SelectableTags.styled';

function SelectableTags({ tags, onChange }) {
  return (
    <Wrapper>
      {tags.map((tag, index) => (
        <Tag key={`tag-${index}`}>
          <Input
            type="checkbox"
            id={`tag-${tag.value}`}
            checked={tag.selected}
            onChange={() => onChange(tag.value, !tag.selected)}
          />
          <Label htmlFor={`tag-${tag.value}`}>
            <Icon>
              <ReactSVG src={iconList.close} />
            </Icon>
            {tag.label}
          </Label>
        </Tag>
      ))}
    </Wrapper>
  );
}

SelectableTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      selected: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectableTags;
