import { without } from 'lodash';
import { SET_EDIT_PROFILE_LOADING, SET_EDIT_PROFILE_ERRORS } from '../constants';

const initialState = {
  errors: {},
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EDIT_PROFILE_LOADING:
      return { ...state, loading: action.payload };
    case SET_EDIT_PROFILE_ERRORS:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
