import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  PageContent,
  Container,
  HeaderCustom,
  Row,
  Col,
  Heading,
  HeadingWrapper,
  Cms,
  ThumbnailCard,
  Tags,
} from 'components';
import { Margin, Padding } from 'styled-components-spacing';
import { loadListViewCreator, removeListViewCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { newLineToBr, parseUrlTitle } from 'helpers';
import { useAuthLock } from 'hooks';
import { ReactSVG } from 'react-svg';
import { iconList as icons } from 'assets';
import { ApplyButton } from './ViewRole.styled';
import ApplyModal from '../ApplyModal/ApplyModal';

const ViewRole = ({ loadView, removeView, view, history }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [renderAuthLock] = useAuthLock(history);
  const [formModalOpen, setFormModalOpen] = useState();

  const {
    title,
    occupation,
    careerLevel,
    ycProjectRoleType,
    roleDescription,
    responsibilities,
    qualifications,
    closingInformations,
    skills,
    project,
  } = view;

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.app.projects',
      url: '/projects',
    },
    {
      title: project ? project.title : '',
      url: `/projects/${project ? project.id : ''}/${parseUrlTitle(project ? project.title : '')}`,
    },
    {
      title: title || '',
      url: '',
    },
  ];

  useEffect(() => {
    loadView(id);

    return () => {
      removeView();
    };
  }, []);

  const details = [
    { name: 'page.singleProjectRole.occupation', value: occupation },
    { name: 'page.singleProjectRole.careerLevel', value: careerLevel },
    { name: 'page.singleProjectRole.ycProjectRoleType', value: ycProjectRoleType },
  ];

  const renderDetails = () => (
    <Padding horizontal={{ xl: 20 }} vertical="20">
      {details ? (
        <>
          <HeadingWrapper>
            <Heading as="h2" size="h4" firstLine>
              {t('page.singleProjectRole.details')}
            </Heading>
          </HeadingWrapper>
          {details.map(
            (item) =>
              item.value && (
                <Margin as={Row} bottom="16">
                  <Col size={1 / 2}>{t(item.name)}</Col>
                  <Col size={1 / 2} className="text-right">
                    <strong>{item.value}</strong>
                  </Col>
                </Margin>
              ),
          )}
          <Margin>
            <HeadingWrapper>
              <Heading as="h2" size="h4" firstLine>
                {t('page.singleProjectRole.skills')}
              </Heading>
            </HeadingWrapper>
            <Tags tags={skills} />
          </Margin>
        </>
      ) : null}
    </Padding>
  );

  const applicationModal = () => (
    <ApplyModal id={id} open={formModalOpen} closeFn={() => setFormModalOpen(false)} view={view} />
  );

  return (
    <>
      <HeaderCustom
        breadcrumbs={breadcrumbs}
        title={title}
        description={roleDescription}
        renderActions={() => (
          <>
            {view.status === 'open' && view.positionTimeStatus === 'open' && (
              <ApplyButton onClick={() => setFormModalOpen(true)}>
                <p>{t('page.singleProjectRole.apply')}</p>
                <ReactSVG src={icons.arrowRight} />
              </ApplyButton>
            )}
          </>
        )}
      />
      <PageContent>
        {renderAuthLock()}
        <Container>
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                <Cms>
                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleProjectRole.responsibilities')}
                    </Heading>
                  </HeadingWrapper>
                  {responsibilities ? (
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(responsibilities) }} />
                  ) : null}

                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleProjectRole.qualifications')}
                    </Heading>
                  </HeadingWrapper>
                  {qualifications ? (
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(qualifications) }} />
                  ) : null}

                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleProjectRole.closingInformations')}
                    </Heading>
                  </HeadingWrapper>
                  {closingInformations ? (
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(closingInformations) }} />
                  ) : null}
                </Cms>
              </Margin>
            </Col>
            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              {!!project && (
                <Margin bottom="16">
                  <HeadingWrapper>
                    <Heading as="h2" size="h4" firstLine>
                      {t('page.singleProjectRole.project')}
                    </Heading>
                  </HeadingWrapper>
                  <ThumbnailCard
                    thumbnail={project.thumbnail}
                    title={project.title}
                    link={project.url}
                  />
                </Margin>
              )}
              <div className="border-xl">{renderDetails()}</div>
            </Margin>
          </Row>
        </Container>
      </PageContent>
      {applicationModal()}
    </>
  );
};

ViewRole.propTypes = {
  loadView: PropTypes.func.isRequired,
  removeView: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  view: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    occupation: PropTypes.string,
    careerLevel: PropTypes.string,
    ycProjectRoleType: PropTypes.string,
    roleDescription: PropTypes.string,
    responsibilities: PropTypes.string,
    qualifications: PropTypes.string,
    closingInformations: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
    positionTimeStatus: PropTypes.string,
    status: PropTypes.string,
    cvRequired: PropTypes.bool,
    letterRequired: PropTypes.bool,
    project: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      url: PropTypes.string,
      thumbnail: PropTypes.string,
      place: PropTypes.string,
      description: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
  }),
};

ViewRole.defaultProps = {
  view: {},
};

const mapStateToProps = ({
  user: { appType },
  list: {
    projects: { view },
  },
}) => ({
  appType,
  view,
});

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload, history) => dispatch(loadListViewCreator('projectRole', payload, history)),
  removeView: () => dispatch(removeListViewCreator('projectRole')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRole);
