import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { Margin } from 'styled-components-spacing';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Alerts,
  Button,
  Col,
  Container,
  Header,
  Heading,
  Field,
  Select,
  Row,
  FileInput,
  PageContent,
} from 'components';

import {
  loadGlobalCountriesCreator,
  loadUserOrganisationCreator,
  updateUserOrganisationCreator,
} from 'store/actionsCreators';
import { getFileValue, globalPropTypes } from 'helpers';
import { ProfileEditForm } from './Edit.styled';

const Spacer = ({ double, children }) => (
  <Margin top={{ xs: 12 * (double ? 2 : 1) }}>{children}</Margin>
);

Spacer.propTypes = {
  double: PropTypes.bool,
  children: PropTypes.elementType.isRequired,
};

Spacer.defaultProps = { double: false };

function EditOrganisation({
  countries,
  loadGlobalCountries,

  updateOrganisation,

  organisation,
  errors,

  history,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!countries.length) {
      loadGlobalCountries();
    }
  }, []);

  const { id } = useParams();
  const [fileName, setFileName] = useState('');
  const { setValue, register, handleSubmit, control, reset } = useForm();

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.organisation.edit',
      url: `/organisation/${id}/profile/edit`,
    },
  ];

  useEffect(() => {
    if (Object.keys(organisation).length) {
      reset({
        name: organisation.name,
        country: find(countries, { value: organisation.country.id }),
        contactPerson: organisation.contactPerson,
        address_l1: organisation.address.line1,
        address_l2: organisation.address.line2,
        postCode: organisation.address.postCode,
        city: organisation.address.city,
        address_country: find(countries, { value: organisation.address.country.id }),
        email: organisation.email,
        telephone: organisation.telephone,
        website: organisation.website,
        shortDescription: organisation.shortDescription,
        fullDescription: organisation.fullDescription,
        imageFile: organisation.photo,
      });

      setFileName(organisation.photo || null);
    }
  }, [organisation, countries]);

  useEffect(() => {
    register({ name: 'imageFile' });
  }, [register]);

  const handleFileChange = (e) => {
    setValue('imageFile', e.target.files && e.target.files.length ? e.target.files[0] : null);
    setFileName(e.target.files && e.target.files[0] && e.target.files[0].name);
  };

  const handleClearFileNative = () => {
    setValue('imageFile', null);
    setFileName(null);
  };

  const onSubmit = (data) => {
    const params = new FormData();
    params.append('name', data.name);
    params.append('country', data.country && data.country.value);
    params.append('contactPerson', data.contactPerson);
    params.append('imageFile', getFileValue(data.imageFile));
    params.append('address[line1]', data.address_l1 ? data.address_l1 : '');
    params.append('address[line2]', data.address_l2 ? data.address_l2 : '');
    params.append('address[postCode]', data.postCode ? data.postCode : '');
    params.append('address[city]', data.city ? data.city : '');
    params.append('address[country]', data.address_country && data.address_country.value);
    params.append('telephone', data.telephone ? data.telephone : '');
    params.append('email', data.email ? data.email : '');
    params.append('website', data.website ? data.website : '');
    params.append('shortDescription', data.shortDescription ? data.shortDescription : '');
    params.append('fullDescription', data.fullDescription ? data.fullDescription : '');

    updateOrganisation(id, params, history);
  };

  return (
    <>
      <Header title={t('page.organisation.edit.title')} breadcrumbs={breadcrumbs} />
      <PageContent>
        <Container>
          <Alerts type="organisations" />
          <ProfileEditForm onSubmit={handleSubmit(onSubmit)}>
            <Heading size="h5">{t('page.organisation.edit.basicInformation')}</Heading>
            <Row>
              <Col size={{ xs: 1 / 1 }}>
                <Spacer>
                  <Field
                    label={t('forms.organisationNameLabel')}
                    id="organisation-name"
                    required
                    error={errors.name}
                  >
                    <input
                      id="organisation-name"
                      name="name"
                      placeholder={t('forms.organisationNamePlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.uploadLogoLabel')}
                    id="logo"
                    error={errors.imageFile}
                    description={t('forms.imageInputInfo')}
                  >
                    <FileInput
                      id="logo"
                      name="imageFile"
                      placeholder={t('forms.uploadLogoPlaceholder')}
                      fileName={fileName}
                      clear={handleClearFileNative}
                      onChange={handleFileChange}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.countryLabel')}
                    id="country"
                    required
                    error={errors.country}
                  >
                    <Controller
                      id="country"
                      name="country"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={countries}
                      placeholder={t('forms.countryPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Spacer double>
              <Heading size="h5">{t('forms.contactData')}</Heading>
            </Spacer>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('forms.addressLine1Label')}
                    id="address-l1"
                    required
                    error={errors['address.line1']}
                  >
                    <input
                      id="address-l1"
                      name="address_l1"
                      placeholder={t('forms.addressLinePlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('forms.addressLine2Label')}
                    id="address-l2"
                    error={errors['address.line2']}
                  >
                    <input
                      id="address-l2"
                      name="address_l2"
                      placeholder={`${t('forms.addressLinePlaceholder')} (${t(
                        'forms.addressSecondLinePlaceholder',
                      )})`}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.postCodeLabel')}
                    id="postcode"
                    required
                    error={errors['address.postCode']}
                  >
                    <input
                      id="postcode"
                      name="postCode"
                      placeholder={t('forms.postCodePlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.cityLabel')}
                    id="city"
                    required
                    error={errors['address.city']}
                  >
                    <input
                      id="city"
                      name="city"
                      placeholder={t('forms.cityPlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.countryLabel')}
                    id="address-country"
                    required
                    error={errors['address.country']}
                  >
                    <Controller
                      id="address-country"
                      name="address_country"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={countries}
                      placeholder={t('forms.countryPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('global.phoneLabel')}
                    id="telephone"
                    required
                    error={errors.telephone}
                  >
                    <input
                      id="telephone"
                      name="telephone"
                      placeholder={t('global.phonePlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field label={t('forms.emailLabel')} id="email" required error={errors.email}>
                    <input
                      id="email"
                      name="email"
                      placeholder={t('forms.emailPlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field label={t('forms.websiteLabel')} id="website" error={errors.website}>
                    <input
                      id="website"
                      name="website"
                      placeholder={t('forms.websitePlaceholder.organisation')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Spacer double>
              <Heading size="h5">{t('forms.description')}</Heading>
            </Spacer>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('forms.shortDescriptionLabel')}
                    id="short-desc"
                    error={errors.shortDescription}
                    required
                  >
                    <textarea
                      id="short-desc"
                      name="shortDescription"
                      placeholder={t('forms.shortDescriptionPlaceholder.organisation')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('forms.fullDescriptionLabel')}
                    id="full-desc"
                    error={errors.fullDescription}
                  >
                    <textarea
                      id="full-desc"
                      name="fullDescription"
                      placeholder={t('forms.fullDescriptionPlaceholder.organisation')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 1 / 6 }}>
                <Spacer double>
                  <Button type="submit">{t('global.update')}</Button>
                </Spacer>
              </Col>
            </Row>
          </ProfileEditForm>
        </Container>
      </PageContent>
    </>
  );
}

EditOrganisation.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,

  updateOrganisation: PropTypes.func.isRequired,

  organisation: globalPropTypes.OrganisationProfileTypes.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    country: PropTypes.string,
    contactPerson: PropTypes.string,
    imageFile: PropTypes.string,
    'address.line1': PropTypes.string,
    'address.line2': PropTypes.string,
    'address.postCode': PropTypes.string,
    'address.city': PropTypes.string,
    'address.country': PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
  }),

  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

EditOrganisation.defaultProps = {
  errors: {},
};

const mapStateToProps = ({ globals, organisation }) => ({
  countries: globals.countries,

  organisation: organisation.profile,
  errors: organisation.errors,
  loading: organisation.loading,
});

const mapDispatchToProps = (dispatch) => ({
  updateOrganisation: (id, payload, history) =>
    dispatch(updateUserOrganisationCreator(id, payload, history)),
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  loadUserOrganisation: () => dispatch(loadUserOrganisationCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganisation);
