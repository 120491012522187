import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList as icons } from 'assets/index';
import { Heading as HeadingStyles } from '../Table.styled';
import { Sort, SortContainer } from './Heading.styled';

function Heading({ sort, children, ...res }) {
  return (
    <HeadingStyles {...res}>
      {sort ? (
        <SortContainer onClick={sort}>
          <Sort>
            <ReactSVG src={icons.sort} />
          </Sort>
          {children}
        </SortContainer>
      ) : (
        children
      )}
    </HeadingStyles>
  );
}

Heading.propTypes = {
  sort: PropTypes.func,
  children: PropTypes.elementType,
};

Heading.defaultProps = {
  sort: null,
  children: null,
};

export default Heading;
