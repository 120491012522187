import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH, withAuthorizationHeader } from '..';

export const getPageUserProfile = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'user', 'profile', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getPageUserProfileBadges = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'user', 'my-badges', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const submitContactForm = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'contact_us'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(body),
  });
};

export const getHomeMaList = async () => {
  return ky.get(
    urljoin(BASE_PATH, 'public', 'member-association', 'list-member-associations-home-page'),
    {
      headers: withAuthorizationHeader(),
    },
  );
};
