import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Modal, Heading, Row, FormCreator, Spinner, Button, Col } from 'components';
import { sendUserCpdForVerificationCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import { useFormFields, isLoading } from 'helpers';
import { SubmitButton, Success, ModalContentCustom } from './SendForVerificationModal.styled';

const ModalForm = ({ setSent, sendVerification, cb }) => {
  const { t } = useTranslation();

  const validateError = useRef(false);
  const validateErrorsMsg = useRef({});
  const [formFields, handleFormFieldChange] = useFormFields({
    cb: '',
    errors: {},
  });

  const formDetails = {
    'row-1': {
      cb: {
        type: 'select',
        id: 'f-cb',
        label: t('page.user.myCPD.modalCertificationBodyLabel'),
        placeholder: t('page.user.myCPD.modalCertificationBodyPlaceholder'),
        options: cb,
        required: true,
        menuPortalTarget: document.body,
      },
    },
  };

  const setValidationError = (prop, errorMsg) => {
    validateErrorsMsg.current = {
      ...validateErrorsMsg.current,
      [prop]: errorMsg,
    };
    validateError.current = true;
  };

  const submit = () => {
    if (!formFields.cb) {
      setValidationError('cb', t('global.validationErrors.fieldNotEmpty'));
    }

    if (validateError.current) {
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: validateErrorsMsg.current,
        },
      });
      validateErrorsMsg.current = {};
      validateError.current = false;
      return;
    }

    sendVerification(formFields.cb.value, setSent, (payload) => {
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: payload,
        },
      });
    });
  };

  return (
    <>
      <FormCreator
        formFields={formFields}
        formDetails={formDetails}
        errors={formFields.errors}
        handleFormFieldChange={handleFormFieldChange}
        buttonLabel="Send"
        onSubmit={submit}
        hideButton
      />
      <SubmitButton onClick={submit}>{t('page.singleProjectRole.applyButton')}</SubmitButton>
    </>
  );
};

const SendForVerificationModal = ({ open, loading, closeFn, sendVerification, cb }) => {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);

  useEffect(() => {
    setSent(false);
  }, [open]);

  const renderContent = () => (
    <ModalContentCustom>
      <Heading as="h4" noLine center>
        {sent ? t('page.user.myCPD.sentTitle') : t('page.user.myCPD.sendForVerification')}
      </Heading>
      <Margin top="16">
        {sent
          ? t('page.user.myCPD.sentVerificationDesc')
          : t('page.user.myCPD.modalVerificationDesc')}
      </Margin>
      {loading ? (
        <Spinner />
      ) : (
        <Margin top="32">
          {sent ? (
            <Row halign="center">
              <Success />
              <Col size={1 / 2}>
                <Button onClick={closeFn}>Close</Button>
              </Col>
            </Row>
          ) : (
            <ModalForm setSent={() => setSent(true)} sendVerification={sendVerification} cb={cb} />
          )}
        </Margin>
      )}
    </ModalContentCustom>
  );

  return (
    <Modal
      isOpen={open}
      customContent={renderContent}
      onRequestClose={closeFn}
      autoWidth
      customWidth="70%"
    />
  );
};

SendForVerificationModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  closeFn: PropTypes.func.isRequired,
  sendVerification: PropTypes.func.isRequired,
  cb: PropTypes.arrayOf({}).isRequired,
};

ModalForm.propTypes = {
  setSent: PropTypes.func.isRequired,
  sendVerification: PropTypes.func.isRequired,
  cb: PropTypes.arrayOf({}).isRequired,
};

SendForVerificationModal.defaultProps = {
  open: false,
};

const mapStateToProps = ({ user: { appType }, loading }) => ({
  loading: isLoading(loading, 'CPD_APPLICATION'),
  appType,
});

const mapDispatchToProps = (dispatch) => ({
  sendVerification: (payload, closeModal, setErrors) =>
    dispatch(sendUserCpdForVerificationCreator(payload, closeModal, setErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendForVerificationModal);
