import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Row, Col, Heading, Field, FileInput, Spinner } from 'components';
import { Action } from 'components/Modal/Modal.styled';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getFileValue, isLoading, isValidUrl } from 'helpers';
import { iconList } from 'assets';
import { connect } from 'react-redux';
import {
  uploadIpmaRegOfficeCourseRegLogosCreator,
  loadIpmaRegOfficeCourseRegLogosCreator,
  setIpmaRegOfficeCourseRegLogosCreator,
  removeIpmaRegOfficeCourseRegLogoCreator,
  downloadIpmaRegOfficeCourseRegLogoCreator,
  loadIpmaRegOfficeCoursesCreator,
} from 'store/actionsCreators';
import {
  UploadFiles,
  ContentRow,
  ToUploadList,
  File,
  RemoveIcon,
  DownloadIcon,
  Divider,
  UploadError,
} from './UploadModal.styled';

ReactModal.setAppElement('#modals');

const UploadModal = ({
  loading,
  regLogos,
  google,
  files,
  status,
  open,
  setFiles,
  setOpen,
  uploadFiles,
  loadRegLogos,
  downloadFile,
  removeFile,
  loadCourses,
}) => {
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const [googleLink, setGoogleLink] = useState('');

  useEffect(() => {
    if (open) {
      loadRegLogos(open);
    }
  }, [open]);

  useEffect(() => {
    setGoogleLink(google);
  }, [google]);

  useEffect(() => {
    setError('');
  }, [files, googleLink]);

  const onClose = () => {
    setOpen(0);
    setFiles([]);
    setGoogleLink('');
    setError('');
    setIpmaRegOfficeCourseRegLogosCreator([]);
  };

  const removeFromList = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const onRemoveFile = (fileId) => {
    removeFile({ courseId: open, regLogoId: fileId });
    loadCourses({ status });
  };

  const onDownloadFile = (fileId, name) => {
    downloadFile({ id: fileId, fileName: name });
  };

  const getFileExtension = (name) => {
    if (name.lastIndexOf('.')) {
      return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    }
    return '';
  };

  const submitFiles = () => {
    if (!googleLink || ((!files || files.length === 0) && (!regLogos || regLogos.length === 0))) {
      return setError('An acceptance letter and REG logo are required.');
    }

    if (googleLink && googleLink.length && !isValidUrl(googleLink)) {
      return setError('Provided link is not valid.');
    }

    const params = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      if (!['tiff', 'png', 'pdf'].includes(getFileExtension(files[i].name))) {
        return setError('Wrong file type selected.');
      }
      params.append('courseRegLogos[][imageFile][file]', getFileValue(files[i]));
    }
    params.append('googleFileUrl', googleLink || '');

    uploadFiles({ id: open, body: params });
    setIpmaRegOfficeCourseRegLogosCreator([]);
    return onClose();
  };

  const renderUploadModal = () => (
    <UploadFiles>
      <Heading as="h4" noLine center>
        {t('page.ipma.office.uploadRegLogo')}
      </Heading>
      {loading ? <Spinner /> : null}
      {!loading && (
        <>
          <Margin top="24">
            <ContentRow halign="center">
              <Col size={{ xs: 3 / 4 }}>
                <Divider>{t('page.ipma.office.acceptanceLetter')}</Divider>
                <Field id="participants-csv" required description={t('global.uploadRegLogoDesc')}>
                  <FileInput
                    id="exprt-cv"
                    placeholder={t('global.uploadPlaceholderMultiple')}
                    fileName=""
                    clear={() => {}}
                    onChange={(e) => setFiles([...files, ...Array.from(e.target.files)])}
                    multiple
                  />
                </Field>
                {files ? (
                  <ToUploadList>
                    {files.map((file, index) => (
                      <File key={file.name}>
                        <RemoveIcon
                          src={iconList.close}
                          title="Remove file"
                          onClick={() => removeFromList(index)}
                        />
                        {file.name}
                      </File>
                    ))}
                  </ToUploadList>
                ) : null}
                <Divider>{t('page.ipma.office.googleDriveLink')}</Divider>
                <Field id="google-drive-link" required>
                  <input
                    id="google-drive-link-input"
                    key="gd-input"
                    type="text"
                    placeholder="Google drive link"
                    value={googleLink}
                    onChange={(e) => setGoogleLink(e.target.value)}
                  />
                </Field>
                {regLogos.length ? (
                  <ToUploadList>
                    <Divider>{t('page.ipma.office.uploadedAcceptanceLetters')}</Divider>
                    {regLogos.map((reg) => (
                      <File key={reg.file}>
                        <DownloadIcon
                          src={iconList.fileUpload}
                          title="Download uploaded acceptance letter"
                          onClick={() => onDownloadFile(reg.id, reg.file)}
                        />
                        <RemoveIcon
                          src={iconList.close}
                          title="Remove uploaded acceptance letter"
                          onClick={() => onRemoveFile(reg.id)}
                        />
                        {reg.file}
                      </File>
                    ))}
                  </ToUploadList>
                ) : null}
              </Col>
            </ContentRow>
          </Margin>
          <Margin top="16">
            <Row halign="center">
              <UploadError>{error}</UploadError>
            </Row>
            <Row halign="center">
              <Col size={{ xs: 1 / 2 }}>
                <Action onClick={submitFiles}>{t('global.upload')}</Action>
              </Col>
              <Col size={{ xs: 1 / 2 }}>
                <Action onClick={onClose} bordered>
                  {t('global.closeButton')}
                </Action>
              </Col>
            </Row>
          </Margin>
        </>
      )}
    </UploadFiles>
  );
  return (
    <ReactModal
      style={{
        content: {
          maxWidth: 600,
          width: '100%',
          top: '50%',
          bottom: 'initial',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          border: 'none',
          boxShadow: '0px 0px 48px 0px rgba(61,62,64,0.24)',
        },
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          zIndex: 40,
        },
      }}
      isOpen={open}
      onRequestClose={onClose}
    >
      {renderUploadModal()}
    </ReactModal>
  );
};

UploadModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  regLogos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  google: PropTypes.string.isRequired,
  files: PropTypes.shape({
    map: PropTypes.func,
    filter: PropTypes.func,
    length: PropTypes.number,
  }).isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.number.isRequired,
  setFiles: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  loadRegLogos: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  loadCourses: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ipma, loading }) => ({
  loading: isLoading(loading, 'REGLOGO'),
  regLogos: ipma.regOfficeCourses.regLogos,
  google: ipma.regOfficeCourses.googleLink,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFiles: (payload) => dispatch(uploadIpmaRegOfficeCourseRegLogosCreator(payload)),
  loadRegLogos: (id) => dispatch(loadIpmaRegOfficeCourseRegLogosCreator(id)),
  downloadFile: (payload) => dispatch(downloadIpmaRegOfficeCourseRegLogoCreator(payload)),
  removeFile: (payload) => dispatch(removeIpmaRegOfficeCourseRegLogoCreator(payload)),
  loadCourses: (payload) => dispatch(loadIpmaRegOfficeCoursesCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
