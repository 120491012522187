import React, { useEffect, useState } from 'react';
import { isLogin } from 'api';
import { Col, Modal, Row } from 'components';
import i18next from 'i18next';
import { Margin } from 'styled-components-spacing';

const useAuthModal = (history) => {
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const authModalHandle = () => setAuthModalOpen(false);

  useEffect(() => {
    history.block((location) => {
      if (location.state && location.state.authModal && !isLogin()) {
        setAuthModalOpen(true);
        return false;
      }
      return true;
    });
  }, []);

  const renderAuthModal = () => (
    <Modal
      isOpen={authModalOpen}
      onRequestClose={authModalHandle}
      heading={i18next.t('global.authModal.heading')}
      description={i18next.t('global.authModal.description')}
      customActions={(Action) => (
        <Row>
          <Margin as={Col} bottom={{ xs: 16, md: 0 }} size={{ md: 1 / 2 }}>
            <Action
              onClick={() => {
                history.push('/auth/login');
                authModalHandle();
              }}
            >
              {i18next.t('global.logIn')}
            </Action>
          </Margin>
          <Col size={{ md: 1 / 2 }}>
            <Action
              bordered
              onClick={() => {
                history.push('/auth/register');
                authModalHandle();
              }}
            >
              {i18next.t('global.createAccount')}
            </Action>
          </Col>
        </Row>
      )}
    />
  );

  return [renderAuthModal];
};

export default useAuthModal;
