import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alerts, ButtonAction, Container, Header, Modal, PageContent, Table } from 'components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { globalPropTypes, isDateAfter } from 'helpers';
import { useTranslation } from 'react-i18next';
import { removeUserCertificationsCreator } from 'store/actionsCreators';
import moment from 'moment';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.certification',
    url: '/user/certification',
  },
];

const Certification = ({ certifications, removeUserCertifications }) => {
  const { t } = useTranslation();
  const [confirmRemove, setConfirmRemove] = useState(null);

  const handleRemove = (id) => () => {
    setConfirmRemove(id);
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('certificates.myCertification')}
        renderActions={({ Button: ButtonRender }) => (
          <ButtonRender secondary="true" as={Link} to="/user/certification/add">
            {t('certificates.applyCertificationStatus')}
          </ButtonRender>
        )}
      />
      <PageContent>
        <Container>
          <Alerts type="certificates" />
          <Table tablebreakpoint="lg">
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('certificates.ownerName')}</Table.Heading>
                <Table.Heading>{t('certificates.certificate')}</Table.Heading>
                <Table.Heading>{t('certificates.certificateNumber')}</Table.Heading>
                <Table.Heading>{t('certificates.awardedDate')}</Table.Heading>
                <Table.Heading>{t('certificates.validityDate')}</Table.Heading>
                <Table.Heading>{t('certificates.ma')}</Table.Heading>
                <Table.Heading />
                <Table.Heading />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {!certifications || !certifications.length ? (
                <Table.Row>
                  <Table.Cell colSpan={6}>{t('global.noResultsFound')}</Table.Cell>
                </Table.Row>
              ) : null}
              {certifications && certifications.length
                ? certifications.map((item, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Table.Label>{t('certificates.ownerName')}:</Table.Label>
                        {item.ownerName}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('certificates.certificate')}:</Table.Label>
                        {item.name}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('certificates.certificateNumber')}:</Table.Label>
                        {item.certificateId}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('certificates.awardedDate')}:</Table.Label>
                        {item.awardedDate}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('certificates.validityDate')}:</Table.Label>
                        {item.validityDate}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('certificates.ma')}:</Table.Label>
                        {item.ma.name}
                      </Table.Cell>
                      <Table.Cell>
                        <ButtonAction
                          onClick={handleRemove(item.id)}
                          title={t('global.remove')}
                          icon="closeCircle"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {isDateAfter(
                          moment(item.validityDate, 'DD/MM/YYYY'),
                          moment('01/12/2021', 'DD/MM/YYYY'),
                        ) ? (
                          <ButtonAction
                            as={Link}
                            to="/user/my-badges"
                            icon="addCicrle"
                            title={t('global.claimTheBadgeButton')}
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
        </Container>
      </PageContent>
      <Modal
        heading={t('modals.headings.areYouSure')}
        isOpen={!!confirmRemove}
        confirm={() => removeUserCertifications(confirmRemove) && setConfirmRemove(null)}
        cancel={() => setConfirmRemove(null)}
      />
    </>
  );
};

Certification.propTypes = {
  certifications: PropTypes.arrayOf(globalPropTypes.UserCertificationPropsTypes),
  removeUserCertifications: PropTypes.func.isRequired,
};

Certification.defaultProps = {
  certifications: [],
};

const mapStateToProps = ({ user }) => ({ certifications: user.certifications });

const mapDispatchToProps = (dispatch) => ({
  removeUserCertifications: (payload) => dispatch(removeUserCertificationsCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Certification);
