import React from 'react';
import PropTypes from 'prop-types';
import { useSubRoutes } from 'hooks';
import LayoutImage from 'layouts/LayoutImage/LayoutImage';
import { Alerts } from 'components';
import { globalPropTypes } from 'helpers';

const Auth = ({ routes }) => {
  const [renderSubRoutes] = useSubRoutes(routes);
  return <LayoutImage renderAlerts={() => <Alerts type="auth" />}>{renderSubRoutes()}</LayoutImage>;
};

Auth.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
};

Auth.defaultProps = {
  routes: [],
};

export default Auth;
