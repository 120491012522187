import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAvatar } from 'helpers';
import { Wrapper, Thumbnail, Content } from './UserDetails.styled';

const UserDetails = ({ thumbnail, url, children, ...props }) => {
  const renderThumbnail = () => {
    return (
      <Thumbnail>
        <img src={getAvatar(thumbnail, 'profile')} alt="user avatar" />
      </Thumbnail>
    );
  };

  return (
    <Wrapper {...props}>
      {url ? (
        <Link to={{ pathname: url, state: { authModal: true } }}>{renderThumbnail()} </Link>
      ) : (
        renderThumbnail()
      )}
      <Content>{children}</Content>
    </Wrapper>
  );
};

UserDetails.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  url: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

UserDetails.defaultProps = {
  url: null,
};

export default UserDetails;
