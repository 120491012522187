import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Col,
  Alerts,
  ButtonAction,
  Modal,
  Container,
  PageContent,
  Pagination,
  Table,
  FiltersControls,
  Spinner,
  FiltersCustom,
} from 'components';
import {
  loadYcMembersListCreator,
  setYcPeopleListSortCreator,
  acceptYcPeopleListUserCreator,
  declineYcPeopleListUserCreator,
  removeYcPeopleListUserCreator,
  setYcPeopleListPageCreator,
  setYcPeopleListPerPageCreator,
  downloadYcPeopleReportCreator,
  setYcPeopleListFiltersCreator,
} from 'store/actionsCreators';
import { useFormFields } from 'helpers';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Content, Wrapper, Icon, RowStyled, ActionText } from './List.styled';

const APPLIED_STATUS = 'Applied';
const YES_STATUS = 'Yes';

function List({
  items,
  page,
  pages,
  perPage,
  sort,
  loadUsers,
  setSort,
  loading,
  removeUser,
  setPage,
  setPerPage,
  acceptUser,
  declineUser,
  downloadReport,
  filters,
  setFilters,
}) {
  const { t } = useTranslation();
  const [acceptConfirmModal, setAcceptConfirmModal] = useState(null);
  const [declineConfirmModal, setDeclineConfirmModal] = useState(null);
  const [removeConfirmModal, setRemoveConfirmModal] = useState(null);
  const [filterFields, handleFilterFieldChange] = useFormFields({
    firstName: '',
    lastName: '',
    email: '',
    createdFrom: null,
    certified: null,
  });

  const simpleOptions = [
    {
      value: 'no',
      label: 'No',
    },
    {
      value: 'yes',
      label: 'Yes',
    },
  ];

  const filtersProps = {
    firstName: {
      type: 'input',
      name: 'first-name',
      id: 'f-name',
      label: t('page.ma.peopleAdd.firstName'),
      placeholder: t('forms.firstNamePlaceholder'),
    },
    lastName: {
      type: 'input',
      name: 'last-name',
      id: 'f-lastName',
      label: t('page.ma.peopleAdd.lastName'),
      placeholder: t('forms.lastNamePlaceholder'),
    },
    email: {
      type: 'input',
      name: 'email',
      id: 'f-email',
      label: t('page.ma.peopleAdd.email'),
      placeholder: t('forms.lastNamePlaceholder'),
    },
    createdFrom: {
      type: 'datepicker',
      name: 'createdFrom',
      id: 'f-createdFrom',
      label: t('page.ma.peopleAdd.createdFrom'),
      placeholder: t('forms.createdFromPlaceholder'),
    },
    certified: {
      type: 'select',
      name: 'certified',
      id: 'f-jobTitle',
      label: t('page.ma.peopleAdd.certified'),
      placeholder: t('forms.certifiedPlaceholder'),
      options: simpleOptions,
    },
  };

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadUsers();
  }, [page, perPage, sort]);

  useEffect(() => {
    setPage(1);
    loadUsers();
  }, [filters]);

  const handleSortBy = (name) => () => {
    const [currentSortName, currentSortStatus] = sort || [];
    const sortByNewColumn = currentSortName !== name;

    if (sortByNewColumn) {
      setSort([name, 'desc']);
      return;
    }

    const newSortStatus = currentSortStatus === 'desc' ? 'asc' : 'desc';
    setSort([name, newSortStatus]);
  };

  const handleChangePerPage = ({ value }) => {
    setPerPage(value);
  };

  const getUserStatus = (user) => {
    if (!user.yCPeople) return 'No';
    return user.yCPeople.status;
  };

  const handleAction = (name, id) => () => {
    switch (name) {
      case 'accept':
        return acceptUser({ user: id });
      case 'decline':
        return declineUser({ user: id });
      case 'remove':
        return removeUser({ user: id });
      default:
        return console.log('action not defined');
    }
  };

  const downloadButton = () => (
    <Wrapper onClick={() => downloadReport()}>
      <Icon>
        <ReactSVG src={iconList.download} />
      </Icon>
      <Content>
        <p className="word-break">{t('page.yc.peopleList.reportTitle')}</p>
        <Margin top="4">
          <div className="link uppercase">{t('page.yc.peopleList.downloadReport')}</div>
        </Margin>
      </Content>
    </Wrapper>
  );

  const renderApply = (user) => (
    <>
      {user.yCPeople && user.yCPeople.status === APPLIED_STATUS ? (
        <Row>
          <Col size={{ md: 2 / 5, xl: 1 / 2 }}>
            <ButtonAction
              onClick={() => setAcceptConfirmModal(user.id)}
              title={t('global.acceptButton')}
              icon="checkCircle"
            />
          </Col>
          <Col size={{ md: 2 / 5, xl: 1 / 2 }}>
            <ButtonAction
              onClick={() => setDeclineConfirmModal(user.id)}
              title={t('global.declineButton')}
              icon="closeCircle"
            />
          </Col>
        </Row>
      ) : null}
    </>
  );

  const renderRemove = (user) => (
    <>
      {user.yCPeople && user.yCPeople.status === YES_STATUS ? (
        <Row>
          <Col size={{ md: 2 / 5, xl: 1 / 2 }}>
            <ButtonAction
              onClick={() => setRemoveConfirmModal(user.id)}
              title={t('global.removeButton')}
              icon="closeCircle"
            />
          </Col>
        </Row>
      ) : null}
    </>
  );

  return (
    <>
      <Container>
        <PageContent>
          <Alerts type="yc" />
          <p dangerouslySetInnerHTML={{ __html: t('page.yc.peopleList.info') }} />
          <Margin
            as="p"
            top={{ xs: 16 }}
            dangerouslySetInnerHTML={{ __html: t('page.yc.peopleList.info2') }}
          />
          <Margin top={{ xs: 16 }}>
            <FiltersCustom
              title={t('page.ma.peopleAdd.filterTitle')}
              filters={filtersProps}
              filterFields={filterFields}
              handleFilterFieldChange={handleFilterFieldChange}
              onFiltersApply={setFilters}
            />
          </Margin>
          <Margin top={{ xs: 16 }}>
            <RowStyled>
              {downloadButton()}
              <FiltersControls perPageOnChange={handleChangePerPage} perPageSelected={perPage} />
            </RowStyled>
          </Margin>
          <Table tablebreakpoint="lg">
            <Table.Head>
              <Table.Row>
                <Table.Heading sort={handleSortBy('firstLastName')}>
                  {t('page.yc.peopleList.firstAndLastName')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('email')}>
                  {t('page.yc.peopleList.email')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('createdAt')}>
                  {t('page.ma.peopleList.createdAt')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('certified')}>
                  {t('page.yc.peopleList.certified')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('status')}>
                  {t('page.yc.peopleList.membershipStatus')}
                </Table.Heading>
                <Table.Heading />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <Spinner />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && !items.length ? (
                <Table.Row>
                  <Table.Cell colSpan={4}>No results found</Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && items.length
                ? items.map((user) => (
                    <Table.Row key={user.id}>
                      <Table.Cell>
                        <Table.Label>{t('page.yc.peopleList.firstAndLastName')}:</Table.Label>
                        <Link to={`/user-profile/${user.uniqueUrl}`}>
                          {`${user.firstName} ${user.lastName}`}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.yc.peopleList.email')}:</Table.Label>
                        {user.email}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.ma.peopleUsers.createdAt')}:</Table.Label>
                        {user.createdAt && moment(user.createdAt).format('DD/MM/YYYY')}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.yc.peopleList.certified')}:</Table.Label>
                        {user.certificates.length ? 'Yes' : 'No'}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.yc.peopleList.membershipStatus')}:</Table.Label>
                        {getUserStatus(user)}
                      </Table.Cell>
                      <Table.Cell>
                        {user.yCUserRoles && user.yCUserRoles.length > 0 ? (
                          <ActionText>YC Officer</ActionText>
                        ) : (
                          <>
                            {renderApply(user)}
                            {renderRemove(user)}
                          </>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </PageContent>
      </Container>
      <Modal
        heading={t('page.yc.peopleList.modalAcceptUser')}
        isOpen={!!acceptConfirmModal}
        confirm={() => handleAction('accept', acceptConfirmModal)() && setAcceptConfirmModal(null)}
        cancel={() => setAcceptConfirmModal(null)}
      />
      <Modal
        heading={t('page.yc.peopleList.modalDeclineUser')}
        isOpen={!!declineConfirmModal}
        confirm={() =>
          handleAction('decline', declineConfirmModal)() && setDeclineConfirmModal(null)
        }
        cancel={() => setDeclineConfirmModal(null)}
      />
      <Modal
        heading={t('page.yc.peopleList.modalDeleteUser')}
        isOpen={!!removeConfirmModal}
        confirm={() => handleAction('remove', removeConfirmModal)() && setRemoveConfirmModal(null)}
        cancel={() => setRemoveConfirmModal(null)}
      />
    </>
  );
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loadUsers: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  acceptUser: PropTypes.func.isRequired,
  declineUser: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  sort: PropTypes.arrayOf([PropTypes.string, PropTypes.string]),
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilters: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};
List.defaultProps = {
  sort: [],
};

const mapStateToProps = ({ yc, loading }) => ({
  ...yc.people.list,
  loading: !!loading.filter((item) => item.type === 'YC').length,
});
const mapDispatchToProps = (dispatch) => ({
  loadUsers: () => dispatch(loadYcMembersListCreator()),
  setSort: (payload) => dispatch(setYcPeopleListSortCreator(payload)),
  acceptUser: (payload) => dispatch(acceptYcPeopleListUserCreator(payload)),
  declineUser: (payload) => dispatch(declineYcPeopleListUserCreator(payload)),
  removeUser: (payload) => dispatch(removeYcPeopleListUserCreator(payload)),
  setPage: (payload) => dispatch(setYcPeopleListPageCreator(payload)),
  setPerPage: (payload) => dispatch(setYcPeopleListPerPageCreator(payload)),
  downloadReport: () => dispatch(downloadYcPeopleReportCreator('people')),
  setFilters: (payload) => dispatch(setYcPeopleListFiltersCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
