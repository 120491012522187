import urljoin from 'url-join';
import qs from 'qs';
import ky from './kyInstance';
import { BASE_PATH, withAuthorizationHeader } from '..';

export const getBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'menu-bar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getUsers = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'user', 'list', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const changeUserRole = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'manage', 'member-association', 'user', 'change-role'), {
    headers: withAuthorizationHeader({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(params),
  });
};

export const removeUser = async (id) => {
  return ky.delete(
    urljoin(BASE_PATH, 'manage', 'member-association', 'user', 'remove', id.toString()),
    { headers: withAuthorizationHeader() },
  );
};

export const getList = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'people', 'list', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const getMembers = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(
    urljoin(BASE_PATH, 'manage', 'member-association', 'ma-members', 'list', `?${query}`),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const acceptListUser = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'manage', 'member-association', 'people', 'accept'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const declineListUser = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'manage', 'member-association', 'people', 'decline'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const removeListUser = async ({ user }) => {
  return ky.delete(
    urljoin(BASE_PATH, 'manage', 'member-association', 'people', 'remove', String(user)),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const removeMembersUser = async ({ user }) => {
  return ky.delete(
    urljoin(BASE_PATH, 'manage', 'member-association', 'ma-members', 'remove', String(user)),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const getAddListUsers = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'user', 'find', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const addUsers = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'manage', 'member-association', 'user', 'add'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
    body: JSON.stringify(params),
  });
};

export const getProfile = async () => {
  return ky.get(urljoin(BASE_PATH, 'member-association', 'profile'), {
    headers: withAuthorizationHeader(),
  });
};

export const updateProfile = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'member-association', 'profil', 'edit'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const createEvent = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'new'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getDashboard = async () => {
  return ky.get(urljoin(BASE_PATH, 'member-association', 'dashboard'), {
    headers: withAuthorizationHeader(),
  });
};

export const getEventsBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'event', 'member-association', 'menu-bar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getEvents = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'event', 'member-association', 'list', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const cancelEvent = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'event', String(params), 'cancel'), {
    headers: withAuthorizationHeader(),
  });
};

export const reopenEvent = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'event', String(params), 'reopen'), {
    headers: withAuthorizationHeader(),
  });
};

export const getEvent = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'event', String(params)), {
    headers: withAuthorizationHeader(),
  });
};

export const editEvent = async (body, id) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'edit', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const duplicateEvent = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'duplicate'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const applyUserToMa = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'manage', 'member-association', 'people', 'apply'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getOrganisations = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(
    urljoin(BASE_PATH, 'manage', 'member-association', 'organisation', 'list', `?${query}`),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const getProfileUpcomingEvents = async () => {
  return ky.get(urljoin(BASE_PATH, 'event', 'member-association', 'upcoming'), {
    headers: withAuthorizationHeader(),
  });
};

export const getCoursesBar = async (id) => {
  return ky.get(
    urljoin(BASE_PATH, 'member-association', String(id), 'courses', 'assignee', 'count'),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const getCourses = async (id, type, params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'member-association', String(id), 'courses', type), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getAssessors = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'member-association', String(id), 'assesors', 'list'), {
    headers: withAuthorizationHeader(),
  });
};

export const changeAssessor = async (type, courseId, userId) => {
  return ky.get(urljoin(BASE_PATH, 'course', 'assesor', type, String(courseId), String(userId)), {
    headers: withAuthorizationHeader(),
  });
};

export const acceptCourse = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'course', String(id), 'accept'), {
    headers: withAuthorizationHeader(),
  });
};

export const cancelCourse = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'course', String(id), 'cancel'), {
    headers: withAuthorizationHeader(),
  });
};

export const sendReport = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ma', 'send-report'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const updateReport = async (id, body) => {
  return ky.post(urljoin(BASE_PATH, 'ma', 'edit-report', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const downlodMaPeopleReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'people', 'export'), {
    headers: withAuthorizationHeader(),
  });
};

export const downlodMaMembersReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'ma-members', 'export'), {
    headers: withAuthorizationHeader(),
  });
};

export const downlodMaYoungCrewReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'user', 'exportYoungCrew'), {
    headers: withAuthorizationHeader(),
  });
};

export const downlodMaUsersReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'user', 'exportUsers'), {
    headers: withAuthorizationHeader(),
  });
};

export const downlodMaOrganisationsReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'manage', 'member-association', 'organisation', 'export'), {
    headers: withAuthorizationHeader(),
  });
};

export const getMaReportsList = async () => {
  return ky.get(urljoin(BASE_PATH, 'ma', 'report-list'), {
    headers: withAuthorizationHeader(),
  });
};

export const getMaReport = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'ma', 'report', String(params)), {
    headers: withAuthorizationHeader(),
  });
};

export const getMaLastReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'ma', 'latest-report'), {
    headers: withAuthorizationHeader(),
  });
};

export const canSendReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'ma', 'report-schedule'), {
    headers: withAuthorizationHeader(),
  });
};

export const cisRedirect = async () => {
  return ky.get(urljoin(BASE_PATH, 'sso', 'cis', 'connect'), {
    headers: withAuthorizationHeader(),
  });
};

export const getPeopleYcList = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(
    urljoin(BASE_PATH, 'manage', 'member-association', 'user', 'list-yc', `?${query}`),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const exportReport = async (id) => {
  const url = id
    ? urljoin(BASE_PATH, 'ma', 'report-export', String(id))
    : urljoin(BASE_PATH, 'ma', 'report-export');
  return ky.get(url, {
    headers: withAuthorizationHeader(),
  });
};
