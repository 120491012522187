import urljoin from 'url-join';
import qs from 'qs';
import { BASE_PATH, withAuthorizationHeader } from '..';
import ky from './kyInstance';

export const getProfile = async () => {
  return ky.get(urljoin(BASE_PATH, 'user', 'details'), {
    headers: withAuthorizationHeader(),
  });
};

export const updateProfile = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'profile'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const updatePassword = async (body) => {
  return ky.put(urljoin(BASE_PATH, 'user', 'profile', 'reset-password'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  });
};

export const updateUserEmail = async (body) => {
  return ky.put(urljoin(BASE_PATH, 'user', 'update-user-email'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  });
};

export const validateDeleteUserProfile = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'validate', 'delete-account'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  });
};

export const deleteUserProfile = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'delete-account'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  });
};

export const getNotifications = async () => {
  return ky.get(urljoin(BASE_PATH, 'notification', 'list'), {
    headers: withAuthorizationHeader(),
  });
};

export const removeNotification = async (id) => {
  return ky.delete(urljoin(BASE_PATH, 'notification', 'remove', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const removeAllNotifications = async () => {
  return ky.delete(urljoin(BASE_PATH, 'notification', 'remove-all'), {
    headers: withAuthorizationHeader(),
  });
};

export const getCertifications = async () => {
  return ky.get(urljoin(BASE_PATH, 'user', 'get-certificates'), {
    headers: withAuthorizationHeader(),
  });
};

export const getAddListCertificates = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'find-certificates'), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};

export const removeCertification = async (id) => {
  return ky.delete(urljoin(BASE_PATH, 'user', 'remove-certificate', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const addCertificates = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'add-certificate'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getDashboardCourses = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'user', 'dashboard', 'courses'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getDashboardEvents = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'user', 'dashboard', 'events'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const applyUserToCourse = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'course', 'apply-to-join', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const declineUserFromCourse = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'course', 'decline-join', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getProfileCompletedCourses = async () => {
  return ky.get(urljoin(BASE_PATH, 'user', 'completed-courses'), {
    headers: withAuthorizationHeader(),
  });
};

export const getCoursesBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'user', 'count', 'courses'), {
    headers: withAuthorizationHeader(),
  });
};

export const getCourses = async (type, params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'user', 'courses', type), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getBadges = async () => {
  return ky.get(urljoin(BASE_PATH, 'user', 'my-badges'), {
    headers: withAuthorizationHeader(),
  });
};

export const claimBadge = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'my-badges', 'sso-url'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const getOrganizations = async () => {
  return ky.get(urljoin(BASE_PATH, 'user', 'organizations'), {
    headers: withAuthorizationHeader(),
  });
};

export const pendingBadgesList = async () => {
  return ky.get(urljoin(BASE_PATH, 'event', 'new-badges', 'list'), {
    headers: withAuthorizationHeader(),
  });
};

export const claimEventBadge = async ({ id }) => {
  return ky.put(urljoin(BASE_PATH, 'event', String(id), 'event-participant', 'claim-badge'), {
    headers: withAuthorizationHeader(),
  });
};

export const rejectEventBadge = async ({ id }) => {
  return ky.put(urljoin(BASE_PATH, 'event', String(id), 'event-participant', 'reject-badge'), {
    headers: withAuthorizationHeader(),
  });
};

export const pendingAwardBadgesList = async () => {
  return ky.get(urljoin(BASE_PATH, 'award', 'new-badges', 'list'), {
    headers: withAuthorizationHeader(),
  });
};

export const claimAwardBadge = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'award', 'award-participant', 'claim-badge'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const rejectAwardBadge = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'award', 'award-participant', 'reject-badge'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const loadCourseBadgeList = async () => {
  return ky.get(urljoin(BASE_PATH, 'course', 'my-courses', 'list'), {
    headers: withAuthorizationHeader(),
  });
};

export const claimCourseBadge = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'course', 'course-participant', 'claim-badge'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const rejectCourseBadge = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'course', 'course-participant', 'reject-badge'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const supressCourseBadge = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'course', 'course-participant', 'dont-remind-badge'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const suppressCoupon = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'coupon', 'dont-remind-coupon'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const claimAssessorBadge = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'assessor-badge', 'claim-badge'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const rejectAssessorBadge = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'assessor-badge', 'reject-badge'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const getUserPendingBadges = async () => {
  return ky.get(urljoin(BASE_PATH, 'user', 'my-new-badges'), {
    headers: withAuthorizationHeader(),
  });
};

export const claimHonoraryFellowBadge = async (params) => {
  return ky.put(
    urljoin(BASE_PATH, 'honoraryFellowBadge', 'honoraryFellowBadge-participant', 'claim-badge'),
    {
      headers: withAuthorizationHeader(),
      body: JSON.stringify(params),
    },
  );
};

export const rejectHonoraryFellowBadge = async (params) => {
  return ky.put(
    urljoin(BASE_PATH, 'honoraryFellowBadge', 'honoraryFellowBadge-participant', 'reject-badge'),
    {
      headers: withAuthorizationHeader(),
      body: JSON.stringify(params),
    },
  );
};

export const downloadCourseDiploma = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'course', 'course-participant', 'claim-diploma'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const getListNewCertificates = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'find-new-certificates'), {
    headers: withAuthorizationHeader({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(params),
  });
};
