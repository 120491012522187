import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logoutUserCreator } from 'store/actionsCreators';

function Logout({ logoutUser, history }) {
  useEffect(() => {
    logoutUser(history);
  }, []);

  return <Redirect to="/auth/login" />;
}

Logout.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (history) => dispatch(logoutUserCreator(history)),
});

export default connect(null, mapDispatchToProps)(Logout);
