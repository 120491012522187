import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const defaultMapOptions = {
  disableDefaultUI: true,
};

const MapBox = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      options={defaultMapOptions}
    >
      <Marker position={{ lat: props.lat, lng: props.lng }} />
    </GoogleMap>
  )),
);

const Map = ({ lat, lng }) => {
  const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

  if (!GOOGLE_MAP_API_KEY || !lat || !lng) return null;

  return (
    <MapBox
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `220px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      lat={+lat}
      lng={+lng}
    />
  );
};

export default Map;
