import styled, { css } from 'styled-components';
import { mt, mb, p } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  ${({ theme }) => theme && css`
    ${mb(16)};

    display: flex;
    margin-bottom: 16px;
    
    &:last-of-type {
      ${mb(0)};
    }
    
    ${({ news }) =>
      news &&
      css`
        ${Content} {
          background-color: #4f4e5c;
          color: #fff;
          justify-content: start;
        }
        
        ${Description} {
          color: #fff;
          
          a {
            color: ${theme.colors.secondary};
          }
        }
        
        ${Thumbnail} {
          display: none;

          ${breakpoint('md')`
            ${css`
              display: flex;
            `}
          `}
        }
      `
    }
  `}
`;

export const Thumbnail = styled.div`
  ${p(12)};

  flex: 0 0 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 120px;
  height: 120px;
  background-color: #fff;
  
  img {
    max-height: 100%;
  }
`;

export const Content = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${p(16)};

      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `
  }
`;

export const Description = styled.p`
  ${({ theme }) =>
  theme &&
  css`
      ${mt(8)};

      font-size: ${theme.font.size.pixel('14')};
      font-size: ${theme.font.weight.light};
      line-height: 18px;
      color: ${theme.colors.gray[200]};

      a {
        font-weight: ${theme.font.weight.semibold};
        color: ${theme.colors.primaries.darker};
      }
    `}
`;