import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Container, Col, Heading, Row } from 'components';
import { iconList as icons } from 'assets';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Content, { Form, SubHeading, Message } from './Newsletter.styled';
import { appAddToNewsletterCreator } from '../../store/actionsCreators';

function Newsletter({ appAddToNewsletter }) {
  const { t } = useTranslation();
  const [msg, setMsg] = useState(null);
  const inputEl = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let token = null;

    if (process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
      token = await executeRecaptcha('newsletter');
    }

    appAddToNewsletter({ email: inputEl.current.value, token }, (cbMsg) => {
      setMsg(cbMsg);

      setTimeout(() => setMsg(null), 5000);
    });
  };

  return (
    <Content>
      <Container>
        <Row>
          <Col size={{ md: 3 / 5, lg: 1 / 2 }}>
            <Heading as="h3" color="inherit" noLine>
              {t('global.newsletter.title')}
            </Heading>
            <SubHeading>{t('global.newsletter.subTitle')}</SubHeading>
          </Col>
          <Col size={{ md: 2 / 5, lg: 1 / 2 }}>
            <Form onSubmit={handleSubmit}>
              <input type="text" ref={inputEl} placeholder={t('global.newsletter.placeholder')} />
              <button type="submit">
                <span className="arrow">
                  <ReactSVG src={icons.arrowRightCircle} />
                </span>
                <span className="text">{t('global.newsletter.button')}</span>
              </button>
            </Form>
            {msg ? <Message type={msg.type}>{msg.message}</Message> : null}
          </Col>
        </Row>
      </Container>
    </Content>
  );
}

Newsletter.propTypes = {
  appAddToNewsletter: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  appAddToNewsletter: (payload, cb) => dispatch(appAddToNewsletterCreator(payload, cb)),
});

export default connect(null, mapDispatchToProps)(Newsletter);
