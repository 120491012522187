import styled, { css } from 'styled-components';
import { mb, px, } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div``;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px -12px;
`;

export const Item = styled.li`
  ${mb(12)};  
  ${px(8)};  
  
  flex: 0 0 100%;

  ${breakpoint('sm')`
    ${css`
      flex: 0 0 50%;
    `}
  `}
`;