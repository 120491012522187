import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Alerts,
  ButtonAction,
  Select,
  Modal,
  Container,
  PageContent,
  Pagination,
  Table,
  Spinner,
  FiltersControls,
  FiltersCustom,
} from 'components';
import {
  loadYcPeopleUsersCreator,
  loadGlobalYcRolesCreator,
  setYcPeopleUsersSortCreator,
  changeYcPeopleUsersRoleCreator,
  deleteYcPeopleUserCreator,
  setYcPeopleUsersPageCreator,
  setYcPeopleUsersPerPageCreator,
  downloadYcPeopleReportCreator,
  setYcPeopleUsersFiltersCreator,
} from 'store/actionsCreators';
import { useFormFields } from 'helpers';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Content, Wrapper, Icon, RowStyled } from '../List/List.styled';

function Users({
  items,
  page,
  pages,
  perPage,
  sort,
  loadUsers,
  roles,
  loadGlobalMaRoles,
  setSort,
  loading,
  filters,
  changeRole,
  deleteUser,
  setPage,
  setPerPage,
  downloadReport,
  setFilters,
}) {
  const { t } = useTranslation();
  const [removeConfirmModal, setRemoveConfirmModal] = useState(null);
  const [filterFields, handleFilterFieldChange] = useFormFields({
    firstName: '',
    lastName: '',
    email: '',
    ycFrom: null,
    role: null,
  });

  const filtersProps = {
    firstName: {
      type: 'input',
      name: 'first-name',
      id: 'f-name',
      label: t('page.yc.peopleUsers.firstName'),
      placeholder: t('forms.firstNamePlaceholder'),
    },
    lastName: {
      type: 'input',
      name: 'last-name',
      id: 'f-lastName',
      label: t('page.yc.peopleUsers.lastName'),
      placeholder: t('forms.lastNamePlaceholder'),
    },
    email: {
      type: 'input',
      name: 'email',
      id: 'f-email',
      label: t('page.yc.peopleUsers.email'),
      placeholder: t('forms.lastNamePlaceholder'),
    },
    ycFrom: {
      type: 'datepicker',
      name: 'ycFrom',
      id: 'f-ycFrom',
      label: t('page.yc.peopleUsers.maFrom'),
      placeholder: t('forms.createdFromPlaceholder'),
    },
    role: {
      type: 'select',
      name: 'role',
      id: 'f-role',
      label: t('page.yc.peopleUsers.role'),
      options: roles,
    },
  };

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadUsers();
  }, [page, perPage, sort]);

  useEffect(() => {
    if (!roles.length) {
      loadGlobalMaRoles();
    }
  }, []);

  useEffect(() => {
    setPage(1);
    loadUsers();
  }, [filters]);

  const handleSortBy = (name) => () => {
    const [currentSortName, currentSortStatus] = sort || [];
    const sortByNewColumn = currentSortName !== name;

    if (sortByNewColumn) {
      setSort([name, 'desc']);
      return;
    }

    const newSortStatus = currentSortStatus === 'desc' ? 'asc' : 'desc';
    setSort([name, newSortStatus]);
  };

  const handleChangeRole = (userId, oldRoleId) => ({ value }) => {
    changeRole({ user: userId, oldRole: oldRoleId, role: value });
  };

  const handleDeleteUser = (id) => () => {
    deleteUser(id);
    return true;
  };

  const handleChangePerPage = ({ value }) => {
    setPerPage(value);
  };

  const loadRoles = (_roles, rolesArr, currentId) => {
    const filterRoles = rolesArr.filter(({ role }) => role.id !== currentId);
    const list = _roles.filter(({ value }) => {
      const check = filterRoles.filter(({ role }) => role.id === value);
      if (check.length) {
        return false;
      }
      return true;
    });
    if (list.length && rolesArr.length >= 2) {
      list.unshift({
        label: 'None',
        value: 0,
      });
    }
    return list;
  };

  const downloadButton = () => (
    <Wrapper onClick={() => downloadReport()}>
      <Icon>
        <ReactSVG src={iconList.download} />
      </Icon>
      <Content>
        <p className="word-break">{t('page.yc.peopleUsers.reportTitle')}</p>
        <Margin top="4">
          <div className="link uppercase">{t('page.yc.peopleList.downloadReport')}</div>
        </Margin>
      </Content>
    </Wrapper>
  );

  return (
    <>
      <Container>
        <PageContent>
          <Alerts type="yc" />
          <Margin top={{ xs: 16 }} bottom={{ xs: 16 }}>
            <FiltersCustom
              title={t('page.yc.peopleUsers.filterTitle')}
              filters={filtersProps}
              filterFields={filterFields}
              handleFilterFieldChange={handleFilterFieldChange}
              onFiltersApply={setFilters}
            />
          </Margin>
          <RowStyled>
            {downloadButton()}
            <FiltersControls perPageOnChange={handleChangePerPage} perPageSelected={perPage} />
          </RowStyled>
          <Table tablebreakpoint="lg">
            <Table.Head>
              <Table.Row>
                <Table.Heading sort={handleSortBy('firstLastName')}>
                  {t('page.yc.peopleUsers.firstAndLastName')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('email')}>
                  {t('page.yc.peopleUsers.email')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('role')}>
                  {t('page.yc.peopleUsers.role')}
                </Table.Heading>
                <Table.Heading />
                <Table.Heading sort={handleSortBy('date')}>
                  {t('page.yc.peopleUsers.maFrom')}
                </Table.Heading>
                <Table.Heading />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <Spinner />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && !items.length ? (
                <Table.Row>
                  <Table.Cell colSpan={6}>{t('global.noResultsFound')}</Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && items.length
                ? items.map((user) => (
                    <Table.Row key={user.id}>
                      <Table.Cell>
                        <Table.Label>{t('page.yc.peopleUsers.firstAndLastName')}:</Table.Label>
                        <Link to={`/user-profile/${user.uniqueUrl}`}>
                          {`${user.firstName} ${user.lastName}`}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.yc.peopleUsers.email')}:</Table.Label>
                        {user.email}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.yc.peopleUsers.role')}:</Table.Label>
                        <Select
                          small
                          value={user.yCUserRoles[0] ? user.yCUserRoles[0].role : null}
                          onChange={handleChangeRole(
                            user.id,
                            user.yCUserRoles[0] ? user.yCUserRoles[0].role.id : 0,
                          )}
                          options={loadRoles(
                            roles,
                            user.yCUserRoles,
                            user.yCUserRoles[0] ? user.yCUserRoles[0].role.id : 0,
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Select
                          small
                          value={user.yCUserRoles[1] ? user.yCUserRoles[1].role : null}
                          onChange={handleChangeRole(
                            user.id,
                            user.yCUserRoles[1] ? user.yCUserRoles[1].role.id : 0,
                          )}
                          options={loadRoles(
                            roles,
                            user.yCUserRoles,
                            user.yCUserRoles[1] ? user.yCUserRoles[1].role.id : 0,
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.yc.peopleUsers.maFrom')}:</Table.Label>
                        {user.yCUserRoles[0].dateFrom &&
                          moment(user.yCUserRoles[0].dateFrom).format('DD/MM/YYYY')}
                      </Table.Cell>
                      <Table.Cell>
                        <ButtonAction
                          onClick={() => setRemoveConfirmModal(user.id)}
                          title={t('global.removeButton')}
                          icon="closeCircle"
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </PageContent>
      </Container>
      <Modal
        heading={t('page.yc.peopleUsers.modalRemoveHeading')}
        isOpen={!!removeConfirmModal}
        confirm={() => handleDeleteUser(removeConfirmModal)() && setRemoveConfirmModal(null)}
        cancel={() => setRemoveConfirmModal(null)}
      />
    </>
  );
}

Users.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  sort: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  loadUsers: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }))
    .isRequired,
  loadGlobalMaRoles: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeRole: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

Users.defaultProps = {};

const mapStateToProps = ({ yc, globals, loading }) => ({
  ...yc.people.users,
  roles: globals.yc.roles,
  loading: !!loading.filter((item) => item.type === 'YC').length,
});

const mapDispatchToProps = (dispatch) => ({
  loadUsers: () => dispatch(loadYcPeopleUsersCreator()),
  loadGlobalMaRoles: () => dispatch(loadGlobalYcRolesCreator()),
  setSort: (payload) => dispatch(setYcPeopleUsersSortCreator(payload)),
  changeRole: (payload) => dispatch(changeYcPeopleUsersRoleCreator(payload)),
  deleteUser: (payload) => dispatch(deleteYcPeopleUserCreator(payload)),
  setPage: (payload) => dispatch(setYcPeopleUsersPageCreator(payload)),
  setPerPage: (payload) => dispatch(setYcPeopleUsersPerPageCreator(payload)),
  downloadReport: () => dispatch(downloadYcPeopleReportCreator('users')),
  setFilters: (payload) => dispatch(setYcPeopleUsersFiltersCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
