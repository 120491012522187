import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Col, Modal, Row, Heading, ButtonAction, Spinner } from 'components';
import ModalContent from 'components/Modal/Modal.styled';
import { useTranslation } from 'react-i18next';
import { isLoading, globalPropTypes } from 'helpers';
import { connect } from 'react-redux';
import { setOpenPendingCouponCreator, suppressCouponCreator } from 'store/actionsCreators';
import { Button, CouponStyle, CouponWrapper } from './PendingCoupons.styled';

const PendingCoupons = ({ user, loading, openModal, setOpenModal, suppressCoupon }) => {
  const { coupon } = user;
  const { t } = useTranslation();
  const { name: couponName } = coupon || {};

  useEffect(() => {}, [openModal, setOpenModal]);

  const badgeSuppress = (issuppress) => {
    suppressCoupon(issuppress);
    setOpenModal(false);
  };

  const gotoShop = () => {
    window.open('https://shop.ipma.world/product-category/ipma-store/', '_blank').focus();
  };

  const renderContent = () => (
    <ModalContent>
      {!loading ? (
        <>
          <Heading as="h4" noLine center>
            {t('global.pendingCoupon')}
          </Heading>
          <CouponWrapper top="12">
            <CouponStyle as="h5" center>
              {couponName}
            </CouponStyle>
          </CouponWrapper>
          <Row halign="center">
            {couponName ? (
              <Margin bottom="3">
                <Button onClick={gotoShop}>{t('page.user.dashboard.gotoShop')}</Button>
              </Margin>
            ) : null}
          </Row>
          <Margin>
            <Row halign="center">
              <Col size={{ xl: 1 / 4 }}>
                <Margin bottom={{ xs: 8 }}>
                  <ButtonAction
                    icon="clock"
                    title={t('global.remindMeButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => {
                      badgeSuppress(true);
                    }}
                  />
                </Margin>
              </Col>
              <Col size={{ xl: 1 / 4 }}>
                <Margin bottom={{ xs: 8 }}>
                  <ButtonAction
                    icon="bellSlashed"
                    title={t('global.dontRemindMeButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => badgeSuppress(false)}
                  />
                </Margin>
              </Col>
            </Row>
          </Margin>
        </>
      ) : (
        <Spinner />
      )}
    </ModalContent>
  );

  const selectBadgeType = () => {
    if (couponName !== undefined) {
      return renderContent();
    } else {
      return <></>;
    }
  };

  return (
    <Modal
      isOpen={openModal}
      customContent={selectBadgeType}
      onRequestClose={() => setOpenModal(false)}
    />
  );
};

PendingCoupons.propTypes = {
  user: globalPropTypes.UserPropsTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  suppressCoupon: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, loading }) => ({
  user: user.user,
  loading: isLoading(loading, 'CLAIM_BADGE'),
  openModal: false,
});

const mapDispatchToProps = (dispatch) => ({
  setOpenModal: (payload) => dispatch(setOpenPendingCouponCreator(payload)),
  suppressCoupon: (payload) => dispatch(suppressCouponCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingCoupons);
