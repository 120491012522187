import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Badge, Button, Row, Col } from 'components';
import { Wrapper } from './BadgeList.styled';

const BadgeList = ({ items, alert, claimFn, share, showLimit }) => {
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    if (showLimit) {
      setCurrentItems(items.slice(0, showLimit));
    } else {
      setCurrentItems(items);
    }
  }, [items]);

  const loadMore = () =>
    setCurrentItems(
      currentItems.concat(items.slice(currentItems.length, currentItems.length + showLimit)),
    );

  if (!items || !items.length) {
    return alert ? <Alert type="empty">{alert}</Alert> : null;
  }

  return (
    <>
      <Wrapper>
        {currentItems.map((item, index) => (
          <Badge
            key={index}
            title={item.title}
            image={item.image}
            url={item.url}
            claimFn={item.type === 'pending' && claimFn ? () => claimFn(item) : null}
            share={item.type === 'accepted' && share}
            shareUrl={item.shareUrl}
          />
        ))}
      </Wrapper>
      {showLimit > 0 && currentItems.length !== items.length ? (
        <Row>
          <Col size={{ xs: 2 / 5 }} style={{ margin: 'auto' }}>
            <Button onClick={() => loadMore()}>Show more</Button>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

BadgeList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  alert: PropTypes.string,
  claimFn: PropTypes.func,
  share: PropTypes.bool,
  showLimit: PropTypes.number,
};

BadgeList.defaultProps = {
  items: [],
  alert: '',
  claimFn: () => {},
  share: false,
  showLimit: 0,
};

export default BadgeList;
