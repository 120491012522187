import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing';
import { useParams } from 'react-router-dom';
import { Container, Header, PageContent, Table, Heading, Button, Field, Spinner } from 'components';
import { loadMaReportCreator, verifyReportCreator } from 'store/actionsCreators';
import { isLoading, checkPermissions, globalPropTypes } from 'helpers';
import qs from 'qs';

const ReadReport = ({
  report,
  loadReport,
  verifyReport,
  location,
  userPermissions,
  appType,
  history,
  loading,
}) => {
  const [kamNotes, setKamNotes] = useState('');
  const { t } = useTranslation();
  const { id } = useParams();
  const { status } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.ma.reports',
      url: `/${location.pathname.split('/')[1]}/reports`,
    },
    {
      title: 'navigation.breadcrumbs.ma.readReport',
      param: { title: report.version },
      url: '',
    },
  ];

  useEffect(() => {
    setKamNotes('');
    loadReport({ id, editReport: false });
  }, []);

  useEffect(() => {
    setKamNotes(report.kamNotes ? report.kamNotes : '');
  }, [report]);

  const handleVerifyReport = () => {
    verifyReport(id, kamNotes, history);
  };

  const renderRow = (category, field) => {
    return (
      <>
        <Table.Row key={`${category}_${field}`}>
          {report.items[category][field] !== null &&
          typeof report.items[category][field] === 'object' ? (
            <>
              <Table.Cell style={{ fontWeight: 600, width: '300px' }}>
                {t(`page.ma.addReport.${field}`)}
              </Table.Cell>
              {Object.keys(report.items[category][field]).map((year) => (
                <>
                  <Table.Cell>
                    <Table.Label>{t(`page.ma.addReport.${year}`, year)}:</Table.Label>
                    {report.items[category][field][year]
                      ? report.items[category][field][year]
                      : 'N / A'}
                  </Table.Cell>
                </>
              ))}
            </>
          ) : (
            <>
              <Table.Cell style={{ fontWeight: 600, width: '300px' }}>
                {t('page.ma.addReport.additionalNotes')}
              </Table.Cell>
              <Table.Cell colSpan={3} style={{ lineHeight: '1.4rem' }}>
                {report.items[category][field] ? report.items[category][field] : '-'}
              </Table.Cell>
            </>
          )}
        </Table.Row>
      </>
    );
  };

  const renderTable = (category) => (
    <Table tablebreakpoint="lg">
      <Table.Head>
        <Table.Row>
          <Table.Heading />
          <Table.Heading>
            {t('page.ma.addReport.actualYearReport', { year: report.actualYear })}
          </Table.Heading>
          <Table.Heading>
            {t('page.ma.addReport.forecastYearReport', { year: report.forecastYear })}
          </Table.Heading>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {Object.keys(report.items[category]).map((field) => renderRow(category, field))}
      </Table.Body>
    </Table>
  );

  const renderKamFields = () =>
    checkPermissions(
      {
        roles: ['ipma_ma_members_report_verify'],
      },
      userPermissions,
    ) ? (
      <>
        <Margin bottom="20">
          <Field label={t('page.ma.readReport.kamNotes')}>
            <textarea
              id="kam-notes"
              placeholder={t('page.ma.addReport.placeholderTextArea')}
              value={kamNotes}
              onChange={(e) => setKamNotes(e.target.value)}
              disabled={appType !== 'ipma'}
            />
          </Field>
        </Margin>
        {appType === 'ipma' ? (
          <Button
            onClick={() => handleVerifyReport()}
            style={{ width: 'auto', letterSpacing: '1.4px', padding: '18px 40px' }}
          >
            {status === 'true' ? t('global.update') : t('global.verifyButton')}
          </Button>
        ) : null}
      </>
    ) : (
      <Margin bottom="20">
        <Field label={t('page.ma.readReport.kamNotes')}>
          <textarea
            id="kam-notes"
            placeholder={t('page.ma.addReport.placeholderTextArea')}
            value={kamNotes}
            onChange={(e) => setKamNotes(e.target.value)}
            disabled
          />
        </Field>
      </Margin>
    );

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.ma.readReport.title', { title: `${report.version} - ${report.maName}` })}
      />
      <Container>
        <PageContent>
          {!loading ? (
            <>
              <Margin bottom={{ xs: 32 }}>
                {report.items
                  ? Object.keys(report.items).map((category) => (
                      <Margin bottom="42">
                        <Margin bottom="24">
                          <Heading size="h4">{t(`page.ma.addReport.${category}`)}</Heading>
                        </Margin>
                        {renderTable(category)}
                      </Margin>
                    ))
                  : null}
              </Margin>
              {renderKamFields()}
            </>
          ) : (
            <Margin top={{ xs: 42 }} bottom={{ xs: 42 }}>
              <Spinner />
            </Margin>
          )}
        </PageContent>
      </Container>
    </>
  );
};

ReadReport.propTypes = {
  report: PropTypes.shape({
    version: PropTypes.string,
    previousYear: PropTypes.string,
    actualYear: PropTypes.string,
    forecastYear: PropTypes.string,
    kamNotes: PropTypes.string,
    maName: PropTypes.string,
    items: PropTypes.shape({}),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  loadReport: PropTypes.func.isRequired,
  verifyReport: PropTypes.func.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
  appType: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ ma, user, loading }) => ({
  loading: isLoading(loading, 'IPMA_REPORT'),
  report: ma.report,
  userPermissions: user.userPermissions,
  appType: user.appType,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (payload) => dispatch(loadMaReportCreator(payload)),
  verifyReport: (id, kamNotes, history) => dispatch(verifyReportCreator(id, kamNotes, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReadReport);
