import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Header,
  PageContent,
  Alerts,
  Table,
  Spinner,
  ButtonAction,
  Toggle,
  Modal,
  Alert,
  Heading,
  Dropdown,
  TagButton,
  FiltersCustom,
} from 'components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Margin } from 'styled-components-spacing';
import {
  loadIpmaReportsListCreator,
  loadGlobalCountriesCreator,
  loadGlobalAvailableMACreator,
  toggleReportInvoiceCreator,
  archiveReportCreator,
  exportReportCreator,
  setIpmaReportsFiltersCreator,
} from 'store/actionsCreators';
import { isLoading, checkPermissions, globalPropTypes, useFormFields } from 'helpers';
import { StyledRow, StyledSecondRow, FlexLeft, Actions, GroupByButtons } from './Reports.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.reports',
    url: '',
  },
];

const Reports = ({
  reports,
  countries,
  ma,
  loading,
  userPermissions,
  loadReports,
  loadGlobalCountries,
  loadGlobalAvailableMA,
  setInvoice,
  archiveReport,
  exportReport,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const [archiveConfirmModal, setArchiveConfirmModal] = useState(null);
  const [reportsItems, setReportsItems] = useState([]);
  const [groupedBy, setGroupedBy] = useState('year');
  const [sortBy, setSortBy] = useState(['createdAt', 'desc']);

  const [filterFields, handleFilterFieldChange] = useFormFields({
    country: null,
    memberAssociation: null,
    createdAfter: null,
    reportStatus: null,
    invoice: null,
  });

  const filtersProps = {
    country: {
      type: 'select',
      name: 'country',
      id: 'f-country',
      label: t('page.ipma.reports.country'),
      placeholder: t('forms.countryPlaceholder'),
      options: countries,
    },
    memberAssociation: {
      type: 'select',
      name: 'memberAssociation',
      id: 'f-memberAssociation',
      label: t('page.ipma.reports.memberAssociation'),
      placeholder: t('forms.memberAssociationPlaceholder'),
      options: ma,
    },
    createdAfter: {
      type: 'datepicker',
      name: 'createdAfter',
      id: 'f-createdAfter',
      label: t('page.ipma.reports.createdAfter'),
      placeholder: t('forms.createdFromPlaceholder'),
    },
    reportStatus: {
      type: 'select',
      name: 'reportStatus',
      id: 'f-reportStatus',
      label: t('page.ipma.reports.reportStatus'),
      placeholder: t('forms.statusPlaceholder'),
      options: [
        {
          value: 'waiting',
          label: 'Waiting for verification',
        },
        {
          value: 'verified',
          label: 'Verified',
        },
        {
          value: 'archived',
          label: 'Archived',
        },
      ],
    },
    invoice: {
      type: 'select',
      name: 'invoice',
      id: 'f-invoice',
      label: t('page.ipma.reports.invoiceStatus'),
      placeholder: t('forms.statusPlaceholder'),
      options: [
        {
          value: 'paid',
          label: 'Paid',
        },
        {
          value: 'notpaid',
          label: 'Not paid',
        },
      ],
    },
  };

  const handleReportItems = (value) => {
    const toSet = reports.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
    setReportsItems(toSet);
    return toSet;
  };

  const handleSelectChange = (field) => (selected) => {
    handleFilterFieldChange({
      target: {
        id: field,
        value: selected,
      },
    });
  };

  useEffect(() => {
    loadReports();

    if (!countries.length) {
      loadGlobalCountries();
    }

    if (!ma.length) {
      loadGlobalAvailableMA();
    }
  }, []);

  useEffect(() => {
    loadReports();
  }, [filters]);

  const parseDateTime = (date) => {
    const dateToParse = new Date(date);
    const month = dateToParse.getUTCMonth() + 1;
    const day = dateToParse.getUTCDate();
    const year = dateToParse.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const parseStatus = (status) => (status ? 'Verified' : 'Waiting for verification');

  const toggleInvoice = (reportId, newValue) => {
    const body = new FormData();
    body.append('reportId', reportId);
    body.append('paidInvoices', !!newValue);

    setInvoice(body);
    handleSelectChange('country')(null);
  };

  const handleArchiveReport = () => {
    const body = new FormData();
    body.append('reportId', archiveConfirmModal);
    body.append('archive', true);

    archiveReport(body);
    setArchiveConfirmModal(null);
  };

  const handleReportDownload = (report) => {
    exportReport(report);
  };

  const groupReportsByYear = (items) => {
    const groups = items.reduce((_groupedReports, prev) => {
      const groupedReports = _groupedReports;
      const itemDateGroups = prev.reports.reduce((groupedYears, item) => {
        const date = new Date(item.createdAt);
        const yearArray = groupedYears[date.getFullYear()] ? groupedYears[date.getFullYear()] : [];
        return {
          ...groupedYears,
          [date.getFullYear()]: [...yearArray, item],
        };
      }, []);

      Object.keys(itemDateGroups).forEach((year) => {
        const yearArray = groupedReports[year] ? groupedReports[year] : [];
        const newObj = {
          id: prev.id,
          name: prev.name,
          country: prev.country,
          reports: [...itemDateGroups[year]],
        };
        groupedReports[year] = [...yearArray, newObj];
      });
      return { ...groupedReports };
    }, {});

    return groups;
  };

  const sortReports = (sort, items = null) => {
    const [currentSortName, currentSortStatus] = sort || [];
    const toSort = items || reportsItems;
    const direction = currentSortStatus === 'asc' ? -1 : 1;
    switch (currentSortName) {
      case 'name':
        toSort.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) return direction;
          if (nameA > nameB) return direction * -1;
          return 0;
        });
        break;
      case 'createdAt':
        toSort.forEach((item) => {
          item.reports.sort((a, b) => {
            const dateA = a.createdAt;
            const dateB = b.createdAt;
            if (dateA < dateB) return direction;
            if (dateA > dateB) return direction * -1;
            return 0;
          });
        });
        toSort.sort((a, b) => {
          const dateA = a.reports[0].createdAt;
          const dateB = b.reports[0].createdAt;
          if (dateA < dateB) return direction;
          if (dateA > dateB) return direction * -1;
          return 0;
        });
        break;
      default:
        break;
    }
    setReportsItems(toSort);
  };

  useEffect(() => {
    const newItems = handleReportItems('');
    sortReports(['createdAt', 'desc'], newItems);
  }, [reports]);

  const handleSortBy = (name) => () => {
    const [currentSortName, currentSortStatus] = sortBy || [];
    const sortByNewColumn = currentSortName !== name;

    if (sortByNewColumn) {
      setSortBy([name, 'desc']);
      sortReports([name, 'desc']);
      return;
    }

    const newSortStatus = currentSortStatus === 'desc' ? 'asc' : 'desc';
    setSortBy([name, newSortStatus]);
    sortReports([name, newSortStatus]);
  };

  const handleChangeGroupMode = (mode) => {
    setGroupedBy(mode);
  };

  const renderStyledRow = (report) => (
    <>
      <Table.Cell style={{ fontWeight: 600, color: '#4F4E5C' }}>
        <Table.Label>{t('page.ipma.reports.version')}:</Table.Label>
        {report.version}
      </Table.Cell>
      <Table.Cell>
        <Table.Label>{t('page.ipma.reports.date')}:</Table.Label>
        {parseDateTime(report.createdAt)}
      </Table.Cell>
      {checkPermissions(
        {
          roles: ['ipma_ma_members_report_verify'],
        },
        userPermissions,
      ) ? null : (
        <Table.Cell>
          <Table.Label>{t('page.ipma.reports.invoice')}:</Table.Label>
          <FlexLeft>
            <Toggle
              on={report.paidInvoices}
              custom={['paid', 'unpaid']}
              onClick={() => toggleInvoice(report.id, !report.paidInvoices)}
            />
          </FlexLeft>
        </Table.Cell>
      )}
      <Table.Cell>
        <Table.Label>{t('page.ipma.reports.status')}:</Table.Label>
        {parseStatus(report.accepted)}
      </Table.Cell>
      <Table.Cell>
        <Table.Label>{t('page.ipma.reports.action')}:</Table.Label>
        <Actions>
          {!report.ipmaArchived ? (
            <ButtonAction
              title={t('global.archiveButton')}
              onClick={() => setArchiveConfirmModal(report.id)}
            />
          ) : null}
          <Margin left={{ xs: 12 }}>
            <ButtonAction
              as={Link}
              to={`/ipma/read-report/${report.id}?status=${report.accepted}`}
              title={
                checkPermissions(
                  {
                    roles: ['ipma_ma_members_report_verify'],
                  },
                  userPermissions,
                ) && !report.accepted
                  ? t('global.verifyButton')
                  : t('global.readButton')
              }
            />
          </Margin>
          {checkPermissions(
            {
              roles: ['ipma_ma_members_report_export'],
            },
            userPermissions,
          ) ? (
            <Margin left={{ xs: 12 }}>
              <ButtonAction
                title={t('page.ipma.reports.download')}
                onClick={() => handleReportDownload({ report })}
              />
            </Margin>
          ) : null}
        </Actions>
      </Table.Cell>
    </>
  );

  const renderTable = (items) => (
    <Table tablebreakpoint="lg">
      <Table.Head>
        <Table.Row>
          <Table.Heading sort={handleSortBy('name')}>{t('page.ipma.reports.maName')}</Table.Heading>
          <Table.Heading sort={handleSortBy('name')}>
            {t('page.ipma.reports.maCountry')}
          </Table.Heading>
          <Table.Heading>{t('page.ipma.reports.version')}</Table.Heading>
          <Table.Heading sort={handleSortBy('createdAt')}>
            {t('page.ipma.reports.date')}
          </Table.Heading>
          {checkPermissions(
            {
              roles: ['ipma_ma_members_report_verify'],
            },
            userPermissions,
          ) ? null : (
            <Table.Heading>{t('page.ma.reports.invoice')}</Table.Heading>
          )}
          <Table.Heading>{t('page.ipma.reports.status')}</Table.Heading>
          <Table.Heading>{t('page.ipma.reports.action')}</Table.Heading>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell colSpan={6}>
              <Spinner />
            </Table.Cell>
          </Table.Row>
        ) : null}
        {!loading && !items.length ? (
          <Table.Row>
            <Table.Cell colSpan={6}>{t('global.noResultsFound')}</Table.Cell>
          </Table.Row>
        ) : null}
        {!loading && items.length
          ? items.map((item, index) => (
              <>
                <StyledRow key={item.id} isOdd={(index + 1) % 2 !== 0}>
                  <Table.Cell
                    rowSpan={item.reports.length ? item.reports.length : '1'}
                    style={{ fontWeight: 600, color: '#4F4E5C', maxWidth: '400px' }}
                  >
                    <Table.Label>{t('page.ipma.reports.maName')}:</Table.Label>
                    {item.name}
                  </Table.Cell>
                  <Table.Cell
                    rowSpan={item.reports.length ? item.reports.length : '1'}
                    style={{ fontWeight: 600, color: '#4F4E5C', maxWidth: '400px' }}
                  >
                    <Table.Label>{t('page.ipma.reports.maCountry')}:</Table.Label>
                    {item.country}
                  </Table.Cell>
                  {item.reports.length ? (
                    renderStyledRow(item.reports[0])
                  ) : (
                    <Table.Cell colSpan={5} />
                  )}
                </StyledRow>
                {item.reports.length
                  ? item.reports.map((report, i) =>
                      i !== 0 ? (
                        <StyledSecondRow key={report.version} isOdd={(index + 1) % 2 !== 0}>
                          {renderStyledRow(report)}
                        </StyledSecondRow>
                      ) : null,
                    )
                  : null}
              </>
            ))
          : null}
      </Table.Body>
    </Table>
  );

  const renderGroupByMa = () => <Margin bottom={{ xs: 32 }}>{renderTable(reportsItems)}</Margin>;
  const renderGroupByYear = () => {
    const groupedByYear = groupReportsByYear(reportsItems);
    return Object.keys(groupedByYear)
      .reverse()
      .map((year, index) => (
        <Margin bottom={{ xs: 24 }}>
          <Dropdown
            openOnLoad={index === 0}
            title={
              <Heading as="h3" size="h4">
                {`Reports of ${year}`}
              </Heading>
            }
          >
            {renderTable(groupedByYear[year])}
          </Dropdown>
        </Margin>
      ));
  };

  const renderReports = () => {
    if (!reportsItems || !reportsItems.length) {
      return <Alert type="empty">No reports found</Alert>;
    }
    switch (groupedBy) {
      case 'year':
        return renderGroupByYear();
      case 'ma':
        return renderGroupByMa();
      default:
        return renderGroupByMa();
    }
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.ipma.reports.name')}
        renderActions={() =>
          checkPermissions(
            {
              roles: ['ipma_ma_members_report_export'],
            },
            userPermissions,
          ) ? (
            <Button secondary onClick={() => handleReportDownload({ report: null })}>
              {t('page.ipma.reports.groupReportDownload')}
            </Button>
          ) : null
        }
      />
      <PageContent>
        <Container>
          {checkPermissions(
            {
              roles: ['ipma_ma_members_report'],
            },
            userPermissions,
          ) ? (
            <>
              <Margin bottom={{ md: 48 }}>
                <Alerts type="ipma" />
              </Margin>
              <Col>
                <FiltersCustom
                  title={t('page.ma.peopleAdd.filterTitle')}
                  filters={filtersProps}
                  filterFields={filterFields}
                  handleFilterFieldChange={handleFilterFieldChange}
                  onFiltersApply={setFilters}
                />
              </Col>
              <Col>
                <Margin top="24">
                  <GroupByButtons>
                    <TagButton
                      active={groupedBy === 'year'}
                      onClick={() => handleChangeGroupMode('year')}
                    >
                      {t('page.ipma.reports.groupByYear')}
                    </TagButton>
                    <TagButton
                      active={groupedBy === 'ma'}
                      onClick={() => handleChangeGroupMode('ma')}
                    >
                      {t('page.ipma.reports.groupByMa')}
                    </TagButton>
                  </GroupByButtons>
                </Margin>
              </Col>
              <Col>
                <Margin top="24">{loading ? <Spinner /> : renderReports()}</Margin>
              </Col>
            </>
          ) : (
            <Alert type="info">{t('page.forbidden.subtitle')}</Alert>
          )}
        </Container>
      </PageContent>
      <Modal
        heading={t('page.ipma.reports.archiveTitle')}
        description={t('page.ipma.reports.archiveDesc')}
        isOpen={!!archiveConfirmModal}
        confirm={() => handleArchiveReport()}
        cancel={() => setArchiveConfirmModal(null)}
      />
    </>
  );
};

Reports.propTypes = {
  reports: PropTypes.shape([]).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  ma: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
  loadReports: PropTypes.func.isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,
  loadGlobalAvailableMA: PropTypes.func.isRequired,
  setInvoice: PropTypes.func.isRequired,
  archiveReport: PropTypes.func.isRequired,
  exportReport: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilters: PropTypes.func.isRequired,
};

const mapStateToProps = ({ globals, user, ipma, loading }) => ({
  reports: ipma.reports,
  filters: ipma.reportsFilters,
  loading: isLoading(loading, 'IPMA_REPORT'),
  userPermissions: user.userPermissions,
  countries: globals.countries,
  ma: globals.availableMA,
});

const mapDispatchToProps = (dispatch) => ({
  loadReports: (country) => dispatch(loadIpmaReportsListCreator(country)),
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  loadGlobalAvailableMA: () => dispatch(loadGlobalAvailableMACreator()),
  setInvoice: (payload) => dispatch(toggleReportInvoiceCreator(payload)),
  archiveReport: (payload) => dispatch(archiveReportCreator(payload)),
  exportReport: (payload) => dispatch(exportReportCreator(payload)),
  setFilters: (payload) => dispatch(setIpmaReportsFiltersCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
