import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import clearErrors from 'helpers/clearErrors';

import {
  Alerts,
  Button,
  Col,
  Container,
  Field,
  FileInput,
  Header,
  Heading,
  Row,
  PageContent,
  Select,
} from 'components';
import { Margin } from 'styled-components-spacing';
import {
  loadGlobalCountriesCreator,
  updateIpmaProfileCreator,
  setUpdateIpmaProfileErrorsCreator,
} from 'store/actionsCreators';

import editReducer, { defaultState, setEditDataCreator, updateFieldCreator } from './reducer';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.editProfile',
    url: '',
  },
];

function Edit({ ipmaProfile, countries, loadGlobalCountries, updateProfile, errors, removeError }) {
  const { t } = useTranslation();
  const [profile, dispatch] = useReducer(editReducer, defaultState);

  useEffect(() => {
    if (!countries.length) loadGlobalCountries();
  }, []);

  useEffect(() => {
    if (ipmaProfile) dispatch(setEditDataCreator(ipmaProfile));
  }, [ipmaProfile]);

  const handleChangeNative = (name, errorName) => (e) => {
    dispatch(updateFieldCreator({ name, value: e.target.value }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeFileNative = (name, errorName) => (e) => {
    dispatch(
      updateFieldCreator({
        name,
        value: e.target.files && e.target.files.length ? e.target.files[0] : null,
      }),
    );
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleClearFileNative = (name) => () => {
    dispatch(
      updateFieldCreator({
        name,
        value: null,
      }),
    );
  };

  const handleChangeNestedNative = (group, name, errorName) => (e) => {
    dispatch(
      updateFieldCreator({ name: group, value: { ...profile[group], [name]: e.target.value } }),
    );
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeNestedCustom = (group, name, errorName) => (value) => {
    dispatch(updateFieldCreator({ name: group, value: { ...profile[group], [name]: value } }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const submit = () => {
    const params = new FormData();
    params.append('name', profile.name);
    params.append('initials', profile.initials);
    params.append('imageFile', profile.imageFile);
    params.append('address[line1]', profile.address.line1);
    params.append('address[line2]', profile.address.line2);
    params.append('address[postCode]', profile.address.postCode);
    params.append('address[city]', profile.address.city);
    params.append('address[country]', profile.address.country ? profile.address.country.value : '');
    params.append('telephone', profile.telephone);
    params.append('email', profile.email);
    params.append('website', profile.website);
    params.append('shortDescription', profile.shortDescription);
    params.append('fullDescription', profile.fullDescription);

    updateProfile(params);
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ipma.editProfile.title')} />
      <Container>
        <PageContent>
          <Alerts type="ipmaprofile" />

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ipma.editProfile.basicInformation')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field label={t('page.ipma.editProfile.nameLabel')} required error={errors.name}>
                  <input
                    type="text"
                    placeholder={t('page.ipma.editProfile.namePlaceholder')}
                    value={profile.name}
                    onChange={handleChangeNative('name', 'name')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('page.ipma.editProfile.initialsLabel')}
                  required
                  error={errors.initials}
                >
                  <input
                    type="text"
                    placeholder={t('page.ipma.editProfile.initialsPlaceholder')}
                    value={profile.initials}
                    onChange={handleChangeNative('initials', 'initials')}
                  />
                </Field>
              </Margin>
            </Row>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field
                  label={t('global.logoLabel')}
                  error={errors.imageFile}
                  description={t('global.uploadImageInfo')}
                >
                  <FileInput
                    placeholder={t('global.uploadPlaceholder')}
                    fileName={profile.imageFile ? profile.imageFile.name : ''}
                    clear={handleClearFileNative('imageFile')}
                    onChange={handleChangeFileNative('imageFile', 'imageFile')}
                  />
                </Field>
              </Margin>
            </Row>
          </Margin>

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ipma.editProfile.contactData')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('global.addressLineLabel1')}
                  required
                  error={errors['address.line1']}
                >
                  <input
                    type="text"
                    placeholder={t('global.addressLinePlaceholder1')}
                    value={profile.address.line1}
                    onChange={handleChangeNestedNative('address', 'line1', 'address.line1')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.addressLineLabel2')} error={errors['address.line2']}>
                  <input
                    type="text"
                    placeholder={t('global.addressLinePlaceholder2')}
                    value={profile.address.line2 || ''}
                    onChange={handleChangeNestedNative('address', 'line2', 'address.line2')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.cityLabel')} required error={errors['address.city']}>
                  <input
                    type="text"
                    placeholder={t('global.cityPlaceholder')}
                    value={profile.address.city}
                    onChange={handleChangeNestedNative('address', 'city', 'address.city')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('global.postCodeLabel')}
                  required
                  error={errors['address.postCode']}
                >
                  <input
                    type="text"
                    placeholder={t('global.postCodePlaceholder')}
                    value={profile.address.postCode}
                    onChange={handleChangeNestedNative('address', 'postCode', 'address.postCode')}
                  />
                </Field>
              </Margin>
            </Row>
            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('global.countryLabel')}
                  required
                  error={errors['address.country']}
                  value={profile.address.country}
                  clear={() =>
                    handleChangeNestedCustom('address', 'country', 'address.country')('')
                  }
                >
                  <Select
                    value={profile.address.country}
                    options={countries}
                    placeholder={t('global.selectPlaceholder')}
                    onChange={handleChangeNestedCustom('address', 'country', 'address.country')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.phoneLabel')} required error={errors.telephone}>
                  <input
                    type="text"
                    placeholder={t('global.phonePlaceholder')}
                    value={profile.telephone}
                    onChange={handleChangeNative('telephone', 'telephone')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.emailLabel')} required error={errors.email}>
                  <input
                    type="text"
                    placeholder={t('global.emailPlaceholder')}
                    value={profile.email}
                    onChange={handleChangeNative('email', 'email')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.websiteLabel')} required error={errors.website}>
                  <input
                    type="text"
                    placeholder={t('global.websitePlaceholder')}
                    value={profile.website}
                    onChange={handleChangeNative('website', 'website')}
                  />
                </Field>
              </Margin>
            </Row>
          </Margin>

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ipma.editProfile.description')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field
                  label={t('page.ipma.editProfile.descriptionShortLabel')}
                  required
                  error={errors.shortDescription}
                >
                  <textarea
                    placeholder={t('page.ipma.editProfile.descriptionShortPlaceholder')}
                    value={profile.shortDescription}
                    onChange={handleChangeNative('shortDescription', 'shortDescription')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field
                  label={t('page.ipma.editProfile.descriptionFullLabel')}
                  error={errors.fullDescription}
                >
                  <textarea
                    placeholder={t('page.ipma.editProfile.descriptionFullPlaceholder')}
                    value={profile.fullDescription}
                    onChange={handleChangeNative('fullDescription', 'fullDescription')}
                  />
                </Field>
              </Margin>
            </Row>
          </Margin>

          <Row>
            <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
              <Margin bottom={{ xs: 12, md: 0 }}>
                <Button type="button" onClick={submit}>
                  {t('global.saveButton')}
                </Button>
              </Margin>
            </Col>
          </Row>
        </PageContent>
      </Container>
    </>
  );
}

Edit.propTypes = {
  ipmaProfile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
    }),
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
    individualMembershipsDescription: PropTypes.string,
  }),
  countries: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
  ).isRequired,
  updateProfile: PropTypes.func.isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    initials: PropTypes.string,
    country: PropTypes.string,
    imageFile: PropTypes.string,
    'address.line1': PropTypes.string,
    'address.line2': PropTypes.string,
    'address.postCode': PropTypes.string,
    'address.city': PropTypes.string,
    'address.country': PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
    individualMembershipsDescription: PropTypes.string,
  }),
  removeError: PropTypes.func.isRequired,
};

Edit.defaultProps = {
  ipmaProfile: null,
  errors: {},
};

const mapStateToProps = ({ ipma, globals }) => ({
  ipmaProfile: ipma.profile.data,
  errors: ipma.profile.errors,
  countries: globals.countries,
});
const mapDispatchToProps = (dispatch) => ({
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  updateProfile: (payload) => dispatch(updateIpmaProfileCreator(payload)),
  removeError: (payload) => dispatch(setUpdateIpmaProfileErrorsCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
