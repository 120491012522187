import React from 'react';
import {
  Container,
  Row,
  Col,
  Header,
  ButtonIcon,
  Heading,
  PageContent,
  Alert,
  Alerts,
} from 'components';
import { Margin } from 'styled-components-spacing';
import Notification from 'containers/app/Notifications/Notification/Notification';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeNotificationCreator, removeAllNotificationCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.notifications',
    url: '',
  },
];

const Notifications = ({ notifications, removeNotification, removeAllNotification }) => {
  const { t } = useTranslation();

  const renderNotificationsGroup = (group) => {
    const { date, items } = group;

    return (
      <div>
        <Margin bottom="16">
          <Heading as="h3" size="h5" noLine>
            {date}
          </Heading>
        </Margin>
        {items.map((item) => (
          <Margin bottom={{ xs: 12, md: 8 }} key={item.id}>
            <Notification
              removeFn={removeNotification}
              id={item.id}
              title={item.title}
              description={item.description}
              isRead={item.isRead}
            />
          </Margin>
        ))}
      </div>
    );
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.notifications.title')} />
      <PageContent>
        <Container>
          <Alerts type="notifications" />
          {notifications.length ? (
            <>
              {notifications.map((group, index) => (
                <Margin bottom="32" key={index}>
                  {renderNotificationsGroup(group)}
                </Margin>
              ))}
              <Margin top={{ xs: 20, md: 32 }}>
                <Row>
                  <Col size={{ md: 2 / 4, lg: 1 / 3 }}>
                    <ButtonIcon icon="close" large rounded onClick={removeAllNotification}>
                      {t('page.notifications.removeAllButton')}
                    </ButtonIcon>
                  </Col>
                </Row>
              </Margin>
            </>
          ) : (
            <Alert type="info">{t('page.notifications.emptyNotifications')}</Alert>
          )}
        </Container>
      </PageContent>
    </>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          isRead: PropTypes.bool.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          createdAt: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  removeNotification: PropTypes.func.isRequired,
  removeAllNotification: PropTypes.func.isRequired,
};

Notifications.defaultProps = {
  notifications: [],
};

const mapStateToProps = ({ user: { notifications } }) => ({
  notifications,
});

const mapDispatchToProps = (dispatch) => ({
  removeNotification: (payload) => dispatch(removeNotificationCreator(payload)),
  removeAllNotification: () => dispatch(removeAllNotificationCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
