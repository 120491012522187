import styled, { css } from 'styled-components';

export const Products = styled.ul`
         list-style: none;
         padding: 0;
         display: flex;
         flex-direction: column;
         justify-content: space-around;
         align-items: center;
         margin-top: 3rem;

         margin: 0;
         box-sizing: border-box;

         @media only screen and (min-width: 768px) {
           flex-direction: row;
           align-items: stretch;
           flex-wrap: wrap;
         }
`;

export const ProductItem = styled.li`
         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
         position: relative;
         margin-bottom: 3rem;
         flex-shrink: 0;
         justify-content: space-between;
         flex-direction: column;
         display: flex;
         max-width: 275px;
       `;

export const ImageLink = styled.a`
    text-decoration: none;
    background-color: transparent;
`;

export const ImageWrapper = styled.div`
    position: relative;
    margin-top: -1.25rem;
`;

export const ImageContainer = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    border-width: 1px;
    border-color: #e5e5e7;
    display: block;
    max-width: 100%;
    border-style: solid;
`;

export const PinWrapper = styled.span`
    position: absolute;
    right: 0;
    bottom: 0;
`;

export const Pin = styled.span`
    font-size: 0.75rem;
    color: #515151;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: inline-block;
    background-color: #e5e5e7;
`;

export const ContentWrapper = styled.div`
    flex: 1 1 0%;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
`;

export const ContentTitle = styled.h3`
    font-size: 0.875rem;
    color: #515151;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1.5;
    margin: 0;
`;

export const ActionWrapper = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-top-width: 1px;
    border-color: #e5e5e7;
`;

export const Actions = styled.div`
    display: flex;
`;

export const ActionCart = styled.div`
    margin-right: 0.75rem;
`;

export const ActionCartIco = styled.a`
         width: 3rem;
         text-decoration: none;
         color: #fff;
         height: 3rem;
         flex: 1 1 0%;
         justify-content: center;
         align-items: center;
         display: flex;
         border-radius: 9999px;
         background-color: #ec1d24;

         &:hover {
           background-color: #ab1410;
         }
       `;

export const ActionLink = styled.div`
    width: 3rem;
    color: #ec1d24;
    height: 3rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 1px;
    border-radius: 9999px;
    border-color: #ec1d24;
    border-style: solid;

    &:hover {
        background-color: #ec1d24;
        color: #fff;
        cursor: pointer;
    }

    @media (min-width: 768px) {
        flex-shrink: 0;
    }
`;

export const Svg = styled.svg`
    fill: currentColor;
    margin-right: 1px;
`;

export const PriceWrapper = styled.p`
    font-size: 1.5rem;
    margin: 0;
`;

export const Price = styled.span`
    color: #f59c00;
`;