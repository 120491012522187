import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useExactRedirect, useSubRoutes } from 'hooks';
import { Header, Tabs } from 'components';
import { globalPropTypes } from 'helpers';
import { loadSearchTotalsCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.search',
    url: '',
  },
];

const tabs = [
  {
    title: 'page.search.tabUsers',
    count: 0,
    key: 'users',
    path: {
      pathname: '/search/user-profiles',
      search: '',
      state: { authModal: true },
    },
  },
  {
    title: 'page.search.tabCourses',
    count: 0,
    key: 'courses',
    path: {
      pathname: '/search/courses',
      search: '',
      state: { authModal: true },
    },
  },
  {
    title: 'page.search.tabEvents',
    count: 0,
    key: 'events',
    path: {
      pathname: '/search/events',
      search: '',
      state: { authModal: true },
    },
  },
  {
    title: 'page.search.tabIpmaMa',
    count: 0,
    key: 'ma',
    path: {
      pathname: '/search/ma',
      search: '',
      state: { authModal: true },
    },
  },
  {
    title: 'page.search.tabRegPartners',
    count: 0,
    key: 'organisations',
    path: {
      pathname: '/search/organisations',
      search: '',
      state: { authModal: true },
    },
  },
  {
    title: 'page.search.tabYc',
    count: 0,
    key: 'yc',
    path: {
      pathname: '/search/yc',
      search: '',
      state: { authModal: true },
    },
  },
];

const Search = ({ routes, loadTotals, search }) => {
  const { t } = useTranslation();
  const qp = new URLSearchParams(useLocation().search);
  const searchParams = qp.toString();
  const searchValue = qp.get('s') || '';

  const [renderSubRoutes] = useSubRoutes(
    routes.map((route) => {
      route.searchValue = searchValue;
      return route;
    }),
  );

  const [renderExactRedirect] = useExactRedirect(
    '/search',
    `/search/user-profiles?${searchParams}`,
  );

  useEffect(() => {
    loadTotals(searchValue);
  }, [searchValue]);

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={`${t('page.search.prefix')} ${searchValue}`} />
      <Tabs
        items={tabs.map((tab) => {
          tab.path.search = searchParams;
          tab.count = get(search, `${tab.key}.total`) || 0;

          return tab;
        })}
      />
      {renderExactRedirect()}
      {renderSubRoutes()}
    </>
  );
};

Search.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes).isRequired,
  loadTotals: PropTypes.func.isRequired,
  search: PropTypes.shape({}),
};

Search.defaultProps = {
  search: {},
};

const mapStateToProps = ({ search }) => ({
  search,
});

const mapDispatchToProps = (dispatch) => ({
  loadTotals: (payload) => dispatch(loadSearchTotalsCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
