import styled from 'styled-components';
import { mr } from 'styled-components-spacing';
import { Row } from 'components';

export const RowStyled = styled(Row)`
  margin-right: 0;
  float: right;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: start;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  margin-right: 10px;
`;

export const Icon = styled.div`
  > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    ${mr(12)};

    width: 40px;
    height: 40px;
    fill: ${({ theme }) => theme.colors.tertiary};
    pointer-events: none;
  }
`;

export const ActionText = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: green;
  text-transform: uppercase;
`;

export const Content = styled.div``;
