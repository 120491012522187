import React, { useEffect, useState } from 'react';
import {
  PageContent,
  Container,
  Header,
  Alerts,
  Table,
  Select,
  Modal,
  Spinner,
  Alert,
} from 'components';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  loadOrganisationCourseCompletionCreator,
  setOrganisationCourseCompletionSortCreator,
  setOrganisationCourseCompletionUserStatusCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isLoading } from 'helpers';
import { isEmpty } from 'lodash';
import { iconList } from 'assets';
import { ReactSVG } from 'react-svg';
import { User } from 'containers/organisation/Courses/Actions/Completion/Completion.styled';

const Completion = ({
  loadCourseCompletion,
  loading,
  courseCompletion,
  setUserStatus,
  setSort,
}) => {
  const { id } = useParams();
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const [statusModal, setStatusModal] = useState(null);
  const [prevSort, setPrevSort] = useState(null);

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.organisation.courses',
      url: `/organisation/${organizationId}/courses`,
    },
    {
      title: 'navigation.breadcrumbs.organisation.courseCompletion',
      url: '',
    },
  ];

  useEffect(() => {
    loadCourseCompletion(id);
  }, []);

  const { course, users } = courseCompletion;

  const getStatus = (status) => {
    switch (status) {
      case 'completed':
        return { label: 'Completed', value: 'completed' };
      case 'notcompleted':
        return { label: 'Not completed', value: 'notcompleted' };
      default:
        return null;
    }
  };

  const description = t('courseCompletion.description');

  const handleClickSort = (key) => {
    const sort =
      prevSort && prevSort.key === key
        ? {
            key,
            order: prevSort.order === 'asc' ? 'desc' : 'asc',
          }
        : {
            key,
            order: 'asc',
          };

    setPrevSort(sort);
    setSort(sort);
  };

  const canEditUserStatus = () => {
    return course.canEditUserStatus;
  };

  const renderUser = (name, status) => {
    const icon = status ? (status === 'completed' ? 'checkThin' : 'close') : 'question';
    return (
      <User icon={icon}>
        {canEditUserStatus() ? <ReactSVG className="hide" src={iconList[icon]} /> : null}
        {name}
      </User>
    );
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('courseCompletion.title')}
        description={course.name || ''}
      />
      <PageContent>
        <Container>
          <Alerts type="organisation" />
          {!loading && isEmpty(users) ? (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Alert type="info">{t('global.noResultsFound')}</Alert>
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              {description && canEditUserStatus() ? (
                <Margin bottom="24">
                  <p>{description}</p>
                </Margin>
              ) : null}
              <Table tablebreakpoint="lg">
                <Table.Head>
                  <Table.Row>
                    <Table.Heading sort={() => handleClickSort('name')}>
                      {t('courseCompletion.firstLastName')}
                    </Table.Heading>
                    <Table.Heading sort={() => handleClickSort('email')}>
                      {t('courseCompletion.email')}
                    </Table.Heading>
                    <Table.Heading sort={() => handleClickSort('date')}>
                      {t('courseCompletion.date')}
                    </Table.Heading>
                    {canEditUserStatus() ? (
                      <Table.Heading sort={() => handleClickSort('status')}>
                        {t('courseCompletion.status')}
                      </Table.Heading>
                    ) : null}
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={4}>
                        <Spinner />
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && !isEmpty(users)
                    ? users.map((user, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>
                            <Table.Label>{t('courseCompletion.firstLastName')}:</Table.Label>
                            {renderUser(user.name, user.status)}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('courseCompletion.email')}:</Table.Label>
                            {user.email}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('courseCompletion.date')}:</Table.Label>
                            {user.date}
                          </Table.Cell>
                          {canEditUserStatus() ? (
                            <Table.Cell>
                              <Select
                                small
                                onChange={({ value }) =>
                                  setStatusModal({ id: user.id, status: value, name: user.name })
                                }
                                value={getStatus(user.status)}
                                options={[
                                  { label: t('global.completed'), value: 'completed' },
                                  { label: t('global.notCompleted'), value: 'notcompleted' },
                                ]}
                                placeholder={t('courseCompletion.setStatus')}
                                name="status"
                                disabled={user.status !== null}
                              />
                            </Table.Cell>
                          ) : null}
                        </Table.Row>
                      ))
                    : null}
                </Table.Body>
              </Table>
              <Modal
                heading={t('modals.headings.areYouSure')}
                description={t('courseCompletion.modal.description')}
                isOpen={statusModal}
                confirm={() => {
                  setUserStatus(statusModal);
                  setStatusModal(null);
                }}
                cancel={() => setStatusModal(null)}
              />
            </>
          )}
        </Container>
      </PageContent>
    </>
  );
};

Completion.propTypes = {
  loadCourseCompletion: PropTypes.func.isRequired,
  setUserStatus: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  courseCompletion: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.shape({})),
    course: PropTypes.shape({
      name: PropTypes.string,
      canEditUserStatus: PropTypes.string,
    }),
  }),
};

Completion.defaultProps = {
  loading: false,
  courseCompletion: {
    users: [],
    course: {
      name: '',
      canEditUserStatus: '',
    },
  },
};

const mapStateToProps = ({ loading, organisation: { courseCompletion } }) => ({
  loading: isLoading(loading, 'ORGANISATION_COURSE_COMPLETION'),
  courseCompletion,
});

const mapDispatchToProps = (dispatch) => ({
  loadCourseCompletion: (payload) => dispatch(loadOrganisationCourseCompletionCreator(payload)),
  setUserStatus: (payload) => dispatch(setOrganisationCourseCompletionUserStatusCreator(payload)),
  setSort: (payload) => dispatch(setOrganisationCourseCompletionSortCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Completion);
