import { takeLatest, call, put, cancelled, all, select, debounce } from 'redux-saga/effects';

import * as ipmaService from 'api/services/ipma';

import {
  LOAD_IPMA_DASHBOARD,
  LOAD_IPMA_PROFILE,
  UPDATE_IPMA_PROFILE,
  LOAD_IPMA_PEOPLE_BAR,
  LOAD_IPMA_PEOPLE_USERS,
  CHANGE_IPMA_PEOPLE_USERS_ROLE,
  DELETE_IPMA_PEOPLE_USER,
  LOAD_IPMA_PEOPLE_ADD_LIST_USERS,
  ADD_IPMA_PEOPLE_USERS,
  LOAD_IPMA_EVENTS_BAR,
  LOAD_IPMA_EVENTS,
  CREATE_IPMA_EVENT,
  LOAD_IPMA_EVENT,
  EDIT_IPMA_EVENT,
  CANCEL_IPMA_EVENT,
  REOPEN_IPMA_EVENT,
  DUPLICATE_IPMA_EVENT,
  UPLOAD_IPMA_EVENT_PARTICIPANTS,
  LOAD_IPMA_AWARDS_LIST,
  LOAD_IPMA_AWARD_PARTICIPANTS,
  UPLOAD_IPMA_AWARD_PARTICIPANTS,
  LOAD_IPMA_REG_OFFICE_COURSES,
  LOAD_IPMA_REG_OFFICE_COURSES_BAR,
  ACCEPT_IPMA_REG_OFFICE_COURSE,
  REJECT_IPMA_REG_OFFICE_COURSE,
  UPLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  LOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS,
  REMOVE_IPMA_REG_OFFICE_COURSE_REG_LOGO,
  DOWNLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGO,
  LOAD_IPMA_REG_OFFICE_STAT_TABLE,
  LOAD_IPMA_REG_OFFICE_GOOGLE_LINK,
  TOGGLE_REG_OFFICE_MA_TYPE,
  TOGGLE_REG_OFFICE_ORGANIZATION_FR,
  LOAD_IPMA_REPORT_LIST,
  TOGGLE_REPORT_INVOICE,
  ARCHIVE_REPORT,
  VERIFY_REPORT,
  LOAD_IPMA_PROFILE_UPCOMING_EVENTS,
  CREATE_IPMA_PROJECT,
  LOAD_IPMA_PROJECTS_BAR,
  LOAD_IPMA_PROJECTS,
  LOAD_IPMA_PROJECT,
  EDIT_IPMA_PROJECT,
  CREATE_IPMA_PROJECT_ROLE,
  EDIT_IPMA_PROJECT_ROLE,
  LOAD_IPMA_PROJECT_ROLES_BAR,
  LOAD_IPMA_PROJECT_ROLES,
  LOAD_IPMA_PROJECT_ROLE,
  CREATE_IPMA_PROJECT_ROLE_APPLICATION,
  LOAD_IPMA_PROJECT_ROLE_APPLICATION_BAR,
  LOAD_IPMA_PROJECT_ROLE_APPLICATIONS,
  ACCEPT_IPMA_PROJECT_ROLE_APPLICATION,
  DECLINE_IPMA_PROJECT_ROLE_APPLICATION,
  PUT_ON_HOLD_IPMA_PROJECT_ROLE_APPLICATION,
  DOWNLOAD_IPMA_APPLICATIONS,
  REMOVE_IPMA_PROJECT,
  REMOVE_IPMA_PROJECT_ROLE,
} from 'store/constants';
import {
  setNewAlertCreator,
  setNewLoadingCreator,
  removeLoadingCreator,
  setIpmaDashboardCreator,
  setIpmaProfileCreator,
  setIpmaProfileUsersRoleCreator,
  loadIpmaProfileUcomingEventsCreator,
  loadIpmaProfileCreator,
  setUpdateIpmaProfileErrorsCreator,
  setIpmaPeopleBarCreator,
  loadIpmaPeopleBarCreator,
  setIpmaPeopleUsersCreator,
  loadIpmaPeopleUsersCreator,
  setIpmaPeopleAddListUsersCreator,
  loadIpmaPeopleListCreator,
  setIpmaEventsBarCreator,
  setIpmaEventsStatusCreator,
  loadIpmaEventsBarCreator,
  setIpmaEventsCreator,
  loadIpmaEventsCreator,
  setIpmaEventParticipantsCreator,
  loadIpmaEventParticipantsCreator,
  setIpmaAwardsListCreator,
  setIpmaAwardParticipantsCreator,
  loadIpmaAwardParticipantsCreator,
  setIpmaRegOfficeCoursesBarCreator,
  setIpmaRegOfficeCoursesStatusCreator,
  loadIpmaRegOfficeCoursesBarCreator,
  setIpmaRegOfficeCoursesCreator,
  loadIpmaRegOfficeCoursesCreator,
  setIpmaRegOfficeCourseRegLogosCreator,
  loadIpmaRegOfficeCourseRegLogosCreator,
  setIpmaRegOfficeGoogleLinkCreator,
  loadIpmaRegOfficeGoogleLinkCreator,
  setIpmaRegOfficeStatTableCreator,
  loadIpmaRegOfficeStatTableCreator,
  setIpmaReportsListCreator,
  loadIpmaReportsListCreator,
  setIpmaProfileUcomingEventsCreator,
  setIpmaProjectsBarCreator,
  setIpmaProjectsStatusCreator,
  loadIpmaProjectsBarCreator,
  setIpmaProjectsCreator,
  setIpmaProjectCreator,
  setIpmaProjectRolesBarCreator,
  setIpmaProjectRolesCreator,
  loadIpmaProjectRolesBarCreator,
  setIpmaProjectRolesStatusCreator,
  setIpmaProjectRoleApplicationBarCreator,
  loadIpmaProjectRoleApplicationBarCreator,
  setIpmaProjectRoleApplicationsCreator,
  setIpmaProjectRoleCreator,
  loadIpmaProjectRoleApplicationsCreator,
  setIpmaProjectRoleApplicationStatusCreator,
} from 'store/actionsCreators';

import { parseBoxesList, parseUserListItem } from 'helpers';
import { get, map } from 'lodash';
import i18next from 'i18next';
import { LOAD_IPMA_EVENT_PARTICIPANTS } from '../store/constants';

function* loadDashboard() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(ipmaService.getDashboard);
    if (response.ok) {
      const {
        results: { result: data },
      } = yield response.json();

      const dashboard = {
        name: get(data, 'ipma[0].name') || '',
        phone: get(data, 'ipma[0].telephone') || '',
        email: get(data, 'ipma[0].email') || '',
        website: get(data, 'ipma[0].website') || '',
        photo: get(data, 'ipma[0].photo') || '',
        members: String(get(data, 'chartUniqueUsersInRoles[0][0].userCount') || 0),
        chartPeople: get(data, 'chartPeople') || [],
        chartEvents: get(data, 'chartEvents') || [],
      };

      yield put(setIpmaDashboardCreator(dashboard));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ipma dashboard error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadProfile() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getProfile);
    if (response.ok) {
      const {
        results: {
          result: { ipma_profile: profile, ipma_roles: usersRole },
        },
      } = yield response.json();

      const parsedRoles = Object.entries(usersRole[0]).map(([key, values]) => ({
        name: key,
        items: map(values[0], parseUserListItem),
      }));

      yield all([
        put(
          setIpmaProfileCreator({
            ...profile[0],
            country: {
              label: profile[0].country ? profile[0].country.name : '',
              value: profile[0].country ? profile[0].country.id : '',
            },
            address: {
              ...profile[0].address,
              country: {
                label: profile[0].address.country ? profile[0].address.country.name : '',
                value: profile[0].address.country ? profile[0].address.country.id : '',
              },
            },
          }),
        ),
        put(setIpmaProfileUsersRoleCreator(parsedRoles)),
        put(loadIpmaProfileUcomingEventsCreator()),
      ]);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(setIpmaProfileCreator({}));
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ipma profile error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadIpmaProfileUpcomingEvents() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(ipmaService.getProfileUpcomingEvents);
    if (response.ok) {
      const {
        results: { result: items },
      } = yield response.json();

      const events = parseBoxesList('events', items);

      yield put(setIpmaProfileUcomingEventsCreator(events));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ipma profile upcoming events error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* updateProfile({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.updateProfile, payload);
    if (response.ok) {
      yield put(loadIpmaProfileCreator());
      yield put(
        setNewAlertCreator({
          type: 'ipmaprofile',
          status: 'success',
          text: i18next.t('global.alert.update'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      yield put(
        setUpdateIpmaProfileErrorsCreator(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        ),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipmaprofile',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] update ipma profile error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(
        setIpmaPeopleBarCreator({
          users: bar.ipmaUserList,
        }),
      );
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ipma people bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadUsers() {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage, sort } = yield select(({ ipma }) => ipma.people.users);
    const response = yield call(ipmaService.getUsers, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      yield put(loadIpmaPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setIpmaPeopleUsersCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* changeUserRole({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.changeUserRole, payload);
    if (response.ok) {
      yield put(loadIpmaPeopleUsersCreator());
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ipma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* removeUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.removeUser, payload);
    if (response.ok) {
      yield put(loadIpmaPeopleUsersCreator());
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    if (error.response) {
      const errors = error.response;
      if (errors) {
        yield put(
          setNewAlertCreator({
            type: 'ipma',
            status: 'error',
            text: 'Cannot remove this user',
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* loadAddListUsers({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.getAddListUsers, payload);
    if (response.ok) {
      const {
        results: { result: users },
      } = yield response.json();
      yield put(setIpmaPeopleAddListUsersCreator(users));
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma people add list users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* addUsers({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.addUsers, payload);
    if (response.ok) {
      yield put(setIpmaPeopleAddListUsersCreator([]));
      history.push('/ipma/people/');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors) {
        yield put(loadIpmaPeopleListCreator());
        yield put(
          setNewAlertCreator({
            type: 'ipma',
            status: 'error',
            text: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ipma people add list users error: ', error);
  }
}

function* loadEventsBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getEventsBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setIpmaEventsBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ipma events bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadEvents({ payload }) {
  try {
    if (payload && payload.status) yield put(setIpmaEventsStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage, status } = yield select(({ ipma }) => ipma.events.list);
    const response = yield call(ipmaService.getEvents, {
      page,
      maxResult: perPage,
      status,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadIpmaEventsBarCreator({ withoutLoader: true }));
      yield put(
        setIpmaEventsCreator({
          items: parseBoxesList('events', result.items),
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* createEvent({ payload, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.createEvent, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/ipma/events');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create ipma event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadEvent({ payload, setEvent }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getEvent, payload);
    if (response.ok) {
      const {
        results: { result: event },
      } = yield response.json();
      yield setEvent({
        ...event,
        dateFrom: event.dateFrom ? new Date(event.dateFrom) : null,
        dateTo: event.dateTo ? new Date(event.dateTo) : null,
        mainLanguage: event.mainLanguage
          ? { label: event.mainLanguage.name, value: event.mainLanguage.id }
          : null,
        secondaryLanguage: event.secondaryLanguage
          ? {
              label: event.secondaryLanguage.name,
              value: event.secondaryLanguage.id,
            }
          : null,
        timezone: event.timezone ? { label: event.timezone.gmt, value: event.timezone.id } : null,
        currency: event.currency ? { label: event.currency.name, value: event.currency.id } : null,
        modeOfDelivery: event.modeOfDelivery ? event.modeOfDelivery.id : null,
        youngCrew: event.youngCrew
          ? { label: event.youngCrew.name, value: event.youngCrew.id }
          : null,
        address: {
          ...event.address,
          country: event.address.country
            ? { label: event.address.country.name, value: event.address.country.id }
            : null,
        },
        tags: event.tags.map((item) => item.id),
        imageFile: event.photo || '',
        cpdRoles:
          event.cpdRoleEventCourses && event.cpdRoleEventCourses.length > 0
            ? event.cpdRoleEventCourses.map((el) => ({
                role: el.cpdRole,
                points: el.points,
              }))
            : [
                {
                  role: null,
                  points: null,
                },
              ],
      });
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get ipma event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* editEvent({ payload, id, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.editEvent, payload, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/ipma/events');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ipma cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* cancelEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.cancelEvent, payload);
    if (response.ok) {
      yield put(loadIpmaEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ipma cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* reopenEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.reopenEvent, payload);
    if (response.ok) {
      yield put(loadIpmaEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ipma cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* duplicateEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const body = new FormData();
    body.append('eventId', payload);

    const response = yield call(ipmaService.duplicateEvent, body);
    if (response.ok) {
      yield put(loadIpmaEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] ipma duplicate event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* loadEventParticipants({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage } = yield select(({ ipma }) => ipma.issueBadges);
    const response = yield call(ipmaService.getEventParticipants, payload, {
      page,
      maxResult: perPage,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      yield put(
        setIpmaEventParticipantsCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma event participants error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* uploadParticipants({ id, payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.uploadEventParticipants, id, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      yield put(loadIpmaEventParticipantsCreator(id));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create ipma event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadAwards() {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage } = yield select(({ ipma }) => ipma.awards.list);
    const response = yield call(ipmaService.getAwardsList, {
      page,
      maxResult: perPage,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      yield put(
        setIpmaAwardsListCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma event participants error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* loadAwardParticipants({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage } = yield select(({ ipma }) => ipma.awards.participants);
    const response = yield call(ipmaService.getAwardParticipants, payload, {
      page,
      maxResult: perPage,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      yield put(
        setIpmaAwardParticipantsCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma event participants error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* uploadAwardParticipants({ id, payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.uploadAwardParticipants, id, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      yield put(loadIpmaAwardParticipantsCreator(id));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create ipma event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadRegOfficeCoursesBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getRegOfficeCoursesBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setIpmaRegOfficeCoursesBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ipma events bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadRegOfficeCourses({ payload }) {
  try {
    if (payload && payload.status) yield put(setIpmaRegOfficeCoursesStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage, status } = yield select(({ ipma }) => ipma.regOfficeCourses.list);
    const response = yield call(ipmaService.getRegOfficeCourses, status, {
      page,
      maxResults: perPage,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadIpmaRegOfficeCoursesBarCreator({ withoutLoader: true }));
      yield put(
        setIpmaRegOfficeCoursesCreator({
          items: parseBoxesList('courses', result.items),
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* uploadRegOfficeCourseLogos({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(
      ipmaService.uploadRegOfficeCourseRegLogos,
      payload.id,
      payload.body,
    );
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      yield put(removeLoadingCreator({ type: 'IPMA' }));
      yield put(loadIpmaRegOfficeCoursesCreator(payload.type));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] upload reg logos error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* acceptIpmaRegOfficeCourse({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(
      ipmaService.acceptRegOfficeCourse,
      payload.id,
      payload.cpdNumber,
      payload.regNumber,
    );
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      yield put(loadIpmaRegOfficeCoursesCreator(payload.type));
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] accept course error: ', error);
  }
}
function* rejectIpmaRegOfficeCourse({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.rejectRegOfficeCourse, payload.id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      yield put(loadIpmaRegOfficeCoursesCreator(payload.type));
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] reject course error: ', error);
  }
}

function* loadRegOfficeCourseRegLogos({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'REGLOGO' }));
    const response = yield call(ipmaService.getUploadedRegOfficeCourseRegLogos, payload);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(setIpmaRegOfficeCourseRegLogosCreator(result));
      yield put(loadIpmaRegOfficeGoogleLinkCreator(payload));
    }

    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    console.log('[GET] ipma course get reg logos error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    }
  }
}

function* loadRegOfficeCourseGoogleLink({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'REGLOGO' }));
    const response = yield call(ipmaService.getRegOfficeCourseGoogleLink, payload);

    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(setIpmaRegOfficeGoogleLinkCreator(result));
    }

    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    console.log('[GET] ipma course get reg logos error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    }
  }
}

function* removeRegOfficeCourseRegLogo({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'REGLOGO' }));
    const response = yield call(
      ipmaService.removeRegOfficeCourseRegLogo,
      payload.courseId,
      payload.regLogoId,
    );
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      yield put(removeLoadingCreator({ type: 'REGLOGO' }));
      yield put(loadIpmaRegOfficeCourseRegLogosCreator(payload.courseId));
    }
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    console.log('[GET] ipma course get reg logos error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    }
  }
}

function* downloadRegOfficeCourseRegLogo({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'REGLOGO' }));
    const response = yield call(ipmaService.downloadRegOfficeCourseRegLogo, payload.id);
    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', payload.fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    console.log('[GET] ipma course get reg logos error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    }
  }
}

function* regOfficeStatTable() {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.getRegOfficeStatTable);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      const mapToArray = Object.keys(result).map((key) => {
        const entry = result[key];
        if (!Array.isArray(entry.organisations)) {
          entry.organisations = Object.keys(entry.organisations).map((k) => entry.organisations[k]);
        }
        return entry;
      });
      const newMappedArray = [];
      mapToArray.forEach((item) => {
        if (item.organisations && item.organisations.length) {
          item.organisations.forEach((org) => {
            const newItem = {
              ...org,
              ma: {
                id: item.id,
                name: item.name,
                cpdRec: item.cpdRec,
                regAgreement: item.regAgreement,
              },
            };
            if (org.courses && parseInt(org.courses, 10)) {
              newMappedArray.push(newItem);
            }
          });
        }
      });
      yield put(setIpmaRegOfficeStatTableCreator(newMappedArray));
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] load ipma reg office stat table error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* toggleRegOfficeMaType({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(
      ipmaService.toggleRegOfficeMaType,
      payload.maId,
      payload.type,
      payload.newValue,
    );

    if (response.ok) {
      yield put(loadIpmaRegOfficeStatTableCreator());
    }

    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma reg office toggle ma type error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* toggleRegOfficeOrgFr({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(
      ipmaService.toggleRegOfficeOrganizationFlatRate,
      payload.orgId,
      payload.newValue,
    );

    if (response.ok) {
      yield put(loadIpmaRegOfficeStatTableCreator());
    }

    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] ipma reg office toggle organization flat rate error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* loadIpmaReportsList() {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA_REPORT' }));
    const reportsFilters = yield select(({ ipma }) => ipma.reportsFilters);
    const response = yield call(ipmaService.getIpmaReportList, { ...reportsFilters });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      const maList = {};
      result.forEach((obj) => {
        if (!maList[obj.memberAssociation.id]) {
          maList[obj.memberAssociation.id] = {
            id: obj.memberAssociation.id,
            name: obj.memberAssociation.name,
            country: obj.memberAssociation.country.name || '',
            ipmaArchived: obj.ipmaArchived,
            reports: [obj],
          };
        } else {
          maList[obj.memberAssociation.id] = {
            ...maList[obj.memberAssociation.id],
            reports: [...maList[obj.memberAssociation.id].reports, obj],
          };
        }
      });
      const mapToArray = Object.keys(maList).map((key) => {
        const entry = maList[key];
        if (!Array.isArray(entry.reports)) {
          entry.reports = Object.keys(entry.reports).map((k) => entry.reports[k]);
        }
        return entry;
      });
      mapToArray.sort((a, b) => (a.id < b.id ? 1 : -1));

      yield put(setIpmaReportsListCreator(mapToArray));
    }

    yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
    console.log('[GET] ipma reports list error: ');
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
  }
}

function* toggleReportInvoice({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA_REPORT' }));
    const response = yield call(ipmaService.setIpmaReportInvoice, payload);

    if (response.ok) {
      yield put(loadIpmaReportsListCreator());
    }

    yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
    console.log('[GET] ipma report set invoice error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
    }
  }
}

function* archiveReport({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA_REPORT' }));
    const response = yield call(ipmaService.archiveIpmaReport, payload);

    if (response.ok) {
      yield put(loadIpmaReportsListCreator());
    }

    yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
    console.log('[GET] archive report error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
    }
  }
}

function* verifyReport({ id, kamNotes, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA_REPORT' }));
    const response = yield call(ipmaService.verifyIpmaReport, id, kamNotes);

    if (response.ok) {
      history.push('/ipma/reports/');
    }

    yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
    console.log('[GET] verify report error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA_REPORT' }));
    }
  }
}

function* createProject({ payload, history, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.createProject, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/ipma/projects');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create project error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadProjectsBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getProjectsBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setIpmaProjectsBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] projects bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadProjects({ payload }) {
  try {
    if (payload && payload.status) yield put(setIpmaProjectsStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage, status } = yield select(({ ipma }) => ipma.projects.list);
    const response = yield call(ipmaService.getProjects, {
      page,
      maxResult: perPage,
      status,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadIpmaProjectsBarCreator({ withoutLoader: true }));
      yield put(
        setIpmaProjectsCreator({
          items: parseBoxesList('project', result.items),
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] projects list error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* loadProject({ payload, setProject = null }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getProject, payload);
    if (response.ok) {
      const {
        results: { result: projectArray },
      } = yield response.json();
      const project = projectArray[0] || {};
      const projectObj = {
        name: project.name,
        imageFile: project.banner || '',
        dateFrom: project.dateFrom ? new Date(project.dateFrom) : null,
        dateTo: project.dateTo ? new Date(project.dateTo) : null,
        mainLanguage: project.mainLanguage
          ? { label: project.mainLanguage.name, value: project.mainLanguage.id }
          : null,
        secondaryLanguage: project.secondaryLanguage
          ? {
              label: project.secondaryLanguage.name,
              value: project.secondaryLanguage.id,
            }
          : null,
        type: project.type,
        'location.line1': project.location.line1,
        'location.line2': project.location.line2,
        'location.postCode': project.location.postCode,
        'location.city': project.location.city,
        'location.country': project.location.country
          ? { label: project.location.country.name, value: project.location.country.id }
          : null,
        customUrl: project.customUrl,
        shortDescription: project.shortDescription,
        description: project.description,
        isAlwaysOngoing: project.isAlwaysOngoing,
        errors: {},
      };
      yield put(setIpmaProjectCreator(projectObj));
      if (setProject !== null) {
        setProject(projectObj, true);
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get project error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* editProject({ payload, id, history, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.editProject, payload, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/ipma/projects');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* createProjectRole({ payload, history, projectId, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.createProjectRole, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push(`/ipma/project-roles/${projectId}`);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create project role error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadProjectRolesBar({ payload, id }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getProjectRolesBar, id);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setIpmaProjectRolesBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] project roles bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadProjectRoles({ payload, id }) {
  try {
    if (payload && payload.status) yield put(setIpmaProjectRolesStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage, status } = yield select(({ ipma }) => ipma.projectRoles.list);
    const response = yield call(
      ipmaService.getProjectRoles,
      {
        page,
        maxResult: perPage,
        status,
      },
      id,
    );
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadIpmaProjectRolesBarCreator({ withoutLoader: true }, id));
      yield put(
        setIpmaProjectRolesCreator({
          items: parseBoxesList('project-role', result.items),
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] projects list error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* loadProjectRole({ payload, setProjectRole = null }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getProjectRole, payload);
    if (response.ok) {
      const {
        results: { result: projectRoleArray },
      } = yield response.json();
      const projectRole = projectRoleArray[0] || {};
      if (setProjectRole) {
        setProjectRole(
          {
            name: projectRole.name,
            sameDateRange: projectRole.sameDateRange ? 1 : 2,
            dateFrom: projectRole.dateFrom ? new Date(projectRole.dateFrom) : null,
            dateTo: projectRole.dateTo ? new Date(projectRole.dateTo) : null,
            type: projectRole.type,
            occupation: { label: projectRole.occupation, value: projectRole.occupation },
            careerLevel: projectRole.careerLevel
              ? { label: projectRole.careerLevel.value, value: projectRole.careerLevel.id }
              : null,
            ipmaProjectRoleType: projectRole.ycProjectRoleType
              ? {
                  label: projectRole.ycProjectRoleType.value,
                  value: projectRole.ycProjectRoleType.id,
                }
              : null,
            roleDescription: projectRole.roleDescription,
            responsibilities: projectRole.responsibilities,
            qualifications: projectRole.qualifications,
            closingInformations: projectRole.closingInformations,
            cvRequired: projectRole.cvFileRequired,
            letterRequired: projectRole.letterFileRequired,
            skills: projectRole.skills
              ? projectRole.skills.reduce((prev, item) => [...prev, item.id], [])
              : [],
          },
          true,
        );
      }
      yield put(setIpmaProjectRoleCreator(projectRole));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get ipma project error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* editProjectRole({ payload, id, history, projectId, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.editProjectRole, payload, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push(`/ipma/project-roles/${projectId}`);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* createProjectRoleApplication({ payload, closeModal, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'APPLICATION' }));
    const response = yield call(ipmaService.createProjectRoleApplication, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      closeModal();
    }
    yield put(removeLoadingCreator({ type: 'APPLICATION' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'APPLICATION' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create project role application error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'APPLICATION' }));
  }
}

function* loadProjectRoleApplicationBar({ payload, id }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.getProjectRoleApplicationBar, id);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setIpmaProjectRoleApplicationBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ipma project role applications bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadProjectRoleApplications({ payload, id }) {
  try {
    if (payload && payload.status)
      yield put(setIpmaProjectRoleApplicationStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const { page, perPage, status } = yield select(({ ipma }) => ipma.applications.list);
    const response = yield call(ipmaService.getProjectRoleApplications, {
      page,
      maxResult: perPage,
      applicationState: status,
      roleid: id,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadIpmaProjectRoleApplicationBarCreator({ withoutLoader: true }, id));
      yield put(
        setIpmaProjectRoleApplicationsCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[GET] applications list error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* acceptApplication({ payload, roleId }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.acceptProjectRoleApplication, payload);
    if (response.ok) {
      yield put(loadIpmaProjectRoleApplicationsCreator(null, roleId));
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ipma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[PUT] accept application error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* rejectApplication({ payload, roleId }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.rejectProjectRoleApplication, payload);
    if (response.ok) {
      yield put(loadIpmaProjectRoleApplicationsCreator(null, roleId));
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ipma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[PUT] decline application error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* putOnHoldApplication({ payload, roleId }) {
  try {
    yield put(setNewLoadingCreator({ type: 'IPMA' }));
    const response = yield call(ipmaService.putOnHoldProjectRoleApplication, payload);
    if (response.ok) {
      yield put(loadIpmaProjectRoleApplicationsCreator(null, roleId));
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ipma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'IPMA' }));
    console.log('[PUT] decline application error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'IPMA' }));
    }
  }
}

function* downloadApplicationsZip({ roleId, roleStatus }) {
  try {
    yield put(setNewLoadingCreator({ type: 'REGLOGO' }));
    const response = yield call(ipmaService.exportApplications, {
      roleId,
      roleStatus,
    });
    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'applications.zip');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    console.log('[GET] applications zip error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    }
  }
}

function* removeProject({ id }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.removeProject, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      window.location.reload();
    }
  } catch (error) {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] remove project error: ', error);
  }
}

function* removeProjectRole({ id }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ipmaService.removeProjectRole, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      window.location.reload();
    }
  } catch (error) {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] remove project role error: ', error);
  }
}

export default function* watchIpma() {
  yield takeLatest(LOAD_IPMA_PEOPLE_BAR, loadBar);
  yield takeLatest(LOAD_IPMA_PEOPLE_USERS, loadUsers);
  yield takeLatest(CHANGE_IPMA_PEOPLE_USERS_ROLE, changeUserRole);
  yield takeLatest(DELETE_IPMA_PEOPLE_USER, removeUser);
  yield takeLatest(LOAD_IPMA_DASHBOARD, loadDashboard);
  yield takeLatest(LOAD_IPMA_PROFILE, loadProfile);
  yield takeLatest(UPDATE_IPMA_PROFILE, updateProfile);
  yield debounce(1000, LOAD_IPMA_PEOPLE_ADD_LIST_USERS, loadAddListUsers);
  yield takeLatest(ADD_IPMA_PEOPLE_USERS, addUsers);
  yield takeLatest(LOAD_IPMA_EVENTS_BAR, loadEventsBar);
  yield takeLatest(LOAD_IPMA_EVENTS, loadEvents);
  yield takeLatest(CREATE_IPMA_EVENT, createEvent);
  yield takeLatest(LOAD_IPMA_EVENT, loadEvent);
  yield takeLatest(EDIT_IPMA_EVENT, editEvent);
  yield takeLatest(CANCEL_IPMA_EVENT, cancelEvent);
  yield takeLatest(REOPEN_IPMA_EVENT, reopenEvent);
  yield takeLatest(DUPLICATE_IPMA_EVENT, duplicateEvent);
  yield takeLatest(LOAD_IPMA_EVENT_PARTICIPANTS, loadEventParticipants);
  yield takeLatest(UPLOAD_IPMA_EVENT_PARTICIPANTS, uploadParticipants);
  yield takeLatest(LOAD_IPMA_AWARDS_LIST, loadAwards);
  yield takeLatest(LOAD_IPMA_AWARD_PARTICIPANTS, loadAwardParticipants);
  yield takeLatest(UPLOAD_IPMA_AWARD_PARTICIPANTS, uploadAwardParticipants);
  yield takeLatest(LOAD_IPMA_REG_OFFICE_COURSES_BAR, loadRegOfficeCoursesBar);
  yield takeLatest(LOAD_IPMA_REG_OFFICE_COURSES, loadRegOfficeCourses);
  yield takeLatest(UPLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS, uploadRegOfficeCourseLogos);
  yield takeLatest(ACCEPT_IPMA_REG_OFFICE_COURSE, acceptIpmaRegOfficeCourse);
  yield takeLatest(REJECT_IPMA_REG_OFFICE_COURSE, rejectIpmaRegOfficeCourse);
  yield takeLatest(LOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS, loadRegOfficeCourseRegLogos);
  yield takeLatest(LOAD_IPMA_REG_OFFICE_GOOGLE_LINK, loadRegOfficeCourseGoogleLink);
  yield takeLatest(REMOVE_IPMA_REG_OFFICE_COURSE_REG_LOGO, removeRegOfficeCourseRegLogo);
  yield takeLatest(DOWNLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGO, downloadRegOfficeCourseRegLogo);
  yield takeLatest(LOAD_IPMA_REG_OFFICE_STAT_TABLE, regOfficeStatTable);
  yield takeLatest(TOGGLE_REG_OFFICE_MA_TYPE, toggleRegOfficeMaType);
  yield takeLatest(TOGGLE_REG_OFFICE_ORGANIZATION_FR, toggleRegOfficeOrgFr);
  yield takeLatest(LOAD_IPMA_REPORT_LIST, loadIpmaReportsList);
  yield takeLatest(TOGGLE_REPORT_INVOICE, toggleReportInvoice);
  yield takeLatest(ARCHIVE_REPORT, archiveReport);
  yield takeLatest(VERIFY_REPORT, verifyReport);
  yield takeLatest(LOAD_IPMA_PROFILE_UPCOMING_EVENTS, loadIpmaProfileUpcomingEvents);

  yield takeLatest(CREATE_IPMA_PROJECT, createProject);
  yield takeLatest(LOAD_IPMA_PROJECTS_BAR, loadProjectsBar);
  yield takeLatest(LOAD_IPMA_PROJECTS, loadProjects);
  yield takeLatest(LOAD_IPMA_PROJECT, loadProject);
  yield takeLatest(EDIT_IPMA_PROJECT, editProject);

  yield takeLatest(CREATE_IPMA_PROJECT_ROLE, createProjectRole);
  yield takeLatest(EDIT_IPMA_PROJECT_ROLE, editProjectRole);
  yield takeLatest(LOAD_IPMA_PROJECT_ROLES_BAR, loadProjectRolesBar);
  yield takeLatest(LOAD_IPMA_PROJECT_ROLES, loadProjectRoles);
  yield takeLatest(LOAD_IPMA_PROJECT_ROLE, loadProjectRole);

  yield takeLatest(CREATE_IPMA_PROJECT_ROLE_APPLICATION, createProjectRoleApplication);
  yield takeLatest(LOAD_IPMA_PROJECT_ROLE_APPLICATION_BAR, loadProjectRoleApplicationBar);
  yield takeLatest(LOAD_IPMA_PROJECT_ROLE_APPLICATIONS, loadProjectRoleApplications);
  yield takeLatest(ACCEPT_IPMA_PROJECT_ROLE_APPLICATION, acceptApplication);
  yield takeLatest(DECLINE_IPMA_PROJECT_ROLE_APPLICATION, rejectApplication);
  yield takeLatest(PUT_ON_HOLD_IPMA_PROJECT_ROLE_APPLICATION, putOnHoldApplication);
  yield takeLatest(DOWNLOAD_IPMA_APPLICATIONS, downloadApplicationsZip);
  yield takeLatest(REMOVE_IPMA_PROJECT, removeProject);
  yield takeLatest(REMOVE_IPMA_PROJECT_ROLE, removeProjectRole);
}
