import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PageContent, Container, Pagination, FiltersControls, BoxList, Spinner } from 'components';
import {
  loadSearchCreator,
  setSearchPageCreator,
  setSearchPerPageCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';

const Courses = ({ loading, searchValue, loadList, setPage, setPerPage, list }) => {
  const { t } = useTranslation();

  const { items, page, pages, perPage } = list;

  useEffect(() => {
    loadList(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    loadList(searchValue);
  }, [perPage]);

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <BoxList type="course" items={items} alert={t('global.noResultsFound')} />
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList(searchValue);
              }}
            />
          </>
        ) : null}
      </Container>
    </PageContent>
  );
};

Courses.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  list: globalPropTypes.ListPropsTypes.isRequired,
};

Courses.defaultProps = {
  loading: false,
  searchValue: '',
};

const mapStateToProps = ({ loading, search: { courses: list } }) => ({
  loading: isLoading(loading, 'LOAD_SEARCH_COURSES'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: (payload) => dispatch(loadSearchCreator('courses', payload)),
  setPage: (payload) => dispatch(setSearchPageCreator('courses', payload)),
  setPerPage: (payload) => dispatch(setSearchPerPageCreator('courses', payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
