import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Breadcrumbs, Row, PageContent, Tags, BadgeList } from 'components';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { loadPageUserProfileCreator } from 'store/actionsCreators';
import { useAuthLock } from 'hooks';
import { globalPropTypes } from 'helpers';
import {
  ProfileSection,
  CertificationsStatus,
  Details,
  Description,
  UserOverview,
} from './components';
import { Content, ContentPadding } from './UserProfile.styled';

function UserProfile({ loadPage, history, view }) {
  const { t } = useTranslation();
  const [renderAuthLock] = useAuthLock(history);

  const { id } = useParams();

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.user.globalProfile',
      url: `/user-profile/${id}`,
    },
  ];

  useEffect(() => {
    loadPage(id, history);
  }, []);
  const {
    name,
    thumbnail,
    phone,
    email,
    shortBio,
    professionDetails,
    additionalInfo,
    roles,
    tags,
    certifications,
    badges,
  } = view;

  return (
    <PageContent>
      {renderAuthLock()}
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        <Content>
          <Row>
            <Col size={{ xs: 1, lg: 1 / 6 }}>
              <UserOverview
                thumbnail={thumbnail}
                name={name}
                phone={phone}
                email={email}
                facebook={view.facebook}
                instagram={view.instagram}
                twitter={view.twitter}
                tiktok={view.tiktok}
                linkedin={view.linkedin}
              />
            </Col>
            <Col size={{ xs: 1, lg: 5 / 6 }}>
              <ContentPadding>
                <Row>
                  <Col size={{ md: 2 / 5 }}>
                    {!isEmpty(professionDetails) ? (
                      <ProfileSection
                        displayer={Details}
                        heading={t('page.userProfile.professionDetails')}
                        details={professionDetails}
                      />
                    ) : null}
                    {!isEmpty(additionalInfo) ? (
                      <ProfileSection
                        displayer={Details}
                        heading={t('page.userProfile.additionalInfo')}
                        details={additionalInfo}
                      />
                    ) : null}
                    {!isEmpty(roles) ? (
                      <ProfileSection
                        displayer={Details}
                        heading={t('page.userProfile.roles')}
                        details={roles}
                      />
                    ) : null}
                  </Col>
                </Row>
                {shortBio ? (
                  <ProfileSection
                    displayer={Description}
                    heading={t('page.userProfile.shortBio')}
                    text={shortBio}
                  />
                ) : null}
                {!isEmpty(tags) ? (
                  <ProfileSection
                    displayer={Tags}
                    heading={t('page.userProfile.tags')}
                    tags={tags}
                  />
                ) : null}
                {!isEmpty(certifications) ? (
                  <ProfileSection
                    displayer={CertificationsStatus}
                    heading={t('page.userProfile.certificationStatus')}
                    certs={certifications}
                  />
                ) : null}
                {!isEmpty(badges) ? (
                  <ProfileSection
                    displayer={BadgeList}
                    heading={t('page.userProfile.badgesTitle')}
                    items={badges}
                    alert={t('global.noResultsBadges')}
                    showLimit={8}
                  />
                ) : null}
              </ContentPadding>
            </Col>
          </Row>
        </Content>
      </Container>
    </PageContent>
  );
}

UserProfile.propTypes = {
  history: PropTypes.shape({}).isRequired,
  loadPage: PropTypes.func.isRequired,
  view: PropTypes.shape({
    name: PropTypes.string,
    thumbnail: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    shortBio: PropTypes.string,
    professionDetails: PropTypes.arrayOf({}),
    additionalInfo: PropTypes.arrayOf({}),
    roles: PropTypes.arrayOf({}),
    tags: PropTypes.arrayOf(PropTypes.string),
    certifications: PropTypes.arrayOf(PropTypes.shape({})),
    badges: PropTypes.arrayOf(globalPropTypes.BadgePropsTypes),
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    tiktok: PropTypes.string,
    instagram: PropTypes.string,
    linkedin: PropTypes.string,
  }),
};

UserProfile.defaultProps = {
  view: {
    name: '',
    thumbnail: '',
    phone: '',
    email: '',
    shortBio: '',
    professionDetails: [],
    additionalInfo: [],
    roles: [],
    tags: [],
    certifications: [],
    badges: [],
    facebook: '',
    twitter: '',
    tiktok: '',
    instagram: '',
    linkedin: '',
  },
};

const mapStateToProps = ({ page: { userProfile } }) => ({
  view: userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  loadPage: (payload, history) => dispatch(loadPageUserProfileCreator(payload, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
