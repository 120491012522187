import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { IconText, Flag, ButtonAction, Select } from 'components';
import { getAvatar, newLineToBr } from 'helpers';
import {
  Wrapper,
  Content,
  Thumbnail,
  Description,
  InlineContent,
  InlineContentPrice,
  ActionContent,
  ActionContentBox,
  Price,
  Heading,
  Paragraph,
  ActionContentItemFull,
  Subtitle,
  BoxStatus,
} from './Box.styled';

const Box = ({
  type,
  thumbnail,
  title,
  subtitle,
  url,
  description,
  price,
  projectOpen,
  renderInfo,
  renderActions,
  renderActionsFull,
  renderActionsLeft,
  headerAs,
  projectStatus,
  positionTimeStatus,
  courseStatus,
}) => {
  const { t } = useTranslation();
  const [isTrucated, setIsTruncated] = useState(false);

  const renderInfoComponents = { IconText, Flag, Paragraph };
  const renderActionsComponents = { ButtonAction, Select, ActionContentItemFull };

  const renderInfoContent = () => {
    if (typeof renderInfo === 'function') {
      if (type === 'event') {
        return (
          <InlineContentPrice>
            <InlineContent>{renderInfo(renderInfoComponents)}</InlineContent>
            <Price>{price}</Price>
          </InlineContentPrice>
        );
      }

      return <InlineContent>{renderInfo(renderInfoComponents)}</InlineContent>;
    }

    return null;
  };

  const renderActionContent = () => {
    if (typeof renderActions === 'function') {
      return (
        <ActionContent renderActionsFull={renderActionsFull} renderActionsLeft={renderActionsLeft}>
          <ActionContentBox>{renderActions(renderActionsComponents)}</ActionContentBox>
        </ActionContent>
      );
    }

    return null;
  };

  const renderDetailsLink = () => (
    <>
      ... <Link to={url}>{t('global.seeDetails')}</Link>
    </>
  );

  const renderProjectStatus = () =>
    type === 'project' && (
      <>
        {projectStatus === 'planning' && (
          <BoxStatus color="green">{t('page.yc.projects.planning')}</BoxStatus>
        )}
        {projectStatus === 'running' && (
          <BoxStatus color="orange">{t('page.yc.projects.running')}</BoxStatus>
        )}
        {projectStatus === 'completed' && (
          <BoxStatus color="red">{t('page.yc.projects.completed')}</BoxStatus>
        )}
      </>
    );

  const projectRoleStatus = () =>
    type === 'project-role' && (
      <>
        {projectOpen && positionTimeStatus === 'open' && (
          <BoxStatus color="green">{t('page.yc.projectRoles.open')}</BoxStatus>
        )}
        {(!projectOpen || positionTimeStatus === 'closed') && (
          <BoxStatus color="red">{t('page.yc.projectRoles.closed')}</BoxStatus>
        )}
      </>
    );

  const courseGeneralStatus = () =>
    type === 'course' && (
      <>
        {courseStatus === 'courseState-template' && (
          <BoxStatus color="blue">{t('dictionaries.courseStatusTemplate')}</BoxStatus>
        )}
        {courseStatus === 'courseState-upcoming' && (
          <BoxStatus color="orange">{t('dictionaries.courseStatusUpcoming')}</BoxStatus>
        )}
        {courseStatus === 'courseState-running' && (
          <BoxStatus color="green">{t('dictionaries.courseStatusRunning')}</BoxStatus>
        )}
        {courseStatus === 'courseState-ended' && (
          <BoxStatus color="red">{t('dictionaries.courseStatusCompleted')}</BoxStatus>
        )}
      </>
    );

  return (
    <Wrapper renderActionsFull={renderActionsFull}>
      {!(type === 'project-role' || type === 'cpd-pending') ? (
        <Link to={{ pathname: url, state: { authModal: true } }}>
          <Thumbnail>
            <img src={getAvatar(thumbnail, type)} alt="" />
          </Thumbnail>
        </Link>
      ) : null}
      <Content>
        <Heading size="h6" as={headerAs} noLine>
          {subtitle ? (
            <div>
              <Link to={url}>{title}</Link>
              <Subtitle>{subtitle}</Subtitle>
            </div>
          ) : (
            <Link to={url}>{title}</Link>
          )}
          {type === 'event' && price && <Price>{price}</Price>}
          {renderProjectStatus()}
          {projectRoleStatus()}
          {courseGeneralStatus()}
        </Heading>
        {renderInfoContent()}
        {description && (
          <>
            {type === 'cpd-pending' ? (
              <Description>
                <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
              </Description>
            ) : (
              <Description>
                <Truncate
                  lines={type === 'project-role' ? 2 : 3}
                  onTruncate={(truncated) => setIsTruncated(truncated)}
                  ellipsis={renderDetailsLink()}
                >
                  <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
                </Truncate>
                {!isTrucated && renderDetailsLink()}
              </Description>
            )}
          </>
        )}
      </Content>
      {renderActionContent()}
    </Wrapper>
  );
};

Box.propTypes = {
  type: PropTypes.oneOf(['event', 'course', 'organisation', 'ma', 'yc', 'project-role']).isRequired,
  thumbnail: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]),
  description: PropTypes.string,
  price: PropTypes.string,
  projectOpen: PropTypes.bool,
  renderInfo: PropTypes.func,
  renderActions: PropTypes.func,
  renderActionsFull: PropTypes.bool,
  renderActionsLeft: PropTypes.bool,
  headerAs: PropTypes.string,
  projectStatus: PropTypes.string,
  positionTimeStatus: PropTypes.string,
  courseStatus: PropTypes.string,
};

Box.defaultProps = {
  thumbnail: '',
  subtitle: '',
  description: '',
  price: '',
  projectOpen: false,
  renderInfo: null,
  renderActions: null,
  renderActionsFull: null,
  renderActionsLeft: null,
  headerAs: 'h3',
  url: '',
  projectStatus: '',
  positionTimeStatus: '',
  courseStatus: '',
};

export default Box;
