import styled from 'styled-components';
import { mt } from 'styled-components-spacing';

export const Content = styled.div`
  line-height: 21px;

  p {
    ${mt(16)};

    &:first-of-type {
      ${mt(0)};
    }
  }
`;