import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import * as appService from 'api/services/app';
import { APP_ADD_TO_NEWSLETTER, CONNECT_AZURE, IPMA_SHOP_SSO } from 'store/constants';
import { setNewLoadingCreator, removeLoadingCreator } from '../store/actionsCreators';

function* addToNewsletter({ payload, cb }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const body = new FormData();
    body.append(`email`, payload.email);
    body.append(`recaptcha`, payload.token);

    const response = yield call(appService.addToNewsletter, body);

    if (response.ok) {
      const {
        results: {
          result: { message },
        },
      } = yield response.json();

      cb({
        type: 'success',
        message,
      });
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    if (error.response) {
      const { errors } = yield error.response.json();

      cb({
        type: 'error',
        message: errors[0].message,
      });
    }
    console.log('[POST] add to newsletter error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* connectAzure() {
  try {
    yield put(setNewLoadingCreator({ type: 'CONNECT_AZURE' }));

    const response = yield call(appService.connectAzure);

    if (response.ok) {
      const [link] = yield response.json();

      window.location.href = link;
    }

    yield put(removeLoadingCreator({ type: 'CONNECT_AZURE' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'CONNECT_AZURE' }));
    console.log('[GET] connect azure error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'CONNECT_AZURE' }));
  }
}

function* ipmaShopSso({ history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(appService.ssoIpmaShopUri);
    if (response.ok) {
      const url = yield response.json();
      window.location.href = url;
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    history.push('/');
    console.log('[GET] sso to ipma shop error: ', error);
  }
}

export default function* watchApp() {
  yield takeLatest(IPMA_SHOP_SSO, ipmaShopSso);
  yield takeLatest(APP_ADD_TO_NEWSLETTER, addToNewsletter);
  yield takeLatest(CONNECT_AZURE, connectAzure);
}
