import styled, { css } from 'styled-components';
import { p, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { Col, Row } from 'components';

export const AcceptContent = styled.div`
  text-align: center;
  ${px(16)};
  ${py(24)};

  ${breakpoint('md')`
  ${css`
    ${p(42)};
  `}
`}
`;

export const AcceptError = styled.div`
  color: ${({ theme }) => theme.colors.primaries.darker};
  margin-bottom: 8px;
`;

export const CpdRolesWrapper = styled(Row)`
    max-height: 550px;
    overflow-y: auto;
`

export const MaxMinContainer = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 4px;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 2px;
`;

export const MaxMinWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: baseline;
  * {
    color: #424242;
  }
`;

export const MaxMinLabel = styled.span`
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 12px;
`;

export const MaxMinValue = styled.span`
  font-weight: 400;
  font-size: 16px;
`;

export const MaxMinSuffix = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

export const RoleLabel = styled.span`
  font-size: 16px;
  display: flex;
  height: calc(100% - 22px);
  align-items: center;
  margin-top: 22px;
  color: #424242;
  font-weight: 700;
  overflow-wrap: anywhere;
`;
