import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Float = styled.div`
  ${breakpoint('lg')`
    ${css`
      float: right;
    `}
  `}
`;
