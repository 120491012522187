import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { ButtonIcon } from 'components';
import { Col, Content } from './ColIcon.styled';

const ColIcon = ({ as, icon, children, routes, open, modalId, ...props }) => {
  const redirect = (url) => {
    window.open(url, '_blank').focus();
  };

  return (
    <Col icon={icon} content={children} {...props}>
      {children && children}
      <ReactSVG src={iconList[icon] || ''} />
      <Content
        id={modalId}
        style={{
          visibility: open ? 'visible' : 'hidden',
          borderRadius: '0.5rem',
          padding: '5px',
        }}
      >
        {routes.map((action, index) => (
          <ButtonIcon
            onClick={() => redirect(action.path)}
            key={index}
            // as={Link}
            // to={action.path}
            icon={action.icon}
            color={action.color}
            bg=""
            left="true"
            large="true"
            style={{ background: 'transparent', border: 'none', textAlign: 'left' }}
          >
            {action.title}
          </ButtonIcon>
        ))}
      </Content>
    </Col>
  );
};

ColIcon.propTypes = {
  as: PropTypes.oneOfType([PropTypes.oneOf(['button', 'a']), PropTypes.elementType]),
  icon: PropTypes.string,
  children: PropTypes.node,
  secondary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tertiary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rounded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  outline: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  large: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  color: PropTypes.string,
  bg: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  modalId: PropTypes.string,
};

ColIcon.defaultProps = {
  as: 'button',
  modalId: null,
  icon: '',
  children: null,
  secondary: '',
  tertiary: '',
  rounded: '',
  outline: '',
  large: '',
  left: '',
  color: null,
  bg: null,
  routes: [],
  open: false,
};

export default ColIcon;
