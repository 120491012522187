import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Pagination,
  FiltersControls,
  BoxList,
  Spinner,
  Alerts,
  Modal,
  PrivateComponent,
} from 'components';
import {
  acceptMaCourseCreator,
  cancelMaCourseCreator,
  changeMaCoursesAssessorCreator,
  loadMaCoursesCreator,
  setMaCoursesPageCreator,
  setMaCoursesPerPageCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { checkPermissions, globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Margin } from 'styled-components-spacing';
import { getFilteredAssessors, getSelectedAssessorByIndex } from 'containers/ma/Courses/helpers';

const Assigned = ({
  loading,
  loadList,
  setPage,
  setPerPage,
  list,
  assessors,
  changeAssessor,
  acceptCourse,
  cancelCourse,
  userPermissions,
}) => {
  const { t } = useTranslation();
  const [assessorModal, setAssessorModal] = useState(null);
  const [actionModal, setActionModal] = useState(null);
  const [actionCancelModal, setActionCancelModal] = useState(null);

  const { items, page, pages, perPage } = list;

  useEffect(() => {
    setPage(1);
    loadList();
  }, [perPage]);

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <Alerts type="ma" />
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <BoxList
              type="course"
              items={items}
              alert={t('global.noResultsCourses')}
              renderActionsFull
              customRenderActions={
                checkPermissions(
                  {
                    roles: ['ma_courses_assign_assessor', 'ma_courses_accept'],
                  },
                  userPermissions,
                )
                  ? ({ item, ButtonAction, Select, ActionContentItemFull }) => (
                      <ActionContentItemFull>
                        <PrivateComponent
                          permissions={{
                            roles: ['ma_courses_assign_assessor'],
                          }}
                        >
                          <>
                            <Margin bottom="12">
                              <Select
                                options={getFilteredAssessors(assessors, item.assessors)}
                                small
                                placeholder={t('page.ma.coursesAssigned.assessorPlaceholder1')}
                                value={getSelectedAssessorByIndex(item.assessors, 0)}
                                onChange={({ value }) =>
                                  setAssessorModal({
                                    courseId: item.id,
                                    userId: value,
                                    prevUserId: getSelectedAssessorByIndex(item.assessors, 0, true),
                                  })
                                }
                              />
                            </Margin>
                            <Margin bottom="12">
                              <Select
                                options={getFilteredAssessors(assessors, item.assessors)}
                                small
                                placeholder={t('page.ma.coursesAssigned.assessorPlaceholder2')}
                                value={getSelectedAssessorByIndex(item.assessors, 1)}
                                onChange={({ value }) =>
                                  setAssessorModal({
                                    courseId: item.id,
                                    userId: value,
                                    prevUserId: getSelectedAssessorByIndex(item.assessors, 1, true),
                                  })
                                }
                              />
                            </Margin>
                          </>
                        </PrivateComponent>

                        <div style={{ margin: '24px -12px 0', display: 'flex' }}>
                          <PrivateComponent
                            permissions={{
                              roles: ['ma_courses_accept'],
                            }}
                          >
                            <Margin horizontal="12" inline>
                              <ButtonAction
                                icon="checkCircle"
                                title={t('global.acceptButton')}
                                onClick={() =>
                                  setActionModal({
                                    id: item.id,
                                  })
                                }
                              />
                            </Margin>
                          </PrivateComponent>
                          <PrivateComponent
                            permissions={{
                              roles: ['ma_courses_accept'],
                            }}
                          >
                            <Margin horizontal="12" inline>
                              <ButtonAction
                                icon="lockClose"
                                title={t('global.rejectButton')}
                                onClick={() =>
                                  setActionCancelModal({
                                    id: item.id,
                                  })
                                }
                              />
                            </Margin>
                          </PrivateComponent>
                        </div>
                      </ActionContentItemFull>
                    )
                  : null
              }
            />
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList();
              }}
            />
          </>
        ) : null}
      </Container>
      <Modal
        heading={t('page.ma.coursesAssigned.modalChangeHeading')}
        isOpen={assessorModal}
        confirm={() => {
          changeAssessor(assessorModal);
          setAssessorModal(null);
        }}
        cancel={() => setAssessorModal(null)}
      />
      <Modal
        heading={t('page.ma.coursesAssigned.modalAcceptHeading')}
        isOpen={actionModal}
        confirm={() => {
          acceptCourse(actionModal);
          setActionModal(null);
        }}
        cancel={() => setActionModal(null)}
        description={t('page.ma.coursesAssigned.modalAcceptDesc')}
      />
      <Modal
        heading={t('page.ma.coursesAssigned.modalCancelHeading')}
        isOpen={actionCancelModal}
        confirm={() => {
          cancelCourse(actionCancelModal);
          setActionCancelModal(null);
        }}
        cancel={() => setActionCancelModal(null)}
      />
    </PageContent>
  );
};

Assigned.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  list: globalPropTypes.ListPropsTypes.isRequired,
  assessors: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes).isRequired,
  changeAssessor: PropTypes.func.isRequired,
  acceptCourse: PropTypes.func.isRequired,
  cancelCourse: PropTypes.func.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

Assigned.defaultProps = {
  loading: false,
};

const mapStateToProps = ({
  loading,
  ma: {
    courses: { assigned: list },
    assessors,
  },
  user,
}) => ({
  loading: isLoading(loading, 'LOAD_MA_COURSES_ASSIGNED'),
  list,
  assessors,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: () => dispatch(loadMaCoursesCreator('assigned')),
  setPage: (payload) => dispatch(setMaCoursesPageCreator('assigned', payload)),
  setPerPage: (payload) => dispatch(setMaCoursesPerPageCreator('assigned', payload)),
  changeAssessor: (payload) => dispatch(changeMaCoursesAssessorCreator('assigned', payload)),
  acceptCourse: (payload) => dispatch(acceptMaCourseCreator('assigned', payload)),
  cancelCourse: (payload) => dispatch(cancelMaCourseCreator('assigned', payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assigned);
