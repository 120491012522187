import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Alerts,
  Container,
  PageContent,
  Pagination,
  Table,
  Spinner,
  FiltersControls,
} from 'components';
import {
  loadMaPeopleOrganisationsCreator,
  setMaPeopleOrganisationsSortCreator,
  setMaPeopleOrganisationsPageCreator,
  setMaPeopleOrganisationsPerPageCreator,
  downloadMaPeopleReportCreator,
} from 'store/actionsCreators';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Content, Wrapper, Icon, RowStyled } from '../List/List.styled';

function People({
  items,
  page,
  pages,
  perPage,
  sort,
  loadOrganisations,
  setSort,
  loading,
  setPage,
  setPerPage,
  downloadReport,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadOrganisations();
  }, [page, perPage, sort]);

  const handleSortBy = (name) => () => {
    const [currentSortName, currentSortStatus] = sort || [];
    const sortByNewColumn = currentSortName !== name;

    if (sortByNewColumn) {
      setSort([name, 'desc']);
      return;
    }

    const newSortStatus = currentSortStatus === 'desc' ? 'asc' : 'desc';
    setSort([name, newSortStatus]);
  };

  const handleChangePerPage = ({ value }) => {
    setPerPage(value);
  };

  const downloadButton = () => (
    <Wrapper onClick={() => downloadReport()}>
      <Icon>
        <ReactSVG src={iconList.download} />
      </Icon>
      <Content>
        <p className="word-break">{t('page.ma.peopleOrganisations.reportTitle')}</p>
        <Margin top="4">
          <div className="link uppercase">{t('page.ma.peopleList.downloadReport')}</div>
        </Margin>
      </Content>
    </Wrapper>
  );

  return (
    <>
      <Container>
        <PageContent>
          <Alerts type="ma" />
          <RowStyled>
            {downloadButton()}
            <FiltersControls perPageOnChange={handleChangePerPage} perPageSelected={perPage} />
          </RowStyled>
          <Table tablebreakpoint="lg">
            <Table.Head>
              <Table.Row>
                <Table.Heading sort={handleSortBy('firstLastName')}>
                  {t('page.ma.peopleOrganisations.name')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('email')}>
                  {t('page.ma.peopleOrganisations.president')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('role')}>
                  {t('page.ma.peopleOrganisations.presidentEmail')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('date')}>
                  {t('page.ma.peopleOrganisations.maFrom')}
                </Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <Spinner />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && !items.length ? (
                <Table.Row>
                  <Table.Cell colSpan={4}>{t('global.noResultsFound')}</Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && items.length
                ? items.map((organisation) => (
                    <Table.Row key={organisation.id}>
                      <Table.Cell>
                        <Table.Label>{t('page.ma.peopleOrganisations.name')}:</Table.Label>
                        <Link to={`/organisations/${organisation.id}`}>
                          <strong>{organisation.name}</strong>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.ma.peopleOrganisations.president')}:</Table.Label>
                        <Link to={`/user-profile/${organisation.personResponsible.uniqueUrl}`}>
                          <strong>
                            {organisation.personResponsible.firstName}{' '}
                            {organisation.personResponsible.lastName}
                          </strong>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>
                          {t('page.ma.peopleOrganisations.presidentEmail')}:
                        </Table.Label>
                        {organisation.personResponsible.email}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.ma.peopleOrganisations.maFrom')}:</Table.Label>
                        {organisation.dateFromInMa &&
                          moment(organisation.dateFromInMa).format('DD/MM/YYYY')}
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </PageContent>
      </Container>
    </>
  );
}

People.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      personResponsible: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
      dateFrom: PropTypes.string,
    }),
  ).isRequired,
  sort: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loadOrganisations: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};
People.defaultProps = {};

const mapStateToProps = ({ ma, loading }) => ({
  ...ma.people.organisations,
  loading: !!loading.filter((item) => item.type === 'MA').length,
});
const mapDispatchToProps = (dispatch) => ({
  loadOrganisations: () => dispatch(loadMaPeopleOrganisationsCreator()),
  setSort: (payload) => dispatch(setMaPeopleOrganisationsSortCreator(payload)),
  setPage: (payload) => dispatch(setMaPeopleOrganisationsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setMaPeopleOrganisationsPerPageCreator(payload)),
  downloadReport: () => dispatch(downloadMaPeopleReportCreator('organisations')),
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
