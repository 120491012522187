import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  UserList,
  IconText,
  Map,
  HeaderBrand,
  Breadcrumbs,
  Alerts,
  BoxList,
  ThumbnailCard,
} from 'components';
import { loadListViewCreator, removeListViewCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import { getAvatar, globalPropTypes, newLineToBr, parseUrlTitle } from 'helpers';
import { useAuthLock } from 'hooks';

const initialBreadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.yc',
    url: '/ipma-young-crews',
  },
];

const View = ({ loadView, removeView, view, history, userYc, ...restProps }) => {
  const { t } = useTranslation();
  let { breadcrumbs } = restProps;
  let { id } = useParams();
  const { name, id: urlId } = useParams();
  const [renderAuthLock] = useAuthLock(history);

  if (!id && userYc) {
    id = userYc.id;
  }

  if (!breadcrumbs) {
    breadcrumbs = initialBreadcrumbs;
  }

  const {
    title,
    description,
    youngCrewMembershipsDescription,
    photo,
    usersRole,
    events,
    memberAssociation,
  } = view;

  useEffect(() => {
    loadView(id, history);

    return () => {
      removeView();
    };
  }, []);

  useEffect(() => {
    if (!name && view && view.title && urlId) {
      history.replace({ pathname: `/ipma-young-crews/${id}/${parseUrlTitle(view.title)}` });
    }
  }, [view]);

  const renderDescription = (_name, content) => {
    if (!_name && !content) return null;

    return (
      <Margin bottom={{ xs: 32, xl: 48 }}>
        {_name && (
          <HeadingWrapper>
            <Heading as="h2" size="h4">
              {_name}
            </Heading>
          </HeadingWrapper>
        )}

        {content && (
          <Cms>
            <p dangerouslySetInnerHTML={{ __html: newLineToBr(content) }} />
          </Cms>
        )}
      </Margin>
    );
  };

  const renderContactDetails = (target) => (
    <Padding all="20" className="bg-gray-100">
      <Margin bottom="16">
        <Heading as="h3" size="h6">
          {t(`page.singleMa.contact${target.charAt(0).toUpperCase() + target.slice(1)}`)}:
        </Heading>
      </Margin>
      {view[target].phone && (
        <Margin bottom="12">
          <a href={`tel:${view[target].phone}`}>{view[target].phone}</a>
        </Margin>
      )}
      {view[target].email && (
        <Margin bottom="12">
          <a href={`mailto:${view[target].email}`} className="link link--16 word-break">
            {view[target].email}
          </a>
        </Margin>
      )}
      {view[target].website && (
        <a href={view[target].website} className="link link--16 word-break">
          {view[target].website}
        </a>
      )}
      {view[target].contactPerson ? (
        <Margin top="12">
          <a
            href={`/user-profile/${view[target].contactPerson.uniqueUrl}`}
            target="noreferrer _blank"
            className="link link--16 word-break"
          >
            {`${view[target].contactPerson.firstName} ${view[target].contactPerson.lastName}`}
          </a>
        </Margin>
      ) : null}
    </Padding>
  );

  const renderPlace = (target) => {
    if (!view[target].place) return null;

    return (
      <Padding all="20">
        <IconText top icon="marker" text={view[target].place} />
      </Padding>
    );
  };

  const renderMap = (target) => {
    if (!view[target].lat || !view[target].lng) return null;

    return <Map lat={view[target].lat} lng={view[target].lng} />;
  };

  const renderContact = (target) => (
    <>
      {Object.keys(view).length ? (
        <Margin bottom="32">
          <div className="border-xl">
            {renderContactDetails(target)}
            {renderPlace(target)}
            {renderMap(target)}
          </div>
        </Margin>
      ) : null}
    </>
  );

  return (
    <>
      <PageContent>
        {renderAuthLock()}
        <Container>
          <Breadcrumbs items={breadcrumbs} />
          <Alerts type="ma" />
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom="24">
                <HeaderBrand thumbnail={getAvatar(photo, 'yc')}>
                  <Heading as="h1" size="h1" firstLine>
                    {title}
                  </Heading>
                </HeaderBrand>
              </Margin>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                {description && (
                  <Cms>
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
                  </Cms>
                )}
                {/* {renderApply()} */}
              </Margin>

              {renderDescription(
                t('page.singleYc.youngCrewMemberships'),
                youngCrewMembershipsDescription,
              )}

              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.singleYc.eventsTitle')}
                  </Heading>
                  <Link to="/events" className="link uppercase">
                    {t('global.seeAll')}
                  </Link>
                </HeadingWrapper>
                <BoxList type="event" items={events} alert={t('global.noResultsEvents')} />
              </Margin>
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              {renderContact('yc')}

              {memberAssociation ? (
                <Margin top="32">
                  <Margin bottom="16">
                    <Heading as="h3" size="h5">
                      {t('page.singleYc.connectedMemberAssociation')}
                    </Heading>
                  </Margin>
                  <ThumbnailCard
                    thumbnail={memberAssociation.photo}
                    title={memberAssociation.name}
                    link={`/ipma-associations/${memberAssociation.id}/${parseUrlTitle(
                      memberAssociation.name,
                    )}`}
                  />
                </Margin>
              ) : null}

              {usersRole && !!usersRole.length ? (
                <Margin top="32">
                  {usersRole.map((item) => (
                    <Margin top="32">
                      <UserList title={`${item.name}:`} users={item.items} />
                    </Margin>
                  ))}
                </Margin>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

View.propTypes = {
  loadView: PropTypes.func.isRequired,
  removeView: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  userYc: PropTypes.shape({
    id: PropTypes.number,
  }),
  view: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    youngCrewMembershipsDescription: PropTypes.string,
    photo: PropTypes.string,
    usersRole: globalPropTypes.UserListPropsTypes,
    events: PropTypes.arrayOf(PropTypes.shape({})),
    memberAssociation: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      photo: PropTypes.string,
    }),
    yc: PropTypes.shape({
      place: PropTypes.string,
      lat: PropTypes.number,
      long: PropTypes.number,
      phone: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string,
      contactPerson: PropTypes.shape({
        uniqueUrl: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
  }),
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
};

View.defaultProps = {
  userYc: {},
  view: {
    title: '',
    description: '',
    youngCrewMembershipsDescription: '',
    phone: '',
    email: '',
    website: '',
    photo: '',
    place: '',
    lat: '',
    lng: '',
    usersRole: [],
    events: [],
    memberAssociation: {
      id: undefined,
      name: '',
      photo: '',
    },
    yc: {
      place: '',
      lat: '',
      long: '',
      phone: '',
      email: '',
      website: '',
    },
  },
  breadcrumbs: null,
};

const mapStateToProps = ({
  user: {
    user: { youngCrew },
  },
  list: {
    yc: { view },
  },
}) => ({
  view,
  userYc: youngCrew,
});

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload, history) => dispatch(loadListViewCreator('yc', payload, history)),
  removeView: () => dispatch(removeListViewCreator('yc')),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
