import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BoxList, FiltersControls, Pagination, Spinner, PrivateComponent, Modal } from 'components';
import {
  loadYcProjectRolesCreator,
  setYcProjectRolesPageCreator,
  setYcProjectRolesPerPageCreator,
  removeYcProjectRoleCreator,
} from 'store/actionsCreators';
import { isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

const Open = ({
  projectRoles,
  page,
  pages,
  perPage,
  loading,
  loadProjectRoles,
  setPage,
  setPerPage,
  deleteProjectRole,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(null);

  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadProjectRoles({ status: 'open' }, id);
  }, [perPage, page, loadProjectRoles]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="project-role"
            items={projectRoles}
            alert={t('global.noResultsFound')}
            customRenderActions={({ item, ButtonAction }) => (
              <>
                <PrivateComponent
                  permissions={{
                    roles: ['yc_project_role_manage'],
                  }}
                >
                  <ButtonAction
                    icon="edit"
                    title={t('global.editButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => history.push(`/yc/project-role/${id}/edit/${item.id}`)}
                  />
                </PrivateComponent>
                <PrivateComponent
                  permissions={{
                    roles: ['yc_project_application_manage'],
                  }}
                >
                  <ButtonAction
                    icon="list"
                    title={t('page.yc.projectRoles.manageApplications')}
                    verticalviewbreakpoint="xl"
                    onClick={() =>
                      history.push({
                        pathname: `/yc/applications/${item.id}`,
                      })
                    }
                  />
                </PrivateComponent>
                <PrivateComponent
                  permissions={{
                    roles: ['yc_project_role_manage'],
                  }}
                >
                  <ButtonAction
                    icon="closeCircle"
                    title={t('global.remove')}
                    verticalviewbreakpoint="xl"
                    onClick={() => setDeleteModal(item.id)}
                  />
                </PrivateComponent>
              </>
            )}
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </>
      ) : null}
      <Modal
        heading={t('modals.headings.areYouSure')}
        description={t('page.yc.projectRoles.removeProjectRoleDesc')}
        isOpen={deleteModal}
        confirm={() => {
          deleteProjectRole(deleteModal);
          setDeleteModal(null);
        }}
        cancel={() => setDeleteModal(null)}
      />
    </>
  );
};

Open.propTypes = {
  projectRoles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadProjectRoles: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  deleteProjectRole: PropTypes.func.isRequired,
};

const mapStateToProps = ({ yc, loading }) => ({
  projectRoles: yc.projectRoles.list.items,
  page: yc.projectRoles.list.page,
  pages: yc.projectRoles.list.pages,
  perPage: yc.projectRoles.list.perPage,
  loading: isLoading(loading, 'YC'),
});
const mapDispatchToProps = (dispatch) => ({
  loadProjectRoles: (payload, id) => dispatch(loadYcProjectRolesCreator(payload, id)),
  setPage: (payload) => dispatch(setYcProjectRolesPageCreator(payload)),
  setPerPage: (payload) => dispatch(setYcProjectRolesPerPageCreator(payload)),
  deleteProjectRole: (id) => dispatch(removeYcProjectRoleCreator(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Open);
