import styled, { css } from 'styled-components';
import { py, mt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { px } from 'styled-components-spacing/dist/cjs/Padding';
import { mr } from 'styled-components-spacing/dist/cjs/Margin';

export default styled.section`
  ${({ theme }) =>
    theme &&
    css`
      ${py(42)};
      color: ${theme.colors.gray[200]};
      background-color: ${theme.colors.tertiary};

      text-align: center;

      ${breakpoint('md')`
        text-align: left;
      `}
    `}
`;

export const SubHeading = styled.p`
  ${mt(8)}
  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('16')};
      font-weight: ${theme.font.weight.semibold};
    `}
`;

export const Form = styled.form`
  ${mt(20)}
  display: flex;
  align-items: center;
  height: 58px;
  border-radius: 2px;
  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.colors.gray[100]};

      input {
        ${mr(16)}
        ${px(16)};
        
        height: 100%;
        width: 100%;

        &, &::placeholder {
          font-size: ${theme.font.size.pixel('16')};
          font-weight: ${theme.font.weight.regular};
          color: ${theme.colors.gray[500]};
        }
      }

      button {
        ${px(16)};

        background: none;
        border: none;
        color: ${theme.colors.primary};
        font-size: ${theme.font.size.pixel('14')};
        font-weight: ${theme.font.weight.bold};
        text-transform: uppercase;

        .arrow {
          svg {
            width: 22px;
            height: 22px;
            fill: ${theme.colors.primary};
          }
          ${breakpoint('lg')`
            display: none;
          `}
        }

        .text {
          display: none;
          ${breakpoint('lg')`
            display: block;
          `}
        }
      }

      ${breakpoint('md')`
        ${mt(0)}
      `}
    `}
`;

export const Message = styled.p`
  ${mt(8)}
  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.regular};
      color: ${theme.colors.gray[300]};
      line-height: 21px;
      
      ${({ type }) =>
        type && type === 'error' &&
        css`
          color: ${theme.colors.primary};
      `}
  `}
`;
