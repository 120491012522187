import styled, { css } from 'styled-components';
import { py, pb, px } from 'styled-components-spacing';

export const Wrapper = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    ${py(16)};

    display: none;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: ${theme.zindex.cookie};
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    
    ${({ visible }) =>
    visible &&
    css`
      display: block;
    `}
  `}
`;

export const Text = styled.p`
  ${({ theme }) =>
  theme &&
  css`
    color: #fff;
    font-size: ${theme.font.size.pixel('14')}
  
    a {
      font-weight: ${theme.font.weight.bold};
      text-decoration: underline;
    }
  `}
`;
