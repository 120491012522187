import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon } from 'components';
import { Wrapper } from './Password.styled';

const Password = ({ small, disabled, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <Wrapper>
      <input type={show ? 'text' : 'password'} small={small} disabled={disabled} {...rest} />
      <ButtonIcon
        type="button"
        icon={show ? 'eye' : 'eyeCrossed'}
        secondary
        large={!small}
        disabled={disabled}
        rounded
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        onClick={() => setShow((prev) => !prev)}
      />
    </Wrapper>
  );
};

Password.propTypes = {
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

Password.defaultProps = {
  small: false,
  disabled: false,
};

export default Password;
