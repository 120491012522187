import styled, { css } from 'styled-components';
import { p, mt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { Button } from 'components';

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      text-align: center;

      ${breakpoint('sm')`
      ${css`
        display: flex;
      `}
    `}

      ${breakpoint('lg')`
      ${css`
        display: block;
        margin-right: ${({ theme }) => `-${theme.grid.gutter}px`};
      `}
    `}
    `}
`;

export const Thumbnail = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      width: 180px;
      height: 180px;
      display: inline-block;

      img {
        max-height: 100%;
      }

      ${breakpoint('sm')`
      ${css`
        display: block;
        flex: 0 0 240px;
        width: 240px;
        height: 240px;
      `}
    `}

      ${breakpoint('lg')`
      ${css`
        width: 100%;
        height: auto;
        background-color: ${theme.colors.gray[100]};
      `}
    `}
    `}
`;

export const Content = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(16)};
      ${p(8)};

      text-align: left;
      flex: 1;
      border: 1px solid ${theme.colors.gray['200']};

      ${breakpoint('sm')`
      ${css`
        ${mt(0)};
        ${p(38)};
      `}
    `}

      ${breakpoint('lg')`
      ${css`
        ${p(16)};

        border: none;
        background-color: ${theme.colors.gray[100]};
        text-align: center;
      `}
    `}
    `}
`;

export const SocialWrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      position: relative;
      overflow: hidden;
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 6px;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
    `}
`;

export const SocialButton = styled(Button)`
  ${({ theme }) =>
    theme &&
    css`
      justify-content: start;
      padding: 0;
      border: 0 none;
      border-radius: 3px;
      text-decoration: none;
      transition: all 250ms linear;
      color: #fff;
      height: 32px;
      width: 32px;
      text-align: left;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
        left: -100%;
        transition: all 0.4s ease-in-out;
      }

      &:hover {
        text-decoration: none;
      }

      &:hover::before {
        left: 100%;
      }

      > div {
        width: 100%;
        height: 100%;

        > div {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          > svg {
            display: flex;
            fill: #fff;
            justify-content: center;
            align-items: center;
            width: 60%;
            height: 60%;
            text-align: center;
          }
        }
      }
    `}
`;

export const TwitterButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #292929;

      > div > div > svg {
        width: 50%;
        height: 50%;
      }

      &:hover {
        background-color: #3e3e3e;
      }
    `}
`;

export const FacebookButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #3b5998;

      &:hover {
        background-color: #293e69;
      }
    `}
`;

export const LinkedinButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #1a81b9;

      &:hover {
        background-color: #0770a9;
      }
    `}
`;

export const InstagramButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      transition: all 1s;
      background: radial-gradient(
          circle farthest-corner at 0% 150%,
          #ffe17d 0%,
          #ffcd69 12%,
          #fa9137 25%,
          #eb4141 41%,
          transparent 95%
        ),
        linear-gradient(-15deg, #234bd7 -10%, #c33cbe 65%);
    `}
`;

export const TikTokButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #000000;

      > div > div > svg {
        width: 90%;
        height: 90%;
      }

      &:hover {
        background-color: #121212;
      }
    `}
`;
