import styled, { css } from 'styled-components';
import { px } from 'styled-components-spacing';

export default styled.div`
  position: relative;
  display: flex;
`;

export const ClearButton = styled.button`
  ${({ theme }) =>
    theme &&
    css`
      ${px(8)};

      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;
      background-color: ${theme.colors.gray[100]};
      cursor: pointer;
      
      .accessibility &:focus {
        outline: none;
        
        svg {
          outline: 1px dashed #000;
        }
      }
      
      svg {
        width: 14px;
        height: 14px;
        fill: ${theme.colors.primary};
      }
      
      ${({ disabled }) =>
        disabled &&
        css`
          background-color: ${theme.colors.gray[200]};
          pointer-events: none;
        ` 
      }
    `
  }
`;
