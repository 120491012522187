import { takeLatest, call, put } from 'redux-saga/effects';
import * as localLibraryService from 'api/services/library';
import { isEmpty } from 'lodash';

import {
  LOAD_MY_LOCAL_LIBRARY,
} from 'store/constants';

import {
  setNewLoadingCreator,
  removeLoadingCreator,
  setLocalLibraryCreator,
} from 'store/actionsCreators';

function* loadMyLocalLibrary() {
  try {
    put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(localLibraryService.getLibrary);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      if (!isEmpty(result) && !isEmpty(result)) {
        yield put(setLocalLibraryCreator({ all: result }));
      }
    }
  } catch (error) {
    console.log('[GET] my library error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

export default function* watchLibrary() {
  yield takeLatest(LOAD_MY_LOCAL_LIBRARY, loadMyLocalLibrary);
}