import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSubRoutes, useExactRedirect } from 'hooks';
import { globalPropTypes } from 'helpers';

import { loadYcProfileCreator } from 'store/actionsCreators';

const Profile = ({ routes, getProfile }) => {
  useEffect(() => {
    getProfile();
  }, []);
  const [renderSubRoutes] = useSubRoutes(routes);
  const [renderExactRedirect] = useExactRedirect('/yc/profile', '/yc/profile/view');

  return (
    <>
      {renderSubRoutes()}
      {renderExactRedirect()}
    </>
  );
};

Profile.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  getProfile: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  routes: [],
};

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(loadYcProfileCreator()),
});

export default connect(null, mapDispatchToProps)(Profile);
