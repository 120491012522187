import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';

import { Margin } from 'styled-components-spacing';

import { Button, Col, Datepicker, Field, Row, Select } from 'components';

import { loadUserAddListCertificatesCreator } from 'store/actionsCreators';

import { iconList as icons } from 'assets';
import { useTranslation } from 'react-i18next';

const Spacer = ({ children }) => <Margin top={{ xs: 12 }}>{children}</Margin>;

function AddCertificatesFiltrationForm({ loadCertificates, certificatesFiltration, asUser }) {
  const { t } = useTranslation();

  const [filtration, setFiltration] = useState({ ...certificatesFiltration });

  const handleChangeNative = (name) => (e) => {
    setFiltration({
      ...filtration,
      [name]: e.target.value,
    });
  };

  const handleChangeCustom = (name) => (value) => {
    setFiltration({
      ...filtration,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loadCertificates(filtration, asUser);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col size={{ lg: 1 / 2 }}>
          <Spacer>
            <Field small id="certificate-number">
              <input
                id="certificate-number"
                placeholder={t('forms.certificateNumberPlaceholder')}
                name="certificateNumber"
                value={filtration.certificateNumber}
                onChange={handleChangeNative('certificateNumber')}
              />
            </Field>
          </Spacer>
        </Col>
        <Col size={{ lg: 1 / 2 }}>
          <Spacer>
            <Field small id="associated-email">
              <input
                id="associated-email"
                placeholder={t('forms.associatedEmailPlaceholder')}
                name="associatedEmail"
                value={filtration.associatedEmail}
                onChange={handleChangeNative('associatedEmail')}
              />
            </Field>
          </Spacer>
        </Col>
      </Row>
      <Row>
        <Col size={{ lg: 2 / 5 }}>
          <Spacer>
            <Field clear={() => handleChangeCustom('awardedDate')(null)}>
              <Datepicker
                small
                name="awardedDate"
                onChange={handleChangeCustom('awardedDate')}
                placeholderText={t('forms.awardedDatePlaceholder')}
                selected={filtration.awardedDate}
                dateFormat="yyyy-MM-dd"
              />
            </Field>
          </Spacer>
        </Col>
        <Col size={{ lg: 2 / 5 }}>
          <Spacer>
            <Field clear={() => handleChangeCustom('validityDate')(null)}>
              <Datepicker
                small
                name="validityDate"
                onChange={handleChangeCustom('validityDate')}
                placeholderText={t('forms.validityDatePlaceholder')}
                selected={filtration.validityDate}
                dateFormat="yyyy-MM-dd"
              />
            </Field>
          </Spacer>
        </Col>
        <Col size={{ lg: 1 / 5 }}>
          <Spacer>
            <Button small icon>
              <ReactSVG
                style={{
                  width: 20,
                  fill: 'white',
                }}
                src={icons.search}
              />
            </Button>
          </Spacer>
        </Col>
      </Row>
    </form>
  );
}

AddCertificatesFiltrationForm.propTypes = {
  loadCertificates: PropTypes.func.isRequired,
  certificatesFiltration: PropTypes.shape({
    certificateNumber: PropTypes.string,
    associatedEmail: PropTypes.string,
    awardedDate: PropTypes.string,
    validityDate: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ user }) => ({
  certificatesFiltration: user.add.filtration,
});

const mapDispatchToProps = (dispatch) => ({
  loadCertificates: (payload, asUser) =>
    dispatch(loadUserAddListCertificatesCreator(payload, asUser)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCertificatesFiltrationForm);
