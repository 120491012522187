import { all } from 'redux-saga/effects';

import watchRegistration from './registrationSaga';
import watchLogin from './loginSaga';
import watchResetPassword from './resetPasswordSaga';
import watchUser from './userSaga';
import watchGlobalsSaga from './globalsSaga';
import watchOrganisation from './organisationSaga';
import watchCourse from './courseSaga';
import watchMa from './maSaga';
import watchList from './listSaga';
import watchPage from './pageSaga';
import watchSearch from './searchSaga';
import watchHome from './homeSaga';
import watchApp from './appSaga';
import watchIpma from './ipmaSaga';
import watchYc from './ycSaga';
import watchCpd from './cpdSaga';
import watchLibrary from './localLibrarySaga';

export default function* rootSaga() {
  yield all([
    watchRegistration(),
    watchLogin(),
    watchResetPassword(),
    watchUser(),
    watchGlobalsSaga(),
    watchOrganisation(),
    watchCourse(),
    watchMa(),
    watchList(),
    watchPage(),
    watchSearch(),
    watchHome(),
    watchApp(),
    watchIpma(),
    watchYc(),
    watchCpd(),
    watchLibrary(),
  ]);
}
