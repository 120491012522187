import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Header,
  Row,
  Col,
  Filters,
  Dropdown,
  Heading,
  FiltersControlsYcProjects,
  Pagination,
  BoxList,
  Spinner,
} from 'components';
import { connect } from 'react-redux';
import { Padding, Margin } from 'styled-components-spacing';
import {
  loadListCreator,
  setListPageCreator,
  setListPerPageCreator,
  setListSortCreator,
  setListFiltersCreator,
  removeListFiltersCreator,
  loadGlobalProjectSkillsCreator,
} from 'store/actionsCreators';
import { isLoading, globalPropTypes } from 'helpers';
import { useTranslation } from 'react-i18next';
import { ListTypeContainer, ListType } from './ProjectsList.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.projects',
    url: '/training',
  },
];

const ProjectsList = ({
  skills,
  loading,
  list,
  loadList,
  setPage,
  setPerPage,
  setSort,
  setFilters,
  removeFilters,
  loadGlobalProjectSkills,
}) => {
  const { filters, items, page, pages, perPage, sort } = list;
  const { t } = useTranslation();
  const scrollAfterFiltersRef = useRef(null);
  const [listType, setListType] = useState('projects');

  useEffect(() => {
    setPage(1);
    loadList({ type: listType });
  }, [perPage, sort]);

  useEffect(() => {
    if (!skills.length) {
      loadGlobalProjectSkills();
    }
  }, []);

  const filtersFieldsProject = [
    {
      type: 'input',
      name: 'title',
      id: 'f-title',
      label: t('page.listProjects.titleInput'),
      placeholder: t('page.listProjects.titlePlaceholder'),
    },
    {
      type: 'select',
      name: 'type',
      id: 'f-type',
      label: t('page.listProjects.typeSelect'),
      placeholder: t('page.listProjects.typePlaceholder'),
      options: [
        { value: 'global', label: 'Global' },
        { value: 'national', label: 'National' },
      ],
    },
    {
      type: 'select',
      name: 'status',
      id: 'f-status',
      label: t('page.listProjects.statusSelect'),
      placeholder: t('page.listProjects.statusPlaceholder'),
      options: [
        { value: 'planning', label: 'Planning' },
        { value: 'running', label: 'Running' },
        { value: 'completed', label: 'Completed' },
      ],
    },
  ];

  const filterFieldsPositions = [
    {
      type: 'input',
      name: 'title',
      id: 'f-title',
      label: t('page.listProjects.titleInputPosition'),
      placeholder: t('page.listProjects.titlePlaceholderPosition'),
    },
    {
      type: 'select',
      name: 'roles',
      id: 'f-roles',
      label: t('page.listProjects.rolesSelect'),
      placeholder: t('page.listProjects.rolesPlaceholder'),
      options: [
        { value: 'open', label: 'Open roles' },
        { value: 'all', label: 'All roles' },
      ],
    },
    {
      type: 'multi-select',
      name: 'skills',
      id: 'f-skills',
      label: t('page.listProjects.skillSelect'),
      placeholder: t('page.listProjects.skillPlaceholder'),
      options: skills,
    },
  ];

  const changeListType = (type) => {
    setFilters({});
    setListType(type);
    setPage(1);
    loadList({ type });
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.listProjects.title')} />
      <PageContent>
        <Container>
          <Row>
            <Col size={{ xl: 1 / 3 }}>
              <Padding all={{ xs: 12, xl: 20 }} className="border-xs">
                <Dropdown
                  openbreakpoint="xl"
                  title={
                    <Heading as="h3" size="h4" noLine>
                      {t('page.listProjects.filtersTitle')}
                    </Heading>
                  }
                >
                  <ListTypeContainer>
                    <ListType
                      active={listType === 'projects'}
                      onClick={() => changeListType('projects')}
                    >
                      {t('page.listProjects.listProjects')}
                    </ListType>
                    <ListType
                      active={listType === 'positions'}
                      onClick={() => changeListType('positions')}
                    >
                      {t('page.listProjects.listPositions')}
                    </ListType>
                  </ListTypeContainer>
                  {listType === 'projects' && (
                    <Filters
                      fields={filtersFieldsProject}
                      filters={filters}
                      onChangeFilter={(payload) => setFilters(payload)}
                      onClickApplyFilters={() => {
                        setPage(1);
                        loadList({ type: listType });
                      }}
                      onClickRemoveAllFilters={() => removeFilters()}
                      scrollAfterFiltersRef={scrollAfterFiltersRef}
                    />
                  )}
                  {listType === 'positions' && (
                    <Filters
                      fields={filterFieldsPositions}
                      filters={filters}
                      onChangeFilter={(payload) => setFilters(payload)}
                      onClickApplyFilters={() => {
                        setPage(1);
                        loadList({ type: listType });
                      }}
                      onClickRemoveAllFilters={() => removeFilters()}
                      scrollAfterFiltersRef={scrollAfterFiltersRef}
                    />
                  )}
                </Dropdown>
              </Padding>
            </Col>
            <Margin as={Col} size={{ xl: 2 / 3 }} top={{ xs: 16, xl: 0 }}>
              <FiltersControlsYcProjects
                perPageSelected={perPage}
                perPageOnChange={(payload) => setPerPage(payload.value)}
                sortBySelected={sort}
                sortByOnChange={(payload) => setSort(payload.value)}
              />
              {loading ? <Spinner /> : null}
              {!loading ? (
                <div ref={scrollAfterFiltersRef}>
                  {listType === 'projects' && (
                    <BoxList
                      type="project"
                      items={items}
                      alert={t('global.noResultsFound')}
                      headerAs="h2"
                    />
                  )}
                  {listType === 'positions' && (
                    <BoxList
                      type="project-role"
                      items={items}
                      alert={t('global.noResultsFound')}
                      headerAs="h2"
                    />
                  )}
                  <Pagination
                    styled
                    currentPage={page}
                    totalPages={pages}
                    onChange={(payload) => {
                      setPage(payload);
                      loadList({ type: listType });
                    }}
                  />
                </div>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

ProjectsList.propTypes = {
  skills: PropTypes.shape([]).isRequired,
  loading: PropTypes.bool,
  list: globalPropTypes.ListPropsTypes.isRequired,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  removeFilters: PropTypes.func.isRequired,
  loadGlobalProjectSkills: PropTypes.func.isRequired,
};

ProjectsList.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ globals, loading, list: { projects: list } }) => ({
  skills: globals.projectSkills,
  loading: isLoading(loading, 'LOAD_LIST_PROJECTS'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: (payload) => dispatch(loadListCreator('projects', payload)),
  setPage: (payload) => dispatch(setListPageCreator('projects', payload)),
  setPerPage: (payload) => dispatch(setListPerPageCreator('projects', payload)),
  setSort: (payload) => dispatch(setListSortCreator('projects', payload)),
  setFilters: (payload) => dispatch(setListFiltersCreator('projects', payload)),
  removeFilters: () => dispatch(removeListFiltersCreator('projects')),
  loadGlobalProjectSkills: () => dispatch(loadGlobalProjectSkillsCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
