import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Margin } from 'styled-components-spacing';

import { resendRegistrationActivateCreator } from 'store/actionsCreators';
import { Button, Col, Heading, Row, Stepper } from 'components';
import { StepperWrapper } from 'containers/auth/Register/Register.styled';
import { useTranslation } from 'react-i18next';

function SummaryStep({ resendRegistrationActivate, history }) {
  const { t } = useTranslation();
  return (
    <div>
      <Row halign="justify" valign="center">
        <Col size={{ xs: 3 / 4 }}>
          <Heading as="h1" size="h2">
            {t('page.auth.register.registrationCompleteHeading')}
          </Heading>
        </Col>
        <StepperWrapper>
          <Stepper
            activeIndex={2}
            steps={[
              { label: 1, disabled: true, go: () => history.replace('/auth/register') },
              {
                label: 2,
                disabled: true,
                go: () => history.replace('/auth/register/extendedRegistrationStep'),
              },
              { label: 3, disabled: true },
            ]}
          />
        </StepperWrapper>
      </Row>
      <Row>
        <Col size={{ xs: 1 / 1 }}>
          <Margin top={{ xs: 24 }} bottom={{ xs: 24 }}>
            <p>
              <strong>{t('page.auth.register.registrationCompleteInfoHeading')}!</strong>
            </p>
            <Margin as="p" top={{ xs: 8 }}>
              {t('page.auth.register.registrationCompleteInfoDesc')}
            </Margin>
            <Margin as="p" top={{ xs: 8 }}>
              {t('page.auth.register.registrationCompleteInfoDesc2')}
            </Margin>
          </Margin>
        </Col>
      </Row>
      <Row>
        <Col size={{ xs: 1 / 1, md: 4 / 12, lg: 3 / 12 }}>
          <Button onClick={() => resendRegistrationActivate(history)} primary>
            {t('page.auth.register.resendVertificationButton')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

SummaryStep.propTypes = {
  resendRegistrationActivate: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired, replace: PropTypes.func.isRequired })
    .isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  resendRegistrationActivate: (history) => dispatch(resendRegistrationActivateCreator(history)),
});

export default connect(null, mapDispatchToProps)(SummaryStep);
