import { combineReducers } from 'redux';

import alerts from './reducers/alerts';
import registration from './reducers/registration';
import user from './reducers/user';
import globals from './reducers/globals';
import editProfile from './reducers/editProfile';
import editPassword from './reducers/editPassword';
import deleteProfile from './reducers/deleteProfile';
import organisation from './reducers/organisation';
import loading from './reducers/loading';
import course from './reducers/course';
import ma from './reducers/ma';
import list from './reducers/list';
import page from './reducers/page';
import search from './reducers/search';
import home from './reducers/home';
import ipma from './reducers/ipma';
import yc from './reducers/yc';
import login from './reducers/login';
import cpd from './reducers/cpd';
import localLibrary from './reducers/localLibrary';

export default combineReducers({
  alerts,
  registration,
  user,
  globals,
  editProfile,
  editPassword,
  deleteProfile,
  organisation,
  loading,
  course,
  ma,
  list,
  page,
  search,
  home,
  ipma,
  yc,
  login,
  cpd,
  localLibrary,
});
