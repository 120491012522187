import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BoxList, FiltersControls, Pagination, Spinner, ButtonAction } from 'components';
import {
  loadMaCpdRequestsCreator,
  setMaCpdRequestsPageCreator,
  setMaCpdRequestsPerPageCreator,
} from 'store/actionsCreators';
import { isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import AcceptModal from '../AcceptModal/AcceptModal';

const Rejected = ({ cpds, page, pages, perPage, loading, loadCpds, setPage, setPerPage }) => {
  const { t } = useTranslation();
  const [acceptModal, setAcceptModal] = useState(false);

  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadCpds({ status: 'rejected' });
  }, [perPage, page, loadCpds]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="cpd-pending"
            items={cpds}
            alert={t('global.noResultsFound')}
            customRenderActions={({ item }) => (
              <>
                <ButtonAction
                  icon="link"
                  title="Agenda"
                  as="a"
                  target="_blank"
                  href={item.linkToAgenda}
                  disabled={!item.linkToAgenda}
                />
                <ButtonAction
                  icon="duplicate"
                  title="Diploma"
                  as="a"
                  target="_blank"
                  href={item.diploma}
                  disabled={!item.diploma}
                />
                <ButtonAction
                  icon="checkCircle"
                  title="Accept"
                  verticalviewbreakpoint="xl"
                  onClick={() => setAcceptModal(item)}
                />
              </>
            )}
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </>
      ) : null}
      <AcceptModal
        status="rejected"
        open={acceptModal.id}
        setOpen={setAcceptModal}
        cpdPoints={acceptModal.points}
      />
    </>
  );
};

Rejected.propTypes = {
  cpds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadCpds: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cpd, loading }) => ({
  cpds: cpd.certificationBody.list.items,
  page: cpd.certificationBody.list.page,
  pages: cpd.certificationBody.list.pages,
  perPage: cpd.certificationBody.list.perPage,
  loading: isLoading(loading, 'MA'),
});
const mapDispatchToProps = (dispatch) => ({
  loadCpds: (payload) => dispatch(loadMaCpdRequestsCreator(payload)),
  setPage: (payload) => dispatch(setMaCpdRequestsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setMaCpdRequestsPerPageCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rejected);
