import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { mb, mr, my, pl, px, py } from 'styled-components-spacing';

export const TitleWrapper = styled.button.attrs(() => ({
  type: 'button',
}))`
  ${({ theme }) =>
  theme &&
  css`
    ${px(24)};
    ${py(16)};

    background-color: ${theme.colors.gray['100']};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    font-size: ${theme.font.size.pixel('14')};
    font-weight: ${theme.font.weight.bold};
    color: ${theme.colors.gray['500']};
    
    ${breakpoint('xl')`
      ${css`
        font-size: ${theme.font.size.pixel('18')};
      `}
    `}
    `
}`;

export const ContentWrapper = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    ${px(24)};
    ${py(16)};

    display: none;
    font-size: ${theme.font.size.pixel('16')};
    color: ${theme.colors.gray['500']};
    `
}`;

export const Arrow = styled.span`
  margin-bottom: 4px;
  padding-left: 8px;

  svg {
    width: 16px;
    height: 9px;
    fill: #000;
    pointer-events: none;
    transform-origin: center;
  }
`;

export const ItemWrapper = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    ${mb(16)};

    ${({ active }) =>
      active &&
      css`
        ${ContentWrapper} {
          display: block;
        }
        
        ${Arrow} {
          svg {
            transform: rotate(180deg);
          }
        }
      `}
    `
}`;
