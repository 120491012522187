import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';

import { Margin, Padding } from 'styled-components-spacing';
import { useFormFields } from 'helpers';
import {
  loadYcPeopleAddListUsersCreator,
  loadGlobalJobsTitlesCreator,
  loadGlobalIndustriesCreator,
  addYcPeopleUsersCreator,
  loadGlobalYcRolesCreator,
} from 'store/actionsCreators';
import {
  Alerts,
  Button,
  Col,
  Checkbox,
  Container,
  Field,
  Header,
  PageContent,
  Row,
  Select,
  Table,
  Spinner,
  Dropdown,
  Heading,
} from 'components';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.yc.people',
    url: '/yc/people',
  },
  {
    title: 'navigation.breadcrumbs.yc.addPeople',
    url: '/yc/people/add',
  },
];

function People({
  users,
  loadUsers,
  loadGlobalJobsTitles,
  loadGlobalIndustries,
  loading,
  addUsers,
  loadGlobalMaRoles,
  roles,
  history,
  jobsTitles,
  industries,
}) {
  const { t } = useTranslation();
  const [selectedPeople, setPeople] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [filterFields, handleFilterFieldChange] = useFormFields({
    firstName: '',
    lastName: '',
    jobsTitle: null,
    industry: null,
  });

  const filters = {
    firstName: {
      type: 'input',
      name: 'first-name',
      id: 'f-name',
      label: t('page.organisation.peopleAdd.firstName'),
      placeholder: t('forms.firstNamePlaceholder'),
    },
    lastName: {
      type: 'input',
      name: 'last-name',
      id: 'f-lastName',
      label: t('page.organisation.peopleAdd.lastName'),
      placeholder: t('forms.lastNamePlaceholder'),
    },
    jobsTitle: {
      type: 'select',
      name: 'job-title',
      id: 'f-jobTitle',
      label: t('page.organisation.peopleAdd.jobTitle'),
      placeholder: t('forms.jobTitlePlaceholder'),
      options: jobsTitles,
    },
    industry: {
      type: 'select',
      name: 'industry',
      id: 'f-industry',
      label: t('page.organisation.peopleAdd.industry'),
      placeholder: t('forms.industryPlaceholder'),
      options: industries,
    },
  };

  useEffect(() => {
    setPeople(selectedPeople.filter((personId) => find(users, { id: personId })));
  }, [users]);

  useEffect(() => {
    if (!roles.length) {
      loadGlobalMaRoles();
    }

    if (!industries.length) {
      loadGlobalIndustries();
    }

    if (!jobsTitles.length) {
      loadGlobalJobsTitles();
    }
  }, []);

  const handleSelectRow = (id) => (value) => {
    setPeople(
      value ? [...selectedPeople, id] : selectedPeople.filter((personId) => personId !== id),
    );
  };

  const handleChangeRole = (id, select) => ({ value }) => {
    const noDuplicates = selectedRoles.filter((item) => {
      if (item.userId === id && item.select === select) {
        return false;
      }
      return true;
    });
    setSelectedRoles([...noDuplicates, { userId: id, userRole: value, select }]);
  };

  const submit = () => {
    addUsers(
      selectedPeople.map((item) => {
        const user = { userId: item, userRoles: [] };
        selectedRoles.forEach((el) => {
          if (el.userId === item) {
            user.userRoles.push(el.userRole);
          }
        });
        return { ...user };
      }),
      history,
    );
  };

  const applyFilters = () => {
    const filtersToApply = Object.keys(filterFields)
      .filter((field) => filterFields[field])
      .reduce(
        (p, c) => ({
          ...p,
          [filters[c].name]: filterFields[c].value ? filterFields[c].value : filterFields[c],
        }),
        {},
      );
    if (Object.keys(filtersToApply).length) {
      loadUsers({ ...filtersToApply });
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filterFields]);

  const handleInputChange = (e) => {
    handleFilterFieldChange(e);
  };

  const handleSelectChange = (field) => (selected) => {
    handleFilterFieldChange({
      target: {
        id: field,
        value: selected,
      },
    });
  };

  const renderSelect = (field, label, placeholder, options) => {
    return (
      <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }} key={`sid-${field}`}>
        <Field
          label={label}
          id={`f-${field}`}
          value={filterFields[field]}
          clear={() => handleSelectChange(field)(null)}
        >
          <Select
            id={`f-${field}`}
            name={field}
            placeholder={placeholder}
            value={filterFields[field]}
            onChange={handleSelectChange(field)}
            options={options}
          />
        </Field>
      </Margin>
    );
  };

  const renderInput = (field, label, placeholder) => (
    <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }} key={`fid-${field}`}>
      <Field label={label}>
        <input
          id={field}
          type="text"
          placeholder={placeholder}
          value={filterFields[field]}
          onChange={handleInputChange}
        />
      </Field>
    </Margin>
  );

  const renderFilters = () => (
    <>
      <Margin as="p" bottom={{ xs: 16 }}>
        <strong>{t('page.yc.peopleAdd.info')}</strong>
      </Margin>
      <form>
        <Row>
          <Margin left={{ xs: 16 }} right={{ xs: 16 }} style={{ width: '100%' }}>
            <Padding horizontal={{ xs: 16 }} vertical={{ xs: 16 }} className="border-xs">
              <Dropdown
                openbreakpoint="xl"
                title={
                  <Heading as="h3" size="h4">
                    {t('page.organisation.peopleAdd.filterTitle')}
                  </Heading>
                }
              >
                <Row>
                  {Object.keys(filterFields).map((field) => {
                    if (filters[field].type === 'input') {
                      return renderInput(field, filters[field].label, filters[field].placeholder);
                    }
                    return renderSelect(
                      field,
                      filters[field].label,
                      filters[field].placeholder,
                      filters[field].options,
                    );
                  })}
                </Row>
              </Dropdown>
            </Padding>
          </Margin>
        </Row>
      </form>
    </>
  );

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.yc.peopleAdd.title')} />
      <Container>
        <PageContent>
          <Alerts type="yc" />
          {renderFilters()}
          <Margin top={{ xs: 32 }}>
            {loading ? <Spinner /> : null}
            {!loading ? (
              <Table tablebreakpoint="lg">
                <Table.Head>
                  <Table.Row>
                    <Table.Heading />
                    <Table.Heading>{t('page.yc.peopleAdd.firstAndLastName')}</Table.Heading>
                    <Table.Heading>{t('page.yc.peopleAdd.role')}</Table.Heading>
                    <Table.Heading />
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {!loading && !users.length ? (
                    <Table.Row>
                      <Table.Cell colSpan={4}>{t('global.noResultsFound')}</Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && users.length
                    ? users.map((user) => (
                        <Table.Row key={`person-add-${user.id}`}>
                          <Table.Cell>
                            <Field>
                              <Checkbox
                                onChange={handleSelectRow(user.id)}
                                selected={selectedPeople.includes(user.id)}
                              />
                            </Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.yc.peopleAdd.firstAndLastName')}:</Table.Label>
                            {`${user.firstName} ${user.lastName}`}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.yc.peopleAdd.role')}:</Table.Label>
                            <Select small onChange={handleChangeRole(user.id, 0)} options={roles} />
                          </Table.Cell>
                          <Table.Cell>
                            <Select small onChange={handleChangeRole(user.id, 1)} options={roles} />
                          </Table.Cell>
                        </Table.Row>
                      ))
                    : null}
                </Table.Body>
              </Table>
            ) : null}
          </Margin>
          <Margin top={{ xs: 16 }}>
            <Row>
              <Col size={{ md: 1 / 5, lg: 1 / 6 }}>
                <Button onClick={submit}>{t('global.addUserButton')}</Button>
              </Col>
            </Row>
          </Margin>
        </PageContent>
      </Container>
    </>
  );
}

People.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadGlobalJobsTitles: PropTypes.func.isRequired,
  loadGlobalIndustries: PropTypes.func.isRequired,
  addUsers: PropTypes.func.isRequired,
  loadGlobalMaRoles: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.number.isRequired }),
  ).isRequired,
  jobsTitles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
};
People.defaultProps = {};

const mapStateToProps = ({ yc, loading, globals }) => ({
  users: yc.people.add.data,
  loading: !!loading.filter((item) => item.type === 'YC').length,
  roles: globals.yc.roles,
  jobsTitles: globals.jobsTitles,
  industries: globals.industries,
});
const mapDispatchToProps = (dispatch) => ({
  loadUsers: (payload) => dispatch(loadYcPeopleAddListUsersCreator(payload)),
  loadGlobalJobsTitles: () => dispatch(loadGlobalJobsTitlesCreator()),
  loadGlobalIndustries: () => dispatch(loadGlobalIndustriesCreator()),
  addUsers: (payload, history) => dispatch(addYcPeopleUsersCreator(payload, history)),
  loadGlobalMaRoles: () => dispatch(loadGlobalYcRolesCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
