import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Header,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  Box,
  IconText,
  Tags,
  Map,
  ButtonAction,
} from 'components';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { loadListViewCreator, removeListViewCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { newLineToBr, correctExternalLink, parseUrlTitle } from 'helpers';
import { useAuthLock } from 'hooks';
import {
  StrongWrap,
  SocialContainer,
  FacebookSocial,
  LinkedinSocial,
  TwitterSocial,
  InstagramSocial,
  YoutubeSocial,
  ShareButton,
} from './View.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.events',
    url: '/events',
  },
];

const View = ({ loadView, removeView, view, history }) => {
  const { t } = useTranslation();
  const { id, name } = useParams();
  const [renderAuthLock] = useAuthLock(history);

  const {
    title,
    description,
    nationalName,
    tags,
    website,
    place,
    countryCode,
    startDate,
    endDate,
    deliveryMode,
    timeOfEvent,
    mainLanguage,
    secondaryLanguage,
    price,
    lat,
    lng,
    timezone,
    organisator,
    ticketsLink,
    contactEmail,
    venueName,
    facebookLink,
    linkedinLink,
    twitterLink,
    instagramLink,
    youtubeLink,
    customLinks,
  } = view;

  const details = [
    { name: 'page.singleEvent.country', value: countryCode },
    { name: 'page.singleEvent.venueName', value: venueName },
    { name: 'page.singleEvent.contactEmail', value: contactEmail },
    { name: 'page.singleEvent.startingDate', value: startDate },
    { name: 'page.singleEvent.endingDate', value: endDate },
    { name: 'page.singleEvent.modeOfDelivery', value: deliveryMode },
    { name: 'page.singleEvent.timeOfEvent', value: timeOfEvent },
    { name: 'page.singleEvent.timezone', value: timezone },
    { name: 'page.singleEvent.mainLanguage', value: mainLanguage },
    { name: 'page.singleEvent.secondaryLanguages', value: secondaryLanguage },
    { name: 'page.singleEvent.price', value: price },
  ];

  useEffect(() => {
    loadView(id, history);

    return () => {
      removeView();
    };
  }, []);

  useEffect(() => {
    if (!name && view && view.title) {
      history.replace({ pathname: `/events/${id}/${parseUrlTitle(view.title)}` });
    }
  }, [view]);

  const renderDetails = () => (
    <Padding horizontal={{ xl: 20 }} vertical="20">
      <Margin bottom="16">
        <HeadingWrapper>
          <Heading as="h2" size="h4" firstLine>
            {t('page.singleEvent.details')}
          </Heading>
        </HeadingWrapper>
        {place && (
          <Margin as={Row} bottom="16">
            <Col size={1 / 2}>{t('page.singleEvent.address')}</Col>
            <Col size={1 / 2} className="text-right">
              <StrongWrap>
                <IconText inline icon="marker" text={place} />
              </StrongWrap>
            </Col>
          </Margin>
        )}
        {details &&
          details.map(
            (item) =>
              item.value && (
                <Margin as={Row} bottom="16">
                  <Col size={1 / 2}>{t(item.name)}</Col>
                  <Col size={1 / 2} className="text-right">
                    <StrongWrap>{item.value}</StrongWrap>
                  </Col>
                </Margin>
              ),
          )}
        {ticketsLink && (
          <Margin as={Row} bottom="16">
            <Col style={{ textAlign: 'center' }}>
              <ButtonAction
                title={t('page.singleEvent.ticketsLink')}
                icon="ticket"
                as="a"
                href={ticketsLink}
                target="_blank"
                rel="nofollow noopener"
              />
            </Col>
          </Margin>
        )}
      </Margin>
      {customLinks && customLinks.length > 0 && (
        <Margin>
          <HeadingWrapper>
            <Heading as="h2" size="h4" firstLine>
              {t('page.singleEvent.links')}
            </Heading>
          </HeadingWrapper>
          {customLinks.map(
            (item) =>
              item.url && (
                <Margin as={Row} bottom="16">
                  <Col style={{ overflowWrap: 'anywhere' }}>
                    <ButtonAction
                      title={item.label}
                      icon="link"
                      as="a"
                      href={item.url}
                      target="_blank"
                      rel="nofollow noopener"
                    />
                  </Col>
                </Margin>
              ),
          )}
        </Margin>
      )}
    </Padding>
  );

  const renderSocialLinks = () => (
    <Margin top="16">
      <SocialContainer>
        {facebookLink && (
          <FacebookSocial href={facebookLink} target="_blank" rel="nofollow noopener">
            <ReactSVG src={iconList.facebook} />
          </FacebookSocial>
        )}
        {linkedinLink && (
          <LinkedinSocial href={linkedinLink} target="_blank" rel="nofollow noopener">
            <ReactSVG src={iconList.linkedin} />
          </LinkedinSocial>
        )}
        {twitterLink && (
          <TwitterSocial href={twitterLink} target="_blank" rel="nofollow noopener">
            <ReactSVG src={iconList.twitter} />
          </TwitterSocial>
        )}
        {instagramLink && (
          <InstagramSocial href={instagramLink} target="_blank" rel="nofollow noopener">
            <ReactSVG src={iconList.instagram} />
          </InstagramSocial>
        )}
        {youtubeLink && (
          <YoutubeSocial href={youtubeLink} target="_blank" rel="nofollow noopener">
            <ReactSVG src={iconList.youtube} />
          </YoutubeSocial>
        )}
      </SocialContainer>
    </Margin>
  );

  const renderContactDetails = () => (
    <Padding all="20" className="bg-gray-100">
      {organisator && organisator.title && (
        <Margin bottom="16">
          <Heading as="h3" size="h6">
            {organisator.title}
          </Heading>
        </Margin>
      )}

      {website && (
        <a
          href={correctExternalLink(website)}
          target="_blank"
          rel="noopener noreferrer"
          className="link link--16 word-break"
        >
          {website}
        </a>
      )}
    </Padding>
  );

  const renderMap = () => {
    if (!lat || !lng) return null;

    return <Map lat={lat} lng={lng} />;
  };

  const handleShareClick = async () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Event - ${title}`,
          text: `MyIPMA event - ${title}`,
          url: document.location,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch((error) => {
          console.error('Something went wrong sharing', error);
        });
    }
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={title}
        description={nationalName}
        big
        headingFirstLine
      />
      <PageContent>
        {renderAuthLock()}
        <Container>
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                <Cms>
                  <HeadingWrapper>
                    <Heading as="h2" size="h5">
                      {title}
                    </Heading>
                  </HeadingWrapper>
                  {nationalName && (
                    <p>
                      <strong>{nationalName}</strong>
                    </p>
                  )}

                  {description ? (
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
                  ) : null}
                </Cms>
                {tags && !!tags.length && (
                  <Margin top="24">
                    <Tags tags={tags} />
                  </Margin>
                )}
              </Margin>

              {!!organisator && !!organisator.id && (
                <Margin bottom={{ xs: 32, xl: 48 }}>
                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleEvent.organisator')}
                    </Heading>
                  </HeadingWrapper>
                  <Box
                    thumbnail={organisator.thumbnail}
                    title={organisator.title}
                    url={organisator.url}
                    type="ma"
                    description={organisator.description}
                    renderInfo={({ IconText }) => (
                      <>
                        {organisator.place && <IconText icon="marker" text={organisator.place} />}
                        {organisator.events && (
                          <IconText
                            icon="checkRectangle"
                            text={`${t('global.events')} (${organisator.events})`}
                          />
                        )}
                      </>
                    )}
                  />
                </Margin>
              )}
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              <div className="border-xl">
                {renderContactDetails()}
                {renderDetails()}
                {renderMap()}
              </div>
              {renderSocialLinks()}
            </Margin>
            <ShareButton onClick={() => handleShareClick()}>
              <ReactSVG src={iconList.share} />
            </ShareButton>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

View.propTypes = {
  loadView: PropTypes.func.isRequired,
  removeView: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  view: PropTypes.shape({
    title: PropTypes.string.isRequired,
    nationalName: PropTypes.string,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    website: PropTypes.string,
    place: PropTypes.string,
    countryCode: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    deliveryMode: PropTypes.string,
    timeOfEvent: PropTypes.string,
    mainLanguage: PropTypes.string,
    secondaryLanguage: PropTypes.string,
    price: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    timezone: PropTypes.string,
    ticketsLink: PropTypes.string,
    contactEmail: PropTypes.string,
    venueName: PropTypes.string,
    facebookLink: PropTypes.string,
    linkedinLink: PropTypes.string,
    twitterLink: PropTypes.string,
    instagramLink: PropTypes.string,
    youtubeLink: PropTypes.string,
    photo: PropTypes.string,
    customLinks: PropTypes.arrayOf({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
    organisator: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      url: PropTypes.string,
      thumbnail: PropTypes.string,
      place: PropTypes.string,
      description: PropTypes.string,
      website: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string,
      events: PropTypes.string,
    }),
  }),
};

View.defaultProps = {
  view: {
    nationalName: '',
    description: '',
    tags: [],
    website: '',
    place: '',
    countryCode: '',
    startDate: '',
    endDate: '',
    deliveryMode: '',
    timeOfEvent: '',
    mainLanguage: '',
    secondaryLanguage: '',
    price: '',
    lat: '',
    lng: '',
    timezone: '',
    organisator: {},
  },
};

const mapStateToProps = ({
  user: { appType },
  list: {
    events: { view },
  },
}) => ({
  appType,
  view,
});

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload, history) => dispatch(loadListViewCreator('events', payload, history)),
  removeView: () => dispatch(removeListViewCreator('events')),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
