import { SET_HOME_SLIDER } from '../constants';

const initialState = {
  slider: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_SLIDER:
      return { ...state, slider: action.payload };
    default:
      return state;
  }
};
