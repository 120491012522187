import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import clearErrors from 'helpers/clearErrors';
import parseDate from 'helpers/parseDate';

import {
  Alerts,
  Button,
  Checkbox,
  Col,
  Datepicker,
  Field,
  FileInput,
  Header,
  Heading,
  Container,
  PageContent,
  Radio,
  Row,
  Select,
} from 'components';
import { Margin } from 'styled-components-spacing';

import {
  loadGlobalEventAttributesCreator,
  loadMaEventCreator,
  editMaEventCreator,
  loadCpdRolesCreator,
} from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import { getFileValue, filterCpdRoles } from 'helpers';
import createReducer, {
  defaultState,
  setInitDataCreator,
  updateFieldCreator,
  setErrorsCreator,
} from './reducer';
import { Error } from '../../../../components/Field/Field.styled';
import {
  MaxMinContainer,
  MaxMinWrapper,
  MaxMinLabel,
  MaxMinValue,
  MaxMinSuffix,
} from '../MyEvents.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.editEvent',
    url: '/ma/event/{{id}}/edit',
  },
];

function Edit({
  currencies,
  countries,
  timezones,
  languages,
  deliveryModes,
  cloudTags,
  loadGlobals,
  loadEvent,
  editEvent,
  history,
  match,
  loadCpdRoles,
  cpdRoles,
}) {
  const { t } = useTranslation();
  const {
    params: { id: eventId },
  } = match;
  const [{ data: event, errors }, dispatch] = useReducer(createReducer, defaultState);
  const [cpdRolesState, setCpdRolesState] = useState([]);

  useEffect(() => {
    setCpdRolesState(filterCpdRoles(cpdRoles, event.cpdRoles));
  }, [event.cpdRoles, cpdRoles]);

  useEffect(() => {
    loadGlobals();
    loadCpdRoles();

    loadEvent(eventId, (payload) => dispatch(setInitDataCreator(payload)));
  }, [eventId, loadGlobals, loadEvent]);

  const handleChangeNative = (name, errorName) => (e) => {
    dispatch(updateFieldCreator({ name, value: e.target.value }));
    if (errors[errorName]) dispatch(setErrorsCreator(clearErrors(errors, errorName)));
  };

  const handleChangeCustom = (name, errorName) => (value) => {
    dispatch(updateFieldCreator({ name, value }));
    if (errors[errorName]) dispatch(setErrorsCreator(clearErrors(errors, errorName)));
  };

  const handleChangeFileNative = (name, errorName) => (e) => {
    dispatch(
      updateFieldCreator({
        name,
        value: e.target.files && e.target.files.length ? e.target.files[0] : null,
      }),
    );
    if (errors[errorName]) dispatch(setErrorsCreator(clearErrors(errors, errorName)));
  };

  const handleClearFileNative = (name) => () => {
    dispatch(
      updateFieldCreator({
        name,
        value: null,
      }),
    );
  };

  const handleChangeNestedNative = (group, name, errorName) => (e) => {
    dispatch(
      updateFieldCreator({ name: group, value: { ...event[group], [name]: e.target.value } }),
    );
    if (errors[errorName]) dispatch(setErrorsCreator(clearErrors(errors, errorName)));
  };

  const handleChangeNestedCustom = (group, name, errorName) => (value) => {
    dispatch(updateFieldCreator({ name: group, value: { ...event[group], [name]: value } }));
    if (errors[errorName]) dispatch(setErrorsCreator(clearErrors(errors, errorName)));
  };

  const handleChangeMultiCustom = (name, errorName, id) => (value) => {
    dispatch(
      updateFieldCreator({
        name,
        value: value ? [...event[name], id] : event[name].filter((item) => item !== id),
      }),
    );
    if (errors[errorName]) dispatch(setErrorsCreator(clearErrors(errors, errorName)));
  };

  const handleCpdChangeCustom = (name, subName, errorName, index) => (value) => {
    dispatch(
      updateFieldCreator({
        name,
        value: event[name].reduce((prev, current, i) => {
          if (index !== i) return [...prev, current];
          const roleDefaultPoints = subName === 'role' ? { points: value.defaultPoints } : {};
          let val = value;
          if (
            subName === 'points' &&
            current.role &&
            parseInt(value, 10) < current.role.minPoints
          ) {
            val = `${current.role.minPoints}`;
          } else if (
            subName === 'points' &&
            current.role &&
            parseInt(value, 10) > current.role.maxPoints
          ) {
            val = `${current.role.maxPoints}`;
          } else {
            val = value;
          }
          return [...prev, { ...current, [subName]: val, ...roleDefaultPoints }];
        }, []),
      }),
    );
    if (errors[errorName]) dispatch(setErrorsCreator(clearErrors(errors, errorName)));
  };

  const clearCpdChange = (name, errorName, value) => {
    dispatch(
      updateFieldCreator({
        name,
        value: event[name].filter((item) => item.role.id !== value),
      }),
    );
    if (errors[errorName]) dispatch(setErrorsCreator(clearErrors(errors, errorName)));
  };

  const submit = () => {
    const params = new FormData();

    params.append('name', event.name || '');
    params.append('nationalName', event.nationalName || '');
    params.append('mainLanguage', event.mainLanguage ? event.mainLanguage.value : '');
    params.append(
      'secondaryLanguage',
      event.secondaryLanguage ? event.secondaryLanguage.value : '',
    );
    params.append(
      'dateFrom',
      event.dateFrom ? parseDate(event.dateFrom, 'YYYY-MM-DD HH:mm:ss') : '',
    );
    params.append('dateTo', event.dateTo ? parseDate(event.dateTo, 'YYYY-MM-DD HH:mm:ss') : '');
    params.append('timezone', event.timezone ? event.timezone.value : '');
    params.append('price', event.price || '');
    params.append('currency', event.currency ? event.currency.value : '');
    params.append('imageFile', getFileValue(event.imageFile));
    params.append('modeOfDelivery', event.modeOfDelivery);
    params.append('youngCrewEvent', false);
    params.append('address[line1]', event.address.line1 || '');
    params.append('address[line2]', event.address.line2 || '');
    params.append('address[postCode]', event.address.postCode || '');
    params.append('address[city]', event.address.city || '');
    params.append('address[country]', event.address.country ? event.address.country.value : '');
    params.append('phone', event.phone || '');
    params.append('email', event.email || '');
    params.append('website', event.website || '');
    params.append('shortDescription', event.shortDescription || '');
    params.append('fullDescription', event.fullDescription || '');
    event.tags.forEach((value, index) => params.append(`tags[${index}]`, value));
    event.cpdRoles.forEach((item, index) => {
      if (item && item.role) {
        params.append(`cpdRoleEventCourses[${index}][cpdRole]`, item.role.id);
        params.append(`cpdRoleEventCourses[${index}][points]`, item.points);
      }
    });

    editEvent(params, eventId, history, (payload) => dispatch(setErrorsCreator(payload)));
  };

  const isModeOfDeliveryOnline = () => event.modeOfDelivery === 2;

  return (
    <>
      <Header title={t('page.ma.editEvent.title')} breadcrumbs={breadcrumbs} />
      <PageContent>
        <Container>
          <Alerts type="ma" />
          <form>
            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.ma.editEvent.basicInformation')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    id="e-name"
                    label={t('page.ma.editEvent.nameLabel')}
                    required
                    error={errors.name}
                  >
                    <input
                      id="e-name"
                      type="text"
                      placeholder={t('page.ma.editEvent.namePlaceholder')}
                      value={event.name}
                      onChange={handleChangeNative('name', 'name')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    id="e-national-name"
                    label={t('page.ma.editEvent.nationalNameLabel')}
                    error={errors.nationalName}
                  >
                    <input
                      id="e-national-name"
                      type="text"
                      placeholder={t('page.ma.editEvent.nationalNamePlaceholder')}
                      value={event.nationalName}
                      onChange={handleChangeNative('nationalName', 'nationalName')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="e-main-language"
                    label={t('page.ma.editEvent.mainLanguageLabel')}
                    required
                    error={errors.mainLanguage}
                    value={event.mainLanguage}
                    clear={() => handleChangeCustom('mainLanguage', 'mainLanguage')('')}
                  >
                    <Select
                      id="e-main-language"
                      options={languages}
                      value={event.mainLanguage}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeCustom('mainLanguage', 'mainLanguage')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="e-secondary-language"
                    label={t('page.ma.editEvent.secondaryLanguageLabel')}
                    error={errors.secondaryLanguage}
                    value={event.secondaryLanguage}
                    clear={() => handleChangeCustom('secondaryLanguage', 'secondaryLanguage')('')}
                  >
                    <Select
                      id="e-secondary-language"
                      options={languages}
                      value={event.secondaryLanguage}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeCustom('secondaryLanguage', 'secondaryLanguage')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 3 }}>
                  <Field
                    id="e-date-from"
                    label={t('global.dateLabel')}
                    required
                    error={errors.dateFrom}
                    value={event.dateFrom}
                    clear={() => handleChangeCustom('dateFrom', 'dateFrom')('')}
                  >
                    <Datepicker
                      id="e-date-from"
                      showYearDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      defaultValue={new Date()}
                      placeholderText={t('global.dateFromPlaceholder')}
                      selected={event.dateFrom}
                      onChange={handleChangeCustom('dateFrom', 'dateFrom')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} top={{ lg: 22 }} size={{ lg: 1 / 3 }}>
                  <Field
                    id="e-date-to"
                    error={errors.dateTo}
                    value={event.dateTo}
                    clear={() => handleChangeCustom('dateTo', 'dateTo')('')}
                  >
                    <Datepicker
                      id="e-date-to"
                      showYearDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      defaultValue={new Date()}
                      placeholderText={t('global.dateToPlaceholder')}
                      selected={event.dateTo}
                      onChange={handleChangeCustom('dateTo', 'dateTo')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 3 }}>
                  <Field
                    id="e-timezone"
                    label={t('global.timezoneLabel')}
                    error={errors.timezone}
                    value={event.timezone}
                    clear={() => handleChangeCustom('timezone', 'timezone')('')}
                  >
                    <Select
                      id="e-timezone"
                      options={timezones}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeCustom('timezone', 'timezone')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field id="e-price" label={t('global.priceLabel')} error={errors.price}>
                    <input
                      id="e-price"
                      type="text"
                      placeholder={t('global.pricePlaceholder')}
                      value={event.price}
                      onChange={handleChangeNative('price', 'price')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="e-currency"
                    label={t('global.currencyLabel')}
                    error={errors.currency}
                    value={event.currency}
                    clear={() => handleChangeCustom('currency', 'currency')('')}
                  >
                    <Select
                      id="e-currency"
                      options={currencies}
                      value={event.currency}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeCustom('currency', 'currency')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="e-image-file"
                    label={t('page.ma.editEvent.uploadLogoLabel')}
                    error={errors.imageFile}
                    description={t('global.uploadImageInfo')}
                  >
                    <FileInput
                      id="e-image-file"
                      placeholder={t('global.uploadPlaceholder')}
                      fileName={event.imageFile ? event.imageFile.name || event.imageFile : ''}
                      clear={handleClearFileNative('imageFile')}
                      onChange={handleChangeFileNative('imageFile', 'imageFile')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field label={t('global.modeOfDeliveryLabel')} error={errors.modeOfDelivery}>
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {deliveryModes.map((el) => (
                        <Col
                          key={`delivery-mode-${el.value}`}
                          size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}
                        >
                          <Margin bottom="8">
                            <Radio
                              onClick={() =>
                                handleChangeCustom('modeOfDelivery', 'modeOfDelivery')(el.value)
                              }
                              selected={event.modeOfDelivery === el.value}
                            >
                              {el.label}
                            </Radio>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.ma.editEvent.contactData')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="e-line1"
                    label={t('global.addressLineLabel1')}
                    required={!isModeOfDeliveryOnline()}
                    error={errors['address.line1']}
                  >
                    <input
                      id="e-line1"
                      type="text"
                      placeholder={t('global.addressLinePlaceholder1')}
                      value={event.address.line1}
                      onChange={handleChangeNestedNative('address', 'line1', 'address.line1')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="e-line2"
                    label={t('global.addressLineLabel2')}
                    error={errors['address.line2']}
                  >
                    <input
                      id="e-line2"
                      type="text"
                      placeholder={t('global.addressLinePlaceholder2')}
                      value={event.address.line2 || ''}
                      onChange={handleChangeNestedNative('address', 'line2', 'address.line2')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="e-city"
                    label={t('global.cityLabel')}
                    required={!isModeOfDeliveryOnline()}
                    error={errors['address.city']}
                  >
                    <input
                      id="e-city"
                      type="text"
                      placeholder={t('global.cityPlaceholder')}
                      value={event.address.city}
                      onChange={handleChangeNestedNative('address', 'city', 'address.city')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="e-post-code"
                    label={t('global.postCodeLabel')}
                    required={!isModeOfDeliveryOnline()}
                    error={errors['address.postCode']}
                  >
                    <input
                      id="e-post-code"
                      type="text"
                      placeholder={t('global.postCodePlaceholder')}
                      value={event.address.postCode}
                      onChange={handleChangeNestedNative('address', 'postCode', 'address.postCode')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="e-country"
                    label={t('global.countryLabel')}
                    required={!isModeOfDeliveryOnline()}
                    error={errors['address.country']}
                    value={event.address.country}
                    clear={() =>
                      handleChangeNestedCustom('address', 'country', 'address.country')('')
                    }
                  >
                    <Select
                      id="e-country"
                      value={event.address.country}
                      options={countries}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeNestedCustom('address', 'country', 'address.country')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="e-phone-number"
                    label={t('global.phoneLabel')}
                    required={!isModeOfDeliveryOnline()}
                    error={errors.phone}
                  >
                    <input
                      id="e-phone-number"
                      type="text"
                      placeholder={t('global.phonePlaceholder')}
                      value={event.phone}
                      onChange={handleChangeNative('phone', 'phone')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field id="e-email" label={t('global.emailLabel')} required error={errors.email}>
                    <input
                      id="e-email"
                      type="text"
                      placeholder={t('global.emailPlaceholder')}
                      value={event.email}
                      onChange={handleChangeNative('email', 'email')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="e-website"
                    label={t('global.websiteLabel')}
                    required
                    error={errors.website}
                  >
                    <input
                      id="e-website"
                      type="text"
                      placeholder={t('global.websitePlaceholder')}
                      value={event.website}
                      onChange={handleChangeNative('website', 'website')}
                    />
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.ma.editEvent.description')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="e-short-desc"
                    label={t('page.ma.editEvent.descriptionShortLabel')}
                    required
                    error={errors.shortDescription}
                  >
                    <textarea
                      id="e-short-desc"
                      placeholder={t('page.ma.editEvent.descriptionShortPlaceholder')}
                      value={event.shortDescription}
                      onChange={handleChangeNative('shortDescription', 'shortDescription')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="e-full-desc"
                    label={t('page.ma.editEvent.descriptionFullLabel')}
                    error={errors.fullDescription}
                  >
                    <textarea
                      id="e-full-desc"
                      placeholder={t('page.ma.editEvent.descriptionFullPlaceholder')}
                      value={event.fullDescription}
                      onChange={handleChangeNative('fullDescription', 'fullDescription')}
                    />
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.organisation.createCourse.cpdRoles')}</Heading>
              </Margin>
              <Margin bottom="20">
                {event.cpdRoles.map((cpd, index) => (
                  <Margin top="20" as={Row} key={`cpdRole-${index}`}>
                    <Col size={{ lg: 1 / 3 }}>
                      <Field
                        id={`cpdRoleFieldId-${index}`}
                        label={t('page.organisation.createCourse.cpdRoleLabel', {
                          index: index + 1,
                        })}
                        required
                        value={cpd.role}
                        clear={() => clearCpdChange('cpdRoles', 'cpdRoles', cpd.role.id)}
                      >
                        <Select
                          id={`cpdRoleSelectId-${index}`}
                          placeholder={t('page.organisation.createCourse.cpdRoleSelectPlaceholder')}
                          value={cpd.role}
                          onChange={handleCpdChangeCustom('cpdRoles', 'role', 'cpdRoles', index)}
                          options={cpdRolesState}
                          getOptionLabel={(row) => row.label}
                          getOptionValue={(row) => row.id}
                        />
                      </Field>
                    </Col>
                    <Col size={{ lg: 1 / 3 }}>
                      <Field
                        id={`cpdRolePointsFieldId-${index}`}
                        label={t('page.organisation.createCourse.cpdPoints')}
                        required
                        value={cpd.points}
                      >
                        <input
                          id={`cpdRolePointsInputId-${index}`}
                          type="number"
                          placeholder={t('page.organisation.createCourse.cpdPointsPlaceholder')}
                          value={cpd.points ? cpd.points : null}
                          min={cpd.role ? cpd.role.minPoints : null}
                          max={cpd.role ? cpd.role.maxPoints : null}
                          onChange={(e) =>
                            handleCpdChangeCustom(
                              'cpdRoles',
                              'points',
                              'cpdRoles',
                              index,
                            )(e.target.value)
                          }
                          disabled={!cpd.role}
                        />
                      </Field>
                    </Col>
                    {cpd.role && (
                      <MaxMinContainer size={{ lg: 1 / 3 }}>
                        <MaxMinWrapper>
                          <MaxMinLabel>Minimum:</MaxMinLabel>
                          <MaxMinValue>{cpd.role.minPoints}</MaxMinValue>
                          <MaxMinSuffix>points</MaxMinSuffix>
                        </MaxMinWrapper>
                        <MaxMinWrapper>
                          <MaxMinLabel>Maximum:</MaxMinLabel>
                          <MaxMinValue>{cpd.role.maxPoints}</MaxMinValue>
                          <MaxMinSuffix>points</MaxMinSuffix>
                        </MaxMinWrapper>
                      </MaxMinContainer>
                    )}
                  </Margin>
                ))}
                {errors.cpdRoles ? <Error>{errors.cpdRoles}</Error> : null}
                {event.cpdRoles.length < cpdRoles.length && (
                  <Button
                    type="button"
                    onClick={() => {
                      dispatch(
                        updateFieldCreator({
                          name: 'cpdRoles',
                          value: [...event.cpdRoles, ''],
                        }),
                      );
                    }}
                    auto="xs"
                    text
                    primaryDark
                  >
                    {t('page.organisation.createCourse.addAnotherCpdRole')}
                  </Button>
                )}
              </Margin>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.ma.editEvent.tags')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field label={t('page.ma.editEvent.tagsLabel')} error={errors.tags}>
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {cloudTags.map((el) => (
                        <Col
                          key={`tag-cloud-${el.value}`}
                          size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}
                        >
                          <Margin bottom="8">
                            <Checkbox
                              onChange={handleChangeMultiCustom('tags', 'tags', el.value)}
                              selected={event.tags.includes(el.value)}
                            >
                              {el.label}
                            </Checkbox>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Row>
              <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
                <Margin bottom={{ xs: 12, md: 0 }}>
                  <Button type="button" onClick={submit}>
                    {t('global.saveButton')}
                  </Button>
                </Margin>
              </Col>
            </Row>
          </form>
        </Container>
      </PageContent>
    </>
  );
}

Edit.propTypes = {
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  deliveryModes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  cloudTags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,

  loadGlobals: PropTypes.func.isRequired,
  loadEvent: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,

  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  loadCpdRoles: PropTypes.func.isRequired,
  cpdRoles: PropTypes.arrayOf({}).isRequired,
};

const mapStateToProps = ({ globals, cpd }) => ({
  currencies: globals.currencies,
  countries: globals.countries,
  timezones: globals.timezones,
  languages: globals.languages,
  deliveryModes: globals.event.deliveryModes,
  cloudTags: globals.course.cloudTags,
  cpdRoles: cpd.cpdRoles,
});
const mapDispatchToProps = (dispatch) => ({
  loadGlobals: () => dispatch(loadGlobalEventAttributesCreator()),
  loadEvent: (payload, setEvent) => dispatch(loadMaEventCreator(payload, setEvent)),
  editEvent: (payload, id, history, setErrors) =>
    dispatch(editMaEventCreator(payload, id, history, setErrors)),
  loadCpdRoles: () => dispatch(loadCpdRolesCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
