import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components';
import { getAvatar } from 'helpers';
import { Wrapper, Thumbnail, Content } from './ThumbnailCard.styled';

const ThumbnailCard = ({ thumbnail, link, title }) => {
  return (
    <Wrapper to={link}>
      <Thumbnail>
        <img src={getAvatar(thumbnail)} alt="" />
      </Thumbnail>
      <Content>
        <Heading as="h3" size="h6">
          {title}
        </Heading>
      </Content>
    </Wrapper>
  );
};

ThumbnailCard.propTypes = {
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
};

ThumbnailCard.defaultProps = {
  thumbnail: '',
  title: '',
  link: '',
};

export default ThumbnailCard;
