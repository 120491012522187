import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import View from 'containers/app/MaList/View/View';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.ma',
    url: '/user/my-ma',
  },
];

const MyMa = ({ userMa, history }) => {
  if (userMa && userMa.id) {
    return <View breadcrumbs={breadcrumbs} history={history} />;
  }
  if (!userMa) {
    history.push('/ipma-associations');
  }

  return null;
};

MyMa.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
  userMa: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

MyMa.defaultProps = {
  history: {},
  userMa: {},
};

const mapStateToProps = ({
  user: {
    user: { memberAssociation },
  },
}) => ({
  userMa: memberAssociation,
});

export default connect(mapStateToProps, null)(MyMa);
