import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { mb, pl } from 'styled-components-spacing';

export const Steps = styled.ul`
  ${({ theme }) => css`
    line-height: 1.4;
    text-align: start;

    ${breakpoint('xl')`
        ${css`
          line-height: 1.6;
        `}
      `}

    * {
      word-break: break-word;
    }

    p {
      margin-bottom: 8px;
    }

    a {
      text-decoration: underline;
    }

    a:hover {
      color: #949799;
    }

    > li {
      ${pl(16)};
      ${mb(4)};

      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: ${theme.colors.secondary};
      }

      ${breakpoint('xl')`
        ${css`
          ${pl(20)};

          &::before {
            width: 12px;
            height: 12px;
          }
        `}
    `}
    }
  `}
`;

export const MyLibraryWrapper = styled.div`
  position: absolute;
  right: 1%;
  height: 50%;
  align-items: top;

  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1460px) {
    position: static;
    flex-direction: row;
    justify-content: center;
    height: unset;
  }

  @media (min-width: 1500px) {
    right: 2%;
  }

  @media (min-width: 1550px) {
    right: 2.5%;
  }

  @media (min-width: 1600px) {
    right: 3%;
  }

  @media (min-width: 1650px) {
    right: 3.5%;
  }
`;

export const MyLibraryLink = styled.a`
  cursor: pointer;

  width: 8rem;
  height: 4rem;

  font-size: 1.1em;
  text-decoration: none;
  color: #fff;
  flex: 0 0 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #7d7d7d;

  &:hover {
    background-color: #72737e;
  }

  @media (max-width: 1460px) {
    margin-bottom: 30px;
    flex: 0 0 8rem;
    height: 3rem;
  }
`;
