import styled from 'styled-components';
import { mr } from 'styled-components-spacing';

export const Sort = styled.span`
  ${mr(8)};

  display: inline-block;
  cursor: pointer;

  svg {
    width: 12px;
    height: 19px;
    fill: #fff;
  }
`;

export const SortContainer = styled.button`
  display: flex;
  align-items: center;
  color: #fff;
`;