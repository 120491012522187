import Ipma from 'containers/ipma/Ipma';
import Dashboard from 'containers/ipma/Dashboard/Dashboard';
import Profile from 'containers/ipma/Profile/Profile';
import View from 'containers/ipma/Profile/View/View';
import Edit from 'containers/ipma/Profile/Edit/Edit';
import Add from 'containers/ipma/Add/Add';
import People from 'containers/ipma/People/People';
import Users from 'containers/ipma/People/Users/Users';
import Create from 'containers/ipma/Events/Create/Create';
import EventEdit from 'containers/ipma/Events/Edit/Edit';
import Events from 'containers/ipma/Events/Events';
import Ongoing from 'containers/ipma/Events/Ongoing/Ongoing';
import Completed from 'containers/ipma/Events/Completed/Completed';
import Cancelled from 'containers/ipma/Events/Cancelled/Cancelled';
import Duplicated from 'containers/ipma/Events/Duplicated/Duplicated';
import IssueBadge from 'containers/ipma/IssueBadge/IssueBadge';
import RegOffice from 'containers/ipma/RegOffice/RegOffice';
import CoursesProgrammes from 'containers/ipma/RegOffice/CoursesProgrammes/CoursesProgrammes';
import Waiting from 'containers/ipma/RegOffice/CoursesProgrammes/Waiting/Waiting';
import Accepted from 'containers/ipma/RegOffice/CoursesProgrammes/Accepted/Accepted';
import Rejected from 'containers/ipma/RegOffice/CoursesProgrammes/Rejected/Rejected';
import RegTable from 'containers/ipma/RegOffice/RegTable/RegTable';
import Awards from 'containers/ipma/Awards/Awards';
import IssueAward from 'containers/ipma/IssueAward/IssueAward';
import Reports from 'containers/ipma/Reports/Reports';
import ReadReport from 'containers/ma/Reports/ReadReport/ReadReport';
import Projects from '../containers/ipma/Projects/Projects';
import ProjectsPlanning from '../containers/ipma/Projects/Planning/Planning';
import ProjectsRunning from '../containers/ipma/Projects/Running/Running';
import ProjectsCompleted from '../containers/ipma/Projects/Completed/Completed';
import ProjectsCreate from '../containers/ipma/Projects/Create/Create';
import ProjectsEdit from '../containers/ipma/Projects/Edit/Edit';
import ProjectRoles from '../containers/ipma/ProjectRoles/ProjectRoles';
import ProjectRolesOpen from '../containers/ipma/ProjectRoles/Open/Open';
import ProjectRolesClosed from '../containers/ipma/ProjectRoles/Closed/Closed';
import ProjectRolesArchived from '../containers/ipma/ProjectRoles/Archived/Archived';
import ProjectRolesCreate from '../containers/ipma/ProjectRoles/Create/Create';
import ProjectRolesEdit from '../containers/ipma/ProjectRoles/Edit/Edit';
import Applications from '../containers/ipma/Applications/Applications';
import ApplicationsWaiting from '../containers/ipma/Applications/Waiting/Waiting';
import ApplicationsAccepted from '../containers/ipma/Applications/Accepted/Accepted';
import ApplicationsRejected from '../containers/ipma/Applications/Rejected/Rejected';
import ApplicationsAutoRejected from '../containers/ipma/Applications/AutoRejected/AutoRejected';
import ApplicationsOnHold from '../containers/ipma/Applications/OnHold/OnHold';

const IPMA = 'ipmaBase';
const DASHBOARD = 'ipmaDashboard';
const PROFILE = 'ipmaProfile';
const PROFILE_EDIT = 'ipmaProfilEdit';
const PROFILE_VIEW = 'ipmaProfileView';
const PEOPLE = 'ipmaPeople';
const USERS_LIST = 'ipmaUsersList';
const PEOPLE_ADD = 'ipmaPeopleAdd';
const EVENTS = 'ipmaEvents';
const EVENTS_ONGOING = 'ipmaEventsOngoing';
const EVENTS_COMPLETED = 'ipmaEventsCompleted';
const EVENTS_CANCELLED = 'ipmaEventsCancelled';
const EVENTS_DUPLICATED = 'ipmaEventsDuplicated';
const EVENTS_CREATE = 'ipmaEventsCreate';
const EVENTS_EDIT = 'ipmaEventsEdit';
const EVENTS_ISSUE_BADGE = 'ipmaEventsIssueBadge';
const REG_OFFICE = 'ipmaRegOffice';
const REG_OFFICE_COURSES_PROGRAMMES = 'ipmaRegOfficeCoursesProgrammes';
const REG_OFFICE_COURSES_PROGRAMMES_WAITING = 'ipmaRegOfficeCoursesProgrammesWaiting';
const REG_OFFICE_COURSES_PROGRAMMES_ACCEPTED = 'ipmaRegOfficeCoursesProgrammesAccepted';
const REG_OFFICE_COURSES_PROGRAMMES_REJECTED = 'ipmaRegOfficeCoursesProgrammesRejected';
const REG_OFFICE_REG_TABLE = 'ipmaRegOfficeRegTable';
const AWARDS = 'ipmaAwards';
const ISSUE_AWARDS = 'ipmaIssueAward';
const REPORTS = 'ipmaReports';
const READ_REPORT = 'ipmaReadReport';
const PROJECTS = 'ipmaProjects';
const PROJECTS_PLANNING = 'ipmaProjectsPlaning';
const PROJECTS_RUNNING = 'ipmaProjectsRunning';
const PROJECTS_COMPLETED = 'ipmaProjectsCompleted';
const PROJECTS_CREATE = 'ipmaProjectsCreate';
const PROJECTS_EDIT = 'ipmaProjectsEdit';
const PROJECT_ROLES = 'ipmaProjectRoles';
const PROJECT_ROLES_OPEN = 'ipmaProjectRolesOpen';
const PROJECT_ROLES_CLOSED = 'ipmaProjectRolesClosed';
const PROJECT_ROLES_ARCHIVED = 'ipmaProjectRolesArchived';
const PROJECT_ROLES_CREATE = 'ipmaProjectRolesCreate';
const PROJECT_ROLES_EDIT = 'ipmaProjectRolesEdit';
const APPLICATIONS = 'ipmaApplications';
const APPLICATIONS_WAITING = 'ipmaApplicationsWaiting';
const APPLICATIONS_ACCEPTED = 'ipmaApplicationsAccepted';
const APPLICATIONS_REJECTED = 'ipmaApplicationsRejected';
const APPLICATIONS_AUTO_REJECTED = 'ipmaApplicationsAutoRejected';
const APPLICATIONS_ON_HOLD = 'ipmaApplicationsOnHold';

export default [
  {
    key: IPMA,
    path: '/ipma',
    component: Ipma,
    exact: false,
    withAuth: true,
    permissions: {
      appType: 'ipma',
    },
    routes: [
      {
        key: DASHBOARD,
        path: '/ipma',
        component: Dashboard,
        exact: true,
        withAuth: false,
      },
      {
        key: PROFILE,
        path: '/ipma/profile',
        component: Profile,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: PROFILE_VIEW,
            path: '/ipma/profile/view',
            component: View,
            exact: false,
            withAuth: false,
          },
          {
            key: PROFILE_EDIT,
            path: '/ipma/profile/edit',
            component: Edit,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['ipma_edit_profile'],
            },
          },
        ],
      },
      {
        key: PEOPLE_ADD,
        path: '/ipma/people/add',
        component: Add,
        exact: false,
        withAuth: false,
      },
      {
        key: PEOPLE,
        path: '/ipma/people',
        component: People,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: USERS_LIST,
            path: '/ipma/people/users',
            component: Users,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['ipma_manage_ipma_user_list'],
            },
          },
        ],
      },
      {
        key: EVENTS,
        path: '/ipma/events',
        component: Events,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: EVENTS_ONGOING,
            path: '/ipma/events/ongoing',
            component: Ongoing,
            exact: false,
            withAuth: false,
          },
          {
            key: EVENTS_COMPLETED,
            path: '/ipma/events/completed',
            component: Completed,
            exact: false,
            withAuth: false,
          },
          {
            key: EVENTS_CANCELLED,
            path: '/ipma/events/cancelled',
            component: Cancelled,
            exact: false,
            withAuth: false,
          },
          {
            key: EVENTS_DUPLICATED,
            path: '/ipma/events/duplicated',
            component: Duplicated,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: EVENTS_CREATE,
        path: '/ipma/event/create/:id',
        component: Create,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_events_create_event'],
        },
      },
      {
        key: EVENTS_CREATE,
        path: '/ipma/event/create',
        component: Create,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_events_create_event'],
        },
      },
      {
        key: EVENTS_EDIT,
        path: '/ipma/event/:id/edit',
        component: EventEdit,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_events_edit_event'],
        },
      },
      {
        key: EVENTS_ISSUE_BADGE,
        path: '/ipma/event/:id/issue-badge',
        component: IssueBadge,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_events_issue_badge_event_list'],
        },
      },
      {
        key: REG_OFFICE,
        path: '/ipma/reg-office-hub',
        component: RegOffice,
        exact: false,
        withAuth: false,
      },
      {
        key: REG_OFFICE_COURSES_PROGRAMMES,
        path: '/ipma/reg-office/courses-programmes',
        component: CoursesProgrammes,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_manage_course'],
        },
        routes: [
          {
            key: REG_OFFICE_COURSES_PROGRAMMES_WAITING,
            path: '/ipma/reg-office/courses-programmes/waiting',
            component: Waiting,
            exact: false,
            withAuth: false,
          },
          {
            key: REG_OFFICE_COURSES_PROGRAMMES_ACCEPTED,
            path: '/ipma/reg-office/courses-programmes/accepted',
            component: Accepted,
            exact: false,
            withAuth: false,
          },
          {
            key: REG_OFFICE_COURSES_PROGRAMMES_REJECTED,
            path: '/ipma/reg-office/courses-programmes/rejected',
            component: Rejected,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: REG_OFFICE_REG_TABLE,
        path: '/ipma/reg-office-table',
        component: RegTable,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_manage_course'],
        },
      },
      {
        key: AWARDS,
        path: '/ipma/awards',
        component: Awards,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_award_issue_badge_award_list'],
        },
      },
      {
        key: ISSUE_AWARDS,
        path: '/ipma/award/:id/issue-badge',
        component: IssueAward,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_award_issue_badge_award_list'],
        },
      },
      {
        key: REPORTS,
        path: '/ipma/reports',
        component: Reports,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_ma_members_report'],
        },
      },
      {
        key: READ_REPORT,
        path: '/ipma/read-report/:id',
        component: ReadReport,
        exact: false,
        withAuth: false,
      },

      {
        key: PROJECTS,
        path: '/ipma/projects',
        component: Projects,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ipma_project_manage_project'],
        },
        routes: [
          {
            key: PROJECTS_PLANNING,
            path: '/ipma/projects/planning',
            component: ProjectsPlanning,
            exact: false,
            withAuth: false,
          },
          {
            key: PROJECTS_RUNNING,
            path: '/ipma/projects/running',
            component: ProjectsRunning,
            exact: false,
            withAuth: false,
          },
          {
            key: PROJECTS_COMPLETED,
            path: '/ipma/projects/completed',
            component: ProjectsCompleted,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: PROJECTS_CREATE,
        path: '/ipma/project/create',
        component: ProjectsCreate,
        exact: false,
        withAuth: false,
      },
      {
        key: PROJECTS_EDIT,
        path: '/ipma/project/:id/edit',
        component: ProjectsEdit,
        exact: false,
        withAuth: false,
      },
      {
        key: PROJECT_ROLES,
        path: '/ipma/project-roles/:id',
        component: ProjectRoles,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: PROJECT_ROLES_OPEN,
            path: '/ipma/project-roles/:id/open',
            component: ProjectRolesOpen,
            exact: false,
            withAuth: false,
          },
          {
            key: PROJECT_ROLES_CLOSED,
            path: '/ipma/project-roles/:id/closed',
            component: ProjectRolesClosed,
            exact: false,
            withAuth: false,
          },
          {
            key: PROJECT_ROLES_ARCHIVED,
            path: '/ipma/project-roles/:id/archived',
            component: ProjectRolesArchived,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: PROJECT_ROLES_CREATE,
        path: '/ipma/project-role/:id/create',
        component: ProjectRolesCreate,
        exact: false,
        withAuth: false,
      },
      {
        key: PROJECT_ROLES_EDIT,
        path: '/ipma/project-role/:id/edit/:roleId',
        component: ProjectRolesEdit,
        exact: false,
        withAuth: false,
      },
      {
        key: APPLICATIONS,
        path: '/ipma/applications/:id',
        component: Applications,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: APPLICATIONS_WAITING,
            path: '/ipma/applications/:id/waiting',
            component: ApplicationsWaiting,
            exact: false,
            withAuth: false,
          },
          {
            key: APPLICATIONS_ACCEPTED,
            path: '/ipma/applications/:id/accepted',
            component: ApplicationsAccepted,
            exact: false,
            withAuth: false,
          },
          {
            key: APPLICATIONS_REJECTED,
            path: '/ipma/applications/:id/rejected',
            component: ApplicationsRejected,
            exact: false,
            withAuth: false,
          },
          {
            key: APPLICATIONS_AUTO_REJECTED,
            path: '/ipma/applications/:id/auto-rejected',
            component: ApplicationsAutoRejected,
            exact: false,
            withAuth: false,
          },
          {
            key: APPLICATIONS_ON_HOLD,
            path: '/ipma/applications/:id/on-hold',
            component: ApplicationsOnHold,
            exact: false,
            withAuth: false,
          },
        ],
      },
    ],
  },
];
