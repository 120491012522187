import styled, { css } from 'styled-components';
import { mb, ml, mr } from 'styled-components-spacing';

export const Wrapper = styled.ul`
  ${mb(24)};
`;

export const Item = styled.li`
  ${({ theme }) =>
    theme &&
    css`
      ${mr(8)};

      display: inline;
      color: #949799;
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.semibold};
      line-height: 16px;
      
      &::after {
        ${ml(8)};

        content: '›';
        font-size: ${theme.font.size.pixel('16')};
      }
      
      &:last-child {
        &::after {
          content: none;
        }
      }
    `
  }
`;