import styled, { css } from 'styled-components';
import Row from 'components';

export const Wrapper = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  visibility: visible;
  height: 90px;
  justify-content: center;
  align-items: center;
  
  input {
    background: transparent;
    border: none;
    padding: 0px;
  }
`;

export const StyledRow = styled.div`
  padding: 0px;
    border: solid 1px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    width: 600px
`;
