import { connect } from 'react-redux';
import { checkPermissions, globalPropTypes } from 'helpers';
import PropTypes from 'prop-types';

const PrivateComponent = ({ permissions, children, userPermissions }) => {
  if (!checkPermissions(permissions, userPermissions)) return null;

  return children;
};

PrivateComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

PrivateComponent.defaultProps = {
  userPermissions: {},
};

const mapStateToProps = ({ user }) => ({
  userPermissions: user.userPermissions,
});

export default connect(mapStateToProps, null)(PrivateComponent);
