import { takeLatest, put, call, cancelled, select } from 'redux-saga/effects';
import * as searchService from 'api/services/search';
import {
  setNewLoadingCreator,
  removeLoadingCreator,
  setSearchTotalsCreator,
  setSearchPagesCreator,
  setSearchCreator,
} from 'store/actionsCreators';
import { LOAD_SEARCH, LOAD_SEARCH_TOTALS } from 'store/constants';
import { get, map } from 'lodash';
import { parseBoxesList, parseParams, parsePlace } from 'helpers';

function* loadSearchTotals({ payload: searchValue }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(searchService.getSearchTotals, { q: searchValue || '' });
    if (response.ok) {
      const {
        results: {
          result: { entity: items },
        },
      } = yield response.json();

      const totals = {
        users: get(items, 'users.totalItems') || 0,
        courses: get(items, 'courses.totalItems') || 0,
        events: get(items, 'events.totalItems') || 0,
        ma: get(items, 'member-associations.totalItems') || 0,
        organisations: get(items, 'organizations.totalItems') || 0,
        yc: get(items, 'young-crew.totalItems') || 0,
      };

      yield put(setSearchTotalsCreator(totals));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] search total error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function loadSearch(view) {
  switch (view.listType) {
    case 'users':
      return loadSearchUsers(view);
    case 'events':
      return loadSearchList(view, 'events', 'LOAD_SEARCH_EVENTS');
    case 'courses':
      return loadSearchList(view, 'courses', 'LOAD_SEARCH_COURSES');
    case 'organisations':
      return loadSearchList(view, 'organisations', 'LOAD_SEARCH_ORGANISATIONS', 'organizations');
    case 'ma':
      return loadSearchList(view, 'ma', 'LOAD_SEARCH_MA', 'member-associations');
    case 'yc':
      return loadSearchList(view, 'yc', 'LOAD_SEARCH_YC', 'young-crew');
    default:
      return null;
  }
}

function* loadSearchUsers({ payload: searchValue }) {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_SEARCH_USERS' }));

    const [page, perPage] = yield select(({ search: { users } }) => [users.page, users.perPage]);

    const params = parseParams({
      q: searchValue || '',
      maxResults: perPage,
      page,
    });

    const response = yield call(searchService.getSearch, 'users', params);
    if (response.ok) {
      const {
        results: {
          result: {
            entity: { items, pageCount },
          },
        },
      } = yield response.json();

      const data = map(items, (n) => ({
        name: [n.firstName || '', n.lastName].filter((el) => !!el).join(' '),
        place: parsePlace(get(n, 'address.city') || '', get(n, 'address.country.name') || ''),
        url: `/user-profile/${n.uniqueUrl || null}`,
        thumbnail: get(n, 'userProfile.photo') || '',
        job: [get(n, 'userProfile.jobTitle.name') || '', get(n, 'userProfile.company') || '']
          .filter((el) => !!el)
          .join(', '),
      }));

      yield put(setSearchCreator('users', data));
      yield put(setSearchPagesCreator('users', pageCount || 1));
    }

    yield put(removeLoadingCreator({ type: 'LOAD_SEARCH_USERS' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_SEARCH_USERS' }));
    console.log('[GET] search users error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'LOAD_SEARCH_USERS' }));
  }
}

function* loadSearchList({ payload: searchValue }, type, loading, apiName = type) {
  try {
    yield put(setNewLoadingCreator({ type: loading }));

    const [page, perPage] = yield select(({ search }) => [search[type].page, search[type].perPage]);

    const params = parseParams({
      q: searchValue || '',
      maxResults: perPage,
      page,
    });

    const response = yield call(searchService.getSearch, apiName, params);
    if (response.ok) {
      const {
        results: {
          result: {
            entity: { items, pageCount },
          },
        },
      } = yield response.json();

      const data = parseBoxesList(type, items, true);
      yield put(setSearchCreator(type, data));
      yield put(setSearchPagesCreator(type, pageCount || 1));
    }

    yield put(removeLoadingCreator({ type: loading }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: loading }));
    console.log(`[GET] search ${type} error: `, error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: loading }));
  }
}

export default function* watchSearch() {
  yield takeLatest(LOAD_SEARCH_TOTALS, loadSearchTotals);
  yield takeLatest(LOAD_SEARCH, loadSearch);
}
