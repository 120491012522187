import styled, { css } from 'styled-components';
import { p, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

import { Button } from 'components/';

export default styled.div`
  text-align: center;
  ${px(16)};
  ${py(24)};

  ${breakpoint('md')`
    ${css`
      ${p(42)};
    `}
  `}

  p {
    text-align: left;
    ${({ theme }) =>
      theme &&
      css`
        font-size: ${theme.font.size.pixel('16')};
        line-height: 24px;
        color: ${theme.colors.gray[500]};
      `}
  }
`;

export const Action = styled(Button)`
  ${py(12)};
`;
