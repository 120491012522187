import styled, { css } from 'styled-components';
import { ButtonAction } from 'components';
import { ReactSVG } from 'react-svg';

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      position: relative;
      height: 100%;
      background-color: rgba(254, 149, 5, 1);
      display: flex;
      justify-content: space-between;
      border-radius: 33px;
      width: 85px;
      height: 42px;

      ${({ type }) =>
        (type === 'organisation' || type === 'ma' || type === 'ipma' || type === 'yc') &&
        css`
          background-color: ${type === 'organisation'
            ? 'rgba(46, 107, 143, 1)'
            : type === 'ma'
            ? 'rgba(80, 47, 104, 1)'
            : type === 'yc'
            ? '#8d7Ca2'
            : 'rgba(43, 102, 90, 1)'};
        `}

      ${({ open }) =>
        open &&
        css`
          ${Content} {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            visibility: visible;
          }

          ${Arrow} {
            svg {
              transform-origin: center;
              transform: rotate(180deg);
            }
          }
        `}

        ${({ open, type }) =>
          open &&
          css`
            box-shadow: ${open ? 'inset 0px 4px 4px rgba(0, 0, 0, 0.25);' : 'none'};
            background-color: ${type === 'organisation'
              ? 'rgba(75, 135, 170, 1)'
              : type === 'ma'
              ? 'rgba(123, 87, 150, 1)'
              : type === 'ipma'
              ? 'rgba(75, 142, 129, 1)'
              : type === 'yc'
              ? '#8d7Ca2'
              : 'rgba(254, 149, 5, 1)'};
          `}


    `}
`;

export const OuterWrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      position: relative;
      height: 100%;
      display: flex;
      width: 250px;
      height: 42px;
    `}
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 6px;
`;

export const ButtonContent = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Avatar = styled.div`
  flex: 0 0 36px;
  width: 32px;
  height: 32px;
  border: none;

  ${({ type, open }) =>
    css`
      border: 1px solid ${open ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.2)'};
    `}

  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const DefaultAvatar = styled(ReactSVG)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
`;

export const EmptyAvatar = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 32px;
  width: 32px;
  height: 32px;

  background-size: auto;

  ${DefaultAvatar} {
    path {
      fill: ${({ open }) => (open ? 'rgba(0, 0, 0, 0.4)' : 'white')};
    }
  }
`;

export const Info = styled.span`
  flex: 1;
  padding: 0 8px;
  text-align: left;
  font-size: ${({ theme }) => theme.font.size.pixel('16')};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: #ededed;
  word-break: break-word;

  span {
    display: block;
    font-size: ${({ theme }) => theme.font.size.pixel('12')};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  &::after {
    content: '';
  }

  ${({ small }) =>
    small &&
    css`
      font-size: ${({ theme }) => theme.font.size.pixel('12')};
    `}
`;

export const Arrow = styled.span`
  align-self: flex-end;
  margin-bottom: 4px;
  padding-left: 8px;

  svg {
    width: 10px;
    height: 6px;
    fill: #f8f8f8;
    pointer-events: none;
  }
`;

export const Notification = styled(ButtonAction)`
  margin: 0 5px 0 0;
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: column;
  width: 40px;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  color: black;

  ${({ title }) =>
    title &&
    css`
      margin-top: 30px;
    `}

  title {
    display: none;
  }

  span {
    gap: 10px;
    color: black;
    position: relative;
    top: -40px;
    right: -10px;
    color: white;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }

  ${({ title }) =>
    title &&
    css`
      span {
        width: 27px;
        height: 14px;
        padding: 3px 1px 3px 1px;
        border-radius: 7px;
        color: black;
        position: relative;
        top: -35px;
        right: -9px;
        background: red;
        color: white;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 5px;
      }
    `}

  svg {
    width: 20px;
    height: 21px;
    fill: black;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 80px;
  left: -200px;
  width: 288px;
  transform: translate3d(0, 16px, 0);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.2s ease, opacity 0.2s ease;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(79, 78, 92, 0.32);
  z-index: ${({ theme }) => theme.zindex.menu};
`;

export const Top = styled.div`
  margin-bottom: 20px;
  margin-top: 24px;
  padding: 0 ${({ theme }) => theme.grid.gutter / 2 + 'px'};
`;

export const Lines = styled.div`
  ${({ open, type }) =>
    css`
      width: 19px;
      height: 6px;
      top: 50%;
      content: '';
      position: absolute;
      left: 50px;
      width: 19px;
      height: 2px;
      border-radius: 33px;
      background: ${(type === 'organisation') & open
        ? '#05273C'
        : (type === 'ma') & open
        ? '#221030'
        : (type === 'yc') & open
        ? '#0C2E27'
        : (type === 'user') & open
        ? '#6D4917'
        : (type === 'ipma') & open
        ? '#0C2E27'
        : '#ffffff'};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 33px;
        background: ${(type === 'organisation') & open
          ? '#05273C'
          : (type === 'ma') & open
          ? '#221030'
          : (type === 'yc') & open
          ? '#0C2E27'
          : (type === 'user') & open
          ? '#6D4917'
          : (type === 'ipma') & open
          ? '#0C2E27'
          : '#ffffff'};
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 33px;
        background: ${(type === 'organisation') & open
          ? '#05273C'
          : (type === 'ma') & open
          ? '#221030'
          : (type === 'yc') & open
          ? '#0C2E27'
          : (type === 'user') & open
          ? '#6D4917'
          : (type === 'ipma') & open
          ? '#0C2E27'
          : '#ffffff'};
      }

      &::before {
        top: -9px;
      }

      &::after {
        bottom: -9px;
      }

      &::nth-child(2) {
        top: 50%;
      }
    `}
`;

export const LoggedUser = styled.div`
  margin-bottom: 20px;
  margin-top: 24px;
  .user {
    color: black;
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
`;
