export const initialState = {
  course: {
    name: '',
  },
  fields: {
    startDate: '',
    endDate: '',
    city: '',
    postCode: '',
    country: '',
    line1: '',
    line2: '',
    timezone: '',
    doesntApply: false,
    onDemand: false,
  },
  errors: {},
};

const SET_COURSE = 'SET_COURSE';
const SET_FIELD = 'SET_FIELD';
const SET_FIELDS = 'SET_FIELDS';
const SET_ERRORS = 'SET_ERRORS';

export const setCourseCreator = (payload) => ({ type: SET_COURSE, payload });
export const setFieldCreator = (payload) => ({ type: SET_FIELD, payload });
export const setFieldsCreator = (payload) => ({ type: SET_FIELDS, payload });
export const setErrorsCreator = (payload) => ({ type: SET_ERRORS, payload });

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE:
      return {
        ...state,
        course: action.payload,
      };
    case SET_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
      };
    case SET_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload,
        },
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};
