import styled, { css } from 'styled-components';

export const Wrapper = styled.ul`
  ${({ horizontal }) => horizontal && css`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-around;
  `}
`;

export const Item = styled.li`
  ${({ theme }) => theme && css`
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `}

  ${({ horizontal }) => horizontal && css`
    flex-grow: 1;
    margin-bottom: 0;
    height: 100%;
  `}
`;



export const ItemLink = styled.a`
  ${({ theme }) => theme && css`
    color: ${theme.colors.tertiary};
    font-size: ${theme.font.size.pixel('13')};
    font-weight: ${theme.font.weight.medium};
    text-transform: uppercase;
    letter-spacing: 1.4px;

    ${({ disabled }) => disabled && css`
      opacity: 0.5;
      pointer-events: none;
    `}

    ${({ horizontal }) => horizontal && css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 18px;
      height: 100%;

      &.active {
        background-color: ${theme.colors.secondary};
        color: #fff;
      }

      ${({ active }) => active && css`

      `}

      ${({ light }) => light && css`
        color: #fff;

        &.active {
          background-color: #72737e;
        }
      `}
    `}

    ${({ smallfont }) => smallfont && css`
      font-size: ${theme.font.size.pixel('12')};
      padding: 12px 14px;
    `}
  `}
`;
