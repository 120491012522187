const yearFields = {
  // previousYearReport: '',
  actualYearReport: '',
  forecastYearReport: '',
};

export const yearFieldsKeys = Object.keys(yearFields);

export const defaultState = {
  membership: {
    individualMembersF: yearFields,
    individualMembersM: yearFields,
    individualMembersStudentsF: yearFields,
    individualMembersStudentsM: yearFields,
    yCMembersF: yearFields,
    yCMembersM: yearFields,
    corporateMembers: yearFields,
    inTotal: yearFields,
    membershipNotes: { notes: '' },
  },
  events: {
    eventsNumber: yearFields,
    eventsTotalParticipants: yearFields,
    eventsNotes: { notes: '' },
  },
  education: {
    trainingsProvided: yearFields,
    regPartners: yearFields,
    educationNotes: { notes: '' },
  },
  // financialHealth: {
  //   financialResult: yearFields,
  //   financialNotes: { notes: '' },
  // },
};

const SET_EDIT_DATA = 'SET_EDIT_DATA';
const UPDATE_FIELD = 'UPDATE_FIELD';

export const setEditDataCreator = (payload) => ({ type: SET_EDIT_DATA, payload });
export const updateFieldCreator = (payload) => ({ type: UPDATE_FIELD, payload });

export default function (state = defaultState, action) {
  if (action.type === SET_EDIT_DATA) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === UPDATE_FIELD) {
    return { ...state, [action.payload.name]: action.payload.value };
  }

  return state;
}
