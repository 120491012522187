import PropTypes from 'prop-types';
import { useSubRoutes } from 'hooks';
import { globalPropTypes } from 'helpers';

const Yc = ({ routes }) => {
  const [renderSubRoutes] = useSubRoutes(routes);

  return renderSubRoutes();
};

Yc.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
};

Yc.defaultProps = {
  routes: [],
};

export default Yc;
