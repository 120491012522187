import { flatMap, isEmpty } from 'lodash';

const getFlatRoutes = (routes) => {
  if (!isEmpty(routes)) {
    return flatMap(routes, (n) => [n, ...getFlatRoutes(n.routes)]);
  }

  return [];
};

export default getFlatRoutes;
