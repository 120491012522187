import styled, { css } from 'styled-components';
import { pt, pb, px } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const StyledTag = styled.button`
  ${({ theme, active }) => css`
    color: ${active ? '#fff' : theme.colors.secondaries.darker};
    font-weight: 700;
    padding: 10px;
    border: 2px solid ${theme.colors.secondaries.darker};
    border-radius: 30px;
    background-color: ${active ? theme.colors.secondaries.darker : '#fff'};
    transition: all 0.2s;
    ${!active &&
    css`
      &:hover {
        color: #fff;
        border-color: ${theme.colors.secondaries.default};
        background-color: ${theme.colors.secondaries.default};
      }
    `}
  `}
`;
