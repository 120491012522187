import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserStep from 'containers/auth/Register/steps/UserStep';
import ExtendedRegistrationStep from 'containers/auth/Register/steps/ExtendedRegistrationStep';
import SummaryStep from 'containers/auth/Register/steps/SummaryStep';

function Register() {
  return (
    <div>
      <Switch>
        <Route exact path="/auth/register" component={UserStep} />
        <Route
          path="/auth/register/ExtendedRegistrationStep"
          component={ExtendedRegistrationStep}
        />
        <Route path="/auth/register/summary" component={SummaryStep} />
      </Switch>
    </div>
  );
}

export default Register;
