import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BoxList, FiltersControls, Pagination, Spinner, PrivateComponent } from 'components';
import {
  duplicateIpmaEventCreator,
  loadIpmaEventsCreator,
  setIpmaEventsPageCreator,
  setIpmaEventsPerPageCreator,
} from 'store/actionsCreators';
import { isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';

const Completed = ({
  events,
  page,
  pages,
  perPage,
  loading,
  loadEvents,
  setPage,
  setPerPage,
  duplicateEvent,
  history,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadEvents({ status: 'completed' });
  }, [perPage, page, loadEvents]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="event"
            items={events}
            alert={t('global.noResultsFound')}
            customRenderActions={({ item, ButtonAction }) => (
              <>
                <PrivateComponent
                  permissions={{
                    roles: ['ipma_events_create_event'],
                  }}
                >
                  <ButtonAction
                    icon="duplicate"
                    title={t('global.duplicateButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => duplicateEvent(item.id)}
                  />
                </PrivateComponent>

                <PrivateComponent
                  permissions={{
                    roles: ['ipma_events_issue_badge_event_list'],
                  }}
                >
                  <ButtonAction
                    icon="award"
                    title={t('global.issueBadges')}
                    verticalviewbreakpoint="xl"
                    onClick={() => history.push(`/ipma/event/${item.id}/issue-badge`)}
                  />
                </PrivateComponent>
              </>
            )}
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </>
      ) : null}
    </>
  );
};

Completed.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadEvents: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  duplicateEvent: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

const mapStateToProps = ({ ipma, loading }) => ({
  events: ipma.events.list.items,
  page: ipma.events.list.page,
  pages: ipma.events.list.pages,
  perPage: ipma.events.list.perPage,
  loading: isLoading(loading, 'IPMA'),
});
const mapDispatchToProps = (dispatch) => ({
  loadEvents: (payload) => dispatch(loadIpmaEventsCreator(payload)),
  setPage: (payload) => dispatch(setIpmaEventsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setIpmaEventsPerPageCreator(payload)),
  duplicateEvent: (payload) => dispatch(duplicateIpmaEventCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Completed);
