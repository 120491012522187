export default (avatar, type) => {
  if (typeof avatar === 'string' && avatar) {
    return avatar;
  }

  if (type === 'profile') {
    return `${process.env.PUBLIC_URL}/avatars/defaultAvatar.jpg`;
  }

  return `${process.env.PUBLIC_URL}/avatars/defaultQuestionMark.jpg`;
};
