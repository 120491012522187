import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Checkbox,
  Button,
  Header,
  PageContent,
  Table,
  Spinner,
} from 'components';
import AddCertificatesFiltrationForm from 'components/AddCertificatesFiltrationForm/AddCertificatesFiltrationForm';
import { Margin } from 'styled-components-spacing';

import {
  setUserAddListSelectedCertificateCreator,
  addUserAddListCertificatesCreator,
} from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.certification',
    url: '/user/certification',
  },
  {
    title: 'navigation.breadcrumbs.user.addCertification',
    url: '/user/certification/add',
  },
];

const CertificationAdd = ({
  certificates,
  loading,
  selectedCertificates,
  setSelectedCertificate,
  addCertificates,
  history,
}) => {
  const { t } = useTranslation();
  const submit = () => {
    addCertificates(history);
  };

  const handleCheck = (id) => (value) => {
    if (!value) {
      setSelectedCertificate(
        selectedCertificates.filter((selectedCertId) => selectedCertId !== id),
      );
    } else {
      setSelectedCertificate([...selectedCertificates, id]);
    }
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.user.certification.title')} />
      <PageContent>
        <Container>
          <Margin as="p" top={{ xs: 20 }}>
            {t('page.user.certification.description')}
          </Margin>
          <Margin as="p" top={{ xs: 16 }}>
            <strong>{t('page.user.certification.descriptionExtra')}</strong>
          </Margin>
          <Margin top="16" bottom="32">
            <AddCertificatesFiltrationForm asUser />
          </Margin>
          <form>
            <Margin vertical="24">
              <Table tablebreakpoint="lg">
                <Table.Head>
                  <Table.Row>
                    <Table.Heading />
                    <Table.Heading>{t('certificates.ownerName')}</Table.Heading>
                    <Table.Heading>{t('certificates.certificate')}</Table.Heading>
                    <Table.Heading>{t('certificates.certificateNumber')}</Table.Heading>
                    <Table.Heading>{t('certificates.awardedDate')}</Table.Heading>
                    <Table.Heading>{t('certificates.validityDate')}</Table.Heading>
                    <Table.Heading>{t('certificates.ma')}</Table.Heading>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={7}>
                        <Spinner />
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && !certificates.length ? (
                    <Table.Row>
                      <Table.Cell colSpan={7}>{t('global.noResultsFound')}</Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && certificates.length
                    ? certificates.map((cert) => {
                        return (
                          <Table.Row key={cert.id}>
                            <Table.Cell>
                              <Checkbox
                                selected={selectedCertificates.includes(cert.id)}
                                onChange={handleCheck(cert.id)}
                              />
                            </Table.Cell>
                            <Table.Cell>{`${cert.first_name} ${cert.last_name}`}</Table.Cell>
                            <Table.Cell>{cert.designation}</Table.Cell>
                            <Table.Cell>{cert.cert_id}</Table.Cell>
                            <Table.Cell>{cert.issue_date}</Table.Cell>
                            <Table.Cell>{cert.valid_until}</Table.Cell>
                            <Table.Cell>{cert.clientCountry}</Table.Cell>
                          </Table.Row>
                        );
                      })
                    : null}
                </Table.Body>
              </Table>
            </Margin>
            <Row>
              <Col size={{ xs: 1 / 1, md: 4 / 12, lg: 3 / 12 }}>
                <Button type="button" onClick={submit} primary>
                  {t('global.apply')}
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
      </PageContent>
    </>
  );
};

CertificationAdd.propTypes = {
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      last_name: PropTypes.string,
      designation: PropTypes.string,
      cert_id: PropTypes.string,
      valid_until: PropTypes.string,
      clientCountry: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedCertificates: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedCertificate: PropTypes.func.isRequired,
  addCertificates: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ user, loading }) => ({
  certificates: user.add.data,
  loading: !!loading.filter((item) => item.type === 'ADD_USER_CERTS').length,
  selectedCertificates: user.add.selectedCertificates,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedCertificate: (payload) => dispatch(setUserAddListSelectedCertificateCreator(payload)),
  addCertificates: (history) => dispatch(addUserAddListCertificatesCreator(history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationAdd);
