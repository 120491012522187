import { takeLatest, put, all, call, cancelled } from 'redux-saga/effects';
import * as pageService from 'api/services/page';
import * as listService from 'api/services/list';
import {
  setNewLoadingCreator,
  removeLoadingCreator,
  setPageHomeCreator,
  setPageUserProfileCreator,
  loadPageUserProfileBadgesCreator,
  setPageUserProfileBadgesCreator,
} from 'store/actionsCreators';
import {
  LOAD_PAGE_HOME,
  LOAD_PAGE_USER_PROFILE,
  LOAD_PAGE_USER_PROFILE_BADGES,
  SUBMIT_PAGE_CONTACT_FORM,
} from 'store/constants';
import { get, has, map } from 'lodash';
import { parseBoxesList, parseDate } from 'helpers';
import i18next from 'i18next';
import moment from 'moment';

function* loadPageHome() {
  function* parseResponse(response) {
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      if (has(result, 'entity.items')) {
        return result.entity.items;
      }

      if (has(result, 'entity')) {
        return result.entity;
      }

      return result.items;
    }

    return [];
  }

  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const params = {
      maxResult: 3,
      page: 1,
      courseGeneralStatus: 'courseState-upcoming',
    };

    const [courses, events, organisations, ma] = yield all([
      call(listService.getListCourses, { ...params, 'sort-by': 'createdDate.asc' }),
      call(listService.getListEvents, {
        ...params,
        dateFrom: new Date(),
        'sort-by': 'dateFrom.asc',
      }),
      call(listService.getListOrganisations, {
        ...params,
        'sort-by': 'createdDate.desc',
        maxResult: 8,
      }),
      call(pageService.getHomeMaList),
    ]);

    const obj = {
      courses: parseBoxesList('courses', yield parseResponse(courses)),
      events: parseBoxesList('events', yield parseResponse(events)),
      ma: parseBoxesList('ma', yield parseResponse(ma)),
      organisations: parseBoxesList('organisations', yield parseResponse(organisations)),
    };

    yield put(setPageHomeCreator(obj));

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] load page home error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadPageUserProfile({ payload: id, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(pageService.getPageUserProfile, id);

    if (response.ok) {
      const {
        results: {
          result: { entity: user },
        },
      } = yield response.json();

      const data = {
        name: [user.firstName || '', user.lastName].filter((el) => !!el).join(' '),
        thumbnail: get(user, 'userProfile.photo') || '',
        phone: get(user, 'userProfile.telephone') || '',
        email: user.email || '',
        shortBio: get(user, 'userProfile.shortBio') || '',
        facebook: get(user, 'userProfile.facebook') || '',
        twitter: get(user, 'userProfile.twitter') || '',
        instagram: get(user, 'userProfile.instagram') || '',
        tiktok: get(user, 'userProfile.tiktok') || '',
        linkedin: get(user, 'userProfile.linkedin') || '',
        professionDetails: [
          [
            i18next.t('page.userProfile.professionalTitle'),
            get(user, 'userProfile.proffesionalTitle.name') || '',
          ],
          [i18next.t('page.userProfile.jobTitle'), get(user, 'userProfile.jobTitle.name') || ''],
          [i18next.t('page.userProfile.company'), get(user, 'userProfile.company') || ''],
          [i18next.t('page.userProfile.industry'), get(user, 'userProfile.industry.name') || ''],
        ].filter((n) => !!n[1]),
        additionalInfo: [
          [i18next.t('page.userProfile.email'), user.email || ''],
          [
            i18next.t('page.userProfile.dob'),
            (user.dob && moment(user.dob).format('DD/MM/YYYY')) || '',
          ],
          [
            i18next.t('page.userProfile.countryOfOrigin'),
            get(user, 'userProfile.countryOfOrigin.name') || '',
          ],
          [i18next.t('page.userProfile.liveNow'), get(user, 'country.name') || ''],
          [i18next.t('page.userProfile.ma'), get(user, 'memberAssociation.name') || ''],
          get(user, 'youngCrew')
            ? [i18next.t('page.userProfile.youngCrewMember'), get(user, 'youngCrew.name') || 'Yes']
            : [],
        ].filter((n) => !!n[1]),
        roles: user.profileRoles || [],
        tags: map(get(user, 'userProfile.tags'), 'name') || [],
        certifications: map(user.certificates, (n) => {
          const { memberAssociation, level, certificateDomain, ownerName } = n;

          const levelName = get(level, 'name') || '';
          const certificateName =
            get(certificateDomain, 'certificateLevelDomains[0].designation') || '';

          return {
            id: n.certificateId || null,
            name: levelName ? `${certificateName} Level ${levelName}` : certificateName,
            awardedDate: parseDate(n.issueDate),
            validityDate: parseDate(n.validUntil),
            ma: {
              id: get(memberAssociation, 'id') || null,
              name: get(memberAssociation, 'name') || '',
            },
            level: {
              name: levelName,
            },
            ownerName,
          };
        }),
      };

      yield put(setPageUserProfileCreator(data));
      yield put(loadPageUserProfileBadgesCreator(id));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] load page user profile error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadPageUserProfileBadges({ payload: id }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(pageService.getPageUserProfileBadges, id);

    if (response.ok) {
      const {
        results: {
          result: { entity },
        },
      } = yield response.json();

      const parser = (n) => ({
        id: n.badgeId,
        title: n.badgeTemplateName,
        image: n.badgeTemplateImage,
        url: n.url,
      });

      const badges = map(entity, parser);

      yield put(setPageUserProfileBadgesCreator(badges));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    console.log('[GET] load page user profile badges error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* submitContactForm({ payload: body, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'SUBMIT_PAGE_CONTACT_FORM' }));

    const response = yield call(pageService.submitContactForm, body);

    if (response.ok) {
      history.push('/contact/thank-you');
    }

    yield put(removeLoadingCreator({ type: 'SUBMIT_PAGE_CONTACT_FORM' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'SUBMIT_PAGE_CONTACT_FORM' }));
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    console.log('[POST] submit contact form error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'SUBMIT_PAGE_CONTACT_FORM' }));
  }
}

export default function* watchPage() {
  yield takeLatest(LOAD_PAGE_HOME, loadPageHome);
  yield takeLatest(LOAD_PAGE_USER_PROFILE, loadPageUserProfile);
  yield takeLatest(LOAD_PAGE_USER_PROFILE_BADGES, loadPageUserProfileBadges);
  yield takeLatest(SUBMIT_PAGE_CONTACT_FORM, submitContactForm);
}
