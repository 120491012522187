import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
  changeOrganisationUserRoleCreator,
  loadGlobalOrganisationRolesCreator,
  loadUserOrganisationPeopleCreator,
  removeUserOrganisationPeopleCreator,
  setUserOrganisationPeoplePageCreator,
  setUserOrganisationPeoplePerPageCreator,
  updateOrganisationPersonResponsibleCreator,
} from 'store/actionsCreators';
import {
  Alerts,
  ButtonAction,
  Modal,
  Container,
  Header,
  PageContent,
  Radio,
  Table,
  FiltersControls,
  Pagination,
  Spinner,
  Select,
} from 'components';
import { useTranslation } from 'react-i18next';
import { Margin } from 'styled-components-spacing';
import { find } from 'lodash';

function People({
  items,
  loadGlobalOrganisationRoles,
  loadOrganisationPeople,
  removeOrganisationPeople,
  updatePersonResponsible,
  personResponsible,
  changeOrganisationUserRole,
  roles,
  page,
  pages,
  perPage,
  setPage,
  setPerPage,
  loading,
}) {
  const { t } = useTranslation();
  const [confirmRemove, setConfirmRemove] = useState(null);
  const { id } = useParams();

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.organisation.people',
      url: `/organisation/${id}/people`,
    },
  ];

  useEffect(() => {
    if (!roles.length) {
      loadGlobalOrganisationRoles();
    }
  }, []);

  useEffect(() => {
    setPage(1);
    loadOrganisationPeople(id);
  }, [perPage]);

  useEffect(() => {
    loadOrganisationPeople(id);
  }, [page, perPage]);

  const handleRemove = (_id) => () => {
    setConfirmRemove(_id);
  };

  const handleChangePerPage = ({ value }) => {
    setPerPage(value);
  };

  const handleChangePersonResponsible = (_id) => () => {
    if (personResponsible.id === _id) return;
    updatePersonResponsible({ user: _id, organization: id });
  };

  const handleChangeRole = (_id) => ({ value }) => {
    changeOrganisationUserRole({ user: _id, roles: [value], organization: id });
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.organisation.people.title')}
        renderActions={({ Button }) => (
          <Button as={Link} to={`/organisation/${id}/people/add`} secondary>
            {t('page.organisation.people.titleButton')}
          </Button>
        )}
      />
      <PageContent>
        <Container>
          <Alerts type="organisation" />
          <Margin top={{ xs: 16 }}>
            <FiltersControls perPageOnChange={handleChangePerPage} perPageSelected={perPage} />
          </Margin>
          <Table tablebreakpoint="lg">
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('people.firstLastName')}</Table.Heading>
                <Table.Heading>{t('people.email')}</Table.Heading>
                <Table.Heading>{t('global.personResponsible')}</Table.Heading>
                <Table.Heading>{t('people.role')}</Table.Heading>
                <Table.Heading />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <Spinner />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && !items.length ? (
                <Table.Row>
                  <Table.Cell colSpan={4}>{t('global.noResultsFound')}</Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && items.length
                ? items.map(({ user, createdAt: fromDate }) => (
                    <Table.Row key={user.id}>
                      <Table.Cell>
                        <Table.Label>{t('people.firstLastName')}:</Table.Label>
                        <Link to={`/user-profile/${user.uniqueUrl}`}>
                          {`${user.firstName} ${user.lastName}`}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('people.email')}:</Table.Label>
                        {user.email}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('global.personResponsible')}:</Table.Label>
                        <Radio
                          selected={personResponsible ? personResponsible.id === user.id : false}
                          onClick={handleChangePersonResponsible(user.id)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('people.role')}:</Table.Label>
                        <Select
                          small
                          value={
                            find(roles, {
                              value:
                                user.organizationRoles && user.organizationRoles.length
                                  ? user.organizationRoles[0].id
                                  : null,
                            }) || null
                          }
                          onChange={handleChangeRole(user.id)}
                          options={roles}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {personResponsible && personResponsible.id !== user.id ? (
                          <ButtonAction
                            onClick={handleRemove(user.id)}
                            title={t('global.remove')}
                            icon="closeCircle"
                          />
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </Container>
      </PageContent>
      <Modal
        heading={t('modals.headings.deleteUser')}
        isOpen={!!confirmRemove}
        confirm={() =>
          removeOrganisationPeople({ userToRemove: confirmRemove, organization: id }) &&
          setConfirmRemove(null)
        }
        cancel={() => setConfirmRemove(null)}
      />
    </>
  );
}

People.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  loadGlobalOrganisationRoles: PropTypes.func.isRequired,
  loadOrganisationPeople: PropTypes.func.isRequired,
  removeOrganisationPeople: PropTypes.func.isRequired,
  updatePersonResponsible: PropTypes.func.isRequired,
  changeOrganisationUserRole: PropTypes.func.isRequired,
  personResponsible: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  roles: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }))
    .isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
People.defaultProps = {
  personResponsible: null,
  loading: false,
};

const mapStateToProps = ({ organisation, globals, loading }) => ({
  ...organisation.people,
  personResponsible: organisation.personResponsible,
  roles: globals.organisation.roles,
  loading: !!loading.filter((item) => item.type === 'ORGANISATION').length,
});
const mapDispatchToProps = (dispatch) => ({
  loadGlobalOrganisationRoles: () => dispatch(loadGlobalOrganisationRolesCreator()),
  loadOrganisationPeople: (payload) => dispatch(loadUserOrganisationPeopleCreator(payload)),
  removeOrganisationPeople: (payload) => dispatch(removeUserOrganisationPeopleCreator(payload)),
  updatePersonResponsible: (payload) =>
    dispatch(updateOrganisationPersonResponsibleCreator(payload)),
  changeOrganisationUserRole: (payload) => dispatch(changeOrganisationUserRoleCreator(payload)),
  setPage: (payload) => dispatch(setUserOrganisationPeoplePageCreator(payload)),
  setPerPage: (payload) => dispatch(setUserOrganisationPeoplePerPageCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
