import React from 'react';
import { ActionCart, ActionCartIco, ActionLink, Actions, ActionWrapper, ContentTitle, ContentWrapper, Image, ImageContainer, ImageLink, ImageWrapper, Pin, PinWrapper, Price, PriceWrapper, ProductItem, Products, Svg } from './BookCards.styled';

function BookCards({library}) {
  const openPdf = (e, href) => {
    e.preventDefault();

    window.open(href, '_blank').focus();
  }
  return (
    <Products>
      {library.map((item) => (
        <ProductItem>
          <ImageLink href={item.shopUrl}>
            <ImageWrapper>
              <ImageContainer>
                <Image src={item.imgUrl} />
              </ImageContainer>
              <PinWrapper>
                <Pin>
                  {item.pin}
                </Pin>
              </PinWrapper>
            </ImageWrapper>
          </ImageLink>
          <ContentWrapper>
            <ImageLink href={item.shopUrl}>
              <ContentTitle>{item.title}</ContentTitle>
            </ImageLink>
            <ActionWrapper>
              <ImageLink
                href={item.shopUrl}
              >
                <PriceWrapper>
                  <Price>
                    <span class="woocommerce-Price-amount amount">{item.price}</span>
                  </Price>
                </PriceWrapper>
              </ImageLink>
              <Actions>
                <ImageLink href={item.shopUrl} />
                <ActionCart>
                  <ImageLink href={item.shopUrl} />
                  <ActionCartIco
                    href={item.shopUrl}
                    data-quantity="1"
                    class="button product_type_simple w-12 ajax_add_to_cart bg-red hover:bg-red-dark text-white no-underline h-12 flex flex-1 justify-center items-center rounded-full"
                    data-product_id="14389"
                    data-product_sku=""
                    aria-label="Read more about “Research Evaluation Baseline”"
                    rel="nofollow"
                  >
                    <Svg xmlns="http://www.w3.org/2000/svg" height="17px" viewBox="0 0 21 19.26">
                      <path d="M19.38 10.32l1.61-8a.53.53 0 0 0-.1-.42.53.53 0 0 0-.39-.18H4L3.76 1a1.37 1.37 0 0 0-1.32-1H.5a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5h1.94a.38.38 0 0 1 .36.27l3 11.11A2.24 2.24 0 0 0 7.92 14h10.84a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5H7.92a1.22 1.22 0 0 1-1.19-.92l-.19-.69H18a1.36 1.36 0 0 0 1.38-1.07zM4.22 2.74h15.67l-1.49 7.39a.36.36 0 0 1-.36.3H6.28zm3.24 12A2.24 2.24 0 1 0 9.7 17a2.24 2.24 0 0 0-2.24-2.22zm0 3.48A1.24 1.24 0 1 1 8.7 17a1.24 1.24 0 0 1-1.24 1.26zM17 14.78A2.24 2.24 0 1 0 19.26 17 2.25 2.25 0 0 0 17 14.78zm0 3.48A1.24 1.24 0 1 1 18.26 17 1.25 1.25 0 0 1 17 18.26z"></path>
                    </Svg>
                  </ActionCartIco>
                </ActionCart>
                <ActionLink
                  onClick={(e) => {openPdf(e, item.file)}}
                >
                  <Svg xmlns="http://www.w3.org/2000/svg" height="17px" viewBox="0 0 21 14.04">
                    <path d="M20.94 6.79C20.79 6.51 17.24 0 10.5 0S.21 6.51.06 6.79a.49.49 0 0 0 0 .47C.21 7.53 3.76 14 10.5 14s10.29-6.47 10.44-6.74a.49.49 0 0 0 0-.47zM10.5 13c-5.45 0-8.73-4.84-9.42-6 .69-1.14 4-6 9.42-6s8.73 4.88 9.42 6c-.69 1.16-3.92 6-9.42 6zm0-10.43A4.42 4.42 0 1 0 14.91 7a4.42 4.42 0 0 0-4.41-4.39zm0 7.83A3.42 3.42 0 1 1 13.91 7a3.42 3.42 0 0 1-3.41 3.44z"></path>
                  </Svg>
                </ActionLink>
              </Actions>
            </ActionWrapper>
          </ContentWrapper>
        </ProductItem>
      ))}
    </Products>
  );
}
export default BookCards;
