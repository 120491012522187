import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Header,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  Box,
  UserList,
  IconText,
  Tags,
  Map,
  Download,
  SeoSection,
  Button,
  Modal,
  Alerts,
} from 'components';
import { connect } from 'react-redux';
import {
  loadListViewCreator,
  removeListViewCreator,
  applyUserToCourseCreator,
} from 'store/actionsCreators';
import { isLogin } from 'api';
import { useTranslation } from 'react-i18next';
import { globalPropTypes, newLineToBr, parseUrlTitle } from 'helpers';
import { useAuthLock } from 'hooks';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.courses',
    url: '/training',
  },
];

const View = ({ appType, loadView, removeView, view, history, applyUserToCourse }) => {
  const { id, name } = useParams();
  const { t } = useTranslation();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [renderAuthLock] = useAuthLock(history);

  const {
    title,
    subtitle,
    description,
    instructionFormat,
    competencies,
    tags,
    keyMaterials,
    learningOutcomes,
    place,
    countryCode,
    startDate,
    endDate,
    duration,
    deliveryMode,
    courseType,
    signature,
    programme,
    phone,
    email,
    website,
    lat,
    lng,
    files,
    timezone,
    keyExperts,
    assessors,
    personResponsible,
    organisator,
    isPublished,
    isOutdated,
    mainLanguage,
    secondaryLanguage,
    courseGeneralStatus,
  } = view;

  const details = [
    { name: 'page.singleCourse.country', value: countryCode },
    { name: 'page.singleCourse.language', value: mainLanguage && mainLanguage.name },
    {
      name: 'page.singleCourse.secondaryLanguage',
      value: secondaryLanguage && secondaryLanguage.name,
    },
    { name: 'page.singleCourse.startingDate', value: startDate },
    { name: 'page.singleCourse.endingDate', value: endDate },
    { name: 'page.singleCourse.duration', value: duration },
    { name: 'page.singleCourse.type', value: courseType },
    { name: 'page.singleCourse.modeOfDelivery', value: deliveryMode },
    { name: 'page.singleCourse.timezone', value: timezone },
    { name: 'page.singleCourse.signature', value: signature },
  ];

  useEffect(() => {
    loadView(id, history);

    return () => {
      removeView();
    };
  }, []);

  useEffect(() => {
    if (!name && view && view.title) {
      history.replace({ pathname: `/training/${id}/${parseUrlTitle(view.title)}` });
    }
  }, [view]);

  const renderDetails = () => (
    <Padding horizontal={{ xl: 20 }} vertical="20" style={{ marginBottom: '-16px' }}>
      {place && (
        <Margin as={Row} bottom="16">
          <Col size={1 / 2}>{t('page.singleCourse.address')}</Col>
          <Col size={1 / 2} className="text-right">
            <strong>
              <IconText inline icon="marker" text={place} />
            </strong>
          </Col>
        </Margin>
      )}

      {details &&
        details.map(
          (item) =>
            item.value && (
              <Margin as={Row} bottom="16">
                <Col size={1 / 2}>{t(item.name)}</Col>
                <Col size={1 / 2} className="text-right">
                  <strong>{item.value}</strong>
                </Col>
              </Margin>
            ),
        )}
    </Padding>
  );

  const renderCourseProgramme = () =>
    programme && (
      <Margin bottom={{ xs: 32, xl: 48 }}>
        <HeadingWrapper>
          <Heading as="h2" size="h4">
            {t('page.singleCourse.courseProgramme')}
          </Heading>
        </HeadingWrapper>
        <Cms>
          <p dangerouslySetInnerHTML={{ __html: programme }} />
        </Cms>
      </Margin>
    );

  const renderInfo = (_name, value) => {
    if (!value) return null;

    return (
      <p>
        <strong>{_name}:</strong> {value}
      </p>
    );
  };

  const renderList = (_name, items) => {
    if (!items || !items.length) return null;

    return (
      <Margin bottom={{ xs: 32, xl: 48 }}>
        <HeadingWrapper>
          <Heading as="h2" size="h4">
            {_name}
          </Heading>
        </HeadingWrapper>
        <Cms>
          <ol>
            {items.map((item) => (
              <li key={item.id}>{item.description}</li>
            ))}
          </ol>
        </Cms>
      </Margin>
    );
  };

  const renderFiles = () => {
    if ((appType !== 'ma' && appType !== 'organisation') || !files || !files.length) return null;

    return (
      <Margin bottom={{ xs: 32, xl: 48 }}>
        <HeadingWrapper>
          <Heading as="h2" size="h4">
            {t('page.singleCourse.files')}
          </Heading>
        </HeadingWrapper>
        <Row style={{ marginBottom: '-24px' }}>
          {files.map(
            (file) =>
              file.path &&
              file.fileName && (
                <Margin as={Col} bottom="24" size={{ md: 1 / 3 }}>
                  <Download src={file.path} title={file.fileName} download />
                </Margin>
              ),
          )}
        </Row>
      </Margin>
    );
  };

  const renderContactDetails = () => (
    <Padding all="20" className="bg-gray-100">
      {organisator && organisator.title && (
        <Margin bottom="16">
          <Heading as="h3" size="h6">
            {organisator.title}
          </Heading>
        </Margin>
      )}

      {phone && (
        <Margin bottom="12">
          <a href={`tel:${phone}`}>{phone}</a>
        </Margin>
      )}

      {email && (
        <Margin bottom="12">
          <a href={`mailto:${email}`} className="link link--16 word-break">
            {email}
          </a>
        </Margin>
      )}

      {website && (
        <a href={website} className="link link--16 word-break">
          {website}
        </a>
      )}
    </Padding>
  );

  const renderMap = () => {
    if (!lat || !lng) return null;

    return <Map lat={lat} lng={lng} />;
  };

  const renderKeyExperts = () => {
    if ((appType !== 'ma' && appType !== 'organisation') || !keyExperts || !keyExperts.length)
      return null;

    return (
      <Margin top="32">
        <UserList title={t('page.singleCourse.keyExperts')} users={keyExperts} />
      </Margin>
    );
  };

  const renderSelfAssessors = () => {
    if ((appType !== 'ma' && appType !== 'organisation') || !assessors || !assessors.length)
      return null;

    return (
      <Margin top="32">
        <UserList title={t('page.singleCourse.selfAssessor')} users={assessors} />
      </Margin>
    );
  };

  const renderPersonResponsible = () => {
    if (!personResponsible || !personResponsible.length) return null;

    return (
      <Margin top="32">
        <UserList title={t('page.singleCourse.personResponsible')} users={personResponsible} />
      </Margin>
    );
  };

  const handleClickJoin = () => {
    setOpenConfirmModal(false);
    applyUserToCourse(id);
  };

  const renderJoin = () => {
    if (!isLogin() || !isPublished || isOutdated) return null;

    return (
      <SeoSection title={t('page.singleCourse.joinTitle')}>
        <p className="text-18 font-weight-medium text-gray-200 text-center">
          {t('page.singleCourse.joinDescription')}
        </p>
        <Margin top="32">
          <Row halign="center">
            <Col size={{ md: 1 / 3 }}>
              <Button onClick={() => setOpenConfirmModal(true)}>
                {t('page.singleCourse.joinButton')}
              </Button>
              <Modal
                heading={t('page.singleCourse.joinModalTitle')}
                isOpen={openConfirmModal}
                confirm={handleClickJoin}
                cancel={() => setOpenConfirmModal(false)}
                onRequestClose={() => setOpenConfirmModal(false)}
              />
            </Col>
          </Row>
        </Margin>
      </SeoSection>
    );
  };

  const joinMessage = () => {
    if (courseGeneralStatus === 'courseState-template') {
      return t('page.singleCourse.cantJoinCourseTemplate');
    }
    if (courseGeneralStatus === 'courseState-ended') {
      return t('page.singleCourse.cantJoinCourseEnded');
    }
    if (
      courseGeneralStatus === 'courseState-running' ||
      courseGeneralStatus === 'courseState-upcoming'
    ) {
      return t('page.singleCourse.canJoinCourseMsg');
    }
    return '';
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={title}
        description={subtitle}
        secondDescription={joinMessage()}
        big
        headingFirstLine
      />
      <PageContent>
        {renderAuthLock()}
        <Container>
          <Alerts type="courses" />
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                <Cms>
                  <HeadingWrapper>
                    <Heading as="h2" size="h5">
                      {title}
                    </Heading>
                  </HeadingWrapper>
                  {description ? (
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
                  ) : null}
                  {renderInfo(t('page.singleCourse.formatsOfInstruction'), instructionFormat)}
                  {renderInfo(t('page.singleCourse.competenciesCovered'), competencies)}
                </Cms>
                {tags && !!tags.length && (
                  <Margin top="24">
                    <Tags tags={tags} />
                  </Margin>
                )}
              </Margin>

              {renderCourseProgramme()}

              {renderList(t('page.singleCourse.expectedLearningOutcomes'), learningOutcomes)}
              {renderList(t('page.singleCourse.keyMaterials'), keyMaterials)}
              {renderFiles()}

              {!!organisator && (
                <Margin bottom={{ xs: 32, xl: 48 }}>
                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleCourse.organisator')}
                    </Heading>
                  </HeadingWrapper>
                  <Box
                    thumbnail={organisator.thumbnail}
                    title={organisator.title}
                    url={organisator.url}
                    type="organisation"
                    description={organisator.description}
                    renderInfo={({ IconText }) => (
                      <>
                        {organisator.place && <IconText icon="marker" text={organisator.place} />}
                        {organisator.user && <IconText icon="user" text={organisator.user} />}
                        {organisator.courses && (
                          <IconText
                            icon="checkRectangle"
                            text={`${t('global.courses')} (${organisator.courses})`}
                          />
                        )}
                      </>
                    )}
                  />
                </Margin>
              )}
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              <div className="border-xl">
                {renderContactDetails()}
                {renderDetails()}
                {renderMap()}
              </div>
              {renderPersonResponsible()}
              {renderKeyExperts()}
              {renderSelfAssessors()}
            </Margin>
          </Row>
        </Container>
      </PageContent>
      {renderJoin()}
    </>
  );
};

View.propTypes = {
  appType: PropTypes.oneOf(['guest', 'user', 'organisation', 'ma', 'ipma', 'yc']).isRequired,
  loadView: PropTypes.func.isRequired,
  removeView: PropTypes.func.isRequired,
  applyUserToCourse: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  view: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    instructionFormat: PropTypes.arrayOf(PropTypes.string),
    competencies: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    keyMaterials: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
      }),
    ),
    learningOutcomes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
      }),
    ),
    place: PropTypes.string,
    countryCode: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    duration: PropTypes.string,
    deliveryMode: PropTypes.string,
    courseType: PropTypes.string,
    signature: PropTypes.string,
    programme: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        fileName: PropTypes.string,
      }),
    ),
    keyExperts: PropTypes.arrayOf(globalPropTypes.UserListItemPropsTypes),
    assessors: PropTypes.arrayOf(globalPropTypes.UserListItemPropsTypes),
    personResponsible: PropTypes.arrayOf(globalPropTypes.UserListItemPropsTypes),
    timezone: PropTypes.string,
    organisator: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      url: PropTypes.string,
      thumbnail: PropTypes.string,
      place: PropTypes.string,
      user: PropTypes.string,
      description: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string,
      courses: PropTypes.string,
    }),
    isPublished: PropTypes.bool,
    isOutdated: PropTypes.bool,
    mainLanguage: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    secondaryLanguage: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    courseGeneralStatus: PropTypes.string,
  }),
};

View.defaultProps = {
  view: {
    subtitle: '',
    description: '',
    instructionFormat: [],
    competencies: [],
    tags: [],
    keyMaterials: [],
    learningOutcomes: [],
    place: '',
    countryCode: '',
    startDate: '',
    endDate: '',
    duration: '',
    deliveryMode: '',
    courseType: '',
    signature: '',
    programme: '',
    phone: '',
    email: '',
    website: '',
    lat: '',
    lng: '',
    files: [],
    keyExperts: [],
    assessors: [],
    personResponsible: [],
    timezone: '',
    organisator: {},
    isPublished: false,
    isOutdated: false,
    courseGeneralStatus: '',
  },
};

const mapStateToProps = ({
  user: { appType },
  list: {
    courses: { view },
  },
}) => ({
  appType,
  view,
});

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload, history) => dispatch(loadListViewCreator('courses', payload, history)),
  removeView: () => dispatch(removeListViewCreator('courses')),
  applyUserToCourse: (payload) => dispatch(applyUserToCourseCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
