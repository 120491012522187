import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Pagination,
  FiltersControls,
  BoxList,
  Spinner,
  Alerts,
} from 'components';
import {
  loadOrganisationCoursesCreator,
  setOrganisationCoursesPageCreator,
  setOrganisationCoursesPerPageCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const SubmittedTemplates = ({ loading, loadList, setPage, setPerPage, list, history }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { items, page, pages, perPage } = list;

  useEffect(() => {
    setPage(1);
    loadList(id);
  }, [perPage]);

  const handleEditDraft = (item) => () => {
    history.push(`/organisation/${id}/courses/edit/${item.id}`);
  };

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <Alerts type="organisation" />
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <BoxList
              type="course"
              items={items}
              alert={t('global.noResultsCourses')}
              customRenderActions={({ item, ButtonAction }) => (
                <>
                  <ButtonAction
                    icon="edit"
                    title={t('global.edit')}
                    verticalviewbreakpoint="xl"
                    onClick={handleEditDraft(item)}
                  />
                </>
              )}
            />
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList(id);
              }}
            />
          </>
        ) : null}
      </Container>
    </PageContent>
  );
};

SubmittedTemplates.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  list: globalPropTypes.ListPropsTypes.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

SubmittedTemplates.defaultProps = {
  loading: false,
};

const mapStateToProps = ({
  loading,
  organisation: {
    courses: { submittedTemplates: list },
  },
}) => ({
  loading: isLoading(loading, 'LOAD_ORGANISATION_COURSES_SUBMITTED_TEMPLATES'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: (organization) =>
    dispatch(loadOrganisationCoursesCreator('submittedTemplates', organization)),
  setPage: (payload) => dispatch(setOrganisationCoursesPageCreator('submittedTemplates', payload)),
  setPerPage: (payload) =>
    dispatch(setOrganisationCoursesPerPageCreator('submittedTemplates', payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedTemplates);
