import styled, { css } from 'styled-components';

export const Wrapper = styled.form`
  width: 20px;
  display: flex;
  align-items: center;
  margin-right: 0px;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
