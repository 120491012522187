import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';

import { Margin } from 'styled-components-spacing/dist/cjs';
import { Wrapper, Icon, Content } from './Download.styled';

const Download = ({ src, title, downloadTitle }) => {
  return (
    <Wrapper>
      <Icon>
        <ReactSVG src={iconList.download} />
      </Icon>
      <Content>
        <p className="word-break">{title}</p>
        <Margin top="4">
          <a href={src} download className="link uppercase">
            {downloadTitle || 'Download'}
          </a>
        </Margin>
      </Content>
    </Wrapper>
  );
};

Download.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  downloadTitle: PropTypes.string,
};

Download.defaultProps = {
  downloadTitle: '',
};

export default Download;
