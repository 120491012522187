import React from 'react';
import { ReactSVG } from 'react-svg';

import StepsContent, { Step } from './Stepper.styled';

import { iconList as icons } from '../../assets';

function Stepper({ steps, activeIndex }) {

  return <StepsContent>
    { steps.map((step, index) => {
      const isActive = index <= activeIndex;
      const isDone = index < activeIndex;

      return (
        <Step
          key={step.label}
          active={isActive}
          onClick={!step.disabled && step.go || undefined}
        >
          {isDone ? <ReactSVG src={icons.checkThin} /> : step.label}
        </Step>
      );
    }) }
  </StepsContent>;
}

export default Stepper;
