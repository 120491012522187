import React, { useEffect } from 'react';
import { Row, Col, Dropdown, Heading, Field, Select, Datepicker } from 'components';
import { Margin, Padding } from 'styled-components-spacing';
import PropTypes from 'prop-types';
import { parseParams } from 'helpers';

const FiltersCustom = ({
  title,
  filters,
  filterFields,
  handleFilterFieldChange,
  onFiltersApply,
  info,
}) => {
  const applyFilters = () => {
    const filtersToApply = Object.keys(filterFields)
      .filter((field) => filterFields[field])
      .reduce(
        (p, c) => ({
          ...p,
          [filters[c].name]: filterFields[c].value ? filterFields[c].value : filterFields[c],
        }),
        {},
      );
    onFiltersApply(parseParams({ ...filtersToApply }));
  };

  useEffect(() => {
    applyFilters();
  }, [filterFields]);

  const handleInputChange = (e) => {
    handleFilterFieldChange(e);
  };

  const handleDatePickerChange = (field) => (selected) => {
    handleFilterFieldChange({
      target: {
        id: field,
        value: selected,
      },
    });
  };

  const handleSelectChange = (field) => (selected) => {
    handleFilterFieldChange({
      target: {
        id: field,
        value: selected,
      },
    });
  };

  const renderSelect = (field, label, placeholder, options) => {
    return (
      <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }} key={`sid-${field}`}>
        <Field
          label={label}
          id={`f-${field}`}
          value={filterFields[field]}
          clear={() => handleSelectChange(field)(null)}
        >
          <Select
            id={`f-${field}`}
            name={field}
            placeholder={placeholder}
            value={filterFields[field]}
            onChange={handleSelectChange(field)}
            options={options}
          />
        </Field>
      </Margin>
    );
  };

  const renderInput = (field, label, placeholder) => (
    <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }} key={`fid-${field}`}>
      <Field label={label}>
        <input
          id={field}
          type="text"
          placeholder={placeholder}
          value={filterFields[field]}
          onChange={handleInputChange}
        />
      </Field>
    </Margin>
  );

  const renderDatePicker = (field, label, placeholder) => (
    <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }} key={`fid-${field}`}>
      <Field
        label={label}
        id={`f-${field}`}
        value={filterFields[field]}
        clear={() => handleDatePickerChange(field)(null)}
      >
        <Datepicker
          id={`f-${field}`}
          name={field}
          onChange={handleDatePickerChange(field)}
          placeholderText={placeholder}
          selected={filterFields[field]}
          dateFormat="dd/MM/yyyy"
        />
      </Field>
    </Margin>
  );

  const renderFields = () => {
    return Object.keys(filterFields).map((field) => {
      switch (filters[field].type) {
        case 'input':
          return renderInput(field, filters[field].label, filters[field].placeholder);
        case 'datepicker':
          return renderDatePicker(field, filters[field].label, filters[field].placeholder);
        case 'select':
          return renderSelect(
            field,
            filters[field].label,
            filters[field].placeholder,
            filters[field].options,
          );
        default:
          return <></>;
      }
    });
  };

  return (
    <>
      {info ? (
        <Margin as="p" bottom={{ xs: 16 }} top={{ xs: 16 }}>
          <strong>{title}</strong>
        </Margin>
      ) : null}
      <form>
        <Row>
          <Margin left={{ xs: 16 }} right={{ xs: 16 }} style={{ width: '100%' }}>
            <Padding horizontal={{ xs: 16 }} vertical={{ xs: 16 }} className="border-xs">
              <Dropdown
                openbreakpoint="xl"
                title={
                  <Heading as="h3" size="h4">
                    {title}
                  </Heading>
                }
              >
                <Row>{renderFields()}</Row>
              </Dropdown>
            </Padding>
          </Margin>
        </Row>
      </form>
    </>
  );
};

FiltersCustom.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.shape(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      options: PropTypes.shape({}),
    }),
  ).isRequired,
  filterFields: PropTypes.shape({}).isRequired,
  handleFilterFieldChange: PropTypes.func.isRequired,
  onFiltersApply: PropTypes.func.isRequired,
  info: PropTypes.string,
};

FiltersCustom.defaultProps = {
  info: '',
};

export default FiltersCustom;
