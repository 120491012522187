import styled, { css } from 'styled-components';
import { p, pl, mb } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  ${({ theme }) => theme && css`
    text-align: center;

    ${breakpoint('md')`
      ${css`
        display: flex;
        align-items: center;
      `}
    `}
  `}

`;

export const Thumbnail = styled.div`
  ${({ theme }) => theme && css`
    ${p(8)};

    flex: 0 0 180px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    height: 150px;
    background-color: #fff;
    
    border: 1px solid ${theme.colors.gray['100']};
    
    img {
      max-height: 100%;
    }
    
    ${breakpoint('md')`
      ${css`
        order: -1;
      `}
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => theme && css`
    ${mb(16)};

    text-align: left;
  
    ${breakpoint('md')`
      ${css`
        ${mb(0)};
        ${pl(16)};
      `}
    `}
  `}
`;
