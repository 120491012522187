import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return [
    {
      value: 'courseState-running',
      label: t('dictionaries.courseStatusRunning'),
    },
    {
      value: 'courseState-ended',
      label: t('dictionaries.courseStatusCompleted'),
    },
    {
      value: 'courseState-upcoming',
      label: t('dictionaries.courseStatusUpcoming'),
    },
    {
      value: 'courseState-template',
      label: t('dictionaries.courseStatusTemplate'),
    },
  ];
};
