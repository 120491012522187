import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Modal, Row, Heading, FormCreator, Spinner, Button, Col } from 'components';
import { createProjectRoleApplicationCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import { useFormFields, isLoading, getFileValue } from 'helpers';
import { SubmitButton, Success, ModalContentCustom } from './ApplyModal.styled';
import {
  isValidEmail,
  isValidFileFormat,
  isValidFileSize,
  isValidPhone,
  isValidUrl,
} from '../../../../helpers';

const ModalForm = ({ id, setSent, sendApplication, isCvRequired, isLetterRequired }) => {
  const { t } = useTranslation();

  const validateError = useRef(false);
  const validateErrorsMsg = useRef({});
  const [formFields, handleFormFieldChange] = useFormFields({
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    linkedinUrl: '',
    letterFile: null,
    cvFile: null,
    errors: {},
  });

  const formDetails = {
    'row-1': {
      name: {
        type: 'input',
        id: 'f-firstName',
        label: t('page.singleProjectRole.firstNameLabel'),
        placeholder: t('page.singleProjectRole.firstNameDesc'),
        icon: 'user',
        required: true,
      },
      surname: {
        type: 'input',
        id: 'f-lastName',
        label: t('page.singleProjectRole.lastNameLabel'),
        placeholder: t('page.singleProjectRole.lastNameDesc'),
        icon: 'user',
        required: true,
      },
    },
    'row-2': {
      email: {
        type: 'input',
        id: 'f-email',
        label: t('page.singleProjectRole.emailLabel'),
        placeholder: t('page.singleProjectRole.emailDesc'),
        icon: 'mail',
        required: true,
      },
      phoneNumber: {
        type: 'input',
        id: 'f-phone',
        label: t('page.singleProjectRole.phoneLabel'),
        placeholder: t('page.singleProjectRole.phoneDesc'),
        icon: 'phone',
        required: true,
      },
    },
    'row-3': {
      linkedinUrl: {
        type: 'input',
        id: 'f-linkedinUrl',
        label: t('page.singleProjectRole.linkedinUrlLabel'),
        placeholder: t('page.singleProjectRole.linkedinUrlDesc'),
        icon: 'linkedin',
      },
    },
    'row-4': {
      letterFile: {
        type: 'fileupload',
        id: 'f-motivationLetter',
        label: t('page.singleProjectRole.motivationLetterLabel'),
        placeholder: t('page.singleProjectRole.motivationLetterDesc'),
        required: isLetterRequired,
      },
      cvFile: {
        type: 'fileupload',
        id: 'f-cv',
        label: t('page.singleProjectRole.cvLabel'),
        placeholder: t('page.singleProjectRole.cvDesc'),
        required: isCvRequired,
      },
    },
  };

  const setValidationError = (prop, errorMsg) => {
    validateErrorsMsg.current = {
      ...validateErrorsMsg.current,
      [prop]: errorMsg,
    };
    validateError.current = true;
  };

  const submit = () => {
    const params = new FormData();
    if (!formFields.name) {
      setValidationError('name', t('global.validationErrors.fieldNotEmpty'));
    }

    if (!formFields.surname) {
      setValidationError('surname', t('global.validationErrors.fieldNotEmpty'));
    }

    if (!formFields.email) {
      setValidationError('email', t('global.validationErrors.fieldNotEmpty'));
    } else if (!isValidEmail(formFields.email)) {
      setValidationError('email', t('global.validationErrors.invalidEmail'));
    }

    if (!formFields.phoneNumber) {
      setValidationError('phoneNumber', t('global.validationErrors.fieldNotEmpty'));
    } else if (!isValidPhone(formFields.phoneNumber)) {
      setValidationError('phoneNumber', t('global.validationErrors.invalidPhone'));
    }

    if (formDetails['row-4'].letterFile.required && !formFields.letterFile) {
      setValidationError('letterFile', t('global.validationErrors.fieldNotEmpty'));
    } else if (formFields.letterFile && !isValidFileFormat(formFields.letterFile, ['pDF'])) {
      setValidationError('letterFile', t('global.validationErrors.invalidFileFormat'));
    } else if (formFields.letterFile && !isValidFileSize(formFields.letterFile, 2)) {
      setValidationError('letterFile', t('global.validationErrors.invalidFileSize'));
    }

    if (formDetails['row-4'].cvFile.required && !formFields.cvFile) {
      setValidationError('cvFile', t('global.validationErrors.fieldNotEmpty'));
    } else if (formFields.cvFile && !isValidFileFormat(formFields.cvFile, ['pDf'])) {
      setValidationError('cvFile', t('global.validationErrors.invalidFileFormat'));
    } else if (formFields.cvFile && !isValidFileSize(formFields.cvFile, 2)) {
      setValidationError('cvFile', t('global.validationErrors.invalidFileSize'));
    }

    if (formFields.linkedinUrl && !isValidUrl(formFields.linkedinUrl)) {
      setValidationError('linkedinUrl', t('global.validationErrors.invalidUrl'));
    }

    if (validateError.current) {
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: validateErrorsMsg.current,
        },
      });
      validateErrorsMsg.current = {};
      validateError.current = false;
      return;
    }
    params.append('ycProjectRole', id);
    params.append('name', formFields.name || '');
    params.append('surname', formFields.surname || '');
    params.append('email', formFields.email || '');
    params.append('phoneNumber', formFields.phoneNumber || '');
    params.append('linkedInUrl', formFields.linkedinUrl || '');
    params.append('letterFile', getFileValue(formFields.letterFile));
    params.append('cvFile', getFileValue(formFields.cvFile));

    sendApplication(params, setSent, (payload) => {
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: payload,
        },
      });
    });
  };

  return (
    <>
      <FormCreator
        formFields={formFields}
        formDetails={formDetails}
        errors={formFields.errors}
        handleFormFieldChange={handleFormFieldChange}
        buttonLabel="Apply"
        onSubmit={submit}
        hideButton
      />
      <SubmitButton onClick={submit}>{t('page.singleProjectRole.applyButton')}</SubmitButton>
    </>
  );
};

const ApplyModal = ({ id, open, loading, closeFn, sendApplication, view }) => {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);

  const renderContent = () => (
    <ModalContentCustom>
      <Heading as="h4" noLine center>
        {sent ? t('page.singleProjectRole.sentTitle') : t('page.singleProjectRole.applyFormTitle')}
      </Heading>
      <Margin top="16">
        {sent ? t('page.singleProjectRole.sentDesc') : t('page.singleProjectRole.applyFormDesc')}
      </Margin>
      {loading ? (
        <Spinner />
      ) : (
        <Margin top="32">
          <Row halign="center">
            {sent ? (
              <>
                <Success />
                <Col size={1 / 2}>
                  <Button onClick={closeFn}>Close</Button>
                </Col>
              </>
            ) : (
              <ModalForm
                id={id}
                setSent={() => setSent(true)}
                sendApplication={sendApplication}
                isCvRequired={view.cvRequired}
                isLetterRequired={view.letterRequired}
              />
            )}
          </Row>
        </Margin>
      )}
    </ModalContentCustom>
  );

  return (
    <Modal
      isOpen={open}
      customContent={renderContent}
      onRequestClose={closeFn}
      autoWidth
      customWidth="100%"
    />
  );
};

ApplyModal.propTypes = {
  id: PropTypes.number.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  closeFn: PropTypes.func.isRequired,
  sendApplication: PropTypes.func.isRequired,
  view: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    occupation: PropTypes.string,
    careerLevel: PropTypes.string,
    ycProjectRoleType: PropTypes.string,
    roleDescription: PropTypes.string,
    responsibilities: PropTypes.string,
    qualifications: PropTypes.string,
    closingInformations: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
    positionTimeStatus: PropTypes.string,
    status: PropTypes.string,
    cvRequired: PropTypes.bool,
    letterRequired: PropTypes.bool,
    project: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      url: PropTypes.string,
      thumbnail: PropTypes.string,
      place: PropTypes.string,
      description: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
  }),
};

ModalForm.propTypes = {
  id: PropTypes.number.isRequired,
  setSent: PropTypes.func.isRequired,
  sendApplication: PropTypes.func.isRequired,
  isCvRequired: PropTypes.bool.isRequired,
  isLetterRequired: PropTypes.bool.isRequired,
};

ApplyModal.defaultProps = {
  open: false,
  view: {},
};

const mapStateToProps = ({
  user: { appType },
  list: {
    projects: { view },
  },
  loading,
}) => ({
  loading: isLoading(loading, 'APPLICATION'),
  appType,
  view,
});

const mapDispatchToProps = (dispatch) => ({
  sendApplication: (payload, closeModal, setErrors) =>
    dispatch(createProjectRoleApplicationCreator(payload, closeModal, setErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyModal);
