import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSubRoutes } from 'hooks';
import { globalPropTypes } from 'helpers';
import {
  loadUserCertificationsCreator,
  loadUserAddListCertificatesCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import PendingBadges from 'containers/user/PendingBadges/PendingBadges';
import PendingCoupons from 'containers/user/PendingCoupons/PendingCoupons';
import ChangeEmail from './ChangeEmail/ChangeEmail';

const User = ({ routes, user, loadUserCertifications, loadAddListCertificates }) => {
  const [renderSubRoutes] = useSubRoutes(routes);

  useEffect(() => {
    loadUserCertifications();
    loadAddListCertificates();
  }, [user]);

  return (
    <>
      {renderSubRoutes()}
      <PendingBadges />
      <PendingCoupons />
      <ChangeEmail email={user.email} />
    </>
  );
};

User.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  loadUserCertifications: PropTypes.func.isRequired,
  loadAddListCertificates: PropTypes.func.isRequired,
};

User.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ user }) => ({ user: user.user });

const mapDispatchToProps = (dispatch) => ({
  loadUserCertifications: () => dispatch(loadUserCertificationsCreator()),
  loadAddListCertificates: () => dispatch(loadUserAddListCertificatesCreator(null, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
