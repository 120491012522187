import styled, { css } from 'styled-components';
import { p, py } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';
import headerImageMobile from 'assets/images/header_mobile.png';
import headerImageDesktop from 'assets/images/header_desktop_light.png';

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${py(32)};
      padding-bottom: 140px;
      
      position: relative;

      &::before {
        content: '';
        width: 100%;
        height: 123px;
        background-image: url(${headerImageMobile});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right top;
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
      }

      ${breakpoint('sm')`
        ${css`          
          &::before {
            background-image: url(${headerImageDesktop});
          }
        `}
      `}
      
      ${breakpoint('md')`
        ${css`
          ${py(42)};
          &::before {
            height: 270px;
          }
        `}
      `}
    `
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;

  ${breakpoint('md')`
    ${css`          
      ${p(24)};
      
      box-shadow: 0 8px 48px rgba(79, 78, 92, 0.08);
      background-color: #fff;
    `}
  `}
  
  ${breakpoint('xl')`
    ${css`          
      ${p(38)};
    `}
  `}
`;