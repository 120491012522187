import { takeLatest, call, put, select, all, cancelled } from 'redux-saga/effects';
import { chain, get, isEmpty, map } from 'lodash';
import moment from 'moment';
import i18next from 'i18next';

import * as userService from 'api/services/user';
import {
  setEditProfileErrorsCreator,
  setUserCreator,
  setNewAlertCreator,
  setEditPasswordErrorsCreator,
  setDeleteProfileErrorsCreator,
  setNewLoadingCreator,
  removeLoadingCreator,
  loadNotificationsCreator,
  setNotificationsCreator,
  setUserCertificationsCreator,
  setUserAddListCertificatesCreator,
  setUserAddListFiltrationCreator,
  loadUserCertificationsCreator,
  setUserDashboardCreator,
  setProfileCompletedCoursesCreator,
  setUserCoursesBarCreator,
  setUserCoursesCreator,
  setUserCoursesPagesCreator,
  loadUserCoursesBarCreator,
  loadUserCoursesCreator,
  checkTokenCreator,
  resetAppCreator,
  setUserBadgesCreator,
  loadUserBadgesCreator,
  setOpenPendingBadgesCreator,
  setCourseBadgeListCreator,
  loadCourseBadgeListCreator,
  setUserPendingBadgesCreator,
  downloadCourseDiplomaCreator,
  setUserPendingCertificationsBadgesCreator,
  loadUserPendingCertificatesCreator,
} from 'store/actionsCreators';
import {
  EDIT_PROFILE,
  LOAD_PROFILE,
  EDIT_PASSWORD,
  UPDATE_USER_EMAIL,
  DELETE_PROFILE,
  LOGOUT_USER,
  LOAD_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
  LOAD_USER_CERTIFICATIONS,
  LOAD_USER_DASHBOARD,
  LOAD_USER_ADD_LIST_CERTIFICATES,
  ADD_USER_ADD_LIST_CERTIFICATES,
  APPLY_USER_TO_COURSE,
  LOAD_PROFILE_COMPLETED_COURSES,
  LOAD_USER_COURSES_BAR,
  LOAD_USER_COURSES,
  DECLINE_USER_FROM_COURSE,
  REMOVE_USER_CERTIFICATIONS,
  LOAD_USER_BADGES,
  CLAIM_USER_BADGE,
  CLAIM_EVENT_PENDING_BADGE,
  REJECT_EVENT_PENDING_BADGE,
  CLAIM_PENDING_AWARD,
  REJECT_PENDING_AWARD,
  LOAD_COURSE_BADGE_LIST,
  CLAIM_COURSE_BADGE,
  REJECT_COURSE_BADGE,
  SUPPRESS_COURSE_BADGE,
  SUPPRESS_COUPON,
  LOAD_USER_PENDING_BADGES,
  CLAIM_ASSESSOR_BADGE,
  REJECT_ASSESSOR_BADGE,
  CLAIM_HONORARY_FELLOW_BADGE,
  REJECT_HONORARY_FELLOW_BADGE,
  DOWNLOAD_COURSE_DIPLOMA,
  LOAD_USER_PENDING_CERTIFICATIONS,
  CLAIM_USER_CERTIFICATE,
} from 'store/constants';
import { parseBoxesList, parseParams } from 'helpers';

function* editProfile({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(userService.updateProfile, payload);
    if (response.ok) {
      const { data, message } = yield response.json();

      const user = data.length ? data[0] : null;
      yield put(setUserCreator(user));
      yield put(setEditProfileErrorsCreator({}));
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      yield put(
        setNewAlertCreator({
          type: 'editProfile',
          status: 'success',
          text: message,
        }),
      );
    }
  } catch (error) {
    const { errors } = yield error.response.json();
    if (errors && Object.keys(errors).length) {
      yield put(
        setEditProfileErrorsCreator(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        ),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[POST] edit profile error: ', error);
  }
}

function* editPassword({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(userService.updatePassword, payload);
    if (response.ok) {
      const { message } = yield response.json();

      yield call(history.push, '/user/profile/edit');
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      yield put(
        setNewAlertCreator({
          type: 'editProfile',
          status: 'success',
          text: message,
        }),
      );
      yield put(setEditPasswordErrorsCreator({}));
    }
  } catch (error) {
    const { errors } = yield error.response.json();

    if (errors && Object.keys(errors).length) {
      yield put(
        setEditPasswordErrorsCreator(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        ),
      );
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[POST] edit password error: ', error);
  }
}

function* updateEmail({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(userService.updateUserEmail, payload);
    if (response.ok) {
      const {
        results: {
          result: { token },
        },
      } = yield response.json();

      yield window.localStorage.setItem('token', token);
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      window.location.reload();
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[POST] update email error: ', error);
  }
}

function* loadProfile() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(userService.getProfile);
    if (response.ok) {
      const profile = yield response.json();
      console.log(profile);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] user profile error: ', error);
  }
}

function* deleteProfile({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const validateResponse = yield call(userService.validateDeleteUserProfile, payload);
    if (validateResponse.ok) {
      const deleteResponse = yield call(userService.deleteUserProfile, payload);
      if (deleteResponse.ok) {
        yield put(setUserCreator({}));
        window.localStorage.removeItem('token');
        history.push('/auth/login');
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    const { errors } = yield error.response.json();

    if (errors.system) {
      yield put(
        setNewAlertCreator({
          type: 'deleteAccount',
          status: 'error',
          list: errors.system,
        }),
      );
    }

    if (errors.acceptDelete && errors.acceptDelete.length) {
      yield put(setDeleteProfileErrorsCreator({ acceptDelete: errors.acceptDelete[0] }));
    }

    console.log('[POST] delete profile error: ', error);
  }
}

function* logoutUser({ history }) {
  yield call([window.localStorage, 'removeItem'], 'token');
  yield call([window.localStorage, 'removeItem'], 'currentOrganization');
  yield put(resetAppCreator());
  yield put(checkTokenCreator(history));
}

function* loadNotifications() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(userService.getNotifications);
    if (response.ok) {
      const {
        results: {
          result: [notifications],
        },
      } = yield response.json();

      const notificationsGrouped = chain(notifications)
        .groupBy((notification) => moment(notification.createdAt).format('DD/MM/YYYY'))
        .map((items, date) => ({
          date,
          items,
        }))
        .value();

      yield put(setNotificationsCreator(notificationsGrouped));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] notifications error: ', error);
  }
}

function* removeNotification({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    yield call(userService.removeNotification, payload);
    yield put(loadNotificationsCreator());
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'notifications',
        status: 'success',
        text: i18next.t('page.notifications.removeNotificationAlert'),
      }),
    );
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[DELETE] remove notification error: ', error);
  }
}

function* removeAllNotifications() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    yield call(userService.removeAllNotifications);
    yield put(loadNotificationsCreator());
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[DELETE] remove all notifications error: ', error);
  }
}

function* loadUserCertifications() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(userService.getCertifications);
    if (response.ok) {
      const {
        results: {
          result: { entity: certifications },
        },
      } = yield response.json();

      const certificationsFiltered = certifications.map((item) => {
        const {
          id,
          certificateId,
          issueDate,
          validUntil,
          memberAssociation,
          level,
          levelDomain,
          ownerName,
        } = item;

        const levelName = get(level, 'name') || '';

        return {
          id,
          certificateId,
          name: levelDomain,
          ownerName,
          awardedDate: moment(issueDate).format('DD/MM/YYYY'),
          validityDate: moment(validUntil).format('DD/MM/YYYY'),
          ma: {
            id: get(memberAssociation, 'id') || null,
            name: get(memberAssociation, 'name') || '',
          },
          level: {
            name: levelName,
          },
        };
      });

      yield put(setUserCertificationsCreator(certificationsFiltered));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] user certification error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* removeUserCertifications({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    yield call(userService.removeCertification, payload);
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(loadUserCertificationsCreator());
    yield put(
      setNewAlertCreator({
        type: 'certificates',
        status: 'success',
        text: i18next.t('global.alert.success'),
      }),
    );
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    if (error.response) {
      const { errors } = yield error.response.json();
      yield put(
        setNewAlertCreator({
          type: 'certificates',
          status: 'error',
          text: errors,
        }),
      );
    }
    console.log('[DELETE] remove user certification error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* getUserAddListCertificates({ payload, asUser }) {
  yield put(setNewLoadingCreator({ type: 'ADD_USER_CERTS' }));

  if (payload) yield put(setUserAddListFiltrationCreator(payload));

  const { firstName, lastName } = yield select(({ registration, user }) =>
    asUser ? user.user : registration.user,
  );
  const certUserId = yield select(({ user }) => user.user.id);
  const filtration = yield select(({ user }) => user.add.filtration);

  const params = {
    firstName,
    lastName,

    ...filtration,

    awardedDate: filtration.awardedDate
      ? moment(filtration.awardedDate).format('YYYY-MM-DD').toString()
      : null,
    validityDate: filtration.validityDate
      ? moment(filtration.validityDate).format('YYYY-MM-DD').toString()
      : null,
    country: filtration.country ? filtration.country.value : null,
    userId: certUserId,
  };

  try {
    const response = yield call(userService.getAddListCertificates, params);
    if (response.ok) {
      const {
        results: { result: certs },
      } = yield response.json();
      yield put(setUserAddListCertificatesCreator(certs));
    }
    yield put(removeLoadingCreator({ type: 'ADD_USER_CERTS' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'ADD_USER_CERTS' }));
    console.error('[GET] registration certs error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'ADD_USER_CERTS' }));
    }
  }
}

function* addUserCertificates({ history }) {
  try {
    const certs = yield select(({ user }) => user.add.selectedCertificates);
    const params = new FormData();
    certs.forEach((item, index) => {
      params.append(`certificatesId[${index}]`, item);
    });

    const response = yield call(userService.addCertificates, params);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      yield put(
        setNewAlertCreator({
          type: 'certificates',
          status: 'success',
          text: get(result, [0]) || i18next.t('global.alert.success'),
        }),
      );
      yield put(setUserAddListCertificatesCreator([]));
      history.push('/user/certification');
    }
    yield put(removeLoadingCreator({ type: 'ADD_USER_CERTS' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'ADD_USER_CERTS' }));
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'ADD_USER_CERTS' }));
    }
  }
}

function* loadDashboard() {
  function* parseResponse(response, type) {
    if (response.ok) {
      const {
        results: {
          result: { entity },
        },
      } = yield response.json();

      return entity[type].results;
    }

    return [];
  }

  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    yield put(loadUserBadgesCreator());

    const [coursesResponse, eventsResponse] = yield all([
      call(userService.getDashboardCourses),
      call(userService.getDashboardEvents),
    ]);

    const courses = parseBoxesList('courses', yield parseResponse(coursesResponse, 'courses'));
    const events = parseBoxesList('events', yield parseResponse(eventsResponse, 'events'));

    yield put(setUserDashboardCreator({ courses, events }));

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] user dashboard error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* applyUserToCourse({ payload: id }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(userService.applyUserToCourse, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'courses',
          status: 'success',
          text: i18next.t('alerts.applyUserToCourseSuccess'),
        }),
      );
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (error.response) {
      const { errors } = yield error.response.json();

      if (!isEmpty(errors)) {
        yield put(
          setNewAlertCreator({
            type: 'courses',
            status: 'error',
            text: errors[0],
          }),
        );
      }
    }

    console.log('[GET] apply user to course error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* declineUserFromCourse({ payload: id }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(userService.declineUserFromCourse, id);
    if (response.ok) {
      yield put(loadUserCoursesCreator('upcoming'));
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.declineUserFromCourseSuccess'),
        }),
      );
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (error.response) {
      const { errors } = yield error.response.json();

      if (!isEmpty(errors)) {
        yield put(
          setNewAlertCreator({
            type: 'courses',
            status: 'error',
            text: errors[0],
          }),
        );
      }
    }

    console.log('[GET] decline user from course error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadProfileCompletedCourses() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(userService.getProfileCompletedCourses);
    if (response.ok) {
      const {
        results: {
          result: { entity: items },
        },
      } = yield response.json();

      const courses = parseBoxesList('courses', items);

      yield put(setProfileCompletedCoursesCreator(courses));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] profile completed courses error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadCoursesBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(userService.getCoursesBar);
    if (response.ok) {
      const {
        results: {
          result: { entity: bar },
        },
      } = yield response.json();
      yield put(
        setUserCoursesBarCreator({
          completed: bar.completed || 0,
          upcoming: bar.upcoming || 0,
          pending: bar.pending || 0,
        }),
      );
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] user courses bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function loadCourses(view) {
  switch (view.listType) {
    case 'completed':
      return loadCoursesList('completed', 'LOAD_USER_COURSES_COMPLETED');
    case 'upcoming':
      return loadCoursesList('upcoming', 'LOAD_USER_COURSES_UPCOMING');
    case 'pending':
      return loadCoursesList('pending', 'LOAD_USER_COURSES_PENDING');
    default:
      return null;
  }
}

function* loadCoursesList(type, loading, apiName = type) {
  try {
    yield put(setNewLoadingCreator({ type: loading }));

    const [page, perPage] = yield select(({ user }) => [
      user.courses[type].page,
      user.courses[type].perPage,
    ]);

    const params = parseParams({
      maxResults: perPage,
      page,
    });

    const response = yield call(userService.getCourses, apiName, params);
    if (response.ok) {
      const {
        results: {
          result: {
            entity: { items, pageCount },
          },
        },
      } = yield response.json();

      const data = parseBoxesList('courses', items);

      yield put(setUserCoursesCreator(type, data));
      yield put(setUserCoursesPagesCreator(type, pageCount || 1));
      yield put(loadUserCoursesBarCreator({ withoutLoader: true }));
      if (type === 'completed') {
        yield put(loadCourseBadgeListCreator());
      }
    }

    yield put(removeLoadingCreator({ type: loading }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: loading }));
    console.log(`[GET] user courses ${type} error: `, error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: loading }));
  }
}

function* loadUserBadges() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(userService.getBadges);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      const parser = (type) => (n) => ({
        id: n.badgeId,
        title: n.badgeTemplateName,
        image: n.badgeTemplateImage,
        url: n.url,
        shareUrl:
          n.url !== null ? `${new URL(n.url).origin}/earner/earned/share/${n.badgeId}` : null,
        type,
      });

      const badges = {
        course: [
          ...map(get(result, 'pending.course_and_programmes_badges'), parser('pending')),
          ...map(get(result, 'accepted.course_and_programmes_badges'), parser('accepted')),
        ],
        certificate: [
          ...map(get(result, 'pending.certificate_badges'), parser('pending')),
          ...map(get(result, 'accepted.certificate_badges'), parser('accepted')),
        ],
        event: [
          ...map(get(result, 'pending.event_badges'), parser('pending')),
          ...map(get(result, 'accepted.event_badges'), parser('accepted')),
        ],
        awards: [
          ...map(get(result, 'pending.awards_badges'), parser('pending')),
          ...map(get(result, 'accepted.awards_badges'), parser('accepted')),
        ],
        assessors: [
          ...map(get(result, 'pending.assessor_badges'), parser('pending')),
          ...map(get(result, 'accepted.assessor_badges'), parser('accepted')),
        ],
        honoraryFellow: [
          ...map(get(result, 'pending.honorary_fellow_badges'), parser('pending')),
          ...map(get(result, 'accepted.honorary_fellow_badges'), parser('accepted')),
        ],
        pebProfessionals: [
          ...map(get(result, 'pending.peb_professional_badges'), parser('pending')),
          ...map(get(result, 'accepted.peb_professional_badges'), parser('accepted')),
        ],
        kids: [
          ...map(get(result, 'pending.kids_badges'), parser('pending')),
          ...map(get(result, 'accepted.kids_badges'), parser('accepted')),
        ],
        sig: [
          ...map(get(result, 'pending.sig_badges'), parser('pending')),
          ...map(get(result, 'accepted.sig_badges'), parser('accepted')),
        ],
        research: [
          ...map(get(result, 'pending.research_fellow_badges'), parser('pending')),
          ...map(get(result, 'accepted.research_fellow_badges'), parser('accepted')),
        ],
      };

      yield put(setUserBadgesCreator(badges));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] user badges error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* claimUserBadge({ payload, cb }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(userService.claimBadge, {
      badgeId: payload.id,
    });

    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      cb(result);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[POST] claim user badge error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadCourseBadgeList() {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_USER_COURSES_COMPLETED' }));
    const response = yield call(userService.loadCourseBadgeList);
    if (response.ok) {
      const {
        results: {
          result: { items },
        },
      } = yield response.json();

      if (!isEmpty(items)) {
        yield put(setCourseBadgeListCreator(items));
      }
    }
    yield put(removeLoadingCreator({ type: 'LOAD_USER_COURSES_COMPLETED' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_USER_COURSES_COMPLETED' }));
    console.error('[GET] load course badge list error: ', error);
  }
}

function* loadPendingUserBadges() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(userService.getUserPendingBadges);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      let totalItems = 0;

      Object.keys(result).forEach((item) => {
        totalItems += result[item].totalItems;
      });
      yield put(setUserPendingBadgesCreator(result));
      if (totalItems !== 0) {
        yield put(setOpenPendingBadgesCreator(true));
      }
    }
  } catch (error) {
    console.error('[GET] check pending badges error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(loadUserPendingCertificatesCreator());
  }
}

function* claimPendingEventBadge({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.claimEventBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.claimPendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorClaimBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] claim pending course badge error: ', error);
  }
}

function* rejectPendingEventBadge({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.rejectEventBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.declinePendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorDeclineBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] reject pending event badge error: ', error);
  }
}

function* claimPendingAward({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.claimAwardBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.claimPendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorClaimBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] claim pending course badge error: ', error);
  }
}

function* rejectPendingAward({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.rejectAwardBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.declinePendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorDeclineBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] reject pending event badge error: ', error);
  }
}

function* acceptCourseBadge({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.claimCourseBadge, { id: payload.id });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.claimPendingEventBadge'),
        }),
      );
      yield put(downloadCourseDiplomaCreator(payload));
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorClaimBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] claim pending course badge error: ', error);
  }
}

function* rejectCourseBadge({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.rejectCourseBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.declinePendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorDeclineBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] reject pending event badge error: ', error);
  }
}

function* suppressCoupon({ payload }) {
  try {
    const response = yield call(userService.suppressCoupon, { suppress: payload });
    if (response.ok) {
      if (!payload) {
        yield put(
          setNewAlertCreator({
            type: 'user',
            status: 'success',
            text: i18next.t('alerts.suppressPendingCoupon'),
          }),
        );
      }
    }
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorSuppressBadge'),
      }),
    );
    console.error('[GET] suppress pending badge error: ', error);
  }
}

function* suppressCourseBadge({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.supressCourseBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.suppressPendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorSuppressBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] suppress pending badge error: ', error);
  }
}

function* claimBadgeAssessor({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.claimAssessorBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.claimPendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorClaimBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] claim pending course badge error: ', error);
  }
}

function* rejectBadgeAssessor({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.rejectAssessorBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.declinePendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorDeclineBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] reject pending event badge error: ', error);
  }
}

function* claimBadgeHonoraryFellow({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.claimHonoraryFellowBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.claimPendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorClaimBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] claim pending honorary fellow badge error: ', error);
  }
}

function* rejectBadgeHonoraryFellow({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const response = yield call(userService.rejectHonoraryFellowBadge, { id: payload });
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.declinePendingEventBadge'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorDeclineBadge'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
    console.error('[GET] reject pending honorary fellow badge error: ', error);
  }
}

function* downloadCourseDiploma({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_USER_COURSES_COMPLETED' }));
    const response = yield call(userService.downloadCourseDiploma, { id: payload.id });
    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Diploma-${payload.name || 'course'}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: i18next.t('alerts.downloadDiploma'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'LOAD_USER_COURSES_COMPLETED' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'user',
        status: 'error',
        text: i18next.t('alerts.errorDownloadDiploma'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'LOAD_USER_COURSES_COMPLETED' }));
    console.error('[GET] download diploma error: ', error);
  }
}

function* getUserNewCertificates() {
  yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

  const { firstName, lastName, email } = yield select(({ registration, user }) =>
    user && user.user ? user.user : registration.user,
  );
  const certUserId = yield select(({ user }) => user.user.id);

  const params = {
    firstName,
    lastName,
    email,
    userId: certUserId,
  };

  try {
    const response = yield call(userService.getListNewCertificates, params);
    if (response.ok) {
      const {
        results: { result: certs },
      } = yield response.json();
      const toSave = {
        totalItems: certs && certs.length ? certs.length : 0,
        items: certs,
      };
      yield put(setUserPendingCertificationsBadgesCreator(toSave));
    }
  } catch (error) {
    console.error('[GET] registration certs error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* claimUserNewCertificates({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'CLAIM_BADGE' }));
    const params = new FormData();
    params.append(`certificatesId[0]`, payload);

    const response = yield call(userService.addCertificates, params);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();

      yield put(
        setNewAlertCreator({
          type: 'user',
          status: 'success',
          text: get(result, [0]) || i18next.t('global.alert.success'),
        }),
      );
      yield put(setUserAddListCertificatesCreator([]));
    }
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'CLAIM_BADGE' }));
  }
}

export default function* watchUser() {
  yield takeLatest(LOAD_PROFILE, loadProfile);
  yield takeLatest(EDIT_PROFILE, editProfile);
  yield takeLatest(EDIT_PASSWORD, editPassword);
  yield takeLatest(UPDATE_USER_EMAIL, updateEmail);
  yield takeLatest(DELETE_PROFILE, deleteProfile);
  yield takeLatest(LOGOUT_USER, logoutUser);
  yield takeLatest(LOAD_NOTIFICATIONS, loadNotifications);
  yield takeLatest(REMOVE_NOTIFICATION, removeNotification);
  yield takeLatest(REMOVE_ALL_NOTIFICATIONS, removeAllNotifications);
  yield takeLatest(LOAD_USER_CERTIFICATIONS, loadUserCertifications);
  yield takeLatest(REMOVE_USER_CERTIFICATIONS, removeUserCertifications);
  yield takeLatest(LOAD_USER_ADD_LIST_CERTIFICATES, getUserAddListCertificates);
  yield takeLatest(ADD_USER_ADD_LIST_CERTIFICATES, addUserCertificates);
  yield takeLatest(LOAD_USER_DASHBOARD, loadDashboard);
  yield takeLatest(APPLY_USER_TO_COURSE, applyUserToCourse);
  yield takeLatest(DECLINE_USER_FROM_COURSE, declineUserFromCourse);
  yield takeLatest(LOAD_PROFILE_COMPLETED_COURSES, loadProfileCompletedCourses);
  yield takeLatest(LOAD_USER_COURSES_BAR, loadCoursesBar);
  yield takeLatest(LOAD_USER_COURSES, loadCourses);
  yield takeLatest(LOAD_USER_BADGES, loadUserBadges);
  yield takeLatest(CLAIM_USER_BADGE, claimUserBadge);
  yield takeLatest(CLAIM_EVENT_PENDING_BADGE, claimPendingEventBadge);
  yield takeLatest(REJECT_EVENT_PENDING_BADGE, rejectPendingEventBadge);
  yield takeLatest(CLAIM_PENDING_AWARD, claimPendingAward);
  yield takeLatest(REJECT_PENDING_AWARD, rejectPendingAward);
  yield takeLatest(LOAD_COURSE_BADGE_LIST, loadCourseBadgeList);
  yield takeLatest(CLAIM_COURSE_BADGE, acceptCourseBadge);
  yield takeLatest(REJECT_COURSE_BADGE, rejectCourseBadge);
  yield takeLatest(SUPPRESS_COURSE_BADGE, suppressCourseBadge);
  yield takeLatest(SUPPRESS_COUPON, suppressCoupon);
  yield takeLatest(LOAD_USER_PENDING_BADGES, loadPendingUserBadges);
  yield takeLatest(CLAIM_ASSESSOR_BADGE, claimBadgeAssessor);
  yield takeLatest(REJECT_ASSESSOR_BADGE, rejectBadgeAssessor);
  yield takeLatest(CLAIM_HONORARY_FELLOW_BADGE, claimBadgeHonoraryFellow);
  yield takeLatest(REJECT_HONORARY_FELLOW_BADGE, rejectBadgeHonoraryFellow);
  yield takeLatest(DOWNLOAD_COURSE_DIPLOMA, downloadCourseDiploma);
  yield takeLatest(LOAD_USER_PENDING_CERTIFICATIONS, getUserNewCertificates);
  yield takeLatest(CLAIM_USER_CERTIFICATE, claimUserNewCertificates);
}
