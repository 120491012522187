import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  UserList,
  IconText,
  Map,
  Breadcrumbs,
  ProfileHeader,
  BoxList,
  PrivateComponent,
  UserDetails,
} from 'components';
import noAvatar from 'assets/images/no_avatar.jpg';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { globalPropTypes, newLineToBr, getAvatar } from '../../../../helpers';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.profile',
    url: '/ipma/profile/view',
  },
];

const View = ({ profile, usersRole, upcomingEvents }) => {
  const { t } = useTranslation();
  if (!profile) return null;
  const parseAddress = () => {
    let address = '';
    if (profile.address.line1) {
      address += profile.address.line1;
    }

    if (profile.address.line2) {
      address += `, ${profile.address.line2}`;
    }

    if (profile.address.city) {
      address += `, ${profile.address.city}`;
    }

    if (profile.address.postCode) {
      address += `, ${profile.address.postCode}`;
    }

    if (profile.address.country && profile.address.country.label) {
      address += `, ${profile.address.country.label}`;
    }

    return address;
  };

  const renderLocation = () => {
    if (profile.address) {
      if (profile.address.lat && profile.address.lang) {
        return (
          <>
            <Padding all="20">
              <IconText top icon="marker" text={parseAddress()} />
            </Padding>
            <Map lat={profile.address.lat} lng={profile.address.lang} />
          </>
        );
      }
      return (
        <Padding all="20">
          <IconText top icon="marker" text={parseAddress()} />
        </Padding>
      );
    }
    return null;
  };

  return (
    <>
      <PageContent>
        <Container>
          <Breadcrumbs items={breadcrumbs} />
          <Margin bottom="24">
            {profile.name ? (
              <ProfileHeader
                title={profile.name}
                image={profile.photo ? profile.photo : noAvatar}
                actions={({ Button }) => (
                  <>
                    <PrivateComponent
                      permissions={{
                        roles: ['ipma_edit_profile'],
                      }}
                    >
                      <Margin bottom={{ xs: 16 }}>
                        <Button as={Link} to="/ipma/profile/edit">
                          {t('page.ipma.profile.editProfileButton')}
                        </Button>
                      </Margin>
                    </PrivateComponent>
                    <Button as={Link} to="/ipma/people">
                      {t('page.ipma.profile.manageMembers')}
                    </Button>
                  </>
                )}
              />
            ) : (
              <UserDetails thumbnail={getAvatar(profile.photo, 'ipma')} />
            )}
          </Margin>
          <PrivateComponent
            permissions={{
              roles: ['ipma_profile'],
            }}
          >
            <Row>
              <Col size={{ xl: 2 / 3 }}>
                <Margin bottom={{ xs: 32, xl: 48 }}>
                  <Cms>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: newLineToBr(profile.fullDescription),
                      }}
                    />
                  </Cms>
                </Margin>

                <Margin bottom={{ xs: 32, xl: 48 }}>
                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.ipma.profile.eventsTitle')}
                    </Heading>
                    <Link to="/events" className="link uppercase">
                      {t('global.seeAll')}
                    </Link>
                  </HeadingWrapper>
                  <BoxList
                    type="event"
                    items={upcomingEvents}
                    alert={t('global.noResultsEvents')}
                  />
                </Margin>
              </Col>

              <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
                <div className="border-xl">
                  <Padding all="20" className="bg-gray-100">
                    <Margin bottom="16">
                      <Heading as="h3" size="h6">
                        {t('page.ipma.profile.contactUs')}
                      </Heading>
                    </Margin>
                    {profile.telephone ? (
                      <Margin bottom="12">
                        <a href={`tel:${profile.telephone}`}>{profile.telephone}</a>
                      </Margin>
                    ) : null}
                    {profile.email ? (
                      <Margin bottom="12">
                        <a href={`mailto:${profile.email}`} className="link link--16 word-break">
                          {profile.email}
                        </a>
                      </Margin>
                    ) : null}
                    {profile.website ? (
                      <a href={profile.website} className="link link--16 word-break">
                        {profile.website}
                      </a>
                    ) : null}
                  </Padding>
                  {renderLocation()}
                </div>
                {usersRole && !!usersRole.length ? (
                  <Margin top="32">
                    {usersRole.map((item) => (
                      <Margin top="32">
                        <UserList title={`${item.name}:`} users={item.items} />
                      </Margin>
                    ))}
                  </Margin>
                ) : null}
              </Margin>
            </Row>
          </PrivateComponent>
        </Container>
      </PageContent>
    </>
  );
};

View.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
      lat: PropTypes.number,
      lang: PropTypes.number,
    }),
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
  }),
  usersRole: globalPropTypes.UserListPropsTypes,
  upcomingEvents: PropTypes.arrayOf(PropTypes.shape({})),
};
View.defaultProps = {
  profile: null,
  usersRole: [],
  upcomingEvents: [],
};

const mapStateToProps = ({ ipma }) => ({
  profile: ipma.profile.data,
  usersRole: ipma.profile.usersRole,
  upcomingEvents: ipma.profile.upcomingEvents,
});

export default connect(mapStateToProps, null)(View);
