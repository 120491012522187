import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { py, mt } from 'styled-components-spacing';

export const Slide = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      height: 200px;
      background-image: url(${({ img }) => img && img});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      ${breakpoint('sm')`
        ${css`
          aspect-ratio: 16/7;
          height: auto;
        `}
      `}
      @media (min-width: 1366px) {
        max-height: 700px;
      }
      @media (min-width: 1920px) {
        max-height: 900px;
      }
    `}
`;

export const Arrow = styled.button`
  ${({ theme }) =>
    theme &&
    css`
      display: none;

      ${breakpoint('xl')`
        ${css`
          display: block;
          width: 80px;
          height: 80px;
          border-radius: 2px;
          background-color: rgba(21, 35, 56, 0.25);
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate3d(0, -50%, 0);
          z-index: 1;

          > div > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          svg {
            width: 32px;
            height: 32px;
            fill: #fff;
            pointer-events: none;
          }

          ${({ next }) =>
            next &&
            css`
              left: auto;
              right: 0;
            `}
        `}
      `}
    `}
`;

export const SlideContent = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${py(100)};

      text-align: center;

      ${breakpoint('xl')`
        ${css`
          max-width: 50%;
          text-align: left;
        `}
      `}
    `}
`;

export const SlideSubHeading = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(8)};

      font-size: ${theme.font.size.pixel('32')};
      font-weight: ${theme.font.weight.bold};
      color: #fff;

      ${breakpoint('xl')`
        ${css`
          font-size: 60px;
        `}
      `}
    `}
`;
