import { all, takeLatest, call, put, cancelled, select, debounce } from 'redux-saga/effects';

import * as ycService from 'api/services/yc';
import {
  LOAD_YC_PROFILE,
  UPDATE_YC_PROFILE,
  LOAD_YC_PROFILE_UPCOMING_EVENTS,
  LOAD_YC_DASHBOARD,
  LOAD_YC_PEOPLE_BAR,
  LOAD_YC_PEOPLE_USERS,
  LOAD_YC_PEOPLE_LIST,
  LOAD_YC_MEMBERS_LIST,
  CHANGE_YC_PEOPLE_USERS_ROLE,
  DELETE_YC_PEOPLE_USER,
  LOAD_YC_PEOPLE_ADD_LIST_USERS,
  ADD_YC_PEOPLE_USERS,
  ACCEPT_YC_PEOPLE_LIST_USER,
  DECLINE_YC_PEOPLE_LIST_USER,
  REMOVE_YC_PEOPLE_LIST_USER,
  LOAD_YC_EVENTS_BAR,
  LOAD_YC_EVENTS,
  CREATE_YC_EVENT,
  LOAD_YC_EVENT,
  EDIT_YC_EVENT,
  CANCEL_YC_EVENT,
  REOPEN_YC_EVENT,
  DUPLICATE_YC_EVENT,
  DOWNLOAD_YC_PEOPLE_REPORT,
  CREATE_PROJECT,
  LOAD_YC_PROJECTS_BAR,
  LOAD_YC_PROJECTS,
  LOAD_YC_PROJECT,
  EDIT_YC_PROJECT,
  CREATE_PROJECT_ROLE,
  EDIT_YC_PROJECT_ROLE,
  LOAD_YC_PROJECT_ROLES_BAR,
  LOAD_YC_PROJECT_ROLES,
  LOAD_YC_PROJECT_ROLE,
  CREATE_PROJECT_ROLE_APPLICATION,
  LOAD_YC_PROJECT_ROLE_APPLICATION_BAR,
  LOAD_YC_PROJECT_ROLE_APPLICATIONS,
  ACCEPT_YC_PROJECT_ROLE_APPLICATION,
  DECLINE_YC_PROJECT_ROLE_APPLICATION,
  PUT_ON_HOLD_YC_PROJECT_ROLE_APPLICATION,
  DOWNLOAD_YC_APPLICATIONS,
  REMOVE_YC_PROJECT,
  REMOVE_YC_PROJECT_ROLE,
} from 'store/constants';
import {
  setNewLoadingCreator,
  removeLoadingCreator,
  setNewAlertCreator,
  setYcProfileCreator,
  loadYcProfileCreator,
  setUpdateYcProfileErrorsCreator,
  setYcProfileUsersRoleCreator,
  setYcDashboardCreator,
  loadYcProfileUpcomingEventsCreator,
  setYcProfileUpcomingEventsCreator,
  setYcPeopleBarCreator,
  loadYcPeopleBarCreator,
  setYcPeopleUsersCreator,
  loadYcPeopleUsersCreator,
  setYcPeopleListCreator,
  setYcPeopleAddListUsersCreator,
  loadYcPeopleListCreator,
  setYcEventsBarCreator,
  setYcEventsStatusCreator,
  loadYcEventsBarCreator,
  setYcEventsCreator,
  loadYcEventsCreator,
  setYcProjectsBarCreator,
  setYcProjectsStatusCreator,
  loadYcProjectsBarCreator,
  setYcProjectsCreator,
  setYcProjectCreator,
  setYcProjectRolesBarCreator,
  setYcProjectRolesCreator,
  loadYcProjectRolesBarCreator,
  setYcProjectRolesStatusCreator,
  setYcProjectRoleApplicationBarCreator,
  loadYcProjectRoleApplicationBarCreator,
  setYcProjectRoleApplicationsCreator,
  setYcProjectRoleCreator,
  loadYcProjectRoleApplicationsCreator,
  setYcProjectRoleApplicationStatusCreator,
} from 'store/actionsCreators';

import { get } from 'lodash';
import { parseUserList, parseBoxesList } from 'helpers';
import i18next from 'i18next';

function* loadDashboard() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(ycService.getDashboard);
    if (response.ok) {
      const {
        results: { result: data },
      } = yield response.json();

      const dashboard = {
        name: get(data, 'yc.name') || '',
        phone: get(data, 'yc.telephone') || '',
        email: get(data, 'yc.email') || '',
        website: get(data, 'yc.website') || '',
        photo: get(data, 'yc.photo') || '',
        members: String(get(data, 'members') || 0),
        chartPeople: get(data, 'chartPeople') || [],
        chartCertifiedMembers: get(data, 'chartCertificate') || [],
        chartEventsAndTraining: get(data, 'chartEventsTrainings') || [],
      };

      yield put(setYcDashboardCreator(dashboard));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc dashboard error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadProfile() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getProfile);
    if (response.ok) {
      const {
        results: {
          result: { yc: profile, userRoleOfYc: usersRole, users: ycUsers },
        },
      } = yield response.json();

      yield all([
        put(
          setYcProfileCreator({
            ...profile,
            country: {
              label: profile.country ? profile.country.name : '',
              value: profile.country ? profile.country.id : '',
            },
            address: profile.address
              ? {
                  ...profile.address,
                  country: {
                    label: profile.address.country ? profile.address.country.name : '',
                    value: profile.address.country ? profile.address.country.id : '',
                  },
                }
              : {},
            contactPerson: profile.contactPerson
              ? {
                  ...profile.contactPerson,
                  value: profile.contactPerson.id,
                  label: `${profile.contactPerson.firstName} ${profile.contactPerson.lastName}`,
                }
              : null,
            ycUsers: ycUsers.map((item) => ({
              label: `${item.firstName} ${item.lastName}`,
              value: item.id,
            })),
          }),
        ),
        put(setYcProfileUsersRoleCreator(parseUserList(usersRole))),
        put(loadYcProfileUpcomingEventsCreator()),
      ]);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc profile error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadProfileUpcomingEvents() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(ycService.getProfileUpcomingEvents);
    if (response.ok) {
      const {
        results: { result: items },
      } = yield response.json();

      const events = parseBoxesList('events', items);

      yield put(setYcProfileUpcomingEventsCreator(events));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc profile upcoming events error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* updateProfile({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.updateProfile, payload);
    if (response.ok) {
      yield put(loadYcProfileCreator());
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.update'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      yield put(
        setUpdateYcProfileErrorsCreator(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        ),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] update yc profile error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(
        setYcPeopleBarCreator({
          users: bar.ycUserList ? bar.ycUserList : 0,
          people: bar.people ? bar.people : 0,
          members: bar.members ? bar.members : 0,
        }),
      );
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc people bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadUsers() {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const { page, perPage, sort, filters } = yield select(({ yc }) => yc.people.users);
    const response = yield call(ycService.getUsers, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
      ...filters,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadYcPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setYcPeopleUsersCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* loadPeopleYC() {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const { page, perPage, sort, filters } = yield select(({ yc }) => yc.people.list);
    const response = yield call(ycService.getPeopleYcList, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
      ...filters,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadYcPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setYcPeopleListCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc people organisations error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* loadMembersYc() {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const { page, perPage, sort, filters } = yield select(({ yc }) => yc.people.list);
    filters.membership = 'yes';
    const response = yield call(ycService.getPeopleYcList, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
      ...filters,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadYcPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setYcPeopleListCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc people organisations error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* changeUserRole({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.changeUserRole, payload);
    if (response.ok) {
      yield put(loadYcPeopleUsersCreator());
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* removeUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.removeUser, payload);
    if (response.ok) {
      yield put(loadYcPeopleUsersCreator());
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* loadAddListUsers({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.getAddListUsers, payload);
    if (response.ok) {
      const {
        results: { result: users },
      } = yield response.json();
      yield put(setYcPeopleAddListUsersCreator(users));
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc people add list users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* addUsers({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.addUsers, payload);
    if (response.ok) {
      yield put(setYcPeopleAddListUsersCreator([]));
      history.push('/yc/people/');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors) {
        yield put(loadYcPeopleUsersCreator());
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            text: i18next.t('global.alert.error'),
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc people add list users error: ', error);
  }
}

function* acceptListUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.acceptListUser, payload);
    if (response.ok) {
      yield put(loadYcPeopleListCreator());
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[POST] accept yc list user error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* declineListUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.declineListUser, payload);
    if (response.ok) {
      yield put(loadYcPeopleListCreator());
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[POST] decline yc list user error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* removeListUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.removeListUser, payload);
    if (response.ok) {
      yield put(loadYcPeopleListCreator());
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[POST] remove yc list user error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* loadEventsBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getEventsBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setYcEventsBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc events bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadEvents({ payload }) {
  try {
    if (payload && payload.status) yield put(setYcEventsStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const { page, perPage, status } = yield select(({ yc }) => yc.events.list);
    const response = yield call(ycService.getEvents, {
      page,
      maxResult: perPage,
      status,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadYcEventsBarCreator({ withoutLoader: true }));
      yield put(
        setYcEventsCreator({
          items: parseBoxesList('events', result.items),
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* createEvent({ payload, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.createEvent, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/yc/events');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create yc event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadEvent({ payload, setEvent }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getEvent, payload);
    if (response.ok) {
      const {
        results: { result: event },
      } = yield response.json();
      yield setEvent({
        ...event,
        dateFrom: event.dateFrom ? new Date(event.dateFrom) : null,
        dateTo: event.dateTo ? new Date(event.dateTo) : null,
        mainLanguage: event.mainLanguage
          ? { label: event.mainLanguage.name, value: event.mainLanguage.id }
          : null,
        secondaryLanguage: event.secondaryLanguage
          ? {
              label: event.secondaryLanguage.name,
              value: event.secondaryLanguage.id,
            }
          : null,
        timezone: event.timezone ? { label: event.timezone.gmt, value: event.timezone.id } : null,
        currency: event.currency ? { label: event.currency.name, value: event.currency.id } : null,
        modeOfDelivery: event.modeOfDelivery ? event.modeOfDelivery.id : null,
        address: {
          ...event.address,
          country: event.address.country
            ? { label: event.address.country.name, value: event.address.country.id }
            : null,
        },
        tags: event.tags.map((item) => item.id),
        imageFile: event.photo || '',
        cpdRoles:
          event.cpdRoleEventCourses && event.cpdRoleEventCourses.length > 0
            ? event.cpdRoleEventCourses.map((el) => ({
                role: el.cpdRole,
                points: el.points,
              }))
            : [
                {
                  role: null,
                  points: null,
                },
              ],
      });
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get yc event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* editEvent({ payload, id, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.editEvent, payload, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/yc/events');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] yc cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* cancelEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.cancelEvent, payload);
    if (response.ok) {
      yield put(loadYcEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] yc cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* reopenEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.reopenEvent, payload);
    if (response.ok) {
      yield put(loadYcEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] yc cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* duplicateEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const body = new FormData();
    body.append('eventId', payload);

    const response = yield call(ycService.duplicateEvent, body);
    if (response.ok) {
      yield put(loadYcEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] yc duplicate event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* downloadYcPeopleReport({ payload }) {
  try {
    let apiToCall = ycService.downlodYcPeopleReport;
    let xlsxTitle = 'people-report';
    switch (payload) {
      case 'users':
        xlsxTitle = 'users-report';
        apiToCall = ycService.downlodYcUsersReport;
        break;
      case 'people':
        xlsxTitle = 'people-report';
        apiToCall = ycService.downlodYcPeopleReport;
        break;
      default:
        break;
    }

    const response = yield call(apiToCall);
    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${xlsxTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );

    console.log('[GET] yc people report error: ', error);
  }
}

function* createProject({ payload, history, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.createProject, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/yc/projects');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create project error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadProjectsBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getProjectsBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setYcProjectsBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc projects bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadProjects({ payload }) {
  try {
    if (payload && payload.status) yield put(setYcProjectsStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const { page, perPage, status } = yield select(({ yc }) => yc.projects.list);
    const response = yield call(ycService.getProjects, {
      page,
      maxResult: perPage,
      status,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadYcProjectsBarCreator({ withoutLoader: true }));
      yield put(
        setYcProjectsCreator({
          items: parseBoxesList('project', result.items),
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc projects list error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* loadProject({ payload, setProject = null }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getProject, payload);
    if (response.ok) {
      const {
        results: { result: projectArray },
      } = yield response.json();
      const project = projectArray[0] || {};
      const projectObj = {
        name: project.name,
        imageFile: project.banner || '',
        dateFrom: project.dateFrom ? new Date(project.dateFrom) : null,
        dateTo: project.dateTo ? new Date(project.dateTo) : null,
        mainLanguage: project.mainLanguage
          ? { label: project.mainLanguage.name, value: project.mainLanguage.id }
          : null,
        secondaryLanguage: project.secondaryLanguage
          ? {
              label: project.secondaryLanguage.name,
              value: project.secondaryLanguage.id,
            }
          : null,
        type: project.type,
        'location.line1': project.location.line1,
        'location.line2': project.location.line2,
        'location.postCode': project.location.postCode,
        'location.city': project.location.city,
        'location.country': project.location.country
          ? { label: project.location.country.name, value: project.location.country.id }
          : null,
        customUrl: project.customUrl,
        shortDescription: project.shortDescription,
        description: project.description,
        isAlwaysOngoing: project.isAlwaysOngoing,
        errors: {},
      };
      yield put(setYcProjectCreator(projectObj));
      if (setProject !== null) {
        setProject(projectObj, true);
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get yc project error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* editProject({ payload, id, history, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.editProject, payload, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/yc/projects');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] yc cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* createProjectRole({ payload, history, projectId, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.createProjectRole, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push(`/yc/project-roles/${projectId}`);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create project role error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadProjectRolesBar({ payload, id }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getProjectRolesBar, id);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setYcProjectRolesBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc project roles bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadProjectRoles({ payload, id }) {
  try {
    if (payload && payload.status) yield put(setYcProjectRolesStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const { page, perPage, status } = yield select(({ yc }) => yc.projectRoles.list);
    const response = yield call(
      ycService.getProjectRoles,
      {
        page,
        maxResult: perPage,
        status,
      },
      id,
    );
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadYcProjectRolesBarCreator({ withoutLoader: true }, id));
      yield put(
        setYcProjectRolesCreator({
          items: parseBoxesList('project-role', result.items),
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc projects list error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* loadProjectRole({ payload, setProjectRole = null }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getProjectRole, payload);
    if (response.ok) {
      const {
        results: { result: projectRoleArray },
      } = yield response.json();
      const projectRole = projectRoleArray[0] || {};
      if (setProjectRole) {
        setProjectRole(
          {
            name: projectRole.name,
            sameDateRange: projectRole.sameDateRange ? 1 : 2,
            dateFrom: projectRole.dateFrom ? new Date(projectRole.dateFrom) : null,
            dateTo: projectRole.dateTo ? new Date(projectRole.dateTo) : null,
            type: projectRole.type,
            occupation: { label: projectRole.occupation, value: projectRole.occupation },
            careerLevel: projectRole.careerLevel
              ? { label: projectRole.careerLevel.value, value: projectRole.careerLevel.id }
              : null,
            ycProjectRoleType: projectRole.ycProjectRoleType
              ? {
                  label: projectRole.ycProjectRoleType.value,
                  value: projectRole.ycProjectRoleType.id,
                }
              : null,
            roleDescription: projectRole.roleDescription,
            responsibilities: projectRole.responsibilities,
            qualifications: projectRole.qualifications,
            closingInformations: projectRole.closingInformations,
            cvRequired: projectRole.cvFileRequired,
            letterRequired: projectRole.letterFileRequired,
            skills: projectRole.skills
              ? projectRole.skills.reduce((prev, item) => [...prev, item.id], [])
              : [],
          },
          true,
        );
      }
      yield put(setYcProjectRoleCreator(projectRole));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get yc project error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* editProjectRole({ payload, id, history, projectId, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.editProjectRole, payload, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push(`/yc/project-roles/${projectId}`);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] yc cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* createProjectRoleApplication({ payload, closeModal, setErrors }) {
  try {
    setErrors({});
    yield put(setNewLoadingCreator({ type: 'APPLICATION' }));
    const response = yield call(ycService.createProjectRoleApplication, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'yc',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      closeModal();
    }
    yield put(removeLoadingCreator({ type: 'APPLICATION' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'APPLICATION' }));
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create project role application error: ', error);
  } finally {
    yield put(removeLoadingCreator({ type: 'APPLICATION' }));
  }
}

function* loadProjectRoleApplicationBar({ payload, id }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.getProjectRoleApplicationBar, id);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setYcProjectRoleApplicationBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] yc project role applications bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadProjectRoleApplications({ payload, id }) {
  try {
    if (payload && payload.status)
      yield put(setYcProjectRoleApplicationStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const { page, perPage, status } = yield select(({ yc }) => yc.applications.list);
    const response = yield call(ycService.getProjectRoleApplications, {
      page,
      maxResult: perPage,
      applicationState: status,
      roleid: id,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadYcProjectRoleApplicationBarCreator({ withoutLoader: true }, id));
      yield put(
        setYcProjectRoleApplicationsCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[GET] yc applications list error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* acceptApplication({ payload, roleId }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.acceptProjectRoleApplication, payload);
    if (response.ok) {
      yield put(loadYcProjectRoleApplicationsCreator(null, roleId));
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[PUT] accept yc application error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* rejectApplication({ payload, roleId }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.rejectProjectRoleApplication, payload);
    if (response.ok) {
      yield put(loadYcProjectRoleApplicationsCreator(null, roleId));
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[PUT] decline yc application error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* putOnHoldApplication({ payload, roleId }) {
  try {
    yield put(setNewLoadingCreator({ type: 'YC' }));
    const response = yield call(ycService.putOnHoldProjectRoleApplication, payload);
    if (response.ok) {
      yield put(loadYcProjectRoleApplicationsCreator(null, roleId));
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'yc',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'YC' }));
    console.log('[PUT] decline yc application error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'YC' }));
    }
  }
}

function* downloadApplicationsZip({ roleId, roleStatus }) {
  try {
    yield put(setNewLoadingCreator({ type: 'REGLOGO' }));
    const response = yield call(ycService.exportApplications, {
      roleId,
      roleStatus,
    });
    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'applications.zip');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'yc',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    console.log('[GET] yc applications zip error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'REGLOGO' }));
    }
  }
}

function* removeProject({ id }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.removeProject, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      window.location.reload();
    }
  } catch (error) {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] remove project error: ', error);
  }
}

function* removeProjectRole({ id }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(ycService.removeProjectRole, id);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      window.location.reload();
    }
  } catch (error) {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ipma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] remove project role error: ', error);
  }
}
export default function* watchYc() {
  yield takeLatest(LOAD_YC_DASHBOARD, loadDashboard);
  yield takeLatest(LOAD_YC_PROFILE, loadProfile);
  yield takeLatest(LOAD_YC_PROFILE_UPCOMING_EVENTS, loadProfileUpcomingEvents);
  yield takeLatest(UPDATE_YC_PROFILE, updateProfile);
  yield takeLatest(LOAD_YC_PEOPLE_BAR, loadBar);
  yield takeLatest(LOAD_YC_PEOPLE_USERS, loadUsers);
  yield takeLatest(LOAD_YC_PEOPLE_LIST, loadPeopleYC);
  yield takeLatest(LOAD_YC_MEMBERS_LIST, loadMembersYc);
  yield takeLatest(CHANGE_YC_PEOPLE_USERS_ROLE, changeUserRole);
  yield takeLatest(DELETE_YC_PEOPLE_USER, removeUser);
  yield debounce(1000, LOAD_YC_PEOPLE_ADD_LIST_USERS, loadAddListUsers);
  yield takeLatest(ADD_YC_PEOPLE_USERS, addUsers);
  yield takeLatest(ACCEPT_YC_PEOPLE_LIST_USER, acceptListUser);
  yield takeLatest(DECLINE_YC_PEOPLE_LIST_USER, declineListUser);
  yield takeLatest(REMOVE_YC_PEOPLE_LIST_USER, removeListUser);
  yield takeLatest(LOAD_YC_EVENTS_BAR, loadEventsBar);
  yield takeLatest(LOAD_YC_EVENTS, loadEvents);
  yield takeLatest(CREATE_YC_EVENT, createEvent);
  yield takeLatest(LOAD_YC_EVENT, loadEvent);
  yield takeLatest(EDIT_YC_EVENT, editEvent);
  yield takeLatest(CANCEL_YC_EVENT, cancelEvent);
  yield takeLatest(REOPEN_YC_EVENT, reopenEvent);
  yield takeLatest(DUPLICATE_YC_EVENT, duplicateEvent);
  yield takeLatest(DOWNLOAD_YC_PEOPLE_REPORT, downloadYcPeopleReport);
  yield takeLatest(CREATE_PROJECT, createProject);
  yield takeLatest(LOAD_YC_PROJECTS_BAR, loadProjectsBar);
  yield takeLatest(LOAD_YC_PROJECTS, loadProjects);
  yield takeLatest(LOAD_YC_PROJECT, loadProject);
  yield takeLatest(EDIT_YC_PROJECT, editProject);

  yield takeLatest(CREATE_PROJECT_ROLE, createProjectRole);
  yield takeLatest(EDIT_YC_PROJECT_ROLE, editProjectRole);
  yield takeLatest(LOAD_YC_PROJECT_ROLES_BAR, loadProjectRolesBar);
  yield takeLatest(LOAD_YC_PROJECT_ROLES, loadProjectRoles);
  yield takeLatest(LOAD_YC_PROJECT_ROLE, loadProjectRole);
  yield takeLatest(CREATE_PROJECT_ROLE_APPLICATION, createProjectRoleApplication);
  yield takeLatest(LOAD_YC_PROJECT_ROLE_APPLICATION_BAR, loadProjectRoleApplicationBar);
  yield takeLatest(LOAD_YC_PROJECT_ROLE_APPLICATIONS, loadProjectRoleApplications);
  yield takeLatest(ACCEPT_YC_PROJECT_ROLE_APPLICATION, acceptApplication);
  yield takeLatest(DECLINE_YC_PROJECT_ROLE_APPLICATION, rejectApplication);
  yield takeLatest(PUT_ON_HOLD_YC_PROJECT_ROLE_APPLICATION, putOnHoldApplication);
  yield takeLatest(DOWNLOAD_YC_APPLICATIONS, downloadApplicationsZip);
  yield takeLatest(REMOVE_YC_PROJECT, removeProject);
  yield takeLatest(REMOVE_YC_PROJECT_ROLE, removeProjectRole);
}
