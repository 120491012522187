import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, PageContent, Container, Table, SpinnerDimmed, Toggle, Alerts } from 'components';
import { Margin } from 'styled-components-spacing';
import { connect } from 'react-redux';
import { isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import {
  loadIpmaRegOfficeStatTableCreator,
  toggleIpmaRegOfficeMaTypeCreator,
  toggleIpmaRegOfficeOrgFrCreator,
} from 'store/actionsCreators';
import { RegRow } from './RegTable.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.office',
    url: '/ipma/reg-office-hub',
  },
  {
    title: 'navigation.breadcrumbs.ipma.regTable',
    url: '',
  },
];

const RegTable = ({ items, loading, loadStatTable, toggleMa, toggleOrg }) => {
  const { t } = useTranslation();

  useEffect(() => {
    loadStatTable();
  }, []);

  const toogleMaType = (maId, type, newValue) => toggleMa({ maId, type, newValue });
  const toogleOrgFr = (orgId, newValue) => toggleOrg({ orgId, newValue });
  const renderOrgContent = (org) => (
    <>
      <Table.Cell style={{ fontWeight: 500, textAlign: 'center' }}>
        {org.name} ({org.courses})
      </Table.Cell>
      <Table.Cell style={{ fontWeight: 500, textAlign: 'center' }}>
        <Table.Label>{t('page.ipma.officeTable.flatRate')}:</Table.Label>
        <Toggle on={org.flatRate} onClick={() => toogleOrgFr(org.id, !org.flatRate)} />
      </Table.Cell>
      <Table.Cell style={{ fontWeight: 500, textAlign: 'center' }}>
        <Table.Label>{t('page.ipma.officeTable.numberOfCpds')}:</Table.Label>
        {org.flatRate ? '-' : org.cpdsIssued}
      </Table.Cell>
      <Table.Cell style={{ fontWeight: 500, textAlign: 'center' }}>
        <Table.Label>{t('page.ipma.officeTable.diplomas')}:</Table.Label>
        N/A
      </Table.Cell>
    </>
  );

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ipma.officeTable.name')} />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="ipma" />
          </Margin>
          <Margin>
            <Table tablebreakpoint="lg">
              <Table.Head>
                <Table.Row>
                  <Table.Heading>{t('page.ipma.officeTable.maName')}</Table.Heading>
                  <Table.Heading>{t('page.ipma.officeTable.regAgreement')}</Table.Heading>
                  <Table.Heading>{t('page.ipma.officeTable.cpdRec')}</Table.Heading>
                  <Table.Heading>{t('page.ipma.officeTable.listRegCourses')}</Table.Heading>
                  <Table.Heading>{t('page.ipma.officeTable.flatRate')}</Table.Heading>
                  <Table.Heading>{t('page.ipma.officeTable.numberOfCpds')}</Table.Heading>
                  <Table.Heading>{t('page.ipma.officeTable.diplomas')}</Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body style={{ position: 'relative', height: '80px' }}>
                {loading ? <SpinnerDimmed /> : null}
                {!loading && !items.length ? (
                  <Table.Row>
                    <Table.Cell colSpan={7}>{t('global.noResultsFound')}</Table.Cell>
                  </Table.Row>
                ) : null}
                {items.length
                  ? items.map((item, index) => (
                      <>
                        <RegRow key={item.ma.name} isOdd={(index + 1) % 2 !== 0}>
                          <Table.Cell style={{ fontWeight: 600, color: '#4F4E5C' }}>
                            <Table.Label>{t('page.ipma.officeTable.maName')}:</Table.Label>
                            {item.ma.name}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.officeTable.regAgreement')}:</Table.Label>
                            <Toggle
                              on={item.ma.regAgreement}
                              onClick={() =>
                                toogleMaType(item.ma.id, 'regAgreement', !item.ma.regAgreement)
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.officeTable.cpdRec')}:</Table.Label>
                            <Toggle
                              on={item.ma.cpdRec}
                              onClick={() => toogleMaType(item.ma.id, 'cpdRec', !item.ma.cpdRec)}
                            />
                          </Table.Cell>
                          {renderOrgContent(item)}
                        </RegRow>
                      </>
                    ))
                  : null}
              </Table.Body>
            </Table>
          </Margin>
        </Container>
      </PageContent>
    </>
  );
};

RegTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  loadStatTable: PropTypes.func.isRequired,
  toggleMa: PropTypes.func.isRequired,
  toggleOrg: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ipma, loading }) => ({
  loading: isLoading(loading, 'IPMA'),
  items: ipma.regOfficeTable.items,
});

const mapDispatchToProps = (dispatch) => ({
  loadStatTable: () => dispatch(loadIpmaRegOfficeStatTableCreator()),
  toggleMa: (payload) => dispatch(toggleIpmaRegOfficeMaTypeCreator(payload)),
  toggleOrg: (payload) => dispatch(toggleIpmaRegOfficeOrgFrCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegTable);
