import styled, { css } from 'styled-components';
import { mr } from 'styled-components-spacing';

export const Input = styled.div`
  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 2px;

  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.colors.gray[200]};

      svg {
        width: 10px;
        height: 9px;

        fill: ${theme.colors.white};
      }
    `}

  ${({ theme, selected }) =>
    theme &&
    selected &&
    css`
      background-color: ${theme.colors.secondary};
    `}
`;

export default styled.button`
  display: flex;
  text-align: left;
  cursor: pointer;

  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.regular};
      color: ${theme.colors.gray[500]};

      ${Input} {
        ${mr(8)}
      }
    `}

  ${({ theme, disabled }) =>
    theme &&
    disabled &&
    css`
      cursor: not-allowed;
      ${Input} {
        background-color: ${theme.colors.gray[300]};
      }
    `}

  line-height: 16px;
`;
