import styled, { css } from 'styled-components';
import { mx, mr, mt } from 'styled-components-spacing';

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.p`
  ${({ theme }) => theme && css`
    color: #626466;
    font-size: ${theme.font.size.pixel('12')};
    font-weight: ${theme.font.weight.light};
  `}
`;

export const Line = styled.div`
  ${({ theme }) => theme && css`
    ${mx(8)};

    width: 100%;
    height: 16px;
    background-color: ${theme.colors.gray['200']};
    border-radius: 8px;
    overflow: hidden;
  `}
`;

export const Value = styled.div`
  ${({ theme, value, progressStatus }) => theme && value && css`
    width: ${value}%;
    height: 16px;
    background-color: ${progressStatus === 'default'
        ? theme.colors.tertiary
        : progressStatus === 'warning'
        ? theme.colors.secondaries.darker
        : progressStatus === 'expired'
        ? theme.colors.primaries.darker
        : progressStatus === 'new'
        ? theme.colors.secondaries.new
        : ''};
  `}
`;

export const Message = styled.div`
  ${({ theme }) => theme && css`
    ${mt(12)};

    color: #7a142c;
    font-size: ${theme.font.size.pixel('12')};
    font-weight: ${theme.font.weight.light};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      ${mr(8)};
    
      width: 20px;
      height: 20px;
      fill: #7a142c;
      pointer-events: none;
    }
  `}
`;