import React from 'react';
import { Container, Ball } from './Spinner.styled';

function Spinner() {
  return (
    <div className="text-center">
      <Container>
        <Ball />
        <Ball />
        <Ball />
        <Ball />
        <Ball />
        <Ball />
        <Ball />
        <Ball />
        <Ball />
        <Ball />
        <Ball />
        <Ball />
      </Container>
    </div>
  );
}

export default Spinner;
