import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Container,
  Breadcrumbs,
  Row,
  PageContent,
  BoxList,
  Tags,
  BadgeList,
} from 'components';
import { isEmpty } from 'lodash';
import { Margin } from 'styled-components-spacing';
import { loadProfileCompletedCoursesCreator, loadUserBadgesCreator } from 'store/actionsCreators';
import { globalPropTypes } from 'helpers';
import moment from 'moment';
import {
  ProfileSection,
  CertificationsStatus,
  Details,
  Description,
  UserOverview,
} from './components';
import { Content, ContentPadding } from './Profile.styled';

const EMPTY_CHAR = '-';
const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.profile',
    url: '/user/profile',
  },
];

function Profile({
  user,
  certifications,
  badges,
  loadUserBadges,
  loadProfileCompletedCourses,
  completedCourses,
}) {
  const { t } = useTranslation();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    loadUserBadges();
    loadProfileCompletedCourses();
  }, []);

  useEffect(() => {
    const profileData = {
      avatar: user.userProfile && user.userProfile.photo,
      userName: `${user.firstName} ${user.lastName}`,
      phoneNumber: (user.userProfile && user.userProfile.telephone) || undefined,
      professionDetails: [
        [
          t('page.user.profile.professionalTitle'),
          (user.userProfile &&
            user.userProfile.proffesionalTitle &&
            user.userProfile.proffesionalTitle.name) ||
            EMPTY_CHAR,
        ],
        [
          t('page.user.profile.jobTitle'),
          (user.userProfile && user.userProfile.jobTitle && user.userProfile.jobTitle.name) ||
            EMPTY_CHAR,
        ],
        [
          t('page.user.profile.company'),
          (user.userProfile && user.userProfile.company) || EMPTY_CHAR,
        ],
        [
          t('page.user.profile.industry'),
          (user.userProfile && user.userProfile.industry && user.userProfile.industry.name) ||
            EMPTY_CHAR,
        ],
      ],
      additionalInfo: [
        [t('page.user.profile.email'), user.email || EMPTY_CHAR],
        [
          t('page.user.profile.dob'),
          (user.dob && moment(user.dob).format('DD/MM/YYYY')) || EMPTY_CHAR,
        ],
        [
          t('page.user.profile.gender'),
          (user.userProfile && user.userProfile.gender) || EMPTY_CHAR,
        ],
        [
          t('page.user.profile.countryOfOrigin'),
          (user.userProfile &&
            user.userProfile.countryOfOrigin &&
            user.userProfile.countryOfOrigin.name) ||
            EMPTY_CHAR,
        ],
        [t('page.user.profile.liveNow'), (user.country && user.country.name) || EMPTY_CHAR],
        [
          t('page.user.profile.ma'),
          (user.memberAssociation && user.memberAssociation.name) || EMPTY_CHAR,
        ],
        user.youngCrew && user.youngCrew.name
          ? [t('page.user.profile.youngCrewMember'), user.youngCrew.name || EMPTY_CHAR]
          : [],
      ],
      shortBio: (user.userProfile && user.userProfile.shortBio) || EMPTY_CHAR,
      tags:
        user.userProfile && user.userProfile.tags && user.userProfile.tags.length
          ? user.userProfile.tags.map((item) => item.name)
          : [],
      profileRoles: user.profileRoles,
    };

    setProfile(profileData);
  }, [user]);

  const renderBadges = () => {
    const badgesList = Object.keys(badges)
      .map((key) => badges[key].filter((el) => el.type === 'accepted'))
      .flat();

    return (
      <ProfileSection
        displayer={BadgeList}
        heading={t('page.user.dashboard.badgesTitle')}
        items={badgesList}
        share
        alert={t('global.noResultsBadges')}
        showLimit={6}
      />
    );
  };

  return (
    <PageContent>
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        <Content>
          <Row>
            <Col size={{ xs: 1, lg: 1 / 6 }}>
              <Margin bottom={{ xs: 32, lg: 0 }}>
                <UserOverview
                  thumbnail={profile.avatar}
                  name={profile.userName}
                  phone={profile.phoneNumber}
                />
              </Margin>
            </Col>
            <Col size={{ xs: 1, lg: 5 / 6 }}>
              <ContentPadding>
                <Row>
                  <Col size={{ md: 2 / 5 }}>
                    <ProfileSection
                      displayer={Details}
                      heading={t('page.user.profile.professionDetails')}
                      details={profile.professionDetails}
                    />
                    <ProfileSection
                      displayer={Details}
                      heading={t('page.user.profile.additionalInfo')}
                      details={profile.additionalInfo}
                    />
                    {!isEmpty(profile.profileRoles) ? (
                      <ProfileSection
                        displayer={Details}
                        heading={t('page.user.profile.roles')}
                        details={profile.profileRoles}
                      />
                    ) : null}
                  </Col>
                  <Col size={{ md: 3 / 5 }}>
                    <ProfileSection
                      displayer={Description}
                      heading={t('page.user.profile.shortBio')}
                      text={profile.shortBio}
                    />
                    <ProfileSection
                      displayer={Tags}
                      heading={t('page.user.profile.tags')}
                      tags={profile.tags}
                    />
                  </Col>
                </Row>
                <ProfileSection
                  displayer={CertificationsStatus}
                  heading={t('page.user.profile.certificationStatus')}
                  certs={certifications}
                />
                <ProfileSection
                  displayer={BoxList}
                  heading={t('page.user.profile.coursesTitle')}
                  type="course"
                  items={completedCourses}
                  alert={t('global.noResultsCourses')}
                />
                {renderBadges()}
              </ContentPadding>
            </Col>
          </Row>
        </Content>
      </Container>
    </PageContent>
  );
}

Profile.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    dob: PropTypes.string,
    memberAssociation: PropTypes.any,
    youngCrew: PropTypes.any,
    country: PropTypes.shape({ name: PropTypes.number.isRequired }),
    youngCrewMember: PropTypes.bool,
    profileRoles: PropTypes.shape([]),
    userProfile: PropTypes.shape({
      company: PropTypes.string,
      countryOfOrigin: PropTypes.shape({ name: PropTypes.string.isRequired }),
      industry: PropTypes.shape({ name: PropTypes.string.isRequired }),
      telephone: PropTypes.string,
      proffesionalTitle: PropTypes.shape({ name: PropTypes.number.isRequired }),
      jobTitle: PropTypes.shape({ name: PropTypes.number.isRequired }),
      tags: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.number.isRequired })),
      profilePrivacy: PropTypes.shape({ name: PropTypes.number.isRequired }),
      gender: PropTypes.string,
      shortBio: PropTypes.string,
      photo: PropTypes.string,
    }),
  }).isRequired,
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      awardedDate: PropTypes.string,
      validityDate: PropTypes.string,
      ma: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      level: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
  completedCourses: PropTypes.shape({}).isRequired,
  loadUserBadges: PropTypes.func.isRequired,
  loadProfileCompletedCourses: PropTypes.func.isRequired,
  profileRoles: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  badges: globalPropTypes.BadgesPropsTypes,
};

Profile.defaultProps = {
  certifications: [],
  profileRoles: [],
  badges: {},
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  certifications: user.certifications,
  completedCourses: user.profileCompletedCurses,
  badges: user.badges,
});

const mapDispatchToProps = (dispatch) => ({
  loadUserBadges: () => dispatch(loadUserBadgesCreator()),
  loadProfileCompletedCourses: () => dispatch(loadProfileCompletedCoursesCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
