import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Col, Modal, Row, Heading, ButtonAction, Spinner, Table } from 'components';
import ModalContent, { Action } from 'components/Modal/Modal.styled';
import { useTranslation } from 'react-i18next';
import { isLoading } from 'helpers';
import { connect } from 'react-redux';
import {
  setOpenPendingBadgesCreator,
  claimPendingEventBadgeCreator,
  rejectPendingEventBadgeCreator,
  acceptCourseBadgeCreator,
  rejectCourseBadgeCreator,
  suppressCourseBadgeCreator,
  claimPendingAwardCreator,
  rejectPendingAwardCreator,
  acceptAssessorBadgeCreator,
  rejectAssessorBadgeCreator,
  acceptHonoraryFellowBadgeCreator,
  rejectHonoraryFellowBadgeCreator,
  claimUserCertificateCreator,
} from 'store/actionsCreators';
import { TextContainer, StyledAction } from './PendingBadges.styled';

const PendingBadges = ({
  loading,
  openModal,
  items,
  certificates,
  setOpenModal,
  claimEventBadge,
  rejectEventBadge,
  claimAwardBadge,
  rejectAwardBadge,
  claimCourseBadge,
  rejectCourseBadge,
  suppressCourseBadge,
  claimAssessorBadge,
  rejectAssessorBadge,
  claimHonoraryFellowBadge,
  rejectHonoraryFellowBadge,
  claimUserCertificate
}) => {
  const { t } = useTranslation();
  const [badges, setBadges] = useState([]);
  const [item, setItem] = useState({});
  const [currentBadge, setCurrentBadge] = useState(1);
  const [pendingBadgesLength, setPendingBadgesLength] = useState(1);

  useEffect(() => {
    const reduceItems = (toReduce, type, combined) => {
      const reducedArray = toReduce.reduce((a, i) => {
        const newItem = i;
        newItem.badgeType = type;

        a.push(newItem);

        return a;
      }, []);
      return combined.concat(reducedArray);
    };

    const combineArrays = () => {
      let combined = [];
      Object.keys(items).forEach((key) => {
        combined = reduceItems(items[key].items, key, combined);
      });
      combined = reduceItems(certificates.items, 'certificates', combined);

      setPendingBadgesLength(combined.length);
      setCurrentBadge(1);
      setItem(combined.shift());
      setBadges(combined);
    };
    combineArrays();
  }, [items, certificates, openModal, setOpenModal]);

  const shiftPendingBadge = () => {
    const newBadges = badges;
    setItem(newBadges.shift());
    setBadges(newBadges);
  };

  const badgeAccept = () => {
    shiftPendingBadge();
    setCurrentBadge(currentBadge + 1);
    switch (item.badgeType) {
      case 'events':
        return claimEventBadge(item.event.id);
      case 'course':
        return claimCourseBadge({ id: item.course.id, name: item.course.name });
      case 'award':
        return claimAwardBadge(item.award.id);
      case 'assessor':
        return claimAssessorBadge(item.assesorBadge.id);
      case 'honoraryFellow':
        return claimHonoraryFellowBadge(item.honoraryFellowBadge.id);
      case 'certificates':
        return claimUserCertificate(item.id);
      default:
        return <></>;
    }
  };

  const badgeReject = () => {
    shiftPendingBadge();
    setCurrentBadge(currentBadge + 1);
    switch (item.badgeType) {
      case 'events':
        return rejectEventBadge(item.event.id);
      case 'course':
        return rejectCourseBadge(item.course.id);
      case 'award':
        return rejectAwardBadge(item.award.id);
      case 'assessor':
        return rejectAssessorBadge(item.assesorBadge.id);
      case 'honoraryFellow':
        return rejectHonoraryFellowBadge(item.honoraryFellowBadge.id);
      default:
        return <></>;
    }
  };

  const badgeSuppress = () => {
    shiftPendingBadge();
    setCurrentBadge(currentBadge + 1);
    switch (item.badgeType) {
      case 'course':
        return suppressCourseBadge(item.course.id);
      default:
        return <></>;
    }
  };

  const badgeTypeTitle = () => {
    switch (item.badgeType) {
      case 'events':
        return 'Event';
      case 'course':
        return 'Course';
      case 'award':
        return 'Award';
      case 'assessor':
        return 'Assesor';
      case 'honoraryFellow':
        return 'Honorary Fellow';
      default:
        return '';
    }
  };

  const renderContent = (badge) => (
    <ModalContent>
      {!loading ? (
        <>
          <Heading as="h4" noLine center>
            {t('global.pendingBadge')}
          </Heading>
          <Heading as="h5" noLine center>
            {`${currentBadge}/${pendingBadgesLength}`}
          </Heading>
          <Margin top="32" bottom="32">
            <Row halign="center">
              <Col size={{ xs: 4 / 5 }}>
                {badge ? (
                  <TextContainer>
                    <Margin bottom="12">
                      <Row halign="center">{t('page.user.pendingBadges.dearUser')}</Row>
                    </Margin>
                    <Margin bottom="12">
                      <Row halign="center">
                        {t('page.user.pendingBadges.issued', {
                          type: badgeTypeTitle(),
                          name: badge.name,
                        })}
                      </Row>
                    </Margin>
                    <Margin>
                      <Row halign="center">{t('page.user.pendingBadges.doYouAcceptBadge')}</Row>
                    </Margin>
                  </TextContainer>
                ) : null}
              </Col>
            </Row>
          </Margin>
          <Margin>
            <Row halign="center">
              <Col size={{ xl: 1 / 4 }}>
                <Margin bottom={{ xs: 8 }}>
                  <ButtonAction
                    icon="checkCircle"
                    title={t('global.acceptButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => badgeAccept()}
                  />
                </Margin>
              </Col>
              <Col size={{ xl: 1 / 4 }}>
                <Margin bottom={{ xs: 8 }}>
                  <ButtonAction
                    icon="closeCircle"
                    title={t('global.declineButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => badgeReject()}
                  />
                </Margin>
              </Col>
              <Col size={{ xl: 1 / 4 }}>
                <Margin bottom={{ xs: 8 }}>
                  <ButtonAction
                    icon="clock"
                    title={t('global.remindMeButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => {
                      shiftPendingBadge();
                      setCurrentBadge(currentBadge + 1);
                    }}
                  />
                </Margin>
              </Col>
              {item.badgeType === 'course' && (
                <Col size={{ xl: 1 / 4 }}>
                  <Margin bottom={{ xs: 8 }}>
                    <ButtonAction
                      icon="bellSlashed"
                      title={t('global.dontRemindMeButton')}
                      verticalviewbreakpoint="xl"
                      onClick={() => badgeSuppress()}
                    />
                  </Margin>
                </Col>
              )}
            </Row>
          </Margin>
        </>
      ) : (
        <Spinner />
      )}
    </ModalContent>
  );

  const renderCertificate = () => (
    <ModalContent>
      {!loading ? (
        <>
          <Heading as="h4" noLine center>
            {t('global.pendingCertificate')}
          </Heading>
          <Heading as="h5" noLine center>
            {`${currentBadge}/${pendingBadgesLength}`}
          </Heading>
          <Margin top="32" bottom="16">
            <Row halign="center">
              <Col size={{ xs: 4 / 5 }}>
                <TextContainer>
                  <Margin bottom="12">
                    <Row halign="center">{t('page.user.pendingBadges.dearUser')}</Row>
                  </Margin>
                  <Margin as={Row} halign="center" bottom="12">
                    {t('page.user.pendingBadges.issuedCert', {
                      date: item.issue_date,
                      certId: item.cert_id,
                    })}
                  </Margin>
                  <Margin>
                    <Row halign="center">{t('page.user.pendingBadges.doYouAccept')}</Row>
                  </Margin>
                  <Margin vertical="24">
                    <Table tablebreakpoint="always">
                      <Table.Body>
                        {loading ? (
                          <Table.Row>
                            <Table.Cell colSpan={5}>
                              <Spinner />
                            </Table.Cell>
                          </Table.Row>
                        ) : null}
                        {!loading && item ? (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <Table.Label>{t('certificates.certificate')}:</Table.Label>
                              {item.designation}
                            </Table.Cell>
                            <Table.Cell>
                              <Table.Label>{t('certificates.certificateNumber')}:</Table.Label>
                              {item.cert_id}
                            </Table.Cell>
                            <Table.Cell>
                              <Table.Label>{t('certificates.awardedDate')}:</Table.Label>
                              {item.issue_date}
                            </Table.Cell>
                            <Table.Cell>
                              <Table.Label>{t('certificates.validityDate')}:</Table.Label>
                              {item.valid_until}
                            </Table.Cell>
                            <Table.Cell>
                              <Table.Label>{t('certificates.ma')}:</Table.Label>
                              {item.clientCountry}
                            </Table.Cell>
                          </Table.Row>
                        ) : null}
                      </Table.Body>
                    </Table>
                  </Margin>
                </TextContainer>
              </Col>
            </Row>
          </Margin>
          <Margin>
            <Row halign="center">
              <Col size={{ xl: 1 / 4 }}>
                <Margin bottom={{ xs: 8 }}>
                  <ButtonAction
                    icon="checkCircle"
                    title={t('global.acceptButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => badgeAccept()}
                  />
                </Margin>
              </Col>
              <Col size={{ xl: 1 / 4 }}>
                <Margin bottom={{ xs: 8 }}>
                  <ButtonAction
                    icon="clock"
                    title={t('global.remindMeButton')}
                    verticalviewbreakpoint="xl"
                    onClick={() => {
                      shiftPendingBadge();
                      setCurrentBadge(currentBadge + 1);
                    }}
                  />
                </Margin>
              </Col>
            </Row>
          </Margin>
        </>
      ) : (
        <Spinner />
      )}
    </ModalContent>
  );

  const noMoreBadges = () => (
    <ModalContent>
      {!loading ? (
        <>
          <Heading as="h4" noLine center>
            {t('page.user.pendingBadges.noBadges')}
          </Heading>
          <Margin top="16">
            <Row halign="center">
              <Col size={{ xs: 1 / 2 }}>
                <StyledAction
                  as={Link}
                  to="/user/my-badges"
                  onClick={() => setOpenModal(false)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('global.goToBadgesButton')}
                </StyledAction>
              </Col>
              <Col size={{ xs: 1 / 2 }}>
                <Action onClick={() => setOpenModal(false)} bordered>
                  {t('global.closeButton')}
                </Action>
              </Col>
            </Row>
          </Margin>
        </>
      ) : (
        <Spinner />
      )}
    </ModalContent>
  );

  const selectBadgeType = () => {
    if (item !== undefined) {
      switch (item.badgeType) {
        case 'events':
          return renderContent(item.event);
        case 'course':
          return renderContent(item.course);
        case 'award':
          return renderContent(item.award);
        case 'assessor':
          return renderContent(item.assesorBadge);
        case 'honoraryFellow':
          return renderContent(item.honoraryFellowBadge);
        case 'certificates':
          return renderCertificate();
        default:
          return <></>;
      }
    } else {
      return noMoreBadges();
    }
  };

  return (
    <Modal
      isOpen={openModal}
      customContent={selectBadgeType}
      onRequestClose={() => setOpenModal(false)}
    />
  );
};

PendingBadges.propTypes = {
  loading: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  items: PropTypes.shape({
    event: PropTypes.shape({
      totalItems: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    assessor: PropTypes.shape({
      totalItems: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    award: PropTypes.shape({
      totalItems: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    course: PropTypes.shape({
      totalItems: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    honoraryFellow: PropTypes.shape({
      totalItems: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  certificates: PropTypes.shape({
    totalItems: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setOpenModal: PropTypes.func.isRequired,
  claimEventBadge: PropTypes.func.isRequired,
  rejectEventBadge: PropTypes.func.isRequired,
  claimAwardBadge: PropTypes.func.isRequired,
  rejectAwardBadge: PropTypes.func.isRequired,
  claimCourseBadge: PropTypes.func.isRequired,
  rejectCourseBadge: PropTypes.func.isRequired,
  suppressCourseBadge: PropTypes.func.isRequired,
  claimAssessorBadge: PropTypes.func.isRequired,
  rejectAssessorBadge: PropTypes.func.isRequired,
  claimHonoraryFellowBadge: PropTypes.func.isRequired,
  rejectHonoraryFellowBadge: PropTypes.func.isRequired,
  claimUserCertificate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, loading }) => ({
  loading: isLoading(loading, 'CLAIM_BADGE'),
  items: user.pendingBadges.badges,
  certificates: user.pendingBadges.certifications,
  openModal: user.pendingBadges.openModal,
});

const mapDispatchToProps = (dispatch) => ({
  setOpenModal: (payload) => dispatch(setOpenPendingBadgesCreator(payload)),
  claimEventBadge: (payload) => dispatch(claimPendingEventBadgeCreator(payload)),
  rejectEventBadge: (payload) => dispatch(rejectPendingEventBadgeCreator(payload)),
  claimAwardBadge: (payload) => dispatch(claimPendingAwardCreator(payload)),
  rejectAwardBadge: (payload) => dispatch(rejectPendingAwardCreator(payload)),
  claimCourseBadge: (payload) => dispatch(acceptCourseBadgeCreator(payload)),
  rejectCourseBadge: (payload) => dispatch(rejectCourseBadgeCreator(payload)),
  suppressCourseBadge: (payload) => dispatch(suppressCourseBadgeCreator(payload)),
  claimAssessorBadge: (payload) => dispatch(acceptAssessorBadgeCreator(payload)),
  rejectAssessorBadge: (payload) => dispatch(rejectAssessorBadgeCreator(payload)),
  claimHonoraryFellowBadge: (payload) => dispatch(acceptHonoraryFellowBadgeCreator(payload)),
  rejectHonoraryFellowBadge: (payload) => dispatch(rejectHonoraryFellowBadgeCreator(payload)),
  claimUserCertificate: (payload) => dispatch(claimUserCertificateCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingBadges);
