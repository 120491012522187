import styled from 'styled-components';

export const RegNumber = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  color: #ab1410;
  > div {
    font-weight: 700;
    margin-right: 5px;
  }
`;
