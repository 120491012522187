import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonIcon } from 'components';
import List from 'components/Navbar/List/List';
import { getAvatar, globalPropTypes, isLoading } from 'helpers';
import { iconList } from 'assets';
import { useTranslation } from 'react-i18next';
import { connectAzureCreator } from 'store/actionsCreators';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import MyLibrary from 'shared/MyLibrary/MyLibrary';
import {
  Wrapper,
  Button,
  ButtonContent,
  Avatar,
  Notification,
  Content,
  Top,
  OuterWrapper,
  Lines,
  EmptyAvatar,
  DefaultAvatar,
} from './Logged.styled';

const Logged = ({
  appType,
  user,
  notifications,
  userRoutes,
  connectAzure,
  connectAzureLoading,
}) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const loggedRef = useRef(null);

  const {
    userProfile: { photo },
  } = user;

  useEffect(() => {
    const handleClick = (event) => {
      if (loggedRef.current && !loggedRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    const handleScroll = () => {
      setMenuOpen(false);
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getTotalNotifications = () => {
    if (notifications.length) {
      const total = notifications.reduce((prev, curr) => {
        return prev + curr.items.filter((item) => !item.isRead).length;
      }, 0);
      if (total > 0) {
        return `${total}`;
      }
      return '';
    }

    return '';
  };

  return (
    <OuterWrapper>
      <Notification
        icon="notificationNew"
        title={getTotalNotifications()}
        verticalviewbreakpoint="sm"
        forwardedAs={Link}
        to="/notifications"
      />
      <Wrapper type={appType} open={menuOpen} ref={loggedRef}>
        <Button onClick={() => setMenuOpen(!menuOpen)}>
          <ButtonContent>
            {getAvatar(photo, 'profile') ? (
              <Avatar
                type={appType}
                open={menuOpen}
                style={{ backgroundImage: `url(${getAvatar(photo, 'profile')})` }}
              />
            ) : (
              <EmptyAvatar type={appType} open={menuOpen}>
                <DefaultAvatar src={iconList.userNew} />
              </EmptyAvatar>
            )}

            <Lines type={appType} open={menuOpen} />
          </ButtonContent>
        </Button>
        <Content>
          <Top>
            <List
              items={userRoutes.links}
              closeFn={() => setMenuOpen(false)}
              extra={(Item, ItemLink) => (
                <>
                  {appType === 'user' ? (
                    <>
                      {/* <Item>
                        <MyLibrary mobile />
                      </Item> */}
                      <Item>
                        <ItemLink
                          as="button"
                          type="button"
                          onClick={connectAzure}
                          disabled={connectAzureLoading}
                        >
                          {t('global.myWorkspacesButton')}
                        </ItemLink>
                      </Item>
                    </>
                  ) : null}
                  <Item>
                    <ItemLink as={NavLink} to={{ pathname: '/auth/logout' }}>
                      {t('global.logOut')}
                    </ItemLink>
                  </Item>
                </>
              )}
            />
          </Top>
          {userRoutes.actions.map((action, index) => (
            <ButtonIcon
              key={index}
              as={Link}
              to={action.path}
              icon={action.icon}
              color={action.color}
              bg={action.bg}
              left="true"
              large="true"
              onClick={() => setMenuOpen(false)}
            >
              {action.title}
            </ButtonIcon>
          ))}
        </Content>
      </Wrapper>
    </OuterWrapper>
  );
};

Logged.propTypes = {
  appType: PropTypes.oneOf(['guest', 'user', 'organisation', 'ma', 'ipma', 'yc']).isRequired,
  userRoutes: globalPropTypes.UserRoutesPropsTypes.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    userProfile: PropTypes.shape({
      photo: PropTypes.string,
    }),
  }).isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          isRead: PropTypes.bool.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          createdAt: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  connectAzure: PropTypes.func.isRequired,
  connectAzureLoading: PropTypes.bool.isRequired,
};

Logged.defaultProps = {
  notifications: [],
};

const mapStateToProps = ({ user: { appType, user, notifications, loading } }) => ({
  appType,
  user,
  notifications,
  connectAzureLoading: isLoading(loading, 'CONNECT_AZURE'),
});

const mapDispatchToProps = (dispatch) => ({
  connectAzure: () => dispatch(connectAzureCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logged);
