import Organisation from 'containers/organisation/Organisation';
import Dashboard from 'containers/organisation/Dashboard/Dashboard';
import Profile from 'containers/organisation/Profile/Profile';
import Edit from 'containers/organisation/Edit/Edit';
import People from 'containers/organisation/People/People';
import PeopleAdd from 'containers/organisation/PeopleAdd/PeopleAdd';
import Courses from 'containers/organisation/Courses/Courses';
import CoursesCourseTemplates from 'containers/organisation/Courses/CourseTemplates/CourseTemplates';
import CoursesPublished from 'containers/organisation/Courses/Published/Published';
import CoursesCompleted from 'containers/organisation/Courses/Completed/Completed';
import CoursesDrafts from 'containers/organisation/Courses/Drafts/Drafts';
import CoursesSubmittedTemplates from 'containers/organisation/Courses/SubmittedTemplates/SubmittedTemplates';
import CoursesPending from 'containers/organisation/Courses/Pending/Pending';
import CoursesActionsCreate from 'containers/organisation/Courses/Actions/Create/Create';
import CoursesActionsCreateSubcourse from 'containers/organisation/Courses/Actions/CreateSubcourse/CreateSubcourse';
import CoursesActionsCompletion from 'containers/organisation/Courses/Actions/Completion/Completion';
import CoursesActionsPublish from 'containers/organisation/Courses/Actions/Publish/Publish';

const ORGANISATION = 'organisationBase';
const DASHBOARD = 'organisationDashboard';
const PROFILE = 'organisationProfile';
const EDIT = 'organisationEdit';
const PEOPLE = 'organisationPeople';
const PEOPLE_ADD = 'organisationPeopleAdd';
const COURSES = 'organisationCourses';
const COURSES_COURSE_TEMPLATES = 'organisationCoursesCoursesCourseTemplates';
const COURSES_PUBLISHED = 'organisationCoursesPublished';
const COURSES_COMPLETED = 'organisationCoursesCompleted';
const COURSES_DRAFTS = 'organisationCoursesDrafts';
const COURSES_SUBMITTED_TEMPLATES = 'organisationCoursesSubmittedTemplates';
const COURSES_PENDING = 'organisationCoursesPending';
const COURSES_ACTIONS_CREATE = 'organisationCoursesActionsCreate';
const COURSES_ACTIONS_CREATE_SUBCOURSE = 'organisationCoursesActionsCreateSubcourse';
const COURSES_ACTIONS_COMPLETION = 'organisationCoursesActionsCompletion';
const COURSES_ACTIONS_PUBLISH = 'organisationCoursesActionsPublish';

export default [
  {
    key: ORGANISATION,
    path: '/organisation/:id',
    component: Organisation,
    exact: false,
    withAuth: true,
    permissions: {
      appType: 'organisation',
    },
    routes: [
      {
        key: DASHBOARD,
        path: `/organisation/:id`,
        component: Dashboard,
        exact: true,
        withAuth: false,
      },
      {
        key: PROFILE,
        path: `/organisation/:id/profile`,
        component: Profile,
        exact: true,
        withAuth: false,
      },
      {
        key: EDIT,
        path: '/organisation/:id/edit',
        component: Edit,
        exact: true,
        withAuth: false,
      },
      {
        key: PEOPLE,
        path: '/organisation/:id/people',
        component: People,
        exact: true,
        withAuth: false,
      },
      {
        key: PEOPLE_ADD,
        path: '/organisation/:id/people/add',
        component: PeopleAdd,
        exact: true,
        withAuth: false,
      },
      {
        key: COURSES_ACTIONS_CREATE,
        path: [
          '/organisation/:organizationId/courses/create',
          '/organisation/:organizationId/courses/edit/:id?',
        ],
        component: CoursesActionsCreate,
        exact: true,
        withAuth: false,
      },
      {
        key: COURSES_ACTIONS_CREATE_SUBCOURSE,
        path: '/organisation/:organizationId/courses/create-subcourse/:id',
        component: CoursesActionsCreateSubcourse,
        exact: true,
        withAuth: false,
      },
      {
        key: COURSES_ACTIONS_COMPLETION,
        path: '/organisation/:organizationId/courses/completion/:id',
        component: CoursesActionsCompletion,
        exact: false,
        withAuth: false,
      },
      {
        key: COURSES_ACTIONS_PUBLISH,
        path: '/organisation/:organizationId/courses/publish/:id',
        component: CoursesActionsPublish,
        exact: false,
        withAuth: false,
      },
      {
        key: COURSES,
        path: '/organisation/:id/courses',
        component: Courses,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: COURSES_COURSE_TEMPLATES,
            path: '/organisation/:id/courses/course-templates',
            component: CoursesCourseTemplates,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_PUBLISHED,
            path: '/organisation/:id/courses/published',
            component: CoursesPublished,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_COMPLETED,
            path: '/organisation/:id/courses/completed',
            component: CoursesCompleted,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_DRAFTS,
            path: '/organisation/:id/courses/drafts',
            component: CoursesDrafts,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_SUBMITTED_TEMPLATES,
            path: '/organisation/:id/courses/submitted-templates',
            component: CoursesSubmittedTemplates,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_PENDING,
            path: '/organisation/:id/courses/pending',
            component: CoursesPending,
            exact: false,
            withAuth: false,
          },
        ],
      },
    ],
  },
];
