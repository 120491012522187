import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from 'components';

const useSubRoutes = (routes) => {
  const renderSubRoutes = () => (
    <>
      <Switch>
        {routes.map(({ key, path, exact, withAuth, permissions, component: Component, ...res }) => {
          const AppRoute = withAuth || permissions ? PrivateRoute : Route;
          return (
            <AppRoute
              key={key}
              path={path}
              exact={exact}
              permissions={permissions}
              render={(props) => <Component {...{ ...props, ...res }} />}
            />
          );
        })}
      </Switch>
    </>
  );

  return [renderSubRoutes];
};

export default useSubRoutes;
