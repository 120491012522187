import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing';
import { connect } from 'react-redux';

import {
  Alerts,
  Button,
  Col,
  Container,
  Header,
  Heading,
  Field,
  Checkbox,
  Row,
  PageContent,
} from 'components';

import { deleteProfileCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import { ProfileDeleteForm } from './DeleteProfile.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.profile',
    url: '/user/profile',
  },
  {
    title: 'navigation.breadcrumbs.user.deleteProfile',
    url: '/user/profile/delete',
  },
];

function DeleteProfile({ deleteProfile, errors, loading, history }) {
  const { t } = useTranslation();
  const [acceptDelete, setAcceptDelete] = useState(false);
  const handleChangeAcceptance = (value) => {
    setAcceptDelete(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    deleteProfile({ acceptDelete }, history);
  };

  return (
    <>
      <Header title={t('page.user.deleteProfile.title')} breadcrumbs={breadcrumbs} />
      <PageContent>
        <Container>
          <Alerts type="deleteAccount" />
          <ProfileDeleteForm onSubmit={onSubmit}>
            <Heading size="h5" color="inherit">
              {t('page.user.deleteProfile.deleteAccountHeading')}?
            </Heading>
            <Margin as="p" top={{ xs: 16 }}>
              {t('page.user.deleteProfile.deleteAccountSubHeading')}
            </Margin>
            <Margin as="p" top={{ xs: 16 }}>
              <strong>{t('page.user.deleteProfile.deleteAccountInfo')}</strong>
            </Margin>
            <Margin top={{ xs: 16 }}>
              <Row>
                <Col>
                  <Field error={errors.acceptDelete}>
                    <Checkbox onChange={handleChangeAcceptance} selected={acceptDelete}>
                      {t('page.user.deleteProfile.deleteAccountCheckbox')}
                    </Checkbox>
                  </Field>
                </Col>
              </Row>
              <Margin top={{ xs: 16 }}>
                <Row>
                  <Col size={{ md: 1 / 5, xl: 1 / 6 }}>
                    <Button type="submit">{t('global.saveButton')}</Button>
                  </Col>
                </Row>
              </Margin>
            </Margin>
          </ProfileDeleteForm>
        </Container>
      </PageContent>
    </>
  );
}

DeleteProfile.propTypes = {
  errors: PropTypes.shape({
    acceptDelete: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ deleteProfile }) => ({
  errors: deleteProfile.errors,
  loading: deleteProfile.loading,
});
const mapDispatchToProps = (dispatch) => ({
  deleteProfile: (payload, history) => dispatch(deleteProfileCreator(payload, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProfile);
