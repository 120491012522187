import React from 'react';
import PropTypes from 'prop-types';
import { StyledTag } from './TagButton.styled';

function TagButton({ children, ...res }) {
  return <StyledTag {...res}>{children}</StyledTag>;
}

TagButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

TagButton.defaultProps = {
  children: null,
};

export default TagButton;
