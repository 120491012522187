import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { Header, Tabs, Container, PageContent, Alerts } from 'components';
import { Margin } from 'styled-components-spacing';
import { globalPropTypes } from 'helpers';
import { useTranslation } from 'react-i18next';
import { loadIpmaProjectRoleCreator, downloadIpmaApplicationsCreator } from 'store/actionsCreators';

const Applications = ({
  routes,
  bar,
  projectRole,
  userPermissions,
  loadProjectRole,
  downloadApplications,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const project = projectRole && projectRole.ycProject;
  const initialTabs = [
    {
      title: 'navigation.tabs.yc.applications.waiting',
      path: `/ipma/applications/${id}/waiting`,
      key: 'waiting',
    },
    {
      title: 'navigation.tabs.yc.applications.accepted',
      path: `/ipma/applications/${id}/accepted`,
      key: 'accepted',
    },
    {
      title: 'navigation.tabs.yc.applications.rejected',
      path: `/ipma/applications/${id}/rejected`,
      key: 'rejected',
    },
    {
      title: 'navigation.tabs.yc.applications.onHold',
      path: `/ipma/applications/${id}/on-hold`,
      key: 'on-hold',
    },
    {
      title: 'navigation.tabs.yc.applications.autoRejected',
      path: `/ipma/applications/${id}/auto-rejected`,
      key: 'autoRejected',
    },
  ];

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.yc.projects',
      url: '/ipma/projects',
    },
    {
      title: project ? project.name : 'Project',
      url: `/ipma/project-roles/${project ? project.id : 'bad/gate'}`,
    },
    {
      title: projectRole.name || 'Applications',
      url: '',
    },
  ];

  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect(`/ipma/applications/${id}`, firstTabPath);

  useEffect(() => {
    loadProjectRole(id);
  }, [id, loadProjectRole]);

  const handleDownload = () => {
    downloadApplications(projectRole.id, null);
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={projectRole.name}
        renderActions={({ Button: ButtonRender }) => (
          <ButtonRender secondary onClick={handleDownload}>
            {t('page.yc.applications.downloadApplications')}
          </ButtonRender>
        )}
      />
      <Tabs items={tabs} />
      {renderExactRedirect()}
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="yc" />
          </Margin>
          {renderSubRoutes()}
        </Container>
      </PageContent>
    </>
  );
};

Applications.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  bar: PropTypes.shape({
    waiting: PropTypes.number.isRequired,
    accepted: PropTypes.number.isRequired,
    rejected: PropTypes.number.isRequired,
    onHold: PropTypes.number.isRequired,
    autoRejected: PropTypes.number.isRequired,
  }).isRequired,
  projectRole: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    ycProject: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
  loadProjectRole: PropTypes.func.isRequired,
  downloadApplications: PropTypes.func.isRequired,
};

Applications.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ ipma, user }) => ({
  bar: ipma.applications.bar,
  projectRole: ipma.projectRoles.view,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadProjectRole: (payload) => dispatch(loadIpmaProjectRoleCreator(payload, null)),
  downloadApplications: (roleId, roleStatus) =>
    dispatch(downloadIpmaApplicationsCreator(roleId, roleStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
