import styled, { css } from 'styled-components';
import { pt, pb, px } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export default styled.button`
  ${({ theme, bordered }) =>
    theme &&
    css`
      width: 100%;

      ${pt(16)}
      ${pb(16)}
      ${px(16)}

      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      font-family: inherit;
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.semibold};

      color: ${!bordered ? theme.colors.white : theme.colors.primary};
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.primary};

      border-width: 2px;
      border-style: solid;
      border-radius: 2px;

      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: ${theme.colors.white};
        border-color: ${theme.colors.primaries.darker};
        background-color: ${theme.colors.primaries.darker};
      }
    `}

  ${({ theme, auto }) =>
    theme &&
    auto &&
    css`
      ${breakpoint(auto)`
        width: auto;
      `}
    `}

  ${({ theme, secondary, bordered }) =>
    theme &&
    secondary &&
    css`
      color: ${!bordered ? theme.colors.white : theme.colors.secondary};
      border-color: ${theme.colors.secondary};
      background-color: ${theme.colors.secondary};

      &:hover {
        color: ${theme.colors.white};
        border-color: ${theme.colors.secondaries.darker};
        background-color: ${theme.colors.secondaries.darker};
      }
    `}

  ${({ theme, bordered }) =>
    theme &&
    bordered &&
    css`
      background-color: transparent;
    `}

  ${({ theme, text }) =>
  theme &&
  text &&
  css`
    background-color: transparent;
    border-width: 0;

    &:hover {
      background-color: transparent;
    }
  `}

  ${({ theme, primaryDark }) =>
  theme &&
  primaryDark &&
  css`
    color: ${theme.colors.primaries.darker};
    &:hover {
      color: ${theme.colors.primaries.darker};
    }
  `}  

  ${({ theme, disabled }) =>
  theme &&
  disabled &&
  css`
    background-color: ${theme.colors.gray['400']};
    border-color: ${theme.colors.gray['400']};
    color: #fff;
    pointer-events: none;
  `}

  ${({ theme, small, icon }) =>
  theme &&
  icon &&
  small &&
  css`
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  `}

  ${({ resend }) =>
  resend &&
  css`
    margin-left: 10px;
    display: flex;
    height: 40px;
    width: 25%;
    padding-top: 0;
    padding-bottom: 0;
  `}
`;
