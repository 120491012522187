import styled, { css } from 'styled-components';
import { ml } from 'styled-components-spacing';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Name = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      ${ml(12)};

      color: ${theme.colors.tertiary};
      font-size: ${theme.font.size.pixel('16')};
      font-weight: ${theme.font.weight.semibold};
    `
  }
`;

export const NameLink = styled.a`
  ${({ theme }) =>
    theme &&
    css`
      ${ml(12)};

      color: ${theme.colors.tertiary};
      font-size: ${theme.font.size.pixel('16')};
      font-weight: ${theme.font.weight.semibold};
      
      &:hover {
        color: ${theme.colors.tertiaries.darker};
      }
    `
  }
`;


