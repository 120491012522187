import styled, { css } from 'styled-components';
import { px, py, mr, my } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const Head = styled.thead`
  ${({ theme }) =>
    theme &&
    css`
      display: none;
      background-color: ${theme.colors.tertiary};
    `}
`;

export const Body = styled.tbody``;

export const Row = styled.tr`
  ${({ theme }) =>
    theme &&
    css`
      ${py(8)};
      ${px(16)};

      display: block;
      margin-left: -16px;
      margin-right: -16px;

      &:nth-of-type(even) {
        background-color: #fbfbfb;
      }
    `}
`;

export const Heading = styled.th`
  ${({ theme }) =>
    theme &&
    css`
      display: block;
      color: #fff;
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.semibold};
      vertical-align: middle;
    `}
`;

export const Cell = styled.td`
  ${({ theme, middle }) =>
    theme &&
    css`
      ${my(8)};

      display: block;
      color: ${theme.colors.gray[500]};
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.semibold};
      ${middle &&
      css`
        vertical-align: middle;
      `}
    `}
`;

export const Label = styled.span`
  ${({ theme }) =>
    theme &&
    css`
      ${mr(4)};

      display: inline-block;
      font-weight: ${theme.font.weight.regular};
    `}
`;

export const Table = styled.table`
  ${({ theme }) =>
    theme &&
    css`
      width: 100%;
      text-align: left;

      ${({ tablebreakpoint }) =>
        tablebreakpoint &&
        css`
          ${breakpoint(tablebreakpoint)`
        ${css`
          ${Label} {
            display: none;
          }

          ${Head} {
            display: table-header-group;
          }

          ${Heading},
          ${Cell} {
            ${px(16)};
            ${py(12)};

            font-weight: ${theme.font.weight.regular};
            display: table-cell;
          }

          ${Cell} {
            font-size: ${theme.font.size.pixel('16')};
          }

          ${Row} {
            display: table-row;
          }
        `}
      `}
        `}
    `}
`;
