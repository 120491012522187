import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { useTranslation } from 'react-i18next';

function CertificationsStatus({ certs }) {
  const { t } = useTranslation();

  return (
    <Table tablebreakpoint="lg">
      <Table.Head>
        <Table.Row>
          <Table.Heading>{t('page.userProfile.certificate')}</Table.Heading>
          <Table.Heading>{t('page.userProfile.awardedDate')}</Table.Heading>
          <Table.Heading>{t('page.userProfile.validityDate')}</Table.Heading>
          <Table.Heading>{t('page.userProfile.certificateOwner')}</Table.Heading>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {certs.map((cert, index) => (
          <Table.Row key={`cert-${index}`}>
            <Table.Cell>
              <Table.Label>{t('page.userProfile.certificate')}:</Table.Label>
              {cert.name}
            </Table.Cell>
            <Table.Cell>
              <Table.Label>{t('page.userProfile.awardedDate')}:</Table.Label>
              {cert.awardedDate}
            </Table.Cell>
            <Table.Cell>
              <Table.Label>{t('page.userProfile.validityDate')}:</Table.Label>
              {cert.validityDate}
            </Table.Cell>
            <Table.Cell>
              <Table.Label>{t('page.userProfile.certificateOwner')}:</Table.Label>
              {cert.ownerName}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

CertificationsStatus.propTypes = {
  certs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      awardedDate: PropTypes.string,
      validityDate: PropTypes.string,
      ma: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
};

CertificationsStatus.defaultProps = {
  certs: [],
};

export default CertificationsStatus;
