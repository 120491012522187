import styled, { css } from 'styled-components';
import { mt } from 'styled-components-spacing';
import { Row } from 'components';

export const ProfileEditForm = styled.form``;

export const PrivacyHeading = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel(18)};
      font-weight: ${theme.font.weight.bold};
      line-height: 23px;
      color: ${theme.colors.gray[500]};
    `}
`;
export const PrivacyDescription = styled.div`
  ${mt(8)}
  ${({ theme }) =>
    theme &&
    css`
      line-height: 21px;
      color: ${theme.colors.gray[500]};
    `}
`;

export const FormPrivacyDescription = styled.div`
  ${mt(8)}
  ${({ theme }) =>
    theme &&
    css`
      line-height: 16px;
      width: 600px;
      color: ${theme.colors.gray[500]};
    `}
`;

export const PrivacyRadioLabel = styled.div`
  font-weight: bold;
`;

export const VerticalRow = styled(Row)`
  flex-direction: column;
  margin-left: 0;
`;
