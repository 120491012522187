import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH } from '..';

export const forgot = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'resetting', 'reset_password'), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};

export const reset = async (params, token) => {
  return ky.post(urljoin(BASE_PATH, 'user', 'resetting', String(token), 'change_password'), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};
