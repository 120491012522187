import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Col, Modal, Row, Heading, Box, IconText } from 'components';
import ModalContent, { Action } from 'components/Modal/Modal.styled';
import { connect } from 'react-redux';
import { parseBoxesList } from 'helpers';
import { ItemLink } from '../../components/Navbar/List/List.styled';
import { MyLibraryLink, MyLibraryWrapper, Steps } from './MyLibrary.styled';

const MyLibrary = ({ user, memberAssociationPeople, memberRegPartner, mobile, ma, screen }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [parsedMa, setParsedMa] = useState(null);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  useEffect(() => {
    const parseMa = () => {
      const parsed = parseBoxesList('ma', [ma])[0];
      setParsedMa(parsed);
    };
    if (ma) {
      parseMa();
    }
  }, [ma]);

  const onClickLibrary = () => {
    if (memberAssociationPeople === 'Yes') {
      const redirectUrl = `https://myipma.vanharen.net/ipma/customers/login/?firstname=${user.firstName}&lastname=${user.lastName}&email=${user.email}&voucher=IPMAWORLD`;
      window.open(redirectUrl, '_blank').focus();
    } else {
      openModal();
    }
  };

  const onClickShop = () => {
    const baseUrl = `https://bookshop.ipma.world/ipma/customers/login/?firstname=${user.firstName}&lastname=${user.lastName}&email=${user.email}`;

    if(memberRegPartner === 'Yes') {
      const redirectUrl = `${baseUrl}&voucher=IPMAWorldTR`;
      window.open(redirectUrl, '_blank').focus();
    }
    else if (memberAssociationPeople === 'Yes') {
      const redirectUrl = `${baseUrl}&voucher=IPMAWorldMA`;
      window.open(redirectUrl, '_blank').focus();
    } else {
      openModal();
    }
  };

  const renderContent = () => (
    <ModalContent>
      <Heading as="h4" noLine center>
        {t('modals.headings.myLibraryTitle')}
      </Heading>
      <Margin top="16">
        {parsedMa ? (
          t('modals.desc.myLibraryDesc')
        ) : (
          <>
            {t('modals.desc.myLibraryNoMa')}
            <Margin top="16">
              <Steps>
                <li>
                  <a href="/ipma-associations" target="_blank" rel="noopener noreferrer">
                    {t('modals.desc.myLibraryStepOne')}
                  </a>
                </li>
                <li>
                  <a href="/user/profile/edit" target="_blank" rel="noopener noreferrer">
                    {t('modals.desc.myLibraryStepTwo')}
                  </a>
                </li>
                <li>{t('modals.desc.myLibraryStepThree')}</li>
              </Steps>
            </Margin>
          </>
        )}
      </Margin>
      <Margin top="32">
        <Margin bottom="32" style={{ textAlign: 'initial' }}>
          {parsedMa ? (
            <Box
              key={parsedMa.id}
              thumbnail={parsedMa.thumbnail}
              title={parsedMa.title}
              subtitle={parsedMa.subtitle}
              url={parsedMa.url}
              type="ma"
              description={parsedMa.description}
              price={parsedMa.price || null}
              renderInfo={() => (
                <>
                  {parsedMa.place && <IconText icon="marker" text={parsedMa.place} />}
                  {parsedMa.events && (
                    <IconText icon="checkRectangle" text={`Events (${parsedMa.events})`} />
                  )}
                </>
              )}
              headerAs="h2"
            />
          ) : null}
        </Margin>
        <Row halign="center">
          <Col size={{ xs: 1 / 2 }}>
            <Action onClick={closeModal} bordered>
              {t('global.closeButton')}
            </Action>
          </Col>
        </Row>
      </Margin>
    </ModalContent>
  );

  return (
    <>
      {screen ? (
        <MyLibraryWrapper>
          <MyLibraryLink onClick={onClickLibrary}>{t('global.shop')}</MyLibraryLink>
          <MyLibraryLink onClick={onClickShop}>{t('global.bookShop')}</MyLibraryLink>
        </MyLibraryWrapper>
      ) : (
        <ItemLink
          as="button"
          type="button"
          horizontal={mobile ? '' : 'true'}
          light={mobile ? '' : 'true'}
          smallfont={mobile ? '' : 'true'}
          onClick={onClickLibrary}
        >
          {t('global.myLibrary')}
        </ItemLink>
      )}
      <Modal isOpen={open} customContent={renderContent} onRequestClose={closeModal} />
    </>
  );
};

MyLibrary.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  permissions: PropTypes.shape({}),
  mobile: PropTypes.bool,
  ma: PropTypes.shape({}),
  memberAssociationPeople: PropTypes.string,
};

MyLibrary.defaultProps = {
  permissions: null,
  mobile: false,
  ma: {},
  memberAssociationPeople: '',
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  permissions: user.userPermissions,
  memberAssociationPeople: user.memberAssociationPeople,
  memberRegPartner: user.memberRegPartner,
  ma: user.user.memberAssociation,
});

export default connect(mapStateToProps, null)(MyLibrary);
