import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BoxList, FiltersControls, Pagination, Spinner } from 'components';
import {
  duplicateMaEventCreator,
  loadMaEventsCreator,
  setMaEventsPageCreator,
  setMaEventsPerPageCreator,
} from 'store/actionsCreators';
import { checkPermissions, globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';

const Completed = ({
  events,
  page,
  pages,
  perPage,
  loading,
  loadEvents,
  setPage,
  setPerPage,
  duplicateEvent,
  userPermissions,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadEvents({ status: 'completed' });
  }, [perPage, page, loadEvents]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="event"
            items={events}
            alert={t('global.noResultsFound')}
            customRenderActions={
              checkPermissions(
                {
                  roles: ['ma_my_events_create_event'],
                },
                userPermissions,
              )
                ? ({ item, ButtonAction }) => (
                    <ButtonAction
                      icon="duplicate"
                      title={t('global.duplicateButton')}
                      verticalviewbreakpoint="xl"
                      onClick={() => duplicateEvent(item.id)}
                    />
                  )
                : null
            }
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </>
      ) : null}
    </>
  );
};

Completed.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadEvents: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  duplicateEvent: PropTypes.func.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

const mapStateToProps = ({ ma, loading, user }) => ({
  events: ma.events.list.items,
  page: ma.events.list.page,
  pages: ma.events.list.pages,
  perPage: ma.events.list.perPage,
  loading: isLoading(loading, 'MA'),
  userPermissions: user.userPermissions,
});
const mapDispatchToProps = (dispatch) => ({
  loadEvents: (payload) => dispatch(loadMaEventsCreator(payload)),
  setPage: (payload) => dispatch(setMaEventsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setMaEventsPerPageCreator(payload)),
  duplicateEvent: (payload) => dispatch(duplicateMaEventCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Completed);
