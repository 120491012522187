import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Margin } from 'styled-components-spacing';
import { connect } from 'react-redux';

import {
  Alerts,
  Button,
  Col,
  Container,
  Header,
  Field,
  Password,
  Row,
  PageContent,
} from 'components';

import { editPasswordCreator } from 'store/actionsCreators';
import { ProfileEditForm } from 'containers/user/PasswordEdit/PasswordEdit.styled';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.profile',
    url: '/user/profile',
  },
  {
    title: 'navigation.breadcrumbs.user.editPassword',
    url: '/user/profile/password',
  },
];

const Spacer = ({ double, children }) => (
  <Margin top={{ xs: 12 * (double ? 2 : 1) }}>{children}</Margin>
);

function PasswordEdit({ errors, loading, editPassword, history }) {
  const { t } = useTranslation();
  const { setValue, register, handleSubmit } = useForm({
    defaultValues: {
      password: '',
      repassword: '',
    },
  });

  useEffect(() => {
    register({ name: 'password' });
    register({ name: 'repassword' });
  }, [register]);

  const onSubmit = (data) => {
    editPassword({ password: data }, history);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValue(name, value, true);
  };

  return (
    <>
      <Header title={t('page.user.editPassword.title')} breadcrumbs={breadcrumbs} />
      <PageContent>
        <Container>
          <Alerts type="editProfile" />
          <ProfileEditForm onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editPassword.passwordLabel')}
                    id="password"
                    required
                    error={errors.password}
                  >
                    <Password
                      id="password"
                      placeholder={t('page.user.editPassword.passwordPlaceholder')}
                      name="password"
                      onChange={handleChange}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editPassword.repeatPasswordLabel')}
                    id="repassword"
                    required
                    error={errors.repassword}
                  >
                    <Password
                      id="repassword"
                      placeholder={t('page.user.editPassword.repeatPasswordPlaceholder')}
                      name="repassword"
                      onChange={handleChange}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 1 / 5, xl: 1 / 6 }}>
                <Spacer double>
                  <Button type="submit">{t('global.saveButton')}</Button>
                </Spacer>
              </Col>
            </Row>
          </ProfileEditForm>
        </Container>
      </PageContent>
    </>
  );
}

const mapStateToProps = ({ editPassword }) => ({
  errors: editPassword.errors,
  loading: editPassword.loading,
});
const mapDispatchToProps = (dispatch) => ({
  editPassword: (payload, history) => dispatch(editPasswordCreator(payload, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordEdit);
