import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { Wrapper, Action, Arrow, Content } from './Dropdown.styled';

const Dropdown = ({ title, children, openbreakpoint, openOnLoad }) => {
  const [open, setOpen] = useState(openOnLoad);

  return (
    <Wrapper open={open} openbreakpoint={openbreakpoint}>
      <Action onClick={() => setOpen(!open)} type="button">
        {title}
        <Arrow>
          <ReactSVG src={iconList.arrowDown} />
        </Arrow>
      </Action>
      <Content>{children}</Content>
    </Wrapper>
  );
};

Dropdown.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  openbreakpoint: PropTypes.string,
  openOnLoad: PropTypes.bool,
};

Dropdown.defaultProps = {
  children: null,
  openbreakpoint: '',
  openOnLoad: false,
};

export default Dropdown;
