import { isEmpty, isNumber, pickBy } from 'lodash';
import { parseDate } from 'helpers';

export default (params) => {
  params = pickBy(params, (v) => !isEmpty(v) || isNumber(v) || v instanceof Date);

  Object.keys(params).forEach((key) => {
    if (params[key] instanceof Date) {
      params[key] = parseDate(params[key], 'YYYY-MM-DD HH:mm:ss');
    }
  });

  return params;
};
