import styled from 'styled-components';

export const InnerText = styled.div`
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: #424242;

  b {
    font-weight: 600;
  }
`;
