import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Container, Row, Col, Heading, Button } from 'components';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Wrapper, Content, Description } from './Header.styled';

const Header = ({
  breadcrumbs,
  title,
  description,
  secondDescription,
  renderActions,
  big,
  headingFirstLine,
}) => {
  const renderActionsComponents = { Button };

  const renderActionContent = () => {
    if (typeof renderActions === 'function') {
      return (
        <Col size={{ lg: 1 / 3, xl: 2 / 6 }}>
          <div className="text-right">
            <Margin top={{ xs: 24, lg: 0 }}>{renderActions(renderActionsComponents)}</Margin>
          </div>
        </Col>
      );
    }

    return null;
  };

  return (
    <Wrapper big={big}>
      <Container>
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
        <Content big={big}>
          <Row valign="center">
            <Col size={{ lg: 2 / 3, xl: 4 / 6 }}>
              <Heading as="h1" color="#fff" firstLine={headingFirstLine}>
                {title}
              </Heading>
              {description && <Description>{description}</Description>}
              {secondDescription && <Description>{secondDescription}</Description>}
            </Col>
            {renderActionContent()}
          </Row>
        </Content>
      </Container>
    </Wrapper>
  );
};

Header.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string,
  description: PropTypes.string,
  secondDescription: PropTypes.string,
  renderActions: PropTypes.func,
  big: PropTypes.bool,
  headingFirstLine: PropTypes.bool,
};

Header.defaultProps = {
  breadcrumbs: null,
  title: '',
  description: '',
  secondDescription: '',
  renderActions: null,
  big: false,
  headingFirstLine: false,
};

export default Header;
