import styled, { css } from 'styled-components';
import { Action } from 'components/Modal/Modal.styled';

export const StyledAction = styled(Action)`
  ${({ theme }) =>
    css`
      width: 100%;
      padding: 12px 16px;
      height: 100%;

      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      font-family: inherit;
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.semibold};

      color: ${theme.colors.white};
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.primary};

      border-width: 2px;
      border-style: solid;
      border-radius: 2px;

      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: ${theme.colors.white};
        border-color: ${theme.colors.primaries.darker};
        background-color: ${theme.colors.primaries.darker};
      }
    `}
`;
