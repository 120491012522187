import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { FiltersControls, Pagination, Spinner, Table, ButtonAction } from 'components';
import {
  loadYcProjectRoleApplicationsCreator,
  setYcProjectRoleApplicationPageCreator,
  setYcProjectRoleApplicationPerPageCreator,
} from 'store/actionsCreators';
import { isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FlexColumn } from '../Application.styled';

const Rejected = ({
  applications,
  page,
  pages,
  perPage,
  loading,
  loadApplications,
  setPage,
  setPerPage,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadApplications({ status: 'rejected' }, id);
  }, [perPage, page, loadApplications]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      <Table tablebreakpoint="lg">
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('page.yc.applications.firstAndLastName')}</Table.Heading>
            <Table.Heading>{t('page.yc.applications.email')}</Table.Heading>
            <Table.Heading>{t('page.yc.applications.createdAt')}</Table.Heading>
            <Table.Heading>{t('page.yc.applications.cvAndLetter')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {loading ? (
            <Table.Row>
              <Table.Cell colSpan={6}>
                <Spinner />
              </Table.Cell>
            </Table.Row>
          ) : null}
          {!loading && !applications.length ? (
            <Table.Row>
              <Table.Cell colSpan={6}>{t('global.noResultsFound')}</Table.Cell>
            </Table.Row>
          ) : null}
          {!loading && applications.length
            ? applications.map((application) => (
                <Table.Row key={application.id}>
                  <Table.Cell middle>
                    <Table.Label>{t('page.yc.applications.firstAndLastName')}:</Table.Label>
                    {`${application.name} ${application.surname}`}
                  </Table.Cell>
                  <Table.Cell middle>
                    <Table.Label>{t('page.yc.applications.email')}:</Table.Label>
                    {application.email}
                  </Table.Cell>
                  <Table.Cell middle>
                    <Table.Label>{t('page.yc.applications.createdAt')}:</Table.Label>
                    {application.updatedAt && moment(application.updatedAt).format('DD/MM/YYYY')}
                  </Table.Cell>
                  <Table.Cell middle>
                    <Table.Label>{t('page.yc.applications.cvAndLetter')}:</Table.Label>
                    <FlexColumn>
                      <ButtonAction
                        onClick={() => {}}
                        title={t('page.yc.applications.downloadCv')}
                        icon="fileUpload"
                        as="a"
                        target="_blank"
                        rel="nofollow noopener"
                        href={application.cv}
                        disabled={!application.cv}
                      />
                      <ButtonAction
                        onClick={() => {}}
                        title={t('page.yc.applications.downloadLetter')}
                        icon="fileUpload"
                        as="a"
                        target="_blank"
                        rel="nofollow noopener"
                        href={application.letter}
                        disabled={!application.letter}
                      />
                    </FlexColumn>
                  </Table.Cell>
                </Table.Row>
              ))
            : null}
        </Table.Body>
      </Table>
      <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
    </>
  );
};

Rejected.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadApplications: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ yc, loading }) => ({
  applications: yc.applications.list.items,
  page: yc.applications.list.page,
  pages: yc.applications.list.pages,
  perPage: yc.applications.list.perPage,
  loading: isLoading(loading, 'YC'),
});

const mapDispatchToProps = (dispatch) => ({
  loadApplications: (payload, id) => dispatch(loadYcProjectRoleApplicationsCreator(payload, id)),
  setPage: (payload) => dispatch(setYcProjectRoleApplicationPageCreator(payload)),
  setPerPage: (payload) => dispatch(setYcProjectRoleApplicationPerPageCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rejected);
