import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cisRedirectCreator } from 'store/actionsCreators';
import { Margin } from 'styled-components-spacing';
import { isLoading } from 'helpers';
import { Row, Col, Heading, Button, Spinner } from 'components';
import { Link } from 'react-router-dom';
import LayoutImage from 'layouts/LayoutImage/LayoutImage';
import { useTranslation } from 'react-i18next';

const Cis = ({ loading, cisRedirect }) => {
  const { t } = useTranslation();

  useEffect(() => {
    // window.location.href = 'https://cis.ipma.world/login';
    (() => cisRedirect())();
  }, []);

  return (
    <Margin top="64">
      {loading ? (
        <Spinner />
      ) : (
        <LayoutImage>
          <Row valign="center">
            <Col size={{ md: 3 / 5 }}>
              <Heading as="h2">{t('page.forbidden.title')}</Heading>
              <Margin top="20">
                <p>{t('page.forbidden.subtitle')}</p>
              </Margin>
              <Margin top="24">
                <Row>
                  <Col size={{ md: 2 / 3, lg: 1 / 2 }}>
                    <Button as={Link} to="/">
                      {t('page.forbidden.returnButton')}
                    </Button>
                  </Col>
                </Row>
              </Margin>
            </Col>
          </Row>
        </LayoutImage>
      )}
    </Margin>
  );
};

Cis.propTypes = {
  loading: PropTypes.bool.isRequired,
  cisRedirect: PropTypes.func.isRequired,
};

const mapStateToProps = ({ loading }) => ({
  loading: isLoading(loading, 'CIS_REDIRECT'),
});

const mapDispatchToProps = (dispatch) => ({
  cisRedirect: () => dispatch(cisRedirectCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cis);
