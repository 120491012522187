import styled, { css } from 'styled-components';
import { p, py, px, mt } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
    text-align: center;

    ${breakpoint('sm')`
      ${css`
        display: flex;
      `}
    `}
  
    ${({ shadow }) =>
      shadow &&
      css`
        border-bottom: 1px solid ${theme.colors.gray['200']};

        ${breakpoint('sm')`
        ${css`
          border-bottom: none;
          box-shadow: 0 0 24px rgba(79, 78, 92, 0.16);
        `}
      `}
      `}  
  
    ${({ short }) =>
      short &&
      css`
        ${Thumbnail} {
          display: inline-block;
          flex: 0 0 140px;
          width: 140px;
          height: 140px;
        }

        ${Content} {
          ${mt(0)};
          ${py(16)};
          ${px(0)};

          border: none;

          ${breakpoint('sm')`
          ${css`
            ${p(16)};
          `}
        `}
        }
      `}
  `}
`;

export const Thumbnail = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      width: 180px;
      height: 180px;
      display: inline-block;

      img {
        max-height: 100%;
      }

      ${breakpoint('sm')`
      ${css`
        display: block;
        flex: 0 0 240px;
        width: 240px;
        height: 240px;
      `}
    `}
    `}
`;

export const Content = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(16)};
      ${p(8)};

      text-align: left;
      flex: 1;
      border: 1px solid ${theme.colors.gray['200']};

      ${breakpoint('sm')`
      ${css`
        ${mt(0)};
        ${p(38)};
      `}
    `}
    `}
`;
