import styled, { css } from 'styled-components';
import { mr } from 'styled-components-spacing';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: left;
`;

export const Icon = styled.div`
  > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    ${mr(12)};

    width: 40px;
    height: 40px;
    fill: ${({ theme }) => theme.colors.tertiary};
    pointer-events: none;
  }
`;

export const Content = styled.div``;