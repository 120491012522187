import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { Row, Col, Heading, Field } from 'components';
import { Action } from 'components/Modal/Modal.styled';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { acceptCpdRequestCreator } from 'store/actionsCreators';

import { AcceptContent, AcceptError } from './AcceptModal.styled';

ReactModal.setAppElement('#modals');

const AcceptModal = ({ status, open, setOpen, acceptCpd, cpdPoints }) => {
  const [error, setError] = useState('');
  const [cpd, setCpd] = useState('');
  const [comment, setComment] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setCpd(cpdPoints);
  }, [open]);

  useEffect(() => {
    setError('');
  }, [cpd]);

  const onClose = () => {
    setOpen(0);
    setCpd('');
  };

  const submitAccept = () => {
    if (!cpd) {
      setError('CPD field cannot be empty.');
      return;
    }

    if (!parseInt(cpd, 10)) {
      setError('CPD must be a valid number.');
      return;
    }

    acceptCpd({
      id: open,
      type: status,
      cpdNumber: parseInt(cpd, 10),
      comment,
    });
    setOpen(0);
  };

  const renderUploadModal = () => (
    <AcceptContent>
      <Heading as="h4" noLine>
        Are you sure you want to accept this CPD?
      </Heading>

      <Margin top="24">
        <Row halign="center">
          <Col>You can change number of points requested for this CPD and provide comment</Col>
        </Row>
        <Margin top="12">
          <Row halign="center">
            <Col size={{ xs: 3 / 4 }}>
              <Field id="cpd-number-field" label="CPD Number" required>
                <input
                  id="cpd-number-input"
                  key="cpd-input"
                  type="number"
                  placeholder="CPD"
                  value={cpd}
                  onChange={(e) => setCpd(e.target.value)}
                />
              </Field>
            </Col>
          </Row>
        </Margin>
        <Margin top="12">
          <Row halign="center">
            <Col size={{ xs: 3 / 4 }}>
              <Field id="cpd-comment" label="Comment">
                <textarea
                  id="cpd-comment"
                  key="cpd-textarea"
                  type="text"
                  placeholder="Comment..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Field>
            </Col>
          </Row>
        </Margin>
      </Margin>
      <Margin top="16">
        <Row halign="center">
          <AcceptError>{error}</AcceptError>
        </Row>
        <Row halign="center">
          <Col size={{ xs: 1 / 2 }}>
            <Action onClick={submitAccept}>{t('global.acceptButton')}</Action>
          </Col>
          <Col size={{ xs: 1 / 2 }}>
            <Action onClick={onClose} bordered>
              {t('global.closeButton')}
            </Action>
          </Col>
        </Row>
      </Margin>
    </AcceptContent>
  );
  return (
    <ReactModal
      style={{
        content: {
          maxWidth: 600,
          width: '100%',
          top: '50%',
          bottom: 'initial',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          border: 'none',
          boxShadow: '0px 0px 48px 0px rgba(61,62,64,0.24)',
        },
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          zIndex: 40,
        },
      }}
      isOpen={open}
      onRequestClose={onClose}
    >
      {renderUploadModal()}
    </ReactModal>
  );
};

AcceptModal.propTypes = {
  status: PropTypes.string.isRequired,
  open: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
  acceptCpd: PropTypes.func.isRequired,
  cpdPoints: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  acceptCpd: (payload) => dispatch(acceptCpdRequestCreator(payload)),
});

export default connect(null, mapDispatchToProps)(AcceptModal);
