import styled, { css } from 'styled-components';
import { ml } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { Container } from 'components';

export const Wrapper = styled.ul`
  ${({ theme }) => theme && css`
    background-color: ${theme.colors.secondary};
  `}
`;

export const ContainerMobile = styled(Container)`
  ${breakpoint('xl')`
    ${css`
      display: none;
    `}
  `}
`;

export const ContainerDesktop = styled(Container)`
  display: none;

  ${breakpoint('xl')`
    ${css`
      display: block;
    `}
  `}
`;

export const Items = styled.ul`
  display: none;

  ${({ open }) => open && css`
    display: block;
  `}

  ${breakpoint('xl')`
    ${css`
      display: flex;
      align-items: center;
    `}
  `}
`;

export const Item = styled.li``;

export const Dropdown = styled.button`
  ${({ theme }) => theme && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      ${ml(8)};

      width: 10px;
      height: 6px;
      fill: #fff;
      pointer-events: none;
    }
    
    ${({ open }) => open && css`
      svg {
        transform-origin: center;
        transform: rotate(180deg);
      }
    `}

  `}
`;

export const ItemLink = styled.a`
  ${({ theme }) => theme && css`
    color: #fff;
    font-size: ${theme.font.size.pixel('14')};
    font-weight: ${theme.font.weight.bold};
    text-transform: uppercase;
    letter-spacing: 1.4px;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 40px;
    
    ${breakpoint('xl')`
      ${css`
        height: 48px;
        padding: 12px 20px;
        justify-content: center;
        
        &.active {
          background-color: #cc8914;
        }
      `}
    `}
      
    span {
      ${ml(4)};

      display: inline-block;
      font-weight: ${theme.font.weight.regular};
    }
  `}
`;
