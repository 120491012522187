import styled from 'styled-components';

export const ToggleButton = styled.button`
  width: 40px;
  height: 20px;
  position: relative;
  cursor: pointer;
  border-radius: 25px;
  outline: none;
  background-color: #ededed;
  border: 2px solid #ededed;

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    will-change: transform;
    transform: translate(${(props) => (props.on ? 1 : -17)}px);
    transition: transform 0.2s ease-out;
    width: 16px;
    height: 16px;
    background: ${(props) => (props.on ? '#F59C00' : '#D3D3D6')};
    outline: none;
    border-radius: 50%;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-weight: 600;
  margin-left: 8px;
  color: #949799;
  font-size: 14px;
`;
