const checkType = (el) => {
  const isArray = el && Array.isArray(el);
  const isString = el && !isArray && typeof el === 'string';
  const isObject = el && !isArray && !isString;

  return { isArray, isString, isObject };
};

const parseTypes = (obj, el, { isArray, isString, isObject }, key) => {
  if (isArray) {
    return {
      ...obj,
      ...parseFromArray(el, key),
    };
  }

  if (isString) {
    return {
      ...obj,
      [key]: el || null,
    };
  }

  if (isObject) {
    return {
      ...obj,
      ...parseFromObject(el, key),
    };
  }

  return { ...obj };
};

const parseFromObject = (obj, parent) => {
  return Object.keys(obj).reduce((prev, current) => {
    const error = obj[current];
    const newKey = `${parent}.${current}`;

    return parseTypes(prev, error, checkType(error), newKey);
  }, {});
};

const parseFromArray = (arr, parent) => {
  return arr.reduce((prev, current) => {
    const error = current;
    return parseTypes(prev, error, checkType(error), parent);
  }, {});
};

export const parseNestedErrors = (errors) => {
  return Object.keys(errors).reduce((prev, current) => {
    const error = errors[current];

    return parseTypes(prev, error, checkType(error), current);
  }, {});
};
