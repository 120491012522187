export default (file, allowedExtensions) => {
  const allowedExtensionsLower = allowedExtensions.map((item) => item.toLowerCase());
  if (file && file.name && file.name.includes('.')) {
    const parts = file.name.split('.');
    const ext = parts[parts.length - 1].toLowerCase();
    if (allowedExtensionsLower.includes(ext)) {
      return true;
    }
  }
  return false;
};
