import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { newLineToBr, globalPropTypes } from 'helpers';
import {
  IconText,
  Breadcrumbs,
  Col,
  Container,
  HeadingWrapper,
  Heading,
  ProfileHeader,
  UserList,
  Row,
  BoxList,
  Map,
} from 'components';
import { Margin, Padding } from 'styled-components-spacing';

import {
  loadOrganisationProfileCoursesCreator,
  loadUserOrganisationCreator,
} from 'store/actionsCreators';

import { ContactBox, Description } from '../Organisation.styled';

const Profile = ({ organisation, loadUserOrganisation, loadProfileCourses, courses }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.organisation.profile',
      url: `/organisation/${id}/profile`,
    },
  ];

  useEffect(() => {
    loadUserOrganisation(id);
    loadProfileCourses(id);
  }, []);

  const renderPlace = () => {
    if (!organisation) return null;
    const text = `${organisation.address.line1}, ${organisation.address.city} ${organisation.address.postCode}, ${organisation.address.country.name}`;

    return (
      <Padding all="20">
        <IconText top icon="marker" text={text} />
      </Padding>
    );
  };

  const renderMap = () => {
    if (!organisation) return null;
    if (!organisation.address.lat || !organisation.address.lang) return null;

    return <Map lat={organisation.address.lat} lng={organisation.address.lang} />;
  };

  if (!Object.keys(organisation).length) return null;

  return (
    <>
      <Container>
        <Padding top={{ xs: 38 }} bottom={{ xs: 38 }}>
          <Breadcrumbs items={breadcrumbs} />
          <ProfileHeader
            title={organisation.name}
            image={organisation.photo}
            actions={({ Button }) => (
              <>
                <Button as={Link} to={`/organisation/${id}/edit`}>
                  {t('organisations.editProfile')}
                </Button>
                <Margin top={{ xs: 16 }}>
                  <Button as={Link} to={`/organisation/${id}/people`}>
                    {t('organisations.manageUsers')}
                  </Button>
                </Margin>
              </>
            )}
          />
          <Row>
            <Col size={{ md: 4 / 6 }}>
              <Margin as={Description} top={{ xs: 38, md: 0 }}>
                <p
                  dangerouslySetInnerHTML={{ __html: newLineToBr(organisation.fullDescription) }}
                />
              </Margin>
              <Margin top={{ xs: 38 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('courses.new')}
                  </Heading>
                  <Link to="/training" className="link uppercase">
                    {t('global.seeAll')}
                  </Link>
                </HeadingWrapper>
                <BoxList type="course" items={courses} alert={t('global.noResultsCourses')} />
              </Margin>
            </Col>
            <Col size={{ md: 2 / 6 }}>
              <div className="border-xl">
                <Margin top={{ xs: 38, md: 0 }}>
                  <ContactBox>
                    <Margin bottom="16">
                      <Heading as="h3" size="h6">
                        {t('global.contactUs')}
                      </Heading>
                    </Margin>

                    {organisation.telephone && (
                      <Margin bottom="12">
                        <a href={`tel:${organisation.telephone}`}>{organisation.telephone}</a>
                      </Margin>
                    )}

                    {organisation.email && (
                      <Margin bottom="12">
                        <a
                          href={`mailto:${organisation.email}`}
                          className="link link--16 word-break"
                        >
                          {organisation.email}
                        </a>
                      </Margin>
                    )}

                    {organisation.website && (
                      <a href={organisation.website} className="link link--16 word-break">
                        {organisation.website}
                      </a>
                    )}
                  </ContactBox>
                  {renderPlace()}
                  {renderMap()}
                </Margin>
              </div>
              {organisation.organizationRoles && !!organisation.organizationRoles.length ? (
                <Margin top="32">
                  {organisation.organizationRoles.map((item) => (
                    <Margin top="32">
                      <UserList title={`${item.name}:`} users={item.items} />
                    </Margin>
                  ))}
                </Margin>
              ) : null}
            </Col>
          </Row>
        </Padding>
      </Container>
    </>
  );
};

Profile.propTypes = {
  organisation: globalPropTypes.OrganisationProfileTypes.isRequired,
  loadUserOrganisation: PropTypes.func.isRequired,
  loadProfileCourses: PropTypes.func.isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({})),
};

Profile.defaultProps = {
  courses: [],
};

const mapStateToProps = ({ organisation }) => ({
  organisation: organisation.profile,
  courses: organisation.profileCourses,
});

const mapDispatchToProps = (dispatch) => ({
  loadUserOrganisation: (payload) => dispatch(loadUserOrganisationCreator(payload)),
  loadProfileCourses: (payload) => dispatch(loadOrganisationProfileCoursesCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
