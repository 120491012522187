import React from 'react';
import { Container, Header, Cms } from 'components';
import { Margin } from 'styled-components-spacing';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.privacyPolicy',
    url: '',
  },
];

const PrivacyPolicy = () => {
  return (
    <>
      <Header breadcrumbs={breadcrumbs} title="Privacy Policy" />
      <Margin vertical={{ xs: 32, md: 48 }}>
        <Container>
          <Cms>
            <p>
              While creating your account within my.ipma platform, you enter your personal data,
              e.g. your name, surname, e-mail address, date of birth, gender, occupational data and
              country of residence into the system, giving IPMA and the respective national Member
              Association in your country of residence the right to contact you. You have the right
              to choose the amount of data publicly shown in your profile, while your personal
              telephone number will never be displayed publicly and is stored in the internal
              database of my.ipma users visible only to IPMA. Personal data will not be sold to or
              shared with other third parties.
            </p>

            <p>
              IPMA takes appropriate technical and organisational measures to ensure the security of
              your personal data and to protect it against loss or unlawful use. Access to your
              personal data is restricted on a need- to-know basis and by access controls. Our
              information technology environment is appropriately protected and monitored, with
              regular updates, testing and assessment to ensure ongoing security. The personal data
              controller is IPMA, which processes personal data in accordance with the GDPR.
            </p>

            <p>
              Technical data collected automatically can be used to analyze the behavior of users
              using my.ipma platform, as well as for the purpose of maintaining statistics on the
              audience or to personalize its content. We keep your personal data for as long as
              necessary to fulfil the purpose for which it was collected. This could be to use
              my.ipma platform, to receive newsletters and to ensure stability of the
              platform/system/network. As soon as your personal data are no longer required, they
              will be deleted or anonymized, to the extent possible. In general, shorter retention
              periods of no more than twelve months apply for operational data (e.g., system logs).
            </p>

            <p>
              You can find more information about the processing of your personal data and data
              security in <strong>the GDPR of International Project Management Association</strong>,
              which constitutes an integral part of this agreement, on the following link:
              <a href="https://www.ipma.world/about-us/ipma-disclaimer/" target="_blank" rel="noopener noreferrer">
                www.ipma.world/privacy-policy/
              </a>
            </p>
          </Cms>
        </Container>
      </Margin>
    </>
  );
};

export default PrivacyPolicy;
