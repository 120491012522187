import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';

const Heading = styled.h1`
  ${({ theme, as, size = as, color, firstLine, noLine, center }) =>
    css`
      font-weight: ${theme.font.weight.bold};
      color: ${color || theme.colors.gray['600']};
      position: relative;
      z-index: 1;
      line-height: 1.1;
      word-break: break-word;
      text-align: left;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 24px;
        width: 128px;
        height: 10px;
        background-color: ${theme.colors.secondary};
        z-index: -1;

        ${breakpoint('xl')`
          height: 12px;
          top: 34px;
        `}
      }
      
      ${firstLine && css`
        &::first-line {
          content: "";
          border-bottom: 10px solid ${theme.colors.secondary};

          ${breakpoint('xl')`
            border-bottom-width: 12px;
          `}
        }

        &::before {
          content: none;
        }
      `}

      ${size === 'h1' && css`
        font-size: ${theme.font.size.pixel('32')};

        ${breakpoint('xl')`
          font-size: ${theme.font.size.pixel('42')};
        `}
      `}

      ${size === 'h2' && css`
        font-size: ${theme.font.size.pixel('28')};

        ${breakpoint('xl')`
          font-size: ${theme.font.size.pixel('36')};
        `}

        &::before {
          top: 21px;

          ${breakpoint('xl')`
            top: 28px;
          `}
        }
      `}

      ${size === 'h3' && css`
        font-size: ${theme.font.size.pixel('24')};

        ${breakpoint('xl')`
          font-size: ${theme.font.size.pixel('30')};
        `}

        &::before {
          width: 64px;
          height: 8px;
          top: 18px;

          ${breakpoint('xl')`
            top: 25px;
          `}
        }
        
        &::first-line {
          border-bottom-width: 8px;
        }
      `}

      ${size === 'h4' && css`
        font-size: ${theme.font.size.pixel('20')};

        ${breakpoint('xl')`
          font-size: ${theme.font.size.pixel('25')};
        `}
        
        &::before {
          width: 64px;
          height: 6px;
          top: 16px;

          ${breakpoint('xl')`
            height: 8px;
            top: 19px;
          `}
        }
        
        &::first-line {
          border-bottom-width: 6px;

          ${breakpoint('xl')`
            border-bottom-width: 8px;
          `}
        }
      `}

      ${size === 'h5' && css`
        font-size: ${theme.font.size.pixel('18')};

        ${breakpoint('xl')`
          font-size: ${theme.font.size.pixel('20')};
        `}

        &::before {
          content: none;
        }

        &::first-line {
          border-bottom: none;
        }
      `}

      ${size === 'h6' && css`
        font-size: ${theme.font.size.pixel('16')};

        ${breakpoint('xl')`
          font-size: ${theme.font.size.pixel('17')};
        `}
        
        &::before {
          content: none;
        }
        &::first-line {
          border-bottom: none;
        }
      `}
      
      ${noLine && css`
        &::before {
          content: none;
        }

        &::first-line {
          border-bottom: none;
        }
      `}      

      ${center && css`
        text-align: center;
        
        &::before {
          left: 50%;
          transform: translate3d(-50%, 0, 0);
        }
      `}
    `
  }
`;

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  firstLine: PropTypes.bool,
  noLine: PropTypes.bool,
  center: PropTypes.bool,
  color: PropTypes.string,
};

Heading.defaultProps = {
  as: 'h1',
  firstLine: false,
  noLine: false,
  center: false,
};

export default Heading;
