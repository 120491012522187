import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ButtonSearch } from 'components';
import { Wrapper } from './SearchNew.styled';

const SearchNew = ({ closeFn, small, ...props }) => {
  const { handleSubmit } = useForm();
  const onSubmit = () => {};

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)} {...props}>
      <ButtonSearch
        icon="searchIcon"
        secondary
        rounded
        large={!small}
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      />
    </Wrapper>
  );
};

SearchNew.propTypes = {
  closeFn: PropTypes.func,
  small: PropTypes.bool,
};

SearchNew.defaultProps = {
  closeFn: () => {},
  small: false,
};

export default SearchNew;
