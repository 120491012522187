import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  UserList,
  IconText,
  Map,
  Breadcrumbs,
  ProfileHeader,
  BoxList,
  PrivateComponent,
} from 'components';
import noAvatar from 'assets/images/no_avatar.jpg';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { globalPropTypes, newLineToBr } from '../../../../helpers';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.yc.profile',
    url: '/yc/profile/view',
  },
];

const View = ({ profile, usersRole, upcomingEvents }) => {
  const { t } = useTranslation();
  if (!profile) return null;

  const parseAddress = (profileAddress) => {
    let address = '';
    if (profileAddress.line1) {
      address += profileAddress.line1;
    }

    if (profileAddress.line2) {
      address += `, ${profileAddress.line2}`;
    }

    if (profileAddress.city) {
      address += `, ${profileAddress.city}`;
    }

    if (profileAddress.postCode) {
      address += `, ${profileAddress.postCode}`;
    }

    if (profileAddress.country && profileAddress.country.label) {
      address += `, ${profileAddress.country.label}`;
    }

    return address;
  };

  return (
    <>
      <PageContent>
        <Container>
          <Breadcrumbs items={breadcrumbs} />
          <Margin bottom="24">
            <ProfileHeader
              title={profile.name}
              image={profile.photo ? profile.photo : noAvatar}
              actions={({ Button }) => (
                <>
                  <PrivateComponent
                    permissions={{
                      roles: ['yc_edit_yc_profile'],
                    }}
                  >
                    <Margin bottom={{ xs: 16 }}>
                      <Button as={Link} to="/yc/profile/edit">
                        {t('page.yc.profile.editProfileButton')}
                      </Button>
                    </Margin>
                  </PrivateComponent>
                  <PrivateComponent
                    permissions={{
                      roles: ['yc_manage_members_yc_user_list'],
                    }}
                  >
                    <Button as={Link} to="/yc/people">
                      {t('page.yc.profile.manageMembers')}
                    </Button>
                  </PrivateComponent>
                </>
              )}
            />
          </Margin>
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                <Cms>
                  <p dangerouslySetInnerHTML={{ __html: newLineToBr(profile.fullDescription) }} />
                </Cms>
              </Margin>

              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.yc.profile.youngCrewMemberships')}
                  </Heading>
                </HeadingWrapper>
                <Cms>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: newLineToBr(profile.youngCrewMembershipsDescription),
                    }}
                  />
                </Cms>
              </Margin>

              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.yc.profile.eventsTitle')}
                  </Heading>
                  <Link to="/events" className="link uppercase">
                    {t('global.seeAll')}
                  </Link>
                </HeadingWrapper>
                <BoxList type="event" items={upcomingEvents} alert={t('global.noResultsEvents')} />
              </Margin>
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              <div className="border-xl">
                <Padding all="20" className="bg-gray-100">
                  <Margin bottom="16">
                    <Heading as="h3" size="h6">
                      {t('page.yc.profile.contactUs')}
                    </Heading>
                  </Margin>
                  {profile.telephone && profile.telephone !== 'null' ? (
                    <Margin bottom="12">
                      <a href={`tel:${profile.telephone}`}>{profile.telephone}</a>
                    </Margin>
                  ) : null}
                  {profile.email && profile.email !== 'null' ? (
                    <Margin bottom="12">
                      <a href={`mailto:${profile.email}`} className="link link--16 word-break">
                        {profile.email}
                      </a>
                    </Margin>
                  ) : null}
                  {profile.website && profile.website !== 'null' ? (
                    <a href={profile.website} className="link link--16 word-break">
                      {profile.website}
                    </a>
                  ) : null}
                  {profile.contactPerson ? (
                    <Margin top="12">
                      <a
                        href={`/user-profile/${profile.contactPerson.uniqueUrl}`}
                        className="link link--16 word-break"
                      >
                        {`${profile.contactPerson.firstName} ${profile.contactPerson.lastName}`}
                      </a>
                    </Margin>
                  ) : null}
                </Padding>
                <Padding all="20">
                  <IconText top icon="marker" text={parseAddress(profile.address)} />
                </Padding>
                {profile.address.lat && profile.address.lang ? (
                  <Map lat={profile.address.lat} lng={profile.address.lang} />
                ) : null}
              </div>

              {usersRole && !!usersRole.length ? (
                <Margin top="32">
                  {usersRole.map((item) => (
                    <Margin top="32">
                      <UserList title={`${item.name}:`} users={item.items} />
                    </Margin>
                  ))}
                </Margin>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

View.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
      lat: PropTypes.number,
      lang: PropTypes.number,
    }),
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    cbTelephone: PropTypes.string,
    cbEmail: PropTypes.string,
    cbWebsite: PropTypes.string,
    ycTelephone: PropTypes.string,
    ycEmail: PropTypes.string,
    ycWebsite: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
    youngCrewMembershipsDescription: PropTypes.string,
    contactPerson: PropTypes.shape({
      uniqueUrl: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  usersRole: globalPropTypes.UserListPropsTypes,
  upcomingEvents: PropTypes.arrayOf(PropTypes.shape({})),
};
View.defaultProps = {
  profile: null,
  usersRole: [],
  upcomingEvents: [],
};

const mapStateToProps = ({ yc }) => ({
  profile: yc.profile.data,
  usersRole: yc.profile.usersRole,
  upcomingEvents: yc.profile.upcomingEvents,
});

export default connect(mapStateToProps, null)(View);
