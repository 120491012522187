import {
  SET_USER,
  SET_PROFILE,
  SET_APP_TYPE,
  SET_NOTIFICATIONS,
  SET_USER_CERTIFICATIONS,
  SET_USER_ADD_LIST_SELECTED_CERTIFICATE,
  SET_USER_ADD_LIST_CERTIFICATES,
  SET_USER_ADD_LIST_FILTRATION,
  SET_CHECKED_TOKEN,
  SET_USER_DASHBOARD,
  SET_USER_PERMISSIONS,
  SET_USER_MEMBER_ASSOCIATION_PEOPLE,
  SET_USER_MEMBER_REG_PARTNER,
  SET_PROFILE_COMPLETED_COURSES,
  SET_USER_COURSES,
  SET_USER_COURSES_PAGES,
  SET_USER_COURSES_PAGE,
  SET_USER_COURSES_PER_PAGE,
  SET_USER_COURSES_BAR,
  SET_USER_BADGES,
  SET_OPEN_PENDING_BADGES,
  SET_OPEN_PENDING_COUPON,
  SET_USER_PENDING_BADGES,
  SET_COURSE_BADGE_LIST,
  SET_USER_PENDING_CERTIFICATIONS,
  SET_AVA_ORGANISATIONS,
  SET_CURRENT_ORGANISATIONS,
} from '../constants';

export const defaultUser = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  dob: null,
  country: null,
  memberAssociation: null,
  memberAssociationPeople: '',
  memberRegPartner: '',
  userProfile: {
    telephone: '',
    gender: null,
    proffesionalTitle: null,
    jobTitle: null,
    company: '',
    tags: [],
    profilePrivacy: null,
    industry: null,
    facebook: '',
    linkedin: '',
    Twitter: '',
    tiktok: '',
    instagram: '',
  },
};

const initialState = {
  appType: 'guest',
  userPermissions: null,
  memberAssociationPeople: '',
  memberRegPartner: '',
  user: defaultUser,
  tokenChecked: false,
  notifications: [],
  certifications: [],
  organisations: [],
  currentOrganisation: null,
  add: {
    data: [],
    selectedCertificates: [],
    filtration: {
      certificateNumber: '',
      associatedEmail: '',
      awardedDate: null,
      validityDate: null,
    },
  },
  dashboard: {},
  profileCompletedCurses: [],
  courses: {
    completed: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    upcoming: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    pending: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    bar: {
      completed: 0,
      upcoming: 0,
      pending: 0,
    },
  },
  badges: {
    course: [],
    certificate: [],
    event: [],
  },
  pendingBadges: {
    openModal: false,
    badges: {
      event: {
        totalItems: 0,
        items: [],
      },
      assessor: {
        totalItems: 0,
        items: [],
      },
      award: {
        totalItems: 0,
        items: [],
      },
      course: {
        totalItems: 0,
        items: [],
      },
      honoraryFellow: {
        totalItems: 0,
        items: [],
      },
    },
    certifications: {
      totalItems: 0,
      items: [],
    },
  },
  pendingCourse: {
    items: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: {
          ...initialState.user,
          ...action.payload,
        },
      };
    case SET_CHECKED_TOKEN:
      return { ...state, tokenChecked: action.payload };
    case SET_PROFILE:
      return { ...state, profile: action.payload };
    case SET_APP_TYPE:
      return { ...state, appType: action.payload };
    case SET_USER_PERMISSIONS:
      return { ...state, userPermissions: action.payload };
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case SET_USER_CERTIFICATIONS:
      return { ...state, certifications: action.payload };
    case SET_USER_ADD_LIST_CERTIFICATES:
      return { ...state, add: { ...state.add, data: action.payload } };
    case SET_USER_ADD_LIST_FILTRATION:
      return { ...state, add: { ...state.add, filtration: action.payload } };
    case SET_USER_ADD_LIST_SELECTED_CERTIFICATE:
      return { ...state, add: { ...state.add, selectedCertificates: action.payload } };
    case SET_USER_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case SET_PROFILE_COMPLETED_COURSES:
      return {
        ...state,
        profileCompletedCurses: action.payload,
      };
    case SET_USER_COURSES:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            items: action.payload,
          },
        },
      };
    case SET_USER_COURSES_PAGES:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            pages: action.payload,
          },
        },
      };
    case SET_USER_COURSES_PAGE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            page: action.payload,
          },
        },
      };
    case SET_USER_COURSES_PER_PAGE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            perPage: action.payload,
          },
        },
      };
    case SET_USER_COURSES_BAR:
      return {
        ...state,
        courses: {
          ...state.courses,
          bar: action.payload,
        },
      };
    case SET_USER_BADGES:
      return {
        ...state,
        badges: action.payload,
      };
    case SET_OPEN_PENDING_BADGES:
      return {
        ...state,
        pendingBadges: {
          ...state.pendingBadges,
          openModal: action.payload,
        },
      };
    case SET_OPEN_PENDING_COUPON:
      return {
        ...state,
        pendingCoupon: {
          ...state.pendingCoupon,
          openModal: action.payload,
        },
      };
    case SET_USER_PENDING_BADGES:
      return {
        ...state,
        pendingBadges: {
          ...state.pendingBadges,
          badges: action.payload,
        },
      };
    case SET_COURSE_BADGE_LIST:
      return {
        ...state,
        pendingCourse: {
          ...state.pendingCourse,
          items: action.payload,
        },
      };
    case SET_USER_MEMBER_ASSOCIATION_PEOPLE:
      return { ...state, memberAssociationPeople: action.payload };
    case SET_USER_MEMBER_REG_PARTNER:
      return { ...state, memberRegPartner: action.payload };
    case SET_USER_PENDING_CERTIFICATIONS:
      return {
        ...state,
        pendingBadges: {
          ...state.pendingBadges,
          certifications: action.payload,
        },
      };
    case SET_AVA_ORGANISATIONS:
      return {
        ...state,
        organisations: action.payload,
      };
    case SET_CURRENT_ORGANISATIONS:
      return {
        ...state,
        currentOrganisation: action.payload,
      };
    default:
      return state;
  }
};
