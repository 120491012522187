import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Thumbnail, Content } from './HeaderBrand.styled';

const HeaderBrand = ({ thumbnail, children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
      <Thumbnail>
        <img src={thumbnail} alt="brand" />
      </Thumbnail>
    </Wrapper>
  );
};

HeaderBrand.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

export default HeaderBrand;
