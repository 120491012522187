import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FiltersControls, Pagination, Spinner, Modal, BoxList, PrivateComponent } from 'components';
import {
  loadMaEventsCreator,
  cancelMaEventCreator,
  setMaEventsPageCreator,
  setMaEventsPerPageCreator,
  duplicateMaEventCreator,
} from 'store/actionsCreators';
import { isLoading, checkPermissions, globalPropTypes } from 'helpers';

import { useTranslation } from 'react-i18next';

const Ongoing = ({
  events,
  page,
  pages,
  perPage,
  loading,
  loadEvents,
  setPage,
  setPerPage,
  cancelEvent,
  duplicateEvent,
  history,
  userPermissions,
}) => {
  const { t } = useTranslation();
  const [cancelConfirmModal, setCancelConfirmModal] = useState(null);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadEvents({ status: 'ongoing' });
  }, [perPage, page]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="event"
            items={events}
            alert={t('global.noResultsFound')}
            customRenderActions={
              checkPermissions(
                {
                  roles: [
                    'ma_my_events_edit_event',
                    'ma_my_events_canceled_event',
                    'ma_my_events_create_event',
                  ],
                },
                userPermissions,
              )
                ? ({ item, ButtonAction }) => (
                    <>
                      <PrivateComponent
                        permissions={{
                          roles: ['ma_my_events_edit_event'],
                        }}
                      >
                        <ButtonAction
                          icon="edit"
                          title={t('global.editButton')}
                          verticalviewbreakpoint="xl"
                          onClick={() => history.push(`/ma/event/${item.id}/edit`)}
                        />
                      </PrivateComponent>
                      <PrivateComponent
                        permissions={{
                          roles: ['ma_my_events_canceled_event'],
                        }}
                      >
                        <ButtonAction
                          icon="lockClose"
                          title={t('global.cancelButton')}
                          verticalviewbreakpoint="xl"
                          onClick={() => setCancelConfirmModal(item.id)}
                        />
                      </PrivateComponent>
                      <PrivateComponent
                        permissions={{
                          roles: ['ma_my_events_create_event'],
                        }}
                      >
                        <ButtonAction
                          icon="duplicate"
                          title={t('global.duplicateButton')}
                          verticalviewbreakpoint="xl"
                          onClick={() => duplicateEvent(item.id)}
                        />
                      </PrivateComponent>
                    </>
                  )
                : null
            }
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
          <Modal
            heading={t('page.ma.eventsOngoing.modalCancelHeading')}
            description={t('page.ma.eventsOngoing.modalCancelDesc')}
            isOpen={!!cancelConfirmModal}
            confirm={() => cancelEvent(cancelConfirmModal) && setCancelConfirmModal(null)}
            cancel={() => setCancelConfirmModal(null)}
          />
        </>
      ) : null}
    </>
  );
};

Ongoing.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadEvents: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  cancelEvent: PropTypes.func.isRequired,
  duplicateEvent: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

const mapStateToProps = ({ ma, loading, user }) => ({
  events: ma.events.list.items,
  page: ma.events.list.page,
  pages: ma.events.list.pages,
  perPage: ma.events.list.perPage,
  loading: isLoading(loading, 'MA'),
  userPermissions: user.userPermissions,
});
const mapDispatchToProps = (dispatch) => ({
  loadEvents: (payload) => dispatch(loadMaEventsCreator(payload)),
  setPage: (payload) => dispatch(setMaEventsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setMaEventsPerPageCreator(payload)),
  cancelEvent: (payload) => dispatch(cancelMaEventCreator(payload)),
  duplicateEvent: (payload) => dispatch(duplicateMaEventCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ongoing);
