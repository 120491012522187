import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text } from './IpmaShop.styled';
import cart from 'assets/icons/cart-icon.png';
import { useTranslation } from 'react-i18next';

const IpmaShop = ({customTitle}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <img src={cart} alt="cart-icon" />
      <Text>
        {customTitle ?? t('navigation.ipmaWorld.shop')}
      </Text>
    </Wrapper>
  );
};

export default IpmaShop;
