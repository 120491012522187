import React from 'react';
import { Container, Ball, WhiteBackground, DimmedContainer, DimmedWrapper } from './Spinner.styled';

function SpinnerDimmed() {
  return (
    <DimmedWrapper>
      <WhiteBackground />
      <DimmedContainer className="text-center">
        <Container>
          <Ball />
          <Ball />
          <Ball />
          <Ball />
          <Ball />
          <Ball />
          <Ball />
          <Ball />
          <Ball />
          <Ball />
          <Ball />
          <Ball />
        </Container>
      </DimmedContainer>
    </DimmedWrapper>
  );
}

export default SpinnerDimmed;
