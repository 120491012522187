import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Button,
  Col,
  Container,
  Header,
  PageContent,
  Alerts,
  Table,
  Spinner,
  ButtonAction,
  Alert,
} from 'components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Margin } from 'styled-components-spacing';
import { ReactSVG } from 'react-svg';
import { canSendReport } from 'api/services/ma';
import { loadMaReportsListCreator } from 'store/actionsCreators';
import { iconList } from 'assets';
import { isLoading, checkPermissions, globalPropTypes } from 'helpers';
import { IconContainer } from './Reports.styled';
import HowToFill from './HowToFill/HowToFill';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.reports',
    url: '',
  },
];

const Reports = ({ reports, loading, userPermissions, loadReports }) => {
  const { t } = useTranslation();
  const [sendDisabled, setSendDisabled] = useState(true);

  useEffect(() => {
    loadReports();
    (async () => {
      try {
        const response = await canSendReport();
        const {
          results: {
            result: { disabled },
          },
        } = await response.json();
        setSendDisabled(disabled);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const parseDateTime = (date) => {
    const dateToParse = new Date(date);
    const month = dateToParse.getUTCMonth() + 1;
    const day = dateToParse.getUTCDate();
    const year = dateToParse.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const parseStatus = (status) => (status ? 'Verified' : 'Waiting for verification');

  const parseInvoice = (invoice) => {
    const icon = invoice ? 'checkThin' : 'close';
    return (
      <IconContainer icon={icon}>
        <ReactSVG src={iconList[icon]} />
      </IconContainer>
    );
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ma.reports.title')} />
      <PageContent>
        <Container>
          {checkPermissions(
            {
              roles: ['ma_send_members_report'],
            },
            userPermissions,
          ) ? (
            <>
              <Alerts type="ma" />
              <Col>
                <Margin bottom={{ xs: 32 }}>
                  <HowToFill />
                  <Table tablebreakpoint="lg">
                    <Table.Head>
                      <Table.Row>
                        <Table.Heading>{t('page.ma.reports.version')}</Table.Heading>
                        <Table.Heading>{t('page.ma.reports.date')}</Table.Heading>
                        <Table.Heading style={{ display: 'flex', justifyContent: 'center' }}>
                          {t('page.ma.reports.invoice')}
                        </Table.Heading>
                        <Table.Heading>{t('page.ma.reports.status')}</Table.Heading>
                        <Table.Heading>{t('page.ma.reports.action')}</Table.Heading>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {loading ? (
                        <Table.Row>
                          <Table.Cell colSpan={7}>
                            <Spinner />
                          </Table.Cell>
                        </Table.Row>
                      ) : null}
                      {!loading && !reports.length ? (
                        <Table.Row>
                          <Table.Cell colSpan={7}>{t('global.noResultsFound')}</Table.Cell>
                        </Table.Row>
                      ) : null}
                      {!loading && reports.length
                        ? reports.map((item) => (
                            <>
                              <Table.Row key={item.id}>
                                <Table.Cell>
                                  <Table.Label>{t('page.ma.reports.version')}:</Table.Label>
                                  {item.version}
                                </Table.Cell>
                                <Table.Cell>
                                  <Table.Label>{t('page.ma.reports.date')}:</Table.Label>
                                  {parseDateTime(item.createdAt)}
                                </Table.Cell>
                                <Table.Cell>
                                  <Table.Label>{t('page.ma.reports.invoice')}:</Table.Label>
                                  {parseInvoice(item.paidInvoices)}
                                </Table.Cell>
                                <Table.Cell>
                                  <Table.Label>{t('page.ma.reports.status')}:</Table.Label>
                                  {parseStatus(item.accepted)}
                                </Table.Cell>
                                <Table.Cell>
                                  <Table.Label>{t('page.ma.reports.action')}:</Table.Label>
                                  <ButtonAction
                                    as={Link}
                                    to={`/ma/read-report/${item.id}`}
                                    title={t('global.readButton')}
                                    style={{ alignItems: 'baseline', marginLeft: '0' }}
                                  />
                                  {item.accepted === false ? (
                                    <ButtonAction
                                      as={Link}
                                      to={`/ma/edit-report/${item.id}`}
                                      title={t('global.editButton')}
                                      style={{ alignItems: 'baseline' }}
                                    />
                                  ) : null}
                                </Table.Cell>
                              </Table.Row>
                            </>
                          ))
                        : null}
                    </Table.Body>
                  </Table>
                </Margin>

                <Row>
                  <Margin as={Col} size={{ xl: 1 / 5 }}>
                    <Button as={Link} to="/ma/add-report" disabled={sendDisabled}>
                      {t('page.ma.reports.addTheReport')}
                    </Button>
                  </Margin>
                </Row>
              </Col>
            </>
          ) : (
            <Alert type="info">{t('page.forbidden.subtitle')}</Alert>
          )}
        </Container>
      </PageContent>
    </>
  );
};

Reports.propTypes = {
  reports: PropTypes.shape([]).isRequired,
  loading: PropTypes.bool.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
  loadReports: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, ma, loading }) => ({
  reports: ma.reports,
  loading: isLoading(loading, 'MA_REPORT'),
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadReports: (payload) => dispatch(loadMaReportsListCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
