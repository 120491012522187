import styled, { css } from 'styled-components';
import { Button } from 'components';

export const SocialButton = styled(Button)`
  ${({ theme }) =>
    theme &&
    css`
      justify-content: start;
      padding: 0 10px;
      border: 0 none;
      border-radius: 3px;
      text-decoration: none;
      transition: all 250ms linear;
      color: #fff;
      height: 50px;
      text-align: left;
      box-shadow: rgb(0 0 0 / 50%) 0px 1px 2px;

      &:hover {
        text-decoration: none;
      }
      > div > div {
        width: 26px;
        height: 26px;
        margin-right: 10px;
        > svg {
          display: flex;
          fill: #fff;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 26px;
          margin-right: 8px;
          text-align: center;
        }
      }
    `}
`;

export const GoogleButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: white;
      color: #595959;

      &:hover {
        background-color: #f7f7f7;
        color: #595959;
      }
    `}
`;

export const MicrosoftButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #329ffd;

      &:hover {
        background-color: #0089ff;
      }
    `}
`;

export const TwitterButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #292929;

      &:hover {
        background-color: #3e3e3e;
      }
    `}
`;

export const FacebookButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #3b5998;

      &:hover {
        background-color: #293e69;
      }
    `}
`;

export const LinkedinButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #1a81b9;

      &:hover {
        background-color: #0770a9;
      }
    `}
`;

export const InstagramButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #C13584;

      &:hover {
        background-color: #E1306C;
      }
    `}
`;

export const TikTokButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #FE2C55;

      &:hover {
        background-color: #25F4EE;
      }
    `}
`;

export const AppleButton = styled(SocialButton)`
  ${({ theme }) =>
    theme &&
    css`
      background-color: #292929;

      &:hover {
        background-color: #3e3e3e;
      }
    `}
`;
