import styled, { css } from 'styled-components';
import { p, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const UploadForm = styled.div`
  text-align: center;
  ${px(16)};
  ${py(24)};

  ${breakpoint('md')`
    ${css`
      ${p(42)};
    `}
  `}
`;

export const PreviewForm = styled.div`
  text-align: start;
  ${px(16)};
  ${py(24)};

  ${breakpoint('md')`
    ${css`
      ${p(32)};
    `}
  `}
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
`;

export const Tab = styled.span`
  flex-basis: 50%;
  padding: 12px;
  border: 1px solid;
  text-transform: uppercase;
  font-weight: 600;
  border-color: ${({ theme }) => theme.colors.primaries.darker};
  color: ${({ theme, selected }) => (selected ? '#fff' : theme.colors.primaries.darker)};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.primaries.darker : '#fff')};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
`;
