import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { globalPropTypes } from 'helpers';

import { Header, Tabs } from 'components';
import { loadMaPeopleBarCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.people',
    url: '/ma/people',
  },
];

const initialTabs = [
  {
    title: 'navigation.tabs.ma.people.memberAssociations',
    path: '/ma/people/users',
    key: 'users',
  },
  {
    title: 'navigation.tabs.ma.people.organisations',
    path: '/ma/people/organisations',
    key: 'organisations',
  },
  {
    title: 'navigation.tabs.ma.people.people',
    path: '/ma/people/list',
    key: 'list',
  },
  {
    title: 'navigation.tabs.ma.people.members',
    path: '/ma/people/members',
    key: 'members',
  },
];

const People = ({ routes, loadBar, bar, userPermissions }) => {
  const { t } = useTranslation();
  useEffect(() => {
    loadBar();
  }, []);

  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect('/ma/people', firstTabPath);

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.ma.people.title')}
        renderActions={({ Button: ButtonRender }) => (
          <ButtonRender secondary as={Link} to="/ma/people/add">
            {t('page.ma.people.addMaUserButton')}
          </ButtonRender>
        )}
      />
      <Tabs items={tabs} />
      {renderExactRedirect()}
      {renderSubRoutes()}
    </>
  );
};

People.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  loadBar: PropTypes.func.isRequired,
  bar: PropTypes.shape({
    users: PropTypes.number,
    organisations: PropTypes.number,
    list: PropTypes.number,
    members: PropTypes.number,
    youngcrew: PropTypes.number,
  }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

People.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ ma, user }) => ({
  bar: ma.people.bar,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadBar: () => dispatch(loadMaPeopleBarCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
