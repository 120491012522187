import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Margin, Padding } from 'styled-components-spacing';
import { useFormFields } from 'helpers';

import {
  loadUserOrganisationPeopleAddCreator,
  loadGlobalJobsTitlesCreator,
  loadGlobalIndustriesCreator,
  addUserOrganisationPeopleAddCreator,
  loadGlobalOrganisationRolesCreator,
} from 'store/actionsCreators';
import {
  Button,
  Col,
  Checkbox,
  Container,
  Field,
  Header,
  PageContent,
  Row,
  Table,
  Spinner,
  Dropdown,
  Heading,
  Select,
} from 'components';
import { useTranslation } from 'react-i18next';

function People({
  people,
  roles,
  loadGlobalOrganisationRoles,
  loadOrganisationPeopleAdd,
  loadGlobalJobsTitles,
  loadGlobalIndustries,
  loading,
  addOrganisationPeopleAdd,
  history,
  jobsTitles,
  industries,
}) {
  const { t } = useTranslation();
  const [selectedPeople, setPeople] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { id } = useParams();

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.organisation.people',
      url: `/organisation/${id}/people`,
    },
    {
      title: 'navigation.breadcrumbs.organisation.addPeople',
      url: `/organisation/${id}/people/add`,
    },
  ];

  const [filterFields, handleFilterFieldChange] = useFormFields({
    firstName: '',
    lastName: '',
    jobsTitle: null,
    industry: null,
  });

  const filters = {
    firstName: {
      type: 'input',
      name: 'first-name',
      id: 'f-name',
      label: t('page.organisation.peopleAdd.firstName'),
      placeholder: t('forms.firstNamePlaceholder'),
    },
    lastName: {
      type: 'input',
      name: 'last-name',
      id: 'f-lastName',
      label: t('page.organisation.peopleAdd.lastName'),
      placeholder: t('forms.lastNamePlaceholder'),
    },
    jobsTitle: {
      type: 'select',
      name: 'job-title',
      id: 'f-jobTitle',
      label: t('page.organisation.peopleAdd.jobTitle'),
      placeholder: t('forms.jobTitlePlaceholder'),
      options: jobsTitles,
    },
    industry: {
      type: 'select',
      name: 'industry',
      id: 'f-industry',
      label: t('page.organisation.peopleAdd.industry'),
      placeholder: t('forms.industryPlaceholder'),
      options: industries,
    },
  };

  useEffect(() => {
    if (!industries.length) {
      loadGlobalIndustries();
    }

    if (!jobsTitles.length) {
      loadGlobalJobsTitles();
    }

    if (!roles.length) {
      loadGlobalOrganisationRoles();
    }
  }, []);

  useEffect(() => {
    setPeople(selectedPeople.filter((personId) => find(people, { id: personId })));
  }, [people]);

  const handleChange = (_id) => (value) => {
    setPeople(
      value ? [...selectedPeople, _id] : selectedPeople.filter((personId) => personId !== _id),
    );
  };

  const submit = () => {
    addOrganisationPeopleAdd(
      {
        users: selectedPeople.map((item) => {
          const role = find(selectedRoles, { userId: item });
          return { userId: item, userRole: role ? role.userRole : null };
        }),
        organizationId: id,
      },
      history,
    );
  };

  const applyFilters = () => {
    const filtersToApply = Object.keys(filterFields)
      .filter((field) => filterFields[field])
      .reduce(
        (p, c) => ({
          ...p,
          [filters[c].name]: filterFields[c].value ? filterFields[c].value : filterFields[c],
        }),
        {},
      );
    if (Object.keys(filtersToApply).length) {
      loadOrganisationPeopleAdd({ organizationId: id, ...filtersToApply });
    }
  };

  useEffect(() => {
    applyFilters();
  }, [filterFields]);

  const handleChangeRole = (_id) => ({ value }) => {
    setSelectedRoles([...selectedRoles, { userId: _id, userRole: value }]);
  };

  const handleInputChange = (e) => {
    handleFilterFieldChange(e);
  };

  const handleSelectChange = (field) => (selected) => {
    handleFilterFieldChange({
      target: {
        id: field,
        value: selected,
      },
    });
  };

  const renderSelect = (field, label, placeholder, options) => {
    return (
      <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }} key={`${id}-${field}`}>
        <Field
          label={label}
          id={`f-${field}`}
          value={filterFields[field]}
          clear={() => handleSelectChange(field)(null)}
        >
          <Select
            id={`f-${field}`}
            name={field}
            placeholder={placeholder}
            value={filterFields[field]}
            onChange={handleSelectChange(field)}
            options={options}
          />
        </Field>
      </Margin>
    );
  };

  const renderInput = (field, label, placeholder) => (
    <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }} key={`${id}-${field}`}>
      <Field label={label}>
        <input
          id={field}
          type="text"
          placeholder={placeholder}
          value={filterFields[field]}
          onChange={handleInputChange}
        />
      </Field>
    </Margin>
  );

  const renderFilters = () => (
    <>
      <Margin as="p" bottom={{ xs: 16 }}>
        <strong>{t('page.organisation.peopleAdd.description')}</strong>
      </Margin>
      <form>
        <Row>
          <Margin left={{ xs: 16 }} right={{ xs: 16 }} style={{ width: '100%' }}>
            <Padding horizontal={{ xs: 16 }} vertical={{ xs: 16 }} className="border-xs">
              <Dropdown
                openbreakpoint="xl"
                title={
                  <Heading as="h3" size="h4">
                    {t('page.organisation.peopleAdd.filterTitle')}
                  </Heading>
                }
              >
                <Row>
                  {Object.keys(filterFields).map((field) => {
                    if (filters[field].type === 'input') {
                      return renderInput(field, filters[field].label, filters[field].placeholder);
                    }
                    return renderSelect(
                      field,
                      filters[field].label,
                      filters[field].placeholder,
                      filters[field].options,
                    );
                  })}
                </Row>
              </Dropdown>
            </Padding>
          </Margin>
        </Row>
      </form>
    </>
  );

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.organisation.peopleAdd.title')} />
      <Container>
        <PageContent>
          {renderFilters()}
          <Margin top={{ xs: 32 }}>
            {loading ? <Spinner /> : null}
            {!loading ? (
              <Table tablebreakpoint="lg">
                <Table.Head>
                  <Table.Row>
                    <Table.Heading />
                    <Table.Heading>{t('people.firstLastName')}</Table.Heading>
                    <Table.Heading>{t('people.role')}</Table.Heading>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {!loading && !people.length ? (
                    <Table.Row>
                      <Table.Cell colSpan={4}>{t('global.noResultsFound')}</Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && people.length
                    ? people.map((user) => (
                        <Table.Row key={`person-add-${user.id}`}>
                          <Table.Cell>
                            <Field>
                              <Checkbox
                                onChange={handleChange(user.id)}
                                selected={selectedPeople.includes(user.id)}
                              />
                            </Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('people.firstLastName')}:</Table.Label>
                            {`${user.firstName} ${user.lastName}`}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('people.role')}:</Table.Label>
                            <Select small onChange={handleChangeRole(user.id)} options={roles} />
                          </Table.Cell>
                        </Table.Row>
                      ))
                    : null}
                </Table.Body>
              </Table>
            ) : null}
          </Margin>
          <Margin top={{ xs: 16 }}>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 5, lg: 1 / 6 }}>
                <Button onClick={submit}>{t('global.addAddButton')}</Button>
              </Col>
            </Row>
          </Margin>
        </PageContent>
      </Container>
    </>
  );
}

People.propTypes = {
  people: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }))
    .isRequired,
  loadGlobalOrganisationRoles: PropTypes.func.isRequired,
  loadOrganisationPeopleAdd: PropTypes.func.isRequired,
  loadGlobalJobsTitles: PropTypes.func.isRequired,
  loadGlobalIndustries: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  addOrganisationPeopleAdd: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  jobsTitles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
};
People.defaultProps = {};

const mapStateToProps = ({ globals, organisation, loading }) => ({
  people: organisation.peopleToAdd,
  roles: globals.organisation.roles,
  loading: !!loading.filter((item) => item.type === 'ORGANISATION_PEOPLE_ADD').length,
  jobsTitles: globals.jobsTitles,
  industries: globals.industries,
});

const mapDispatchToProps = (dispatch) => ({
  loadGlobalOrganisationRoles: () => dispatch(loadGlobalOrganisationRolesCreator()),
  loadOrganisationPeopleAdd: (payload) => dispatch(loadUserOrganisationPeopleAddCreator(payload)),
  loadGlobalJobsTitles: () => dispatch(loadGlobalJobsTitlesCreator()),
  loadGlobalIndustries: () => dispatch(loadGlobalIndustriesCreator()),
  addOrganisationPeopleAdd: (payload, history) =>
    dispatch(addUserOrganisationPeopleAddCreator(payload, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
