import styled from 'styled-components';
import { Col } from 'components';

export const MaxMinContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 4px;
  gap: 12px;
`;

export const MaxMinWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: baseline;
  * {
    color: #424242;
  }
`;

export const MaxMinLabel = styled.span`
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 14px;
`;

export const MaxMinValue = styled.span`
  font-weight: 400;
  font-size: 20px;
`;

export const MaxMinSuffix = styled.span`
  font-weight: 400;
  font-size: 18px;
`;
