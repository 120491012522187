import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH, withAuthorizationHeader } from '..';

export const getCpdList = async () => {
  return ky.get(urljoin(BASE_PATH, 'cpd', 'listByUser'), {
    headers: withAuthorizationHeader(),
  });
};

export const createUserCpdApplication = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'cpd', 'new'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getCpdCategories = async () => {
  return ky.get(urljoin(BASE_PATH, 'cpd', 'category', 'list'), {
    headers: withAuthorizationHeader(),
  });
};

export const getCpdBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'cpd', 'pending', 'menubar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getCpdPendingList = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return ky.get(urljoin(BASE_PATH, 'cpd', 'pending', 'listByCertificationBody', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const acceptCpd = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'cpd', 'pending', 'accept'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
    body: JSON.stringify(params),
  });
};

export const rejectCpd = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'cpd', 'pending', 'reject'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
    body: JSON.stringify(params),
  });
};

export const cpdRoles = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'cpd', 'listCpdRoles'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
    body: JSON.stringify(params),
  });
};

export const sendCpdForVerification = async (id) => {
  return ky.post(urljoin(BASE_PATH, 'cpd', 'requestCpdCheckup', String(id)), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
  });
};

export const listBatchesByMa = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return ky.get(urljoin(BASE_PATH, 'cpd', 'pending', 'listBatchesByMa', `?${query}`), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
  });
};

export const getUserBatchDetails = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'cpd', 'pending', 'getUserBatchDetails', String(id)), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
  });
};

export const saveUserBatchCpds = async (id, items) => {
  return ky.post(urljoin(BASE_PATH, 'cpd', 'pending', 'saveUserBatchCpds', String(id)), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
    body: JSON.stringify(items),
  });
};

export const exportBatchCsv = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'cpd', 'pending', 'exportBatchCsv', String(id)), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
  });
};

export const exportUserCpdCsv = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'cpd', 'exportUserCsv'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
    body: JSON.stringify(params),
  });
};
