import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { ReactComponent as ListSvg } from '../../assets/icons/list.svg';
import customStyles from './Select.styled';

const DropdownIndicator = ({ isMulti, ...props }) => {
  return isMulti ? (
    <components.DropdownIndicator {...props}>
      <ListSvg />
    </components.DropdownIndicator>
  ) : (
    <components.DropdownIndicator {...props} />
  );
};

function Select({ disabled, small, ...res }) {
  return (
    <ReactSelect
      components={{ DropdownIndicator }}
      styles={customStyles}
      small={small}
      isDisabled={disabled}
      {...res}
    />
  );
}

DropdownIndicator.propTypes = {
  props: PropTypes.shape({}),
  isMulti: PropTypes.bool.isRequired,
};

DropdownIndicator.defaultProps = {
  props: {},
};

Select.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  isMulti: PropTypes.bool,
};

Select.defaultProps = {
  disabled: false,
  small: false,
  isMulti: false,
};

export default Select;
