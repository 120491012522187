import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, page }) =>
  theme &&
  page &&
  css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    z-index: 1000;
  `}
`;