import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Tag } from './Tags.styled';

function Tags({ tags }) {
  return (
    <Wrapper>
      {tags.map((tag, index) => (
        <Tag key={`tag-${index}`}>{tag}</Tag>
      ))}
    </Wrapper>
  );
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

Tags.defaultProps = {
  tags: [],
};

export default Tags;
