import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { Wrapper } from './ButtonSearch.styled';

const ButtonSearch = ({ as, icon, children, ...props }) => {
  return (
    <Wrapper as={as} icon={icon} content={children} {...props}>
      <ReactSVG src={iconList[icon] || ''} />
      {children && children}
    </Wrapper>
  );
};

ButtonSearch.propTypes = {
  as: PropTypes.oneOfType([PropTypes.oneOf(['button', 'a']), PropTypes.elementType]),
  icon: PropTypes.string,
  children: PropTypes.node,
  secondary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tertiary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rounded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  outline: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  large: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  color: PropTypes.string,
  bg: PropTypes.string,
};

ButtonSearch.defaultProps = {
  as: 'button',
  icon: '',
  children: null,
  secondary: '',
  tertiary: '',
  rounded: '',
  outline: '',
  large: '',
  left: '',
  color: null,
  bg: null,
};

export default ButtonSearch;
