import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, Center, Text } from './Toggle.styled';

const Toggle = ({ on, onClick, custom }) => (
  <Center onClick={onClick}>
    <ToggleButton on={on} />
    {custom ? (
      <Text>{on ? custom[0] : custom[1]}</Text>
    ) : (
      <Text>{on ? 'enabled' : 'disabled'}</Text>
    )}
  </Center>
);

Toggle.propTypes = {
  on: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  custom: PropTypes.shape([]).isRequired,
};

Toggle.defaultProps = {
  on: false,
};

export default Toggle;
