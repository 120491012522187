import styled, { css } from 'styled-components';
import { py, my } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import bgNews from 'assets/images/see_where_we_are.jpg';

export const Content = styled.div`
  ${my(32)};
  
  ${breakpoint('md')`
    ${css`
      ${my(64)};
    `}
  `}
  
  @media (min-width: 1366px) {
    margin-top: -140px;
    position: relative;
    z-index: 1;
    
    &::before {
      content: "";
      position: absolute;
      top: -59px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 1320px;
      height: 200px;
      background-color: #fff;
      z-index: -1;
      border-radius: 4px 4px 0 0;
    }
  }
`;

export const News = styled.div`
  ${py(42)};

  background-image: url(${bgNews});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  ${breakpoint('xl')`
    ${css`
      ${py(64)};
    `}
  `}
`;
