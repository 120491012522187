export const clearFontSizes = {
  10: 10,
  12: 12,
  13: 13,
  14: 14,
  16: 16,
  17: 17,
  18: 18,
  20: 20,
  24: 24,
  25: 25,
  28: 28,
  30: 30,
  32: 32,
  36: 36,
  42: 42,
};

export const fontSizes = {
  pixel(key) {
    return `${clearFontSizes[key]}px`;
  },
};

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const fontFamilies = {
  primary: 'Libre Franklin',
  navbar: 'Montserrat',
};
