import styled, { css } from 'styled-components';
import { ButtonIcon } from 'components';
import { p } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  ${({ theme }) => theme && css`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${theme.colors.gray['200']};
    border-radius: 2px;
    .title {
      color: white;
    }
    
    ${breakpoint('md')`
      ${css`
        border-radius: 0;
        background-color: transparent;
        align-items: start;
      `}
    `}
  `}
`;

export const Content = styled.p`
  ${({ theme }) => theme && css`
    ${p(16)};

    flex: 1;
    font-size: ${theme.font.size.pixel('16')};
    
    ${breakpoint('md')`
      ${css`
        border-radius: 2px 0 0 2px;
        background-color: ${theme.colors.gray['200']};
      `}
    `}
    
    strong {
      font-weight: ${theme.font.weight.bold};
    }
    
    p {
      &:nth-of-type(1) {
        margin-bottom: 8px;
      }
      ${breakpoint('md')`
        ${css`
          display: inline;
          
          &:nth-of-type(1) {
            margin-bottom: 0;
          }
        `}
      `}
    }
  `}
`;

export const Button = styled(ButtonIcon)`
  ${({ theme }) => theme && css`
    flex-grow: 0;
    @media (max-width: 767px) {
      background-color: transparent;
      border-color: transparent;
      
      svg {
        fill: #4F4E5B;
      }
    }
  `}
`;