import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { forgotPasswordCreator } from 'store/actionsCreators';
import clearErrors from 'helpers/clearErrors';

import { Margin } from 'styled-components-spacing';
import { Button, Col, Field, Heading, Row } from 'components';
import { useTranslation } from 'react-i18next';

function ForgotStep({ forgotPassword }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setEmail(e.target.value);
    setErrors(clearErrors(errors, 'email'));
  };

  const submit = () => {
    forgotPassword({ email }, setErrors);
  };

  return (
    <div>
      <Row halign="justify" valign="center">
        <Col size={{ xs: 1 / 1 }}>
          <Heading as="h2">{t('forgot.forgotPasswordHeading')}?</Heading>
          <Margin as="p" top={{ xs: 24 }} bottom={{ xs: 24 }}>
            {t('forgot.forgotPasswordDesc')}
          </Margin>
        </Col>
      </Row>
      <form>
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
            <Field id="email" icon="mail" error={errors.email}>
              <input
                id="email"
                placeholder={t('forms.emailPlaceholder')}
                value={email}
                onChange={handleChange}
              />
            </Field>
          </Col>
        </Row>
        <Row>
          <Col size={{ xs: 1 / 1, md: 1 / 4 }}>
            <Margin top={{ xs: 24 }}>
              <Button type="button" onClick={submit}>
                {t('forgot.resetPassword')}
              </Button>
            </Margin>
          </Col>
        </Row>
      </form>
    </div>
  );
}

ForgotStep.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (payload, setErrors) => dispatch(forgotPasswordCreator(payload, setErrors)),
});

export default connect(null, mapDispatchToProps)(ForgotStep);
