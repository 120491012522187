import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const StrongWrap = styled.strong`
  overflow-wrap: anywhere;
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SocialButton = styled.a`
  > div > div {
    width: 40px;
    height: 40px;
    background-color: #d3d3d3;
    border-radius: 50%;
    transition: background-color 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      fill: #fff;
      width: 20px;
      height: 20px;
    }
  }
`;

export const FacebookSocial = styled(SocialButton)`
  &:hover > div > div {
    background-color: #3b5998;
  }
`;
export const LinkedinSocial = styled(SocialButton)`
  &:hover > div > div {
    background-color: #1a81b9;
  }
`;
export const TwitterSocial = styled(SocialButton)`
  &:hover > div > div {
    background-color: #33ccff;
  }
`;
export const InstagramSocial = styled(SocialButton)`
  &:hover > div > div {
    background-color: #c13584;
  }
`;
export const YoutubeSocial = styled(SocialButton)`
  &:hover > div > div {
    background-color: #ff0000;
  }
`;

export const ShareButton = styled.button`
  position: fixed;
  bottom: 90px;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondaries.default};
  box-shadow: grey 0px 0px 5px;
  svg {
    display: flex;
    height: 30px;
    width: 30px;
    fill: #fff;
    margin-right: 2px;
  }
  ${breakpoint('md')`
    ${css`
      display: none;
    `}
  `}
`;
