export const clearSpacing = {
  0: 0,
  2: 2,
  4: 4,
  6: 6,
  8: 8,
  12: 12,
  16: 16,
  20: 20,
  22: 22,
  24: 24,
  28: 28,
  32: 32,
  34: 34,
  38: 38,
  42: 42,
  48: 48,
  50: 50,
  54: 54,
  64: 64,
  80: 80,
  100: 100,
  128: 128,
  153: 153,
};

export default {
  ...Object.keys(clearSpacing).reduce(
    (prev, current) => ({ ...prev, [current]: `${current}px` }),
    {},
  ),
};
