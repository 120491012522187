import styled, { css } from 'styled-components';


export const Col = styled.div`
  padding-left: ${({ theme }) => `${theme.grid.gutter / 2}px`};
  padding-right: ${({ theme }) => `${theme.grid.gutter / 2}px`};
  font-size: 16px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;

  ${css`
  @media (max-width: 1600px) {
    font-size: 12px
  }`}

  &:hover {
    .Content {
      visibility: visible;
    }
  }

  svg {
    margin: auto 0 auto 5px;
    display: block;
    width: 10px;
    height: 6px;
    fill: rgb(237 28 36);
    pointer-events: none;
  }
`;

export const Content = styled.div`
  visibility: visible;
  font-size: 14px;
  line-height: 25px;
  border-radius: 0.5rem;
  position: absolute;
  top: 50px;
  width: 250px;
  transform: translate3d(0, 16px, 0);
  transition: transform 0.2s ease, opacity 0.2s ease;
  background-color: white;
  box-shadow: 0 0 24px rgba(79, 78, 92, 0.32);
  z-index: ${({ theme }) => theme.zindex.menu};
`;
