import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Wrapper, Content } from './LayoutImage.styled';

const LayoutImage = ({ children, renderAlerts }) => {
  const renderAlertsContent = () => {
    if (typeof renderAlerts === 'function') {
      return <Margin bottom="32">{renderAlerts()}</Margin>;
    }

    return null;
  };

  return (
    <Wrapper>
      <Container>
        {renderAlertsContent()}
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  );
};

LayoutImage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  renderAlerts: PropTypes.func,
};

LayoutImage.defaultProps = {
  renderAlerts: null,
};

export default LayoutImage;
