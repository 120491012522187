import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Container, PageContent, Alerts, FormCreator } from 'components';
import { useTranslation } from 'react-i18next';
import { Margin } from 'styled-components-spacing';
import { useFormFields, getFileValue, parseDate } from 'helpers';
import { ProjectTypes } from 'dictionaries';
import {
  loadGlobalCountriesCreator,
  loadGlobalLanguagesCreator,
  createIpmaProjectCreator,
} from 'store/actionsCreators';
import { YesNo } from '../../../../dictionaries';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.yc.projects',
    url: '/ipma/projects',
  },
  {
    title: 'Create project',
    url: '',
  },
];

const Create = ({
  countries,
  languages,
  history,
  loadGlobalLanguages,
  loadGlobalCountries,
  createProject,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!countries.length) {
      loadGlobalCountries();
    }
    if (!languages.length) {
      loadGlobalLanguages();
    }
  }, []);

  const [formFields, handleFormFieldChange] = useFormFields({
    name: '',
    imageFile: null,
    dateFrom: null,
    dateTo: null,
    mainLanguage: null,
    secondaryLanguage: null,
    type: null,
    'location.line1': '',
    'location.line2': '',
    'location.postCode': '',
    'location.city': '',
    'location.country': null,
    customUrl: '',
    shortDescription: '',
    description: '',
    isAlwaysOngoing: false,
    errors: {},
  });

  const defaultCountry = countries.find((element) => element.label === 'Online');

  const formDetails = {
    'row-basic-information': {
      basicInfoTitle: {
        type: 'heading',
        text: t('page.yc.projects.basicInformationTitle'),
        textSize: 'h5',
      },
    },
    'row-1': {
      name: {
        type: 'input',
        id: 'f-projectName',
        label: t('page.yc.projects.projectNameLabel'),
        placeholder: t('page.yc.projects.projectNamePlaceholder'),
        required: true,
      },
    },
    'row-isAlwaysOngoing': {
      isAlwaysOngoing: {
        type: 'radio',
        label: t('page.yc.projects.isAlwaysOngoing'),
        description: t('page.yc.projects.isAlwaysOngoingDesc'),
        required: true,
        options: YesNo(),
      },
    },
    'row-2': {
      dateFrom: {
        type: 'datetime',
        id: 'f-dateFrom',
        label: t('page.yc.projects.dateLabel'),
        placeholder: t('page.yc.projects.dateFromPlaceholder'),
        required: !formFields.isAlwaysOngoing,
        disabled: formFields.isAlwaysOngoing,
      },
      dateTo: {
        type: 'datetime',
        id: 'f-dateTo',
        label: t('page.yc.projects.dateToLabel'),
        placeholder: t('page.yc.projects.dateToPlaceholder'),
        required: !formFields.isAlwaysOngoing,
        disabled: formFields.isAlwaysOngoing,
      },
    },
    'row-3': {
      mainLanguage: {
        type: 'select',
        id: 'f-mainLanguage',
        label: t('page.yc.projects.mainLanguageLabel'),
        placeholder: t('page.yc.projects.pickLanguagePlaceholder'),
        required: true,
        options: languages,
      },
      secondaryLanguage: {
        type: 'select',
        id: 'f-secondaryLanguage',
        label: t('page.yc.projects.secondaryLanguageLabel'),
        placeholder: t('page.yc.projects.pickLanguagePlaceholder'),
        options: languages,
      },
    },
    'row-4': {
      type: {
        type: 'radio',
        id: 'f-projectType',
        label: t('page.yc.projects.projectTypeLabel'),
        required: true,
        options: ProjectTypes(),
      },
    },
    'row-5': {
      imageFile: {
        type: 'imageUpload',
        id: 'f-imageFile',
        label: t('page.yc.projects.uploadProjectLogoLabel'),
        placeholder: t('forms.uploadLogoPlaceholder'),
        size: 1 / 2,
      },
    },
    'row-contact-data': {
      contactDataTitle: {
        type: 'heading',
        text: t('page.yc.projects.contactDataTitle'),
        textSize: 'h5',
        marginTop: 24,
      },
    },
    'row-6': {
      'location.line1': {
        type: 'input',
        label: t('page.yc.projects.line1Label'),
        placeholder: t('page.yc.projects.line1Placeholder'),
        required: formFields.type !== 'VIRTUAL',
      },
      'location.line2': {
        type: 'input',
        label: t('page.yc.projects.line2Label'),
        placeholder: t('page.yc.projects.line2Placeholder'),
      },
      'location.city': {
        type: 'input',
        label: t('page.yc.projects.cityLabel'),
        placeholder: t('page.yc.projects.cityPlaceholder'),
        required: formFields.type !== 'VIRTUAL',
      },
      'location.postCode': {
        type: 'input',
        label: t('page.yc.projects.postCodeLabel'),
        placeholder: t('page.yc.projects.postCodePlaceholder'),
        required: formFields.type !== 'VIRTUAL',
      },
    },
    'row-7': {
      'location.country': {
        type: 'select',
        label: t('page.yc.projects.countryLabel'),
        placeholder: t('global.selectPlaceholder'),
        required: formFields.type !== 'VIRTUAL',
        options: countries,
        disabled: formFields.type === 'VIRTUAL',
        value: 'Poland',
      },
      customUrl: {
        type: 'input',
        label: t('page.yc.projects.customUrlLabel'),
        placeholder: t('page.yc.projects.customUrlPlaceholder'),
        required: true,
      },
    },
    'row-description': {
      descriptionTitle: {
        type: 'heading',
        text: t('page.yc.projects.descriptionTitle'),
        textSize: 'h5',
        marginTop: 24,
      },
    },
    'row-8': {
      shortDescription: {
        type: 'textarea',
        label: t('page.yc.projects.shortDescriptionLabel'),
        placeholder: t('page.yc.projects.shortDescriptionPlaceholder'),
        required: true,
      },
      description: {
        type: 'textarea',
        label: t('page.yc.projects.descriptionLabel'),
        placeholder: t('page.yc.projects.descriptionPlaceholder'),
        required: true,
      },
    },
  };

  useEffect(() => {
    if (formFields.isAlwaysOngoing) {
      handleFormFieldChange(
        {
          ...formFields,
          dateFrom: null,
          dateTo: null,
          errors: {
            ...formFields.errors,
            dateFrom: null,
            dateTo: null,
          },
        },
        true,
      );
    }
  }, [formFields.isAlwaysOngoing]);

  const submit = () => {
    const params = new FormData();

    params.append('name', formFields.name || '');
    params.append('level', 'global');
    params.append('imageFile', getFileValue(formFields.imageFile));
    params.append(
      'dateFrom',
      formFields.dateFrom ? parseDate(formFields.dateFrom, 'YYYY-MM-DD HH:mm:ss') : '',
    );
    params.append(
      'dateTo',
      formFields.dateTo ? parseDate(formFields.dateTo, 'YYYY-MM-DD HH:mm:ss') : '',
    );
    params.append('mainLanguage', formFields.mainLanguage ? formFields.mainLanguage.value : '');
    params.append(
      'secondaryLanguage',
      formFields.secondaryLanguage ? formFields.secondaryLanguage.value : '',
    );
    params.append('type', formFields.type ? formFields.type : '');
    params.append('location[line1]', formFields['location.line1'] || '');
    params.append('location[line2]', formFields['location.line2'] || '');
    params.append('location[postCode]', formFields['location.postCode'] || '');
    params.append('location[city]', formFields['location.city'] || '');
    params.append(
      'location[country]',
      formFields['location.country'] ? formFields['location.country'].value : defaultCountry.value,
    );
    params.append('customUrl', formFields.customUrl || '');
    params.append('shortDescription', formFields.shortDescription || '');
    params.append('description', formFields.description || '');
    params.append('isAlwaysOngoing', formFields.isAlwaysOngoing);

    createProject(params, history, (payload) =>
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: payload,
        },
      }),
    );
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.yc.projects.createProjectTitle')} />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="yc" />
            <FormCreator
              formFields={formFields}
              formDetails={formDetails}
              errors={formFields.errors}
              handleFormFieldChange={handleFormFieldChange}
              onSubmit={submit}
            />
          </Margin>
        </Container>
      </PageContent>
    </>
  );
};

Create.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  loadGlobalLanguages: PropTypes.func.isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
};

const mapStateToProps = ({ globals }) => ({
  countries: globals.countries,
  languages: globals.languages,
});
const mapDispatchToProps = (dispatch) => ({
  loadGlobalLanguages: () => dispatch(loadGlobalLanguagesCreator()),
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  createProject: (payload, history, setErrors) =>
    dispatch(createIpmaProjectCreator(payload, history, setErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
