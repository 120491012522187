import styled, { css } from 'styled-components';
import { p, ml, mb } from 'styled-components-spacing';

export default styled.div`
  ${({ theme, type }) =>
    theme &&
    type &&
    css`
      font-size: ${theme.font.size.pixel('16')};
      font-weight: ${theme.font.weight.regular};
      line-height: 24px;

      color: ${theme.colors.alerts[type].text};
      background-color: ${theme.colors.alerts[type].background};

      ${p(24)}
      ${mb(16)}

      ul {
        list-style: disc;
        ${ml(16)}
      }
    `}

    ${({ theme, type, resend}) =>
    theme &&
    type &&
    resend &&
    css`

      display: flex;
      font-size: ${theme.font.size.pixel('16')};
      font-weight: ${theme.font.weight.regular};
      line-height: 24px;
      align-items: center;

      color: ${theme.colors.alerts[type].text};
      background-color: ${theme.colors.alerts[type].background};

      ${p(24)}
      ${mb(16)}

      ul {
        list-style: disc;
        ${ml(16)}
      }
    `}
`;
