import { isEmpty } from 'lodash';

export default (permissions, userPermissions) => {
  if (permissions) {
    if (userPermissions) {
      let appTypeAllow = true;
      let rolesAllow = true;

      if (!isEmpty(permissions.appType)) {
        appTypeAllow = !!userPermissions.appType[permissions.appType];
      }

      if (!isEmpty(permissions.roles)) {
        if (!isEmpty(userPermissions.roles)) {
          rolesAllow = permissions.roles.some((role) => userPermissions.roles.includes(role));
        } else {
          rolesAllow = false;
        }
      }

      return appTypeAllow && rolesAllow;
    }

    return false;
  }

  return true;
};
