import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import customStyles from './SelectCreatable.styled';

function SelectCreatable({ disabled, small, ...res }) {
  return <CreatableSelect styles={customStyles} small={small} isDisabled={disabled} {...res} />;
}

SelectCreatable.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
};

SelectCreatable.defaultProps = {
  disabled: false,
  small: false,
};

export default SelectCreatable;
