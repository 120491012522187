import styled, { css } from 'styled-components';

export const ApplyButton = styled.button`
  ${({ theme, active }) => css`
    display: flex;
    align-items: center;
    gap: 5px;
    color: #000;
    font-weight: 700;
    font-size: 20px;
    padding: 12px 25px 12px 30px;
    border: 2px solid #fff;
    border-radius: 30px;
    background-color: #fff;
    *,
    & {
      transition: all 0.2s;
    }
    svg {
      width: 17px;
      height: 17px;
      display: flex;
    }
    ${!active &&
    css`
      &:hover {
        color: #fff;
        border-color: ${theme.colors.secondaries.default};
        background-color: ${theme.colors.secondaries.default};
        svg {
          fill: #fff;
        }
      }
    `}
  `}
`;