import React from 'react';
import { Link } from 'react-router-dom';
import LayoutImage from 'layouts/LayoutImage/LayoutImage';
import { Row, Col, Heading, Button } from 'components';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';

const ThankYou = () => {
  const { t } = useTranslation();

  return (
    <LayoutImage>
      <Row valign="center">
        <Col size={{ md: 3 / 5 }}>
          <Heading as="h2">{t('page.thankYou.title')}</Heading>
          <Margin top="20">
            <p>{t('page.thankYou.subtitle')}</p>
          </Margin>
          <Margin top="24">
            <Row>
              <Col size={{ md: 2 / 3, lg: 1 / 2 }}>
                <Button as={Link} to="/">
                  {t('page.thankYou.returnButton')}
                </Button>
              </Col>
            </Row>
          </Margin>
        </Col>
      </Row>
    </LayoutImage>
  );
};

export default ThankYou;
