import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './HeadingWrapper.styled';

const HeadingWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

HeadingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeadingWrapper;
