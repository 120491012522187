import urljoin from 'url-join';
import ky from './kyInstance';
import { BASE_PATH, withAuthorizationHeader } from '..';

export const getDashboard = async () => {
  return ky.get(urljoin(BASE_PATH, 'yc', 'dashboard'), {
    headers: withAuthorizationHeader(),
  });
};

export const getProfile = async () => {
  return ky.get(urljoin(BASE_PATH, 'yc', 'profile'), {
    headers: withAuthorizationHeader(),
  });
};

export const updateProfile = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'yc', 'profile', 'edit'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getProfileUpcomingEvents = async () => {
  return ky.get(urljoin(BASE_PATH, 'event', 'yc', 'upcoming'), {
    headers: withAuthorizationHeader(),
  });
};

export const getBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'yc', 'menu-bar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getUsers = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'yc', 'user', 'list', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const changeUserRole = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'yc', 'user', 'change-role'), {
    headers: withAuthorizationHeader({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(params),
  });
};

export const removeUser = async (id) => {
  return ky.delete(urljoin(BASE_PATH, 'yc', 'user', 'remove', id.toString()), {
    headers: withAuthorizationHeader(),
  });
};

export const getAddListUsers = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'yc', 'user', 'find', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const addUsers = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'yc', 'user', 'add'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
    body: JSON.stringify(params),
  });
};

export const getPeopleYcList = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'yc', 'people', 'list', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const acceptListUser = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'yc', 'people', 'accept'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const declineListUser = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'yc', 'people', 'decline'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const removeListUser = async ({ user }) => {
  return ky.delete(urljoin(BASE_PATH, 'yc', 'people', 'remove', String(user)), {
    headers: withAuthorizationHeader(),
  });
};

export const getEventsBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'event', 'yc', 'menu-bar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getEvents = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'event', 'yc', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const getEvent = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'event', 'yc', String(params)), {
    headers: withAuthorizationHeader(),
  });
};

export const createEvent = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'yc'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const editEvent = async (body, id) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'yc', 'edit', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const cancelEvent = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'event', 'yc', String(params), 'cancel'), {
    headers: withAuthorizationHeader(),
  });
};

export const reopenEvent = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'event', 'yc', String(params), 'reopen'), {
    headers: withAuthorizationHeader(),
  });
};

export const duplicateEvent = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'yc', 'duplicate'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const downlodYcPeopleReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'yc', 'people', 'export'), {
    headers: withAuthorizationHeader(),
  });
};

export const downlodYcUsersReport = async () => {
  return ky.get(urljoin(BASE_PATH, 'yc', 'user', 'export'), {
    headers: withAuthorizationHeader(),
  });
};

export const createProject = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'project', 'new', 'yc'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const editProject = async (body, id) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'project', 'edit', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getProject = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'ycproject', 'project', 'show', String(params)), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjectsBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'ycproject', 'menu-bar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjects = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ycproject', 'list', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const createProjectRole = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'new', 'yc'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const editProjectRole = async (body, id) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'edit', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getProjectRolesBar = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'menu-bar', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjectRoles = async (params, id) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'yc', String(id), `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjectRole = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'ycproject', 'projectrole', 'show', String(params)), {
    headers: withAuthorizationHeader(),
  });
};

export const createProjectRoleApplication = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'application', 'new'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getProjectRoleApplications = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ycproject', 'application', 'list', 'yc', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjectRoleApplication = async (params) => {
  return ky.get(
    urljoin(BASE_PATH, 'public', 'ycproject', 'application', 'show', 'yc', String(params)),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const getProjectRoleApplicationBar = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'ycproject', 'application', 'menu-bar', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const acceptProjectRoleApplication = async (id) => {
  return ky.put(urljoin(BASE_PATH, 'ycproject', 'application', 'accept', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const rejectProjectRoleApplication = async (id) => {
  return ky.put(urljoin(BASE_PATH, 'ycproject', 'application', 'reject', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const putOnHoldProjectRoleApplication = async (id) => {
  return ky.put(urljoin(BASE_PATH, 'ycproject', 'application', 'put-on-hold', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const exportApplications = async (params) => {
  const query = Object.keys(params)
    .filter((key) => params[key] !== null)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ycproject', 'application', 'export', 'yc', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const removeProject = async (id) => {
  return ky.delete(urljoin(BASE_PATH, 'ycproject', 'project', 'delete', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const removeProjectRole = async (id) => {
  return ky.delete(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'delete', 'yc', String(id)), {
    headers: withAuthorizationHeader(),
  });
};
