import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { without, find, get } from 'lodash';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Margin } from 'styled-components-spacing';
import { connect } from 'react-redux';

import {
  Alerts,
  Button,
  Checkbox,
  Col,
  Container,
  Datepicker,
  Header,
  Heading,
  Field,
  Select,
  Radio,
  Row,
  FileInput,
  PageContent,
  FieldCounter,
} from 'components';

import {
  editProfileCreator,
  loadGlobalCountriesCreator,
  loadGlobalAvailableMACreator,
  loadGlobalAvailableYCCreator,
  loadGlobalJobsTitlesCreator,
  loadGlobalCourseCloudTagsCreator,
  loadGlobalProfilePrivacyCreator,
  loadGlobalGenderCreator,
  loadGlobalIndustriesCreator,
  loadGlobalProfessionalTitlesCreator,
} from 'store/actionsCreators';
import {
  ProfileEditForm,
  PrivacyDescription,
  PrivacyRadioLabel,
  VerticalRow,
} from 'containers/user/ProfileEdit/ProfileEdit.styled';
import { useTranslation } from 'react-i18next';
import { getFileValue } from 'helpers';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.profile',
    url: '/user/profile',
  },
  {
    title: 'navigation.breadcrumbs.user.editProfile',
    url: '/user/profile/edit',
  },
];

const Spacer = ({ double, children }) => (
  <Margin top={{ xs: 12 * (double ? 2 : 1) }}>{children}</Margin>
);

Spacer.propTypes = {
  double: PropTypes.bool,
  children: PropTypes.elementType.isRequired,
};

Spacer.defaultProps = { double: false };

function ProfileEdit({
  countries,
  ma,
  yc,
  professionalTitles,
  jobsTitles,
  tags,
  profilePrivacy,
  gender,
  industries,
  editProfile,
  loadGlobalAvailableMA,
  loadGlobalAvailableYC,
  loadGlobalCountries,
  loadGlobalProfessionalTitles,
  loadGlobalJobsTitles,
  loadGlobalCourseCloudTags,
  loadGlobalProfilePrivacy,
  loadGlobalGender,
  loadGlobalIndustries,
  user,
  errors,
  history,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!countries.length) {
      loadGlobalCountries();
    }

    if (!industries.length) {
      loadGlobalIndustries();
    }

    if (!ma.length) {
      loadGlobalAvailableMA();
    }

    if (!yc.length) {
      loadGlobalAvailableYC();
    }

    if (!professionalTitles.length) {
      loadGlobalProfessionalTitles();
    }

    if (!jobsTitles.length) {
      loadGlobalJobsTitles();
    }

    if (!tags.length) {
      loadGlobalCourseCloudTags();
    }

    if (!profilePrivacy.length) {
      loadGlobalProfilePrivacy();
    }

    if (!gender.length) {
      loadGlobalGender();
    }
  }, []);

  const [fileName, setFileName] = useState('');
  const [interest, setInterest] = useState([]);
  const [selectedPrivacy, setSelectedPrivacy] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);

  const { setValue, register, handleSubmit, control, reset, getValues, watch } = useForm();
  watch([
    'memberAssociation',
    'dob',
    'country',
    'countryOfOrigin',
    'proffesionalTitle',
    'jobTitle',
    'industry',
    'shortBio',
  ]);

  useEffect(() => {
    setInterest((get(user, 'userProfile.tags') || []).map((el) => el.id));

    if (user.userProfile && user.userProfile.profilePrivacy && profilePrivacy) {
      const temp = find(profilePrivacy, {
        value: user.userProfile.profilePrivacy.id,
      });

      setSelectedPrivacy(temp ? temp.value : null);
    }

    if (user.userProfile && user.userProfile.gender && gender) {
      const temp = find(gender, {
        value: user.userProfile.gender,
      });

      setSelectedGender(temp ? temp.value : null);
    }

    reset({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      dob: user.dob ? new Date(user.dob) : null,
      country: user.country && countries ? find(countries, { value: user.country.id }) : null,
      memberAssociation:
        user.memberAssociation && ma ? find(ma, { value: user.memberAssociation.id }) : null,
      youngCrew: user.youngCrew && yc ? find(yc, { value: user.youngCrew.id }) : null,
      telephone: user.userProfile && user.userProfile.telephone,
      proffesionalTitle:
        user.userProfile && user.userProfile.proffesionalTitle && professionalTitles
          ? find(professionalTitles, { value: user.userProfile.proffesionalTitle.id })
          : null,
      jobTitle:
        user.userProfile && user.userProfile.jobTitle && jobsTitles
          ? find(jobsTitles, { value: user.userProfile.jobTitle.id })
          : null,
      company: user.userProfile && user.userProfile.company,
      countryOfOrigin:
        user.userProfile && user.userProfile.countryOfOrigin && countries
          ? find(countries, { value: user.userProfile.countryOfOrigin.id })
          : null,
      shortBio: user.userProfile && user.userProfile.shortBio,
      facebook: user.userProfile && user.userProfile.facebook,
      twitter: user.userProfile && user.userProfile.twitter,
      instagram: user.userProfile && user.userProfile.instagram,
      tiktok: user.userProfile && user.userProfile.tiktok,
      linkedin: user.userProfile && user.userProfile.linkedin,
      imageFile: user.userProfile && user.userProfile.photo ? user.userProfile.photo : '',
      industry:
        user.userProfile && user.userProfile.industry && industries
          ? find(industries, { value: user.userProfile.industry.id })
          : null,
    });
    setFileName(user.userProfile && user.userProfile.photo ? user.userProfile.photo : null);
  }, [user, profilePrivacy, gender, countries, ma, professionalTitles, jobsTitles, industries, yc]);

  useEffect(() => {
    register({ name: 'imageFile' });
  }, [register]);

  const handleFileChange = (e) => {
    setValue('imageFile', e.target.files && e.target.files.length ? e.target.files[0] : null);
    setFileName(e.target.files && e.target.files[0] && e.target.files[0].name);
  };

  const handleClearFileNative = () => {
    setValue('imageFile', null);
    setFileName(null);
  };

  const handleChangeInterest = (value) => (status) => {
    if (status) {
      setInterest([...interest, value]);
    } else {
      setInterest(without(interest, value));
    }
  };

  const handleChangePrivacy = (value) => (status) => {
    setSelectedPrivacy(status ? value : null);
  };

  const handleChangeGender = (value) => (status) => {
    setSelectedGender(status ? value : null);
  };

  const onSubmit = (data) => {
    const params = new FormData();
    params.append('firstName', data.firstName || '');
    params.append('lastName', data.lastName || '');
    params.append('email', data.email || '');
    params.append('dob', data.dob ? moment(data.dob).format('YYYY-MM-DD HH:mm:ss') : '');
    params.append('country', data.country ? data.country.value : '');
    params.append('memberAssociation', data.memberAssociation ? data.memberAssociation.value : '');
    params.append('youngCrew', data.youngCrew ? data.youngCrew.value : '');
    params.append('userProfile[shortBio]', data.shortBio || '');
    params.append(
      'userProfile[countryOfOrigin]',
      data.countryOfOrigin ? data.countryOfOrigin.value : '',
    );
    params.append('userProfile[telephone]', data.telephone || '');
    params.append('userProfile[gender]', selectedGender || '');
    params.append(
      'userProfile[proffesionalTitle]',
      data.proffesionalTitle ? data.proffesionalTitle.value : '',
    );
    params.append('userProfile[jobTitle]', data.jobTitle ? data.jobTitle.value : '');
    params.append('userProfile[company]', data.company || '');
    params.append('userProfile[industry]', data.industry ? data.industry.value : '');

    params.append('userProfile[facebook]', data.facebook ? data.facebook : '');
    params.append('userProfile[twitter]', data.twitter ? data.twitter : '');
    params.append('userProfile[tiktok]', data.tiktok ? data.tiktok : '');
    params.append('userProfile[instagram]', data.instagram ? data.instagram : '');
    params.append('userProfile[linkedin]', data.linkedin ? data.linkedin : '');

    interest
      .filter(Boolean)
      .forEach((item, index) => params.append(`userProfile[tags][${index}]`, item));

    params.append('userProfile[profilePrivacy]', selectedPrivacy || '');
    params.append('userProfile[imageFile]', getFileValue(data.imageFile));

    editProfile(params);
  };

  const profilePrivacyText = (privacyLabel) => {
    if (privacyLabel === 'private') {
      return (
        <Margin left={{ xs: 8 }}>
          <PrivacyRadioLabel>{t('page.user.editProfile.privateProfile')}</PrivacyRadioLabel>
          <p>{t('page.user.editProfile.privateProfileDesc')}</p>
        </Margin>
      );
    }
    if (privacyLabel === 'general') {
      return (
        <Margin left={{ xs: 8 }} top={{ xs: 8 }}>
          <PrivacyRadioLabel>{t('page.user.editProfile.generalProfile')}</PrivacyRadioLabel>
          <p>{t('page.user.editProfile.generalProfileDesc')}</p>
        </Margin>
      );
    }
    if (privacyLabel === 'public') {
      return (
        <Margin left={{ xs: 8 }} top={{ xs: 8 }}>
          <PrivacyRadioLabel>{t('page.user.editProfile.publicProfile')}</PrivacyRadioLabel>
          <p>{t('page.user.editProfile.publicProfileDesc')}</p>
        </Margin>
      );
    }
    return <></>;
  };

  return (
    <>
      <Header
        title={t('page.user.editProfile.title')}
        breadcrumbs={breadcrumbs}
        renderActions={({ Button: HeaderButton }) => (
          <HeaderButton as={Link} to="/user/profile/password" secondary>
            {t('page.user.editProfile.editPasswordButton')}
          </HeaderButton>
        )}
      />
      <PageContent>
        <Container>
          <Alerts type="editProfile" />
          <ProfileEditForm onSubmit={handleSubmit(onSubmit)}>
            <Heading size="h5">{t('page.user.editProfile.basicInformation')}</Heading>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('global.firstNameLabel')}
                    id="first-name"
                    required
                    error={errors.firstName}
                  >
                    <input
                      id="first-name"
                      name="firstName"
                      placeholder={t('global.firstNamePlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('global.lastNameLabel')}
                    id="last-name"
                    required
                    error={errors.lastName}
                  >
                    <input
                      id="last-name"
                      name="lastName"
                      placeholder={t('global.lastNamePlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 1 / 3 }}>
                <Spacer>
                  <Field label={t('global.emailLabel')} id="email" required error={errors.email}>
                    <input
                      id="email"
                      name="email"
                      placeholder={t('global.emailPlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('global.dobLabel')}
                    id="birthdate"
                    error={errors.dob}
                    clear={() => setValue('dob', null)}
                    value={getValues('dob')}
                  >
                    <Controller
                      id="birthdate"
                      name="dob"
                      as={
                        <Datepicker showYearDropdown scrollableYearDropdown dropdownMode="select" />
                      }
                      control={control}
                      onChange={([value]) => value}
                      defaultValue={new Date()}
                      valueName="selected"
                      placeholderText={t('global.dobPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.countryWhereLiveLabel')}
                    id="country"
                    error={errors.country}
                    clear={() => setValue('country', null)}
                    value={getValues('country')}
                    required
                  >
                    <Controller
                      id="country"
                      name="country"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={countries}
                      placeholder={t('global.selectPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Spacer double>
              <Heading size="h5">{t('page.user.editProfile.additionalInformation')}</Heading>
            </Spacer>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.profilePhotoLabel')}
                    id="profile-photo"
                    error={errors['userProfile.imageFile']}
                    description={t('global.uploadImageInfo')}
                  >
                    <FileInput
                      id="profile-photo"
                      name="imageFile"
                      placeholder={t('global.uploadPlaceholder')}
                      fileName={fileName}
                      clear={handleClearFileNative}
                      onChange={handleFileChange}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.shortBioLabel')}
                    id="short-bio"
                    countChars={getValues('shortBio')}
                    maxChars={2000}
                  >
                    <textarea
                      id="short-bio"
                      name="shortBio"
                      placeholder={t('page.user.editProfile.shortBioPlaceholder')}
                      ref={(element) => register(element, { maxLength: 2000 })}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.countyOfOrigin')}
                    id="nationality"
                    required
                    error={errors['userProfile.countryOfOrigin']}
                    clear={() => setValue('countryOfOrigin', null)}
                    value={getValues('countryOfOrigin')}
                  >
                    <Controller
                      id="nationality"
                      name="countryOfOrigin"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={countries}
                      placeholder={t('global.selectPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('global.phoneLabel')}
                    id="phone-number"
                    error={errors['userProfile.telephone']}
                    required
                  >
                    <input
                      id="phone-number"
                      name="telephone"
                      placeholder={t('global.phonePlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.memberOfMaLabel')}
                    id="member-ma"
                    error={errors.memberAssociation}
                    clear={() => {
                      setValue('memberAssociation', null);
                    }}
                    value={getValues('memberAssociation')}
                  >
                    <Controller
                      id="member-ma"
                      name="memberAssociation"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={ma}
                      placeholder={t('global.selectPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.memberOfYcLabel')}
                    id="member-yc"
                    error={errors.youngCrew}
                    clear={() => {
                      setValue('youngCrew', null);
                    }}
                    value={getValues('youngCrew')}
                  >
                    <Controller
                      id="member-yc"
                      name="youngCrew"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={yc}
                      placeholder={t('global.selectPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 2 / 6 }}>
                <Spacer>
                  <Field
                    id="gender"
                    label={t('page.user.editProfile.genderLabel')}
                    error={errors['userProfile.gender']}
                  >
                    <Row>
                      {gender.map((el) => (
                        <Col key={`gender-${el.value}`} size={{ xs: 1 / 3 }}>
                          <Radio
                            onClick={handleChangeGender(el.value)}
                            selected={selectedGender === el.value}
                          >
                            {el.label}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Spacer double>
              <Heading size="h5">{t('page.user.editProfile.professionDetails')}</Heading>
            </Spacer>
            <Row>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.professionalTitleLabel')}
                    id="professional-titles"
                    error={errors['userProfile.proffesionalTitle']}
                    clear={() => setValue('proffesionalTitle', null)}
                    value={getValues('proffesionalTitle')}
                  >
                    <Controller
                      id="professional-title"
                      name="proffesionalTitle"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={professionalTitles}
                      placeholder={t('global.selectPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.jobTitleLabel')}
                    id="job-title"
                    required
                    error={errors['userProfile.jobTitle']}
                    clear={() => setValue('jobTitle', null)}
                    value={getValues('jobTitle')}
                  >
                    <Controller
                      id="job-title"
                      name="jobTitle"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={jobsTitles}
                      placeholder={t('global.selectPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.companyLabel')}
                    id="company"
                    error={errors['userProfile.company']}
                    required
                  >
                    <input
                      id="company"
                      name="company"
                      placeholder={t('page.user.editProfile.companyPlaceholder')}
                      ref={register}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('page.user.editProfile.industryLabel')}
                    id="industry"
                    error={errors['userProfile.industry']}
                    clear={() => setValue('industry', null)}
                    value={getValues('industry')}
                    required
                  >
                    <Controller
                      id="industry"
                      name="industry"
                      as={<Select />}
                      control={control}
                      onChange={([value]) => value}
                      options={industries}
                      placeholder={t('global.selectPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Spacer double>
              <Heading size="h5">{t('page.user.editProfile.tags')}</Heading>
            </Spacer>
            <Spacer>
              <Field
                id="interests"
                label={t('page.user.editProfile.tagsLabel')}
                error={errors['userProfile.tags']}
              >
                <Row>
                  {tags.map((el) => (
                    <Col key={`interest-${el.value}`} size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}>
                      <Margin bottom={{ xs: 12 }}>
                        <Checkbox
                          control={control}
                          onChange={handleChangeInterest(el.value)}
                          selected={interest.includes(el.value)}
                        >
                          {el.label}
                        </Checkbox>
                      </Margin>
                    </Col>
                  ))}
                </Row>
              </Field>
            </Spacer>
            <Spacer>
              <Heading size="h5">{t('page.user.editProfile.privacyHeading')}</Heading>
            </Spacer>
            <Row>
              <Col size={{ md: 3 / 5 }}>
                <Spacer>
                  <PrivacyDescription>
                    {t('page.user.editProfile.privacyDescription')}
                  </PrivacyDescription>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ md: 2 / 4 }}>
                <Spacer>
                  <Field
                    id="profile-view"
                    label={t('page.user.editProfile.profileViewLabel')}
                    required
                    error={errors['userProfile.profilePrivacy']}
                  >
                    <VerticalRow wide>
                      {profilePrivacy.map((privacy) =>
                        privacy.label.toLowerCase() !== 'private' ? (
                          <Radio
                            onClick={handleChangePrivacy(privacy.value)}
                            selected={selectedPrivacy === privacy.value}
                          >
                            {profilePrivacyText(privacy.label.toLowerCase())}
                          </Radio>
                        ) : null,
                      )}
                    </VerticalRow>
                  </Field>
                </Spacer>
              </Col>
            </Row>
            {selectedPrivacy === 1 ? (
              <Spacer double>
                <Spacer bottom="24">
                  <Heading size="h5">{t('page.user.editProfile.socialMediaLinks')}</Heading>
                </Spacer>
                <Row>
                  <Col ifsize={{ md: 1 / 3 }} size={{ lg: 1 / 2 }}>
                    <Spacer>
                      <Field label={t('global.linkedinLabel')} id="linkedin" icon="linkedin">
                        <input
                          id="linkedin"
                          name="linkedin"
                          placeholder={t('global.socialMediaPlaceholder')}
                          ref={register}
                        />
                      </Field>
                    </Spacer>
                  </Col>
                  <Col ifsize={{ md: 1 / 3 }} size={{ lg: 1 / 2 }}>
                    <Spacer>
                      <Field label={t('global.twitterLabel')} id="twitter" icon="twitter">
                        <input
                          id="twitter"
                          name="twitter"
                          placeholder={t('global.socialMediaPlaceholder')}
                          ref={register}
                        />
                      </Field>
                    </Spacer>
                  </Col>
                  <Col ifsize={{ md: 1 / 3 }} size={{ lg: 1 / 2 }}>
                    <Spacer>
                      <Field label={t('global.facebookLabel')} id="facebook" icon="facebook">
                        <input
                          id="facebook"
                          name="facebook"
                          placeholder={t('global.socialMediaPlaceholder')}
                          ref={register}
                        />
                      </Field>
                    </Spacer>
                  </Col>
                  <Col ifsize={{ md: 1 / 3 }} size={{ lg: 1 / 2 }}>
                    <Spacer>
                      <Field label={t('global.tiktokLabel')} id="tiktok" icon="tiktok">
                        <input
                          id="tiktok"
                          name="tiktok"
                          placeholder={t('global.socialMediaPlaceholder')}
                          ref={register}
                        />
                      </Field>
                    </Spacer>
                  </Col>
                  <Col ifsize={{ md: 1 / 3 }} size={{ lg: 1 / 2 }}>
                    <Spacer>
                      <Field label={t('global.instagramLabel')} id="instagram" icon="instagram">
                        <input
                          id="instagram"
                          name="instagram"
                          placeholder={t('global.socialMediaPlaceholder')}
                          ref={register}
                        />
                      </Field>
                    </Spacer>
                  </Col>
                </Row>
              </Spacer>
            ) : null}
            <Row>
              <Col size={{ md: 1 / 5, xl: 1 / 6 }}>
                <Spacer double>
                  <Button type="submit">{t('global.saveButton')}</Button>
                </Spacer>
              </Col>
            </Row>
            <Spacer>
              <Button
                auto="xs"
                type="button"
                text
                primaryDark
                onClick={() => history.push('/user/profile/delete')}
              >
                {t('page.user.editProfile.deleteAccountButton')}
              </Button>
            </Spacer>
          </ProfileEditForm>
        </Container>
      </PageContent>
    </>
  );
}

ProfileEdit.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  ma: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  yc: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  professionalTitles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  jobsTitles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  profilePrivacy: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  gender: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  editProfile: PropTypes.func.isRequired,
  loadGlobalAvailableMA: PropTypes.func.isRequired,
  loadGlobalAvailableYC: PropTypes.func.isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,
  loadGlobalProfessionalTitles: PropTypes.func.isRequired,
  loadGlobalJobsTitles: PropTypes.func.isRequired,
  loadGlobalCourseCloudTags: PropTypes.func.isRequired,
  loadGlobalProfilePrivacy: PropTypes.func.isRequired,
  loadGlobalGender: PropTypes.func.isRequired,
  loadGlobalIndustries: PropTypes.func.isRequired,

  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    dob: PropTypes.string,
    memberAssociation: PropTypes.any,
    youngCrew: PropTypes.any,
    country: PropTypes.shape({ id: PropTypes.number.isRequired }),
    userProfile: PropTypes.shape({
      company: PropTypes.string,
      countryOfOrigin: PropTypes.shape({ id: PropTypes.number.isRequired }),
      telephone: PropTypes.string,
      proffesionalTitle: PropTypes.shape({ id: PropTypes.number.isRequired }),
      jobTitle: PropTypes.shape({ id: PropTypes.number.isRequired }),
      interest: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired })),
      profilePrivacy: PropTypes.shape({ id: PropTypes.number.isRequired }),
      gender: PropTypes.shape({ id: PropTypes.number.isRequired }),
      industry: PropTypes.shape({ id: PropTypes.number.isRequired }),
      shortBio: PropTypes.string,
      photo: PropTypes.string,
      linkedin: PropTypes.string,
      facebook: PropTypes.string,
      tiktok: PropTypes.string,
      twitter: PropTypes.string,
      instagram: PropTypes.string,
    }),
  }).isRequired,

  errors: PropTypes.shape({
    firstName: PropTypes.any,
    lastName: PropTypes.any,
    email: PropTypes.any,
    dob: PropTypes.any,
    memberAssociation: PropTypes.any,
    youngCrew: PropTypes.any,
    country: PropTypes.any,
    youngCrewMember: PropTypes.any,
    'userProfile.company': PropTypes.any,
    'userProfile.countryOfOrigin': PropTypes.any,
    'userProfile.telephone': PropTypes.any,
    'userProfile.proffesionalTitle': PropTypes.any,
    'userProfile.jobTitle': PropTypes.any,
    'userProfile.interest': PropTypes.any,
    'userProfile.profilePrivacy': PropTypes.any,
    'userProfile.gender': PropTypes.any,
    'userProfile.industry': PropTypes.any,
    'userProfile.imageFile': PropTypes.any,
    'userProfile.tags': PropTypes.any,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ProfileEdit.defaultProps = {
  errors: {},
};

const mapStateToProps = ({ globals, user, editProfile }) => ({
  countries: globals.countries,
  ma: globals.availableMA,
  yc: globals.availableYC,
  professionalTitles: globals.professionalTitles,
  jobsTitles: globals.jobsTitles,
  tags: globals.course.cloudTags,
  profilePrivacy: globals.profilePrivacy,
  gender: globals.gender,
  industries: globals.industries,
  user: user.user,
  errors: editProfile.errors,
});

const mapDispatchToProps = (dispatch) => ({
  editProfile: (payload) => dispatch(editProfileCreator(payload)),
  loadGlobalAvailableMA: () => dispatch(loadGlobalAvailableMACreator({ showAll: true })),
  loadGlobalAvailableYC: () => dispatch(loadGlobalAvailableYCCreator()),
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  loadGlobalProfessionalTitles: () => dispatch(loadGlobalProfessionalTitlesCreator()),
  loadGlobalJobsTitles: () => dispatch(loadGlobalJobsTitlesCreator()),
  loadGlobalCourseCloudTags: () => dispatch(loadGlobalCourseCloudTagsCreator()),
  loadGlobalProfilePrivacy: () => dispatch(loadGlobalProfilePrivacyCreator()),
  loadGlobalGender: () => dispatch(loadGlobalGenderCreator()),
  loadGlobalIndustries: () => dispatch(loadGlobalIndustriesCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
