import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Pagination,
  FiltersControls,
  BoxList,
  Spinner,
  Alerts,
  Modal,
  PrivateComponent,
} from 'components';
import {
  changeMaCoursesAssessorCreator,
  loadMaCoursesCreator,
  setMaCoursesPageCreator,
  setMaCoursesPerPageCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { checkPermissions, globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Margin } from 'styled-components-spacing';
import { getFilteredAssessors, getSelectedAssessorByIndex } from 'containers/ma/Courses/helpers';

const Unassigned = ({
  loading,
  loadList,
  setPage,
  setPerPage,
  list,
  assessors,
  changeAssessor,
  userPermissions,
}) => {
  const { t } = useTranslation();
  const [assessorModal, setAssessorModal] = useState(null);

  const { items, page, pages, perPage } = list;

  useEffect(() => {
    setPage(1);
    loadList();
  }, [perPage]);

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <Alerts type="ma" />
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <BoxList
              type="course"
              items={items}
              alert={t('global.noResultsCourses')}
              renderActionsFull
              customRenderActions={
                checkPermissions(
                  {
                    roles: ['ma_courses_assign_assessor'],
                  },
                  userPermissions,
                )
                  ? ({ item, Select, ActionContentItemFull }) => (
                      <>
                        <PrivateComponent
                          permissions={{
                            roles: ['ma_courses_assign_assessor'],
                          }}
                        >
                          <ActionContentItemFull>
                            <Margin bottom="12">
                              <Select
                                options={getFilteredAssessors(assessors, item.assessors)}
                                small
                                placeholder={t('page.ma.coursesUnassigned.assessorPlaceholder1')}
                                value={getSelectedAssessorByIndex(item.assessors, 0)}
                                onChange={({ value }) =>
                                  setAssessorModal({
                                    courseId: item.id,
                                    userId: value,
                                    prevUserId: getSelectedAssessorByIndex(item.assessors, 0, true),
                                  })
                                }
                              />
                            </Margin>
                            <Margin bottom="12">
                              <Select
                                options={getFilteredAssessors(assessors, item.assessors)}
                                small
                                placeholder={t('page.ma.coursesUnassigned.assessorPlaceholder2')}
                                value={getSelectedAssessorByIndex(item.assessors, 1)}
                                onChange={({ value }) =>
                                  setAssessorModal({
                                    courseId: item.id,
                                    userId: value,
                                    prevUserId: getSelectedAssessorByIndex(item.assessors, 1, true),
                                  })
                                }
                              />
                            </Margin>
                          </ActionContentItemFull>
                        </PrivateComponent>
                      </>
                    )
                  : null
              }
            />
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList();
              }}
            />
          </>
        ) : null}
      </Container>
      <Modal
        heading={t('page.ma.coursesUnassigned.modalChangeHeading')}
        isOpen={assessorModal}
        confirm={() => {
          changeAssessor(assessorModal);
          setAssessorModal(null);
        }}
        cancel={() => setAssessorModal(null)}
      />
    </PageContent>
  );
};

Unassigned.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  list: globalPropTypes.ListPropsTypes.isRequired,
  assessors: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes).isRequired,
  changeAssessor: PropTypes.func.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

Unassigned.defaultProps = {
  loading: false,
};

const mapStateToProps = ({
  loading,
  ma: {
    courses: { unassigned: list },
    assessors,
  },
  user,
}) => ({
  loading: isLoading(loading, 'LOAD_MA_COURSES_UNASSIGNED'),
  list,
  assessors,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: () => dispatch(loadMaCoursesCreator('unassigned')),
  setPage: (payload) => dispatch(setMaCoursesPageCreator('unassigned', payload)),
  setPerPage: (payload) => dispatch(setMaCoursesPerPageCreator('unassigned', payload)),
  changeAssessor: (payload) => dispatch(changeMaCoursesAssessorCreator('unassigned', payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unassigned);
