import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import {
  loadMaCpdBatchesListCreator,
  saveUserBatchCpdsCreator,
  exportBatchCsvCreator,
} from 'store/actionsCreators';
import { isLoading, useFormFields } from 'helpers';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Header,
  PageContent,
  Container,
  Alerts,
  Spinner,
  Table,
  Alert,
  ButtonAction,
  Modal,
  Row,
  Heading,
  Col,
  Field,
  Button,
  TagButton,
  Dropdown,
} from 'components';
import {
  StyledRow,
  NameCell,
  ModalContentCustom,
  SubmitButton,
  ContentWrapper,
  StyledContentRow,
  StyledContentRowTextArea,
  ActionButtonsFlex,
  GroupByButtons,
} from '../CpdRequests.styled';

const ReviewCpdBatch = ({ cpds, loading, loadCpds, requestInfo, saveBatch, exportBatchCsv }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [formFields, handleFormFieldChange] = useFormFields({});
  const [groupedBy, setGroupedBy] = useState('default');

  useEffect(() => {
    loadCpds(id);
  }, [loadCpds]);

  useEffect(() => {
    handleFormFieldChange(
      cpds.reduce((acc, current) => ({ ...acc, [current.cpd.id]: current.cpd.manualPoints }), {}),
      true,
    );
  }, [cpds]);

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.ma.cpdRequests',
      url: '/ma/cpd-requests',
    },
  ];

  const handleInputChange = (field) => (e) => {
    handleFormFieldChange(
      {
        ...formFields,
        [field]: e.target.value,
      },
      true,
    );
  };

  const closeFn = () => setOpenModal(false);

  const renderContent = () => (
    <ModalContentCustom>
      <Heading as="h4" center>
        {modalItem.cpd.name}
      </Heading>
      {loading ? (
        <Spinner />
      ) : (
        <Margin top="32">
          <ContentWrapper>
            <StyledContentRow>
              <Col size={{ lg: 1 / 2 }}>
                <Heading as="h2" size="h6" noLine>
                  Category name
                </Heading>
                <div>{modalItem.cpd.category.label}</div>
              </Col>
              <Col size={{ lg: 1 / 2 }}>
                <Heading as="h2" size="h6" noLine>
                  Category type
                </Heading>
                {modalItem.cpd.category.isFormal ? 'Formal' : 'Non-formal'}
              </Col>
            </StyledContentRow>
            <StyledContentRow>
              <Col size={{ lg: 1 / 2 }}>
                <Heading as="h2" size="h6" noLine>
                  Points
                </Heading>
                {modalItem.cpd.manualPoints}
              </Col>
              <Col size={{ lg: 1 / 2 }}>
                <Heading as="h2" size="h6" noLine>
                  Date of attendance
                </Heading>
                {moment(modalItem.cpd.dateOfAttendance).format('DD/MM/YYYY')}
              </Col>
            </StyledContentRow>
            <StyledContentRow>
              <Col size={{ lg: 1 / 2 }}>
                <Heading as="h2" size="h6" noLine>
                  Agenda
                </Heading>
                <ButtonAction
                  title="Open"
                  as="a"
                  target="_blank"
                  href={modalItem.cpd.linkToAgenda}
                />
              </Col>
              <Col size={{ lg: 1 / 2 }}>
                <Heading as="h2" size="h6" noLine>
                  Proof
                </Heading>
                <ButtonAction title="Open" as="a" target="_blank" href={modalItem.cpd.diploma} />
              </Col>
            </StyledContentRow>
            <StyledContentRowTextArea>
              <Col>
                <Heading as="h2" size="h6" noLine>
                  Additional information / explanation
                </Heading>
                {modalItem.cpd.description}
              </Col>
            </StyledContentRowTextArea>
            <Margin top="24" as={Row} halign="center">
              <Col size={1 / 2}>
                <SubmitButton onClick={closeFn}>Close</SubmitButton>
              </Col>
            </Margin>
          </ContentWrapper>
        </Margin>
      )}
    </ModalContentCustom>
  );

  const submit = () => {
    saveBatch({ history, id, items: formFields });
  };

  const exportSave = () => {
    const username =
      requestInfo && requestInfo.user
        ? `${requestInfo.user.firstName} ${requestInfo.user.lastName}`
        : 'Request';
    const date =
      requestInfo && requestInfo.requestDate
        ? moment(requestInfo.requestDate).format('DD/MM/YYYY')
        : null;
    const filenameToExport = `${username}_cpd_hours_${date}.csv`.replace(' ', '_');
    exportBatchCsv({ id, filename: filenameToExport });
  };

  const handleChangeGroupMode = (mode) => {
    setGroupedBy(mode);
  };

  const renderTable = (items) => (
    <>
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <Table tablebreakpoint="lg">
            <Table.Head>
              <Table.Row>
                <Table.Heading>Name</Table.Heading>
                <Table.Heading>Category</Table.Heading>
                <Table.Heading>Date of attendance</Table.Heading>
                <Table.Heading>Points</Table.Heading>
                <Table.Heading />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell colSpan={4}>
                    <Spinner />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && !items.length ? (
                <Table.Row>
                  <Table.Cell colSpan={4}>
                    <Alert type="info">{t('global.noResultsFound')}</Alert>
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && items.length
                ? items.map((item, index) => (
                    <>
                      <StyledRow key={item.id} isOdd={(index + 1) % 2 !== 0}>
                        <NameCell>
                          <Table.Label>Name:</Table.Label>
                          {item.cpd.name}
                        </NameCell>
                        <Table.Cell>
                          <Table.Label>Category:</Table.Label>
                          {item.cpd.category.label}
                        </Table.Cell>
                        <Table.Cell>
                          <Table.Label>Date of attendance:</Table.Label>
                          {moment(item.cpd.dateOfAttendance).format('DD/MM/YYYY')}
                        </Table.Cell>
                        <Table.Cell>
                          <Table.Label>Points:</Table.Label>
                          <Field id={`f-${item.cpd.id}`}>
                            <input
                              id={item.cpd.id}
                              name={item.cpd.id}
                              type="number"
                              value={formFields[item.cpd.id]}
                              onChange={handleInputChange(item.cpd.id)}
                            />
                          </Field>
                        </Table.Cell>
                        <Table.Cell>
                          <ButtonAction
                            title="Details"
                            onClick={() => {
                              setModalItem(item);
                              setOpenModal(true);
                            }}
                          />
                        </Table.Cell>
                      </StyledRow>
                    </>
                  ))
                : null}
            </Table.Body>
          </Table>
        </>
      ) : null}
    </>
  );

  const getYearsList = (items) => {
    const itemsYearsList = items.reduce((years, item) => {
      const date = new Date(item.cpd.dateOfAttendance);
      years.push(date.getFullYear());
      return years;
    }, []);
    return [...new Set(itemsYearsList)];
  };

  const renderGrouped = (items) => {
    const years = getYearsList(items);
    if (!isEmpty(years)) {
      return years.map((year) => {
        const list = items.filter((item) => {
          const date = new Date(item.cpd.dateOfAttendance);
          if (date.getFullYear() === year) {
            return true;
          }
          return false;
        });
        return (
          <>
            {!isEmpty(list) && (
              <Margin bottom={{ xs: 24 }}>
                <Dropdown
                  openOnLoad
                  title={
                    <Heading as="h3" size="h4">
                      {year}
                    </Heading>
                  }
                >
                  {renderTable(list)}
                </Dropdown>
              </Margin>
            )}
          </>
        );
      });
    }
    return (
      <Margin bottom={{ xs: 24 }}>
        <Alert type="info">{t('global.noResultsFound')}</Alert>
      </Margin>
    );
  };

  const renderCpds = () => {
    const deepClone = cloneDeep(cpds);
    switch (groupedBy) {
      case 'default':
        return renderTable(deepClone);
      case 'year':
        return renderGrouped(deepClone);
      default:
        return renderTable(deepClone);
    }
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={
          requestInfo && requestInfo.user
            ? `${requestInfo.user.firstName} ${requestInfo.user.lastName}`
            : 'Request'
        }
        description={
          requestInfo && requestInfo.requestDate
            ? moment(requestInfo.requestDate).format('DD/MM/YYYY')
            : null
        }
        renderActions={() => (
          <>
            {cpds && cpds.length ? (
              <ActionButtonsFlex>
                <Button onClick={() => exportSave()}>
                  <p>DOWNLOAD CSV</p>
                </Button>
                <Button onClick={() => submit()}>
                  <p>SAVE</p>
                </Button>
              </ActionButtonsFlex>
            ) : null}
          </>
        )}
      />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="cpd" />
          </Margin>
          <Col>
            <Row>
              <Margin bottom="24">
                <GroupByButtons>
                  <TagButton
                    active={groupedBy === 'default'}
                    onClick={() => handleChangeGroupMode('default')}
                  >
                    Default
                  </TagButton>
                  <TagButton
                    active={groupedBy === 'year'}
                    onClick={() => handleChangeGroupMode('year')}
                  >
                    Grouped by year
                  </TagButton>
                </GroupByButtons>
              </Margin>
            </Row>
          </Col>
          {renderCpds()}
        </Container>
      </PageContent>
      <Modal
        isOpen={openModal}
        customContent={renderContent}
        onRequestClose={closeFn}
        autoWidth="650px"
        customWidth="100%"
      />
    </>
  );
};

ReviewCpdBatch.propTypes = {
  cpds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  requestInfo: PropTypes.shape({
    requestDate: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  loadCpds: PropTypes.func.isRequired,
  saveBatch: PropTypes.func.isRequired,
  exportBatchCsv: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cpd, loading }) => ({
  cpds: cpd.certificationBodyBatch.batchCpds,
  requestInfo: cpd.certificationBodyBatch.batchCpdsInfo,
  loading: isLoading(loading, 'MA'),
});
const mapDispatchToProps = (dispatch) => ({
  loadCpds: (payload) => dispatch(loadMaCpdBatchesListCreator(payload)),
  saveBatch: (payload) => dispatch(saveUserBatchCpdsCreator(payload)),
  exportBatchCsv: (payload) => dispatch(exportBatchCsvCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCpdBatch);
