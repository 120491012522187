import Auth from 'containers/auth/Auth';
import Login from 'containers/auth/Login/Login';
import Register from 'containers/auth/Register/Register';
import ResetPassword from 'containers/auth/ResetPassword/ResetPassword';
import Logout from 'containers/auth/Logout/Logout';
import SocialLogin from 'containers/auth/SocialLogin/SocialLogin';
import IpmaShopVerify from 'containers/auth/IpmaShopVerify/IpmaShopVerify';

const AUTH = 'authBase';
const LOGIN = 'authLogin';
const REGISTER = 'authRegister';
const RESET_PASSWORD = 'authPassword';
const LOGOUT = 'authLogout';
const SOCIALLOGIN = 'authSocialLogin';
const IPMA_SHOP_VERIFY = 'authIpmaShopVerify';

export default [
  {
    key: AUTH,
    path: '/auth',
    component: Auth,
    exact: false,
    withAuth: false,
    routes: [
      {
        key: LOGIN,
        path: '/auth/login',
        component: Login,
        exact: false,
        withAuth: false,
      },
      {
        key: REGISTER,
        path: '/auth/register',
        component: Register,
        exact: false,
        withAuth: false,
      },
      {
        key: RESET_PASSWORD,
        component: ResetPassword,
        path: '/auth/password',
        exact: false,
        withAuth: false,
      },
      {
        key: LOGOUT,
        component: Logout,
        path: '/auth/logout',
        exact: false,
        withAuth: false,
      },
      {
        key: SOCIALLOGIN,
        component: SocialLogin,
        path: '/auth/provider/social-app',
        exact: false,
        withAuth: false,
      },
      {
        key: IPMA_SHOP_VERIFY,
        component: IpmaShopVerify,
        path: '/auth/ipma-shop-verify',
        exact: false,
        withAuth: false,
      },
    ],
  },
];
