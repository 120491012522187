import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { Wrapper } from './IconText.styled';

const IconText = ({ icon, text, inline, top, big }) => {
  return (
    <Wrapper inline={inline} top={top} big={big}>
      <ReactSVG src={iconList[icon]} />
      {text}
    </Wrapper>
  );
};

IconText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  top: PropTypes.bool,
  big: PropTypes.bool,
};

IconText.defaultProps = {
  inline: false,
  top: false,
  big: false,
};

export default IconText;
