import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';
import theme from '.';

export default createGlobalStyle`
  ${reset}
  body {
    font-family: ${theme.font.family.primary};
    font-size: ${theme.font.size.pixel('16')};
    font-weight: ${theme.font.weight.regular};
    color: ${theme.colors.gray[500]};
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body, html {
    height: 100%;
  }
  
  button {
    font-family: inherit;
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    vertical-align: top;
  
    :hover {
      cursor: pointer;
    }
  }
  
  ul,
  ol {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  input,
  textarea {
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: none;
  }
  
  label {
    margin-bottom: 0;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
  
  a,
  button,
  span,
  select {
    :focus {
      outline: none;
    }
    
    .accessibility &:focus {
      outline: 1px dashed #000;
      outline-offset: 1px;
    }
  }
  
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  .w-full {
    width: 100%;
  }
  
  .w-auto {
    width: auto;
  }
  
  .h-full {
    height: 100%;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
  
  .text-center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  strong {
    font-weight: ${theme.font.weight.bold};
  }
  
  .font-weight-medium {
    font-weight: ${theme.font.weight.medium};
  }
  
  .font-weight-bold {
    font-weight: ${theme.font.weight.bold};
  }
  
  .text-12 {
    font-size: ${theme.font.size.pixel('12')};
  }  
  
  .text-14 {
    font-size: ${theme.font.size.pixel('14')};
  }  
  
  .text-18 {
    font-size: ${theme.font.size.pixel('18')};
  }  
  
  .text-tertiary {
    color: ${theme.colors.tertiary};
  }  
  
  .text-gray-200 {
    color: ${theme.colors.gray['200']};
  }
  
  .word-break {
    word-break: break-word;
  }
  
  .link {
    display: inline-block;
    color: ${theme.colors.primaries.darker} !important;
    font-size: ${theme.font.size.pixel('14')};
    font-weight: ${theme.font.weight.bold};
    letter-spacing: 1.4px;
    
    &:hover {
      color: ${theme.colors.primary};
    }
    
    &--16 {
      font-size: ${theme.font.size.pixel('16')};
    }
    
    &--disabled {
      color: ${theme.colors.gray['400']};
      pointer-events: none;
    }
  }
  
  .unauthorized {
    > * {
      pointer-events: none !important;

      &:focus {
        outline: none !important;
      }
    }
  }
  
  .bg-gray-100 {
    background-color: ${theme.colors.gray['100']};
  }
  
  ${() =>
    Object.keys(theme.breakpoints).map((key) => {
      return css`
        @media (min-width: ${theme.breakpoints[key]}px) {
          .border-${key} {
            border: 1px solid ${theme.colors.gray['200']};
          }
        }
      `;
    })}

  .rdw-editor-wrapper {
    width: 100%;
    background-color: #f8f8f8;
  }
  .rdw-editor-toolbar {
    background-color: #4f4e5c;
    padding: 6px 5px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .rdw-option-wrapper {
    background: none;
    border: none;
    &:hover {
      box-shadow: none;
    }
  }
  .rdw-fontsize-wrapper {
    margin: 0 24px;
  }
  .rdw-text-align-wrapper,
  .rdw-inline-wrapper {
    margin: 0;
  }
  .rdw-fontsize-dropdown {
    min-width: 54px;
  }
  .rdw-editor-main {
    min-height: 124px;
    padding: 0 16px;
  }

  .react-datepicker-popper {
    z-index: 3;
  }
  
  ${() =>
    Object.keys(theme.breakpoints).map((key) => {
      return css`
        @media (min-width: ${theme.breakpoints[key]}px) {
          .d-none-${key} {
            display: none;
          }
        }
      `;
    })}
  
  ${() =>
    Object.keys(theme.breakpoints).map((key) => {
      return css`
        @media (min-width: ${theme.breakpoints[key]}px) {
          .d-block-${key} {
            display: block;
          }
        }
      `;
    })}
    
  ${() =>
    Object.keys(theme.breakpoints).map((key) => {
      return css`
        @media (min-width: ${theme.breakpoints[key]}px) {
          .text-right-${key} {
            text-align: right;
          }
        }
      `;
    })}
  
  .content-blur #root {
    filter: blur(4px);
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
