import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/images/logo.png';
import { Wrapper } from './Brand.styled';

const Brand = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <img src={props.logo ?? logo} alt="Ipma logo" />
    </Wrapper>
  );
};

Brand.propTypes = {
  as: PropTypes.oneOfType([PropTypes.oneOf(['button', 'a']), PropTypes.elementType]),
};

Brand.defaultProps = {
  as: 'button',
};

export default Brand;
