import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch, useParams } from 'react-router-dom';
import { loadUserOrganisationCreator, setCurrentOrganisationsCreator } from 'store/actionsCreators';
import { globalPropTypes } from 'helpers';

const Organisation = ({ routes, loadUserOrganisation, setCurrentOrganisations }) => {
  const { id } = useParams();

  useEffect(() => {
    loadUserOrganisation(id);
    setCurrentOrganisations(id);
  }, [id]);

  return (
    <div>
      <Switch>
        {routes.map(({ key, path, exact, component: Component, ...res }) => {
          return (
            <Route
              key={key}
              path={path}
              exact={exact}
              render={(props) => <Component {...{ ...props, ...res }} />}
            />
          );
        })}
      </Switch>
    </div>
  );
};

Organisation.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  loadUserOrganisation: PropTypes.func.isRequired,
  setCurrentOrganisations: PropTypes.func.isRequired,
};

Organisation.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  currentOrganisation: user.currentOrganisation,
});

const mapDispatchToProps = (dispatch) => ({
  loadUserOrganisation: (payload) => dispatch(loadUserOrganisationCreator(payload)),
  setCurrentOrganisations: (payload) => dispatch(setCurrentOrganisationsCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organisation);
