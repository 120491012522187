import styled, { css } from 'styled-components';
import { py, px, mr, mb } from 'styled-components-spacing';

export const Wrapper = styled.div`
  margin-bottom: -8px;
`;

export const Tag = styled.div`
  ${({ theme }) => theme && css`
    display: inline-block;
    position: relative;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => theme && css`
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;
    
    .accessibility &:focus {
      ~ ${Label} {
        outline: 1px dashed #000;
        outline-offset: 1px;
      }
    }
    
    &:checked {
      ~ ${Label} {
        border-color: ${theme.colors.gray[300]};
        background-color: ${theme.colors.gray[200]};

        ${Icon} {
          display: inline-block;
        }
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => theme && css`
    ${py(8)};
    ${px(16)};
    ${mr(12)};
    ${mb(8)};

    display: inline-block;
    font-size: ${theme.font.size.pixel(14)};
    background-color: ${theme.colors.gray[100]};
    color: ${theme.colors.tertiary};
    border: 1px solid transparent;
    border-radius: 2px;
    vertical-align: middle;
    
    &:hover {
      cursor: pointer;
    }
  `}
`;

export const Icon = styled.div`
  ${mr(8)};

  display: none;

  ${({ theme }) => theme && css`
    > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    svg {
      width: 12px;
      height: 12px;
      fill: ${theme.colors.primary};
      pointer-events: none;
    }
  `}
`;
