import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StyledButton from './Button.styled';

function Button({ as, bordered, secondary, children, disabled, ...res }) {
  return (
    <StyledButton as={as} {...res} bordered={bordered} secondary={secondary} disabled={disabled}>
      {children}
    </StyledButton>
  );
}

Button.propTypes = {
  as: PropTypes.oneOf(['button', 'a', Link]),
  bordered: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  secondary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Button.defaultProps = {
  as: 'button',
  bordered: '',
  secondary: '',
  children: null,
  disabled: false,
};

export default Button;
