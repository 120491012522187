import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { globalPropTypes, checkPermissions } from 'helpers';

import { Header, Tabs, Alert, Container, PageContent } from 'components';
import { loadIpmaPeopleBarCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.people',
    url: '/ipma/people',
  },
];

const initialTabs = [
  {
    title: 'navigation.tabs.ipma.people.myIpma',
    path: '/ipma/people/users',
    key: 'users',
  },
];

const People = ({ routes, loadBar, bar, userPermissions }) => {
  const { t } = useTranslation();
  useEffect(() => {
    loadBar();
  }, []);

  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect('/ipma/people', firstTabPath);

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.ipma.people.title')}
        renderActions={({ Button: ButtonRender }) =>
          checkPermissions(
            {
              roles: ['ipma_manage_ipma_user_list'],
            },
            userPermissions,
          ) ? (
            <ButtonRender secondary as={Link} to="/ipma/people/add">
              {t('page.ipma.people.addIpmaUserButton')}
            </ButtonRender>
          ) : null
        }
      />
      <Tabs items={tabs} />
      {checkPermissions(
        {
          roles: ['ipma_manage_ipma_user_list'],
        },
        userPermissions,
      ) ? (
        <>
          {renderExactRedirect()}
          {renderSubRoutes()}
        </>
      ) : (
        <Container>
          <PageContent>
            <Alert type="info">{t('page.forbidden.subtitle')}</Alert>
          </PageContent>
        </Container>
      )}
    </>
  );
};

People.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  loadBar: PropTypes.func.isRequired,
  bar: PropTypes.shape({
    users: PropTypes.number,
    organisations: PropTypes.number,
    list: PropTypes.number,
  }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

People.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ ipma, user }) => ({
  bar: ipma.people.bar,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadBar: () => dispatch(loadIpmaPeopleBarCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
