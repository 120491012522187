import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Pagination,
  FiltersControls,
  BoxList,
  Spinner,
  Alerts,
} from 'components';
import {
  loadOrganisationCoursesCreator,
  setOrganisationCoursesPageCreator,
  setOrganisationCoursesPerPageCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { RegNumber } from './CourseTemplate.styled';

const CourseTemplates = ({ loading, loadList, setPage, setPerPage, list }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { items, page, pages, perPage } = list;

  useEffect(() => {
    setPage(1);
    loadList(id);
  }, [perPage]);

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <Alerts type="organisation" />
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <BoxList
              type="courseNoDate"
              items={items}
              alert={t('global.noResultsCourses')}
              renderActionsFull
              renderActionsLeft
              customRenderActions={({ item, ButtonAction }) => (
                <>
                  <ButtonAction
                    icon="duplicate"
                    title={t('global.publishDuplicate')}
                    as={Link}
                    to={`/organisation/${id}/courses/publish/${item.id}`}
                  />
                  {item.possibleSubcourse ? (
                    <ButtonAction
                      icon="addCicrle"
                      title={t('courses.createSubcourse')}
                      as={Link}
                      to={`/organisation/${id}/courses/create-subcourse/${item.id}`}
                    />
                  ) : null}
                  <ButtonAction
                    icon="download"
                    title={t('global.downloadRegLogo')}
                    as="a"
                    target="_blank"
                    rel="nofollow noopener"
                    href={item.regLogoZipFile}
                    disabled={!item.regLogoZipFile}
                  />
                  <ButtonAction
                    icon="link"
                    title={t('global.openLink')}
                    as="a"
                    target="_blank"
                    rel="nofollow noopener"
                    href={item.googleFileUrl}
                    disabled={!item.googleFileUrl}
                  />
                  <RegNumber>
                    <div>REG Number</div>
                    {item.regNumber ? item.regNumber : 0}
                  </RegNumber>
                </>
              )}
            />
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList(id);
              }}
            />
          </>
        ) : null}
      </Container>
    </PageContent>
  );
};

CourseTemplates.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  list: globalPropTypes.ListPropsTypes.isRequired,
};

CourseTemplates.defaultProps = {
  loading: false,
};

const mapStateToProps = ({
  loading,
  organisation: {
    courses: { courseTemplates: list },
  },
}) => ({
  loading: isLoading(loading, 'LOAD_ORGANISATION_COURSES_COURSE_TEMPLATES'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: (organization) =>
    dispatch(loadOrganisationCoursesCreator('courseTemplates', organization)),
  setPage: (payload) => dispatch(setOrganisationCoursesPageCreator('courseTemplates', payload)),
  setPerPage: (payload) =>
    dispatch(setOrganisationCoursesPerPageCreator('courseTemplates', payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseTemplates);
