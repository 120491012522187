import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Margin } from 'styled-components-spacing';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { isLogin } from 'api';
import { Button, Checkbox, Col, Field, Heading, Row, Password } from 'components';
import {
  GoogleButton,
  MicrosoftButton,
  FacebookButton,
  LinkedinButton,
} from 'containers/auth/Login/Login.styled';

import { loginUserCreator, setNewAlertCreator, loginSocialCreator } from 'store/actionsCreators';

function IpmaShopVerify({ loginUser, loginSocial, setNewAlert, location, history }) {
  const { t } = useTranslation();
  const showActivationAlert = () => {
    setNewAlert({
      type: 'auth',
      status: 'success',
      text: 'Account has been verified',
    });
  };

  useEffect(() => {
    if (isLogin()) {
      history.replace('/ipma-shop');
    }
    window.localStorage.setItem('ipmaShopVerify', true);
    const queryParams = new URLSearchParams(location.search);
    const hasConfirmedParam = queryParams.has('confirmed');
    const isConfirmedParamTrue = hasConfirmedParam && queryParams.get('confirmed') === 'true';

    if (isConfirmedParamTrue) showActivationAlert();
  }, []);

  const [form, setForm] = useState({
    username: '',
    password: '',
    remember: false,
  });

  const handleChange = (name) => (e) => {
    e.preventDefault();
    setForm({ ...form, [name]: e.target.value });
  };

  const handleChangeCustom = (name) => (value) => {
    setForm({ ...form, [name]: value });
  };

  const submit = (e) => {
    e.preventDefault();
    loginUser({ username: form.username, password: form.password }, history, true);
  };

  return (
    <div>
      <Row halign="justify" valign="center">
        <Col size={{ xs: 3 / 4 }}>
          <Heading as="h1" size="h2">
            {t('auth.logInIpmaShop')}
          </Heading>
          <Margin as="p" top={{ xs: 20 }}>
            {t('auth.dontHaveAccount')}{' '}
            <Link className="link" to="/auth/register">
              {t('auth.toRegister')}
            </Link>
          </Margin>
        </Col>
      </Row>
      <Row>
        <Col size={{ lg: 1 / 2 }}>
          <form onSubmit={submit}>
            <Margin top={{ xs: 28 }} bottom={{ xs: 22 }}>
              <Margin bottom={{ xs: 8 }}>
                <Field icon="mail">
                  <input
                    onChange={handleChange('username')}
                    type="text"
                    value={form.username}
                    placeholder={t('forms.emailPlaceholder')}
                  />
                </Field>
              </Margin>
              <Margin bottom={{ xs: 16 }}>
                <Field icon="lock">
                  <Password
                    onChange={handleChange('password')}
                    value={form.password}
                    placeholder={t('forms.passwordPlaceholder')}
                  />
                </Field>
              </Margin>
              <Margin bottom={{ xs: 16 }}>
                <Row>
                  <Col size={{ md: 1 / 3 }}>
                    <Checkbox selected={form.remember} onChange={handleChangeCustom('remember')}>
                      {t('forms.remember')}
                    </Checkbox>
                  </Col>
                </Row>
              </Margin>
              <Row>
                <Col size={{ md: 1 / 3 }}>
                  <Button type="submit">{t('auth.logIn')}</Button>
                </Col>
              </Row>
            </Margin>
          </form>
        </Col>
        <Col>
          <Margin as="p" bottom={{ xs: 12 }}>
            {t('auth.orSignIn')}
          </Margin>
          <Row>
            <Col size={{ lg: 1 / 6 }}>
              <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                <GoogleButton onClick={() => loginSocial('google')}>
                  <ReactSVG src={iconList.googleColor} />
                  {t('auth.sGoogle')}
                </GoogleButton>
              </Margin>
            </Col>

            <Col size={{ lg: 1 / 6 }}>
              <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                <MicrosoftButton onClick={() => loginSocial('microsoft')}>
                  <ReactSVG src={iconList.windows} />
                  {t('auth.sMicrosoft')}
                </MicrosoftButton>
              </Margin>
            </Col>

            <Col size={{ lg: 1 / 6 }}>
              <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                <FacebookButton onClick={() => loginSocial('facebook')}>
                  <ReactSVG src={iconList.facebookSquare} />
                  {t('auth.sFacebook')}
                </FacebookButton>
              </Margin>
            </Col>

            <Col size={{ lg: 1 / 6 }}>
              <Margin bottom={{ xs: 8 }} top={{ xs: 8 }}>
                <LinkedinButton onClick={() => loginSocial('linkedin')}>
                  <ReactSVG src={iconList.linkedin} />
                  {t('auth.sLinkedin')}
                </LinkedinButton>
              </Margin>
            </Col>
          </Row>
          <Link to="/auth/password" className="link uppercase">
            {t('auth.forgotPassword')}?
          </Link>
        </Col>
      </Row>
    </div>
  );
}

IpmaShopVerify.propTypes = {
  loginUser: PropTypes.func.isRequired,
  setNewAlert: PropTypes.func.isRequired,
  loginSocial: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: (payload, history, ipmaShopVerify) =>
    dispatch(loginUserCreator(payload, history, ipmaShopVerify)),
  loginSocial: (payload) => dispatch(loginSocialCreator(payload)),
  setNewAlert: (payload) => dispatch(setNewAlertCreator(payload)),
});

export default connect(null, mapDispatchToProps)(IpmaShopVerify);
