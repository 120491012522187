import { SET_PAGE_HOME, SET_PAGE_USER_PROFILE, SET_PAGE_USER_PROFILE_BADGES } from '../constants';

const initialState = {
  home: {},
  userProfile: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_HOME:
      return {
        ...state,
        home: action.payload,
      };
    case SET_PAGE_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case SET_PAGE_USER_PROFILE_BADGES:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          badges: action.payload,
        },
      };
    default:
      return state;
  }
};
