import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormCreator from 'components/FormCreator/FormCreator';
import { useFormFields, getFileValue } from 'helpers';
import moment from 'moment';
import { useForm } from 'react-hook-form';

import {
  registerUserCreator,
  loadGlobalCountriesCreator,
  loadGlobalAvailableMACreator,
  loadGlobalAvailableYCCreator,
  loadGlobalJobsTitlesCreator,
  loadGlobalCourseCloudTagsCreator,
  loadGlobalGenderCreator,
  loadGlobalProfessionalTitlesCreator,
  loadGlobalProfilePrivacyCreator,
  loadGlobalIndustriesCreator,
  getRegistrationUserDataCreator,
} from 'store/actionsCreators';

import { Col, Heading, Row, Stepper, Container, PageContent } from 'components';

import { StepperWrapper, RegisterButton } from 'containers/auth/Register/Register.styled';

import { useTranslation } from 'react-i18next';
import { Margin } from 'styled-components-spacing/dist/cjs/Margin';

function ExtendedRegistrationStep({
  registerUser,
  history,
  countries,
  ma,
  yc,
  professionalTitles,
  jobsTitles,
  profilePrivacy,
  userRegistrationData,
  tags,
  gender,
  industries,
  loadGlobalAvailableMA,
  loadGlobalAvailableYC,
  loadGlobalCountries,
  loadGlobalProfessionalTitles,
  loadGlobalJobsTitles,
  loadGlobalCourseCloudTags,
  loadGlobalGender,
  loadGlobalIndustries,
  loadGlobalProfilePrivacy,
  loadUserRegistrationData,
}) {
  const { watch } = useForm();
  watch([
    'memberAssociation',
    'dob',
    'country',
    'countryOfOrigin',
    'proffesionalTitle',
    'jobTitle',
    'industry',
  ]);

  const firstStepRegistration = ['email', 'firstname', 'lastname', 'country', 'password', 'ma'];

  const { t } = useTranslation();
  useEffect(() => {
    if (!countries.length) {
      loadGlobalCountries();
    }

    if (!industries.length) {
      loadGlobalIndustries();
    }

    if (!ma.length) {
      loadGlobalAvailableMA();
    }

    if (!yc.length) {
      loadGlobalAvailableYC();
    }

    if (!professionalTitles.length) {
      loadGlobalProfessionalTitles();
    }

    if (!jobsTitles.length) {
      loadGlobalJobsTitles();
    }

    if (!tags.length) {
      loadGlobalCourseCloudTags();
    }

    if (!profilePrivacy.length) {
      loadGlobalProfilePrivacy();
    }

    if (!userRegistrationData.length) {
      loadUserRegistrationData();
    }

    if (!gender.length) {
      loadGlobalGender();
    }
  }, []);

  const [formFields, handleFormFieldChange] = useFormFields({
    errors: {},
    terms: userRegistrationData.terms,
    ma: userRegistrationData.ma,
    yc: userRegistrationData.yc,
    gender: '',
    memberAssociation: '',
    youngCrew: '',
    telephone: '',
    firstName: userRegistrationData.firstName,
    lastName: userRegistrationData.lastName,
    email: userRegistrationData.email,
    dob: userRegistrationData.dob,
    country: userRegistrationData.country,
    profilePhoto: {},
    'userProfile.imageFile': null,
    shortBio: '',
    proffesionalTitle: '',
    jobTitle: null,
    industry: null,
    company: '',
    interests: [],
    profilePrivacy: 3,
    password: userRegistrationData.password,
    repassword: userRegistrationData.repassword,
    countryOfOrigin: null,
  });

  const formDetails = {
    'row-profilePhoto': {
      'userProfile.imageFile': {
        type: 'fileupload',
        id: 'image-file',
        label: t('page.user.editProfile.profilePhotoLabel'),
        placeholder: t('global.uploadPlaceholder'),
        description: t('global.uploadImageInfo'),
        required: false,
        size: 0.5,
      },
    },
    'row-shortBio': {
      shortBio: {
        type: 'textarea',
        id: 'short-bio',
        label: t('page.user.editProfile.shortBioLabel'),
        placeholder: t('page.user.editProfile.shortBioPlaceholder'),
        required: false,
        size: 0.5,
      },
    },
    'row-countryOfOrigin&PhoneNumber': {
      countryOfOrigin: {
        type: 'select',
        id: 'countryOfOrigin',
        label: t('page.user.editProfile.countyOfOrigin'),
        placeholder: t('global.selectPlaceholder'),
        options: countries,
        required: true,
      },
      telephone: {
        type: 'input',
        icon: null,
        id: 'telephone',
        label: t('global.phoneLabel'),
        placeholder: t('global.phonePlaceholder'),
        required: true,
      },
    },
    'row-ma&yc': {
      memberAssociation: {
        type: 'select',
        id: 'member-ma',
        label: t('page.user.editProfile.memberOfMaLabel'),
        placeholder: t('global.selectPlaceholder'),
        options: ma,
        required: false,
      },
      youngCrew: {
        type: 'select',
        id: 'member-yc',
        label: t('page.user.editProfile.memberOfYcLabel'),
        placeholder: t('global.selectPlaceholder'),
        options: yc,
        required: false,
      },
    },
    'row-gender': {
      gender: {
        type: 'radio',
        id: 'gender',
        label: t('page.user.editProfile.genderLabel'),
        placeholder: t('global.selectPlaceholder'),
        options: gender,
        required: false,
      },
    },
    'row-professionDetails': {
      basicInfoTitle: {
        type: 'heading',
        text: t('page.user.editProfile.professionDetails'),
        textSize: 'h5',
      },
    },
    'row-professionalTitle&jobTitle': {
      proffesionalTitle: {
        type: 'select',
        id: 'proffesional-Title',
        label: t('page.user.editProfile.professionalTitleLabel'),
        placeholder: t('global.selectPlaceholder'),
        options: professionalTitles,
        required: false,
      },
      jobTitle: {
        type: 'select',
        id: 'job-Title',
        label: t('page.user.editProfile.jobTitleLabel'),
        placeholder: t('global.selectPlaceholder'),
        options: jobsTitles,
        required: true,
      },
    },
    'row-company&Industry': {
      company: {
        type: 'input',
        icon: null,
        id: 'company',
        label: t('page.user.editProfile.companyLabel'),
        placeholder: t('page.user.editProfile.companyPlaceholder'),
        required: true,
      },
      industry: {
        type: 'select',
        id: 'industry',
        label: t('page.user.editProfile.industryLabel'),
        placeholder: t('global.selectPlaceholder'),
        options: industries,
        required: true,
      },
    },
    'row-profileTags': {
      basicInfoTitle: {
        type: 'heading',
        text: t('page.user.editProfile.tags'),
        textSize: 'h5',
      },
    },
    'row-interests': {
      interests: {
        type: 'checkbox',
        id: 'interests',
        label: t('page.user.editProfile.tagsLabel'),
        options: tags,
        required: false,
      },
    },
    'row-privacyHeading': {
      basicInfoTitle: {
        type: 'heading',
        text: t('page.user.editProfile.privacyHeading'),
        textSize: 'h5',
      },
    },
    'row-PrivacyDescription': {
      PrivacyDescription: {
        type: 'heading',
        text: t('page.user.editProfile.privacyDescription'),
        textSize: 'h5',
      },
    },
    'row-profilePrivacy': {
      profilePrivacy: {
        type: 'privacyRadio',
        id: 'profile-view',
        label: t('page.user.editProfile.profileViewLabel'),
        options: [
          {
            heading: t('page.user.editProfile.publicProfile'),
            label: t('page.user.editProfile.publicProfileDesc'),
            value: 1,
          },
          {
            heading: t('page.user.editProfile.generalProfile'),
            label: t('page.user.editProfile.generalProfileDesc'),
            value: 2,
          },
        ],
        required: true,
      },
    },
  };

  const submitWithProfileData = (e) => {
    e.preventDefault();
    const params = new FormData();
    params.append('password[password]', formFields.password);
    params.append('password[repassword]', formFields.repassword);
    params.append('country', formFields.country ? formFields.country.value : null);
    params.append(
      'dob',
      formFields.dob ? moment(formFields.dob).format('YYYY-MM-DD HH:mm:ss') : '',
    );
    params.append('email', formFields.email || '');
    params.append('firstName', formFields.firstName || '');
    params.append('lastName', formFields.lastName || '');
    params.append('yc', userRegistrationData.youngCrewMember || '');
    params.append('ma', userRegistrationData.ma || '');
    params.append('youngCrew', formFields.youngCrew ? formFields.youngCrew.value : '');
    params.append(
      'memberAssociation',
      formFields.memberAssociation ? formFields.memberAssociation.value : '',
    );
    params.append('terms', formFields.terms || '');
    params.append('userProfile[shortBio]', formFields.shortBio || '');
    params.append(
      'userProfile[countryOfOrigin]',
      formFields.countryOfOrigin ? formFields.countryOfOrigin.value : '',
    );
    params.append(
      'userProfile[proffesionalTitle]',
      formFields.proffesionalTitle ? formFields.proffesionalTitle.value : '',
    );
    params.append('userProfile[jobTitle]', formFields.jobTitle ? formFields.jobTitle.value : '');
    params.append(
      'userProfile[profilePrivacy]',
      formFields.profilePrivacy ? formFields.profilePrivacy : '',
    );
    params.append('userProfile[industry]', formFields.industry ? formFields.industry.value : '');
    params.append('userProfile[gender]', formFields.gender ? formFields.gender : '');
    formFields.interests
      .filter(Boolean)
      .forEach((item, index) => params.append(`userProfile[tags][${index}]`, item));

    params.append('userProfile[telephone]', formFields.telephone || '');
    params.append('userProfile[company]', formFields.company || '');
    params.append(
      'userProfile[imageFile]',
      getFileValue(formFields['userProfile.imageFile']) || '',
    );

    registerUser(history, params, (payload) => {
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: payload,
        },
      });

      Object.keys(payload).forEach((error) => {
        if (firstStepRegistration.includes(error)) {
          history.replace('/auth/register');
        }
      });
    });
  };

  return (
    <>
      <Container>
        <PageContent>
          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Row halign="justify" valign="center">
              <Col size={{ xs: 3 / 4 }}>
                <Heading as="h1" size="h2">
                  {t('page.auth.register.additionalInformationHeading')}
                </Heading>
              </Col>
              <Col size={{ xs: 3 / 4 }}>
                <Margin top="16" as="h1" size="h5">
                  {t('page.auth.register.additionalInformationText')}
                </Margin>
              </Col>
              <StepperWrapper>
                <Stepper
                  activeIndex={1}
                  steps={[
                    { label: 1, go: () => history.replace('/auth/register') },
                    {
                      label: 2,
                      go: () => history.replace('/auth/register/extendedRegistrationStep'),
                    },
                    { label: 3 },
                  ]}
                />
              </StepperWrapper>
            </Row>
          </Margin>
          <Margin top="16">
            <FormCreator
              hideButton
              formFields={formFields}
              formDetails={formDetails}
              handleFormFieldChange={handleFormFieldChange}
              errors={formFields.errors}
            />
          </Margin>
          <Row>
            <Col size={{ xs: 1 / 1, md: 4 / 12, lg: 3 / 12 }}>
              <RegisterButton onClick={submitWithProfileData} type="submitWithProfileData" primary>
                {t('global.createAccountButton')}
              </RegisterButton>
            </Col>
          </Row>
        </PageContent>
      </Container>
    </>
  );
}

ExtendedRegistrationStep.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  jobsTitles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  professionalTitles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  ma: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  yc: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  gender: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  profilePrivacy: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  userRegistrationData: PropTypes.arrayOf().isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  loadGlobalAvailableMA: PropTypes.func.isRequired,
  loadGlobalAvailableYC: PropTypes.func.isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,
  loadGlobalProfessionalTitles: PropTypes.func.isRequired,
  loadGlobalJobsTitles: PropTypes.func.isRequired,
  loadGlobalCourseCloudTags: PropTypes.func.isRequired,
  loadGlobalGender: PropTypes.func.isRequired,
  loadGlobalIndustries: PropTypes.func.isRequired,
  loadGlobalProfilePrivacy: PropTypes.func.isRequired,
  loadUserRegistrationData: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,

  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    dob: PropTypes.string,
    memberAssociation: PropTypes.shape({}),
    youngCrew: PropTypes.shape({}),
    country: PropTypes.shape({ id: PropTypes.number.isRequired }),
    userProfile: PropTypes.shape({
      company: PropTypes.string,
      countryOfOrigin: PropTypes.shape({ id: PropTypes.number.isRequired }),
      telephone: PropTypes.string,
      proffesionalTitle: PropTypes.shape({ id: PropTypes.number.isRequired }),
      jobTitle: PropTypes.shape({ id: PropTypes.number.isRequired }),
      interest: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired })),
      profilePrivacy: PropTypes.shape({ id: PropTypes.number.isRequired }),
      gender: PropTypes.shape({ id: PropTypes.number.isRequired }),
      industry: PropTypes.shape({ id: PropTypes.number.isRequired }),
      shortBio: PropTypes.string,
      imageFile: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = ({ globals, registration, loading }) => ({
  loading: !!loading.filter((item) => item.type === 'ADD_USER_CERTS').length,
  countries: globals.countries,
  ma: globals.availableMA,
  yc: globals.availableYC,
  professionalTitles: globals.professionalTitles,
  jobsTitles: globals.jobsTitles,
  tags: globals.course.cloudTags,
  profilePrivacy: globals.profilePrivacy,
  userRegistrationData: registration.user,
  industries: globals.industries,
  gender: globals.gender,
  firstName: registration.user.firstName,
  lastName: registration.user.lastName,
  country: registration.user.country,
  dob: registration.user.dob,
  password: registration.user.password,
  repassword: registration.user.repassword,
  terms: registration.user.terms,
});

const mapDispatchToProps = (dispatch) => ({
  registerUser: (history, payload, setErrors) =>
    dispatch(registerUserCreator(history, payload, setErrors)),
  loadGlobalAvailableMA: () => dispatch(loadGlobalAvailableMACreator()),
  loadGlobalAvailableYC: () => dispatch(loadGlobalAvailableYCCreator()),
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  loadGlobalProfessionalTitles: () => dispatch(loadGlobalProfessionalTitlesCreator()),
  loadGlobalJobsTitles: () => dispatch(loadGlobalJobsTitlesCreator()),
  loadGlobalCourseCloudTags: () => dispatch(loadGlobalCourseCloudTagsCreator()),
  loadGlobalGender: () => dispatch(loadGlobalGenderCreator()),
  loadGlobalProfilePrivacy: () => dispatch(loadGlobalProfilePrivacyCreator()),
  loadGlobalIndustries: () => dispatch(loadGlobalIndustriesCreator()),
  loadUserRegistrationData: () => dispatch(getRegistrationUserDataCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedRegistrationStep);
