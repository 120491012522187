import { useEffect } from 'react';
import { isLogin } from 'api';
import { checkPermissions } from 'helpers';

const useHistoryAppType = (history, setAppType, userPermissions) => {
  const appTypeChanges = (pathname) => {
    let appTypePath = null;
    const prevAppType = window.localStorage.getItem('appType');
    const typeFromPath = pathname.split('/')[1];
    switch (typeFromPath) {
      case 'user':
      case 'organisation':
      case 'ma':
      case 'yc':
      case 'ipma':
        appTypePath = typeFromPath;
        break;
      default:
        appTypePath = 'guest';
    }

    if (
      appTypePath !== 'guest' &&
      isLogin() &&
      checkPermissions({ appType: appTypePath }, userPermissions)
    ) {
      setAppType(appTypePath);
      window.localStorage.setItem('appType', appTypePath);
    } else if (isLogin() && appTypePath === 'guest' && prevAppType) {
      setAppType(prevAppType);
    } else if (isLogin() && appTypePath === 'guest') {
      setAppType('user');
    }
  };

  useEffect(() => {
    appTypeChanges(history.location.pathname);

    return history.listen((location) => {
      appTypeChanges(location.pathname);
    });
  }, [userPermissions]);
};

export default useHistoryAppType;
