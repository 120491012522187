import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSubRoutes, useExactRedirect } from 'hooks';
import { globalPropTypes } from 'helpers';

import { loadIpmaProfileCreator } from 'store/actionsCreators';

const Profile = ({ routes, getIpmaProfile }) => {
  useEffect(() => {
    getIpmaProfile();
  }, []);
  const [renderSubRoutes] = useSubRoutes(routes);
  const [renderExactRedirect] = useExactRedirect('/ipma/profile', '/ipma/profile/view');

  return (
    <>
      {renderSubRoutes()}
      {renderExactRedirect()}
    </>
  );
};

Profile.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  getIpmaProfile: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  routes: [],
};

const mapDispatchToProps = (dispatch) => ({
  getIpmaProfile: () => dispatch(loadIpmaProfileCreator()),
});

export default connect(null, mapDispatchToProps)(Profile);
