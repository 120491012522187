import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Col,
  Header,
  PageContent,
  Container,
  Table,
  Alert,
  Spinner,
  Download,
  FiltersControls,
  Pagination,
  Alerts,
  PrivateComponent,
  CsvUpload,
} from 'components';

import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import { iconList } from 'assets';
import { ReactSVG } from 'react-svg';
import { User } from 'containers/organisation/Courses/Actions/Completion/Completion.styled';

import {
  loadIpmaEventCreator,
  loadIpmaEventParticipantsCreator,
  setIpmaEventParticipantsPerPageCreator,
  setIpmaEventParticipantsPageCreator,
  uploadIpmaEventParticipantsCreator,
} from 'store/actionsCreators';

import createReducer, { defaultState, setInitDataCreator } from '../Events/Edit/reducer';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.events',
    url: '/ipma/events',
  },
  {
    title: 'navigation.breadcrumbs.ipma.issueBadges',
    url: '',
  },
];

const IssueBadge = ({
  items,
  page,
  pages,
  perPage,
  loading,
  match,
  loadEvent,
  loadParticipants,
  setPage,
  setPerPage,
  uploadParticipantsCsv,
}) => {
  const { t } = useTranslation();
  const {
    params: { id: eventId },
  } = match;
  const [{ data: event }, dispatch] = useReducer(createReducer, defaultState);

  useEffect(() => {
    loadEvent(eventId, (payload) => dispatch(setInitDataCreator(payload)));
  }, [eventId, loadEvent]);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadParticipants(eventId);
  }, [page, perPage]);

  const handleChangePerPage = ({ value }) => {
    setPerPage(value);
  };

  const getStatus = (status) => {
    switch (status) {
      case 'STATUS_CLAIMED':
        return { label: 'Claimed', icon: 'checkThin' };
      case 'STATUS_NEW':
        return { label: 'Pending', icon: 'question' };
      case 'STATUS_REJECTED':
        return { label: 'Rejected', icon: 'close' };
      default:
        return null;
    }
  };

  const renderUser = (firstName, lastName, status) => {
    const icon = getStatus(status) ? getStatus(status).icon : 'question';
    return (
      <User icon={icon}>
        <ReactSVG className="hide" src={iconList[icon]} />
        {firstName} {lastName}
      </User>
    );
  };

  const onSubmitCsv = (csvFile, delim) => {
    const formData = new FormData();
    formData.append('eventParticipantsFile', csvFile);
    formData.append('delimiter', delim);
    uploadParticipantsCsv(eventId, formData);
  };

  return (
    <>
      <Header
        title={t('page.ipma.issueBadges.title')}
        description={event.name || ''}
        breadcrumbs={breadcrumbs}
        renderActions={() => (
          <PrivateComponent
            permissions={{
              roles: ['ipma_events_issue_badge_event_import_csv'],
            }}
          >
            <CsvUpload
              title={t('page.ipma.issueBadges.uploadParticipants')}
              fieldLable="Event participants csv"
              onSubmit={onSubmitCsv}
            />
          </PrivateComponent>
        )}
      />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="ipma" />
          </Margin>
          <Col size={{ md: 1 / 3 }} style={{ paddingLeft: 0 }}>
            <Margin bottom={{ xs: 16 }} top={{ xs: 16 }}>
              <Download
                src={`${process.env.PUBLIC_URL}/files/Participants template.csv`}
                title="Event participants template"
                download
                downloadTitle="Download template"
              />
            </Margin>
          </Col>
          <Margin as="p" bottom="24">
            {t('page.ipma.issueBadges.description')}
          </Margin>
          {!loading && isEmpty(items) ? (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Alert type="info">{t('global.noResultsFound')}</Alert>
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              <FiltersControls perPageSelected={perPage} perPageOnChange={handleChangePerPage} />
              <Table tablebreakpoint="lg">
                <Table.Head>
                  <Table.Row>
                    <Table.Heading>{t('page.ipma.issueBadges.firstLastName')}</Table.Heading>
                    <Table.Heading>{t('page.ipma.issueBadges.email')}</Table.Heading>
                    <Table.Heading>{t('page.ipma.issueBadges.eventRole')}</Table.Heading>
                    <Table.Heading>{t('page.ipma.issueBadges.status')}</Table.Heading>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={4}>
                        <Spinner />
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && !isEmpty(items)
                    ? items.map((user, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.issueBadges.firstLastName')}:</Table.Label>
                            {renderUser(user.firstName, user.lastName, user.status)}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.issueBadges.email')}:</Table.Label>
                            {user.email}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.issueBadges.awardRole')}:</Table.Label>
                            {user.eventRole}
                          </Table.Cell>
                          <Table.Cell>
                            {getStatus(user.status) ? getStatus(user.status).label : null}
                          </Table.Cell>
                        </Table.Row>
                      ))
                    : null}
                </Table.Body>
              </Table>
              <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
            </>
          )}
        </Container>
      </PageContent>
    </>
  );
};

IssueBadge.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  loadEvent: PropTypes.func.isRequired,
  loadParticipants: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  uploadParticipantsCsv: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ipma, loading }) => ({
  ...ipma.issueBadges,
  loading: !!loading.filter((item) => item.type === 'IPMA').length,
});

const mapDispatchToProps = (dispatch) => ({
  loadEvent: (payload, setEvent) => dispatch(loadIpmaEventCreator(payload, setEvent)),
  loadParticipants: (eventId) => dispatch(loadIpmaEventParticipantsCreator(eventId)),
  setPage: (payload) => dispatch(setIpmaEventParticipantsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setIpmaEventParticipantsPerPageCreator(payload)),
  uploadParticipantsCsv: (id, payload) => dispatch(uploadIpmaEventParticipantsCreator(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueBadge);
