import { get, isEmpty, map } from 'lodash';
import { parseDate, parsePlace } from 'helpers';
import parseUrlTitle from './parseUrlTitle';

export default (type, items, auth = false) => {
  if (isEmpty(items)) return [];

  return items.map((item) => {
    const id = item.id || null;
    const city = get(item, 'address.city') || get(item, 'location.city') || '';
    const country = get(item, 'address.country.name') || get(item, 'location.country.name') || '';
    const countryCode =
      get(item, 'address.country.code') || get(item, 'location.country.code') || '';
    const urlTitle = parseUrlTitle(item.name);

    const obj = {
      id: item.id || null,
      title: item.name || '',
      thumbnail: item.photo ? item.photo : item.logo || null,
      description: item.shortDescription ? item.shortDescription : item.description || '',
      place: parsePlace(city, country),
      country: countryCode,
      countryCode,
    };

    switch (type) {
      case 'courses':
        obj.url = auth
          ? { pathname: `/training/${id}/${urlTitle}`, state: { authModal: true } }
          : `/training/${id}/${urlTitle}`;
        obj.date = [parseDate(item.startDate), parseDate(item.endDate)]
          .filter((el) => !!el)
          .join(' - ');
        obj.assessors = map(item.courseAssesors, (n) => ({
          label: [get(n, 'user.firstName') || '', get(n, 'user.lastName') || '']
            .filter((el) => !!el)
            .join(' '),
          value: get(n, 'user.id'),
        }));
        obj.regLogoZipFile = item.regLogoZipFile;
        obj.googleFileUrl = item.googleFileUrl;
        obj.possibleSubcourse = item.possibleSubcourse || false;

        obj.cpdRec = true;
        obj.regNumber = item.regNumber;

        obj.courseGeneralStatus = item.courseGeneralStatus;

        if (item.organization && item.organization.memberAssociation) {
          obj.cpdRec = item.organization.memberAssociation.cpdRec;
        }

        if (item.isSubcourse) {
          obj.title = item.originalCourseName || '';
          obj.subtitle = item.name || '';
        }

        obj.cpdRoles =
          item.cpdRoleEventCourses && item.cpdRoleEventCourses.length > 0
            ? item.cpdRoleEventCourses.map((el) => ({
                role: el.cpdRole,
                points: el.points,
                id: el.id,
              }))
            : null;
        break;
      case 'events':
        obj.url = auth
          ? { pathname: `/events/${id}/${urlTitle}`, state: { authModal: true } }
          : `/events/${id}/${urlTitle}`;
        obj.date = [parseDate(item.dateFrom), parseDate(item.dateTo)]
          .filter((el) => !!el)
          .join(' - ');
        obj.price = [item.price || '', get(item, 'currency.sign') || get(item, 'currency.code')]
          .filter((el) => !!el)
          .join(' ');
        break;
      case 'ma':
        obj.url = auth
          ? { pathname: `/ipma-associations/${id}/${urlTitle}`, state: { authModal: true } }
          : `/ipma-associations/${id}/${urlTitle}`;
        obj.events = item.countEvents ? String(item.countEvents) : '';
        break;
      case 'organisations':
        obj.url = auth
          ? { pathname: `/organisations/${id}/${urlTitle}`, state: { authModal: true } }
          : `/organisations/${id}/${urlTitle}`;
        obj.user = item.contactPerson || '';
        obj.courses = item.coursesCount ? String(item.coursesCount) : '';
        break;
      case 'yc':
        obj.url = auth
          ? { pathname: `/ipma-young-crews/${id}/${urlTitle}`, state: { authModal: true } }
          : `/ipma-young-crews/${id}/${urlTitle}`;
        obj.events = item.countEvents ? String(item.countEvents) : '';
        break;
      case 'project':
        obj.url = auth
          ? { pathname: `/projects/${id}/${urlTitle}`, state: { authModal: true } }
          : `/projects/${id}/${urlTitle}`;
        obj.date = [parseDate(item.dateFrom), parseDate(item.dateTo)]
          .filter((el) => !!el)
          .join(' - ');
        // obj.thumbnail = item.banner ? item.banner : null;
        obj.type = item.type ? item.type : null;
        obj.projectStatus = item.projectStatus || null;
        break;
      case 'project-role':
        obj.url = auth
          ? { pathname: `/project-role/${id}/${urlTitle}`, state: { authModal: true } }
          : `/project-role/${id}/${urlTitle}`;
        obj.type = item.type ? item.type : null;
        obj.description = item.roleDescription;
        obj.skills = map(item.skills, 'value') || [];
        obj.projectOpen = item.open || item.archived;
        obj.careerLevel = item.careerLevel ? item.careerLevel.value : null;
        obj.ycProjectRoleType = item.ycProjectRoleType ? item.ycProjectRoleType.value : null;
        obj.occupation = item.occupation || '';
        obj.date = [parseDate(item.dateFrom), parseDate(item.dateTo)]
          .filter((el) => !!el)
          .join(' - ');
        obj.positionTimeStatus = item.positionTimeStatus;
        break;
      case 'cpd-pending':
        obj.id = item.id || null;
        obj.url = `/user-profile/${item.cpd.user.uniqueUrl}`;
        obj.title = item.cpd.name || '';
        obj.diploma = item.cpd.diploma || '';
        obj.description = item.cpd.description || '';
        obj.linkToAgenda = item.cpd.linkToAgenda || '';
        obj.points = item.cpd.manualPoints || '';
        obj.category = item.cpd && item.cpd.category ? item.cpd.category.label : '';
        obj.isFormal =
          item.cpd && item.cpd.category && item.cpd.category.isFormal ? 'Formal' : 'Non-formal';
        obj.user = `${item.cpd.user.firstName} ${item.cpd.user.lastName}` || '';
        obj.createdAt = parseDate(item.cpd.createdAt);
        break;
      default:
        break;
    }

    return obj;
  });
};
