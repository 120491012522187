import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH } from '..';

export const login = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'login_check'), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
};

export const socialLogin = async (social) => {
  return ky.get(urljoin(BASE_PATH, 'auth', 'connect', social));
};

export const getUser = async () => {
  return ky.get(urljoin(BASE_PATH, 'user', 'details'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};
