import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  PageContent,
  Container,
  Header,
  Row,
  Col,
  Filters,
  Dropdown,
  Heading,
  FiltersControls,
  Pagination,
  SeoSection,
  BoxList,
  Spinner,
} from 'components';
import { connect } from 'react-redux';
import { Padding, Margin } from 'styled-components-spacing';
import {
  loadListCreator,
  setListPageCreator,
  setListPerPageCreator,
  setListSortCreator,
  setListFiltersCreator,
  removeListFiltersCreator,
  loadGlobalCourseAttributesCreator,
} from 'store/actionsCreators';
import { isLoading, globalPropTypes } from 'helpers';
import { useTranslation } from 'react-i18next';
import { CourseGeneralStatus } from '../../../dictionaries';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.courses',
    url: '/training',
  },
];

const CoursesList = ({
  appType,
  loading,
  list,
  loadList,
  setPage,
  setPerPage,
  setSort,
  setFilters,
  removeFilters,
  loadCourseAttributes,
  perspectiveCompetencies,
  peopleCompetencies,
  practiceCompetencies,
  courseTypes,
  deliveryModes,
}) => {
  const { filters, items, page, pages, perPage, sort } = list;
  const { t } = useTranslation();
  const scrollAfterFiltersRef = useRef(null);

  useEffect(() => {
    loadCourseAttributes();
  }, []);

  useEffect(() => {
    setPage(1);
    loadList();
  }, [perPage, sort]);

  useEffect(() => {
    setSort('name.asc');
  }, []);

  const renderHeaderActions = () => {
    switch (appType) {
      case 'organisation':
        return ({ Button: ButtonRender }) => (
          <ButtonRender
            secondary
            as={Link}
            to={`/organisation/${window.localStorage.getItem(
              'currentOrganization',
            )}/courses/create`}
          >
            {t('page.listCourses.createCourseButton')}
          </ButtonRender>
        );
      default:
        return null;
    }
  };

  const filtersFields = [
    {
      type: 'input',
      name: 'name',
      id: 'f-name',
      label: t('page.listCourses.nameLabel'),
      placeholder: t('page.listCourses.namePlaceholder'),
    },
    {
      type: 'input',
      name: 'location',
      id: 'f-location',
      label: t('page.listCourses.locationLabel'),
      placeholder: t('page.listCourses.locationPlaceholder'),
    },
    {
      type: 'select',
      name: 'courseGeneralStatus',
      id: 'f-courseGeneralStatus',
      label: t('page.listCourses.courseGeneralStatusLabel'),
      placeholder: t('page.listCourses.courseGeneralStatusPlaceholder'),
      options: CourseGeneralStatus(),
    },
    {
      type: 'datepicker',
      name: 'dateFrom',
      id: 'f-dateFrom',
      label: t('page.listCourses.dateFromLabel'),
      placeholder: t('page.listCourses.dateFromPlaceholder'),
    },
    {
      type: 'datepicker',
      name: 'dateTo',
      id: 'f-dateTo',
      label: t('page.listCourses.dateToLabel'),
      placeholder: t('page.listCourses.dateToPlaceholder'),
    },
    {
      type: 'select',
      name: 'courseType',
      id: 'f-courseType',
      label: t('page.listCourses.courseTypeLabel'),
      placeholder: t('page.listCourses.courseTypePlaceholder'),
      options: courseTypes,
    },
    {
      type: 'select',
      name: 'modeOfDelivery',
      id: 'f-modeOfDelivery',
      label: t('page.listCourses.modeOfDeliveryLabel'),
      placeholder: t('page.listCourses.modeOfDeliveryPlaceholder'),
      options: deliveryModes,
    },
    {
      type: 'checkbox',
      name: 'perspectiveCompetencies',
      id: 'f-perspectiveCompetencies',
      label: t('page.listCourses.perspectiveCompetenciesLabel'),
      options: perspectiveCompetencies,
      isOpen: false,
    },
    {
      type: 'checkbox',
      name: 'peopleCompetencies',
      id: 'f-peopleCompetencies',
      label: t('page.listCourses.peopleCompetenciesLabel'),
      options: peopleCompetencies,
      isOpen: false,
    },
    {
      type: 'checkbox',
      name: 'practiceCompetencies',
      id: 'f-practiceCompetencies',
      label: t('page.listCourses.practiceCompetenciesLabel'),
      options: practiceCompetencies,
      isOpen: false,
    },
  ];

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.listCourses.title')}
        renderActions={renderHeaderActions()}
      />
      <PageContent>
        <Container>
          <Row>
            <Col size={{ xl: 1 / 3 }}>
              <Padding all={{ xs: 12, xl: 20 }} className="border-xs">
                <Dropdown
                  openbreakpoint="xl"
                  title={
                    <Heading as="h3" size="h4" noLine>
                      {t('page.listCourses.filtersTitle')}
                    </Heading>
                  }
                >
                  <Filters
                    fields={filtersFields}
                    filters={filters}
                    onChangeFilter={(payload) => setFilters(payload)}
                    onClickApplyFilters={() => {
                      setPage(1);
                      loadList();
                    }}
                    onClickRemoveAllFilters={() => removeFilters()}
                    scrollAfterFiltersRef={scrollAfterFiltersRef}
                  />
                </Dropdown>
              </Padding>
            </Col>
            <Margin as={Col} size={{ xl: 2 / 3 }} top={{ xs: 16, xl: 0 }}>
              <FiltersControls
                perPageSelected={perPage}
                perPageOnChange={(payload) => setPerPage(payload.value)}
                sortBySelected={sort}
                sortByOnChange={(payload) => setSort(payload.value)}
              />
              {loading ? <Spinner /> : null}
              {!loading ? (
                <div ref={scrollAfterFiltersRef}>
                  <BoxList
                    type="course"
                    items={items}
                    alert={t('global.noResultsFound')}
                    headerAs="h2"
                  />
                  <Pagination
                    styled
                    currentPage={page}
                    totalPages={pages}
                    onChange={(payload) => {
                      setPage(payload);
                      loadList();
                    }}
                  />
                </div>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
      <SeoSection title={t('page.listCourses.seoTitle')}>
        <p className="text-18 font-weight-medium text-gray-200 text-center">
          {t('page.listCourses.seoContent')}
        </p>
      </SeoSection>
    </>
  );
};

CoursesList.propTypes = {
  appType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  list: globalPropTypes.ListPropsTypes.isRequired,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  removeFilters: PropTypes.func.isRequired,
  loadCourseAttributes: PropTypes.func.isRequired,
  perspectiveCompetencies: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes),
  peopleCompetencies: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes),
  practiceCompetencies: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes),
  courseTypes: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes),
  deliveryModes: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes),
};

CoursesList.defaultProps = {
  perspectiveCompetencies: [],
  peopleCompetencies: [],
  practiceCompetencies: [],
  courseTypes: [],
  deliveryModes: [],
  loading: false,
};

const mapStateToProps = ({ user, loading, list: { courses: list }, globals: { course } }) => ({
  appType: user.appType,
  loading: isLoading(loading, 'LOAD_LIST_COURSES'),
  list,
  perspectiveCompetencies: course.perspectiveCompetencies,
  peopleCompetencies: course.peopleCompetencies,
  practiceCompetencies: course.practiceCompetencies,
  courseTypes: course.courseTypes,
  deliveryModes: course.deliveryModes,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: () => dispatch(loadListCreator('courses')),
  setPage: (payload) => dispatch(setListPageCreator('courses', payload)),
  setPerPage: (payload) => dispatch(setListPerPageCreator('courses', payload)),
  setSort: (payload) => dispatch(setListSortCreator('courses', payload)),
  setFilters: (payload) => dispatch(setListFiltersCreator('courses', payload)),
  removeFilters: () => dispatch(removeListFiltersCreator('courses')),
  loadCourseAttributes: () => dispatch(loadGlobalCourseAttributesCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesList);
