import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';

import { Wrapper, Title } from './ButtonAction.styled';

const ButtonAction = ({ as, icon, title, verticalviewbreakpoint, disabled, ...res }) => {
  return (
    <Wrapper
      as={as}
      icon={icon}
      verticalviewbreakpoint={verticalviewbreakpoint}
      disabled={disabled}
      {...res}
    >
      <ReactSVG src={iconList[icon]} />
      {title && <Title disabled={disabled}>{title}</Title>}
    </Wrapper>
  );
};

ButtonAction.propTypes = {
  as: PropTypes.oneOfType([PropTypes.oneOf(['button', 'a']), PropTypes.elementType]),
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  verticalviewbreakpoint: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonAction.defaultProps = {
  as: 'button',
  verticalviewbreakpoint: null,
  title: '',
  disabled: false,
};

export default ButtonAction;
