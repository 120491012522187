import React from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing';
import { Heading } from 'components';
import StyledMargin from './ProfileSetion.styled';

function ProfileSection({ heading, predicate, displayer, ...res }) {
  const Component = displayer;

  return (
    <StyledMargin as="section" top={{ xs: 32 }}>
      <Heading size="h4">{heading}</Heading>
      <Margin top={{ xs: 24 }}>
        <Component {...res} />
      </Margin>
    </StyledMargin>
  );
}

ProfileSection.propTypes = {
  heading: PropTypes.string.isRequired,
  predicate: PropTypes.func,
  displayer: PropTypes.elementType.isRequired,
};

ProfileSection.defaultProps = {
  predicate: () => true,
};

export default ProfileSection;
