import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  PageContent,
  Container,
  Header,
  Row,
  Col,
  Heading,
  HeadingWrapper,
  Cms,
  IconText,
  Map,
  BoxList,
  ThumbnailCard,
} from 'components';
import { Margin, Padding } from 'styled-components-spacing';
import { loadListViewCreator, removeListViewCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { newLineToBr, correctExternalLink } from 'helpers';
import { useAuthLock } from 'hooks';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.projects',
    url: '/projects',
  },
];

const View = ({ loadView, removeView, view, history }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [renderAuthLock] = useAuthLock(history);

  const {
    title,
    description,
    website,
    place,
    countryCode,
    countryName,
    startDate,
    endDate,
    mainLanguage,
    secondaryLanguage,
    lat,
    lng,
    organisator,
    level,
    type,
    roles,
  } = view;

  useEffect(() => {
    loadView(id);

    return () => {
      removeView();
    };
  }, []);

  const details = [
    { name: 'page.singleProject.country', value: `${countryName}, ${countryCode}` },
    { name: 'page.singleProject.startingDate', value: startDate },
    { name: 'page.singleProject.endingDate', value: endDate },
    { name: 'page.singleProject.mainLanguage', value: mainLanguage },
    { name: 'page.singleProject.secondaryLanguages', value: secondaryLanguage },
    { name: 'page.singleProject.level', value: level },
    { name: 'page.singleProject.type', value: type },
  ];

  const renderDetails = () => (
    <Padding horizontal={{ xl: 20 }} vertical="20" style={{ marginBottom: '-16px' }}>
      {place && (
        <Margin as={Row} bottom="16">
          <Col size={1 / 2}>{t('page.singleProject.address')}</Col>
          <Col size={1 / 2} className="text-right">
            <strong>
              <IconText inline icon="marker" text={place} />
            </strong>
          </Col>
        </Margin>
      )}

      {details &&
        details.map(
          (item) =>
            item.value &&
            item.value.indexOf('Online') === -1 && (
              <Margin as={Row} bottom="16">
                <Col size={1 / 2}>{t(item.name)}</Col>
                <Col size={1 / 2} className="text-right">
                  <strong>{item.value}</strong>
                </Col>
              </Margin>
            ),
        )}
    </Padding>
  );

  const renderContactDetails = () => (
    <Padding all="20" className="bg-gray-100">
      {website && (
        <a
          href={correctExternalLink(website)}
          target="_blank"
          rel="noopener noreferrer"
          className="link link--16 word-break"
        >
          {website}
        </a>
      )}
    </Padding>
  );

  const renderMap = () => {
    if (!lng || !lat) return null;

    return <Map lat={lat} lng={lng} />;
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={title} big headingFirstLine />
      <PageContent>
        {renderAuthLock()}
        <Container>
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                <Cms>
                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleProject.projectDesc')}
                    </Heading>
                  </HeadingWrapper>

                  {description ? (
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
                  ) : null}
                </Cms>
              </Margin>

              {!!roles && (
                <>
                  <HeadingWrapper>
                    <Heading as="h2" size="h4">
                      {t('page.singleProject.positions')}
                    </Heading>
                  </HeadingWrapper>
                  <BoxList type="project-role" items={roles} alert={t('global.noResultsFound')} />
                </>
              )}
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              {!!organisator && (
                <Margin bottom="16">
                  <HeadingWrapper>
                    <Heading as="h2" size="h4" firstLine>
                      {t('page.singleProject.organisator')}
                    </Heading>
                  </HeadingWrapper>
                  <ThumbnailCard
                    thumbnail={organisator.thumbnail}
                    title={organisator.title}
                    link={organisator.url}
                  />
                </Margin>
              )}
              <HeadingWrapper>
                <Heading as="h2" size="h4" firstLine>
                  {t('page.singleProject.details')}
                </Heading>
              </HeadingWrapper>
              <div className="border-xl">
                {renderContactDetails()}
                {renderDetails()}
                {renderMap()}
              </div>
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

View.propTypes = {
  loadView: PropTypes.func.isRequired,
  removeView: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  view: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    website: PropTypes.string,
    place: PropTypes.string,
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    mainLanguage: PropTypes.string,
    secondaryLanguage: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    organisator: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      url: PropTypes.string,
      thumbnail: PropTypes.string,
      place: PropTypes.string,
      description: PropTypes.string,
      website: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    level: PropTypes.string,
    type: PropTypes.string,
    roles: PropTypes.shape([]),
  }),
};

View.defaultProps = {
  view: {},
};

const mapStateToProps = ({
  user: { appType },
  list: {
    projects: { view },
  },
}) => ({
  appType,
  view,
});

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload, history) => dispatch(loadListViewCreator('projects', payload, history)),
  removeView: () => dispatch(removeListViewCreator('projects')),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
