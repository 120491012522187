import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Col,
  Header,
  PageContent,
  Container,
  Table,
  Alert,
  Spinner,
  Download,
  FiltersControls,
  Pagination,
  Modal,
  Row,
  Heading,
  Field,
  FileInput,
  Alerts,
  PrivateComponent,
} from 'components';

import { Action } from 'components/Modal/Modal.styled';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import { iconList } from 'assets';
import { ReactSVG } from 'react-svg';
import { User } from 'containers/organisation/Courses/Actions/Completion/Completion.styled';

import {
  loadIpmaAwardParticipantsCreator,
  setIpmaAwardParticipantsPerPageCreator,
  setIpmaAwardParticipantsPageCreator,
  uploadIpmaAwardParticipantsCreator,
} from 'store/actionsCreators';
import { UploadParticipant } from '../IssueBadge/IssueBadge.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.awards',
    url: '/ipma/awards',
  },
  {
    title: 'navigation.breadcrumbs.ipma.issueBadges',
    url: '',
  },
];

const IssueAward = ({
  items,
  page,
  pages,
  perPage,
  loading,
  match,
  location,
  loadParticipants,
  setPage,
  setPerPage,
  uploadParticipantsCsv,
}) => {
  const { t } = useTranslation();
  const {
    params: { id: awardId },
  } = match;
  const {
    state: { awardName: name },
  } = location;

  const [openModal, setOpenModal] = useState(false);
  const [participantsCsv, setParticipantsCsv] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadParticipants(awardId);
  }, [page, perPage]);

  const handleChangePerPage = ({ value }) => {
    setPerPage(value);
  };

  const getStatus = (status) => {
    switch (status) {
      case 'STATUS_CLAIMED':
        return { label: 'Claimed', icon: 'checkThin' };
      case 'STATUS_NEW':
        return { label: 'Pending', icon: 'question' };
      case 'STATUS_REJECTED':
        return { label: 'Rejected', icon: 'close' };
      default:
        return null;
    }
  };

  const renderUser = (firstName, lastName, status) => {
    const icon = getStatus(status) ? getStatus(status).icon : 'question';
    return (
      <User icon={icon}>
        <ReactSVG className="hide" src={iconList[icon]} />
        {firstName} {lastName}
      </User>
    );
  };

  const submit = () => {
    if (participantsCsv) {
      const formData = new FormData();
      formData.append('awardParticipantsFile', participantsCsv);
      setOpenModal(false);
      uploadParticipantsCsv(awardId, formData);
    } else {
      setUploadError('Field cannot be empty!');
    }
  };

  const renderUploadModal = () => (
    <UploadParticipant>
      <Heading as="h4" noLine center>
        {t('page.ipma.issueBadges.uploadParticipants')}
      </Heading>
      <form onSubmit={(e) => e.preventDefault()}>
        <Margin top="24">
          <Row halign="center">
            <Col size={{ xs: 2 / 3 }}>
              <Field
                id="participants-csv"
                label="Event participants csv"
                required
                error={uploadError}
                description={t('global.uploadCsvInfo')}
              >
                <FileInput
                  id="exprt-cv"
                  placeholder={t('global.uploadPlaceholder')}
                  fileName={participantsCsv ? participantsCsv.name || participantsCsv.fileName : ''}
                  clear={() => setParticipantsCsv(null)}
                  onChange={(e) => {
                    setUploadError(null);
                    return setParticipantsCsv(e.target.files[0]);
                  }}
                />
              </Field>
            </Col>
          </Row>
        </Margin>
        <Margin top="24">
          <Row halign="center">
            <Col size={{ xs: 2 / 3 }}>
              <Action onClick={() => submit()}>{t('global.upload')}</Action>
            </Col>
          </Row>
        </Margin>
        <Margin top="12">
          <Row halign="center">
            <Col size={{ xs: 2 / 3 }}>
              <Action onClick={() => setOpenModal(false)}>{t('global.closeButton')}</Action>
            </Col>
          </Row>
        </Margin>
      </form>
    </UploadParticipant>
  );

  return (
    <>
      <Header
        title={t('page.ipma.issueAwards.title')}
        description={name || ''}
        breadcrumbs={breadcrumbs}
        renderActions={({ Button: ButtonRender }) => (
          <PrivateComponent
            permissions={{
              roles: ['ipma_award_issue_badge_award_import_csv'],
            }}
          >
            <ButtonRender secondary onClick={() => setOpenModal(true)}>
              {t('page.ipma.issueBadges.uploadParticipants')}
            </ButtonRender>
          </PrivateComponent>
        )}
      />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="ipma" />
          </Margin>
          <Col size={{ md: 1 / 3 }} style={{ paddingLeft: 0 }}>
            <Margin bottom={{ xs: 16 }} top={{ xs: 16 }}>
              <Download
                src={`${process.env.PUBLIC_URL}/files/Participants template.csv`}
                title="Award participants template"
                download
                downloadTitle="Download template"
              />
            </Margin>
          </Col>
          <Margin as="p" bottom="24">
            {t('page.ipma.issueBadges.description')}
          </Margin>
          {!loading && isEmpty(items) ? (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Alert type="info">{t('global.noResultsFound')}</Alert>
              </Table.Cell>
            </Table.Row>
          ) : (
            <>
              <FiltersControls perPageSelected={perPage} perPageOnChange={handleChangePerPage} />
              <Table tablebreakpoint="lg">
                <Table.Head>
                  <Table.Row>
                    <Table.Heading>{t('page.ipma.issueBadges.firstLastName')}</Table.Heading>
                    <Table.Heading>{t('page.ipma.issueBadges.email')}</Table.Heading>
                    <Table.Heading>{t('page.ipma.issueBadges.eventRole')}</Table.Heading>
                    <Table.Heading>{t('page.ipma.issueBadges.status')}</Table.Heading>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={4}>
                        <Spinner />
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && !isEmpty(items)
                    ? items.map((user, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.issueBadges.firstLastName')}:</Table.Label>
                            {renderUser(user.firstName, user.lastName, user.status)}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.issueBadges.email')}:</Table.Label>
                            {user.email}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.issueBadges.eventRole')}:</Table.Label>
                            {user.awardRole}
                          </Table.Cell>
                          <Table.Cell>
                            {getStatus(user.status) ? getStatus(user.status).label : null}
                          </Table.Cell>
                        </Table.Row>
                      ))
                    : null}
                </Table.Body>
              </Table>
              <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
            </>
          )}
          <Modal
            isOpen={openModal}
            customContent={renderUploadModal}
            onRequestClose={() => setOpenModal(false)}
          />
        </Container>
      </PageContent>
    </>
  );
};

IssueAward.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      awardName: PropTypes.string,
    }),
  }).isRequired,
  loadParticipants: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  uploadParticipantsCsv: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ipma, loading }) => ({
  ...ipma.awards.participants,
  loading: !!loading.filter((item) => item.type === 'IPMA').length,
});

const mapDispatchToProps = (dispatch) => ({
  loadParticipants: (eventId) => dispatch(loadIpmaAwardParticipantsCreator(eventId)),
  setPage: (payload) => dispatch(setIpmaAwardParticipantsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setIpmaAwardParticipantsPerPageCreator(payload)),
  uploadParticipantsCsv: (id, payload) => dispatch(uploadIpmaAwardParticipantsCreator(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueAward);
