import moment from 'moment';
import { takeLatest, call, select, put } from 'redux-saga/effects';
import i18next from 'i18next';

import { register, validate, resend } from 'api/services/registration';

import {
  setRegisterErrors,
  setRegistrationUserDataCreator,
  setNewAlertCreator,
  setNewLoadingCreator,
  removeLoadingCreator,
  loadUserAddListCertificatesCreator,
} from '../store/actionsCreators';
import {
  REGISTER_USER,
  VALIDATE_REGISTRATION_USER_DATA,
  RESEND_REIGSTRATION_ACTIVATE,
} from '../store/constants';

function* validateUserData({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    yield put(setRegistrationUserDataCreator(payload));
    const params = {
      ...payload,
      password: { password: payload.password, repassword: payload.repassword },
      country: payload.country ? payload.country.value : null,
      dob: payload.dob ? moment(payload.dob).format('YYYY-MM-DD HH:mm:ss') : null,
    };
    delete params.repassword;
    const response = yield call(validate, params);
    if (response.ok) {
      const filtration = yield select(({ user }) => user.add.filtration);
      yield put(loadUserAddListCertificatesCreator(filtration));
      history.push('/auth/register/extendedRegistrationStep');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    const { errors } = yield error.response.json();

    if (errors.length) {
      yield put(
        setRegisterErrors(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        ),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* registerUser({ history, payload, setErrors }) {
  yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
  try {
    const response = yield call(register, payload);
    if (response.ok) {
      history.push('/auth/register/summary');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();

      if (errors.length) {
        setErrors(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        );
        yield put(
          setRegisterErrors(
            errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
          ),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.error('[GET] registration error: ', error);
  }
}

function* resendActivate() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    let email = yield select(({ registration }) => registration.user.email);
    if (!email) {
      email = yield select(({ login }) => login.login.username);
    }
    const response = yield call(resend, { email });
    if (response.ok) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      yield put(
        setNewAlertCreator({
          type: 'auth',
          status: 'success',
          text: i18next.t('auth.verificationLinkSent'),
        }),
      );
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[POST] registration resend activate error: ', error);
  }
}

export default function* watchRegistration() {
  yield takeLatest(VALIDATE_REGISTRATION_USER_DATA, validateUserData);
  yield takeLatest(REGISTER_USER, registerUser);
  yield takeLatest(RESEND_REIGSTRATION_ACTIVATE, resendActivate);
}
