import styled, { css } from 'styled-components';

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.75rem;
  margin-right: 0.75rem;

  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-right: 1.5rem;
  }
`;

export const PaginationText = styled.span`
  display: block;
  margin-right: 0.75rem;
`;

export const PaginationSelect = styled.select`
  background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 12.13"><path d="M0 .5A.5.5 0 0 1 .5 0h16a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5H.5A.5.5 0 0 1 0 .5zm16.5 10.63H.5a.5.5 0 0 0 0 1h16a.5.5 0 0 0 0-1zm0-5.56H.5a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5h16a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5z" fill="rgb(171, 20, 16)"/></svg>');
  background-position: calc(100% - 1rem) 50%;
  background-repeat: no-repeat;
  background-size: 15px 11px;
  height: 2rem;
  border-width: 1px;
  border-color: #e5e5e7;
  color: #7d7d7d;
  border-radius: 9999px;
  padding-left: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0.75rem;

  padding-right: 3rem;
  text-transform: none;
  line-height: 1.15;

  @media (min-width: 768px) {
    min-width: 8rem;
  }
`;
