import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import StyledField, {
  Description,
  Error,
  Required,
  Icon,
  InputWrapper,
  CountChars,
} from './Field.styled';

import { iconList as icons } from '../../assets';
import Clearable from '../Clearable/Clearable';

function Field({
  label,
  icon,
  id,
  horizontalOrientation,
  description,
  error,
  required,
  small,
  children,
  clear,
  value,
  disabled,
  mrAuto,
  descriptionUpper,
  countChars,
  maxChars,
  appendLabelLink,
}) {
  return (
    <StyledField horizontal={horizontalOrientation} small={small} mrAuto={mrAuto}>
      {label ? (
        <label htmlFor={id}>
          {required ? <Required>* </Required> : ''}
          {label}
          {appendLabelLink ? (
            <a
              style={{ marginLeft: '4px', textDecoration: 'underline' }}
              href={appendLabelLink.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {appendLabelLink.label}
            </a>
          ) : null}
        </label>
      ) : null}
      {description && descriptionUpper ? (
        <Description marginTopNone>{description}</Description>
      ) : null}
      <InputWrapper>
        {icon ? (
          <Icon className="input-special">
            <ReactSVG src={icons[icon]} />
          </Icon>
        ) : null}
        {clear && value ? <Clearable clear={clear} disabled={disabled} /> : null}
        {children}
      </InputWrapper>
      {countChars !== null ? (
        <CountChars error={maxChars ? countChars.length > maxChars : false}>
          <span>{`${countChars.length}`}</span>
          <span>{`${maxChars ? ` / ${maxChars}` : ''}`}</span>
        </CountChars>
      ) : null}
      {error ? <Error>{error}</Error> : null}
      {description && !descriptionUpper ? <Description>{description}</Description> : null}
    </StyledField>
  );
}

Field.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  horizontalOrientation: PropTypes.bool,
  description: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  small: PropTypes.bool,
  clear: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  mrAuto: PropTypes.bool,
  descriptionUpper: PropTypes.bool,
  countChars: PropTypes.string,
  maxChars: PropTypes.number,
  appendLabelLink: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
};

Field.defaultProps = {
  label: null,
  id: null,
  horizontalOrientation: false,
  description: null,
  error: null,
  required: false,
  icon: null,
  small: false,
  clear: null,
  value: '',
  disabled: false,
  mrAuto: false,
  descriptionUpper: false,
  countChars: null,
  maxChars: null,
  appendLabelLink: null,
};

export default Field;
