import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FiltersControls, Pagination, Spinner, BoxList } from 'components';
import {
  loadYcEventsCreator,
  setYcEventsPageCreator,
  setYcEventsPerPageCreator,
} from 'store/actionsCreators';
import { checkPermissions, globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Duplicated = ({
  events,
  page,
  pages,
  perPage,
  loading,
  loadEvents,
  setPage,
  setPerPage,
  userPermissions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setPage(1);
  }, [perPage, setPage]);

  useEffect(() => {
    loadEvents({ status: 'duplicate' });
  }, [perPage, page, loadEvents]);

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="event"
            items={events}
            alert={t('global.noResultsFound')}
            customRenderActions={
              checkPermissions(
                {
                  roles: ['yc_events_create_event'],
                },
                userPermissions,
              )
                ? ({ item, ButtonAction }) => (
                    <ButtonAction
                      icon="duplicate"
                      title={t('global.publishButton')}
                      verticalviewbreakpoint="xl"
                      onClick={() => history.push(`/yc/event/create/${item.id}`)}
                    />
                  )
                : null
            }
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </>
      ) : null}
    </>
  );
};

Duplicated.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadEvents: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

const mapStateToProps = ({ yc, loading, user }) => ({
  events: yc.events.list.items,
  page: yc.events.list.page,
  pages: yc.events.list.pages,
  perPage: yc.events.list.perPage,
  loading: isLoading(loading, 'YC'),
  userPermissions: user.userPermissions,
});
const mapDispatchToProps = (dispatch) => ({
  loadEvents: (payload) => dispatch(loadYcEventsCreator(payload)),
  setPage: (payload) => dispatch(setYcEventsPageCreator(payload)),
  setPerPage: (payload) => dispatch(setYcEventsPerPageCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Duplicated);
