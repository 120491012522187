import qs from 'qs';
import urljoin from 'url-join';
import { isLogin } from 'api';
import ky from './kyInstance';

import { BASE_PATH, withAuthorizationHeader } from '..';

export const getSearchTotals = async (params = { q: '' }) => {
  return ky.get(urljoin(BASE_PATH, isLogin() ? '' : 'public', 'search', 'count'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getSearch = async (type, params = { q: '' }) => {
  return ky.get(urljoin(BASE_PATH, isLogin() ? '' : 'public', 'search', type), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};
