import { takeLatest, call, put, select, debounce, cancelled, all } from 'redux-saga/effects';

import * as organisationService from 'api/services/organisation';
import * as listService from 'api/services/list';

import {
  setCreateOrganisationErrorsCreator,
  setUserOrganisationCreator,
  setNewAlertCreator,
  setNewLoadingCreator,
  removeLoadingCreator,
  loadUserOrganisationCreator,
  setUserOrganisationPeopleCreator,
  loadUserOrganisationPeopleCreator,
  setUserOrganisationPeopleAddCreator,
  setOrganisationPersonResponsibleCreator,
  setOrganisationDashboardCreator,
  setOrganisationCourseCompletionCreator,
  setOrganisationCourseCompletionUsersCreator,
  setOrganisationDashboardCoursesCreator,
  loadOrganisationDashboardCoursesCreator,
  setOrganisationProfileCoursesCreator,
  setOrganisationCoursesBarCreator,
  setOrganisationCoursesCreator,
  setOrganisationCoursesPagesCreator,
  loadOrganisationCoursesBarCreator,
  loadOrganisationCoursesCreator,
  setUserOrganisationPeoplePagesCreator,
} from 'store/actionsCreators';
import {
  CREATE_ORGANISATION,
  LOAD_USER_ORGANISATION,
  UPDATE_USER_ORGANISATION,
  LOAD_USER_ORGANISATION_PEOPLE,
  LOAD_USER_ORGANISATION_PEOPLE_ADD,
  REMOVE_USER_ORGANISATION_PEOPLE,
  ADD_USER_ORGANISATION_PEOPLE_ADD,
  UPDATE_ORGANISATION_PERSON_RESPONSIBLE,
  LOAD_ORGANISATION_DASHBOARD,
  LOAD_ORGANISATION_COURSE_COMPLETION,
  SET_ORGANISATION_COURSE_COMPLETION_USER_STATUS,
  SET_ORGANISATION_COURSE_COMPLETION_SORT,
  LOAD_ORGANISATION_DASHBOARD_COURSES,
  LOAD_ORGANISATION_PROFILE_COURSES,
  LOAD_ORGANISATION_COURSES_BAR,
  LOAD_ORGANISATION_COURSES,
  LOAD_ORGANISATION_COURSE_PUBLISH,
  APPLY_ORGANISATION_COURSE_PUBLISH,
  CANCEL_ORGANISATION_COURSE,
  CHANGE_ORGANISATION_USER_ROLE,
} from 'store/constants';
import { parseBoxesList, parseDate, parseUserList, parseParams } from 'helpers';
import { get, findIndex, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import { parseNestedErrors } from 'helpers/parseErrors';

function* createOrganisation({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(organisationService.create, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'organisations',
          status: 'success',
          text: i18next.t('organisations.createOrganisationSuccess', {
            name: payload.get('name'),
          }),
        }),
      );
      // yield call(reset);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    const { errors } = yield error.response.json();
    const isSystemError = errors.length && !errors[0].path;

    if (!isSystemError) {
      yield put(
        setCreateOrganisationErrorsCreator(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        ),
      );
    } else {
      yield put(
        setNewAlertCreator({
          type: 'organisations',
          status: 'error',
          list: errors,
        }),
      );
    }
    console.log('[GET] create organisation error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadUserOrganisation({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(organisationService.getOne, payload);
    if (response.ok) {
      const {
        results: { result: organization },
      } = yield response.json();

      organization.organizationRoles = organization.organizationRoles
        ? parseUserList(organization.organizationRoles)
        : [];

      yield put(setUserOrganisationCreator(organization));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] user organization error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* updateOrganisation({ id, payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(organisationService.update, id, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'organisations',
          status: 'success',
          text: i18next.t('organisations.createOrganisationSuccess', {
            name: payload.get('name'),
          }),
        }),
      );
    }
    history.push(`/organisation/${id}`);
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    const { errors } = yield error.response.json();
    const isSystemError = errors.length && !errors[0].path;

    if (!isSystemError) {
      yield put(
        setCreateOrganisationErrorsCreator(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        ),
      );
    } else {
      yield put(
        setNewAlertCreator({
          type: 'organisations',
          status: 'error',
          list: errors,
        }),
      );
    }
    console.log('[GET] create organisation error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadUserOrganisationPeople({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'ORGANISATION' }));
    const { page, perPage } = yield select(({ organisation }) => organisation.people);
    const response = yield call(organisationService.getPeople, {
      page,
      maxResult: perPage,
      organizationId: payload,
    });

    if (response.ok) {
      const {
        results: {
          result: { items, totalItems, personResponsible },
        },
      } = yield response.json();

      yield put(setOrganisationPersonResponsibleCreator(personResponsible));
      yield put(setUserOrganisationPeopleCreator(items));
      yield put(setUserOrganisationPeoplePagesCreator(Math.ceil(totalItems / perPage)));
    }
    yield put(removeLoadingCreator({ type: 'ORGANISATION' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'ORGANISATION' }));
    console.log('[GET] user organisation people error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'ORGANISATION' }));
    }
  }
}

function* loadUserOrganisationPeopleAdd({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'ORGANISATION_PEOPLE_ADD' }));
    const response = yield call(organisationService.findPeople, payload);
    if (response.ok) {
      const {
        results: { result: people },
      } = yield response.json();
      yield put(setUserOrganisationPeopleAddCreator(people));
    }
    yield put(removeLoadingCreator({ type: 'ORGANISATION_PEOPLE_ADD' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'ORGANISATION_PEOPLE_ADD' }));
    console.log('[GET] user organisation people add error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* addUserOrganisationPeopleAdd({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(organisationService.addPeople, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'success',
          text: i18next.t('organisations.addPeopleOrganisationSuccess'),
        }),
      );
    }
    history.push(`/organisation/${payload.organizationId}/people`);
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[POST] add user organisation people add error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* removeUserOrganisationPeople({ payload: { userToRemove, organization } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    yield call(organisationService.removePeople, userToRemove, {
      organizationId: organization,
    });
    yield put(loadUserOrganisationPeopleCreator(organization));
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'success',
        text: i18next.t('organisations.removePeopleOrganisationSuccess'),
      }),
    );
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    if (error.response) {
      const { errors } = yield error.response.json();
      console.log(errors);
      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'error',
          list: errors,
        }),
      );
    }
    console.log('[POST] remove user organisation people error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* updateOrganisationPersonResponsible({ payload: { user, organization } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    yield call(organisationService.updatePersonResponsible, user, {
      organizationId: organization,
    });
    yield put(loadUserOrganisationPeopleCreator(organization));
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'success',
        text: i18next.t('global.alert.success'),
      }),
    );
    yield put(loadUserOrganisationCreator(organization));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] update organisation person responsible error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadDashboardCourses() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const userOrganization = yield select(
      ({ user: { user: profile } }) =>
        profile && profile.organizationManagement && profile.organizationManagement.organization,
    );

    if (!userOrganization) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      return;
    }

    const response = yield call(
      organisationService.getOrganisationCoursesSort,
      userOrganization.id,
      {
        'sort-by': 'startDate.asc',
      },
    );

    if (response.ok) {
      const {
        results: {
          result: {
            entity: { courses: items },
          },
        },
      } = yield response.json();

      const courses = parseBoxesList('courses', items);

      yield put(setOrganisationDashboardCoursesCreator(courses));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] organisation dashboard courses error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadProfileCourses({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(organisationService.getOrganisationCoursesSort, payload, {
      'sort-by': 'createdAt.desc',
    });

    if (response.ok) {
      const {
        results: {
          result: {
            entity: { courses: items },
          },
        },
      } = yield response.json();

      const courses = parseBoxesList('courses', items);

      yield put(setOrganisationProfileCoursesCreator(courses));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] organisation profile courses error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadDashboard({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(organisationService.getDashboard, payload);
    if (response.ok) {
      const {
        results: {
          result: { entity: data },
        },
      } = yield response.json();

      const chartCoursesData = get(data, 'coursesChart').reduce((total, current) => {
        if (current.label.split(' ').length === 1) {
          const result = current.label.split(/(?=[A-Z])/).join(' ');
          current.label = result.toLowerCase();
        }
        current.count = parseInt(current.count, 10);
        total.push(current);
        return total;
      }, []);

      const dashboard = {
        name: get(data, 'organization.name') || '',
        phone: get(data, 'organization.telephone') || '',
        email: get(data, 'organization.email') || '',
        website: get(data, 'organization.website') || '',
        photo: get(data, 'organization.photo') || '',
        members: String(get(data, 'organization.membersCount') || 0),
        chartCourses: chartCoursesData || [],
        chartPeople: get(data, 'membersChart') || [],
      };

      yield put(setOrganisationDashboardCreator(dashboard));
      yield put(loadOrganisationDashboardCoursesCreator());
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma dashboard error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* courseCompletion({ payload: id }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const [responseCourse, responseCompletion] = yield all([
      call(listService.getListViewCourses, id),
      call(organisationService.getCourseCompletionUsers, id),
    ]);

    if (responseCourse.ok && responseCompletion.ok) {
      const {
        results: {
          result: { entity: course },
        },
      } = yield responseCourse.json();

      const {
        results: {
          result: {
            entity: { users, pending },
          },
        },
      } = yield responseCompletion.json();

      const usersData = [...users, ...pending].map((user) => ({
        id: user.id,
        status: user.status || null,
        name: [get(user, 'user.firstName') || '', get(user, 'user.lastName') || '']
          .filter((el) => !!el)
          .join(' '),
        email: get(user, 'user.email') || '',
        date: parseDate(user.joinDate) || '',
      }));

      const status = get(course, 'courseState.name') || '';

      const courseData = {
        id: course.id,
        name: course.name,
        canEditUserStatus:
          (status === 'published' || status === 'completed') &&
          (course.endDate ? moment(course.endDate) < moment() : false),
      };

      yield put(
        setOrganisationCourseCompletionCreator({
          course: courseData,
          users: usersData,
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] load course completion error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* courseCompletionUserStatus({ payload: { id, status, name } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'ORGANISATION_COURSE_COMPLETION' }));

    const users = yield select(({ organisation }) => organisation.courseCompletion.users);

    const response = yield call(
      organisationService.setCourseCompletionUserStatus,
      id,
      status === 'completed',
    );

    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'success',
          text: i18next.t('organisations.userCourseCompletionOrganisationSuccess', {
            ':name': name,
          }),
        }),
      );

      const userIndex = findIndex(users, (n) => n.id === id);
      users[userIndex].status = status;

      yield put(setOrganisationCourseCompletionUsersCreator(users));
    }
    yield put(removeLoadingCreator({ type: 'ORGANISATION_COURSE_COMPLETION' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'ORGANISATION_COURSE_COMPLETION' }));
    const { errors } = yield error.response.json();
    let errorMsg = i18next.t('organisations.userCourseCompletionOrganisationError');

    if (error && Array.isArray(errors) && errors.length) {
      const [msg] = errors;
      errorMsg = msg;
    } else if (error && typeof errorMsg === 'string') {
      errorMsg = errors;
    }

    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'error',
        text: errorMsg,
      }),
    );

    console.log('[GET] course completion user status error: ', error);
  } finally {
    if (yield cancelled())
      yield put(removeLoadingCreator({ type: 'ORGANISATION_COURSE_COMPLETION' }));
  }
}

function* courseCompletionSort({ payload: { key, order } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'ORGANISATION_COURSE_COMPLETION' }));

    const users = yield select(({ organisation }) => organisation.courseCompletion.users);

    if (!isEmpty(users)) {
      users.sort((a, b) => {
        const keyA = a[key].toLowerCase();
        const keyB = b[key].toLowerCase();

        if (keyA < keyB) {
          return order === 'asc' ? -1 : 1;
        }
        if (keyA > keyB) {
          return order === 'asc' ? 1 : -1;
        }

        return 0;
      });
      yield put(setOrganisationCourseCompletionUsersCreator(users));
    }

    yield put(removeLoadingCreator({ type: 'ORGANISATION_COURSE_COMPLETION' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'ORGANISATION_COURSE_COMPLETION' }));

    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'error',
        text: i18next.t('global.error'),
      }),
    );

    console.log('[GET] course completion sort error: ', error);
  } finally {
    if (yield cancelled())
      yield put(removeLoadingCreator({ type: 'ORGANISATION_COURSE_COMPLETION' }));
  }
}

function* loadCoursesBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(organisationService.getCoursesBar, payload.organization);
    if (response.ok) {
      const {
        results: {
          result: { entity: bar },
        },
      } = yield response.json();

      yield put(
        setOrganisationCoursesBarCreator({
          courseTemplates: bar.courseTemplates || 0,
          published: bar.published || 0,
          completed: bar.completed || 0,
          drafts: bar.drafts || 0,
          submittedTemplates: bar.submittedTemplates || 0,
          pending: bar.pending || 0,
        }),
      );
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] organisation courses bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function loadCourses(payload) {
  switch (payload.listType) {
    case 'courseTemplates':
      return loadCoursesList(
        'courseTemplates',
        'LOAD_ORGANISATION_COURSES_COURSE_TEMPLATES',
        payload.organization,
      );
    case 'published':
      return loadCoursesList(
        'published',
        'LOAD_ORGANISATION_COURSES_PUBLISHED',
        payload.organization,
      );
    case 'completed':
      return loadCoursesList(
        'completed',
        'LOAD_ORGANISATION_COURSES_COMPLETED',
        payload.organization,
      );
    case 'drafts':
      return loadCoursesList('drafts', 'LOAD_ORGANISATION_COURSES_DRAFTS', payload.organization);
    case 'submittedTemplates':
      return loadCoursesList(
        'submittedTemplates',
        'LOAD_ORGANISATION_COURSES_SUBMITTED_TEMPLATES',
        payload.organization,
      );
    case 'pending':
      return loadCoursesList('pending', 'LOAD_ORGANISATION_COURSES_PENDING', payload.organization);
    default:
      return null;
  }
}

function* loadCoursesList(type, loading, organizationId, apiName = type) {
  try {
    yield put(setNewLoadingCreator({ type: loading }));

    const [page, perPage] = yield select(({ organisation }) => [
      organisation.courses[type].page,
      organisation.courses[type].perPage,
    ]);

    const params = parseParams({
      maxResults: perPage,
      page,
    });

    const response = yield call(organisationService.getCourses, organizationId, apiName, params);

    if (response.ok) {
      const {
        results: {
          result: {
            entity: { items, pageCount },
          },
        },
      } = yield response.json();

      const data = parseBoxesList('courses', items);

      yield put(setOrganisationCoursesCreator(type, data));
      yield put(setOrganisationCoursesPagesCreator(type, pageCount || 1));
      yield put(
        loadOrganisationCoursesBarCreator({ withoutLoader: true, organization: organizationId }),
      );
    }

    yield put(removeLoadingCreator({ type: loading }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: loading }));
    console.log(`[GET] organisation courses ${type} error: `, error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: loading }));
  }
}

function* loadCoursePublish({ payload: id, history, cb }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(listService.getListViewCourses, id);

    if (response.ok) {
      const {
        results: {
          result: { entity: item },
        },
      } = yield response.json();

      const course = {
        id: item.id,
        name: item.name || '',
      };

      const fields = {
        startDate: item.startDate ? new Date(item.startDate) : '',
        endDate: item.endDate ? new Date(item.endDate) : '',
        city: get(item, 'address.city') || '',
        postCode: get(item, 'address.postCode') || '',
        country: get(item, 'address.country.id') || '',
        line1: get(item, 'address.line1') || '',
        line2: get(item, 'address.line2') || '',
        timezone: get(item, 'timezone.id') || '',
      };

      cb(course, fields);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] organisation load course edit error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* applyCoursePublish({ payload, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'APPLY_ORGANISATION_COURSE_PUBLISH' }));

    const { type, course, fields, organizationId } = payload;

    const body = new FormData();

    body.append('timezone', fields.timezone);
    body.append('doesntApply', fields.doesntApply ? 1 : 0);
    body.append('onDemand', fields.onDemand);

    body.append(
      'startDate',
      fields.onDemand ? '' : parseDate(fields.startDate, 'YYYY-MM-DDTHH:mm'),
    );
    body.append('endDate', fields.onDemand ? '' : parseDate(fields.endDate, 'YYYY-MM-DDTHH:mm'));

    if (!fields.doesntApply) {
      body.append('address[line1]', fields.line1);
      body.append('address[line2]', fields.line2);
      body.append('address[city]', fields.city);
      body.append('address[postCode]', fields.postCode);
      body.append('address[country]', fields.country);
    }

    const response = yield call(organisationService.submitEditForm, type, course.id, body);

    if (response.ok) {
      if (type === 'publish') {
        yield put(
          setNewAlertCreator({
            type: 'organisation',
            status: 'success',
            text: i18next
              .t('courses.replace.publishSuccessCourse')
              .replace(':courseName', course.name),
          }),
        );
        history.push(`/organisation/${organizationId}/courses/published`);
      } else {
        yield put(
          setNewAlertCreator({
            type: 'organisation',
            status: 'success',
            text: i18next
              .t('courses.replace.draftSuccessCourse')
              .replace(':courseName', course.name),
          }),
        );
        history.push(`/organisation/${organizationId}/courses/drafts`);
      }
    }
    yield put(removeLoadingCreator({ type: 'APPLY_ORGANISATION_COURSE_PUBLISH' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'APPLY_ORGANISATION_COURSE_PUBLISH' }));
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(parseNestedErrors(errors));
    } else {
      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'error',
          text: i18next.t('global.error'),
        }),
      );
    }

    console.log('[POST] apply organisation publish course form error: ', error);
  } finally {
    if (yield cancelled())
      yield put(removeLoadingCreator({ type: 'APPLY_ORGANISATION_COURSE_PUBLISH' }));
  }
}

function* cancelCourse({ listType, payload: { id } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(organisationService.cancelCourse, id);
    if (response.ok) {
      yield put(loadOrganisationCoursesCreator(listType));

      yield put(
        setNewAlertCreator({
          type: 'organisation',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    yield put(
      setNewAlertCreator({
        type: 'organisation',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );

    console.log('[GET] organisation cancel course error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* changeUserRole({ payload: { user, roles, organization } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'ORGANISATION' }));
    const response = yield call(organisationService.changeUserRole, {
      userId: user,
      organizationRoles: roles,
      organizationId: organization,
    });
    if (response.ok) {
      yield put(loadUserOrganisationPeopleCreator(organization));
    }
    yield put(removeLoadingCreator({ type: 'ORGANISATION' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'organisation',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'ORGANISATION' }));
    console.log('[GET] change organisation user role error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'ORGANISATION' }));
    }
  }
}

export default function* watchOrganisation() {
  yield takeLatest(CREATE_ORGANISATION, createOrganisation);
  yield takeLatest(LOAD_USER_ORGANISATION, loadUserOrganisation);
  yield takeLatest(UPDATE_USER_ORGANISATION, updateOrganisation);
  yield takeLatest(LOAD_USER_ORGANISATION_PEOPLE, loadUserOrganisationPeople);
  yield debounce(1000, LOAD_USER_ORGANISATION_PEOPLE_ADD, loadUserOrganisationPeopleAdd);
  yield takeLatest(ADD_USER_ORGANISATION_PEOPLE_ADD, addUserOrganisationPeopleAdd);
  yield takeLatest(REMOVE_USER_ORGANISATION_PEOPLE, removeUserOrganisationPeople);
  yield takeLatest(UPDATE_ORGANISATION_PERSON_RESPONSIBLE, updateOrganisationPersonResponsible);
  yield takeLatest(LOAD_ORGANISATION_DASHBOARD, loadDashboard);
  yield takeLatest(LOAD_ORGANISATION_DASHBOARD_COURSES, loadDashboardCourses);
  yield takeLatest(LOAD_ORGANISATION_PROFILE_COURSES, loadProfileCourses);
  yield takeLatest(LOAD_ORGANISATION_COURSE_COMPLETION, courseCompletion);
  yield takeLatest(SET_ORGANISATION_COURSE_COMPLETION_USER_STATUS, courseCompletionUserStatus);
  yield takeLatest(SET_ORGANISATION_COURSE_COMPLETION_SORT, courseCompletionSort);
  yield takeLatest(LOAD_ORGANISATION_COURSES_BAR, loadCoursesBar);
  yield takeLatest(LOAD_ORGANISATION_COURSES, loadCourses);
  yield takeLatest(LOAD_ORGANISATION_COURSE_PUBLISH, loadCoursePublish);
  yield takeLatest(APPLY_ORGANISATION_COURSE_PUBLISH, applyCoursePublish);
  yield takeLatest(CANCEL_ORGANISATION_COURSE, cancelCourse);
  yield takeLatest(CHANGE_ORGANISATION_USER_ROLE, changeUserRole);
}
