import styled, { css } from 'styled-components';
import { p } from 'styled-components-spacing';
import { Link } from 'react-router-dom';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  cursor: default;
  justify-content: center;
  box-shadow: 0 0 24px rgba(79, 78, 92, 0.16);
`;

export const Text = styled(Link)`
  display: flex;
  align-items: center;
  cursor: default;
  justify-content: center;
  margin-top: 10px;
`;

export const CouponStyle = styled.a`
  ${({ theme }) => theme && css`
    font-size: 20px;
    color: white;
    background: grey;
    border: solid 1px grey;
    border-radius: 5px;
    padding: 4px;
  `}
`;

export const Thumbnail = styled.div`
  ${({ theme }) => theme && css`
    ${p(16)};

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 135px;
    height: 120px;
    border-right: 1px solid ${theme.colors.gray['200']};
    cursor: default;
    justify-content: center;
  `}
`;

export const Button = styled.a`
  ${({ theme }) => theme && css`
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.font.size.pixel('10')};
    color: black;
    cursor: pointer;
    
    ${breakpoint(theme.navbar.breakpoint)`
      ${css`
        min-height: 40px;
        font-size: ${({ theme }) => theme.font.size.pixel('12')};
      `}
    `}
  `}
`;

export const Content = styled.div`
  ${p(16)};
`;
