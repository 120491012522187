import styled, { css } from 'styled-components';
import { py, px, mx } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme && 
    css`
      ${py(24)};
      ${px(16)};

      margin-left: -${theme.grid.gutter / 2}px;
      margin-right: -${theme.grid.gutter / 2}px;
      background-color: ${theme.colors.gray[100]};
      
      ${breakpoint('md')`
        ${css`
          ${mx(0)}
        `}
      `}
    `
  }
`;