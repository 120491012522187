import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return [
    {
      value: 'VIRTUAL',
      label: t('dictionaries.projectTypeVirtual'),
    },
    {
      value: 'PHYSICAL',
      label: t('dictionaries.projectTypePhysical'),
    },
    {
      value: 'HIBRID',
      label: t('dictionaries.projectTypeHybrid'),
    },
  ];
};
