import PropTypes from 'prop-types';

export const RoutesLinksPropsTypes = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
});

export const RoutePropTypes = PropTypes.shape({
  key: PropTypes.string.isRequired,
  path: [PropTypes.string.isRequired, PropTypes.shape([]).isRequired],
  component: PropTypes.elementType,
  exact: PropTypes.bool,
  withAuth: PropTypes.bool,
  routes: PropTypes.arrayOf(RoutesLinksPropsTypes),
});

export const RoutesActionsPropsTypes = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  bg: PropTypes.string.isRequired,
  appType: PropTypes.string,
});

export const GlobalRoutesPropsTypes = PropTypes.arrayOf(RoutesLinksPropsTypes);
export const UserRoutesPropsTypes = PropTypes.shape({
  links: PropTypes.arrayOf(RoutesLinksPropsTypes),
  actions: PropTypes.arrayOf(RoutesActionsPropsTypes),
  recognisedLinks: PropTypes.arrayOf(RoutesActionsPropsTypes),
  engagedLinks: PropTypes.arrayOf(RoutesActionsPropsTypes),
  improveLinks: PropTypes.arrayOf(RoutesActionsPropsTypes),
  involvedLinks: PropTypes.arrayOf(RoutesActionsPropsTypes),
  aboutLinks: PropTypes.arrayOf(RoutesActionsPropsTypes),
});

export const UserCertificationPropsTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  awardedDate: PropTypes.string,
  validityDate: PropTypes.string,
  ma: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  level: PropTypes.shape({
    name: PropTypes.string,
  }),
});

export const UserPropsTypes = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  dob: PropTypes.string,
  memberAssociation: PropTypes.shape({}),
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }),
  userProfile: PropTypes.shape({}),
});

export const ChooseDataPropsTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

export const UserListItemPropsTypes = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  photo: PropTypes.string,
  uniqueUrl: PropTypes.string,
  url: PropTypes.string,
});

export const UserListPropsTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.arrayOf(UserListItemPropsTypes),
  }),
);

export const ListPropsTypes = PropTypes.shape({
  filters: PropTypes.shape({}),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number,
  pages: PropTypes.number,
  perPage: PropTypes.number,
  sort: PropTypes.string,
});

export const UserPermissions = PropTypes.shape({
  appType: PropTypes.shape({
    user: PropTypes.bool.isRequired,
    organisation: PropTypes.bool.isRequired,
    ma: PropTypes.bool.isRequired,
    ipma: PropTypes.bool.isRequired,
  }),
  roles: PropTypes.arrayOf(PropTypes.string),
});

export const BadgePropsTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const BadgesPropsTypes = PropTypes.shape({
  course: PropTypes.arrayOf(BadgePropsTypes),
  certificate: PropTypes.arrayOf(BadgePropsTypes),
  event: PropTypes.arrayOf(BadgePropsTypes),
});

export const OrganisationProfileTypes = PropTypes.shape({
  name: PropTypes.string,
  country: PropTypes.shape({ id: PropTypes.number }),
  contactPerson: PropTypes.string,
  photo: PropTypes.string,
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    postCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  }),
  telephone: PropTypes.string,
  website: PropTypes.string,
  email: PropTypes.string,
  shortDescription: PropTypes.string,
  fullDescription: PropTypes.string,
  organizationRoles: UserListPropsTypes,
  imageFile: PropTypes.string,
});
