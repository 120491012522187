import React from 'react';
import { Cms, Container, Header, Heading } from 'components';
import { Margin } from 'styled-components-spacing';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.termsOfService',
    url: '',
  },
];

const TermsOfService = () => {
  return (
    <>
      <Header breadcrumbs={breadcrumbs} title="Terms of Service" />
      <Margin vertical={{ xs: 32, md: 48 }}>
        <Container>
          <Cms>
            <p>
              By clicking "<strong>Create account</strong>" on my.ipma platform you are agreeing to
              enter into a legally binding User Agreement with International Project Management
              Association (IPMA). If you do not agree with this terms do&nbsp;<strong>not</strong>
              &nbsp;click "<strong>Create account</strong>" and do not access or otherwise use
              my.ipma platform. If you wish to terminate this User Agreement, at any time you can do
              so by closing your account and no longer accessing or using my.ipma platform.
            </p>

            <p>
              To use my.ipma platform, you agree that: (1) you must be 18 years or older; (2) you
              will only have one my.ipma account, which must be in your real name; (3) you will not
              submit or publish any unlawful or inappropriate materials, (4) you will receive
              monthly IPMA newsletters to provided email address, and (5) you are not already
              restricted by IPMA from using IPMA Products &amp; Services. Creating an account with
              false information is a violation of IPMA terms, including accounts registered on
              behalf of others or persons under the age of 18.
            </p>

            <p>
              While using my.ipma platform, be civil and respectful in every interaction, share
              information that are real and authentic, as well as be professional and keep the
              content professionally relevant and on topic.
            </p>

            <p>
              The content of my.ipma pages, including without limitation, trademarks, service names
              and brands is the property of IPMA, and/or respective third parties. Such properties
              are protected by copyright and other laws. You are not allowed to reproduce, transfer,
              distribute or store any part of the contents without prior written consent from IPMA
              and/or respective third parties.
            </p>

            <Margin bottom="8" top="16">
              <Heading as="h2" size="h3" noLine>
                Browser compatibility
              </Heading>
            </Margin>
            <ul>
              <li>Chrome (Android, iOS, Linux, macOS, and Windows)</li>
              <li>Safari (iOS and macOS)</li>
              <li>Firefox (macOS, Windows)</li>
              <li>Samsung Internet (Android)</li>
            </ul>
            <p>Please, keep your browser up to date.</p>
          </Cms>
        </Container>
      </Margin>
    </>
  );
};

export default TermsOfService;
