import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Title = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.pixel('14')};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-transform: uppercase;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray[400] : theme.colors.primaries.darker};
  margin-left: 8px;
`;

export const Wrapper = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray[400] : theme.colors.primaries.darker};
    pointer-events: none;
  }

  ${({ verticalviewbreakpoint }) =>
    verticalviewbreakpoint &&
    css`
      ${breakpoint(verticalviewbreakpoint)`
        display: inline-block;
        text-align: center;

        svg {
          width: 28px;
          height: 28px;
        }
        
        ${Title} {
          margin-left: 0;
          margin-top: 8px;
        }
      `}
    `}
`;
