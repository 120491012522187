import Ma from 'containers/ma/Ma';
import Dashboard from 'containers/ma/Dashboard/Dashboard';
import People from 'containers/ma/People/People';
import Users from 'containers/ma/People/Users/Users';
import List from 'containers/ma/People/List/List';
import Members from 'containers/ma/People/Members/Members';
import Add from '../containers/ma/Add/Add';
import Profile from '../containers/ma/Profile/Profile';
import Edit from '../containers/ma/Profile/Edit/Edit';
import View from '../containers/ma/Profile/View/View';
import Create from '../containers/ma/MyEvents/Create/Create';
import EventEdit from '../containers/ma/MyEvents/Edit/Edit';
import MyEvents from '../containers/ma/MyEvents/MyEvents';
import Ongoing from '../containers/ma/MyEvents/Ongoing/Ongoing';
import Completed from '../containers/ma/MyEvents/Completed/Completed';
import Cancelled from '../containers/ma/MyEvents/Cancelled/Cancelled';
import Duplicated from '../containers/ma/MyEvents/Duplicated/Duplicated';
import Organisations from '../containers/ma/People/Organisations/Organisations';
import Courses from '../containers/ma/Courses/Courses';
import CoursesUnassigned from '../containers/ma/Courses/Unassigned/Unassigned';
import CoursesAssigned from '../containers/ma/Courses/Assigned/Assigned';
import CoursesAccepted from '../containers/ma/Courses/Accepted/Accepted';
import CoursesCanceled from '../containers/ma/Courses/Canceled/Canceled';
import Reports from '../containers/ma/Reports/Reports';
import AddReport from '../containers/ma/Reports/AddReport/AddReport';
import ReadReport from '../containers/ma/Reports/ReadReport/ReadReport';
import EditReport from '../containers/ma/Reports/EditReport/EditReport';
import YoungCrew from '../containers/ma/People/YoungCrew/YoungCrew';
import CpdRequests from '../containers/ma/CpdRequests/CpdRequests';
import Pending from '../containers/ma/CpdRequests/Pending/Pending';
import Accepted from '../containers/ma/CpdRequests/Accepted/Accepted';
import Rejected from '../containers/ma/CpdRequests/Rejected/Rejected';
import ReviewCpdBatch from '../containers/ma/CpdRequests/ReviewCpdBatch/ReviewCpdBatch';

const MA = 'maBase';
const DASHBOARD = 'maDashboard';
const PEOPLE = 'maPeople';
const USERS_LIST = 'maUsersList';
const ORGANISATIONS_LIST = 'maOrganisationList';
const PEOPLE_LIST = 'maPeopleList';
const PEOPLE_MEMBERS = 'maPeopleMembers';
const PEOPLE_YC = 'maPeopleYc';
const PEOPLE_ADD = 'maPeopleAdd';
const PROFILE = 'maProfile';
const PROFILE_EDIT = 'maProfilEdit';
const PROFILE_VIEW = 'maProfileView';
const MYEVENTS = 'maMyEvents';
const MYEVENTS_ONGOING = 'maMyEventsOngoing';
const MYEVENTS_COMPLETED = 'maMyEventsCompleted';
const MYEVENTS_CANCELLED = 'maMyEventsCancelled';
const MYEVENTS_DUPLICATED = 'maMyEventsDuplicated';
const MYEVENTS_CREATE = 'maMyEventsCreate';
const MYEVENTS_EDIT = 'maMyEventsEdit';
const COURSES = 'maCourses';
const COURSES_UNASSIGNED = 'maCoursesUnassigned';
const COURSES_ASSIGNED = 'maCoursesAssigned';
const COURSES_ACCEPTED = 'maCoursesAccepted';
const COURSES_CANCELED = 'maCoursesCanceled';
const REPORTS = 'maReports';
const ADD_REPORT = 'maAddReport';
const READ_REPORT = 'maReadReport';
const EDIT_REPORT = 'maEditReport';
const CPD_REQUESTS = 'maCpdRequests';
const CPD_REQUESTS_PENDING = 'maCpdRequestsPending';
const CPD_REQUESTS_ACCEPTED = 'maCpdRequestsAccepted';
const CPD_REQUESTS_REJECTED = 'maCpdRequestsRejected';
const REVIEW_CPD_BATCH = 'maReviewCpdBatch';

export default [
  {
    key: MA,
    path: '/ma',
    component: Ma,
    exact: false,
    withAuth: true,
    permissions: {
      appType: 'ma',
    },
    routes: [
      {
        key: DASHBOARD,
        path: '/ma',
        component: Dashboard,
        exact: true,
        withAuth: false,
      },
      {
        key: PEOPLE_ADD,
        path: '/ma/people/add',
        component: Add,
        exact: false,
        withAuth: false,
      },
      {
        key: PEOPLE,
        path: '/ma/people',
        component: People,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: USERS_LIST,
            path: '/ma/people/users',
            component: Users,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['ma_manage_members_ma_user_list'],
            },
          },
          {
            key: ORGANISATIONS_LIST,
            path: '/ma/people/organisations',
            component: Organisations,
            exact: false,
            withAuth: false,
          },
          {
            key: PEOPLE_LIST,
            path: '/ma/people/list',
            component: List,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['ma_manage_members_people'],
            },
          },
          {
            key: PEOPLE_MEMBERS,
            path: '/ma/people/members',
            component: Members,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['ma_manage_members_people'],
            },
          },
          {
            key: PEOPLE_YC,
            path: '/ma/people/youngcrew',
            component: YoungCrew,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['ma_manage_members_people'],
            },
          },
        ],
      },
      {
        key: PROFILE,
        path: '/ma/profile',
        component: Profile,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: PROFILE_EDIT,
            path: '/ma/profile/edit',
            component: Edit,
            exact: false,
            withAuth: false,
            permissions: {
              roles: ['ma_my_ma_edit_ma_profil'],
            },
          },
          {
            key: PROFILE_VIEW,
            path: '/ma/profile/view',
            component: View,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: MYEVENTS,
        path: '/ma/my-events',
        component: MyEvents,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: MYEVENTS_ONGOING,
            path: '/ma/my-events/ongoing',
            component: Ongoing,
            exact: false,
            withAuth: false,
          },
          {
            key: MYEVENTS_COMPLETED,
            path: '/ma/my-events/completed',
            component: Completed,
            exact: false,
            withAuth: false,
          },
          {
            key: MYEVENTS_CANCELLED,
            path: '/ma/my-events/cancelled',
            component: Cancelled,
            exact: false,
            withAuth: false,
          },
          {
            key: MYEVENTS_DUPLICATED,
            path: '/ma/my-events/duplicated',
            component: Duplicated,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: MYEVENTS_CREATE,
        path: '/ma/event/create/:id',
        component: Create,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_my_events_create_event'],
        },
      },
      {
        key: MYEVENTS_CREATE,
        path: '/ma/event/create',
        component: Create,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_my_events_create_event'],
        },
      },
      {
        key: MYEVENTS_EDIT,
        path: '/ma/event/:id/edit',
        component: EventEdit,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_my_events_edit_event'],
        },
      },
      {
        key: COURSES,
        path: '/ma/courses',
        component: Courses,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: COURSES_UNASSIGNED,
            path: '/ma/courses/unassigned',
            component: CoursesUnassigned,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_ASSIGNED,
            path: '/ma/courses/assigned',
            component: CoursesAssigned,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_ACCEPTED,
            path: '/ma/courses/accepted',
            component: CoursesAccepted,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_CANCELED,
            path: '/ma/courses/canceled',
            component: CoursesCanceled,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: REPORTS,
        path: '/ma/reports',
        component: Reports,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_send_members_report'],
        },
      },
      {
        key: ADD_REPORT,
        path: '/ma/add-report',
        component: AddReport,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_send_members_report'],
        },
      },
      {
        key: READ_REPORT,
        path: '/ma/read-report/:id',
        component: ReadReport,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_send_members_report'],
        },
      },
      {
        key: EDIT_REPORT,
        path: '/ma/edit-report/:id',
        component: EditReport,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_send_members_report'],
        },
      },
      {
        key: CPD_REQUESTS,
        path: '/ma/cpd-requests',
        component: CpdRequests,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_manage_cpd'],
        },
        routes: [
          {
            key: CPD_REQUESTS_PENDING,
            path: '/ma/cpd-requests/pending',
            component: Pending,
            exact: false,
            withAuth: false,
          },
          {
            key: CPD_REQUESTS_ACCEPTED,
            path: '/ma/cpd-requests/accepted',
            component: Accepted,
            exact: false,
            withAuth: false,
          },
          {
            key: CPD_REQUESTS_REJECTED,
            path: '/ma/cpd-requests/rejected',
            component: Rejected,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: REVIEW_CPD_BATCH,
        path: '/ma/cpd-request/:id',
        component: ReviewCpdBatch,
        exact: false,
        withAuth: false,
        permissions: {
          roles: ['ma_manage_cpd'],
        },
      },
    ],
  },
];
