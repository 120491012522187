import { takeLatest, call, put, select } from 'redux-saga/effects';

import { login, getUser, socialLogin } from 'api/services/login';
import { getOrganizations } from 'api/services/user';

import {
  setUserCreator,
  setNewAlertCreator,
  checkTokenCreator,
  setNewLoadingCreator,
  removeLoadingCreator,
  loadNotificationsCreator,
  setCheckedTokenCreator,
  setAppTypeCreator,
  setUserPermissionsCreator,
  loadUserPendingBadgesCreator,
  setLoginUserDataCreator,
  setUserMemberAssociationPeople,
  setAvaiableOrganisationsCreator,
} from 'store/actionsCreators';
import { LOGIN_USER, CHECK_TOKEN, SOCIAL_LOGIN, SOCIAL_AUTH } from 'store/constants';
import { defaultUser } from 'store/reducers/user';
import { setUserMemberRegPartner } from '../store/actionsCreators';

function* loginUser({ payload, history, ipmaShopVerify }) {
  try {
    yield put(setLoginUserDataCreator(payload));
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(login, payload);
    const { token } = yield response.json();
    yield window.localStorage.setItem('token', token);
    yield call([window.localStorage, 'removeItem'], 'currentOrganization');
    yield put(checkTokenCreator(history, true, null, ipmaShopVerify));
    yield put(loadUserPendingBadgesCreator());
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    if (error.response) {
      const { message } = yield error.response.json();

      yield put(
        setNewAlertCreator({
          type: 'auth',
          status: 'error',
          text: message,
        }),
      );
      console.error('[GET] login response error: ', message);
    }

    console.error('[GET] login error: ', error);
  }
}

function* checkToken({ history, afterLogin, token, ipmaShopVerify }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    if (token) {
      window.localStorage.setItem('token', token);
    }
    const userResponse = yield call(getUser);
    const userState = yield select((state) => state.user);

    if (userState && userState.organisations && !userState.organisations.length) {
      const organisationsResponse = yield call(getOrganizations);
      const {
        results: { result: organisations },
      } = yield organisationsResponse.json();
      yield put(setAvaiableOrganisationsCreator(organisations));
    }

    const {
      results: { result },
    } = yield userResponse.json();
    const user = result.user ? result.user[0] : {};
    const appTypePermissions = result.appTypePermissions || {};
    const maPermissions = result.userMemberAssociationPermissions || [];
    const ipmaPermissions = result.userIpmaPermissions || [];
    const ycPermissions = result.userYoungCrewPermissions || [];
    const memberAssociationPeople = result.memberAssociationPeople || [];
    const memberRegPartner = result.memberRegPartner || [];
    user.profileRoles = result.profileRoles ? Object.entries(result.profileRoles) : [];
    yield put(loadNotificationsCreator());
    yield put(setCheckedTokenCreator());
    yield put(setUserCreator(user));
    yield put(setUserMemberAssociationPeople(memberAssociationPeople));
    yield put(setUserMemberRegPartner(memberRegPartner));
    yield put(
      setUserPermissionsCreator({
        appType: {
          user: true,
          organisation: appTypePermissions.organisation || false,
          ma: appTypePermissions.memberAssociation || false,
          ipma: appTypePermissions.ipma || false,
          yc: appTypePermissions.youngCrew || false,
        },
        roles: [...maPermissions, ...ipmaPermissions, ...ycPermissions],
      }),
    );
    if (ipmaShopVerify) {
      history.replace('/ipma-shop');
    } else if (afterLogin) {
      history.push('/user');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(setUserCreator(defaultUser));
    yield put(setCheckedTokenCreator());
    yield put(setAppTypeCreator('guest'));
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield call([window.localStorage, 'removeItem'], 'token');

    // history.replace('/auth/login');
    console.error('[GET] check token error: ', error);
  }
}

function* loginSocial({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(socialLogin, payload);
    const url = yield response.json();
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    window.location.href = url;
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    if (error.response) {
      const { message } = yield error.response.json();

      yield put(
        setNewAlertCreator({
          type: 'auth',
          status: 'error',
          text: message,
        }),
      );
      console.error('[GET] social login response error: ', message);
    }

    console.error('[GET] social login error: ', error);
  }
}

function* authSocial({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    if ('errorMessage' in payload) {
      yield put(
        setNewAlertCreator({
          type: 'auth',
          status: 'error',
          text: payload.errorMessage,
        }),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      history.replace('/auth/login');
      console.error('[GET] login response error: ', payload.errorMessage);
    } else {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      const { jwtToken } = payload;
      window.localStorage.setItem('token', jwtToken);
      yield call([window.localStorage, 'removeItem'], 'currentOrganization');
      yield put(
        checkTokenCreator(history, true, jwtToken, window.localStorage.getItem('ipmaShopVerify')),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
      yield put(loadUserPendingBadgesCreator());
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    if (error.response) {
      const { message } = yield error.response.json();

      yield put(
        setNewAlertCreator({
          type: 'auth',
          status: 'error',
          text: message,
        }),
      );
      console.error('[GET] login response error: ', message);
    }
    history.replace('/auth/login');
    console.error('[GET] login error: ', error);
  }
}

export default function* watchLogin() {
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(CHECK_TOKEN, checkToken);
  yield takeLatest(SOCIAL_LOGIN, loginSocial);
  yield takeLatest(SOCIAL_AUTH, authSocial);
}
