import { get, has } from 'lodash';

export const parseToSelectOption = (option) => ({ label: option.name, value: option.id });

export const parsePersonResponsible = (person) => ({
  label: `${person.user.firstName} ${person.user.lastName}`,
  value: person.id,
});

export const parseKeyExperts = (keyExperts) => {
  const entity = Array.isArray(keyExperts) && keyExperts.length ? keyExperts[0] : null;
  if (entity) {
    return {
      keyExpert: entity.user
        ? { label: `${entity.user.firstName} ${entity.user.lastName}`, value: entity.user.id }
        : null,
      keyExpertCv: entity.keyExpertCv,
    };
  }

  return {};
};

export default (draft) => {
  const course = {
    name: draft.name || '',
    mainLanguage: (draft.mainLanguage && parseToSelectOption(draft.mainLanguage)) || null,
    secondaryLanguage:
      (draft.secondaryLanguage && parseToSelectOption(draft.secondaryLanguage)) || null,

    deliveryMode: (draft.deliveryMode && draft.deliveryMode[0] && draft.deliveryMode[0].id) || null,
    type:
      draft.courseType && draft.courseType[0]
        ? {
            label: draft.courseType[0].name,
            value: draft.courseType[0].id,
            slug: draft.courseType[0].slug,
          }
        : null,

    city: get(draft, 'address.city') || '',
    line1: get(draft, 'address.line1') || '',
    line2: get(draft, 'address.line2') || '',
    country: has(draft, 'address.country') ? parseToSelectOption(draft.address.country) : null,
    postCode: get(draft, 'address.postCode') || '',

    telephone: draft.phone || '',
    email: draft.contactEmail || '',
    website: draft.website || '',
    responsiblePerson:
      (draft.responsiblePerson && parsePersonResponsible(draft.responsiblePerson)) || null,

    shortDescription: draft.shortDescription || '',
    fullDescription: draft.description || '',

    instructionFormats:
      (draft.instructionFormat && draft.instructionFormat.map((el) => el.id)) || [],
    customInstructionFormat: draft.customInstructionFormat || '',

    preparationTrainingCourse: !!draft.preparationTrainingCourse,
    perspectiveCompetencies: (draft.competency && draft.competency.map((el) => el.id)) || [],
    peopleCompetencies: (draft.peopleCompetency && draft.peopleCompetency.map((el) => el.id)) || [],
    practiceCompetencies:
      (draft.practiceCompetency && draft.practiceCompetency.map((el) => el.id)) || [],
    customCompetencies:
      draft.customCourseCompetencies && draft.customCourseCompetencies.length > 0
        ? draft.customCourseCompetencies.map((el) => el.name)
        : [''],
    duration: draft.duration || '',

    programe: draft.programmeDescription || '',

    learningOutcomes:
      draft.courseLearningOutcomes && draft.courseLearningOutcomes.length > 0
        ? draft.courseLearningOutcomes.map((el) => el.description)
        : [''],

    materials:
      draft.courseKeyMaterials && draft.courseKeyMaterials.length > 0
        ? draft.courseKeyMaterials.map((el) => el.description)
        : [''],

    legalStatement: draft.courseFile1File,
    accreditation: draft.courseFile2File,

    ...parseKeyExperts(draft.keyExperts),

    selfAssesorName: draft.selfAssesorName || '',
    selfAssesorEmail: draft.selfAssesorEmail || '',
    selfAssesorCvFile: draft.selfAssesorCvFile,
    courseProgrammeStatementFile: draft.courseProgrammeStatementFile,

    cloudTags: (draft.tags && draft.tags.map((el) => el.id)) || [],
  };

  return course;
};
