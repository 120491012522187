import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { globalPropTypes } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Padding, Margin } from 'styled-components-spacing';
import { Tabs, Header, Container, Alerts } from 'components';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.office',
    url: '/ipma/reg-office-hub',
  },
  {
    title: 'navigation.breadcrumbs.ipma.acceptCoursesProgrammes',
    url: '',
  },
];

const initialTabs = [
  {
    title: 'navigation.tabs.ipma.acceptCoursesProgrammes.waitingForAcceptance',
    path: '/ipma/reg-office/courses-programmes/waiting',
    key: 'waitingForAcceptance',
  },
  {
    title: 'navigation.tabs.ipma.acceptCoursesProgrammes.accepted',
    path: '/ipma/reg-office/courses-programmes/accepted',
    key: 'accepted',
  },
  {
    title: 'navigation.tabs.ipma.acceptCoursesProgrammes.rejected',
    path: '/ipma/reg-office/courses-programmes/rejected',
    key: 'canceled',
  },
];

const CoursesProgrammes = ({ routes, bar, userPermissions }) => {
  const { t } = useTranslation();

  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect(
    '/ipma/reg-office/courses-programmes',
    firstTabPath,
  );

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ipma.office.title')} />
      <Tabs items={tabs} />
      <Container>
        <Padding top={{ xs: 38 }} bottom={{ xs: 38 }}>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="ipma" />
          </Margin>
          {renderExactRedirect()}
          {renderSubRoutes()}
        </Padding>
      </Container>
    </>
  );
};

CoursesProgrammes.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  bar: PropTypes.shape({
    waitingForAcceptance: PropTypes.number,
    accepted: PropTypes.number,
    canceled: PropTypes.number,
  }),
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

CoursesProgrammes.defaultProps = {
  routes: [],
  bar: {
    waitingForAcceptance: 0,
    accepted: 0,
    canceled: 0,
  },
};

const mapStateToProps = ({ ipma, user }) => ({
  bar: ipma.regOfficeCourses.bar,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesProgrammes);
