import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';

import { authSocialCreator } from 'store/actionsCreators';

const SocialLogin = ({ authSocial, location, history }) => {
  useEffect(() => {
    (() => {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      authSocial(queryParams, history);
    })();
  }, []);
  return <></>;
};

SocialLogin.propTypes = {
  authSocial: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  authSocial: (payload, history) => dispatch(authSocialCreator(payload, history)),
});

export default connect(null, mapDispatchToProps)(SocialLogin);
