import styled, { css } from 'styled-components';
import { p, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const UploadParticipant = styled.div`
  text-align: center;
  ${px(16)};
  ${py(24)};

  ${breakpoint('md')`
    ${css`
      ${p(42)};
    `}
  `}
`;
