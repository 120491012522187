import styled, { css } from 'styled-components';
import { py, mt } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';
import headerImageMobile from 'assets/images/header_mobile.png';
import headerImageDesktop from 'assets/images/header_desktop.png';

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${py(64)};
      position: relative;
      background: linear-gradient(to right, #4f4e5c, #161616, #4f4e5c);

      > * {
        position: relative;
        z-index: 1;
      }

      &::before {
        content: '';
        width: 100%;
        height: 340px;
        background-image: url(${headerImageDesktop});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right top;
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
        opacity: 0.4;
      }
    `}
`;

export const Content = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${({ big }) =>
        big &&
        css`
          padding-bottom: 120px;
          ${breakpoint('md')`
            ${css`
              ${py(100)}
            `}
            `}
        `}
    `}
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
`;

export const Description = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(16)};
      min-height: 100px;
      color: #fff;
      font-size: ${theme.font.size.pixel('18')};
      font-weight: ${theme.font.weight.medium};
      line-height: 2rem;
      letter-spacing: 0.02rem;
      text-align: center;
    `}
`;
