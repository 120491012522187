import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Router, Switch, Route } from 'react-router-dom';
import { Provider, connect } from 'react-redux';

import { ThemeProvider } from 'styled-components';

import { Footer, Navbar, Newsletter, PrivateRoute, Cookie } from 'components';
import { globalPropTypes } from 'helpers';

import { checkTokenCreator, setAppTypeCreator } from 'store/actionsCreators';
import { useAccessibility, useHistoryAppType, useAuthModal } from 'hooks';

import { isLogin } from 'api';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import theme from '../theme';
import ConnectedLoader, { Loader } from '../components/Loader/Loader';

const Root = ({
  checkToken,
  routes,
  history,
  store,
  user,
  tokenChecked,
  setAppType,
  userPermissions,
}) => {
  useAccessibility();
  useHistoryAppType(history, setAppType, userPermissions);
  const [renderAuthModal] = useAuthModal(history);

  useEffect(() => {
    checkToken(history);
  }, []);

  if ((!user.id && !tokenChecked) || (isLogin() && !userPermissions)) {
    return (
      <ThemeProvider theme={theme}>
        <Loader page />
      </ThemeProvider>
    );
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
          <ConnectedLoader page type="GLOBAL" />
          <Router history={history}>
            <Navbar routes={routes} />
            <Switch>
              {routes.map(
                ({ key, path, exact, withAuth, permissions, component: Component, ...res }) => {
                  const AppRoute = withAuth || permissions ? PrivateRoute : Route;
                  return (
                    <AppRoute
                      key={key}
                      path={path}
                      exact={exact}
                      permissions={permissions}
                      render={(props) => <Component {...{ ...props, ...res }} />}
                    />
                  );
                },
              )}
            </Switch>
            <Newsletter />
            <Footer />
            <Cookie />
            {renderAuthModal()}
          </Router>
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </Provider>
  );
};

Root.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes).isRequired,
  history: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  checkToken: PropTypes.func.isRequired,
  setAppType: PropTypes.func.isRequired,
  user: PropTypes.shape({ id: PropTypes.number }).isRequired,
  tokenChecked: PropTypes.bool.isRequired,
  userPermissions: globalPropTypes.UserPermissions,
};

Root.defaultProps = {
  userPermissions: {},
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  tokenChecked: user.tokenChecked,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  checkToken: (history) => dispatch(checkTokenCreator(history)),
  setAppType: (appType) => dispatch(setAppTypeCreator(appType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
