import React from 'react';
import PropTypes from 'prop-types';
import SliderSlick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { iconList } from 'assets';
import { ReactSVG } from 'react-svg';
import { Container, Heading } from 'components';
import { Slide, Arrow, SlideContent, SlideSubHeading } from './Slider.styled';

const ArrowNext = ({ onClick }) => {
  return (
    <Arrow onClick={onClick} next>
      <ReactSVG src={iconList.arrowRight} />
    </Arrow>
  );
};

ArrowNext.propTypes = {
  onClick: PropTypes.func,
};

ArrowNext.defaultProps = {
  onClick: () => {},
};

const ArrowPrev = ({ onClick }) => {
  return (
    <Arrow onClick={onClick}>
      <ReactSVG src={iconList.arrowLeft} />
    </Arrow>
  );
};

ArrowPrev.propTypes = {
  onClick: PropTypes.func,
};

ArrowPrev.defaultProps = {
  onClick: () => {},
};

const Slider = ({ items }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };

  const renderSlide = (item, index) => {
    return (
      <Slide img={item.photo} key={index}>
        <Container>
          <SlideContent>
            {item.title && (
              <Heading as="h2" size="h1" color="#fff" firstLine>
                {item.title}
              </Heading>
            )}
            {item.subtitle && <SlideSubHeading>{item.subtitle}</SlideSubHeading>}
          </SlideContent>
        </Container>
      </Slide>
    );
  };

  return (
    <SliderSlick {...settings}>
      {items.map((item, index) =>
        item.url ? (
          <a href={item.url} rel="noopener noreferrer" target="_blank" key={index}>
            {renderSlide(item, undefined)}
          </a>
        ) : (
          renderSlide(item, index)
        ),
      )}
    </SliderSlick>
  );
};

Slider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
  ).isRequired,
};

export default Slider;
