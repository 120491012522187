import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Wrapper, Item, ItemLink } from 'components/Navbar/List/List.styled';
import { globalPropTypes } from 'helpers';

const List = ({ items, horizontal, light, closeFn, extra, smallfont }) => {
  return (
    items.length > 0 && (
      <Wrapper horizontal={horizontal}>
        {items.map((item, index) => (
          <Item key={index} horizontal={horizontal}>
            <ItemLink
              as={NavLink}
              to={{ pathname: item.path, state: { authModal: !!item.withAuthModal } }}
              horizontal={horizontal ? 'true' : ''}
              light={light ? 'true' : ''}
              onClick={closeFn}
              exact={item.exact}
              activeClassName="active"
              smallfont={smallfont ? 'true' : ''}
            >
              {item.title}
            </ItemLink>
          </Item>
        ))}
        {extra && extra(Item, ItemLink)}
      </Wrapper>
    )
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(globalPropTypes.RoutesLinksPropsTypes),
  horizontal: PropTypes.bool,
  light: PropTypes.bool,
  smallfont: PropTypes.bool,
  closeFn: PropTypes.func,
  extra: PropTypes.func,
};

List.defaultProps = {
  items: [],
  horizontal: false,
  light: false,
  smallfont: false,
  closeFn: () => {},
  extra: () => {},
};

export default List;
