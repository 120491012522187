import React from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Button } from 'components';
import { Wrapper, Image } from './Badge.styled';

const Badge = ({ title, image, url, claimFn, share, shareUrl }) => {
  return (
    <Wrapper>
      <div className="text-center">
        {url ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Image src={image} alt={title} />
          </a>
        ) : (
          <Image src={image} alt={title} />
        )}
      </div>
      {claimFn ? (
        <Margin top="16">
          <Button onClick={claimFn}>Claim badge</Button>
        </Margin>
      ) : null}

      {!claimFn && share && url ? (
        <Margin top="16">
          <Button as="a" href={shareUrl} target="_blank" rel="noopener noreferrer" bordered>
            Share on Social media
          </Button>
        </Margin>
      ) : null}
    </Wrapper>
  );
};

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
  claimFn: PropTypes.func,
  share: PropTypes.bool,
  shareUrl: PropTypes.string,
};

Badge.defaultProps = {
  url: '',
  shareUrl: '',
  claimFn: () => {},
  share: false,
};

export default Badge;
