import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  BadgeList,
  BoxList,
  Cms,
  Col,
  Container,
  Heading,
  HeadingWrapper,
  PageContent,
  Progress,
  Row,
  UserDetails,
  Alerts,
  ThumbnailCard,
  ThumbnailCardCoupon,
} from 'components';
import { Margin, Padding } from 'styled-components-spacing';
import { globalPropTypes, getDaysBetweenDates, parseUrlTitle } from 'helpers';
import { useTranslation } from 'react-i18next';
import peopleImage from 'assets/images/people.jpg';
import { loadUserDashboardCreator } from 'store/actionsCreators';

const Dashboard = ({ user, certifications, badges, loadDashboard, dashboard }) => {
  useEffect(() => {
    loadDashboard();
  }, []);

  const { t } = useTranslation();
  const { firstName, lastName, email, userProfile, memberAssociation, youngCrew, coupon } = user;
  const { telephone, photo } = userProfile || {};
  const { name: maName, photo: maPhoto, id: maId } = memberAssociation || {};
  const { name: ycName, photo: ycPhoto, id: ycId } = youngCrew || {};
  const { name: couponName } = coupon || {};
  const { courses, events } = dashboard || {};

  const renderEvents = () => (
    <Margin bottom="64" top={{ xl: 24 }}>
      <HeadingWrapper>
        <Heading as="h2" size="h4">
          {t('page.user.dashboard.eventsTitle')}
        </Heading>
        <Link to="/events" className="link uppercase">
          {t('global.seeAll')}
        </Link>
      </HeadingWrapper>
      <BoxList type="event" items={events} alert={t('global.noResultsEvents')} />
    </Margin>
  );

  const renderCourses = () => (
    <>
      <HeadingWrapper>
        <Heading as="h2" size="h4">
          {t('page.user.dashboard.coursesTitle')}
        </Heading>
        <Link to="/training" className="link uppercase">
          {t('global.seeAll')}
        </Link>
      </HeadingWrapper>
      <BoxList type="course" items={courses} alert={t('global.noResultsCourses')} />
    </>
  );

  const renderCertifications = () => (
    <>
      {certifications.map((item, index) => {
        const days = getDaysBetweenDates(new Date(), item.validityDate);
        let message = null;
        let value = 0;

        if (days < 0) {
          message = t('page.user.dashboard.expired');
          value = 100;
        } else {
          const daysTotal = getDaysBetweenDates(item.awardedDate, item.validityDate);
          value = 100 - ((days / daysTotal) * 100).toFixed(0);
        }

        if (days <= 30 && days >= 0) {
          message = t('page.user.dashboard.expireInfo', { days });
        }

        return (
          <Margin bottom="24" key={index}>
            <Progress
              title={item.name}
              start={item.awardedDate}
              end={item.validityDate}
              value={String(value || 0)}
              message={message}
              daysLeft={days}
            />
          </Margin>
        );
      })}
    </>
  );

  const renderBadges = () => {
    const badgesList = Object.keys(badges)
      .map((key) => badges[key].filter((el) => el.type === 'accepted'))
      .flat()
      .slice(0, 6);

    return (
      <Margin bottom="64" top={{ xl: 24 }}>
        <HeadingWrapper>
          <Heading as="h2" size="h4">
            {t('page.user.dashboard.badgesTitle')}
          </Heading>
          <Link to="/user/my-badges" className="link uppercase text-right">
            {t('page.user.dashboard.badgesAction')}
          </Link>
        </HeadingWrapper>
        <BadgeList alert={t('global.noResultsBadges')} items={badgesList} share />
      </Margin>
    );
  };

  return (
    <PageContent>
      <Container>
        <Alerts type="user" />
        <Row valign="center">
          <Col size={maName ? { lg: 2 / 3 } : {}}>
            <UserDetails thumbnail={photo}>
              <Margin bottom="16">
                <Heading as="h1" size="h4">
                  {firstName} {lastName}
                </Heading>
              </Margin>
              <Margin bottom="12">
                <a href={`tel:${telephone}`}>{telephone}</a>
              </Margin>
              <a href={`mailto:${email}`} className="link link--16 word-break">
                {email}
              </a>
              {maName ? (
                <Margin top="24">
                  <Margin bottom="8">
                    <p className="text-14">{t('page.user.dashboard.memberOf')}</p>
                  </Margin>
                  <p className="font-weight-medium text-tertiary">{maName}</p>
                </Margin>
              ) : null}
            </UserDetails>
          </Col>
          <Margin as={Col} size={{ lg: 1 / 3 }} top={{ xs: 32, lg: 0 }}>
            {maName ? (
              <Margin bottom="24">
                <Margin bottom="12">
                  <Heading as="h2" size="h5" left>
                    {t('page.user.dashboard.yourLocalMA')}:
                  </Heading>
                </Margin>
                <ThumbnailCard
                  thumbnail={maPhoto}
                  title={maName}
                  link={`/ipma-associations/${maId}/${parseUrlTitle(maName)}`}
                />
              </Margin>
            ) : null}
            {ycName ? (
              <Margin bottom="24">
                <Margin bottom="12">
                  <Heading as="h2" size="h5" left>
                    {t('page.user.dashboard.yourLocalYC')}:
                  </Heading>
                </Margin>
                <ThumbnailCard
                  thumbnail={ycPhoto}
                  title={ycName}
                  link={`/ipma-young-crews/${ycId}/${parseUrlTitle(ycName)}`}
                />
              </Margin>
            ) : null}
            {couponName ? (
              <Margin bottom="24">
                <Margin bottom="12">
                  <Heading as="h2" size="h5" left>
                    {t('global.pendingCoupon')}
                  </Heading>
                </Margin>
                <ThumbnailCardCoupon
                  coupon={couponName}
                  link="https://shop.ipma.world/product-category/ipma-store/"
                />
              </Margin>
            ) : null}
          </Margin>
        </Row>
      </Container>
      <Margin as={Container} top={{ xs: 32, md: 42 }}>
        <Row>
          <Col size={{ xl: 2 / 3 }}>
            <Margin bottom="64" top={{ xl: 24 }}>
              <HeadingWrapper>
                <Heading as="h2" size="h4">
                  {t('page.user.dashboard.certificationStatus')}
                </Heading>
                <Link to="/user/certification" className="link uppercase text-right">
                  {t('page.user.dashboard.certificationApply')}
                </Link>
              </HeadingWrapper>
              {renderCertifications()}
            </Margin>

            {renderBadges()}
            {renderEvents()}
            {renderCourses()}
          </Col>

          <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
            <div className="border-xl">
              <Padding all={{ xl: 16 }}>
                <Margin bottom="16">
                  <Heading as="h2" size="h4" firstLine>
                    {t('page.user.dashboard.sidebarTitle')}
                  </Heading>
                </Margin>
                <Cms>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('page.user.dashboard.sidebarDescription'),
                    }}
                  />
                </Cms>
              </Padding>
              <img src={peopleImage} alt="people" />
            </div>
          </Margin>
        </Row>
      </Margin>
    </PageContent>
  );
};

Dashboard.propTypes = {
  user: globalPropTypes.UserPropsTypes.isRequired,
  certifications: PropTypes.arrayOf(globalPropTypes.UserCertificationPropsTypes),
  dashboard: PropTypes.shape({
    courses: PropTypes.arrayOf(PropTypes.shape({})),
    events: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  loadDashboard: PropTypes.func.isRequired,
  badges: globalPropTypes.BadgesPropsTypes,
};

Dashboard.defaultProps = {
  certifications: [],
  dashboard: {
    courses: [],
    events: [],
  },
  badges: {},
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  certifications: user.certifications,
  dashboard: user.dashboard,
  badges: user.badges,
});

const mapDispatchToProps = (dispatch) => ({
  loadDashboard: () => dispatch(loadUserDashboardCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
