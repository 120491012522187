import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Content,
  Button,
} from 'containers/app/Notifications/Notification/Notification.styled';

const Notification = ({ id, title, description, removeFn, isRead }) => {
  return (
    <Wrapper>
      <Content>
        <p style={{ marginRight: '12px' }}>{isRead ? title : <strong>{title}</strong>}</p>
        <p className="text-12">- {description}</p>
      </Content>
      <Button
        icon="close"
        rounded
        large="true"
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        onClick={() => removeFn(id)}
      />
    </Wrapper>
  );
};

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  removeFn: PropTypes.func.isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default Notification;
