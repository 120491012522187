import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alerts,
  BadgeList,
  Col,
  Container,
  Faq,
  Header,
  Heading,
  Modal,
  PageContent,
  Row,
} from 'components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  claimUserBadgeCreator,
  loadUserBadgesCreator,
  loadUserPendingBadgesCreator,
  setOpenPendingBadgesCreator,
} from 'store/actionsCreators';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { globalPropTypes } from 'helpers';
import ModalContent, { Action } from 'components/Modal/Modal.styled';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.myBadges',
    url: '/user/my-badges',
  },
];

const MyBadges = ({
  badges,
  loadUserBadges,
  loadPendingBadges,
  openPendingBadgesModal,
  claimUserBadge,
}) => {
  const { t } = useTranslation();
  const [infoModal, setInfoModal] = useState(null);
  const [badgeModal, setBadgeModal] = useState(false);

  useEffect(() => {
    loadUserBadges();
  }, []);

  useEffect(() => {
    let timeout = null;

    if (!window.localStorage.getItem('badgeModal')) {
      window.localStorage.setItem('badgeModal', true);
      timeout = setTimeout(() => {
        setBadgeModal(true);
      }, 6000);
    }

    return () => clearTimeout(timeout);
  }, []);

  const handleClaim = (badge) => {
    claimUserBadge(badge, (res) => {
      setInfoModal({ ...badge, url: res });
    });
  };

  const badgesComponentInfo = [
    {
      label: t('page.user.myBadges.assessorsTitle'),
      items: badges.assessors,
    },
    {
      label: t('page.user.myBadges.awardsTitle'),
      items: badges.awards,
    },
    {
      label: t('page.user.myBadges.certificateTitle'),
      items: badges.certificate,
    },
    {
      label: t('page.user.myBadges.eventTitle'),
      items: badges.event,
    },
    {
      label: t('page.user.myBadges.honoraryFellowTitle'),
      items: badges.honoraryFellow,
    },
    {
      label: t('page.user.myBadges.pebProfessionals'),
      items: badges.pebProfessionals,
    },
    {
      label: t('page.user.myBadges.courseTitle'),
      items: badges.course,
    },
    {
      label: t('page.user.myBadges.kidsTitle'),
      items: badges.kids,
    },
    {
      label: t('page.user.myBadges.sigTitle'),
      items: badges.sig,
    },
    {
      label: t('page.user.myBadges.researchTitle'),
      items: badges.research,
    },
  ];

  const sortBadges = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  const ModalBadge = () => (
    <ModalContent>
      <Row>
        <Col className="text-left">
          <Heading as="h4" noLine>
            Did you know that you can auto-accept IPMA badges on your Credly account?
          </Heading>
          <Margin top={{ xs: 24 }}>
            <p>
              You can accept badges that you receive from IPMA automatically. When accepting your
              first badge, please enable the Auto-Accept feature. This will trigger auto issuing of
              any future badges by IPMA.
              <br />
              <br />
              When auto-accept is enabled, the badge will automatically be accepted and displayed on
              your profile. You will receive the confirmation email. No further action would be
              required unless you wanted to share your badge on social media!
              <br />
              <br />
              See more here:{' '}
              <a
                href="https://support.credly.com/hc/en-us/articles/360021214671-Can-I-automatically-accept-badges-"
                rel="noopener noreferrer"
                target="_blank"
                className="link"
              >
                Can I automatically accept badges?
              </a>
            </p>
          </Margin>
        </Col>
      </Row>
      <Margin top={{ xs: 32 }}>
        <Row>
          <Col size={{ xs: 1 / 2 }}>
            <Action onClick={() => setBadgeModal(false)}>{t('global.closeButton')}</Action>
          </Col>
        </Row>
      </Margin>
    </ModalContent>
  );

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.user.myBadges.title')}
        // renderActions={({ Button: ButtonRender }) => (
        //   <ButtonRender
        //     secondary
        //     onClick={() => {
        //       loadPendingBadges();
        //       openPendingBadgesModal(true);
        //     }}
        //   >
        //     {t('page.user.myBadges.checkPendingBadges')}
        //   </ButtonRender>
        // )}
      />
      <PageContent>
        <Container>
          <Alerts type="myBadges" />

          {badgesComponentInfo.sort(sortBadges).map((item) => (
            <>
              <Margin bottom="24">
                <Heading as="h2" size="h4">
                  {item.label}
                </Heading>
              </Margin>
              <Margin bottom="32">
                <BadgeList
                  alert={t('global.noResultsBadges')}
                  items={item.items}
                  claimFn={handleClaim}
                  share
                />
              </Margin>
            </>
          ))}

          <Modal
            heading={t('page.user.myBadges.modalTitle')}
            description={t('page.user.myBadges.modalDescription')}
            isOpen={!!infoModal}
            customActions={() => (
              <Row>
                <Margin as={Col} bottom={{ xs: 16, md: 0 }} size={{ md: 1 / 2 }}>
                  <Action
                    forwardedAs="a"
                    href={infoModal ? infoModal.url : null}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('page.user.myBadges.credlyButton')}
                  </Action>
                </Margin>
                <Col size={{ md: 1 / 2 }}>
                  <Action bordered onClick={() => setInfoModal(null)}>
                    {t('global.closeButton')}
                  </Action>
                </Col>
              </Row>
            )}
          />

          <Modal customContent={ModalBadge} isOpen={!!badgeModal} />

          <Margin bottom="32">
            <Heading as="h2" size="h4">
              {t('page.user.myBadges.faqTitle')}
            </Heading>
          </Margin>
          <Margin bottom="38">
            <Faq title="General">
              <Faq.Item>
                <Faq.Title>What is a digital badge?</Faq.Title>
                <Faq.Content>
                  A digital badge is a web-enabled version of your certificate that represents your
                  learning or professional development achievement. It can be used in email
                  signatures or digital resumes, and on social media sites such as LinkedIn,
                  Facebook and Twitter, while the digital image contains verified metadata that
                  describes your qualifications and the process required to earn it.
                </Faq.Content>
              </Faq.Item>
              <Faq.Item>
                <Faq.Title>Why is my.IPMA offering digital badges?</Faq.Title>
                <Faq.Content>
                  Representing your skills as a badge gives you a way to share your abilities online
                  in a way that is simple, trusted and can be easily verified in real time. Digital
                  badges provide employers and peers concrete evidence of what you had to do to earn
                  your credential and what you’re now capable of. My.ipma offers digital badges to:
                  <ul>
                    <li>
                      show the world the knowledge and skills you demonstrated to receive a
                      professional certificate from a respected, credible source
                    </li>
                    <li>help you publicise your accomplishments</li>
                    <li>demonstrate your knowledge and skills are up to date.</li>
                  </ul>
                </Faq.Content>
              </Faq.Item>
              <Faq.Item>
                <Faq.Title>Why is IPMA partnering with Credly?</Faq.Title>
                <Faq.Content>
                  Credly is an end-to-end solution for issuing and managing digital credentials.
                  Credly works with credible organizations to provide digital credentials to
                  individuals and has the largest repository of credentialed talent in the world.
                </Faq.Content>
              </Faq.Item>
            </Faq>
          </Margin>
          <Faq title="Claiming a Badge">
            <Faq.Item>
              <Faq.Title>How do I know if I will receive a badge?</Faq.Title>
              <Faq.Content>
                If you participate in IPMA events, registered courses and programmes (for more
                details contact your national IPMA Member Association) IPMA awards or you are
                certified according to IPMA standards, badges that you can claim and share will be
                waiting for you in your my.ipma profile within the „My badges” tab.
              </Faq.Content>
            </Faq.Item>
            <Faq.Item>
              <Faq.Title>How will I be notified about my badge?</Faq.Title>
              <Faq.Content>
                Once you have been issued a badge, you will receive an email from noreply@ipma.world
                that your badge is waiting in your my.ipma profile under the &quot;My badges&quot;
                tab. If you don&#39;t have a personalised IPMA account, create it by following the
                instructions from the email.
              </Faq.Content>
            </Faq.Item>
          </Faq>
        </Container>
      </PageContent>
    </>
  );
};

MyBadges.propTypes = {
  loadUserBadges: PropTypes.func.isRequired,
  claimUserBadge: PropTypes.func.isRequired,
  loadPendingBadges: PropTypes.func.isRequired,
  openPendingBadgesModal: PropTypes.func.isRequired,
  badges: globalPropTypes.BadgesPropsTypes,
};

MyBadges.defaultProps = {
  badges: {},
};

const mapStateToProps = ({ user }) => ({ badges: user.badges });

const mapDispatchToProps = (dispatch) => ({
  loadUserBadges: () => dispatch(loadUserBadgesCreator()),
  claimUserBadge: (payload, cb) => dispatch(claimUserBadgeCreator(payload, cb)),
  loadPendingBadges: () => dispatch(loadUserPendingBadgesCreator()),
  openPendingBadgesModal: (payload) => dispatch(setOpenPendingBadgesCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyBadges);
