import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Table, Row } from 'components';
import { p, px, py } from 'styled-components-spacing';

export const GroupByButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledRow = styled(Table.Row)`
  &:nth-of-type(even) {
    background-color: unset;
  }
  background-color: ${({ isOdd }) => (isOdd ? '#fff' : '#fbfbfb')} !important;

  > td {
    vertical-align: middle;
    font-size: 14px !important;
  }
  > td:first-child {
    font-size: 16px !important;
  }
`;

export const StyledCell = styled(Table.Cell)`
  font-weight: 500 !important;
  color: rgb(79, 78, 92) !important;
  text-align: left !important;

  ${breakpoint('lg')`
        ${css`
          text-align: center !important;
        `}
    `}
`;

export const NameCell = styled(StyledCell)`
  width: max-content !important;
  ${breakpoint('lg')`
        ${css`
          width: 450px !important;
          max-width: 450px !important;
          text-align: left !important;
        `}
    `}
`;

export const SubmitButton = styled.button`
  ${({ theme, active }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    padding: 13px 26px;
    border-radius: 30px;
    background-color: ${theme.colors.primaries.default};
    width: 100%;
    transition: all 0.2s;
    ${!active &&
    css`
      &:hover {
        background-color: ${theme.colors.primaries.darker};
      }
    `}
  `}
`;

export const ModalContentCustom = styled.div`
  text-align: center;
  overflow: hidden;
  ${px(16)};
  ${py(24)};

  ${breakpoint('md')`
    ${css`
      ${p(42)};
    `}
  `}

  p {
    ${({ theme }) =>
      theme &&
      css`
        font-size: ${theme.font.size.pixel('16')};
        line-height: 24px;
      `}
  }
`;

export const ContentWrapper = styled.div`
  margin: auto;
`;

export const StyledContentRow = styled(Row)`
  margin-bottom: 10px;

  > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    gap: 10px;
    line-height: 1.35;
  }

  span {
    margin: 0;
  }
`;

export const StyledContentRowTextArea = styled(StyledContentRow)`
  margin-bottom: 10px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    line-height: 1.35;
  }
`;

export const ActionButtonsFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
