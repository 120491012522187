import styled, { css } from 'styled-components';

export const ListTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
  box-shadow: 0 0 24px 0 rgba(79, 78, 92, 0.16);
`;

export const ListType = styled.div`
  ${({ theme, active }) => css`
    padding: 12px 24px;
    background-color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: 0.2s all;
    cursor: pointer;

    ${!active &&
    css`
      &:hover {
        background-color: #f2bc5f;
        color: #fff;
      }
    `}

    ${active &&
    css`
      background-color: ${theme.colors.secondaries.default};
      color: #f3f3f3;
    `}
  `}
`;
