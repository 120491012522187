import styled from 'styled-components';

export const ConMsg = styled.div`
  min-height: 100px;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 1.2em;
`;
