import styled, { css } from 'styled-components';

export const Wrapper = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
