import styled from 'styled-components';
import { Table, Row } from 'components';

export const StyledRow = styled(Table.Row)`
  &:nth-of-type(even) {
    background-color: unset;
  }
  background-color: ${({ isOdd }) => (isOdd ? '#fff' : '#fbfbfb')} !important;

  > td {
    vertical-align: middle;
    font-size: 14px !important;
  }
  > td:first-child {
    font-size: 16px !important;
  }
`;

export const StyledSecondRow = styled(StyledRow)`
  border-top: 1px solid #d3d3d6;
  > td:first-child {
    font-size: 14px !important;
  }
`;

export const FlexLeft = styled.div`
  display: flex;
  justify-content: left;
`;

export const Actions = styled(Row)`
  margin: 0;

  a {
    vertical-align: top;
  }

  span {
    margin: 0;
    line-height: 17px;
  }
`;

export const GroupByButtons = styled.div`
  display: flex;
  gap: 10px;
`;
