import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Heading,
  PageContent,
  Row,
  UserDetails,
  Chart,
  IconText,
} from 'components';
import { Margin, Padding } from 'styled-components-spacing';
import { getAvatar } from 'helpers';
import { loadYcDashboardCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Dashboard = ({ loadDashboard, dashboard }) => {
  const { t } = useTranslation();
  useEffect(() => {
    loadDashboard();
  }, []);

  const {
    name,
    phone,
    email,
    website,
    photo,
    members,
    chartPeople,
    chartEventsAndTraining,
  } = dashboard;

  return (
    <PageContent>
      <Container>
        <UserDetails thumbnail={getAvatar(photo, 'yc')}>
          {name && (
            <Margin bottom="16">
              <Heading as="h1" size="h4">
                {name}
              </Heading>
            </Margin>
          )}

          {phone && (
            <Margin bottom="12">
              <a href={`tel:${phone}`}>{phone}</a>
            </Margin>
          )}

          {email && (
            <Margin bottom="12">
              <a href={`mailto:${email}`} className="link link--16 word-break">
                {email}
              </a>
            </Margin>
          )}

          {website && (
            <Margin bottom="12">
              <a href={website} className="link link--16 word-break">
                {website}
              </a>
            </Margin>
          )}

          {members && (
            <Margin top="24">
              <strong className="text-tertiary">
                <IconText icon="users" text={`${members} ${t('page.yc.dashboard.members')}`} big />
              </strong>
            </Margin>
          )}
        </UserDetails>
      </Container>
      <Margin as={Container} top={{ xs: 32, md: 42 }}>
        <Row>
          {chartPeople && !!chartPeople.length && (
            <Col size={{ xl: 1 / 2 }}>
              <Margin bottom="24" className="border-xl">
                <Padding all={{ xl: 20 }}>
                  <Chart
                    title={t('page.yc.dashboard.chartPeople')}
                    items={chartPeople}
                    color="#8d7Ca2"
                  />
                </Padding>
              </Margin>
            </Col>
          )}

          {chartEventsAndTraining && !!chartEventsAndTraining.length && (
            <Col size={{ xl: 1 / 2 }}>
              <Margin bottom="24" className="border-xl">
                <Padding all={{ xl: 20 }}>
                  <Chart
                    title={t('page.yc.dashboard.chartEventsAndTraining')}
                    items={chartEventsAndTraining}
                    color="#8d7Ca2"
                  />
                </Padding>
              </Margin>
            </Col>
          )}
        </Row>
      </Margin>
    </PageContent>
  );
};

Dashboard.propTypes = {
  loadDashboard: PropTypes.func.isRequired,
  dashboard: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    photo: PropTypes.string,
    members: PropTypes.string,
    chartPeople: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    chartCertifiedMembers: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    chartEventsAndTraining: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }),
};

Dashboard.defaultProps = {
  dashboard: {
    name: '',
    phone: '',
    email: '',
    website: '',
    photo: '',
    members: '0',
    chartPeople: [],
    chartCertifiedMembers: [],
    chartEventsAndTraining: [],
  },
};

const mapStateToProps = ({ yc: { dashboard } }) => ({
  dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  loadDashboard: () => dispatch(loadYcDashboardCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
