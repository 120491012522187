import urljoin from 'url-join';
import ky from './kyInstance';
import { BASE_PATH, withAuthorizationHeader } from '../index';

export const addToNewsletter = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'newsletter', 'add-member'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const connectAzure = async () => {
  return ky.get(urljoin(BASE_PATH, 'auth', 'connect', 'azure'), {
    headers: withAuthorizationHeader(),
  });
};

export const helpCenterPublic = async () => {
  return ky.get(urljoin(BASE_PATH, 'pdf-booklets', 'public'), {
    headers: withAuthorizationHeader(),
  });
};

export const helpCenterMa = async () => {
  return ky.get(urljoin(BASE_PATH, 'pdf-booklets', 'ma'), {
    headers: withAuthorizationHeader(),
  });
};

export const ssoIpmaShopUri = async () => {
  return ky.get(urljoin(BASE_PATH, 'sso', 'ipma-shop'), {
    headers: withAuthorizationHeader(),
  });
};
