import styled, { css } from 'styled-components';
import { mb, pl, pt, mr, mt, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export default styled.div`
  ${({ theme }) =>
    theme &&
    css`
      display: flex;
      width: 100%;
      flex-direction: column;

      label {
        font-size: ${theme.font.size.pixel('14')};
        font-weight: ${theme.font.weight.semibold};
  
        color: ${theme.colors.gray[600]};
  
        ${mb(8)}
        
        &:hover {
          cursor: pointer;
        }
      }
      
      input,
      textarea {
        ${pl(16)};

        width: 100%;
        height: 48px;
        font-family: ${theme.font.family.primary};
        color: ${theme.colors.gray[500]};
        background-color: ${theme.colors.gray[100]};
        border-radius: 2px;
    
        &, &::placeholder {
          font-size: ${theme.font.size.pixel('16')};
          font-weight: ${theme.font.weight.regular};
          color: ${theme.colors.gray[500]};
        }
    
        &:disabled {
          cursor: not-allowed;
          background-color: ${theme.colors.gray[200]};
        }
    
        &:focus {
          outline: 1px solid ${theme.colors.secondary};
          outline-offset: -1px;
        }
      }
    
      textarea {
        ${pt(16)};

        max-width: 100%;
        min-height: 128px;
        height: auto;
      }
      
      ${({ horizontal }) =>
        horizontal &&
        css`
          flex-direction: column;
          align-items: flex-start;

          label {
            ${mb(8)}
          }

          ${breakpoint('md')`
            ${css`
              flex-direction: row;
              align-items: center;

              label {
                ${mr(64)}
                ${mb(0)}
              }
            `}
          `}
        `}

      ${({ mrAuto }) =>
        mrAuto &&
        css`
          label {
            margin-right: auto;
          }
        `}
      
      ${({ small }) =>
        small &&
        css`
          input {
            height: 40px;
            font-size: ${theme.font.size.pixel('14')};
            font-weight: ${theme.font.weight.light};
          }

          ${Icon} {
            width: 40px;
            height: 40px;
          }
        `}
    `}
`;

export const Description = styled.p`
  ${({ theme, marginTopNone }) =>
    theme &&
    css`
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.regular};
      color: ${theme.colors.gray[500]};
      line-height: 21px;

      ${mt(marginTopNone ? 0 : 8)}
    `}
`;

export const Error = styled(Description)`
  ${({ theme }) =>
    theme &&
    css`
      color: ${theme.colors.primaries.darker};
    `}
`;

export const Required = styled.span`
  ${({ theme }) =>
    theme &&
    css`
      color: ${theme.colors.primaries.darker};
    `}
`;

export const InputWrapper = styled.div`
  display: flex;

  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const Icon = styled.span`
  ${({ theme }) =>
    theme &&
    css`
      ${px(12)};

      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 2px 0 0 2px;
      background-color: ${theme.colors.tertiary};

      > div > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        width: 20px;
        height: 20px;
        fill: ${theme.colors.white};
      }
    `}
`;

export const CountChars = styled.div`
  font-size: 12px;
  margin-top: 4px;
  font-weight: 500;
  letter-spacing: 0.5px;

  ${({ error }) =>
    error &&
    css`
      span:first-child {
        color: red;
        font-weight: 700;
      }
    `}
`;
