import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Alerts,
  ButtonAction,
  Select,
  Modal,
  Container,
  PageContent,
  Pagination,
  Table,
  Spinner,
  FiltersControls,
} from 'components';
import {
  loadIpmaPeopleUsersCreator,
  loadGlobalIpmaRolesCreator,
  setIpmaPeopleUsersSortCreator,
  changeIpmaPeopleUsersRoleCreator,
  deleteIpmaPeopleUserCreator,
  setIpmaPeopleUsersPageCreator,
  setIpmaPeopleUsersPerPageCreator,
} from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Users({
  items,
  page,
  pages,
  perPage,
  sort,
  loadUsers,
  roles,
  loadGlobalIpmaRoles,
  setSort,
  loading,
  changeRole,
  deleteUser,
  setPage,
  setPerPage,
}) {
  const { t } = useTranslation();
  const [removeConfirmModal, setRemoveConfirmModal] = useState(null);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadUsers();
  }, [page, perPage, sort]);

  useEffect(() => {
    if (!roles.length) {
      loadGlobalIpmaRoles();
    }
  }, []);

  const handleSortBy = (name) => () => {
    const [currentSortName, currentSortStatus] = sort || [];
    const sortByNewColumn = currentSortName !== name;

    if (sortByNewColumn) {
      setSort([name, 'desc']);
      return;
    }

    const newSortStatus = currentSortStatus === 'desc' ? 'asc' : 'desc';
    setSort([name, newSortStatus]);
  };

  const handleChangeRole = (userId, oldRoleId) => ({ value }) => {
    changeRole({ user: userId, oldRole: oldRoleId, role: value });
  };

  const handleDeleteUser = (id) => () => {
    deleteUser(id);
    return true;
  };

  const handleChangePerPage = ({ value }) => {
    setPerPage(value);
  };

  const loadRoles = (_roles, rolesArr, currentId) => {
    const filterRoles = rolesArr.filter((role) => role.id !== currentId);
    const list = _roles.filter(({ value }) => {
      const check = filterRoles.filter((role) => role.id === value);
      if (check.length) {
        return false;
      }
      return true;
    });
    if (list.length && rolesArr.length >= 2) {
      list.unshift({
        label: 'None',
        value: 0,
      });
    }
    return list;
  };

  return (
    <>
      <Container>
        <PageContent>
          <Alerts type="ipma" />
          <FiltersControls perPageSelected={perPage} perPageOnChange={handleChangePerPage} />
          <Table tablebreakpoint="lg">
            <Table.Head>
              <Table.Row>
                <Table.Heading sort={handleSortBy('firstLastName')}>
                  {t('page.ipma.peopleUsers.firstAndLastName')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('email')}>
                  {t('page.ipma.peopleUsers.email')}
                </Table.Heading>
                <Table.Heading sort={handleSortBy('role')}>
                  {t('page.ipma.peopleUsers.role')}
                </Table.Heading>
                <Table.Heading />
                <Table.Heading />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <Spinner />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && !items.length ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>{t('global.noResultsFound')}</Table.Cell>
                </Table.Row>
              ) : null}
              {!loading && items.length
                ? items.map((user) => (
                    <Table.Row key={user.id}>
                      <Table.Cell>
                        <Table.Label>{t('page.ipma.peopleUsers.firstAndLastName')}:</Table.Label>
                        <Link to={`/user-profile/${user.uniqueUrl}`}>
                          {`${user.firstName} ${user.lastName}`}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.ipma.peopleUsers.email')}:</Table.Label>
                        {user.email}
                      </Table.Cell>
                      <Table.Cell>
                        <Table.Label>{t('page.ipma.peopleUsers.role')}:</Table.Label>
                        <Select
                          small
                          value={
                            user.ipmaRoles[0]
                              ? { id: user.ipmaRoles[0].id, label: user.ipmaRoles[0].name }
                              : null
                          }
                          onChange={handleChangeRole(
                            user.id,
                            user.ipmaRoles[0] ? user.ipmaRoles[0].id : 0,
                          )}
                          options={loadRoles(
                            roles,
                            user.ipmaRoles,
                            user.ipmaRoles[0] ? user.ipmaRoles[0].id : 0,
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Select
                          small
                          value={
                            user.ipmaRoles[1]
                              ? { id: user.ipmaRoles[1].id, label: user.ipmaRoles[1].name }
                              : null
                          }
                          onChange={handleChangeRole(
                            user.id,
                            user.ipmaRoles[1] ? user.ipmaRoles[1].id : 0,
                          )}
                          options={loadRoles(
                            roles,
                            user.ipmaRoles,
                            user.ipmaRoles[1] ? user.ipmaRoles[1].id : 0,
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <ButtonAction
                          onClick={() => setRemoveConfirmModal(user.id)}
                          title={t('global.removeButton')}
                          icon="closeCircle"
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </PageContent>
      </Container>
      <Modal
        heading={t('page.ipma.peopleUsers.modalRemoveHeading')}
        isOpen={!!removeConfirmModal}
        confirm={() => handleDeleteUser(removeConfirmModal)() && setRemoveConfirmModal(null)}
        cancel={() => setRemoveConfirmModal(null)}
      />
    </>
  );
}

Users.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  sort: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  loadUsers: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }))
    .isRequired,
  loadGlobalIpmaRoles: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  changeRole: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
};

Users.defaultProps = {};

const mapStateToProps = ({ ipma, globals, loading }) => ({
  ...ipma.people.users,
  roles: globals.ipma.roles,
  loading: !!loading.filter((item) => item.type === 'IPMA').length,
});

const mapDispatchToProps = (dispatch) => ({
  loadUsers: () => dispatch(loadIpmaPeopleUsersCreator()),
  loadGlobalIpmaRoles: () => dispatch(loadGlobalIpmaRolesCreator()),
  setSort: (payload) => dispatch(setIpmaPeopleUsersSortCreator(payload)),
  changeRole: (payload) => dispatch(changeIpmaPeopleUsersRoleCreator(payload)),
  deleteUser: (payload) => dispatch(deleteIpmaPeopleUserCreator(payload)),
  setPage: (payload) => dispatch(setIpmaPeopleUsersPageCreator(payload)),
  setPerPage: (payload) => dispatch(setIpmaPeopleUsersPerPageCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
