import {
  SET_SEARCH,
  SET_SEARCH_PAGES,
  SET_SEARCH_PAGE,
  SET_SEARCH_TOTALS,
  SET_SEARCH_PER_PAGE,
} from '../constants';

const initialState = {
  users: {
    items: [],
    page: 1,
    pages: 1,
    perPage: 10,
    total: 0,
  },
  courses: {
    items: [],
    page: 1,
    pages: 1,
    perPage: 10,
    total: 0,
  },
  events: {
    items: [],
    page: 1,
    pages: 1,
    perPage: 10,
    total: 0,
  },
  ma: {
    items: [],
    page: 1,
    pages: 1,
    perPage: 10,
    total: 0,
  },
  organisations: {
    items: [],
    page: 1,
    pages: 1,
    perPage: 10,
    total: 0,
  },
  yc: {
    items: [],
    page: 1,
    pages: 1,
    perPage: 10,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          items: action.payload,
        },
      };
    case SET_SEARCH_PAGES:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          pages: action.payload,
        },
      };
    case SET_SEARCH_PAGE:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          page: action.payload,
        },
      };
    case SET_SEARCH_PER_PAGE:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          perPage: action.payload,
        },
      };
    case SET_SEARCH_TOTALS:
      return {
        ...state,
        users: {
          ...state.users,
          total: action.payload.users || 0,
        },
        courses: {
          ...state.courses,
          total: action.payload.courses || 0,
        },
        events: {
          ...state.events,
          total: action.payload.events || 0,
        },
        ma: {
          ...state.ma,
          total: action.payload.ma || 0,
        },
        organisations: {
          ...state.organisations,
          total: action.payload.organisations || 0,
        },
        yc: {
          ...state.yc,
          total: action.payload.yc || 0,
        },
      };
    default:
      return state;
  }
};
