import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { Header, Tabs, Container, PageContent, Alerts, PrivateComponent } from 'components';
import { Margin } from 'styled-components-spacing';
import { globalPropTypes } from 'helpers';
import { useTranslation } from 'react-i18next';
import { loadYcProjectCreator } from 'store/actionsCreators';

const ProjectRoles = ({ routes, bar, project, userPermissions, loadProject }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const initialTabs = [
    {
      title: 'navigation.tabs.yc.projectRoles.open',
      path: `/yc/project-roles/${id}/open`,
      key: 'open',
    },
    {
      title: 'navigation.tabs.yc.projectRoles.closed',
      path: `/yc/project-roles/${id}/closed`,
      key: 'closed',
    },
    {
      title: 'navigation.tabs.yc.projectRoles.archived',
      path: `/yc/project-roles/${id}/archived`,
      key: 'archived',
    },
  ];

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.yc.projects',
      url: '/yc/projects',
    },
    {
      title: project.name || 'Project',
      url: '',
    },
  ];

  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect(`/yc/project-roles/${id}`, firstTabPath);

  useEffect(() => {
    loadProject(id);
  }, [id, loadProject]);

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={project.name}
        renderActions={({ Button: ButtonRender }) => (
          <PrivateComponent
            permissions={{
              roles: ['yc_project_role_manage'],
            }}
          >
            <ButtonRender secondary as={Link} to={`/yc/project-role/${id}/create`}>
              {t('page.yc.projectRoles.createButton')}
            </ButtonRender>
          </PrivateComponent>
        )}
      />
      <Tabs items={tabs} />
      {renderExactRedirect()}
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="yc" />
          </Margin>
          {renderSubRoutes()}
        </Container>
      </PageContent>
    </>
  );
};

ProjectRoles.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  bar: PropTypes.shape({
    planning: PropTypes.number.isRequired,
    running: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
  }).isRequired,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
  loadProject: PropTypes.func.isRequired,
};

ProjectRoles.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ yc, user }) => ({
  bar: yc.projectRoles.bar,
  project: yc.projects.view,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadProject: (payload, setProject) => dispatch(loadYcProjectCreator(payload, setProject)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRoles);
