import { takeLatest, call, put, cancelled } from 'redux-saga/effects';
import i18next from 'i18next';

import { forgot, reset } from 'api/services/password';

import {
  setNewAlertCreator,
  setNewLoadingCreator,
  removeLoadingCreator,
} from '../store/actionsCreators';
import { FORGOT_PASSWORD, RESET_PASSWORD } from '../store/constants';

function* forgotPassword({ payload, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(forgot, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'auth',
          status: 'success',
          text: i18next.t('auth.resetLinkSuccess'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();

      if (errors.length) {
        setErrors(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'auth',
        status: 'error',
        text: i18next.t('auth.resetLinkError'),
      }),
    );
    console.error('[GET] forgot password error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* resetPassword({ payload, token, setErrors, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(reset, payload, token);
    if (response.ok) {
      history.push('/auth/password/summary');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();

      if (errors.length) {
        setErrors(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.error('[GET] reset password error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

export default function* watchRegistration() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
