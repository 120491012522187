import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { iconList as icons } from 'assets';
import { Container, List, ListItem } from './Pagination.styled';

function Pagination({ currentPage, totalPages, onChange, styled }) {
  const prevDisabled = currentPage <= 1;
  const nextDisabled = currentPage >= totalPages;

  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage = currentPage < totalPages ? currentPage + 1 : null;
  const visiblePages = [prevPage, currentPage, nextPage].filter(Boolean);

  return (
    <Container styled={styled}>
      <List>
        <ListItem disabled={prevDisabled}>
          <button type="button" onClick={() => !prevDisabled && onChange(prevPage || 0)}>
            <ReactSVG src={icons.arrowLeft} />
          </button>
        </ListItem>
        {visiblePages.map((page) => (
          <ListItem active={currentPage === page} key={page}>
            <button type="button" onClick={() => onChange(page)}>
              {page}
            </button>
          </ListItem>
        ))}
        <ListItem disabled={nextDisabled}>
          <button type="button" onClick={() => !nextDisabled && onChange(nextPage || totalPages)}>
            <ReactSVG src={icons.arrowRight} />
          </button>
        </ListItem>
      </List>
    </Container>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
