import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  z-index: ${({ theme }) => theme.zindex.menu};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  height: 100%;
  overflow-y: auto;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.2s ease;
  box-shadow: 0 0 16px rgba(61, 62, 64, 0.16);
  background-color: #fff;

  ${({ open }) => open && css`
    transform: translate3d(0, 0, 0);
  `}
`;

export const Top = styled.div`
  ${({ theme }) => theme && css`
    height: ${theme.navbar.mobileHeight};
    flex: 0 0 ${theme.navbar.mobileHeight};
    display: flex;
    align-items: center;
    padding: 0 ${theme.grid.gutter / 2}px;
  `}
`;

export const Middle = styled.div`
  margin-bottom: auto;
  margin-top: 32px;
  padding: 0 ${({ theme }) => theme.grid.gutter / 2 + 'px'};
`;

export const Bottom = styled.div`
  margin-top: 20px;
`;

export const Divider = styled.div`
  ${({ theme }) => theme && css`
    width: calc(100% + ${theme.grid.gutter}px);
    height: 1px;
    margin: 20px -${theme.grid.gutter / 2}px;
    background-color: #d2d5d9;
  `}
`;
