import React from 'react';
import PropTypes from 'prop-types';
import { getAvatar } from 'helpers';
import { Link } from 'react-router-dom';
import { Wrapper, Avatar, Name, NameLink } from './User.styled';

const User = ({ name, avatar, uniqueUrl, url }) => {
  const renderLink = () => {
    if (url) return <NameLink href={url}>{name}</NameLink>;

    return (
      <NameLink as={Link} to={`/user-profile/${uniqueUrl}`}>
        {name}
      </NameLink>
    );
  };

  return (
    <Wrapper>
      <Avatar style={{ backgroundImage: `url(${getAvatar(avatar, 'profile')})` }} />
      {url || uniqueUrl ? renderLink() : <Name>{name}</Name>}
    </Wrapper>
  );
};

User.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  uniqueUrl: PropTypes.string,
  url: PropTypes.string,
};

User.defaultProps = {
  avatar: '',
  uniqueUrl: '',
  url: '',
};

export default User;
