import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { globalPropTypes } from 'helpers';

import { Header, Tabs } from 'components';
import { loadUserCoursesBarCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.courses',
    url: '/user/courses',
  },
];

const initialTabs = [
  {
    title: 'navigation.tabs.user.courses.completed',
    path: '/user/courses/completed',
    key: 'completed',
  },
  {
    title: 'navigation.tabs.user.courses.upcoming',
    path: '/user/courses/upcoming',
    key: 'upcoming',
  },
  {
    title: 'navigation.tabs.user.courses.ongoing',
    path: '/user/courses/pending',
    key: 'pending',
  },
];

const Courses = ({ routes, loadBar, bar, userPermissions }) => {
  const { t } = useTranslation();

  useEffect(() => {
    loadBar();
  }, []);

  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect('/user/courses', firstTabPath);

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.user.courses.title')}
        renderActions={({ Button: ButtonRender }) => (
          <ButtonRender secondary as={Link} to="/training">
            {t('page.user.courses.titleButton')}
          </ButtonRender>
        )}
      />
      <Tabs items={tabs} />
      {renderExactRedirect()}
      {renderSubRoutes()}
    </>
  );
};

Courses.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  loadBar: PropTypes.func.isRequired,
  bar: PropTypes.shape({
    completed: PropTypes.number,
    upcoming: PropTypes.number,
  }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

Courses.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ user }) => ({
  bar: user.courses.bar,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadBar: () => dispatch(loadUserCoursesBarCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
