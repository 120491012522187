import styled, { css } from 'styled-components';
import { ButtonAction } from 'components';
import breakpoint from 'styled-components-breakpoint';

export const CourseButtonAction = styled(ButtonAction)`
  flex-basis: 0;
  flex-grow: 1;
  text-align: left;
  &:disabled {
    cursor: default;
    > span {
      color: #b5b5b5;
    }
    > div > div > svg {
      fill: #b5b5b5;
    }
  }

  ${breakpoint('xl')`
    ${css`
      ${({ fullWidth }) =>
        fullWidth
          ? css`
              flex-basis: 100%;
              margin-top: 10px;

              > span {
                width: 60%;
              }
            `
          : null}
      text-align: center;
    `}
  `}
`;

export const Flex = styled.div`
  display: inherit;
  align-items: baseline;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  ${breakpoint('xl')`
    ${css`
      flex-direction: row;
      gap: 0;
    `}
  `}
`;
