import React from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing';

const PageContent = ({ children }) => {
  return (
    <Margin top={{ xs: 32, md: 42 }} bottom={{ xs: 42, md: 80 }}>
      {children}
    </Margin>
  );
};

PageContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

PageContent.defaultProps = {
  children: null,
};

export default PageContent;
