import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonIcon } from 'components';

const IpmaShopButton = ({ mobile }) => {
  const { t } = useTranslation();
  return (
    <ButtonIcon
      as={Link}
      to="/ipma-shop"
      target="_blank"
      rel="noreferrer noopener"
      left={mobile ? 'true' : undefined}
      large={mobile ? 'true' : undefined}
      style={{
        width: '100%',
        padding: '4px 12px',
        backgroundColor: '#2B384B',
        letterSpacing: '0.7px',
        fontWeight: 400,
        borderColor: '#2B384B',
        textAlign: 'left',
        borderRadius: 0,
      }}
    >
      {t('global.ipmaShop')}
    </ButtonIcon>
  );
};

IpmaShopButton.propTypes = {
  mobile: PropTypes.bool.isRequired,
};

export default IpmaShopButton;
