import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Alerts,
  Dropdown,
  Container,
  Header,
  PageContent,
  Button,
  Col,
  TagButton,
  Alert,
  Table,
  Spinner,
  ButtonAction,
} from 'components';
import { Margin } from 'styled-components-spacing';
import {
  loadUserCpdListCreator,
  loadCpdCategoriesCreator,
  loadGlobalAvailableCBCreator,
} from 'store/actionsCreators';
import { isLoading } from 'helpers';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import ApplyForCPDModal from './ApplyForCPDModal/ApplyForCPDModal';
import {
  FlexContainer,
  PointsCircle,
  SubTotals,
  Card,
  FullWidth,
  ActionButtonsFlex,
  GroupByButtons,
  StyledRow,
  StyledCell,
  NameCell,
} from './MyCPD.styled';
import Heading from '../../../components/Heading/Heading.styled';
import SendForVerificationModal from './SendForVerificationModal/SendForVerificationModal';
import ExportCpd from './ExportCpd/ExportCpd';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.myCPD',
    url: '/user/my-cpd',
  },
];

const MyCPD = ({
  loadCpds,
  loadCpdCategories,
  loadGlobalAvailableCB,
  points,
  buckets,
  loading,
  categories,
  cb,
}) => {
  const { t } = useTranslation();
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [verificationOpen, setVerificationOpen] = useState(false);
  const [exportCpdOpen, setExportCpdOpen] = useState(false);
  const [groupedBy, setGroupedBy] = useState('all');

  useEffect(() => {
    loadCpdCategories();

    if (isEmpty(buckets)) {
      loadCpds();
    }

    if (!cb.length) {
      loadGlobalAvailableCB();
    }
  }, [loadCpds, loadGlobalAvailableCB, loadCpdCategories]);

  const applyForCpdModal = () => (
    <ApplyForCPDModal
      open={formModalOpen}
      closeFn={() => setFormModalOpen(false)}
      categories={categories}
    />
  );

  const verificationModal = () => (
    <SendForVerificationModal
      open={verificationOpen}
      closeFn={() => setVerificationOpen(false)}
      cb={cb}
    />
  );

  const exportCpdModal = () => (
    <ExportCpd
      open={exportCpdOpen}
      closeFn={() => setExportCpdOpen(false)}
      categories={categories}
    />
  );

  const handleChangeGroupMode = (mode) => {
    setGroupedBy(mode);
  };

  const filterCpdBuckets = (by = 'all') => {
    const bucketsCopy = cloneDeep(buckets);

    switch (by) {
      case 'formal':
        Object.keys(bucketsCopy).forEach((categoryBucket) => {
          if (!bucketsCopy[categoryBucket].isFormal) {
            delete bucketsCopy[categoryBucket];
          }

          if (bucketsCopy[categoryBucket]) {
            bucketsCopy[categoryBucket].list = bucketsCopy[categoryBucket].list.filter(
              (item) => moment(item.dateOfAttendance) >= moment().subtract(5, 'year'),
            );
          }
        });
        break;
      case 'nonFormal':
        Object.keys(bucketsCopy).forEach((categoryBucket) => {
          if (bucketsCopy[categoryBucket].isFormal) {
            delete bucketsCopy[categoryBucket];
          }

          if (bucketsCopy[categoryBucket]) {
            bucketsCopy[categoryBucket].list = bucketsCopy[categoryBucket].list.filter(
              (item) => moment(item.dateOfAttendance) >= moment().subtract(5, 'year'),
            );
          }
        });
        break;
      case 'all':
        Object.keys(bucketsCopy).forEach((categoryBucket) => {
          bucketsCopy[categoryBucket].list = bucketsCopy[categoryBucket].list.filter(
            (item) => moment(item.dateOfAttendance) >= moment().subtract(5, 'year'),
          );
        });
        break;
      case 'expired':
        Object.keys(bucketsCopy).forEach((categoryBucket) => {
          bucketsCopy[categoryBucket].list = bucketsCopy[categoryBucket].list.filter(
            (item) => !(moment(item.dateOfAttendance) >= moment().subtract(5, 'year')),
          );
        });
        break;
      default:
        break;
    }

    return bucketsCopy;
  };

  const renderProof = (item) => {
    switch (item.addType) {
      case 'manual':
        return (
          <ButtonAction
            verticalviewbreakpoint="xl"
            icon="duplicate"
            title={t('page.user.myCPD.proofManual')}
            as="a"
            target="_blank"
            href={item.diploma}
          />
        );
      case 'automatic':
        return (
          <ButtonAction
            verticalviewbreakpoint="xl"
            icon="award"
            title={t('page.user.myCPD.proofAutomatic')}
            as="a"
            target="_blank"
            href={item.issuedBadgeUrl}
          />
        );
      default:
        return <></>;
    }
  };

  const renderTable = (items) => (
    <Table tablebreakpoint="lg">
      <Table.Head>
        <Table.Row>
          <Table.Heading>{t('page.user.myCPD.tableName')}</Table.Heading>
          <Table.Heading style={{ textAlign: 'center' }}>
            {t('page.user.myCPD.tablePoints')}
          </Table.Heading>
          <Table.Heading style={{ textAlign: 'center' }}>
            {t('page.user.myCPD.tableDate')}
          </Table.Heading>
          <Table.Heading style={{ textAlign: 'center' }}>
            {t('page.user.myCPD.tableProof')}
          </Table.Heading>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {loading ? (
          <Table.Row>
            <Table.Cell colSpan={4}>
              <Spinner />
            </Table.Cell>
          </Table.Row>
        ) : null}
        {!loading && !items.length ? (
          <Table.Row>
            <Table.Cell colSpan={4}>
              <Alert type="info">{t('global.noResultsFound')}</Alert>
            </Table.Cell>
          </Table.Row>
        ) : null}
        {!loading && items.length
          ? items.map((item, index) => (
              <>
                <StyledRow key={item.id} isOdd={(index + 1) % 2 !== 0}>
                  <NameCell>
                    <Table.Label>{t('page.user.myCPD.tableName')}:</Table.Label>
                    {item.name}
                  </NameCell>
                  <StyledCell>
                    <Table.Label>{t('page.user.myCPD.tablePoints')}:</Table.Label>
                    {item.manualPoints}
                  </StyledCell>
                  <StyledCell>
                    <Table.Label>{t('page.user.myCPD.tableDate')}:</Table.Label>
                    {moment(item.dateOfAttendance).format('DD/MM/YYYY')}
                  </StyledCell>
                  <StyledCell>
                    <Table.Label>{t('page.user.myCPD.tableProof')}:</Table.Label>
                    {renderProof(item)}
                  </StyledCell>
                </StyledRow>
              </>
            ))
          : null}
      </Table.Body>
    </Table>
  );

  const affix = (isFormal) => (isFormal ? '(formal)' : '(non-formal)');

  const renderCpdBuckets = (by = 'all') => {
    const groupedCpd = filterCpdBuckets(by);
    if (!isEmpty(groupedCpd)) {
      return Object.keys(groupedCpd).map((categoryBucket) => (
        <>
          {!isEmpty(groupedCpd[categoryBucket].list) && (
            <Margin bottom={{ xs: 24 }}>
              <Dropdown
                openOnLoad
                title={
                  <Heading as="h3" size="h4">
                    {`${groupedCpd[categoryBucket].label} ${
                      by === 'all' ? affix(groupedCpd[categoryBucket].isFormal) : ''
                    }`}
                  </Heading>
                }
              >
                {renderTable(groupedCpd[categoryBucket].list)}
              </Dropdown>
            </Margin>
          )}
        </>
      ));
    }
    return (
      <Margin bottom={{ xs: 24 }}>
        <Alert type="info">{t('global.noResultsFound')}</Alert>
      </Margin>
    );
  };

  const renderCpds = () => {
    if (isEmpty(buckets)) {
      return <Alert type="empty">No CPD found</Alert>;
    }
    switch (groupedBy) {
      case 'formal':
        return renderCpdBuckets('formal');
      case 'nonFormal':
        return renderCpdBuckets('nonFormal');
      case 'all':
        return renderCpdBuckets('all');
      case 'expired':
        return renderCpdBuckets('expired');
      default:
        return renderCpdBuckets();
    }
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.user.myCPD.title')}
        renderActions={() => (
          <ActionButtonsFlex>
            <Button onClick={() => setFormModalOpen(true)}>
              <p>{t('page.user.myCPD.requestCPD')}</p>
            </Button>
            <Button onClick={() => setVerificationOpen(true)}>
              <p>{t('page.user.myCPD.sendForVerification')}</p>
            </Button>
            <Button onClick={() => setExportCpdOpen(true)}>
              <p>Export CPD Hours</p>
            </Button>
          </ActionButtonsFlex>
        )}
      />
      <PageContent>
        <Container>
          <Alerts type="myCPD" />
          <FlexContainer>
            <PointsCircle>
              <div>UP TO</div>
              <div>{points.total}</div>
              <div>CPD</div>
            </PointsCircle>
            <SubTotals>
              <FullWidth>
                <Heading as="h2" color="#424242" noLine>
                  Partial sums
                </Heading>
              </FullWidth>
              <Card>
                <div>Formal</div>
                <span>UP TO</span>
                <span>{points.formal}</span>
                <span>HOURS</span>
              </Card>
              <Card>
                <div>Non-formal</div>
                <span>UP TO</span>
                <span>{points.nonFormal}</span>
                <span>HOURS</span>
              </Card>
            </SubTotals>
          </FlexContainer>
          <Col>
            <Margin top="24">
              <GroupByButtons>
                <TagButton
                  active={groupedBy === 'all'}
                  onClick={() => handleChangeGroupMode('all')}
                >
                  All
                </TagButton>
                <TagButton
                  active={groupedBy === 'formal'}
                  onClick={() => handleChangeGroupMode('formal')}
                >
                  Formal
                </TagButton>
                <TagButton
                  active={groupedBy === 'nonFormal'}
                  onClick={() => handleChangeGroupMode('nonFormal')}
                >
                  Non-formal
                </TagButton>
                <TagButton
                  active={groupedBy === 'expired'}
                  onClick={() => handleChangeGroupMode('expired')}
                >
                  Expired
                </TagButton>
              </GroupByButtons>
            </Margin>
          </Col>
          <Col>
            <Margin top="24">{loading ? <Spinner /> : renderCpds()}</Margin>
          </Col>
        </Container>
      </PageContent>
      {applyForCpdModal()}
      {verificationModal()}
      {exportCpdModal()}
    </>
  );
};

MyCPD.propTypes = {
  loadCpds: PropTypes.func.isRequired,
  loadCpdCategories: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  points: PropTypes.objectOf({}),
  categories: PropTypes.objectOf({}),
  buckets: PropTypes.objectOf({}),
  cb: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  loadGlobalAvailableCB: PropTypes.func.isRequired,
};

MyCPD.defaultProps = {
  points: 0,
  buckets: null,
  categories: null,
};

const mapStateToProps = ({ globals, cpd, loading }) => ({
  points: cpd.userCpd.points,
  buckets: cpd.userCpd.buckets,
  categories: cpd.categories,
  loading: isLoading(loading, 'CPD_LIST'),
  cb: globals.availableCB,
});

const mapDispatchToProps = (dispatch) => ({
  loadCpds: () => dispatch(loadUserCpdListCreator()),
  loadCpdCategories: () => dispatch(loadCpdCategoriesCreator()),
  loadGlobalAvailableCB: () => dispatch(loadGlobalAvailableCBCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCPD);
