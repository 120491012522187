import styled, { css } from 'styled-components';
import { mt } from 'styled-components-spacing';

export const Loader = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  ${mt(32)}
`;

export const SameAsContact = styled.div`
  ${({ isSame }) =>
    isSame
      ? css`
          pointer-events: none;
          div > div > div > div > div:first-child {
            background-color: #ededed;
          }
          input {
            background-color: #ededed;
          }
        `
      : null}
`;
