import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH, withAuthorizationHeader } from '..';

export const getDashboard = async () => {
  return ky.get(urljoin(BASE_PATH, 'ipma', 'dashboard'), {
    headers: withAuthorizationHeader(),
  });
};

export const getProfile = async () => {
  return ky.get(urljoin(BASE_PATH, 'ipma', 'profile'), {
    headers: withAuthorizationHeader(),
  });
};

export const updateProfile = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ipma', 'profile'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getProfileUpcomingEvents = async () => {
  return ky.get(urljoin(BASE_PATH, 'event', 'ipma', 'upcoming'), {
    headers: withAuthorizationHeader(),
  });
};

export const getBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'ipma', 'manage', 'menu-bar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getUsers = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ipma', 'manage', 'user', 'list', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const changeUserRole = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'ipma', 'manage', 'user', 'change-role'), {
    headers: withAuthorizationHeader({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(params),
  });
};

export const removeUser = async (id) => {
  return ky.delete(urljoin(BASE_PATH, 'ipma', 'manage', 'user', 'remove-user', id.toString()), {
    headers: withAuthorizationHeader(),
  });
};

export const getAddListUsers = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ipma', 'manage', 'user', 'find', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const addUsers = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'ipma', 'manage', 'user', 'add'), {
    headers: withAuthorizationHeader({ 'Content-Type': 'applcation/json' }),
    body: JSON.stringify(params),
  });
};

export const getEventsBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'event', 'ipma', 'menu-bar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getEvents = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'event', 'ipma', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const createEvent = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'ipma'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getEvent = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'event', 'ipma', String(params)), {
    headers: withAuthorizationHeader(),
  });
};

export const editEvent = async (body, id) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'ipma', 'edit', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const cancelEvent = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'event', 'ipma', String(params), 'cancel'), {
    headers: withAuthorizationHeader(),
  });
};

export const reopenEvent = async (params) => {
  return ky.put(urljoin(BASE_PATH, 'event', 'ipma', String(params), 'reopen'), {
    headers: withAuthorizationHeader(),
  });
};

export const duplicateEvent = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'event', 'ipma', 'duplicate'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getEventParticipants = async (id, params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'event', String(id), 'event-participant', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const uploadEventParticipants = async (id, body) => {
  return ky.post(urljoin(BASE_PATH, 'event', String(id), 'event-participant'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getAwardsList = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'award', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const getAwardParticipants = async (id, params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'award', String(id), 'award-participant', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const uploadAwardParticipants = async (id, body) => {
  return ky.post(urljoin(BASE_PATH, 'award', String(id), 'award-participant'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getRegOfficeCoursesBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'regoffice', 'menu-bar'), {
    headers: withAuthorizationHeader(),
  });
};

export const getRegOfficeCourses = async (type, params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'regoffice', 'courses', type, `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const acceptRegOfficeCourse = async (id, cpd, regNumber) => {
  return ky.post(urljoin(BASE_PATH, 'regoffice', 'course', String(id), 'accept'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify({ cpd, regNumber }),
  });
};

export const rejectRegOfficeCourse = async (id) => {
  return ky.post(urljoin(BASE_PATH, 'regoffice', 'course', String(id), 'cancel'), {
    headers: withAuthorizationHeader(),
  });
};

export const uploadRegOfficeCourseRegLogos = async (id, body) => {
  return ky.post(urljoin(BASE_PATH, 'regoffice', String(id), 'reg-logo'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getUploadedRegOfficeCourseRegLogos = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'regoffice', String(id), 'reg-logo'), {
    headers: withAuthorizationHeader(),
  });
};

export const removeRegOfficeCourseRegLogo = async (courseId, regLogoId) => {
  return ky.delete(
    urljoin(BASE_PATH, 'regoffice', String(courseId), 'reg-logo', String(regLogoId)),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const downloadRegOfficeCourseRegLogo = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'regoffice', 'export-course-reg-logo', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getRegOfficeCourseGoogleLink = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'regoffice', String(id), 'reg-logo', 'google'), {
    headers: withAuthorizationHeader(),
  });
};

export const getRegOfficeStatTable = async () => {
  return ky.get(urljoin(BASE_PATH, 'regoffice', 'stat-table'), {
    headers: withAuthorizationHeader(),
  });
};

export const toggleRegOfficeMaType = async (maId, type, newValue) => {
  return ky.put(urljoin(BASE_PATH, 'regoffice', String(maId), type), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify({ value: newValue }),
  });
};

export const toggleRegOfficeOrganizationFlatRate = async (orgId, newValue) => {
  return ky.put(urljoin(BASE_PATH, 'regoffice', 'organization', String(orgId), 'flatrate'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify({ value: newValue }),
  });
};

export const getIpmaReportList = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return ky.get(urljoin(BASE_PATH, 'ma', 'ipma-report-list', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const setIpmaReportInvoice = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ma', 'ipma-report-list', 'set-invoice'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const archiveIpmaReport = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ma', 'ipma-report-list', 'archive'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const verifyIpmaReport = async (id, kamNotes) => {
  return ky.post(urljoin(BASE_PATH, 'ma', 'verify-report', String(id)), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify({ kamNotes }),
  });
};

export const createProject = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'project', 'new', 'ipma'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const editProject = async (body, id) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'project', 'edit', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getProject = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'ycproject', 'project', 'show', String(params)), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjectsBar = async () => {
  return ky.get(urljoin(BASE_PATH, 'ycproject', 'menu-bar', 'ipma'), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjects = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ycproject', 'list', 'ipma', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const createProjectRole = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'new', 'ipma'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const editProjectRole = async (body, id) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'edit', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getProjectRolesBar = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'menu-bar', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjectRoles = async (params, id) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'ipma', String(id), `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjectRole = async (params) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'ycproject', 'projectrole', 'show', String(params)), {
    headers: withAuthorizationHeader(),
  });
};

export const createProjectRoleApplication = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'ycproject', 'application', 'new'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getProjectRoleApplications = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ycproject', 'application', 'list', 'ipma', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const getProjectRoleApplication = async (params) => {
  return ky.get(
    urljoin(BASE_PATH, 'public', 'ycproject', 'application', 'show', 'ipma', String(params)),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const getProjectRoleApplicationBar = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'ycproject', 'application', 'menu-bar', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const acceptProjectRoleApplication = async (id) => {
  return ky.put(urljoin(BASE_PATH, 'ycproject', 'application', 'accept', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const rejectProjectRoleApplication = async (id) => {
  return ky.put(urljoin(BASE_PATH, 'ycproject', 'application', 'reject', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const putOnHoldProjectRoleApplication = async (id) => {
  return ky.put(urljoin(BASE_PATH, 'ycproject', 'application', 'put-on-hold', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const exportApplications = async (params) => {
  const query = Object.keys(params)
    .filter((key) => params[key] !== null)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'ycproject', 'application', 'export', 'ipma', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const removeProject = async (id) => {
  return ky.delete(urljoin(BASE_PATH, 'ycproject', 'project', 'delete', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const removeProjectRole = async (id) => {
  return ky.delete(urljoin(BASE_PATH, 'ycproject', 'projectrole', 'delete', 'ipma', String(id)), {
    headers: withAuthorizationHeader(),
  });
};
