import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Heading, HeadingWrapper, ShortBox } from 'components';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Wrapper } from 'containers/app/Home/Sidebar/Sidebar.styled';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Sidebar = ({ organisations }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading as="h2" size="h4">
          {t('page.home.organisationsTitle')}
        </Heading>
        <Link
          to={{ pathname: '/organisations', state: { authModal: false } }}
          className="link uppercase"
        >
          {t('global.seeAll')}
        </Link>
      </HeadingWrapper>
      {organisations && organisations.length > 0
        ? organisations.map((item, index) => (
            <ShortBox key={index} thumbnail={item.thumbnail} title={item.title} url={item.url} />
          ))
        : null}

      <Margin top="16">
        <Button as={Link} to={{ pathname: '/organisations', state: { authModal: false } }}>
          {t('page.home.organisationsButton')}
        </Button>
      </Margin>
    </Wrapper>
  );
};

Sidebar.propTypes = {
  organisations: PropTypes.shape({}),
};

Sidebar.defaultProps = {
  organisations: [],
};

export default Sidebar;
