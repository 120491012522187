import NotFound from 'containers/app/NotFound/NotFound';
import Forbidden from 'containers/app/Forbidden/Forbidden';
import Error from 'containers/app/Error/Error';

import appRoutes from './app';
import authRoutes from './auth';
import userRoutes from './user';
import organisationRoutes from './organisation';
import maRoutes from './ma';
import ipma from './ipma';
import yc from './yc';

export const routes = [
  ...appRoutes,
  ...authRoutes,
  ...userRoutes,
  ...organisationRoutes,
  ...maRoutes,
  ...ipma,
  ...yc,
  {
    key: 'error',
    path: '/error',
    component: Error,
    exact: false,
    withAuth: false,
  },
  {
    key: 'forbidden',
    path: '/forbidden',
    component: Forbidden,
    exact: false,
    withAuth: false,
  },
  {
    key: 'notfound',
    path: '*',
    component: NotFound,
    exact: false,
    withAuth: false,
  },
];
