import qs from 'qs';
import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH, withAuthorizationHeader } from '..';

export const create = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'organization', 'new'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getOne = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'organization', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const update = async (id, body) => {
  return ky.post(urljoin(BASE_PATH, 'organization', 'edit', String(id)), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getPeople = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'organization', 'management', 'list-user-organization'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const findPeople = async (params) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  return ky.get(urljoin(BASE_PATH, 'organization', 'management', 'find-user', `?${query}`), {
    headers: withAuthorizationHeader(),
  });
};

export const addPeople = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'organization', 'management', 'add-user'), {
    headers: withAuthorizationHeader(),
    body: JSON.stringify(params),
  });
};

export const removePeople = async (id, organization) => {
  return ky.delete(
    urljoin(
      BASE_PATH,
      'organization',
      'management',
      'list-user-organization',
      'remove',
      String(id),
    ),
    {
      headers: withAuthorizationHeader(),
      searchParams: qs.stringify(organization),
    },
  );
};

export const updatePersonResponsible = async (id, params) => {
  return ky.put(
    urljoin(
      BASE_PATH,
      'organization',
      'management',
      'list-user-organization',
      'change-person-responsible',
      String(id),
    ),
    {
      headers: withAuthorizationHeader(),
      searchParams: qs.stringify(params),
    },
  );
};

export const getOrganisationCoursesSort = async (id, params) => {
  return ky.get(urljoin(BASE_PATH, 'organization', String(id), 'courses'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getDashboard = async (id) => {
  return ky.get(
    urljoin(
      BASE_PATH,
      'organization',
      'dashboard',
      'my-dashboard',
      `?organizationId=${String(id)}`,
    ),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const getCourseCompletionUsers = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'organization', 'show-course-users', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const setCourseCompletionUserStatus = async (id, status) => {
  return ky.get(
    urljoin(
      BASE_PATH,
      'organization',
      'user-to-course-pending',
      status ? 'accept' : 'deny',
      String(id),
    ),
    {
      headers: withAuthorizationHeader(),
    },
  );
};

export const getCoursesBar = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'organization', String(id), 'count', 'courses'), {
    headers: withAuthorizationHeader(),
  });
};

export const getCourses = async (id, type, params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'organization', String(id), 'courses', type), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const submitEditForm = async (type, id, body) => {
  return ky.post(urljoin(BASE_PATH, 'course', String(id), 'edit', type), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const cancelCourse = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'course', String(id), 'cancel'), {
    headers: withAuthorizationHeader(),
  });
};

export const changeUserRole = async (params) => {
  return ky.post(urljoin(BASE_PATH, 'organization', 'management', 'add-user-roles'), {
    headers: withAuthorizationHeader({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(params),
  });
};
