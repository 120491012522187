import { takeLatest, call, put, cancelled } from 'redux-saga/effects';

import { getSliderSlides } from 'api/services/home';

import {
  setNewLoadingCreator,
  removeLoadingCreator,
  setHomeSliderCreator,
} from '../store/actionsCreators';
import { LOAD_HOME_SLIDER } from '../store/constants';

function* loadSliderSlides() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getSliderSlides);
    if (response.ok) {
      const {
        results: {
          result: { entity: slides },
        },
      } = yield response.json();
      yield put(setHomeSliderCreator(slides));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.error('[GET] load home slider slides error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

export default function* watchHome() {
  yield takeLatest(LOAD_HOME_SLIDER, loadSliderSlides);
}
