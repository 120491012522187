import styled, { css } from 'styled-components';
import { p } from 'styled-components-spacing';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  box-shadow: 0 0 24px rgba(79, 78, 92, 0.16);
`;

export const Thumbnail = styled.div`
  ${({ theme }) => theme && css`
    ${p(16)};

    flex: 0 0 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 135px;
    height: 120px;
    border-right: 1px solid ${theme.colors.gray['200']};
    
    img {
      max-height: 100%;
    }
  `}
`;

export const Content = styled.div`
  ${p(16)};
`;
