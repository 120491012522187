import { parseUserListItem } from 'helpers';
import { map } from 'lodash';

const assignPosition = (name) => {
  if (name.toLowerCase().includes('executive director')) {
    return 1;
  }
  if (name.toLowerCase().includes('exbo member')) {
    return 2;
  }
  if (name.toLowerCase() === 'president') {
    return 3;
  }
  return 0;
};

export default (roles) => {
  return Object.entries(roles).map(([key, values]) => ({
    name: key,
    items: map(values, parseUserListItem),
    position: assignPosition(key),
  }));
};
