import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Container } from 'components';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Wrapper, Text } from './Cookie.styled';

const Cookie = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const cookieName = 'dcookie';

  useEffect(() => {
    checkCookie();
  }, []);

  const getCookie = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cookieName}=`);

    return parts.length === 2 ? parts.pop().split(';').shift() : false;
  };

  const setCookie = (value = true, days = 7) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    const expires = `expires=${date.toUTCString()}`;

    document.cookie = `${cookieName}=${value || ''}; ${expires}; path=/`;
    setVisible(false);
  };

  const checkCookie = () => {
    setVisible(!getCookie());
  };

  const handleClick = () => {
    setCookie();
    checkCookie();
  };

  return (
    <Wrapper visible={visible}>
      <Container>
        <Row>
          <Col size={{ lg: 3 / 4 }}>
            <Text>
              A Cookie is a small piece of data (text file) that a website asks your browser to
              store on your device. A Cookie doesn’t harm your device and it cannot be used to
              distribute malware. With Cookies IPMA can improve the user experience, but also use
              them to measure traffic in its online services, to modify content based on browsing
              actions and previous visits, or for advertising purposes. Your browser is likely
              configured to automatically accept Cookies, but you can change the options, as well as
              delete the Cookies in your browser&#39;s settings.
            </Text>
          </Col>
          <Col size={{ lg: 1 / 4 }}>
            <Margin top={{ xs: 12, lg: 0 }}>
              <Button type="button" onClick={handleClick}>
                {t('global.acceptButton')}
              </Button>
            </Margin>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Cookie;
