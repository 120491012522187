import React from 'react';
import PropTypes from 'prop-types';

import StyledRadio, { Input } from './Radio.styled';

function Radio({ selected, disabled, onClick, children }) {
  return (
    <StyledRadio disabled={disabled} onClick={!disabled ? onClick : undefined} type="button">
      <Input selected={selected} />
      {children}
    </StyledRadio>
  );
}

Radio.propTypes = {
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.elementType.isRequired,
};

Radio.defaultProps = {
  disabled: false,
};

export default Radio;
