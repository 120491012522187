import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import View from 'containers/app/YcList/View/View';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.yc',
    url: '/user/my-yc',
  },
];

const MyYc = ({ userYc, history }) => {
  if (userYc && userYc.id) {
    return <View breadcrumbs={breadcrumbs} history={history} />;
  }
  if (!userYc) {
    history.push('/ipma-young-crews');
  }

  return null;
};

MyYc.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
  userYc: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

MyYc.defaultProps = {
  history: {},
  userYc: {},
};

const mapStateToProps = ({
  user: {
    user: { youngCrew },
  },
}) => ({
  userYc: youngCrew,
});

export default connect(mapStateToProps, null)(MyYc);
