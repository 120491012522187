import styled, { css } from 'styled-components';
import { mr, p, px } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const Description = styled.div`
  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.font.size.pixel(16)};
    line-height: 21px;
  `}
`;

export const ContactBox = styled.section`
  ${({ theme }) =>
  theme &&
  css`
    background-color: ${theme.colors.gray[100]};
    margin: 0 -${theme.spacing[16]};
    ${p(16)}

    ${breakpoint('md') && css`
      margin: 0;
    `}
  `}
`;

export const MapBox = styled.div``;
export const Address = styled.div`
  display: flex;
  align-items: baseline;

  ${px(16)}

  ${({ theme }) =>
  theme &&
  css`
    font-size: ${theme.font.size.pixel(14)};
    line-height: 18px;
  
    svg {
      width: 12px;
      height: 14px;
      
      fill: ${theme.colors.gray[500]};

      ${mr(4)}
    }
  `}

`;