import styled, { css } from 'styled-components';
import { p, pl } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';

export const Content = styled.div`
  ${({ theme }) => theme && css`
    ${breakpoint('md')`
      ${css`
        ${p(32)};
        
        border: 1px solid ${theme.colors.gray[200]};
      `}
    `}
  `}
`;

export const ContentPadding = styled.div`
  ${({ theme }) => theme && css`
    ${breakpoint('md')`
      ${css`
        ${pl(32)};
      `}
    `}
  `}
`;

export const SocialWrapper = styled.div`
  ${({ theme }) => theme && css`
    margin-top: 16px;
    display: flex;
    width:100%;
    flex-direction: column;

  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme && css`
    text-align: center;
    
    .user-overview-link {
      margin-top: 16px;
    }

    .user-overview-btn {
      display: none;
    }

    ${breakpoint('sm')`
      ${css`
        display: flex;
      `}
    `}
    
    ${breakpoint('lg')`
      ${css`
        display: block;
        margin-right: ${({ theme }) => `-${theme.grid.gutter}px`};
        
        .user-overview-link {
          display: none;
        }

        .user-overview-btn {
          display: block;
          margin: 20px -16px -16px -16px;
        }
      `}
    `}
  `}
`;
