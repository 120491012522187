import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { iconList } from 'assets';
import { get } from 'lodash';
import {
  Wrapper,
  ContainerDesktop,
  ContainerMobile,
  Dropdown,
  Items,
  Item,
  ItemLink,
} from './Tabs.styled';

const Tabs = ({ items }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const renderItemLink = (item) => (
    <ItemLink as={NavLink} to={item.path} activeClassName="active" onClick={() => setOpen(false)}>
      {t(item.title)}
      {(!!item.count || item.count === 0) && <span>({item.count})</span>}
    </ItemLink>
  );

  const renderItemsMobile = () => {
    const activeItem = items.find(
      (item) => (get(item, 'path.pathname') || item.path) === location.pathname,
    );

    if (!activeItem) {
      return (
        <Items open>
          {items.map((item, index) => (
            <Item key={index}>{renderItemLink(item)}</Item>
          ))}
        </Items>
      );
    }

    return (
      <>
        <Dropdown onClick={() => setOpen(!open)} open={open}>
          {renderItemLink(activeItem)}
          <ReactSVG src={iconList.arrowDown} />
        </Dropdown>
        <Items open={open}>
          {items.map((item, index) =>
            item.path !== activeItem.path ? <Item key={index}>{renderItemLink(item)}</Item> : null,
          )}
        </Items>
      </>
    );
  };

  const renderItemsDesktop = () => (
    <Items>
      {items.map((item, index) => (
        <Item key={index}>{renderItemLink(item)}</Item>
      ))}
    </Items>
  );

  return (
    items.length > 0 && (
      <Wrapper>
        <ContainerMobile>{renderItemsMobile()}</ContainerMobile>
        <ContainerDesktop>{renderItemsDesktop()}</ContainerDesktop>
      </Wrapper>
    )
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })])
        .isRequired,
      count: PropTypes.number,
    }),
  ).isRequired,
};

Tabs.defaultProps = {};

export default Tabs;
