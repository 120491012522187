import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Container, PageContent, Alerts, FormCreator } from 'components';
import { useTranslation } from 'react-i18next';
import { Margin } from 'styled-components-spacing';
import { useFormFields } from 'helpers';
import { useParams } from 'react-router-dom';
import {
  loadGlobalCountriesCreator,
  loadGlobalLanguagesCreator,
  loadGlobalProjectSkillsCreator,
  loadGlobalProjectCareerLevelCreator,
  loadGlobalProjectRolesCreator,
  createIpmaProjectRoleCreator,
  loadIpmaProjectCreator,
} from 'store/actionsCreators';
import { YesNo } from '../../../../dictionaries';

const Create = ({
  project,
  projectCareerLevels,
  projectSkills,
  projectRoles,
  history,
  loadGlobalProjectSkills,
  loadGlobalProjectCareer,
  loadGlobalProjectRoles,
  createProjectRole,
  loadProject,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.yc.projects',
      url: '/ipma/projects',
    },
    {
      title: project.name || 'Project',
      url: `/ipma/project-roles/${id}`,
    },
    {
      title: 'Create role',
      url: '',
    },
  ];

  const [formFields, handleFormFieldChange] = useFormFields({
    name: '',
    type: null,
    occupation: '',
    careerLevel: null,
    ycProjectRoleType: null,
    roleDescription: '',
    responsibilities: '',
    qualifications: '',
    closingInformations: '',
    cvRequired: true,
    letterRequired: true,
    skills: [],
    errors: {},
  });

  const formDetails = {
    'row-basic-information': {
      basicInfoTitle: {
        type: 'heading',
        text: t('page.yc.projectRoles.basicInformationTitle'),
        textSize: 'h5',
      },
    },
    'row-1': {
      name: {
        type: 'input',
        id: 'f-name',
        label: t('page.yc.projectRoles.nameLabel'),
        placeholder: t('page.yc.projectRoles.namePlaceholder'),
        required: true,
      },
    },
    'row-3': {
      occupation: {
        type: 'select',
        id: 'f-occupation',
        label: t('page.yc.projectRoles.occupationLabel'),
        placeholder: t('page.yc.projectRoles.occupationPlaceholder'),
        required: true,
        options: [
          { label: 'Voluntary', value: 'Voluntary' },
          { label: 'Paid', value: 'Paid' },
        ],
      },
    },
    'row-4': {
      careerLevel: {
        type: 'select',
        id: 'f-careerLevel',
        label: t('page.yc.projectRoles.careerLevelLabel'),
        placeholder: t('page.yc.projectRoles.careerLevelPlaceholder'),
        required: true,
        options: projectCareerLevels,
      },
      ycProjectRoleType: {
        type: 'select',
        id: 'f-projectName',
        label: t('page.yc.projectRoles.roleTypeLabel'),
        placeholder: t('page.yc.projectRoles.roleTypePlaceholder'),
        required: true,
        options: projectRoles,
      },
    },
    'row-5': {
      roleDescription: {
        type: 'textarea',
        label: t('page.yc.projectRoles.roleDescriptionLabel'),
        placeholder: t('page.yc.projectRoles.roleDescriptionPlaceholder'),
        required: true,
      },
      responsibilities: {
        type: 'textarea',
        label: t('page.yc.projectRoles.responsibilitiesLabel'),
        placeholder: t('page.yc.projectRoles.responsibilitiesPlaceholder'),
        required: true,
      },
    },
    'row-6': {
      qualifications: {
        type: 'textarea',
        label: t('page.yc.projectRoles.qualifications'),
        placeholder: t('page.yc.projectRoles.qualificationsPlaceholder'),
        required: true,
      },
      closingInformations: {
        type: 'textarea',
        label: t('page.yc.projectRoles.closingInformationsLabel'),
        placeholder: t('page.yc.projectRoles.closingInformationsPlaceholder'),
        required: true,
        size: 1 / 2,
      },
    },
    'row-7': {
      skills: {
        type: 'checkbox',
        label: t('page.yc.projectRoles.skillsLabel'),
        required: true,
        options: projectSkills,
      },
    },
    'row-8': {
      cvRequired: {
        type: 'radio',
        label: t('page.yc.projectRoles.cvRequired'),
        required: true,
        options: YesNo(),
      },
    },
    'row-9': {
      letterRequired: {
        type: 'radio',
        label: t('page.yc.projectRoles.letterRequired'),
        required: true,
        options: YesNo(),
      },
    },
  };

  useEffect(() => {
    if (!projectCareerLevels.length) {
      loadGlobalProjectCareer();
    }
    if (!projectSkills.length) {
      loadGlobalProjectSkills();
    }
    if (!projectRoles.length) {
      loadGlobalProjectRoles();
    }
    if (!project || !Object.keys(project).length) {
      loadProject(id);
    }
  }, []);

  const submit = () => {
    const params = new FormData();

    params.append('ycProject', id);
    params.append('status', 'open');
    params.append('type', 'HYBRID');
    params.append('name', formFields.name || '');
    params.append('sameDateRange', true);
    params.append('occupation', formFields.occupation ? formFields.occupation.value : '');
    params.append('careerLevel', formFields.careerLevel ? formFields.careerLevel.value : '');
    params.append(
      'ycProjectRoleType',
      formFields.ycProjectRoleType ? formFields.ycProjectRoleType.value : '',
    );
    params.append('roleDescription', formFields.roleDescription || '');
    params.append('responsibilities', formFields.responsibilities || '');
    params.append('qualifications', formFields.qualifications || '');
    params.append('closingInformations', formFields.closingInformations || '');
    params.append('cvFileRequired', formFields.cvRequired);
    params.append('letterFileRequired', formFields.letterRequired);
    formFields.skills.forEach((value, index) => params.append(`skills[${index}]`, value));
    createProjectRole(params, history, id, (payload) => {
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: payload,
        },
      });
    });
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.yc.projectRoles.title')} />
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="yc" />
            <FormCreator
              formFields={formFields}
              formDetails={formDetails}
              errors={formFields.errors}
              handleFormFieldChange={handleFormFieldChange}
              onSubmit={submit}
            />
          </Margin>
        </Container>
      </PageContent>
    </>
  );
};

Create.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  projectCareerLevels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  projectSkills: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  projectRoles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  loadGlobalProjectSkills: PropTypes.func.isRequired,
  loadGlobalProjectCareer: PropTypes.func.isRequired,
  loadGlobalProjectRoles: PropTypes.func.isRequired,
  createProjectRole: PropTypes.func.isRequired,
  loadProject: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ipma, globals }) => ({
  project: ipma.projects.view,
  countries: globals.countries,
  languages: globals.languages,
  projectCareerLevels: globals.projectCareerLevels,
  projectSkills: globals.projectSkills,
  projectRoles: globals.projectRoles,
});

const mapDispatchToProps = (dispatch) => ({
  loadGlobalLanguages: () => dispatch(loadGlobalLanguagesCreator()),
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  loadGlobalProjectSkills: () => dispatch(loadGlobalProjectSkillsCreator()),
  loadGlobalProjectCareer: () => dispatch(loadGlobalProjectCareerLevelCreator()),
  loadGlobalProjectRoles: () => dispatch(loadGlobalProjectRolesCreator()),
  loadProject: (id) => dispatch(loadIpmaProjectCreator(id)),
  createProjectRole: (payload, history, id, setErrors) =>
    dispatch(createIpmaProjectRoleCreator(payload, history, id, setErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
