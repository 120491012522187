import React from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Text,
} from 'recharts';
import { Heading } from 'components';
import { Margin } from 'styled-components-spacing';

const CustomizedXAxisTick = ({ x, y, payload, size }) => (
  <Text
    x={x}
    y={y}
    width={296 / size}
    textAnchor="middle"
    verticalAnchor="start"
    style={{
      fontSize: '9px',
      color: '#626466',
    }}
  >
    {payload.value}
  </Text>
);

const CustomizedYAxisTick = ({ x, y, payload }) => (
  <Text
    x={x}
    y={y}
    width={40}
    textAnchor="end"
    verticalAnchor="middle"
    style={{
      fontSize: '9px',
      color: '#626466',
    }}
  >
    {payload.value}
  </Text>
);

const colors = ['#f59c00', '#ec1d24', '#cc8914', '#ab1410', '#4f4e5c'];

const Chart = ({ title, items, vertical, color }) => {
  let colorIndex = 0;

  const getColor = () => {
    if (color) {
      return color;
    }
    if (colorIndex > colors.length - 1) colorIndex = 0;
    colorIndex += 1;
    return colors[colorIndex];
  };

  return (
    <div>
      {title && (
        <Margin bottom="8">
          <Heading as="h3" size="h5" center>
            {title}
          </Heading>
        </Margin>
      )}

      {vertical ? (
        <ResponsiveContainer width="100%" height={items.length * 80}>
          <BarChart
            data={items}
            width="100%"
            margin={{ top: 16, right: 16, bottom: 0, left: 0 }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis
              interval={0}
              allowDecimals={false}
              style={{
                fontSize: '11px',
                color: '#626466',
              }}
              type="number"
            />
            <YAxis
              dataKey="label"
              allowDecimals={false}
              type="category"
              interval={0}
              tick={<CustomizedYAxisTick />}
            />
            <Bar
              dataKey="count"
              maxBarSize={44}
              fill="#8884d8"
              isAnimationActive={false}
              layout="vertical"
            >
              {items.map((entry, index) => {
                return <Cell key={`cell-${index}`} fill={getColor()} />;
              })}
              <LabelList
                dataKey="count"
                position="top"
                style={{
                  fontSize: '12px',
                  color: '#626466',
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height={190}>
          <BarChart data={items} margin={{ top: 16, right: 0, bottom: 8, left: 0 }}>
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis
              dataKey="label"
              allowDecimals={false}
              interval={0}
              height={60}
              tick={<CustomizedXAxisTick size={items.length} />}
            />
            <YAxis
              domain={[0, (dataMax) => Math.ceil(Number(dataMax) * 1.2)]}
              allowDataOverflow
              width={30}
              interval={0}
              allowDecimals={false}
              style={{
                fontSize: '11px',
                color: '#626466',
              }}
            />
            <Bar dataKey="count" maxBarSize={44} fill="#8884d8" isAnimationActive={false}>
              {items.map((entry, index) => {
                return <Cell key={`cell-${index}`} fill={getColor()} />;
              })}
              <LabelList
                dataKey="count"
                position="top"
                style={{
                  fontSize: '12px',
                  color: '#626466',
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

Chart.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  vertical: PropTypes.bool,
  color: PropTypes.string,
};

CustomizedXAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.string,
  }),
  size: PropTypes.number.isRequired,
};

CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.string,
  }),
};

Chart.defaultProps = {
  title: null,
  vertical: false,
  color: null,
};

CustomizedXAxisTick.defaultProps = {
  x: undefined,
  y: undefined,
  payload: {},
};

CustomizedYAxisTick.defaultProps = {
  x: undefined,
  y: undefined,
  payload: {},
};

export default React.memo(Chart);
