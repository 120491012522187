import {
  SET_MA_PEOPLE_BAR,
  SET_MA_PEOPLE_USERS,
  SET_MA_PEOPLE_USERS_SORT,
  SET_MA_PEOPLE_USERS_PAGE,
  SET_MA_PEOPLE_USERS_PER_PAGE,
  SET_MA_PEOPLE_LIST,
  SET_MA_PEOPLE_LIST_PAGE,
  SET_MA_PEOPLE_LIST_PER_PAGE,
  SET_MA_PEOPLE_LIST_SORT,
  SET_MA_PEOPLE_ADD_LIST_USERS,
  SET_MA_PROFILE,
  SET_UPDATE_MA_PROFILE_ERRORS,
  SET_MA_PROFILE_USERS_ROLE,
  SET_MA_DASHBOARD,
  SET_MA_EVENTS,
  SET_MA_EVENTS_BAR,
  SET_MA_EVENTS_PAGE,
  SET_MA_EVENTS_PER_PAGE,
  SET_MA_EVENTS_STATUS,
  SET_MA_PEOPLE_ORGANISATIONS_SORT,
  SET_MA_PEOPLE_ORGANISATIONS,
  SET_MA_PEOPLE_ORGANISATIONS_PAGE,
  SET_MA_PEOPLE_ORGANISATIONS_PER_PAGE,
  SET_MA_PROFILE_UPCOMING_EVENTS,
  SET_MA_COURSES,
  SET_MA_COURSES_PAGES,
  SET_MA_COURSES_PAGE,
  SET_MA_COURSES_PER_PAGE,
  SET_MA_COURSES_BAR,
  SET_MA_ASSESSORS,
  SET_MA_PEOPLE_MEMBERS,
  SET_MA_PEOPLE_MEMBERS_SORT,
  SET_MA_PEOPLE_MEMBERS_PAGE,
  SET_MA_PEOPLE_MEMBERS_PER_PAGE,
  SET_MA_REPORT_ERRORS,
  SET_MA_REPORT_LIST,
  SET_MA_REPORT,
  SET_MA_UPDATE_REPORT,
  SET_MA_PEOPLE_YC,
  SET_MA_PEOPLE_YC_SORT,
  SET_MA_PEOPLE_YC_PAGE,
  SET_MA_PEOPLE_YC_PER_PAGE,
  SET_MA_PEOPLE_LIST_FILTERS,
  SET_MA_PEOPLE_MEMBERS_FILTERS,
  SET_MA_PEOPLE_YC_FILTERS,
  SET_MA_PEOPLE_USERS_FILTERS,
} from '../constants';

const initialState = {
  profile: {
    data: null,
    usersRole: {},
    errors: {},
    upcomingEvents: [],
  },
  people: {
    users: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
      filters: null,
    },
    organisations: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
      filters: null,
    },
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
      filters: null,
      localMemberAssociation: 0,
    },
    members: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
      filters: null,
    },
    yc: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
      filters: null,
    },
    bar: {
      users: 0,
      organisations: 0,
      list: 0,
      members: 0,
      youngcrew: 0,
    },
    add: {
      data: [],
    },
  },
  dashboard: {},
  events: {
    list: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      status: 'ongoing',
    },
    bar: {
      ongoing: 0,
      completed: 0,
      cancelled: 0,
      duplicate: 0,
    },
  },
  courses: {
    unassigned: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    assigned: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    accepted: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    canceled: {
      items: [],
      page: 1,
      pages: 1,
      perPage: 10,
      sort: null,
    },
    bar: {
      unassigned: 0,
      assigned: 0,
      accepted: 0,
      canceled: 0,
    },
  },
  reports: [],
  report: {},
  reportName: {},
  updateReport: {},
  updateReportName: {},
  reportErrors: {},
  assessors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MA_PROFILE:
      return { ...state, profile: { ...state.profile, data: action.payload } };
    case SET_MA_PROFILE_USERS_ROLE:
      return { ...state, profile: { ...state.profile, usersRole: action.payload } };
    case SET_UPDATE_MA_PROFILE_ERRORS:
      return { ...state, profile: { ...state.profile, errors: action.payload } };
    case SET_MA_PROFILE_UPCOMING_EVENTS:
      return { ...state, profile: { ...state.profile, upcomingEvents: action.payload } };
    case SET_MA_PEOPLE_BAR:
      return { ...state, people: { ...state.people, bar: action.payload } };
    case SET_MA_PEOPLE_USERS:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, ...action.payload } },
      };
    case SET_MA_PEOPLE_USERS_SORT:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, sort: action.payload } },
      };
    case SET_MA_PEOPLE_USERS_PAGE:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, page: action.payload } },
      };
    case SET_MA_PEOPLE_USERS_PER_PAGE:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, perPage: action.payload } },
      };
    case SET_MA_PEOPLE_USERS_FILTERS:
      return {
        ...state,
        people: { ...state.people, users: { ...state.people.users, filters: action.payload } },
      };
    case SET_MA_PEOPLE_LIST:
      return {
        ...state,
        people: { ...state.people, list: { ...state.people.list, ...action.payload } },
      };
    case SET_MA_PEOPLE_LIST_SORT:
      return {
        ...state,
        people: { ...state.people, list: { ...state.people.list, sort: action.payload } },
      };
    case SET_MA_PEOPLE_LIST_PAGE:
      return {
        ...state,
        people: { ...state.people, list: { ...state.people.list, page: action.payload } },
      };
    case SET_MA_PEOPLE_LIST_PER_PAGE:
      return {
        ...state,
        people: { ...state.people, list: { ...state.people.list, perPage: action.payload } },
      };
    case SET_MA_PEOPLE_LIST_FILTERS:
      return {
        ...state,
        people: { ...state.people, list: { ...state.people.list, filters: action.payload } },
      };
    case SET_MA_PEOPLE_ADD_LIST_USERS:
      return {
        ...state,
        people: { ...state.people, add: { ...state.people.add, data: action.payload } },
      };
    case SET_MA_PEOPLE_MEMBERS:
      return {
        ...state,
        people: { ...state.people, members: { ...state.people.members, ...action.payload } },
      };
    case SET_MA_PEOPLE_MEMBERS_SORT:
      return {
        ...state,
        people: { ...state.people, members: { ...state.people.members, sort: action.payload } },
      };
    case SET_MA_PEOPLE_MEMBERS_PAGE:
      return {
        ...state,
        people: { ...state.people, members: { ...state.people.members, page: action.payload } },
      };
    case SET_MA_PEOPLE_MEMBERS_PER_PAGE:
      return {
        ...state,
        people: { ...state.people, members: { ...state.people.members, perPage: action.payload } },
      };
    case SET_MA_PEOPLE_MEMBERS_FILTERS:
      return {
        ...state,
        people: { ...state.people, members: { ...state.people.members, filters: action.payload } },
      };
    case SET_MA_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case SET_MA_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          list: {
            ...state.events.list,
            ...action.payload,
          },
        },
      };
    case SET_MA_EVENTS_BAR:
      return {
        ...state,
        events: {
          ...state.events,
          bar: action.payload,
        },
      };
    case SET_MA_EVENTS_PAGE:
      return {
        ...state,
        events: { ...state.events, list: { ...state.events.list, page: action.payload } },
      };
    case SET_MA_EVENTS_PER_PAGE:
      return {
        ...state,
        events: { ...state.events, list: { ...state.events.list, perPage: action.payload } },
      };
    case SET_MA_EVENTS_STATUS:
      return {
        ...state,
        events: {
          ...state.events,
          list: {
            ...state.events.list,
            page: state.events.list.status !== action.payload ? 1 : state.events.list.page,
            status: action.payload,
          },
        },
      };
    case SET_MA_PEOPLE_ORGANISATIONS:
      return {
        ...state,
        people: {
          ...state.people,
          organisations: { ...state.people.organisations, ...action.payload },
        },
      };
    case SET_MA_PEOPLE_ORGANISATIONS_SORT:
      return {
        ...state,
        people: {
          ...state.people,
          organisations: { ...state.people.organisations, sort: action.payload },
        },
      };
    case SET_MA_PEOPLE_ORGANISATIONS_PAGE:
      return {
        ...state,
        people: {
          ...state.people,
          organisations: { ...state.people.organisations, page: action.payload },
        },
      };
    case SET_MA_PEOPLE_ORGANISATIONS_PER_PAGE:
      return {
        ...state,
        people: {
          ...state.people,
          organisations: { ...state.people.organisations, perPage: action.payload },
        },
      };
    case SET_MA_COURSES:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            items: action.payload,
          },
        },
      };
    case SET_MA_COURSES_PAGES:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            pages: action.payload,
          },
        },
      };
    case SET_MA_COURSES_PAGE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            page: action.payload,
          },
        },
      };
    case SET_MA_COURSES_PER_PAGE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.listType]: {
            ...state.courses[action.listType],
            perPage: action.payload,
          },
        },
      };
    case SET_MA_COURSES_BAR:
      return {
        ...state,
        courses: {
          ...state.courses,
          bar: action.payload,
        },
      };
    case SET_MA_ASSESSORS:
      return {
        ...state,
        assessors: action.payload,
      };
    case SET_MA_REPORT_ERRORS:
      return { ...state, reportErrors: action.payload };
    case SET_MA_REPORT_LIST:
      return {
        ...state,
        reports: action.payload,
      };
    case SET_MA_REPORT:
      return {
        ...state,
        report: action.payload.report,
        reportName: action.payload.name,
      };
    case SET_MA_UPDATE_REPORT:
      return {
        ...state,
        updateReport: action.payload.report,
        updateReportName: action.payload.name,
      };
    case SET_MA_PEOPLE_YC:
      return {
        ...state,
        people: { ...state.people, yc: { ...state.people.yc, ...action.payload } },
      };
    case SET_MA_PEOPLE_YC_SORT:
      return {
        ...state,
        people: { ...state.people, yc: { ...state.people.yc, sort: action.payload } },
      };
    case SET_MA_PEOPLE_YC_PAGE:
      return {
        ...state,
        people: { ...state.people, yc: { ...state.people.yc, page: action.payload } },
      };
    case SET_MA_PEOPLE_YC_PER_PAGE:
      return {
        ...state,
        people: { ...state.people, yc: { ...state.people.yc, perPage: action.payload } },
      };
    case SET_MA_PEOPLE_YC_FILTERS:
      return {
        ...state,
        people: { ...state.people, yc: { ...state.people.yc, filters: action.payload } },
      };
    default:
      return state;
  }
};
