import styled, { css } from 'styled-components';
import { mt } from 'styled-components-spacing';

export const Count = styled.p`
  ${({ theme }) =>
  theme &&
  css`
      font-size: ${theme.font.size.pixel('14')};
      font-weight: ${theme.font.weight.regular};
      color: ${theme.colors.gray['400']};
      line-height: 21px;
      text-align: right;

      ${mt(4)}
    `}
`;
