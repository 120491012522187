import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-shrink: 0;
`;

export const Text = styled.div`
  margin-left: 10px;
  font-size: 16px;
  padding: 0px;
  cursor: pointer;
  color: black;
  font-weight: 500;
  text-align: center;
  font-family: Montserrat;

  ${css`
  @media (max-width: 1600px) {
    font-size: 12px
  }`}
`;
