import qs from 'qs';
import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH, withAuthorizationHeader } from '..';

export const getListCourses = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'course', 'courses-list'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getListViewCourses = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'course', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getListEvents = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'event', 'events-list'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getListViewEvents = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'event', 'show', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getListMa = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'member-association', 'list'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getListViewMa = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'member-association', 'show', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getListOrganisations = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'organization', 'organizations-list'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getListViewOrganisations = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'organization', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getListYc = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'yc', 'list'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getListViewYc = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'yc', 'show', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getListProjects = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'ycproject', 'search'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};

export const getListViewProjects = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'ycproject', 'project', 'show', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const getListProjectsPositions = async (params = {}) => {
  return ky.get(urljoin(BASE_PATH, 'public', 'ycprojectrole', 'search'), {
    headers: withAuthorizationHeader(),
    searchParams: qs.stringify(params),
  });
};
