import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { Margin } from 'styled-components-spacing';

import { Row, Col, Heading } from 'components/';
import Container, { Action } from './Modal.styled';

ReactModal.setAppElement('#modals');

function Modal({
  customContent,
  customActions,
  heading,
  description,
  confirm,
  cancel,
  autoWidth,
  customWidth,
  overflowType,
  ...res
}) {
  const { t } = useTranslation();
  const renderContent = () => (
    <Container>
      <Row>
        <Col size={{ xs: 1 / 1 }}>
          <Heading as="h4" noLine>
            {heading}
          </Heading>
          <Margin top={{ xs: 24 }}>
            <p>{description}</p>
          </Margin>
        </Col>
      </Row>
      <Margin top={{ xs: 32 }}>
        {!customActions ? (
          <Row>
            <Col size={{ xs: 1 / 2 }}>
              <Action onClick={confirm}>{t('global.confirmButton')}</Action>
            </Col>
            <Col size={{ xs: 1 / 2 }}>
              <Action onClick={cancel} bordered>
                {t('global.cancelButton')}
              </Action>
            </Col>
          </Row>
        ) : (
          customActions(Action)
        )}
      </Margin>
    </Container>
  );

  const Content = customContent || renderContent;

  const pickWidth = () => {
    let width = '100%';
    if (customWidth) {
      width = customWidth;
    } else if (autoWidth) {
      width = 'fit-content';
    }
    return width;
  };

  const pickMaxWidth = () => {
    let width = '';
    if (typeof autoWidth === 'boolean') {
      if (autoWidth) {
        width = 1300;
      } else {
        width = 600;
      }
    }
    if (typeof autoWidth === 'string') {
      width = autoWidth;
    }
    return width;
  };

  return (
    <ReactModal
      style={{
        content: {
          maxWidth: pickMaxWidth(),
          maxHeight: '100%',
          width: pickWidth(),
          top: '50%',
          bottom: 'initial',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          border: 'none',
          boxShadow: '0px 0px 48px 0px rgba(61,62,64,0.24)',
          overflow: overflowType || 'auto',
        },
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          zIndex: 40,
        },
      }}
      {...res}
    >
      <Content />
    </ReactModal>
  );
}

Modal.propTypes = {
  customContent: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
  heading: PropTypes.string,
  description: PropTypes.string,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  customActions: PropTypes.func,
  autoWidth: PropTypes.bool,
  customWidth: PropTypes.number,
  overflowType: PropTypes.string,
};

Modal.defaultProps = {
  customContent: null,
  heading: null,
  description: null,
  confirm: () => {},
  cancel: () => {},
  customActions: undefined,
  autoWidth: false,
  customWidth: null,
  overflowType: null,
};

export default Modal;
