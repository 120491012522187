import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Modal, Row, Heading, FormCreator, Spinner, Button, Col } from 'components';
import { createUserApplyForCpdCreator } from 'store/actionsCreators';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormFields, isLoading, getFileValue, parseDate } from 'helpers';
import { SubmitButton, Success, ModalContentCustom } from './ApplyForCPDModal.styled';
import { isValidFileFormat, isValidFileSize, isValidUrl, findNestedObj } from '../../../../helpers';

const ModalForm = ({ setSent, sendCPD, groupedCategories }) => {
  const { t } = useTranslation();

  const validateError = useRef(false);
  const validateErrorsMsg = useRef({});
  const [formFields, handleFormFieldChange] = useFormFields({
    cpdName: '',
    linkToAgenda: '',
    dateOfAttendance: null,
    category: null,
    manualPoints: '',
    diploma: null,
    description: '',
    addCategory: [],
    newCategoryLabel: '',
    newCategoryIsFormal: false,
    errors: {},
  });

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#fff',
    borderRadius: '2em',
    color: '#f59c00',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.25em 0.5em',
    textAlign: 'center',
  };

  const selectGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const formDetails = {
    'row-1': {
      cpdName: {
        type: 'input',
        id: 'f-cpdName',
        label: t('page.user.myCPD.modalNameLabel'),
        placeholder: t('page.user.myCPD.modalNamePlaceholder'),
        icon: 'signature',
        required: true,
      },
      linkToAgenda: {
        type: 'input',
        id: 'f-linkToAgenda',
        label: t('page.user.myCPD.modalAgendaLabel'),
        placeholder: t('page.user.myCPD.modalAgendaPlaceholder'),
        icon: 'link',
        required: true,
      },
    },
    'row-2': {
      manualPoints: {
        type: 'input-number',
        id: 'f-manualPoints',
        label: t('page.user.myCPD.modalPointsLabel'),
        placeholder: t('page.user.myCPD.modalPointsPlaceholder'),
        icon: 'trophy',
        required: true,
      },
      category: {
        type: 'select',
        id: 'f-category',
        label: t('page.user.myCPD.modalCategoryLabel'),
        placeholder: t('page.user.myCPD.modalCategoryPlaceholder'),
        options: groupedCategories,
        formatGroupLabel: selectGroupLabel,
        required: true,
        disabled: formFields.addCategory.length !== 0,
      },
    },
    'row-22': {
      addCategory: {
        type: 'checkbox',
        id: 'f-addCategory',
        options: [{ value: 1, label: 'Add your own category' }],
      },
    },
    'row-222': {
      newCategoryLabel: {
        type: 'input',
        id: 'f-newCategoryLabel',
        label: 'New category label',
        placeholder: 'New category label...',
        disabled: formFields.addCategory.length === 0,
      },
      newCategoryIsFormal: {
        type: 'radio',
        id: 'f-newCategoryIsFormal',
        label: 'CPD activity',
        options: [
          {
            value: true,
            label: 'Formal',
          },
          {
            value: false,
            label: 'Non-formal',
          },
        ],
        disabled: formFields.addCategory.length === 0,
        appendLabelLink: {
          label: '(see guide)',
          link: 'https://reg.ipma.world/cpd/ipma-cpd-hours/',
        },
      },
    },
    'row-3': {
      dateOfAttendance: {
        type: 'datetime',
        id: 'f-dateOfAttendance',
        label: t('page.user.myCPD.modalDateOfAttendanceLabel'),
        placeholder: t('page.user.myCPD.modalDateOfAttendancePlaceholder'),
        required: true,
      },
      diploma: {
        type: 'fileupload',
        id: 'f-diploma',
        label: t('page.user.myCPD.modalDiplomaLabel'),
        placeholder: t('page.user.myCPD.modalDiplomaPlaceholder'),
        required: true,
      },
    },
    'row-4': {
      description: {
        type: 'textarea',
        id: 'f-description',
        label: t('page.user.myCPD.modalDescriptionLabel'),
        placeholder: t('page.user.myCPD.modalDescriptionPlaceholder'),
        required: false,
      },
    },
  };

  const setValidationError = (prop, errorMsg) => {
    validateErrorsMsg.current = {
      ...validateErrorsMsg.current,
      [prop]: errorMsg,
    };
    validateError.current = true;
  };

  const submit = () => {
    const params = new FormData();

    Object.keys(formFields).forEach((key) => {
      const obj = findNestedObj(formDetails, key);
      if (key === 'category' && formFields.addCategory.length !== 0) {
        return;
      }
      if (obj && obj.required && obj.type === 'fileupload' && !formFields[key]) {
        setValidationError(key, t('global.validationErrors.fieldNotEmpty'));
      } else if (
        key !== 'dateOfAttendance' &&
        obj &&
        obj.required &&
        obj.type !== 'fileupload' &&
        isEmpty(formFields[key])
      ) {
        setValidationError(key, t('global.validationErrors.fieldNotEmpty'));
      } else if (
        key === 'dateOfAttendance' &&
        obj &&
        obj.required &&
        !formFields.dateOfAttendance
      ) {
        setValidationError(key, t('global.validationErrors.fieldNotEmpty'));
      }
    });

    if (formFields.linkToAgenda && !isValidUrl(formFields.linkToAgenda)) {
      setValidationError('linkToAgenda', t('global.validationErrors.invalidUrl'));
    }

    if (formFields.manualPoints <= 0) {
      setValidationError('manualPoints', t('global.validationErrors.invalidPoints'));
    }

    if (formFields.diploma && !isValidFileFormat(formFields.diploma, ['pdf'])) {
      setValidationError('diploma', t('global.validationErrors.invalidFileFormat'));
    } else if (formFields.diploma && !isValidFileSize(formFields.diploma, 5)) {
      setValidationError('diploma', t('global.validationErrors.invalidFileSize'));
    }

    if (formFields.addCategory.length) {
      if (!formFields.newCategoryLabel) {
        setValidationError('newCategoryLabel', t('global.validationErrors.fieldNotEmpty'));
      }
    }

    if (validateError.current) {
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: validateErrorsMsg.current,
        },
      });
      validateErrorsMsg.current = {};
      validateError.current = false;
      return;
    }

    params.append(
      'form[cpd][dateOfAttendance]',
      formFields.dateOfAttendance
        ? parseDate(formFields.dateOfAttendance, 'YYYY-MM-DD HH:mm:ss')
        : '',
    );
    params.append('form[cpd][diplomaFile]', getFileValue(formFields.diploma));
    params.append('form[cpd][name]', formFields.cpdName || '');
    params.append('form[cpd][manualPoints]', formFields.manualPoints || '');
    params.append('form[cpd][linkToAgenda]', formFields.linkToAgenda || '');
    params.append('form[cpd][description]', formFields.description || '');
    if (formFields.addCategory.length) {
      params.append(
        'form[cpd][newCategoryName]',
        formFields.newCategoryLabel ? formFields.newCategoryLabel : '',
      );
      params.append('form[cpd][newCategoryIsFormal]', formFields.newCategoryIsFormal ? '1' : '0');
    } else {
      params.append('form[cpd][category]', formFields.category ? formFields.category.id : '');
    }
    sendCPD(params, setSent, (payload) => {
      handleFormFieldChange({
        target: {
          id: 'errors',
          value: payload,
        },
      });
    });
  };

  return (
    <>
      <FormCreator
        formFields={formFields}
        formDetails={formDetails}
        errors={formFields.errors}
        handleFormFieldChange={handleFormFieldChange}
        buttonLabel="Send"
        onSubmit={submit}
        hideButton
      />
      <SubmitButton onClick={submit}>{t('page.singleProjectRole.applyButton')}</SubmitButton>
    </>
  );
};

const ApplyForCPDModal = ({ open, loading, closeFn, sendCPD, categories }) => {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);

  useEffect(() => {
    setSent(false);
  }, [open]);

  const groupedCategories = [
    {
      label: 'Formal',
      options: categories.formal,
    },
    {
      label: 'Non-formal',
      options: categories.nonFormal,
    },
  ];

  const renderContent = () => (
    <ModalContentCustom>
      <Heading as="h4" noLine center>
        {sent ? t('page.user.myCPD.sentTitle') : t('page.user.myCPD.modalTitle')}
      </Heading>
      <Margin top="16">
        {sent ? t('page.user.myCPD.sentDesc') : t('page.user.myCPD.modalDesc')}
      </Margin>
      {loading ? (
        <Spinner />
      ) : (
        <Margin top="32">
          <Row halign="center">
            {sent ? (
              <>
                <Success />
                <Col size={1 / 2}>
                  <Button onClick={closeFn}>Close</Button>
                </Col>
                <Col size={1 / 2}>
                  <Button onClick={() => setSent(false)}>Submit again</Button>
                </Col>
              </>
            ) : (
              <ModalForm
                setSent={() => setSent(true)}
                sendCPD={sendCPD}
                groupedCategories={groupedCategories}
              />
            )}
          </Row>
        </Margin>
      )}
    </ModalContentCustom>
  );

  return (
    <Modal
      isOpen={open}
      customContent={renderContent}
      onRequestClose={closeFn}
      autoWidth
      customWidth="100%"
    />
  );
};

ApplyForCPDModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  closeFn: PropTypes.func.isRequired,
  sendCPD: PropTypes.func.isRequired,
  categories: PropTypes.objectOf({}).isRequired,
};

ModalForm.propTypes = {
  setSent: PropTypes.func.isRequired,
  sendCPD: PropTypes.func.isRequired,
  groupedCategories: PropTypes.arrayOf({}).isRequired,
};

ApplyForCPDModal.defaultProps = {
  open: false,
};

const mapStateToProps = ({ user: { appType }, loading }) => ({
  loading: isLoading(loading, 'CPD_APPLICATION'),
  appType,
});

const mapDispatchToProps = (dispatch) => ({
  sendCPD: (payload, closeModal, setErrors) =>
    dispatch(createUserApplyForCpdCreator(payload, closeModal, setErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyForCPDModal);
