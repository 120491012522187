import React, { useEffect, useReducer } from 'react';
import {
  PageContent,
  Container,
  Header,
  Alerts,
  Heading,
  Field,
  Row,
  Col,
  Checkbox,
  Button,
  Spinner,
  Editor,
  Datepicker,
  Select,
} from 'components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  loadSubcourseParentCreator,
  createSubcourseCreator,
  loadGlobalCourseAttributesCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { globalPropTypes, isLoading } from 'helpers';
import { Margin } from 'styled-components-spacing';
import { remove, isEmpty, find } from 'lodash';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import {
  initialState,
  reducer,
  setCourseCreator,
  setFieldsCreator,
  setFieldCreator,
  setErrorsCreator,
  setDurationFromCompetenciesCreator,
  addLearningOutcomesCreator,
  setLearningOutcomesCreator,
  removeLearningOutcomesCreator,
} from './reducer';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.organisation.courses',
    url: '/organisation/courses',
  },
  {
    title: 'navigation.breadcrumbs.organisation.createSubcourse',
    url: '',
  },
];

const CreateSubcourse = ({
  loading,
  loadCourseAttributes,
  loadParent,
  history,
  submitForm,
  tags,
  countries,
  timezones,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    loadCourseAttributes();

    loadParent(id, history, (course, fields) => {
      dispatch(setCourseCreator(course));
      dispatch(setFieldsCreator(fields));
    });
  }, []);

  const handleOnSubmit = () => {
    submitForm({ course: state.course, fields: state.fields }, history, (payload) =>
      dispatch(setErrorsCreator(payload)),
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setFieldCreator({ name, value }));
  };

  const handleChangeCustom = (name) => (e) => {
    const value = e.value || e;
    dispatch(setFieldCreator({ name, value }));
  };

  const handleChangeCustomMultiCheckbox = (name, id) => (e) => {
    const prevValue = state.fields[name];
    let newValue = null;

    if (e && !prevValue.includes(id)) {
      newValue = [...prevValue, id];
    } else {
      remove(prevValue, (n) => n === id);
      newValue = prevValue;
    }

    dispatch(setFieldCreator({ name, value: newValue }));
  };

  const formatDurationTime = () => {
    if (state.fields.duration <= 0) return null;

    return moment.duration(state.fields.duration, 'hours').format('HH[h] mm[m]');
  };

  const validateDuration = () => {
    let { duration } = state.fields;
    duration = +parseFloat(duration).toFixed(2);

    if (!duration || duration < 0) duration = 0;
    if (duration > 60) duration = 60;

    dispatch(setFieldCreator({ name: 'duration', value: duration.toFixed(2) }));
  };

  const handleChangeLearningOutcomes = (index) => (e) => {
    const { value } = e.target;

    dispatch(
      setLearningOutcomesCreator({
        index,
        value,
      }),
    );
  };

  const renderCompetencies = (
    name,
    label,
    required = false,
    errorName = name,
    labelAsValue = false,
  ) => {
    if (isEmpty(state.course[name])) return null;

    return (
      <Row>
        <Margin bottom="20" as={Col}>
          <Field id="tags" error={state.errors[errorName]} required={required} label={label}>
            <Margin top="8" as={Row} style={{ width: '100%' }}>
              {state.course[name].map((option, index) => {
                const value = labelAsValue ? option.label : option.value;
                return (
                  <Col key={index} size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}>
                    <Margin bottom="8">
                      <Checkbox
                        onChange={(e) => {
                          handleChangeCustomMultiCheckbox(name, value)(e);
                          dispatch(setDurationFromCompetenciesCreator());
                        }}
                        selected={state.fields[name].includes(value)}
                      >
                        {option.label}
                      </Checkbox>
                    </Margin>
                  </Col>
                );
              })}
            </Margin>
          </Field>
        </Margin>
      </Row>
    );
  };

  const renderLearningOutcomes = () => {
    if (isEmpty(state.fields.learningOutcomes)) return null;

    return state.fields.learningOutcomes.map((item, index) => (
      <Margin top="20" as={Row} key={`learningOutcomes[${index}][${item}]`}>
        <Col size={{ lg: 1 / 2 }}>
          <Field
            id={`learningOutcomes[${index}]`}
            label={t('page.organisation.createSubcourse.learningOutcomeLabel', {
              index: index + 1,
            })}
            value={item}
            clear={() => dispatch(removeLearningOutcomesCreator(index))}
          >
            <input
              id={`learningOutcomes[${index}]`}
              placeholder={t('page.organisation.createSubcourse.learningOutcomePlaceholder')}
              name="learningOutcomes[]"
              defaultValue={item}
              onBlur={handleChangeLearningOutcomes(index)}
            />
          </Field>
        </Col>
      </Margin>
    ));
  };

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.organisation.createSubcourse.title')}
        description={state.course.name}
      />
      <PageContent>
        <Container>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Alerts type="organisation" />

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="24">
                  <Heading size="h5">
                    {t('page.organisation.createSubcourse.basicInformation')}
                  </Heading>
                </Margin>

                <Row>
                  <Margin bottom="20" as={Col}>
                    <Field
                      id="name"
                      error={state.errors.name}
                      required
                      label={t('page.organisation.createSubcourse.nameLabel')}
                    >
                      <input
                        id="name"
                        placeholder={t('page.organisation.createSubcourse.namePlaceholder')}
                        name="name"
                        defaultValue={state.fields.name}
                        onBlur={handleChange}
                      />
                    </Field>
                  </Margin>
                </Row>

                <Row>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      label={t('global.dateLabel')}
                      id="startDate"
                      required
                      value={state.fields.startDate}
                      error={state.errors.startDate}
                      clear={() => handleChangeCustom('startDate')('')}
                      disabled={state.fields.onDemand}
                    >
                      <Datepicker
                        disabled={state.fields.onDemand}
                        id="startDate"
                        name="startDate"
                        onChange={handleChangeCustom('startDate')}
                        placeholderText={t('global.dateFromPlaceholder')}
                        selected={state.fields.startDate || null}
                        dateFormat="dd/MM/yyyy"
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="20" top={{ lg: 22 }} as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="endDate"
                      value={state.fields.endDate}
                      error={state.errors.endDate}
                      clear={() => handleChangeCustom('endDate')('')}
                      disabled={state.fields.onDemand}
                    >
                      <Datepicker
                        disabled={state.fields.onDemand}
                        id="endDate"
                        name="endDate"
                        onChange={handleChangeCustom('endDate')}
                        placeholderText={t('global.dateToPlaceholder')}
                        selected={state.fields.endDate || null}
                        dateFormat="dd/MM/yyyy"
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="20" top={{ lg: 38 }} as={Col} size={{ lg: 1 / 4 }}>
                    <Field id="onDemand" error={state.errors.onDemand}>
                      <Checkbox
                        onChange={handleChangeCustom('onDemand')}
                        selected={state.fields.onDemand}
                      >
                        {t('global.onDemand')}
                      </Checkbox>
                    </Field>
                  </Margin>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="timezone"
                      error={state.errors.timezone}
                      required
                      label={t('global.timezoneLabel')}
                      value={find(timezones, { value: state.fields.timezone }) || null}
                      clear={() => handleChangeCustom('timezone')('')}
                    >
                      <Select
                        id="timezone"
                        name="timezone"
                        placeholder={t('global.selectPlaceholder')}
                        value={find(timezones, { value: state.fields.timezone }) || null}
                        onChange={handleChangeCustom('timezone')}
                        options={timezones}
                      />
                    </Field>
                  </Margin>
                </Row>
              </Margin>

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="24">
                  <Heading size="h5">{t('page.organisation.createSubcourse.contactData')}</Heading>
                </Margin>

                <Row>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="line1"
                      error={state.errors['address.line1']}
                      required
                      label={t('global.addressLineLabel1')}
                    >
                      <input
                        id="line1"
                        placeholder={t('global.addressLinePlaceholder1')}
                        name="line1"
                        defaultValue={state.fields.line1}
                        onBlur={handleChange}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="line2"
                      error={state.errors['address.line2']}
                      label={t('global.addressLineLabel2')}
                    >
                      <input
                        id="line2"
                        placeholder={t('global.addressLinePlaceholder2')}
                        name="line2"
                        defaultValue={state.fields.line2}
                        onBlur={handleChange}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="city"
                      error={state.errors['address.city']}
                      required
                      label={t('global.cityLabel')}
                    >
                      <input
                        id="city"
                        placeholder={t('global.cityPlaceholder')}
                        name="city"
                        defaultValue={state.fields.city}
                        onBlur={handleChange}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="postCode"
                      error={state.errors['address.postCode']}
                      required
                      label={t('global.postCodeLabel')}
                    >
                      <input
                        id="postCode"
                        placeholder={t('global.postCodePlaceholder')}
                        name="postCode"
                        defaultValue={state.fields.postCode}
                        onBlur={handleChange}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                </Row>

                <Row>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                    <Field
                      id="country"
                      error={state.errors['address.country']}
                      required
                      label={t('global.countryLabel')}
                      value={find(countries, { value: state.fields.country }) || null}
                      clear={() => handleChangeCustom('country')('')}
                      disabled={state.fields.doesntApply}
                    >
                      <Select
                        id="country"
                        name="country"
                        placeholder={t('global.selectPlaceholder')}
                        value={find(countries, { value: state.fields.country }) || null}
                        onChange={handleChangeCustom('country')}
                        options={countries}
                        disabled={state.fields.doesntApply}
                      />
                    </Field>
                  </Margin>
                </Row>

                <Row>
                  <Margin bottom="20" as={Col}>
                    <Field id="doesntApply" error={state.errors.doesntApply}>
                      <Checkbox
                        onChange={handleChangeCustom('doesntApply')}
                        selected={state.fields.doesntApply}
                      >
                        {t('global.doesntApply')}
                      </Checkbox>
                    </Field>
                  </Margin>
                </Row>
              </Margin>

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="24">
                  <Heading size="h5">{t('page.organisation.createSubcourse.description')}</Heading>
                </Margin>

                <Row>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                    <Field
                      id="shortDescription"
                      error={state.errors.shortDescription}
                      required
                      label={t('page.organisation.createSubcourse.descriptionShortLabel')}
                    >
                      <textarea
                        id="shortDescription"
                        name="shortDescription"
                        placeholder={t(
                          'page.organisation.createSubcourse.descriptionShortPlaceholder',
                        )}
                        defaultValue={state.fields.shortDescription}
                        onBlur={handleChange}
                      />
                    </Field>
                  </Margin>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                    <Field
                      id="description"
                      error={state.errors.description}
                      required
                      label={t('page.organisation.createSubcourse.descriptionFullLabel')}
                    >
                      <textarea
                        id="description"
                        name="description"
                        placeholder={t(
                          'page.organisation.createSubcourse.descriptionFullPlaceholder',
                        )}
                        defaultValue={state.fields.description}
                        onBlur={handleChange}
                      />
                    </Field>
                  </Margin>
                </Row>
              </Margin>

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="24">
                  <Heading size="h5">
                    {t('page.organisation.createSubcourse.courseCompetenceAreas')}
                  </Heading>
                </Margin>

                {renderCompetencies(
                  'perspectiveCompetencies',
                  t('page.organisation.createSubcourse.perspectiveCompetenciesLabel'),
                  true,
                  'competency',
                )}
                {renderCompetencies(
                  'peopleCompetencies',
                  t('page.organisation.createSubcourse.peopleCompetenciesLabel'),
                  true,
                  'peopleCompetency',
                )}
                {renderCompetencies(
                  'practiceCompetencies',
                  t('page.organisation.createSubcourse.practiceCompetenciesLabel'),
                  true,
                  'practiceCompetency',
                )}
                {renderCompetencies(
                  'customCompetencies',
                  t('page.organisation.createSubcourse.customCompetenciesLabel'),
                  false,
                  'customCourseCompetencies',
                  true,
                )}

                <Row>
                  <Margin bottom="20" as={Col}>
                    <Field
                      id="duration"
                      error={state.errors.duration}
                      required
                      label={t('page.organisation.createSubcourse.courseDurationLabel')}
                    >
                      <Row valign="center" style={{ width: '100%' }}>
                        <Col size={{ xs: 1 / 2, md: 3 / 8 }}>
                          <input
                            id="duration"
                            placeholder={t(
                              'page.organisation.createSubcourse.courseDurationPlaceholder',
                            )}
                            name="duration"
                            value={state.fields.duration}
                            onChange={handleChange}
                            onBlur={validateDuration}
                          />
                        </Col>
                        <Col size={{ xs: 1 / 2, md: 2 / 8 }}>
                          <p>{formatDurationTime()}</p>
                        </Col>
                      </Row>
                    </Field>
                  </Margin>
                </Row>
              </Margin>

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="24">
                  <Heading size="h5">
                    {t('page.organisation.createSubcourse.coursePrograme')}
                  </Heading>
                </Margin>

                <Row>
                  <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                    <Field
                      id="programmeDescription"
                      error={state.errors.programmeDescription}
                      required
                      label={t('page.organisation.createSubcourse.courseProgrameLabel')}
                    >
                      {state.fields.programmeDescription !== null ? (
                        <Editor
                          defaultValue={state.fields.programmeDescription}
                          onChange={(value) =>
                            handleChangeCustom('programmeDescription')(draftToHtml(value))
                          }
                          placeholder={t(
                            'page.organisation.createSubcourse.courseProgramePlaceholder',
                          )}
                        />
                      ) : null}
                    </Field>
                  </Margin>
                </Row>
              </Margin>

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="24">
                  <Heading size="h5">
                    {t('page.organisation.createSubcourse.expectedLearningOutcomes')}
                  </Heading>
                </Margin>

                <Margin bottom="20">
                  {renderLearningOutcomes()}

                  <Button
                    type="button"
                    onClick={() => dispatch(addLearningOutcomesCreator())}
                    auto="xs"
                    text
                    primaryDark
                  >
                    {t('page.organisation.createSubcourse.addAnotherExpectedOutcome')}
                  </Button>
                </Margin>
              </Margin>

              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="24">
                  <Heading size="h5">{t('page.organisation.createSubcourse.tags')}</Heading>
                </Margin>

                <Field
                  id="tags"
                  error={state.errors.tags}
                  label={t('page.organisation.createSubcourse.tagsLabel')}
                >
                  <Margin top="8" as={Row} style={{ width: '100%' }}>
                    {tags.map((option, index) => (
                      <Col key={index} size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}>
                        <Margin bottom="8">
                          <Checkbox
                            onChange={handleChangeCustomMultiCheckbox('tags', option.value)}
                            selected={state.fields.tags.includes(option.value)}
                          >
                            {option.label}
                          </Checkbox>
                        </Margin>
                      </Col>
                    ))}
                  </Margin>
                </Field>
              </Margin>

              <Row>
                <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
                  <Margin bottom={{ xs: 12, md: 0 }}>
                    <Button type="button" onClick={() => handleOnSubmit()}>
                      {t('global.publishButton')}
                    </Button>
                  </Margin>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </PageContent>
    </>
  );
};

CreateSubcourse.propTypes = {
  loading: PropTypes.bool,
  loadParent: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  submitForm: PropTypes.func.isRequired,
  loadCourseAttributes: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes).isRequired,
  timezones: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes).isRequired,
  tags: PropTypes.arrayOf(globalPropTypes.ChooseDataPropsTypes),
};

CreateSubcourse.defaultProps = {
  loading: false,
  tags: [],
};

const mapStateToProps = ({ loading, globals }) => ({
  loading: isLoading(loading, 'CREATE_SUBCOURSE'),
  tags: globals.course.cloudTags,
  countries: globals.countries,
  timezones: globals.timezones,
});

const mapDispatchToProps = (dispatch) => ({
  loadCourseAttributes: () => dispatch(loadGlobalCourseAttributesCreator()),
  loadParent: (payload, history, cb) => dispatch(loadSubcourseParentCreator(payload, history, cb)),
  submitForm: (payload, history, setErrors) =>
    dispatch(createSubcourseCreator(payload, history, setErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubcourse);
