import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from 'components';
import { Container } from './Loader.styled';

function Loader(props) {
  return (
    <Container {...props}>
      <Spinner />
    </Container>
  );
}

function EnhancedLoader({ items, type, ...props }) {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    setVisible(!!items.filter((item) => item.type === type).length);
  }, [items]);

  if (!visible) return null;

  return <Loader {...props} />;
}

EnhancedLoader.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = ({ loading }) => ({
  items: loading,
});

export { Loader };
export default connect(mapStateToProps, null)(EnhancedLoader);
