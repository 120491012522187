import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { globalPropTypes } from 'helpers';

import { Header, Tabs } from 'components';
import { loadMaAssessorsCreator, loadMaCoursesBarCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.courses',
    url: '/ma/courses',
  },
];

const initialTabs = [
  {
    title: 'navigation.tabs.ma.courses.unassigned',
    path: '/ma/courses/unassigned',
    key: 'unassigned',
  },
  {
    title: 'navigation.tabs.ma.courses.assigned',
    path: '/ma/courses/assigned',
    key: 'assigned',
  },
  {
    title: 'navigation.tabs.ma.courses.accepted',
    path: '/ma/courses/accepted',
    key: 'accepted',
  },
  {
    title: 'navigation.tabs.ma.courses.canceled',
    path: '/ma/courses/canceled',
    key: 'canceled',
  },
];

const Courses = ({ routes, loadBar, bar, userPermissions, loadMaAssessors }) => {
  const { t } = useTranslation();
  useEffect(() => {
    loadBar();
    loadMaAssessors();
  }, []);

  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect('/ma/courses', firstTabPath);

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ma.courses.title')} />
      <Tabs items={tabs} />
      {renderExactRedirect()}
      {renderSubRoutes()}
    </>
  );
};

Courses.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  loadBar: PropTypes.func.isRequired,
  loadMaAssessors: PropTypes.func.isRequired,
  bar: PropTypes.shape({
    unassigned: PropTypes.number,
    assigned: PropTypes.number,
    accepted: PropTypes.number,
    canceled: PropTypes.number,
  }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

Courses.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ ma, user }) => ({
  bar: ma.courses.bar,
  userPermissions: user.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  loadBar: () => dispatch(loadMaCoursesBarCreator()),
  loadMaAssessors: () => dispatch(loadMaAssessorsCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
