import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal/Modal';
import { Row, Col, Heading, Button, Field } from 'components/';
import Container from './ChangeEmail.styled';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import { updateUserEmailCreator } from 'store/actionsCreators';
import { connect } from 'react-redux';

const InputField = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <Margin top={{ xs: 32 }}>
      <Row>
        <Col size={{ xs: 2 / 3 }}>
          <Field small={true}>
            <input
              onChange={onChange}
              key="email-input"
              id="email-input"
              type="text"
              value={value}
              placeholder={t('forms.emailPlaceholder')}
              autoFocus
            />
          </Field>
        </Col>
      </Row>
    </Margin>
  );
};

const ChangeEmail = ({ email, updateEmail }) => {
  const { t } = useTranslation();
  const [newEmail, setNewEmail] = useState('');

  const handleSubmit = () => {
    updateEmail({
      email: newEmail,
    });
  };

  const renderChangeEmail = () => (
    <>
      <Container>
        <Row>
          <Col size={{ xs: 1 / 1 }}>
            <Heading as="h4" noLine>
              Action required
            </Heading>
            <Margin top={{ xs: 24 }}>
              <p>
                To continue, please enter your valid e-mail address.
                This is a one-time procedure that will enable you to log in via Twitter.
              </p>
            </Margin>
          </Col>
        </Row>
        <InputField onChange={(e) => setNewEmail(e.target.value)} value={newEmail} />
        <Margin top={{ xs: 32 }}>
          <Row>
            <Col size={{ xs: 1 / 2 }}>
              <Button onClick={handleSubmit}>Accept</Button>
            </Col>
          </Row>
        </Margin>
      </Container>
    </>
  );

  return <Modal isOpen={email.includes("@twitter")} customContent={renderChangeEmail} />;
};

ChangeEmail.propTypes = {
  type: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  updateEmail: (payload) => dispatch(updateUserEmailCreator(payload)),
});

export default connect(null, mapDispatchToProps)(ChangeEmail);
