import urljoin from 'url-join';
import ky from './kyInstance';

import { BASE_PATH, withAuthorizationHeader } from '..';

export const create = async (body, type) => {
  return ky.post(urljoin(BASE_PATH, 'course', 'new', type), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getSubcourseParent = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'subcourse', 'get-parent-course', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const createSubcourse = async (body) => {
  return ky.post(urljoin(BASE_PATH, 'subcourse', 'new'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const getCourse = async (id) => {
  return ky.get(urljoin(BASE_PATH, 'course', String(id)), {
    headers: withAuthorizationHeader(),
  });
};

export const updateDraft = async ({ id, body }) => {
  return ky.post(urljoin(BASE_PATH, 'course', String(id), 'edit-draft'), {
    headers: withAuthorizationHeader(),
    body,
  });
};

export const updateSubmittedTemplateCourse = async ({ id, body }) => {
  return ky.post(urljoin(BASE_PATH, 'course', String(id), 'edit-course'), {
    headers: withAuthorizationHeader(),
    body,
  });
};
