import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';

import { Margin } from 'styled-components-spacing';
import {
  loadIpmaPeopleAddListUsersCreator,
  addIpmaPeopleUsersCreator,
  loadGlobalIpmaRolesCreator,
} from 'store/actionsCreators';
import {
  Alerts,
  Button,
  Col,
  Checkbox,
  Container,
  Field,
  Header,
  PageContent,
  Row,
  Select,
  Table,
  Spinner,
} from 'components';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ipma.people',
    url: '/ipma/people',
  },
  {
    title: 'navigation.breadcrumbs.ipma.addPeople',
    url: '/ipma/people/add',
  },
];

function People({ users, loadUsers, loading, addUsers, loadGlobalIpmaRoles, roles, history }) {
  const { t } = useTranslation();
  const [selectedPeople, setPeople] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [filtration, setFiltration] = useState({ 'first-name': '', 'last-name': '', email: '' });

  useEffect(() => {
    setPeople(selectedPeople.filter((personId) => find(users, { id: personId })));
  }, [users]);

  useEffect(() => {
    if (!roles.length) {
      loadGlobalIpmaRoles();
    }
  }, []);

  const handleSelectRow = (id) => (value) => {
    setPeople(
      value ? [...selectedPeople, id] : selectedPeople.filter((personId) => personId !== id),
    );
  };

  const handleChangeRole = (id, select) => ({ value }) => {
    const noDuplicates = selectedRoles.filter((item) => {
      if (item.userId === id && item.select === select) {
        return false;
      }
      return true;
    });
    setSelectedRoles([...noDuplicates, { userId: id, userRole: value, select }]);
  };

  const handleChangeFiltration = (name) => (e) => {
    const { value } = e.target;
    setFiltration({ ...filtration, [name]: value });
  };

  const submit = () => {
    addUsers(
      selectedPeople.map((item) => {
        const user = { userId: item, ipmaRoles: [] };
        selectedRoles.forEach((el) => {
          if (el.userId === item) {
            user.ipmaRoles.push(el.userRole);
          }
        });
        return { ...user };
      }),
      history,
    );
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ipma.peopleAdd.title')} />
      <Container>
        <PageContent>
          <Alerts type="ipma" />
          <Margin as="p" bottom={{ xs: 16 }}>
            <strong>{t('page.ipma.peopleAdd.info')}</strong>
          </Margin>
          <form onChange={() => loadUsers(filtration)}>
            <Row>
              <Col size={{ md: 1 / 4, lg: 1 / 4 }}>
                <Margin bottom={{ xs: 8, md: 0 }}>
                  <Field small>
                    <input
                      placeholder={t('global.firstNamePlaceholder')}
                      type="text"
                      name="first-name"
                      value={filtration['first-name']}
                      onChange={handleChangeFiltration('first-name')}
                    />
                  </Field>
                </Margin>
              </Col>
              <Col size={{ md: 1 / 4, lg: 1 / 4 }}>
                <Margin bottom={{ xs: 8, md: 0 }}>
                  <Field small>
                    <input
                      placeholder={t('global.lastNamePlaceholder')}
                      type="text"
                      name="last-name"
                      value={filtration['last-name']}
                      onChange={handleChangeFiltration('last-name')}
                    />
                  </Field>
                </Margin>
              </Col>
              <Col size={{ md: 1 / 4, lg: 1 / 4 }}>
                <Margin bottom={{ xs: 8, md: 0 }}>
                  <Field small>
                    <input
                      placeholder={t('global.emailPlaceholder')}
                      type="text"
                      name="email"
                      value={filtration.email}
                      onChange={handleChangeFiltration('email')}
                    />
                  </Field>
                </Margin>
              </Col>
            </Row>
          </form>
          <Margin top={{ xs: 32 }}>
            {loading ? <Spinner /> : null}
            {!loading ? (
              <Table tablebreakpoint="lg">
                <Table.Head>
                  <Table.Row>
                    <Table.Heading />
                    <Table.Heading>{t('page.ipma.peopleAdd.firstAndLastName')}</Table.Heading>
                    <Table.Heading>{t('page.ipma.peopleAdd.email')}</Table.Heading>
                    <Table.Heading>{t('page.ipma.peopleAdd.role')}</Table.Heading>
                    <Table.Heading />
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {!loading && !users.length ? (
                    <Table.Row>
                      <Table.Cell colSpan={4}>{t('global.noResultsFound')}</Table.Cell>
                    </Table.Row>
                  ) : null}
                  {!loading && users.length
                    ? users.map((user) => (
                        <Table.Row key={`person-add-${user.id}`}>
                          <Table.Cell>
                            <Field>
                              <Checkbox
                                onChange={handleSelectRow(user.id)}
                                selected={selectedPeople.includes(user.id)}
                              />
                            </Field>
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.peopleAdd.firstAndLastName')}:</Table.Label>
                            {`${user.firstName} ${user.lastName}`}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.peopleAdd.email')}:</Table.Label>
                            {user.email}
                          </Table.Cell>
                          <Table.Cell>
                            <Table.Label>{t('page.ipma.peopleAdd.role')}:</Table.Label>
                            <Select small onChange={handleChangeRole(user.id, 0)} options={roles} />
                          </Table.Cell>
                          <Table.Cell>
                            <Select small onChange={handleChangeRole(user.id, 1)} options={roles} />
                          </Table.Cell>
                        </Table.Row>
                      ))
                    : null}
                </Table.Body>
              </Table>
            ) : null}
          </Margin>
          <Margin top={{ xs: 16 }}>
            <Row>
              <Col size={{ md: 1 / 5, lg: 1 / 6 }}>
                <Button onClick={submit}>{t('global.addUserButton')}</Button>
              </Col>
            </Row>
          </Margin>
        </PageContent>
      </Container>
    </>
  );
}

People.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  loadUsers: PropTypes.func.isRequired,
  addUsers: PropTypes.func.isRequired,
  loadGlobalIpmaRoles: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.number.isRequired }),
  ).isRequired,
};
People.defaultProps = {};

const mapStateToProps = ({ ipma, loading, globals }) => ({
  users: ipma.people.add.data,
  loading: !!loading.filter((item) => item.type === 'IPMA').length,
  roles: globals.ipma.roles,
});
const mapDispatchToProps = (dispatch) => ({
  loadUsers: (payload) => dispatch(loadIpmaPeopleAddListUsersCreator(payload)),
  addUsers: (payload, history) => dispatch(addIpmaPeopleUsersCreator(payload, history)),
  loadGlobalIpmaRoles: () => dispatch(loadGlobalIpmaRolesCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
