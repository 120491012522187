import styled, { css } from 'styled-components';
import { p, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const AcceptContent = styled.div`
text-align: center;
${px(16)};
${py(24)};

${breakpoint('md')`
  ${css`
    ${p(42)};
  `}
`}
}
`;

export const AcceptError = styled.div`
  color: ${({ theme }) => theme.colors.primaries.darker};
  margin-bottom: 8px;
`;
