import moment from 'moment';

export default (date, format = 'DD/MM/YYYY') => {
  if (date) {
    const md = moment(date);
    if (md.isValid()) {
      return md.format(format);
    }
  }

  return null;
};
