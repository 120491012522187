import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const Title = styled.p`
  ${({ theme }) => theme && css`
    color: ${theme.colors.tertiaries.darker};
    font-size: 96px;
    font-weight: ${theme.font.weight.bold};
    text-align: center;

    ${breakpoint('md')`
      ${css`          
        font-size: 128px;
      `}
    `}
  `}
`;
