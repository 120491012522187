import React from 'react';
import { Heading, Row, Col } from 'components';
import { Margin } from 'styled-components-spacing/dist/cjs';
import PropTypes from 'prop-types';
import { ProgressBar, Label, Line, Value, Message } from './Progress.styled';

const Progress = ({ start, end, value, title, message, daysLeft }) => {
  const progressStatus = () => {
    if (value === '100') {
      return 'expired';
    }
    if (daysLeft <= 30) {
      return 'warning';
    }
    return 'new';
  };

  return (
    <>
      <Row valign="top">
        <Margin as={Col} size={{ lg: 3 / 7 }} bottom={{ xs: 8, lg: 0 }}>
          <Heading as="h4" size="h6">
            {title}
          </Heading>
        </Margin>
        <Col size={{ lg: 4 / 7 }}>
          <ProgressBar>
            <Label>{start}</Label>
            <Line>
              <Value value={value} progressStatus={progressStatus()} />
            </Line>
            <Label>{end}</Label>
          </ProgressBar>
        </Col>
      </Row>
      {message && (
        <Message>
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </Message>
      )}
    </>
  );
};

Progress.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};

Progress.defaultProps = {
  message: '',
};

export default Progress;
