import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { ButtonIcon as ButtonIconBase } from 'components';

export const Overlay = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease;

  ${({ theme, open }) => open && css`
    z-index: ${theme.zindex.overlay};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.6);
    
    ${breakpoint(theme.navbar.breakpoint)`
      ${css`
        display: none;
      `}
    `}
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme && css`
    height: 100%;

    ${breakpoint(theme.navbar.breakpoint)`
      ${css`
        display: none;
      `}
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => theme && css`
    height: ${theme.navbar.mobileHeight};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const ButtonIconNotification = styled(ButtonIconBase)`
  ${({ notification }) => notification && css`
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 10px;
      bottom: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  `}
`;

export const SearchMenu = styled.div`
  z-index: ${({ theme }) => theme.zindex.menu};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ theme }) => theme.navbar.mobileHeight};
  padding: 0 ${({ theme }) => theme.grid.gutter / 2}px;
  background-color: #fff;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.2s ease;

  ${({ open }) => open && css`
    transform: translate3d(0, 0, 0);
  `}
`;