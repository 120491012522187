import React from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing/dist/cjs';
import { Col, Modal, Row, Heading } from 'components';
import ModalContent, { Action } from 'components/Modal/Modal.styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { StyledAction } from './CisModal.styled';

const CisModal = ({ openType, closeFn }) => {
  const { t } = useTranslation();

  const renderContent = () => (
    <ModalContent>
      <Heading as="h4" noLine center>
        {t('modals.headings.areYouSure')}
      </Heading>
      <Margin top="16">{t('modals.desc.cisRedirect')}</Margin>
      <Margin top="32">
        <Row halign="center">
          <Col size={{ xs: 1 / 2 }}>
            <StyledAction
              as={Link}
              to="/user/cis/"
              onClick={closeFn}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('global.confirmButton')}
            </StyledAction>
          </Col>
          <Col size={{ xs: 1 / 2 }}>
            <Action onClick={closeFn} bordered>
              {t('global.closeButton')}
            </Action>
          </Col>
        </Row>
      </Margin>
    </ModalContent>
  );

  return <Modal isOpen={!!openType} customContent={renderContent} onRequestClose={closeFn} />;
};

CisModal.propTypes = {
  openType: PropTypes.oneOf([null, 'public', 'private']),
  closeFn: PropTypes.func.isRequired,
};

CisModal.defaultProps = {
  openType: null,
};

export default CisModal;
