export const LOAD_USER_ADD_LIST_CERTIFICATES = 'LOAD_USER_ADD_LIST_CERTIFICATES';
export const SET_USER_ADD_LIST_CERTIFICATES = 'SET_USER_ADD_LIST_CERTIFICATES';
export const SET_USER_ADD_LIST_FILTRATION = 'SET_USER_ADD_LIST_FILTRATION';
export const SET_USER_ADD_LIST_SELECTED_CERTIFICATE = 'SET_USER_ADD_LIST_SELECTED_CERTIFICATE';
export const ADD_USER_ADD_LIST_CERTIFICATES = 'ADD_USER_ADD_LIST_CERTIFICATES';

export const LOAD_MY_LOCAL_LIBRARY = 'LOAD_MY_LOCAL_LIBRARY';
export const SET_MY_LOCAL_LIBRARY = 'SET_MY_LOCAL_LIBRARY';

export const VALIDATE_REGISTRATION_USER_DATA = 'VALIDATE_REGISTRATION_USER_DATA';
export const SET_REGISTRATION_USER_DATA = 'SET_REGISTRATION_USER_DATA';
export const SET_LOGIN_USER_DATA = 'SET_LOGIN_USER_DATA';
export const GET_REGISTRATION_USER_DATA = 'GET_REGISTRATION_USER_DATA';
export const REGISTER_USER = 'REGISTER_USER';
export const SET_REGISTER_ERRORS = 'SET_REGISTER_ERRORS';
export const RESEND_REIGSTRATION_ACTIVATE = 'RESEND_REIGSTRATION_ACTIVATE';

export const LOGIN_USER = 'LOGIN_USER';
export const SET_LOGIN_USER_LOADING = 'SET_LOGIN_USER_LOADING';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SHOW_ACTIVATION_ALERT = 'SHOW_NOTIFICATION_ALERT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RESET_APP = 'RESET_APP';

export const SET_NEW_ALERT = 'SET_NEW_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOAD_REGISTRATION_CERTS_FILTERS = 'LOAD_REGISTRATION_CERTS_FILTERS';

export const LOAD_REGISTRATION_USER_COUNTRIES = 'LOAD_REGISTRATION_USER_COUNTRIES';
export const SET_REGISTRATION_USER_COUNTRIES = 'SET_REGISTRATION_USER_COUNTRIES';
export const SET_USER = 'SET_USER';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const SET_CHECKED_TOKEN = 'SET_CHECKED_TOKEN';
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';
export const LOAD_USER_CERTIFICATIONS = 'LOAD_USER_CERTIFICATIONS';
export const SET_USER_CERTIFICATIONS = 'SET_USER_CERTIFICATIONS';
export const REMOVE_USER_CERTIFICATIONS = 'REMOVE_USER_CERTIFICATIONS';

export const LOAD_PROFILE = 'LOAD_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SET_EDIT_PROFILE_LOADING = 'SET_EDIT_PROFILE_LOADING';
export const SET_EDIT_PROFILE_ERRORS = 'SET_EDIT_PROFILE_ERRORS';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const SET_DELETE_PROFILE_LOADING = 'SET_DELETE_PROFILE_LOADING';
export const SET_DELETE_PROFILE_ERRORS = 'SET_DELETE_PROFILE_ERRORS';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';

export const LOAD_PROFILE_COMPLETED_COURSES = 'LOAD_PROFILE_COMPLETED_COURSES';
export const SET_PROFILE_COMPLETED_COURSES = 'SET_PROFILE_COMPLETED_COURSES';

export const LOAD_GLOBAL_COUNTRIES = 'LOAD_GLOBAL_COUNTRIES';
export const SET_GLOBAL_COUNTRIES = 'SET_GLOBAL_COUNTRIES';
export const LOAD_GLOBAL_AVAILABLE_MA = 'LOAD_GLOBAL_AVAILABLE_MA';
export const SET_GLOBAL_AVAILABLE_MA = 'SET_GLOBAL_AVAILABLE_MA';
export const LOAD_GLOBAL_PROFESSIONAL_TITLES = 'LOAD_GLOBAL_PROFESSIONAL_TITLES';
export const SET_GLOBAL_PROFESSIONAL_TITLES = 'SET_GLOBAL_PROFESSIONAL_TITLES';
export const LOAD_GLOBAL_JOBS_TITLES = 'LOAD_GLOBAL_JOBS_TITLES';
export const SET_GLOBAL_JOBS_TITLES = 'SET_GLOBAL_JOBS_TITLES';
export const LOAD_GLOBAL_GENDER = 'LOAD_GLOBAL_GENDER';
export const SET_GLOBAL_GENDER = 'SET_GLOBAL_GENDER';
export const LOAD_GLOBAL_PROFILE_PRIVACY = 'LOAD_GLOBAL_PROFILE_PRIVACY';
export const SET_GLOBAL_PROFILE_PRIVACY = 'SET_GLOBAL_PROFILE_PRIVACY';
export const LOAD_GLOBAL_LANGUAGES = 'LOAD_GLOBAL_LANGUAGES';
export const SET_GLOBAL_LANGUAGES = 'SET_GLOBAL_LANGUAGES';
export const EDIT_PASSWORD = 'EDIT_PASSWORD';
export const SET_EDIT_PASSWORD_ERRORS = 'SET_EDIT_PASSWORD_ERRORS';
export const SET_EDIT_PASSWORD_LOADING = 'SET_EDIT_PASSWORD_LOADING';

export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const SET_CREATE_ORGANISATION_ERRORS = 'SET_CREATE_ORGANISATION_ERRORS';
export const SET_CREATE_ORGANISATION_LOADING = 'SET_CREATE_ORGANISATION_LOADING';
export const LOAD_USER_ORGANISATION = 'LOAD_USER_ORGANISATION';
export const SET_USER_ORGANISATION = 'SET_USER_ORGANISATION';
export const UPDATE_USER_ORGANISATION = 'UPDATE_USER_ORGANISATION';
export const LOAD_USER_ORGANISATION_PEOPLE = 'LOAD_USER_ORGANISATION_PEOPLE';
export const SET_USER_ORGANISATION_PEOPLE = 'SET_USER_ORGANISATION_PEOPLE';
export const SET_USER_ORGANISATION_PEOPLE_PAGES = 'SET_USER_ORGANISATION_PEOPLE_PAGES';
export const SET_USER_ORGANISATION_PEOPLE_PAGE = 'SET_USER_ORGANISATION_PEOPLE_PAGE';
export const SET_USER_ORGANISATION_PEOPLE_PER_PAGE = 'SET_USER_ORGANISATION_PEOPLE_PER_PAGE';
export const LOAD_USER_ORGANISATION_PEOPLE_ADD = 'LOAD_USER_ORGANISATION_PEOPLE_ADD';
export const SET_USER_ORGANISATION_PEOPLE_ADD = 'SET_USER_ORGANISATION_PEOPLE_ADD';
export const ADD_USER_ORGANISATION_PEOPLE_ADD = 'ADD_USER_ORGANISATION_PEOPLE_ADD';
export const REMOVE_USER_ORGANISATION_PEOPLE = 'REMOVE_USER_ORGANISATION_PEOPLE';
export const SET_ORGANISATION_PERSON_RESPONSIBLE = 'SET_ORGANISATION_PERSON_RESPONSIBLE';
export const UPDATE_ORGANISATION_PERSON_RESPONSIBLE = 'UPDATE_ORGANISATION_PERSON_RESPONSIBLE';
export const LOAD_USER_DASHBOARD = 'LOAD_USER_DASHBOARD';
export const SET_USER_DASHBOARD = 'SET_USER_DASHBOARD';
export const LOAD_ORGANISATION_DASHBOARD = 'LOAD_ORGANISATION_DASHBOARD';
export const SET_ORGANISATION_DASHBOARD = 'SET_ORGANISATION_DASHBOARD';
export const CHANGE_ORGANISATION_USER_ROLE = 'CHANGE_ORGANISATION_USER_ROLE';

export const LOAD_ORGANISATION_DASHBOARD_COURSES = 'LOAD_ORGANISATION_DASHBOARD_COURSES';
export const SET_ORGANISATION_DASHBOARD_COURSES = 'SET_ORGANISATION_DASHBOARD_COURSES';
export const LOAD_ORGANISATION_PROFILE_COURSES = 'LOAD_ORGANISATION_PROFILE_COURSES';
export const SET_ORGANISATION_PROFILE_COURSES = 'SET_ORGANISATION_PROFILE_COURSES';

export const LOAD_ORGANISATION_COURSE_COMPLETION = 'LOAD_ORGANISATION_COURSE_COMPLETION';
export const SET_ORGANISATION_COURSE_COMPLETION = 'SET_ORGANISATION_COURSE_COMPLETION';
export const SET_ORGANISATION_COURSE_COMPLETION_USERS = 'SET_ORGANISATION_COURSE_COMPLETION_USERS';
export const SET_ORGANISATION_COURSE_COMPLETION_USER_STATUS =
  'SET_ORGANISATION_COURSE_COMPLETION_USER_STATUS';
export const SET_ORGANISATION_COURSE_COMPLETION_SORT = 'SET_ORGANISATION_COURSE_COMPLETION_SORT';

export const SET_APP_TYPE = 'SET_APP_TYPE';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_USER_MEMBER_ASSOCIATION_PEOPLE = 'SET_USER_MEMBER_ASSOCIATION_PEOPLE';
export const SET_USER_MEMBER_REG_PARTNER = 'SET_USER_MEMBER_REG_PARTNER';

export const SET_NEW_LOADING = 'SET_NEW_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

export const LOAD_GLOBAL_COURSE_ATTRIBUTES = 'LOAD_GLOBAL_COURSE_ATTRIBUTES';
export const LOAD_GLOBAL_COURSE_DELIVERY_MODES = 'LOAD_GLOBAL_COURSE_DELIVERY_MODES';
export const SET_GLOBAL_COURSE_DELIVERY_MODES = 'SET_GLOBAL_COURSE_DELIVERY_MODES';
export const LOAD_GLOBAL_COURSE_COURSE_TYPES = 'LOAD_GLOBAL_COURSE_COURSE_TYPES';
export const SET_GLOBAL_COURSE_COURSE_TYPES = 'SET_GLOBAL_COURSE_COURSE_TYPES';
export const LOAD_GLOBAL_COURSE_INSTRUCTION_FORMATS = 'LOAD_GLOBAL_COURSE_INSTRUCTION_FORMATS';
export const SET_GLOBAL_COURSE_INSTRUCTION_FORMATS = 'SET_GLOBAL_COURSE_INSTRUCTION_FORMATS';
export const LOAD_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES =
  'LOAD_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES';
export const SET_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES =
  'SET_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES';
export const LOAD_GLOBAL_COURSE_PEOPLE_COMPETENCIES = 'LOAD_GLOBAL_COURSE_PEOPLE_COMPETENCIES';
export const SET_GLOBAL_COURSE_PEOPLE_COMPETENCIES = 'SET_GLOBAL_COURSE_PEOPLE_COMPETENCIES';
export const LOAD_GLOBAL_COURSE_PRACTICE_COMPETENCIES = 'LOAD_GLOBAL_COURSE_PRACTICE_COMPETENCIES';
export const SET_GLOBAL_COURSE_PRACTICE_COMPETENCIES = 'SET_GLOBAL_COURSE_PRACTICE_COMPETENCIES';
export const LOAD_GLOBAL_COURSE_CLOUD_TAGS = 'LOAD_GLOBAL_COURSE_CLOUD_TAGS';
export const SET_GLOBAL_COURSE_CLOUD_TAGS = 'SET_GLOBAL_COURSE_CLOUD_TAGS';

export const LOAD_GLOBAL_CURRENCIES = 'LOAD_GLOBAL_CURRENCIES';
export const SET_GLOBAL_CURRENCIES = 'SET_GLOBAL_CURRENCIES';
export const LOAD_GLOBAL_EVENT_DELIVERY_MODE = 'LOAD_GLOBAL_EVENT_DELIVERY_MODE';
export const SET_GLOBAL_EVENT_DELIVERY_MODE = 'SET_GLOBAL_EVENT_DELIVERY_MODE';
export const LOAD_GLOBAL_EVENT_ATTRIBUTES = 'LOAD_GLOBAL_EVENT_ATTRIBUTES';
export const LOAD_GLOBAL_CONTACT_SUBJECTS = 'LOAD_GLOBAL_CONTACT_SUBJECTS';
export const SET_GLOBAL_CONTACT_SUBJECTS = 'SET_GLOBAL_CONTACT_SUBJECTS';
export const LOAD_GLOBAL_INDUSTRIES = 'LOAD_GLOBAL_INDUSTRIES';
export const SET_GLOBAL_INDUSTRIES = 'SET_GLOBAL_INDUSTRIES';

export const LOAD_GLOBAL_TIMEZONES = 'LOAD_GLOBAL_TIMEZONE';
export const SET_GLOBAL_TIMEZONES = 'SET_GLOBAL_TIMEZONE';

export const CREATE_COURSE = 'CREATE_COURSE';
export const SET_CREATE_COURSE_ERRORS = 'SET_CREATE_COURSE_ERRORS';
export const UPDATE_COURSE_DRAFT = 'UPDATE_COURSE_DRAFT';
export const UPDATE_SUBMITTED_TEMPLATE_COURSE = 'UPDATE_SUBMITTED_TEMPLATE_COURSE';

export const LOAD_MA_PEOPLE_BAR = 'LOAD_MA_PEOPLE_BAR';
export const SET_MA_PEOPLE_BAR = 'SET_MA_PEOPLE_BAR';
export const LOAD_MA_PEOPLE_USERS = 'LOAD_MA_PEOPLE_USERS';
export const SET_MA_PEOPLE_USERS = 'SET_MA_PEOPLE_USERS';

export const SET_MA_PEOPLE_USERS_SORT = 'SET_MA_PEOPLE_USERS_SORT';
export const LOAD_MA_PEOPLE_ORGANISATIONS = 'LOAD_MA_PEOPLE_ORGANISATIONS';
export const SET_MA_PEOPLE_ORGANISATIONS = 'SET_MA_PEOPLE_ORGANISATIONS';
export const SET_MA_PEOPLE_ORGANISATIONS_SORT = 'SET_MA_PEOPLE_ORGANISATIONS_SORT';
export const CHANGE_MA_PEOPLE_USERS_ROLE = 'CHANGE_MA_PEOPLE_USERS_ROLE';
export const SET_MA_PEOPLE_ORGANISATIONS_PAGE = 'SET_MA_PEOPLE_ORGANISATIONS_PAGE';
export const SET_MA_PEOPLE_ORGANISATIONS_PER_PAGE = 'SET_MA_PEOPLE_ORGANISATIONS_PER_PAGE';
export const DELETE_MA_PEOPLE_USER = 'DELETE_MA_PEOPLE_USER';
export const SET_MA_PEOPLE_USERS_PAGE = 'SET_MA_PEOPLE_USERS_PAGE';
export const SET_MA_PEOPLE_USERS_PER_PAGE = 'SET_MA_PEOPLE_USERS_PER_PAGE';
export const CHANGE_MA_PEOPLE_LIST_ROLE = 'CHANGE_MA_PEOPLE_LIST_ROLE';

export const LOAD_MA_PEOPLE_LIST = 'LOAD_MA_PEOPLE_LIST';
export const SET_MA_PEOPLE_LIST = 'SET_MA_PEOPLE_LIST';
export const SET_MA_PEOPLE_LIST_SORT = 'SET_MA_PEOPLE_LIST_SORT';
export const SET_MA_PEOPLE_LIST_PAGE = 'SET_MA_PEOPLE_LIST_PAGE';
export const SET_MA_PEOPLE_LIST_PER_PAGE = 'SET_MA_PEOPLE_LIST_PER_PAGE';
export const ACCEPT_MA_PEOPLE_LIST_USER = 'ACCEPT_MA_PEOPLE_LIST_USER';
export const DECLINE_MA_PEOPLE_LIST_USER = 'DECLINE_MA_PEOPLE_LIST_USER';
export const REMOVE_MA_PEOPLE_LIST_USER = 'REMOVE_MA_PEOPLE_LIST_USER';

export const LOAD_MA_PEOPLE_MEMBERS = 'LOAD_MA_PEOPLE_MEMBERS';
export const SET_MA_PEOPLE_MEMBERS = 'SET_MA_PEOPLE_MEMBERS';
export const SET_MA_PEOPLE_MEMBERS_SORT = 'SET_MA_PEOPLE_MEMBERS_SORT';
export const SET_MA_PEOPLE_MEMBERS_PAGE = 'SET_MA_PEOPLE_MEMBERS_PAGE';
export const SET_MA_PEOPLE_MEMBERS_PER_PAGE = 'SET_MA_PEOPLE_MEMBERS_PER_PAGE';
export const REMOVE_MA_PEOPLE_MEMBERS_USER = 'REMOVE_MA_PEOPLE_MEMBERS_USER';

export const LOAD_MA_PEOPLE_ADD_LIST_USERS = 'LOAD_MA_PEOPLE_ADD_LIST_USERS';
export const SET_MA_PEOPLE_ADD_LIST_USERS = 'SET_MA_PEOPLE_ADD_LIST_USERS';
export const ADD_MA_PEOPLE_USERS = 'ADD_MA_PEOPLE_USERS';
export const LOAD_MA_DASHBOARD = 'LOAD_MA_DASHBOARD';
export const SET_MA_DASHBOARD = 'SET_MA_DASHBOARD';
export const APPLY_USER_TO_MA = 'APPLY_USER_TO_MA';

export const LOAD_GLOBAL_MA_ROLES = 'LOAD_GLOBAL_MA_ROLES';
export const SET_GLOBAL_MA_ROLES = 'SET_GLOBAL_MA_ROLES';

export const LOAD_GLOBAL_ORGANISATION_ROLES = 'LOAD_GLOBAL_ORGANISATION_ROLES';
export const SET_GLOBAL_ORGANISATION_ROLES = 'SET_GLOBAL_ORGANISATION_ROLES';

export const LOAD_MA_PROFILE = 'LOAD_MA_PROFILE';
export const SET_MA_PROFILE = 'SET_MA_PROFILE';
export const SET_MA_PROFILE_USERS_ROLE = 'SET_MA_PROFILE_USERS_ROLE';
export const UPDATE_MA_PROFILE = 'UPDATE_MA_PROFILE';
export const SET_UPDATE_MA_PROFILE_ERRORS = 'SET_UPDATE_MA_PROFILE_ERRORS';
export const LOAD_MA_PROFILE_UPCOMING_EVENTS = 'LOAD_MA_PROFILE_UPCOMING_EVENTS';
export const SET_MA_PROFILE_UPCOMING_EVENTS = 'SET_MA_PROFILE_UPCOMING_EVENTS';

export const CREATE_EVENT = 'CREATE_EVENT';

export const LOAD_LIST = 'LOAD_LIST';
export const SET_LIST = 'SET_LIST';
export const SET_LIST_PAGE = 'SET_LIST_PAGE';
export const SET_LIST_PAGES = 'SET_LIST_PAGES';
export const SET_LIST_PER_PAGE = 'SET_LIST_PER_PAGE';
export const SET_LIST_SORT = 'SET_LIST_SORT';
export const SET_LIST_FILTERS = 'SET_LIST_FILTERS';
export const REMOVE_LIST_FILTERS = 'REMOVE_LIST_FILTERS';
export const LOAD_LIST_VIEW = 'LOAD_LIST_VIEW';
export const SET_LIST_VIEW = 'SET_LIST_VIEW';
export const REMOVE_LIST_VIEW = 'REMOVE_LIST_VIEW';

export const LOAD_MA_EVENTS = 'LOAD_MA_EVENTS';
export const SET_MA_EVENTS = 'SET_MA_EVENTS';
export const LOAD_MA_EVENTS_BAR = 'LOAD_MA_EVENTS_BAR';
export const SET_MA_EVENTS_BAR = 'SET_MA_EVENTS_BAR';
export const SET_MA_EVENTS_PAGE = 'SET_MA_EVENTS_PAGE';
export const SET_MA_EVENTS_PER_PAGE = 'SET_MA_EVENTS_PER_PAGE';
export const SET_MA_EVENTS_STATUS = 'SET_MA_EVENTS_STATUS';
export const CANCEL_MA_EVENT = 'CANCEL_MA_EVENT';
export const REOPEN_MA_EVENT = 'REOPEN_MA_EVENT';
export const LOAD_MA_EVENT = 'LOAD_MA_EVENT';
export const EDIT_MA_EVENT = 'EDIT_MA_EVENT';
export const DUPLICATE_MA_EVENT = 'DUPLICATE_MA_EVENT';

export const LOAD_PAGE_HOME = 'LOAD_PAGE_HOME';
export const SET_PAGE_HOME = 'SET_PAGE_HOME';
export const LOAD_PAGE_USER_PROFILE = 'LOAD_PAGE_USER_PROFILE';
export const SET_PAGE_USER_PROFILE = 'SET_PAGE_USER_PROFILE';
export const LOAD_PAGE_USER_PROFILE_BADGES = 'LOAD_PAGE_USER_PROFILE_BADGES';
export const SET_PAGE_USER_PROFILE_BADGES = 'SET_PAGE_USER_PROFILE_BADGES';

export const LOAD_SEARCH_TOTALS = 'LOAD_SEARCH_TOTALS';
export const SET_SEARCH_TOTALS = 'SET_SEARCH_TOTALS';
export const LOAD_SEARCH = 'LOAD_SEARCH';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SEARCH_PAGES = 'SET_SEARCH_PAGES';
export const SET_SEARCH_PAGE = 'SET_SEARCH_PAGE';
export const SET_SEARCH_PER_PAGE = 'SET_SEARCH_PER_PAGE';

export const APPLY_USER_TO_COURSE = 'APPLY_USER_TO_COURSE';
export const DECLINE_USER_FROM_COURSE = 'DECLINE_USER_FROM_COURSE';

export const SUBMIT_PAGE_CONTACT_FORM = 'SUBMIT_PAGE_CONTACT_FORM';

export const LOAD_MA_COURSES = 'LOAD_MA_COURSES';
export const SET_MA_COURSES = 'SET_MA_COURSES';
export const SET_MA_COURSES_PAGES = 'SET_MA_COURSES_PAGES';
export const SET_MA_COURSES_PAGE = 'SET_MA_COURSES_PAGE';
export const SET_MA_COURSES_PER_PAGE = 'SET_MA_COURSES_PER_PAGE';
export const LOAD_MA_COURSES_BAR = 'LOAD_MA_COURSES_BAR';
export const SET_MA_COURSES_BAR = 'SET_MA_COURSES_BAR';
export const CHANGE_MA_COURSES_ASSESSOR = 'CHANGE_MA_COURSES_ASSESSOR';

export const LOAD_MA_ASSESSORS = 'LOAD_MA_ASSESSORS';
export const SET_MA_ASSESSORS = 'SET_MA_ASSESSORS';
export const ACCEPT_MA_COURSE = 'ACCEPT_MA_COURSE';
export const CANCEL_MA_COURSE = 'CANCEL_MA_COURSE';

export const LOAD_USER_COURSES = 'LOAD_USER_COURSES';
export const SET_USER_COURSES = 'SET_USER_COURSES';
export const SET_USER_COURSES_PAGES = 'SET_USER_COURSES_PAGES';
export const SET_USER_COURSES_PAGE = 'SET_USER_COURSES_PAGE';
export const SET_USER_COURSES_PER_PAGE = 'SET_USER_COURSES_PER_PAGE';
export const LOAD_USER_COURSES_BAR = 'LOAD_USER_COURSES_BAR';
export const SET_USER_COURSES_BAR = 'SET_USER_COURSES_BAR';

export const LOAD_USER_BADGES = 'LOAD_USER_BADGES';
export const SET_USER_BADGES = 'SET_USER_BADGES';
export const CLAIM_USER_BADGE = 'CLAIM_USER_BADGE';

export const LOAD_ORGANISATION_COURSES = 'LOAD_ORGANISATION_COURSES';
export const SET_ORGANISATION_COURSES = 'SET_ORGANISATION_COURSES';
export const SET_ORGANISATION_COURSES_PAGES = 'SET_ORGANISATION_COURSES_PAGES';
export const SET_ORGANISATION_COURSES_PAGE = 'SET_ORGANISATION_COURSES_PAGE';
export const SET_ORGANISATION_COURSES_PER_PAGE = 'SET_ORGANISATION_COURSES_PER_PAGE';
export const LOAD_ORGANISATION_COURSES_BAR = 'LOAD_ORGANISATION_COURSES_BAR';
export const SET_ORGANISATION_COURSES_BAR = 'SET_ORGANISATION_COURSES_BAR';

export const CANCEL_ORGANISATION_COURSE = 'CANCEL_ORGANISATION_COURSE';

export const LOAD_ORGANISATION_COURSE_PUBLISH = 'LOAD_ORGANISATION_COURSE_PUBLISH';
export const APPLY_ORGANISATION_COURSE_PUBLISH = 'APPLY_ORGANISATION_COURSE_PUBLISH';

export const LOAD_SUBCOURSE_PARENT = 'LOAD_SUBCOURSE_PARENT';
export const CREATE_SUBCOURSE = 'CREATE_SUBCOURSE';

export const LOAD_HOME_SLIDER = 'LOAD_HOME_SLIDER';
export const SET_HOME_SLIDER = 'SET_HOME_SLIDER';

export const APP_ADD_TO_NEWSLETTER = 'APP_ADD_TO_NEWSLETTER';

export const CONNECT_AZURE = 'CONNECT_AZURE';

export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const SOCIAL_AUTH = 'SOCIAL_AUTH';
export const SET_IPMA_DASHBOARD = 'SET_IPMA_DASHBOARD';
export const LOAD_IPMA_DASHBOARD = 'LOAD_IPMA_DASHBOARD';
export const SET_IPMA_PROFILE = 'SET_IPMA_PROFILE';
export const LOAD_IPMA_PROFILE = 'LOAD_IPMA_PROFILE';
export const SET_IPMA_PROFILE_USERS_ROLE = 'SET_IPMA_PROFILE_USERS_ROLE';
export const SET_IPMA_PROFILE_UPCOMING_EVENTS = 'SET_IPMA_PROFILE_UPCOMING_EVENTS';
export const LOAD_IPMA_PROFILE_UPCOMING_EVENTS = 'LOAD_IPMA_PROFILE_UPCOMING_EVENTS';
export const UPDATE_IPMA_PROFILE = 'UPDATE_IPMA_PROFILE';
export const SET_UPDATE_IPMA_PROFILE_ERRORS = 'SET_UPDATE_IPMA_PROFILE_ERRORS';

export const SET_IPMA_PEOPLE_BAR = 'SET_IPMA_PEOPLE_BAR';
export const LOAD_IPMA_PEOPLE_BAR = 'LOAD_IPMA_PEOPLE_BAR';
export const SET_IPMA_PEOPLE_USERS = 'SET_IPMA_PEOPLE_USERS';
export const LOAD_IPMA_PEOPLE_USERS = 'LOAD_IPMA_PEOPLE_USERS';
export const SET_IPMA_PEOPLE_USERS_SORT = 'SET_IPMA_PEOPLE_USERS_SORT';
export const SET_IPMA_PEOPLE_USERS_PAGE = 'SET_IPMA_PEOPLE_USERS_PAGE';
export const SET_IPMA_PEOPLE_USERS_PER_PAGE = 'SET_IPMA_PEOPLE_USERS_PER_PAGE';
export const SET_IPMA_PEOPLE_ADD_LIST_USERS = 'SET_IPMA_PEOPLE_ADD_LIST_USERS';
export const CHANGE_IPMA_PEOPLE_USERS_ROLE = 'CHANGE_IPMA_PEOPLE_USERS_ROLE';
export const DELETE_IPMA_PEOPLE_USER = 'DELETE_IPMA_PEOPLE_USER';

export const LOAD_GLOBAL_IPMA_ROLES = 'LOAD_GLOBAL_IPMA_ROLES';
export const SET_GLOBAL_IPMA_ROLES = 'SET_GLOBAL_IPMA_ROLES';

export const LOAD_IPMA_PEOPLE_ADD_LIST_USERS = 'LOAD_IPMA_PEOPLE_ADD_LIST_USERS';
export const ADD_IPMA_PEOPLE_USERS = 'ADD_IPMA_PEOPLE_USERS';
export const LOAD_IPMA_PEOPLE_LIST = 'LOAD_IPMA_PEOPLE_LIST';

export const LOAD_IPMA_EVENTS = 'LOAD_IPMA_EVENTS';
export const SET_IPMA_EVENTS = 'SET_IPMA_EVENTS';
export const LOAD_IPMA_EVENTS_BAR = 'LOAD_IPMA_EVENTS_BAR';
export const SET_IPMA_EVENTS_BAR = 'SET_IPMA_EVENTS_BAR';
export const SET_IPMA_EVENTS_PAGE = 'SET_IPMA_EVENTS_PAGE';
export const SET_IPMA_EVENTS_PER_PAGE = 'SET_IPMA_EVENTS_PER_PAGE';
export const SET_IPMA_EVENTS_STATUS = 'SET_IPMA_EVENTS_STATUS';
export const CANCEL_IPMA_EVENT = 'CANCEL_IPMA_EVENT';
export const DUPLICATE_IPMA_EVENT = 'DUPLICATE_IPMA_EVENT';
export const REOPEN_IPMA_EVENT = 'REOPEN_IPMA_EVENT';
export const CREATE_IPMA_EVENT = 'CREATE_IPMA_EVENT';
export const LOAD_IPMA_EVENT = 'LOAD_IPMA_EVENT';
export const EDIT_IPMA_EVENT = 'EDIT_IPMA_EVENT';
export const IPMA_SHOP_SSO = 'IPMA_SHOP_SSO';

export const SET_IPMA_EVENT_PARTICIPANTS = 'SET_IPMA_EVENT_PARTICIPANTS';
export const LOAD_IPMA_EVENT_PARTICIPANTS = 'LOAD_IPMA_EVENT_PARTICIPANTS';
export const SET_IPMA_EVENT_PARTICIPANTS_PAGE = 'SET_IPMA_EVENT_PARTICIPANTS_PAGE';
export const SET_IPMA_EVENT_PARTICIPANTS_PER_PAGE = 'SET_IPMA_EVENT_PARTICIPANTS_PER_PAGE';
export const UPLOAD_IPMA_EVENT_PARTICIPANTS = 'UPLOAD_IPMA_EVENT_PARTICIPANTS';

export const SET_IPMA_AWARDS_LIST = 'SET_IPMA_AWARDS_LIST';
export const LOAD_IPMA_AWARDS_LIST = 'LOAD_IPMA_AWARDS_LIST';
export const SET_IPMA_AWARDS_LIST_PAGE = 'SET_IPMA_AWARDS_LIST_PAGE';
export const SET_IPMA_AWARDS_LIST_PER_PAGE = 'SET_IPMA_AWARDS_LIST_PER_PAGE';
export const SET_IPMA_AWARD_PARTICIPANTS = 'SET_IPMA_AWARD_PARTICIPANTS';
export const LOAD_IPMA_AWARD_PARTICIPANTS = 'LOAD_IPMA_AWARD_PARTICIPANTS';
export const SET_IPMA_AWARD_PARTICIPANTS_PAGE = 'SET_IPMA_AWARD_PARTICIPANTS_PAGE';
export const SET_IPMA_AWARD_PARTICIPANTS_PER_PAGE = 'SET_IPMA_AWARD_PARTICIPANTS_PER_PAGE';
export const UPLOAD_IPMA_AWARD_PARTICIPANTS = 'UPLOAD_IPMA_AWARD_PARTICIPANTS';

export const LOAD_IPMA_REG_OFFICE_COURSES = 'LOAD_IPMA_REG_OFFICE_COURSES';
export const SET_IPMA_REG_OFFICE_COURSES = 'SET_IPMA_REG_OFFICE_COURSES';
export const LOAD_IPMA_REG_OFFICE_COURSES_BAR = 'LOAD_IPMA_REG_OFFICE_COURSES_BAR';
export const SET_IPMA_REG_OFFICE_COURSES_BAR = 'SET_IPMA_REG_OFFICE_COURSES_BAR';
export const SET_IPMA_REG_OFFICE_COURSES_PAGE = 'SET_IPMA_REG_OFFICE_COURSES_PAGE';
export const SET_IPMA_REG_OFFICE_COURSES_PER_PAGE = 'SET_IPMA_REG_OFFICE_COURSES_PER_PAGE';
export const SET_IPMA_REG_OFFICE_COURSES_STATUS = 'SET_IPMA_REG_OFFICE_COURSES_STATUS';

export const ACCEPT_IPMA_REG_OFFICE_COURSE = 'ACCEPT_IPMA_REG_OFFICE_COURSE';
export const REJECT_IPMA_REG_OFFICE_COURSE = 'REJECT_IPMA_REG_OFFICE_COURSE';
export const UPLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS = 'UPLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS';
export const LOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS = 'LOAD_IPMA_REG_OFFICE_COURSE_REG_LOGOS';
export const SET_IPMA_REG_OFFICE_COURSE_REG_LOGOS = 'SET_IPMA_REG_OFFICE_COURSE_REG_LOGOS';
export const REMOVE_IPMA_REG_OFFICE_COURSE_REG_LOGO = 'REMOVE_IPMA_REG_OFFICE_COURSE_REG_LOGO';
export const DOWNLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGO = 'DOWNLOAD_IPMA_REG_OFFICE_COURSE_REG_LOGO';

export const LOAD_IPMA_REG_OFFICE_STAT_TABLE = 'LOAD_IPMA_REG_OFFICE_STAT_TABLE';
export const SET_IPMA_REG_OFFICE_STAT_TABLE = 'SET_IPMA_REG_OFFICE_STAT_TABLE';
export const LOAD_IPMA_REG_OFFICE_GOOGLE_LINK = 'LOAD_IPMA_REG_OFFICE_GOOGLE_LINK';
export const SET_IPMA_REG_OFFICE_GOOGLE_LINK = 'SET_IPMA_REG_OFFICE_GOOGLE_LINK';

export const TOGGLE_REG_OFFICE_MA_TYPE = 'TOGGLE_REG_OFFICE_MA_TYPE';
export const TOGGLE_REG_OFFICE_ORGANIZATION_FR = 'TOGGLE_REG_OFFICE_ORGANIZATION_FR';

export const SEND_MA_REPORT = 'SEND_MA_REPORT';
export const SET_MA_REPORT_ERRORS = 'SEND_MA_REPORT_ERRORS';

export const DOWNLOAD_MA_PEOPLE_REPORT = 'DOWNLOAD_MA_PEOPLE_REPORT';
export const LOAD_MA_REPORT_LIST = 'LOAD_MA_REPORT_LIST';
export const SET_MA_REPORT_LIST = 'SET_MA_REPORT_LIST';
export const LOAD_MA_REPORT = 'LOAD_MA_REPORT';
export const SET_MA_REPORT = 'SET_MA_REPORT';
export const SET_MA_UPDATE_REPORT = 'SET_MA_UPDATE_REPORT';
export const LOAD_MA_LAST_REPORT = 'LOAD_MA_LAST_REPORT';

export const LOAD_IPMA_REPORT_LIST = 'LOAD_IPMA_REPORT_LIST';
export const SET_IPMA_REPORT_LIST = 'SET_IPMA_REPORT_LIST';
export const TOGGLE_REPORT_INVOICE = 'TOGGLE_REPORT_INVOICE';
export const ARCHIVE_REPORT = 'ARCHIVE_REPORT';

export const SET_COURSE_BADGE_LIST = 'SET_COURSE_BADGE_LIST';
export const LOAD_COURSE_BADGE_LIST = 'LOAD_COURSE_BADGE_LIST';

export const CIS_REDIRECT = 'CIS_REDIRECT';
export const VERIFY_REPORT = 'VERIFY_REPORT';

export const LOAD_MA_PEOPLE_YC = 'LOAD_MA_PEOPLE_YC';
export const SET_MA_PEOPLE_YC = 'SET_MA_PEOPLE_YC';
export const SET_MA_PEOPLE_YC_SORT = 'SET_MA_PEOPLE_YC_SORT';
export const SET_MA_PEOPLE_YC_PAGE = 'SET_MA_PEOPLE_YC_PAGE';
export const SET_MA_PEOPLE_YC_PER_PAGE = 'SET_MA_PEOPLE_YC_PER_PAGE';

export const SET_OPEN_PENDING_BADGES = 'SET_OPEN_PENDING_BADGES';
export const SET_OPEN_PENDING_COUPON = 'SET_OPEN_PENDING_COUPON';
export const LOAD_USER_PENDING_BADGES = 'LOAD_USER_PENDING_BADGES';
export const SET_USER_PENDING_BADGES = 'SET_USER_PENDING_BADGES';
export const CLAIM_EVENT_PENDING_BADGE = 'CLAIM_EVENT_PENDING_BADGE';
export const REJECT_EVENT_PENDING_BADGE = 'REJECT_EVENT_PENDING_BADGE';
export const CLAIM_PENDING_AWARD = 'CLAIM_PENDING_AWARD';
export const REJECT_PENDING_AWARD = 'REJECT_PENDING_AWARD';
export const CLAIM_COURSE_BADGE = 'CLAIM_COURSE_BADGE';
export const REJECT_COURSE_BADGE = 'REJECT_COURSE_BADGE';
export const SUPPRESS_COURSE_BADGE = 'SUPPRESS_COURSE_BADGE';
export const SUPPRESS_COUPON = 'SUPPRESS_COUPON';
export const CLAIM_ASSESSOR_BADGE = 'CLAIM_ASSESSOR_BADGE';
export const REJECT_ASSESSOR_BADGE = 'REJECT_ASSESSOR_BADGE';
export const CLAIM_HONORARY_FELLOW_BADGE = 'CLAIM_HONORARY_FELLOW_BADGE';
export const REJECT_HONORARY_FELLOW_BADGE = 'REJECT_HONORARY_FELLOW_BADGE';

export const DOWNLOAD_COURSE_DIPLOMA = 'DOWNLOAD_COURSE_DIPLOMA';
export const EXPORT_REPORT = 'EXPORT_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';

export const SET_GLOBAL_YC_ROLES = 'SET_GLOBAL_YC_ROLES';
export const LOAD_GLOBAL_YC_ROLES = 'LOAD_GLOBAL_YC_ROLES';
export const SET_YC_DASHBOARD = 'SET_YC_DASHBOARD';
export const LOAD_YC_DASHBOARD = 'LOAD_YC_DASHBOARD';
export const SET_YC_PROFILE = 'SET_YC_PROFILE';
export const LOAD_YC_PROFILE = 'LOAD_YC_PROFILE';
export const UPDATE_YC_PROFILE = 'UPDATE_YC_PROFILE';
export const SET_UPDATE_YC_PROFILE_ERRORS = 'SET_UPDATE_YC_PROFILE_ERRORS';
export const SET_YC_PROFILE_USERS_ROLE = 'SET_YC_PROFILE_USERS_ROLE';
export const SET_YC_PROFILE_UPCOMING_EVENTS = 'SET_YC_PROFILE_UPCOMING_EVENTS';
export const LOAD_YC_PROFILE_UPCOMING_EVENTS = 'LOAD_YC_PROFILE_UPCOMING_EVENTS';
export const SET_YC_PEOPLE_BAR = 'SET_YC_PEOPLE_BAR';
export const LOAD_YC_PEOPLE_BAR = 'LOAD_YC_PEOPLE_BAR';
export const SET_YC_PEOPLE_USERS = 'SET_YC_PEOPLE_USERS';
export const LOAD_YC_PEOPLE_USERS = 'LOAD_YC_PEOPLE_USERS';
export const SET_YC_PEOPLE_USERS_SORT = 'SET_YC_PEOPLE_USERS_SORT';
export const SET_YC_PEOPLE_USERS_PAGE = 'SET_YC_PEOPLE_USERS_PAGE';
export const SET_YC_PEOPLE_USERS_PER_PAGE = 'SET_YC_PEOPLE_USERS_PER_PAGE';
export const SET_YC_PEOPLE_LIST = 'SET_YC_PEOPLE_LIST';
export const LOAD_YC_PEOPLE_LIST = 'LOAD_YC_PEOPLE_LIST';
export const LOAD_YC_MEMBERS_LIST = 'LOAD_YC_MEMBERS_LIST';
export const SET_YC_PEOPLE_LIST_SORT = 'SET_YC_PEOPLE_LIST_SORT';
export const SET_YC_PEOPLE_LIST_PAGE = 'SET_YC_PEOPLE_LIST_PAGE';
export const SET_YC_PEOPLE_LIST_PER_PAGE = 'SET_YC_PEOPLE_LIST_PER_PAGE';
export const SET_YC_PEOPLE_ADD_LIST_USERS = 'SET_YC_PEOPLE_ADD_LIST_USERS';
export const ACCEPT_YC_PEOPLE_LIST_USER = 'ACCEPT_YC_PEOPLE_LIST_USER';
export const DECLINE_YC_PEOPLE_LIST_USER = 'DECLINE_YC_PEOPLE_LIST_USER';
export const REMOVE_YC_PEOPLE_LIST_USER = 'REMOVE_YC_PEOPLE_LIST_USER';
export const DOWNLOAD_YC_PEOPLE_REPORT = 'DOWNLOAD_YC_PEOPLE_REPORT';

export const CHANGE_YC_PEOPLE_USERS_ROLE = 'CHANGE_YC_PEOPLE_USERS_ROLE';
export const DELETE_YC_PEOPLE_USER = 'DELETE_YC_PEOPLE_USER';
export const LOAD_YC_PEOPLE_ADD_LIST_USERS = 'LOAD_YC_PEOPLE_ADD_LIST_USERS';
export const ADD_YC_PEOPLE_USERS = 'ADD_YC_PEOPLE_USERS';

export const LOAD_GLOBAL_AVAILABLE_YC = 'LOAD_GLOBAL_AVAILABLE_YC';
export const SET_GLOBAL_AVAILABLE_YC = 'SET_GLOBAL_AVAILABLE_YC';

export const LOAD_YC_EVENTS = 'LOAD_YC_EVENTS';
export const SET_YC_EVENTS = 'SET_YC_EVENTS';
export const LOAD_YC_EVENTS_BAR = 'LOAD_YC_EVENTS_BAR';
export const SET_YC_EVENTS_BAR = 'SET_YC_EVENTS_BAR';
export const SET_YC_EVENTS_PAGE = 'SET_YC_EVENTS_PAGE';
export const SET_YC_EVENTS_PER_PAGE = 'SET_YC_EVENTS_PER_PAGE';
export const SET_YC_EVENTS_STATUS = 'SET_YC_EVENTS_STATUS';
export const CANCEL_YC_EVENT = 'CANCEL_YC_EVENT';
export const DUPLICATE_YC_EVENT = 'DUPLICATE_YC_EVENT';
export const REOPEN_YC_EVENT = 'REOPEN_YC_EVENT';
export const CREATE_YC_EVENT = 'CREATE_YC_EVENT';
export const LOAD_YC_EVENT = 'LOAD_YC_EVENT';
export const EDIT_YC_EVENT = 'EDIT_YC_EVENT';

export const SET_MA_PEOPLE_LIST_FILTERS = 'SET_MA_PEOPLE_LIST_FILTERS';
export const LOAD_MA_PEOPLE_LIST_FILTERS = 'LOAD_MA_PEOPLE_LIST_FILTERS';
export const SET_MA_PEOPLE_MEMBERS_FILTERS = 'SET_MA_PEOPLE_MEMBERS_FILTERS';
export const LOAD_MA_PEOPLE_MEMBERS_FILTERS = 'LOAD_MA_PEOPLE_MEMBERS_FILTERS';
export const SET_MA_PEOPLE_YC_FILTERS = 'SET_MA_PEOPLE_YC_FILTERS';
export const LOAD_MA_PEOPLE_YC_FILTERS = 'LOAD_MA_PEOPLE_YC_FILTERS';
export const SET_MA_PEOPLE_USERS_FILTERS = 'SET_MA_PEOPLE_USERS_FILTERS';
export const LOAD_MA_PEOPLE_USERS_FILTERS = 'LOAD_MA_PEOPLE_USERS_FILTERS';
export const SET_IPMA_REPORTS_FILTERS = 'SET_IPMA_REPORTS_FILTERS';
export const LOAD_IPMA_REPORTS_FILTERS = 'LOAD_IPMA_REPORTS_FILTERS';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const LOAD_YC_PROJECTS = 'LOAD_YC_PROJECTS';
export const SET_YC_PROJECTS = 'SET_YC_PROJECTS';
export const LOAD_YC_PROJECTS_BAR = 'LOAD_YC_PROJECTS_BAR';
export const SET_YC_PROJECTS_BAR = 'SET_YC_PROJECTS_BAR';
export const SET_YC_PROJECTS_PAGE = 'SET_YC_PROJECTS_PAGE';
export const SET_YC_PROJECTS_PER_PAGE = 'SET_YC_PROJECTS_PER_PAGE';
export const SET_YC_PROJECTS_STATUS = 'SET_YC_PROJECTS_STATUS';
export const EDIT_YC_PROJECT = 'EDIT_YC_PROJECT';
export const LOAD_YC_PROJECT = 'LOAD_YC_PROJECT';
export const SET_YC_PROJECT = 'SET_YC_PROJECT';

export const LOAD_GLOBAL_PROJECT_CAREERLEVEL = 'LOAD_GLOBAL_PROJECT_CAREERLEVEL';
export const LOAD_GLOBAL_PROJECT_SKILLS = 'LOAD_GLOBAL_PROJECT_SKILLS';
export const LOAD_GLOBAL_PROJECT_ROLES = 'LOAD_GLOBAL_PROJECT_ROLES';
export const SET_GLOBAL_PROJECT_CAREERLEVEL = 'SET_GLOBAL_PROJECT_CAREERLEVEL';
export const SET_GLOBAL_PROJECT_SKILLS = 'SET_GLOBAL_PROJECT_SKILLS';
export const SET_GLOBAL_PROJECT_ROLES = 'SET_GLOBAL_PROJECT_ROLES';

export const LOAD_YC_PROJECT_ROLE = 'LOAD_YC_PROJECT_ROLE';
export const SET_YC_PROJECT_ROLE = 'SET_YC_PROJECT_ROLE';
export const CREATE_PROJECT_ROLE = 'CREATE_PROJECT_ROLE';
export const EDIT_YC_PROJECT_ROLE = 'EDIT_YC_PROJECT_ROLE';
export const LOAD_YC_PROJECT_ROLES = 'LOAD_YC_PROJECT_ROLES';
export const SET_YC_PROJECT_ROLES = 'SET_YC_PROJECT_ROLES';
export const LOAD_YC_PROJECT_ROLES_BAR = 'LOAD_YC_PROJECT_ROLES_BAR';
export const SET_YC_PROJECT_ROLES_BAR = 'SET_YC_PROJECT_ROLES_BAR';
export const SET_YC_PROJECT_ROLES_PAGE = 'SET_YC_PROJECT_ROLES_PAGE';
export const SET_YC_PROJECT_ROLES_PER_PAGE = 'SET_YC_PROJECT_ROLES_PER_PAGE';
export const SET_YC_PROJECT_ROLES_STATUS = 'SET_YC_PROJECT_ROLES_STATUS';

export const CREATE_PROJECT_ROLE_APPLICATION = 'CREATE_PROJECT_ROLE_APPLICATION';
export const LOAD_YC_PROJECT_ROLE_APPLICATION = 'LOAD_YC_PROJECT_ROLE_APPLICATION';
export const SET_YC_PROJECT_ROLE_APPLICATION = 'SET_YC_PROJECT_ROLE_APPLICATION';
export const LOAD_YC_PROJECT_ROLE_APPLICATIONS = 'LOAD_YC_PROJECT_ROLE_APPLICATIONS';
export const SET_YC_PROJECT_ROLE_APPLICATIONS = 'SET_YC_PROJECT_ROLE_APPLICATIONS';
export const LOAD_YC_PROJECT_ROLE_APPLICATION_BAR = 'LOAD_YC_PROJECT_ROLE_APPLICATION_BAR';
export const SET_YC_PROJECT_ROLE_APPLICATION_BAR = 'SET_YC_PROJECT_ROLE_APPLICATION_BAR';
export const SET_YC_PROJECT_ROLE_APPLICATION_PAGE = 'SET_YC_PROJECT_ROLE_APPLICATION_PAGE';
export const SET_YC_PROJECT_ROLE_APPLICATION_PER_PAGE = 'SET_YC_PROJECT_ROLE_APPLICATION_PER_PAGE';
export const SET_YC_PROJECT_ROLE_APPLICATION_STATUS = 'SET_YC_PROJECT_ROLE_APPLICATION_STATUS';
export const ACCEPT_YC_PROJECT_ROLE_APPLICATION = 'ACCEPT_YC_PROJECT_ROLE_APPLICATION';
export const DECLINE_YC_PROJECT_ROLE_APPLICATION = 'DECLINE_YC_PROJECT_ROLE_APPLICATION';
export const PUT_ON_HOLD_YC_PROJECT_ROLE_APPLICATION = 'PUT_ON_HOLD_YC_PROJECT_ROLE_APPLICATION';

export const CREATE_IPMA_PROJECT = 'CREATE_IPMA_PROJECT';
export const LOAD_IPMA_PROJECTS = 'LOAD_IPMA_PROJECTS';
export const SET_IPMA_PROJECTS = 'SET_IPMA_PROJECTS';
export const LOAD_IPMA_PROJECTS_BAR = 'LOAD_IPMA_PROJECTS_BAR';
export const SET_IPMA_PROJECTS_BAR = 'SET_IPMA_PROJECTS_BAR';
export const SET_IPMA_PROJECTS_PAGE = 'SET_IPMA_PROJECTS_PAGE';
export const SET_IPMA_PROJECTS_PER_PAGE = 'SET_IPMA_PROJECTS_PER_PAGE';
export const SET_IPMA_PROJECTS_STATUS = 'SET_IPMA_PROJECTS_STATUS';
export const EDIT_IPMA_PROJECT = 'EDIT_IPMA_PROJECT';
export const LOAD_IPMA_PROJECT = 'LOAD_IPMA_PROJECT';
export const SET_IPMA_PROJECT = 'SET_IPMA_PROJECT';

export const LOAD_IPMA_PROJECT_ROLE = 'LOAD_IPMA_PROJECT_ROLE';
export const SET_IPMA_PROJECT_ROLE = 'SET_IPMA_PROJECT_ROLE';
export const CREATE_IPMA_PROJECT_ROLE = 'CREATE_IPMA_PROJECT_ROLE';
export const EDIT_IPMA_PROJECT_ROLE = 'EDIT_IPMA_PROJECT_ROLE';
export const LOAD_IPMA_PROJECT_ROLES = 'LOAD_IPMA_PROJECT_ROLES';
export const SET_IPMA_PROJECT_ROLES = 'SET_IPMA_PROJECT_ROLES';
export const LOAD_IPMA_PROJECT_ROLES_BAR = 'LOAD_IPMA_PROJECT_ROLES_BAR';
export const SET_IPMA_PROJECT_ROLES_BAR = 'SET_IPMA_PROJECT_ROLES_BAR';
export const SET_IPMA_PROJECT_ROLES_PAGE = 'SET_IPMA_PROJECT_ROLES_PAGE';
export const SET_IPMA_PROJECT_ROLES_PER_PAGE = 'SET_IPMA_PROJECT_ROLES_PER_PAGE';
export const SET_IPMA_PROJECT_ROLES_STATUS = 'SET_IPMA_PROJECT_ROLES_STATUS';

export const CREATE_IPMA_PROJECT_ROLE_APPLICATION = 'CREATE_IPMA_PROJECT_ROLE_APPLICATION';
export const LOAD_IPMA_PROJECT_ROLE_APPLICATION = 'LOAD_IPMA_PROJECT_ROLE_APPLICATION';
export const SET_IPMA_PROJECT_ROLE_APPLICATION = 'SET_IPMA_PROJECT_ROLE_APPLICATION';
export const LOAD_IPMA_PROJECT_ROLE_APPLICATIONS = 'LOAD_IPMA_PROJECT_ROLE_APPLICATIONS';
export const SET_IPMA_PROJECT_ROLE_APPLICATIONS = 'SET_IPMA_PROJECT_ROLE_APPLICATIONS';
export const LOAD_IPMA_PROJECT_ROLE_APPLICATION_BAR = 'LOAD_IPMA_PROJECT_ROLE_APPLICATION_BAR';
export const SET_IPMA_PROJECT_ROLE_APPLICATION_BAR = 'SET_IPMA_PROJECT_ROLE_APPLICATION_BAR';
export const SET_IPMA_PROJECT_ROLE_APPLICATION_PAGE = 'SET_IPMA_PROJECT_ROLE_APPLICATION_PAGE';
export const SET_IPMA_PROJECT_ROLE_APPLICATION_PER_PAGE =
  'SET_IPMA_PROJECT_ROLE_APPLICATION_PER_PAGE';
export const SET_IPMA_PROJECT_ROLE_APPLICATION_STATUS = 'SET_IPMA_PROJECT_ROLE_APPLICATION_STATUS';
export const ACCEPT_IPMA_PROJECT_ROLE_APPLICATION = 'ACCEPT_IPMA_PROJECT_ROLE_APPLICATION';
export const DECLINE_IPMA_PROJECT_ROLE_APPLICATION = 'DECLINE_IPMA_PROJECT_ROLE_APPLICATION';
export const PUT_ON_HOLD_IPMA_PROJECT_ROLE_APPLICATION =
  'PUT_ON_HOLD_IPMA_PROJECT_ROLE_APPLICATION';

export const DOWNLOAD_IPMA_APPLICATIONS = 'DOWNLOAD_IPMA_APPLICATIONS';

export const SET_YC_PEOPLE_USERS_FILTERS = 'SET_YC_PEOPLE_USERS_FILTERS';
export const LOAD_YC_PEOPLE_USERS_FILTERS = 'LOAD_YC_PEOPLE_USERS_FILTERS';
export const SET_YC_PEOPLE_LIST_FILTERS = 'SET_YC_PEOPLE_LIST_FILTERS';
export const LOAD_YC_PEOPLE_LIST_FILTERS = 'LOAD_YC_PEOPLE_LIST_FILTERS';
export const DOWNLOAD_YC_APPLICATIONS = 'DOWNLOAD_YC_APPLICATIONS';
export const SET_GLOBAL_ABOUT_IPMA = 'SET_GLOBAL_ABOUT_IPMA';
export const LOAD_GLOBAL_ABOUT_IPMA = 'LOAD_GLOBAL_ABOUT_IPMA';
export const SET_USER_PENDING_CERTIFICATIONS = 'SET_USER_PENDING_CERTIFICATIONS';
export const LOAD_USER_PENDING_CERTIFICATIONS = 'LOAD_USER_PENDING_CERTIFICATIONS';
export const CLAIM_USER_CERTIFICATE = 'CLAIM_USER_CERTIFICATE';
export const REMOVE_IPMA_PROJECT = 'REMOVE_IPMA_PROJECT';
export const REMOVE_IPMA_PROJECT_ROLE = 'REMOVE_IPMA_PROJECT_ROLE';
export const REMOVE_YC_PROJECT = 'REMOVE_YC_PROJECT';
export const REMOVE_YC_PROJECT_ROLE = 'REMOVE_YC_PROJECT_ROLE';

export const LOAD_USER_CPD_LIST = 'LOAD_USER_CPD_LIST';
export const SET_USER_CPD_LIST = 'SET_USER_CPD_LIST';

export const USER_APPLY_FOR_CPD = 'USER_APPLY_FOR_CPD';

export const LOAD_CPD_CATEGORIES = 'LOAD_CPD_CATEGORIES';
export const SET_CPD_CATEGORIES = 'SET_CPD_CATEGORIES';

export const LOAD_MA_CPD_REQUESTS = 'LOAD_MA_CPD_REQUESTS';
export const SET_MA_CPD_REQUESTS = 'SET_MA_CPD_REQUESTS';
export const LOAD_MA_CPD_REQUESTS_BAR = 'LOAD_MA_CPD_REQUESTS_BAR';
export const SET_MA_CPD_REQUESTS_BAR = 'SET_MA_CPD_REQUESTS_BAR';
export const SET_MA_CPD_REQUESTS_PAGE = 'SET_MA_CPD_REQUESTS_PAGE';
export const SET_MA_CPD_REQUESTS_PER_PAGE = 'SET_MA_CPD_REQUESTS_PER_PAGE';
export const SET_MA_CPD_REQUESTS_STATUS = 'SET_MA_CPD_REQUESTS_STATUS';

export const ACCEPT_CPD_REQUEST = 'ACCEPT_CPD_REQUEST';
export const REJECT_CPD_REQUEST = 'REJECT_CPD_REQUEST';

export const SET_CPD_ROLES = 'SET_CPD_ROLES';
export const LOAD_CPD_ROLES = 'LOAD_CPD_ROLES';
export const LOAD_GLOBAL_AVAILABLE_CB = 'LOAD_GLOBAL_AVAILABLE_CB';
export const SET_GLOBAL_AVAILABLE_CB = 'SET_GLOBAL_AVAILABLE_CB';
export const SEND_USER_CPD_FOR_VERIFICATION = 'SEND_USER_CPD_FOR_VERIFICATION';

export const LOAD_MA_CPD_BATCH_REQUESTS = 'LOAD_MA_CPD_BATCH_REQUESTS';
export const SET_MA_CPD_BATCH_REQUESTS = 'SET_MA_CPD_BATCH_REQUESTS';
export const SET_MA_CPD_BATCH_REQUESTS_PAGE = 'SET_MA_CPD_BATCH_REQUESTS_PAGE';
export const SET_MA_CPD_BATCH_REQUESTS_PER_PAGE = 'SET_MA_CPD_BATCH_REQUESTS_PER_PAGE';
export const LOAD_MA_CPD_BATCH_REQUESTS_LIST = 'LOAD_MA_CPD_BATCH_REQUESTS_LIST';
export const SET_MA_CPD_BATCH_REQUESTS_LIST = 'SET_MA_CPD_BATCH_REQUESTS_LIST';
export const SAVE_USER_BATCH_CPDS = 'SAVE_USER_BATCH_CPDS';
export const EXPORT_USER_BATCH_CPD = 'EXPORT_USER_BATCH_CPD';
export const EXPORT_USER_CPD_CSV = 'EXPORT_USER_CPD_CSV';

export const SET_AVA_ORGANISATIONS = 'SET_AVA_ORGANISATIONS';
export const LOAD_AVA_ORGANISATIONS = 'LOAD_AVA_ORGANISATIONS';
export const SET_CURRENT_ORGANISATIONS = 'SET_CURRENT_ORGANISATIONS';
export const LOAD_CURRENT_ORGANISATIONS = 'LOAD_CURRENT_ORGANISATIONS';
