import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import Root from 'containers/Root';

import { routes } from 'router/routes';
import configureStore from 'store/store';
import rootSaga from 'sagas';
import GlobalStyles from './theme/global';

import * as serviceWorker from './serviceWorker';

import 'react-datepicker/dist/react-datepicker.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

const history = createBrowserHistory();
const store = configureStore();

history.listen(() => window.scrollTo({ top: 0 }));

store.runSaga(rootSaga);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <Root routes={routes} history={history} store={store} />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
