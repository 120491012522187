import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FiltersControls, Pagination, Spinner, BoxList, Modal } from 'components';
import {
  loadIpmaRegOfficeCoursesCreator,
  setIpmaRegOfficeCoursesPageCreator,
  setIpmaRegOfficeCoursesPerPageCreator,
  rejectIpmaRegOfficeCourseCreator,
} from 'store/actionsCreators';
import { isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { CourseButtonAction, Flex } from '../CoursesProgrammes.styled';
import UploadModal from '../UploadModal/UploadModal';

const Accepted = ({
  courses,
  page,
  pages,
  perPage,
  loading,
  loadCourses,
  setPage,
  setPerPage,
  cancelCourse,
}) => {
  const { t } = useTranslation();
  const [cancelConfirmModal, setCancelConfirmModal] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    loadCourses({ status: 'accepted' });
  }, [perPage, page]);

  const submitCancel = () => {
    cancelCourse({ id: cancelConfirmModal, type: 'pending' });
    setCancelConfirmModal(null);
  };

  return (
    <>
      <FiltersControls
        perPageSelected={perPage}
        perPageOnChange={({ value }) => setPerPage(value)}
      />
      {loading ? <Spinner /> : null}
      {!loading ? (
        <>
          <BoxList
            type="course"
            items={courses}
            alert={t('global.noResultsFound')}
            customRenderActions={({ item }) => (
              <Flex>
                <CourseButtonAction
                  icon="upload"
                  title={t('global.uploadEditRegLogo')}
                  verticalviewbreakpoint="xl"
                  onClick={() => setOpenModal(item.id)}
                />
                <CourseButtonAction
                  icon="closeCircle"
                  title={t('global.rejectButton')}
                  verticalviewbreakpoint="xl"
                  onClick={() => setCancelConfirmModal(item.id)}
                />
              </Flex>
            )}
          />
          <Pagination styled currentPage={page} totalPages={pages} onChange={setPage} />
        </>
      ) : null}
      <Modal
        heading={t('page.ipma.office.cancelCourse')}
        description={t('page.ipma.office.cancelCourseDesc')}
        isOpen={!!cancelConfirmModal}
        confirm={submitCancel}
        cancel={() => setCancelConfirmModal(null)}
      />
      <UploadModal
        files={filesToUpload}
        open={openModal}
        setFiles={setFilesToUpload}
        setOpen={setOpenModal}
      />
    </>
  );
};

Accepted.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadCourses: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  cancelCourse: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ipma, loading }) => ({
  courses: ipma.regOfficeCourses.list.items,
  page: ipma.regOfficeCourses.list.page,
  pages: ipma.regOfficeCourses.list.pages,
  perPage: ipma.regOfficeCourses.list.perPage,
  loading: isLoading(loading, 'IPMA'),
});
const mapDispatchToProps = (dispatch) => ({
  loadCourses: (payload) => dispatch(loadIpmaRegOfficeCoursesCreator(payload)),
  setPage: (payload) => dispatch(setIpmaRegOfficeCoursesPageCreator(payload)),
  setPerPage: (payload) => dispatch(setIpmaRegOfficeCoursesPerPageCreator(payload)),
  cancelCourse: (payload) => dispatch(rejectIpmaRegOfficeCourseCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accepted);
