import styled, { css } from 'styled-components';
import { mt } from 'styled-components-spacing';

export const List = styled.ul``;

export const ListItem = styled.li`
  ${({ theme }) => theme && css`
    ${mt(16)};
    
    display: flex;
    justify-content: space-between;
    font-size: ${theme.font.size.pixel(14)};
    color: ${theme.colors.gray[500]};

    &:first-of-type {
      ${mt(0)};
    }
    
    > * {
      flex: 1;
    }
  `}
`;

export const ListKey = styled.span``;

export const ListValue = styled.span`
  ${({ theme }) => theme && css`
    font-weight: ${theme.font.weight.bold};
    text-align: right;
  `}
`;