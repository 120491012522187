import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => `${theme.grid.gutter / 2}px`};
  padding-right: ${({ theme }) => `${theme.grid.gutter / 2}px`};
  width: 100%;

  ${({
    theme: {
      grid: { containerMaxWidths },
    },
  }) =>
    Object.keys(containerMaxWidths).map((key) => {
      return css`
        ${breakpoint(key)`
          max-width: ${containerMaxWidths[key]};
        `}
      `;
    })}
`;

export default Container;
