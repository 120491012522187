import React, { useEffect, useState } from 'react';
import { isLogin } from 'api';
import { Col, Modal, Row } from 'components';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';

const useAuthLock = (history) => {
  const { t } = useTranslation();
  const [authLockOpen, setAuthLockOpen] = useState(false);

  const authLockHandle = (redirect = null) => {
    setAuthLockOpen(false);

    const redirectPath = redirect || '/auth/login';

    history.push(redirectPath);
  };

  useEffect(() => {
    let timeout = null;
    if (!isLogin()) {
      timeout = setTimeout(() => {
        setAuthLockOpen(true);
      }, 6000);
    }

    return () => clearTimeout(timeout);
  }, []);

  const blurOverlay = (show) => {
    document.body.classList[show ? 'add' : 'remove']('content-blur');
  };

  useEffect(() => {
    if (!isLogin() && authLockOpen) {
      blurOverlay(true);
    }

    return () => blurOverlay(false);
  }, [authLockOpen]);

  const renderAuthLock = () => (
    <Modal
      isOpen={authLockOpen}
      onRequestClose={() => authLockHandle()}
      heading={t('global.authModal.heading')}
      description={t('global.authModal.description')}
      customActions={(Action) => (
        <Row>
          <Margin as={Col} bottom={{ xs: 16, md: 0 }} size={{ md: 1 / 2 }}>
            <Action onClick={() => authLockHandle()}>{t('global.logIn')}</Action>
          </Margin>
          <Col size={{ md: 1 / 2 }}>
            <Action bordered onClick={() => authLockHandle('/auth/register')}>
              {t('global.createAccount')}
            </Action>
          </Col>
        </Row>
      )}
    />
  );

  return [renderAuthLock];
};

export default useAuthLock;
