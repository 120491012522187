import React from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing';
import {
  Checkbox,
  Row,
  Col,
  Datepicker,
  Dropdown,
  Field,
  Heading,
  Select,
  Button,
  SelectableTags,
} from 'components';
import { find, get, remove, isEmpty, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';

const Filters = ({
  fields,
  filters,
  onChangeFilter,
  onClickApplyFilters,
  onClickRemoveAllFilters,
  scrollAfterFiltersRef,
}) => {
  const { t } = useTranslation();
  const getValue = (prevValue, payload) => {
    const { type, value } = payload;

    if (type === 'multi') {
      const { id, checked } = value;

      if (checked && !prevValue.includes(id)) {
        return [...prevValue, id];
      }

      remove(prevValue, (n) => n === id);

      return prevValue;
    }

    return get(value, 'value') || value;
  };

  const handleChangeValue = (name, type = 'single', id = null) => (e) => {
    let value = null;

    if (type === 'multi') {
      value = getValue(filters[name], {
        type: 'multi',
        value: {
          id,
          checked: e,
        },
      });
    } else if (type === 'multi-select') {
      value = getValue(filters[name], {
        type: 'single',
        value: e,
      });
      if (value) {
        value = value.map((item) => item.value);
      }
    } else {
      value = getValue(filters[name], {
        type: 'single',
        value: e,
      });
    }

    onChangeFilter({
      name,
      value,
    });
  };

  const handleChangeInputValue = (e) => {
    const { name, value } = e.target;

    onChangeFilter({
      name,
      value,
    });
  };

  const hasFilters = () => {
    return !!find(filters, (v) => !isEmpty(v) || isNumber(v) || v instanceof Date);
  };

  const renderInput = ({ name, id, label, placeholder }) => (
    <Margin bottom="24" key={id}>
      <Field label={label} id={id} value={filters[name]} clear={() => handleChangeValue(name)('')}>
        <input
          id={id}
          name={name}
          value={filters[name]}
          onChange={handleChangeInputValue}
          placeholder={placeholder}
        />
      </Field>
    </Margin>
  );

  const renderDatePicker = ({ name, id, label, placeholder }) => (
    <Margin bottom="24" key={id}>
      <Field label={label} id={id} value={filters[name]} clear={() => handleChangeValue(name)('')}>
        <Datepicker
          id={id}
          name={name}
          onChange={handleChangeValue(name)}
          placeholderText={placeholder}
          selected={filters[name]}
          dateFormat="dd/MM/yyyy"
        />
      </Field>
    </Margin>
  );

  const renderSelect = ({ name, id, label, placeholder, options }) => {
    const selected = find(options, { value: filters[name] }) || null;
    return (
      <Margin bottom="24" key={id}>
        <Field label={label} id={id} value={selected} clear={() => handleChangeValue(name)('')}>
          <Select
            id={id}
            name={name}
            placeholder={placeholder}
            value={selected}
            onChange={handleChangeValue(name)}
            options={options}
          />
        </Field>
      </Margin>
    );
  };

  const renderMultiSelect = ({ name, id, label, placeholder, options }) => {
    const selected =
      filters[name] && filters[name].length
        ? options.filter((option) => filters[name].includes(option.value))
        : null;
    return (
      <Margin bottom="24" key={id}>
        <Field label={label} id={id} value={selected} clear={() => handleChangeValue(name)([])}>
          <Select
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={handleChangeValue(name, 'multi-select')}
            options={options}
            value={selected}
            isClearable={false}
            isMulti
            closeMenuOnSelect={false}
          />
        </Field>
      </Margin>
    );
  };

  const renderCheckbox = ({ name, id, label, options, isOpen }) => (
    <Margin bottom="24" key={id}>
      <Dropdown
        openOnLoad={isOpen === undefined ? true : isOpen}
        title={
          <Heading as="h4" size="h6">
            {label}
          </Heading>
        }
      >
        {options.map((option, index) => (
          <Margin bottom="8" key={index}>
            <Checkbox
              onChange={handleChangeValue(name, 'multi', option.value)}
              selected={filters[name].includes(option.value)}
            >
              {option.label}
            </Checkbox>
          </Margin>
        ))}
      </Dropdown>
    </Margin>
  );

  const renderTags = ({ name, id, label, options }) => {
    return (
      <Margin bottom="24" key={id}>
        <Dropdown
          openOnLoad
          title={
            <Heading as="h4" size="h6">
              {label}
            </Heading>
          }
        >
          <SelectableTags
            onChange={(value, checked) => handleChangeValue(name, 'multi', value)(checked)}
            tags={options.map((option) => {
              option.selected = filters[name].includes(option.value);

              return option;
            })}
          />
        </Dropdown>
      </Margin>
    );
  };

  const renderFields = () => {
    return fields.map((field) => {
      switch (field.type) {
        case 'input':
          return renderInput(field);
        case 'datepicker':
          return renderDatePicker(field);
        case 'select':
          return renderSelect(field);
        case 'multi-select':
          return renderMultiSelect(field);
        case 'checkbox':
          return renderCheckbox(field);
        case 'tags':
          return renderTags(field);
        default:
          return <></>;
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClickApplyFilters();

    if (scrollAfterFiltersRef && scrollAfterFiltersRef.current) {
      window.scrollTo({ top: scrollAfterFiltersRef.current.offsetTop - 200 });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {renderFields()}
        <Margin>
          <Row>
            <Col size={{ md: 1 / 2 }}>
              <Button type="submit" onClick={handleSubmit}>
                {t('global.filterButton')}
              </Button>
            </Col>
            {hasFilters() && (
              <Margin as={Col} top={{ xs: 16, md: 0 }} size={{ md: 1 / 2 }}>
                <Button type="button" bordered onClick={onClickRemoveAllFilters}>
                  {t('global.filterClearButton')}
                </Button>
              </Margin>
            )}
          </Row>
        </Margin>
      </form>
    </>
  );
};

Filters.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  filters: PropTypes.shape({}).isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onClickApplyFilters: PropTypes.func.isRequired,
  onClickRemoveAllFilters: PropTypes.func.isRequired,
  scrollAfterFiltersRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

Filters.defaultProps = {
  fields: [],
  scrollAfterFiltersRef: null,
};

export default Filters;
