import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  PageContent,
  Container,
  Header,
  Row,
  Col,
  Filters,
  Dropdown,
  Heading,
  FiltersControls,
  Pagination,
  SeoSection,
  BoxList,
  Spinner,
} from 'components';
import { connect } from 'react-redux';
import { Padding, Margin } from 'styled-components-spacing';
import {
  loadListCreator,
  setListPageCreator,
  setListPerPageCreator,
  setListSortCreator,
  setListFiltersCreator,
  removeListFiltersCreator,
} from 'store/actionsCreators';
import { globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.organisations',
    url: '/organisations',
  },
];

const OrganisationsList = ({
  appType,
  loading,
  list,
  loadList,
  setPage,
  setPerPage,
  setSort,
  setFilters,
  removeFilters,
}) => {
  const { filters, items, page, pages, perPage, sort } = list;
  const { t } = useTranslation();
  const scrollAfterFiltersRef = useRef(null);

  useEffect(() => {
    setPage(1);
    loadList();
  }, [perPage, sort]);

  const renderHeaderActions = () => {
    switch (appType) {
      case 'user':
        return ({ Button: ButtonRender }) => (
          <ButtonRender secondary as={Link} to="/user/organisations/create">
            {t('page.listOrganisations.createButton')}
          </ButtonRender>
        );
      case 'organisation':
        return ({ Button: ButtonRender }) => (
          <ButtonRender secondary as={Link} to="/organisation/profile">
            {t('page.listOrganisations.myOrganisationButton')}
          </ButtonRender>
        );
      default:
        return null;
    }
  };

  const filtersFields = [
    {
      type: 'input',
      name: 'name',
      id: 'f-name',
      label: t('page.listOrganisations.nameLabel'),
      placeholder: t('page.listOrganisations.namePlaceholder'),
    },
    {
      type: 'input',
      name: 'location',
      id: 'f-location',
      label: t('page.listOrganisations.locationLabel'),
      placeholder: t('page.listOrganisations.locationPlaceholder'),
    },
  ];

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.listOrganisations.title')}
        renderActions={renderHeaderActions()}
      />
      <PageContent>
        <Container>
          <Row>
            <Col size={{ xl: 1 / 3 }}>
              <Padding all={{ xs: 12, xl: 20 }} className="border-xs">
                <Dropdown
                  openbreakpoint="xl"
                  title={
                    <Heading as="h3" size="h4">
                      {t('page.listOrganisations.filtersTitle')}
                    </Heading>
                  }
                >
                  <Filters
                    fields={filtersFields}
                    filters={filters}
                    onChangeFilter={(payload) => setFilters(payload)}
                    onClickApplyFilters={() => {
                      setPage(1);
                      loadList();
                    }}
                    onClickRemoveAllFilters={() => removeFilters()}
                    scrollAfterFiltersRef={scrollAfterFiltersRef}
                  />
                </Dropdown>
              </Padding>
            </Col>
            <Margin as={Col} size={{ xl: 2 / 3 }} top={{ xs: 16, xl: 0 }}>
              <FiltersControls
                perPageSelected={perPage}
                perPageOnChange={(payload) => setPerPage(payload.value)}
                sortBySelected={sort}
                sortByOnChange={(payload) => setSort(payload.value)}
              />
              {loading ? <Spinner /> : null}
              {!loading ? (
                <div ref={scrollAfterFiltersRef}>
                  <BoxList
                    type="organisation"
                    items={items}
                    alert={t('global.noResultsFound')}
                    headerAs="h2"
                  />
                  <Pagination
                    styled
                    currentPage={page}
                    totalPages={pages}
                    onChange={(payload) => {
                      setPage(payload);
                      loadList();
                    }}
                  />
                </div>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
      <SeoSection title={t('page.listOrganisations.seoTitle')}>
        <p className="text-18 font-weight-medium text-gray-200 text-center">
          {t('page.listOrganisations.seoContent')}
        </p>
      </SeoSection>
    </>
  );
};

OrganisationsList.propTypes = {
  appType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  list: globalPropTypes.ListPropsTypes.isRequired,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  removeFilters: PropTypes.func.isRequired,
};

OrganisationsList.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ user, loading, list: { organisations: list } }) => ({
  appType: user.appType,
  loading: isLoading(loading, 'LOAD_LIST_ORGANISATIONS'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: () => dispatch(loadListCreator('organisations')),
  setPage: (payload) => dispatch(setListPageCreator('organisations', payload)),
  setPerPage: (payload) => dispatch(setListPerPageCreator('organisations', payload)),
  setSort: (payload) => dispatch(setListSortCreator('organisations', payload)),
  setFilters: (payload) => dispatch(setListFiltersCreator('organisations', payload)),
  removeFilters: () => dispatch(removeListFiltersCreator('organisations')),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsList);
