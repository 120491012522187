import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCurrentOrganisationsCreator } from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import Mobile from 'components/Navbar/Mobile/Mobile';
import Desktop from 'components/Navbar/Desktop/Desktop';
import { globalRoutes, userRoutes } from 'router/navbarRoutes';
import { checkPermissions, globalPropTypes, getFlatRoutes, findRoute } from 'helpers';
import { HelpCenter, CisModal } from 'shared';
import { GlobalStyle, Wrapper } from './Navbar.styled';

const Navbar = ({
  appType,
  userPermissions,
  routes,
  maInitials,
  ycInitials,
  username,
  organisations,
  currentOrganisation,
  setCurrentOrganisations,
}) => {
  const { t } = useTranslation();
  const flatRoutes = useMemo(() => getFlatRoutes(routes), routes);
  const [helpCenter, setHelpCenter] = useState(null);
  const [cisModal, setCisModal] = useState(null);

  const getUserRoutes = () => {
    let org = currentOrganisation;
    if (appType !== 'organisation') {
      org = null;
      setCurrentOrganisations(null);
    }
    const userRoutesAppType = userRoutes(
      t,
      organisations.filter((item) => item.id !== parseInt(org, 10)),
      org,
      maInitials,
      ycInitials,
      username,
    )[appType];

    if (userPermissions) {
      const routeTypes = ['links', 'actions'];
      routeTypes.forEach((type) => {
        userRoutesAppType[type] = userRoutesAppType[type].filter((link) => {
          const routeData = findRoute(flatRoutes, link.path);
          return routeData ? checkPermissions(routeData.permissions, userPermissions) : true;
        });
      });
    }
    return userRoutesAppType;
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        {/* <Alert /> */}
        <Mobile
          appType={appType}
          globalRoutes={globalRoutes(t)}
          userRoutes={getUserRoutes()}
          openHelpCenter={(type) => setHelpCenter(type)}
          openCisModal={(type) => setCisModal(type)}
        />
        <Desktop
          appType={appType}
          globalRoutes={globalRoutes(t)}
          userRoutes={getUserRoutes()}
          openHelpCenter={(type) => setHelpCenter(type)}
          openCisModal={(type) => setCisModal(type)}
        />
        <HelpCenter openType={helpCenter} closeFn={() => setHelpCenter(null)} />
        <CisModal openType={cisModal} closeFn={() => setCisModal(null)} />
      </Wrapper>
    </>
  );
};

Navbar.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes).isRequired,
  appType: PropTypes.string.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
  maInitials: PropTypes.string.isRequired,
  ycInitials: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  organisations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  currentOrganisation: PropTypes.number.isRequired,
  setCurrentOrganisations: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({
  appType: user.appType,
  userPermissions: user.userPermissions,
  maInitials: user?.user?.memberAssociation?.initials,
  ycInitials: user?.user?.youngCrew?.initials,
  username: `${user?.user?.firstName} ${user?.user?.lastName}`,
  organisations: user.organisations,
  currentOrganisation: user.currentOrganisation,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentOrganisations: (payload) => dispatch(setCurrentOrganisationsCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
