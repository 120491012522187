import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import draftToHtml from 'draftjs-to-html';

import clearErrors from 'helpers/clearErrors';

import { Margin } from 'styled-components-spacing';
import {
  Alerts,
  Button,
  Col,
  Container,
  Checkbox,
  Field,
  FileInput,
  Header,
  Heading,
  PageContent,
  Radio,
  Row,
  Select,
  Editor,
  Download,
} from 'components';
import { useTranslation } from 'react-i18next';
import {
  loadGlobalCourseAttributesCreator,
  createCourseCreator,
  loadUserOrganisationPeopleCreator,
  setCreateCourseErrorsCreator,
  setNewLoadingCreator,
  removeLoadingCreator,
  updateCourseDraftCreator,
  updateSubmittedTemplateCourseCreator,
} from 'store/actionsCreators';
import { useRouteMatch, useParams } from 'react-router-dom';
import { getCourse } from 'api/services/course';
import { getFileValue, globalPropTypes } from 'helpers';
import { get, has } from 'lodash';
import parseFromDraft, { parseToSelectOption } from './parseFromDraft';
import { Error } from '../../../../../components/Field/Field.styled';

momentDurationFormatSetup(moment);

const COURSE_DRAFT_STATUS = 'drafts';
const COURSE_SUBM_TEMP_STATUS = 'submittedTemplates';

function Create({
  loadCourseAttributes,
  loadOrganisationPeople,
  countries,
  languages,
  courseAttributes,
  organisation,
  loading,
  createCourse,
  errors,
  removeError,
  setGlobalLoading,
  removeGlobalLoading,
  updateCourseDraft,
  updateSubmittedTemplateCourse,
  history,
}) {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const [courseStatus, setCourseStatus] = useState(null);
  const { organizationId } = useParams();

  const breadcrumbs = [
    {
      title: 'navigation.breadcrumbs.organisation.courses',
      url: `/organisation/${organizationId}/courses`,
    },
    {
      title: 'navigation.breadcrumbs.organisation.createCourse',
      url: `/organisation/${organizationId}/courses/create`,
    },
  ];

  const [courseData, setCourseData] = useState({
    name: '',
    mainLanguage: null,
    secondaryLanguage: null,

    deliveryMode: null,
    type: null,

    city: '',
    line1: '',
    line2: '',
    country: null,
    postCode: '',

    telephone: '',
    email: '',
    website: '',
    responsiblePerson: null,

    shortDescription: '',
    fullDescription: '',

    instructionFormats: [],
    customInstructionFormat: '',

    preparationTrainingCourse: false,
    perspectiveCompetencies: [],
    peopleCompetencies: [],
    practiceCompetencies: [],
    customCompetencies: [''],
    duration: '',

    programe: '',

    learningOutcomes: [''],

    materials: [''],

    legalStatement: null,
    accreditation: null,

    keyExpert: null,
    keyExpertCv: null,

    selfAssesorName: '',
    selfAssesorEmail: '',
    selfAssesorCvFile: null,

    courseProgrammeStatementFile: null,

    cloudTags: [],
  });

  const [canUpdateDuration, setCanUpdateDuration] = useState(undefined);
  const [switchPreparationTrainingCourse, setSwitchPreparationTrainingCourse] = useState(undefined);

  const fillOrganisationData = () => {
    let responsiblePersonObject = null;
    if (
      organisation.personResponsible &&
      organisation.personResponsible.id &&
      organisation.courseResponsiblePeople &&
      Array.isArray(organisation.courseResponsiblePeople)
    ) {
      responsiblePersonObject = organisation.courseResponsiblePeople.find(
        (item) => item.user && item.user.id && item.user.id === organisation.personResponsible.id,
      );
    }
    setCourseData((prev) => {
      return {
        ...prev,
        city: get(organisation, 'address.city') || '',
        line1: get(organisation, 'address.line1') || '',
        line2: get(organisation, 'address.line2') || '',
        country: has(organisation, 'address.country')
          ? parseToSelectOption(organisation.address.country)
          : null,
        postCode: get(organisation, 'address.postCode') || '',
        telephone: organisation.telephone || '',
        email: organisation.email || '',
        website: organisation.website || '',
        responsiblePerson: organisation.personResponsible
          ? {
              label: `${responsiblePersonObject.user.firstName} ${responsiblePersonObject.user.lastName}`,
              value: responsiblePersonObject.id,
            }
          : null,
      };
    });
  };

  const getDurationFromCompetence = () => {
    return (
      [
        ...courseData.perspectiveCompetencies,
        ...courseData.peopleCompetencies,
        ...courseData.practiceCompetencies,
        ...courseData.customCompetencies.filter((item) => item.length),
      ].length * 2
    );
  };

  const updateDuration = () => {
    setCourseData((prev) => ({
      ...prev,
      duration: String(getDurationFromCompetence().toFixed(1)),
    }));
  };

  useEffect(() => {
    if (params.id) {
      setGlobalLoading();
      getCourse(params.id)
        .then((response) => response.json())
        .then(
          ({
            results: {
              result: { entity: course },
            },
          }) => {
            setCourseStatus(course.courseState);
            setCourseData(parseFromDraft(course));
            setCanUpdateDuration(true);
            removeError({});
          },
        )
        .finally(() => {
          removeGlobalLoading();
        })
        .catch(() => {
          history.replace(`/organisation/${organizationId}/courses`);
        });
    } else {
      fillOrganisationData();
      setCanUpdateDuration(true);
    }
  }, []);

  useEffect(() => {
    loadCourseAttributes();
    loadOrganisationPeople(organizationId);
  }, []);

  useEffect(() => {
    if (courseData.preparationTrainingCourse) {
      setCourseData((prev) => ({
        ...prev,
        perspectiveCompetencies: courseAttributes.perspectiveCompetencies.map((item) => item.value),
        peopleCompetencies: courseAttributes.peopleCompetencies.map((item) => item.value),
        practiceCompetencies: courseAttributes.practiceCompetencies.map((item) => item.value),
        customCompetencies: [''],
      }));
    } else {
      setCourseData((prev) => ({
        ...prev,
        perspectiveCompetencies: [],
        peopleCompetencies: [],
        practiceCompetencies: [],
      }));
    }
  }, [switchPreparationTrainingCourse]);

  useEffect(() => {
    if (canUpdateDuration) {
      updateDuration();
    }
  }, [
    courseData.perspectiveCompetencies,
    courseData.peopleCompetencies,
    courseData.practiceCompetencies,
    courseData.customCompetencies,
  ]);

  const handleDurationTime = () => {
    return moment.duration(moment.duration(courseData.duration, 'hours')).format('HH[h] mm[m]');
  };

  const handleChangeNative = (name, errorName, helper) => (e) => {
    const { value } = e.target;
    setCourseData((prev) => ({ ...prev, [name]: value }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
    if (helper) helper(e.target.value);
  };

  const handleChangeCustom = (name, errorName) => (value) => {
    setCourseData((prev) => ({ ...prev, [name]: value }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeType = (value) => {
    setCourseData((prev) => ({ ...prev, type: value }));
    if (errors.courseType) removeError(clearErrors(errors, 'courseType'));
  };

  const handleChangeMultiCustom = (name, errorName, id) => (value) => {
    setCourseData((prev) => ({
      ...prev,
      [name]: value ? [...courseData[name], id] : courseData[name].filter((item) => item !== id),
    }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeFileNative = (name, errorName) => (e) => {
    const { files } = e.target;

    setCourseData((prev) => ({
      ...prev,
      [name]: files && files.length ? files[0] : null,
    }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleClearFileNative = (name) => () => {
    setCourseData((prev) => ({
      ...prev,
      [name]: null,
    }));
  };

  const handleChangeDynamicNative = (name, errorName, index) => (e) => {
    const { value } = e.target;
    setCourseData((prevState) => ({
      ...prevState,
      [name]: courseData[name].reduce((prev, current, i) => {
        if (index !== i) return [...prev, current];
        return [...prev, value];
      }, []),
    }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleRemoveDynamicNative = (name, index, oneRequired = false) => {
    const data = courseData[name];

    if (Array.isArray(data)) {
      data.splice(index, 1);

      if (oneRequired && data.length === 0) {
        data.push('');
      }

      setCourseData((prev) => ({
        ...prev,
        [name]: data,
      }));
    }
  };

  const validateDuration = () => {
    if (!courseData.preparationTrainingCourse) {
      const { duration } = courseData;
      const durationFromCompetence = getDurationFromCompetence();

      const fDuration = parseFloat(duration);
      const fDurationFromCompetence = parseFloat(durationFromCompetence);

      setCourseData((prev) => ({
        ...prev,
        duration:
          fDuration > parseFloat(fDurationFromCompetence)
            ? String(fDuration.toFixed(1))
            : String(fDurationFromCompetence.toFixed(1)),
      }));
    }
  };

  const isStatus = (status) => {
    if (!courseStatus) return false;

    return courseStatus.name === status;
  };

  const isType = (slug) => {
    if (!courseData.type) return false;

    return courseData.type.slug === slug;
  };

  const submit = (asDraft = false) => {
    const formData = new FormData();
    formData.append('name', courseData.name);
    formData.append('description', courseData.fullDescription);
    formData.append('shortDescription', courseData.shortDescription);
    formData.append('contactEmail', courseData.email);
    formData.append('phone', courseData.telephone);
    formData.append('website', courseData.website);
    formData.append(
      'responsiblePerson',
      courseData.responsiblePerson ? courseData.responsiblePerson.value : '',
    );
    formData.append('programmeDescription', courseData.programe);

    courseData.learningOutcomes
      .filter((el, index) => index === 0 || !!el)
      .forEach((item, index) =>
        formData.append(`courseLearningOutcomes[${index}][description]`, item),
      );
    courseData.materials
      .filter((el, index) => index === 0 || !!el)
      .forEach((item, index) => formData.append(`courseKeyMaterials[${index}][description]`, item));

    if (isType('training')) {
      formData.append('courseFile1File', getFileValue(courseData.legalStatement));
    }

    if (isType('education')) {
      formData.append('courseFile2File', getFileValue(courseData.accreditation));
    }

    formData.append('deliveryMode[]', courseData.deliveryMode || '');
    formData.append('courseType[]', courseData.type ? courseData.type.value : null);
    courseData.instructionFormats.filter(Boolean).forEach((item, index) => {
      formData.append(`instructionFormat[${index}]`, item);
    });
    if (courseData.customInstructionFormat.length) {
      formData.append('customInstructionFormat', courseData.customInstructionFormat);
    }

    formData.append('preparationTrainingCourse', courseData.preparationTrainingCourse);

    courseData.perspectiveCompetencies.forEach((item, index) => {
      formData.append(`competency[${index}]`, item);
    });
    courseData.peopleCompetencies.forEach((item, index) => {
      formData.append(`peopleCompetency[${index}]`, item);
    });
    courseData.practiceCompetencies.forEach((item, index) => {
      formData.append(`practiceCompetency[${index}]`, item);
    });

    if (!courseData.preparationTrainingCourse) {
      courseData.customCompetencies.forEach((item, index) => {
        formData.append(`customCourseCompetencies[${index}][name]`, item);
      });
    }

    courseData.cloudTags.forEach((item, index) => formData.append(`tags[${index}]`, item));

    formData.append('duration', courseData.duration);
    formData.append('mainLanguage', courseData.mainLanguage ? courseData.mainLanguage.value : '');
    formData.append(
      'secondaryLanguage',
      courseData.secondaryLanguage ? courseData.secondaryLanguage.value : '',
    );

    formData.append('address[city]', courseData.city);
    formData.append('address[line1]', courseData.line1);
    formData.append('address[line2]', courseData.line2);
    formData.append('address[country]', courseData.country ? courseData.country.value : '');
    formData.append('address[postCode]', courseData.postCode);

    formData.append('keyExperts[0][user]', courseData.keyExpert ? courseData.keyExpert.value : '');
    formData.append('keyExperts[0][keyExpertCv]', getFileValue(courseData.keyExpertCv));

    formData.append('selfAssesorName', courseData.selfAssesorName);
    formData.append('selfAssesorEmail', courseData.selfAssesorEmail);
    formData.append('selfAssesorCvFile', getFileValue(courseData.selfAssesorCvFile));
    formData.append(
      'courseProgrammeStatementFile',
      getFileValue(courseData.courseProgrammeStatementFile),
    );

    if (!courseStatus || (isStatus(COURSE_DRAFT_STATUS) && !asDraft)) {
      if (courseStatus) {
        formData.append('id', params.id);
      }

      formData.append('organizationId', organizationId);
      createCourse(formData, asDraft, history);
    } else if (isStatus(COURSE_DRAFT_STATUS)) {
      updateCourseDraft({ id: params.id, body: formData }, history);
    } else if (isStatus(COURSE_SUBM_TEMP_STATUS)) {
      updateSubmittedTemplateCourse({ id: params.id, body: formData }, history);
    }
  };

  const draft = () => {
    submit(true);
  };

  const parseKeyExperts = useCallback(
    () =>
      courseAttributes.expertKeys.map(({ user: item }) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
    [courseAttributes.expertKeys],
  );

  const parseResponsiblePersons = useCallback(
    () =>
      courseAttributes.expertKeys.map(({ id, user: item }) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: id,
      })),
    [courseAttributes.expertKeys],
  );

  if (loading) return null;

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.organisation.createCourse.title')} />
      <Container>
        <PageContent>
          <Alerts type="organisation" />
          <form onSubmit={(e) => e.preventDefault()}>
            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.organisation.createCourse.basicInformation')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    id="course-name"
                    label={t('page.organisation.createCourse.nameLabel')}
                    required
                    error={errors.name}
                  >
                    <input
                      id="course-name"
                      type="text"
                      placeholder={t('page.organisation.createCourse.namePlaceholder')}
                      defaultValue={courseData.name}
                      onBlur={handleChangeNative('name', 'name')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="main-language"
                    label={t('page.organisation.createCourse.mainLanguageLabel')}
                    required
                    error={errors.mainLanguage}
                    value={courseData.mainLanguage}
                    clear={() => handleChangeCustom('mainLanguage', 'mainLanguage')('')}
                  >
                    <Select
                      id="main-language"
                      options={languages}
                      value={courseData.mainLanguage}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeCustom('mainLanguage', 'mainLanguage')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="secondary-language"
                    label={t('page.organisation.createCourse.secondaryLanguageLabel')}
                    error={errors.secondaryLanguage}
                    value={courseData.secondaryLanguage}
                    clear={() => handleChangeCustom('secondaryLanguage', 'secondaryLanguage')('')}
                  >
                    <Select
                      id="secondary-language"
                      options={languages}
                      value={courseData.secondaryLanguage}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeCustom('secondaryLanguage', 'secondaryLanguage')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    id="delivery-mode"
                    label={t('page.organisation.createCourse.modeOfDeliveryLabel')}
                    required
                    error={errors.deliveryMode}
                  >
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {courseAttributes.deliveryModes.map((el) => (
                        <Col
                          key={`delivery-mode-${el.value}`}
                          size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}
                        >
                          <Margin bottom="8">
                            <Radio
                              onClick={() =>
                                handleChangeCustom('deliveryMode', 'deliveryMode')(el.value)
                              }
                              selected={courseData.deliveryMode === el.value}
                            >
                              {el.label}
                            </Radio>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    id="course-type"
                    label={t('page.organisation.createCourse.typeOfCourseLabel')}
                    required
                    error={errors.courseType}
                  >
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {courseAttributes.courseTypes.map((el) => (
                        <Col
                          key={`course-type-${el.value}`}
                          size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}
                        >
                          <Margin bottom="8">
                            <Radio
                              onClick={() => handleChangeType(el)}
                              selected={
                                courseData.type ? courseData.type.value === el.value : false
                              }
                            >
                              {el.label}
                            </Radio>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.organisation.createCourse.contactData')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="line1"
                    label={t('global.addressLineLabel1')}
                    error={errors['address.line1']}
                  >
                    <input
                      id="line1"
                      type="text"
                      placeholder={t('global.addressLinePlaceholder1')}
                      defaultValue={courseData.line1}
                      onBlur={handleChangeNative('line1', 'address[line1]')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="line2"
                    label={t('global.addressLineLabel2')}
                    error={errors['address.line2']}
                  >
                    <input
                      id="line2"
                      type="text"
                      placeholder={t('global.addressLinePlaceholder2')}
                      defaultValue={courseData.line2}
                      onBlur={handleChangeNative('line2', 'address[line2]')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field id="city" label={t('global.cityLabel')} error={errors['address.city']}>
                    <input
                      id="city"
                      type="text"
                      placeholder={t('global.cityPlaceholder')}
                      defaultValue={courseData.city}
                      onBlur={handleChangeNative('city', 'address[city]')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="post-code"
                    label={t('global.postCodeLabel')}
                    error={errors['address.postCode']}
                  >
                    <input
                      id="post-code"
                      type="text"
                      placeholder={t('global.postCodePlaceholder')}
                      defaultValue={courseData.postCode}
                      onBlur={handleChangeNative('postCode', 'address[postCode]')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="country"
                    label={t('global.countryLabel')}
                    error={errors['address.country']}
                    value={courseData.country}
                    clear={() => handleChangeCustom('country', 'address[country]')('')}
                  >
                    <Select
                      id="country"
                      options={countries}
                      value={courseData.country}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeCustom('country', 'address[country]')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field id="phone-number" label={t('global.phoneLabel')} error={errors.phone}>
                    <input
                      id="phone-number"
                      type="text"
                      placeholder={t('global.phonePlaceholder')}
                      defaultValue={courseData.telephone}
                      onBlur={handleChangeNative('telephone', 'phone')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="e-mail"
                    label={t('global.emailLabel')}
                    required
                    error={errors.contactEmail}
                  >
                    <input
                      id="e-mail"
                      type="text"
                      placeholder={t('global.emailPlaceholder')}
                      defaultValue={courseData.email}
                      onBlur={handleChangeNative('email', 'contactEmail')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="website"
                    label={t('global.websiteLabel')}
                    required
                    error={errors.website}
                  >
                    <input
                      id="website"
                      type="text"
                      placeholder={t('global.websitePlaceholder')}
                      defaultValue={courseData.website}
                      onBlur={handleChangeNative('website', 'website')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    id="responsiblePerson"
                    label={t('global.personResponsible')}
                    error={errors.responsiblePerson}
                    required
                    value={courseData.responsiblePerson}
                    clear={() => handleChangeCustom('responsiblePerson', 'responsiblePerson')('')}
                  >
                    <Select
                      id="responsiblePerson"
                      options={parseResponsiblePersons()}
                      value={courseData.responsiblePerson}
                      onChange={handleChangeCustom('responsiblePerson', 'responsiblePerson')}
                    />
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.organisation.createCourse.description')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="short-description"
                    label={t('page.organisation.createCourse.descriptionShortLabel')}
                    required
                    error={errors.shortDescription}
                  >
                    <textarea
                      id="short-description"
                      placeholder={t('page.organisation.createCourse.descriptionShortPlaceholder')}
                      defaultValue={courseData.shortDescription}
                      onBlur={handleChangeNative('shortDescription', 'shortDescription')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="full-description"
                    label={t('page.organisation.createCourse.descriptionFullLabel')}
                    required
                    error={errors.description}
                  >
                    <textarea
                      id="full-description"
                      placeholder={t('page.organisation.createCourse.descriptionFullPlaceholder')}
                      defaultValue={courseData.fullDescription}
                      onBlur={handleChangeNative('fullDescription', 'description')}
                    />
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    label={t('forms.formatOfInstructionLabel')}
                    required
                    error={errors.instructionFormat}
                  >
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {courseAttributes.instructionFormats.map((el) => (
                        <Col
                          key={`formats-instruction-${el.value}`}
                          size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}
                        >
                          <Margin bottom="8">
                            <Checkbox
                              onChange={handleChangeMultiCustom(
                                'instructionFormats',
                                'instructionFormat',
                                el.value,
                              )}
                              selected={courseData.instructionFormats.includes(el.value)}
                            >
                              {el.label}
                            </Checkbox>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field id="formats-custom" label={t('forms.customFormatLabel')}>
                    <input
                      id="formats-custom"
                      type="text"
                      placeholder={t('forms.customFormatPlaceholder')}
                      defaultValue={courseData.customInstructionFormat}
                      onBlur={handleChangeNative(
                        'customInstructionFormat',
                        'customInstructionFormat',
                      )}
                    />
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">
                  {t('page.organisation.createCourse.courseCompetenceAreas')}
                </Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    label={t('page.organisation.createCourse.preparationTrainingCourseLabel')}
                    required
                    description={t('page.organisation.createCourse.preparationTrainingCourseDesc')}
                  >
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      <Col size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}>
                        <Margin bottom="8">
                          <Radio
                            onClick={() => {
                              handleChangeCustom(
                                'preparationTrainingCourse',
                                'preparationTrainingCourse',
                              )(true);
                              setSwitchPreparationTrainingCourse(true);
                            }}
                            selected={courseData.preparationTrainingCourse}
                          >
                            Yes
                          </Radio>
                        </Margin>
                      </Col>
                      <Col size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}>
                        <Margin bottom="8">
                          <Radio
                            onClick={() => {
                              handleChangeCustom(
                                'preparationTrainingCourse',
                                'preparationTrainingCourse',
                              )(false);
                              setSwitchPreparationTrainingCourse(false);
                            }}
                            selected={!courseData.preparationTrainingCourse}
                          >
                            No
                          </Radio>
                        </Margin>
                      </Col>
                    </Margin>
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    label={t('page.organisation.createCourse.perspectiveCompetenciesLabel')}
                    required
                    error={errors.competency}
                  >
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {courseAttributes.perspectiveCompetencies.map((el) => (
                        <Col key={`perspective-${el.value}`} size={{ md: 1 / 4, xl: 1 / 6 }}>
                          <Margin bottom="8">
                            <Checkbox
                              onChange={handleChangeMultiCustom(
                                'perspectiveCompetencies',
                                'competency',
                                el.value,
                                updateDuration,
                              )}
                              selected={courseData.perspectiveCompetencies.includes(el.value)}
                            >
                              {el.label}
                            </Checkbox>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    label={t('page.organisation.createCourse.peopleCompetenciesLabel')}
                    required
                    error={errors.peopleCompetency}
                  >
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {courseAttributes.peopleCompetencies.map((el) => (
                        <Col key={`people-competence-${el.value}`} size={{ md: 1 / 4, xl: 1 / 6 }}>
                          <Margin bottom="8">
                            <Checkbox
                              onChange={handleChangeMultiCustom(
                                'peopleCompetencies',
                                'peopleCompetency',
                                el.value,
                                updateDuration,
                              )}
                              selected={courseData.peopleCompetencies.includes(el.value)}
                            >
                              {el.label}
                            </Checkbox>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    label={t('page.organisation.createCourse.practiceCompetenciesLabel')}
                    required
                    error={errors.practiceCompetency}
                  >
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {courseAttributes.practiceCompetencies.map((el) => (
                        <Col
                          key={`practice-competence-${el.value}`}
                          size={{ md: 1 / 4, xl: 1 / 6 }}
                        >
                          <Margin bottom="8">
                            <Checkbox
                              onChange={handleChangeMultiCustom(
                                'practiceCompetencies',
                                'practiceCompetency',
                                el.value,
                                updateDuration,
                              )}
                              selected={courseData.practiceCompetencies.includes(el.value)}
                            >
                              {el.label}
                            </Checkbox>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
              {!courseData.preparationTrainingCourse ? (
                <Margin bottom="20">
                  <p style={{ fontSize: 14 }}>
                    <strong>{t('page.organisation.createCourse.cantFindCompetencies')}</strong>
                  </p>
                  {courseData.customCompetencies.map((competency, index) => (
                    <Margin top="20" as={Row} key={`customCompetencies[${index}][${competency}]`}>
                      <Col size={{ lg: 1 / 2 }}>
                        <Field
                          id={`competency-${index}`}
                          label={t('page.organisation.createCourse.competencyLabel', {
                            index: index + 1,
                          })}
                          value={competency}
                          clear={() => handleRemoveDynamicNative('customCompetencies', index, true)}
                        >
                          <input
                            id={`competency-${index}`}
                            type="text"
                            placeholder={t('page.organisation.createCourse.competencyPlaceholder')}
                            defaultValue={competency}
                            onBlur={handleChangeDynamicNative(
                              'customCompetencies',
                              'customCompetencies',
                              index,
                            )}
                          />
                        </Field>
                      </Col>
                    </Margin>
                  ))}
                  <Button
                    type="button"
                    onClick={() =>
                      setCourseData((prev) => ({
                        ...prev,
                        customCompetencies: [...courseData.customCompetencies, ''],
                      }))
                    }
                    auto="xs"
                    text
                    primaryDark
                  >
                    {t('page.organisation.createCourse.addAnotherCompetency')}
                  </Button>
                </Margin>
              ) : null}
              <Row>
                <Margin as={Col} size={{ lg: 1 / 2 }}>
                  <Row>
                    <Col size={{ xs: 4 / 6, md: 6 / 8 }}>
                      <Field
                        id="course-duration"
                        label={t('page.organisation.createCourse.courseDurationLabel')}
                        description={t('page.organisation.createCourse.courseDurationDescription')}
                        error={errors.duration}
                      >
                        <input
                          id="course-duration"
                          type="text"
                          placeholder={t(
                            'page.organisation.createCourse.courseDurationPlaceholder',
                          )}
                          value={courseData.duration}
                          onChange={handleChangeNative('duration', 'duration', handleDurationTime)}
                          onBlur={validateDuration}
                        />
                      </Field>
                    </Col>
                    <Col size={{ xs: 2 / 6, md: 2 / 8 }}>
                      <Margin top="38">
                        <p>{parseFloat(courseData.duration) > 0 ? handleDurationTime() : null}</p>
                      </Margin>
                    </Col>
                  </Row>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    label={t('page.organisation.createCourse.courseProgrameLabel')}
                    required
                    error={errors.programmeDescription}
                  >
                    <Editor
                      defaultValue={courseData.programe}
                      onChange={(value) =>
                        handleChangeCustom('programe', 'programmeDescription')(draftToHtml(value))
                      }
                      placeholder={t('page.organisation.createCourse.courseProgramePlaceholder')}
                    />
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">
                  {t('page.organisation.createCourse.expectedLearningOutcomes')}
                </Heading>
              </Margin>
              <Margin bottom="20">
                {courseData.learningOutcomes.map((outcome, index) => (
                  <Margin top="20" as={Row} key={`learning-outcome-${index}-${outcome}`}>
                    <Col size={{ lg: 1 / 2 }}>
                      <Field
                        id={`learning-outcome-${index}`}
                        label={t('page.organisation.createCourse.learningOutcomeLabel', {
                          index: index + 1,
                        })}
                        required
                        value={outcome}
                        clear={() => handleRemoveDynamicNative('learningOutcomes', index, true)}
                      >
                        <input
                          id={`learning-outcome-${index}`}
                          type="text"
                          placeholder={t(
                            'page.organisation.createCourse.learningOutcomePlaceholder',
                          )}
                          defaultValue={outcome}
                          onBlur={handleChangeDynamicNative(
                            'learningOutcomes',
                            'courseLearningOutcomes.description',
                            index,
                          )}
                        />
                      </Field>
                    </Col>
                  </Margin>
                ))}
                {errors['courseLearningOutcomes.description'] ? (
                  <Error>{errors['courseLearningOutcomes.description']}</Error>
                ) : null}
                <Button
                  type="button"
                  onClick={() =>
                    setCourseData((prev) => ({
                      ...prev,
                      learningOutcomes: [...courseData.learningOutcomes, ''],
                    }))
                  }
                  auto="xs"
                  text
                  primaryDark
                >
                  {t('page.organisation.createCourse.addAnotherExpectedOutcome')}
                </Button>
              </Margin>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">
                  {t('page.organisation.createCourse.keyLiteratureAndMaterials')}
                </Heading>
              </Margin>
              <Margin bottom="20">
                {courseData.materials.map((material, index) => (
                  <Margin top="20" as={Row} key={`materials-${index}-${material}`}>
                    <Col size={{ lg: 1 / 2 }}>
                      <Field
                        id={`materials-${index}`}
                        label={t('page.organisation.createCourse.materialLabel', {
                          index: index + 1,
                        })}
                        required
                        value={material}
                        clear={() => handleRemoveDynamicNative('materials', index, true)}
                      >
                        <textarea
                          id={`materials-${index}`}
                          type="text"
                          placeholder={t('page.organisation.createCourse.materialPlaceholder')}
                          defaultValue={material}
                          onBlur={handleChangeDynamicNative(
                            'materials',
                            'courseKeyMaterials.description',
                            index,
                          )}
                        />
                      </Field>
                    </Col>
                  </Margin>
                ))}
                {errors['courseKeyMaterials.description'] ? (
                  <Error>{errors['courseKeyMaterials.description']}</Error>
                ) : null}
                <Button
                  type="button"
                  onClick={() =>
                    setCourseData((prev) => ({ ...prev, materials: [...courseData.materials, ''] }))
                  }
                  auto="xs"
                  text
                  primaryDark
                >
                  {t('page.organisation.createCourse.addAnotherKeyMaterial')}
                </Button>
              </Margin>
            </Margin>

            {isType('training') || isType('education') ? (
              <Margin bottom={{ xs: 24, lg: 38 }}>
                <Margin bottom="24">
                  <Heading size="h5">{t('page.organisation.createCourse.courseFiles')}</Heading>
                </Margin>
                <Row>
                  {isType('training') ? (
                    <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                      <Field
                        id="legal-statement"
                        label={t('page.organisation.createCourse.legalStatementLabel')}
                        required
                        error={errors.courseFile1File}
                        description={t('global.uploadFileInfo')}
                      >
                        <FileInput
                          id="legal-statement"
                          placeholder={t('global.uploadPlaceholder')}
                          fileName={
                            courseData.legalStatement
                              ? courseData.legalStatement.name || courseData.legalStatement.fileName
                              : ''
                          }
                          clear={handleClearFileNative('legalStatement')}
                          onChange={handleChangeFileNative('legalStatement', 'courseFile1File')}
                        />
                      </Field>
                    </Margin>
                  ) : null}
                  {isType('education') ? (
                    <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                      <Field
                        id="accreditation"
                        label={t('page.organisation.createCourse.accreditationLabel')}
                        required
                        error={errors.courseFile2File}
                        description={t('global.uploadFileInfo')}
                      >
                        <FileInput
                          id="accreditation"
                          placeholder={t('global.uploadPlaceholder')}
                          fileName={
                            courseData.accreditation
                              ? courseData.accreditation.name || courseData.accreditation.fileName
                              : ''
                          }
                          clear={handleClearFileNative('accreditation')}
                          onChange={handleChangeFileNative('accreditation', 'courseFile2File')}
                        />
                      </Field>
                    </Margin>
                  ) : null}
                </Row>
              </Margin>
            ) : null}

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.organisation.createCourse.keyExperts')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 3 }}>
                  <Field
                    id="key-experts"
                    label={t('page.organisation.createCourse.keyExperts')}
                    required
                    error={errors['keyExperts.user']}
                    value={courseData.keyExpert}
                    clear={() => handleChangeCustom('keyExpert', 'keyExperts.user')('')}
                  >
                    <Select
                      id="key-experts"
                      placeholder={t('global.selectPlaceholder')}
                      value={courseData.keyExpert}
                      onChange={handleChangeCustom('keyExpert', 'keyExperts.user')}
                      options={parseKeyExperts()}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 3 }}>
                  <Field
                    id="exprt-cv"
                    label={t('page.organisation.createCourse.keyExpertCvLabel')}
                    required
                    error={errors['keyExperts.keyExpertCv']}
                    description={t('global.uploadFileInfo')}
                  >
                    <FileInput
                      id="exprt-cv"
                      placeholder={t('global.uploadPlaceholder')}
                      fileName={
                        courseData.keyExpertCv
                          ? courseData.keyExpertCv.name || courseData.keyExpertCv.fileName
                          : ''
                      }
                      clear={handleClearFileNative('keyExpertCv')}
                      onChange={handleChangeFileNative('keyExpertCv', 'keyExperts.keyExpertCv')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" top={{ lg: 22 }} as={Col} size={{ lg: 1 / 3 }}>
                  <Download
                    src={`${process.env.PUBLIC_URL}/files/Key Expert CV.docx`}
                    title="Key Expert CV"
                    download
                    downloadTitle="Download the template"
                  />
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.organisation.createCourse.selfAssessor')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="selfAssesorName"
                    label={t('page.organisation.createCourse.selfAssessorNameLabel')}
                    required
                    error={errors.selfAssesorName}
                  >
                    <input
                      id="selfAssesorName"
                      type="text"
                      placeholder={t('page.organisation.createCourse.selfAssessorNamePlaceholder')}
                      defaultValue={courseData.selfAssesorName}
                      onBlur={handleChangeNative('selfAssesorName', 'selfAssesorName')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="selfAssesorEmail"
                    label={t('global.emailLabel')}
                    required
                    error={errors.selfAssesorEmail}
                  >
                    <input
                      id="selfAssesorEmail"
                      type="text"
                      placeholder={t('global.emailPlaceholder')}
                      defaultValue={courseData.selfAssesorEmail}
                      onBlur={handleChangeNative('selfAssesorEmail', 'selfAssesorEmail')}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="selfAssesorCvFile"
                    label={t('page.organisation.createCourse.selfAssessorCvFileLabel')}
                    required
                    error={errors.selfAssesorCvFile}
                    description={t('global.uploadFileInfo')}
                  >
                    <FileInput
                      id="selfAssesorCvFile"
                      placeholder={t('global.uploadPlaceholder')}
                      fileName={
                        courseData.selfAssesorCvFile
                          ? courseData.selfAssesorCvFile.name ||
                            courseData.selfAssesorCvFile.fileName
                          : ''
                      }
                      clear={handleClearFileNative('selfAssesorCvFile')}
                      onChange={handleChangeFileNative('selfAssesorCvFile', 'selfAssesorCvFile')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" top={{ lg: 22 }} as={Col} size={{ lg: 1 / 3 }}>
                  <Download
                    src={`${process.env.PUBLIC_URL}/files/Self Assessor CV.docx`}
                    title="Self Assessor CV"
                    download
                    downloadTitle="Download the template"
                  />
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">
                  {t('page.organisation.createCourse.courseProgrammeStatementFileTitle')}
                </Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                  <Field
                    id="courseProgrammeStatementFile"
                    label={t('page.organisation.createCourse.courseProgrammeStatementFileLabel')}
                    required
                    error={errors.courseProgrammeStatementFile}
                    description={t('global.uploadFileInfo')}
                  >
                    <FileInput
                      id="courseProgrammeStatementFile"
                      placeholder={t('global.uploadPlaceholder')}
                      fileName={
                        courseData.courseProgrammeStatementFile
                          ? courseData.courseProgrammeStatementFile.name ||
                            courseData.courseProgrammeStatementFile.fileName
                          : ''
                      }
                      clear={handleClearFileNative('courseProgrammeStatementFile')}
                      onChange={handleChangeFileNative(
                        'courseProgrammeStatementFile',
                        'courseProgrammeStatementFile',
                      )}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" top={{ lg: 22 }} as={Col} size={{ lg: 1 / 3 }}>
                  <Download
                    src={`${process.env.PUBLIC_URL}/files/Course programme application statement.docx`}
                    title="Course/Programme application statement"
                    download
                    downloadTitle="Download the template"
                  />
                </Margin>
              </Row>
            </Margin>

            <Margin bottom={{ xs: 24, lg: 38 }}>
              <Margin bottom="24">
                <Heading size="h5">{t('page.organisation.createCourse.tags')}</Heading>
              </Margin>
              <Row>
                <Margin bottom="20" as={Col}>
                  <Field
                    label={t('page.organisation.createCourse.tagsLabel')}
                    required
                    error={errors.tags}
                  >
                    <Margin top="8" as={Row} style={{ width: '100%' }}>
                      {courseAttributes.cloudTags.map((el) => (
                        <Col
                          key={`tag-cloud-${el.value}`}
                          size={{ xs: 1 / 2, md: 1 / 4, xl: 1 / 6 }}
                        >
                          <Margin bottom="8">
                            <Checkbox
                              onChange={handleChangeMultiCustom('cloudTags', 'cloudTags', el.value)}
                              selected={courseData.cloudTags.includes(el.value)}
                            >
                              {el.label}
                            </Checkbox>
                          </Margin>
                        </Col>
                      ))}
                    </Margin>
                  </Field>
                </Margin>
              </Row>
            </Margin>

            <Row>
              <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
                <Margin bottom={{ xs: 12, md: 0 }}>
                  <Button type="button" onClick={() => submit()}>
                    {courseStatus && courseStatus.name === COURSE_SUBM_TEMP_STATUS
                      ? t('global.saveChangesButton')
                      : t('page.organisation.createCourse.publishCourseButton')}
                  </Button>
                </Margin>
              </Col>
              {!courseStatus || isStatus(COURSE_DRAFT_STATUS) ? (
                <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
                  <Margin bottom={{ xs: 12, md: 0 }}>
                    <Button type="button" onClick={draft} bordered>
                      {t('global.saveAsDraftButton')}
                    </Button>
                  </Margin>
                </Col>
              ) : null}
            </Row>
          </form>
        </PageContent>
      </Container>
    </>
  );
}

Create.propTypes = {
  loadCourseAttributes: PropTypes.func.isRequired,
  loadOrganisationPeople: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }))
    .isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }))
    .isRequired,
  courseAttributes: PropTypes.shape({
    deliveryModes: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
    ),
    courseTypes: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string, slug: PropTypes.string }),
    ),
    instructionFormats: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
    ),
    perspectiveCompetencies: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
    ),
    peopleCompetencies: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
    ),
    practiceCompetencies: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
    ),
    expertKeys: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
    ),
    cloudTags: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string }),
    ),
  }).isRequired,
  organisation: globalPropTypes.OrganisationProfileTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  createCourse: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    deliveryMode: PropTypes.string,
    description: PropTypes.string,
    keyExperts: PropTypes.string,
    mainLanguage: PropTypes.string,
    name: PropTypes.string,
    privacyPolicy: PropTypes.string,
    secondaryLanguage: PropTypes.string,
    shortDescription: PropTypes.string,
    tags: PropTypes.string,
    'keyExperts.keyExpertCv': PropTypes.string,
    'keyExperts.user': PropTypes.string,
    courseFile1File: PropTypes.string,
    courseFile2File: PropTypes.string,
    'courseKeyMaterials.description': PropTypes.string,
    'courseLearningOutcomes.description': PropTypes.string,
    programmeDescription: PropTypes.string,
    duration: PropTypes.string,
    practiceCompetency: PropTypes.string,
    competency: PropTypes.string,
    peopleCompetency: PropTypes.string,
    instructionFormat: PropTypes.string,
    'address.city': PropTypes.string,
    'address.line1': PropTypes.string,
    'address.line2': PropTypes.string,
    'address.country': PropTypes.string,
    'address.postCode': PropTypes.string,
    phone: PropTypes.string,
    contactEmail: PropTypes.string,
    website: PropTypes.string,
    courseType: PropTypes.string,
    responsiblePerson: PropTypes.string,
    selfAssesorName: PropTypes.string,
    selfAssesorEmail: PropTypes.string,
    selfAssesorCvFile: PropTypes.string,
    courseProgrammeStatementFile: PropTypes.string,
  }).isRequired,
  setGlobalLoading: PropTypes.func.isRequired,
  removeGlobalLoading: PropTypes.func.isRequired,
  updateCourseDraft: PropTypes.func.isRequired,
  updateSubmittedTemplateCourse: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};
Create.defaultProps = {};

const mapStateToProps = ({ globals, loading, course, organisation }) => ({
  countries: globals.countries,
  languages: globals.languages,
  courseAttributes: {
    ...globals.course,
    expertKeys: organisation.people.items,
  },
  organisation: organisation.profile,
  loading: !!loading.filter((item) => item.type === 'GLOBAL').length,
  errors: course.errors,
});

const mapDispatchToProps = (dispatch) => ({
  loadCourseAttributes: () => dispatch(loadGlobalCourseAttributesCreator()),
  createCourse: (payload, asDraft, history) =>
    dispatch(createCourseCreator(payload, asDraft, history)),
  updateCourseDraft: (payload, history) => dispatch(updateCourseDraftCreator(payload, history)),
  updateSubmittedTemplateCourse: (payload, history) =>
    dispatch(updateSubmittedTemplateCourseCreator(payload, history)),
  loadOrganisationPeople: (payload) => dispatch(loadUserOrganisationPeopleCreator(payload)),
  removeError: (payload) => dispatch(setCreateCourseErrorsCreator(payload)),
  setGlobalLoading: () => dispatch(setNewLoadingCreator({ type: 'GLOBAL' })),
  removeGlobalLoading: () => dispatch(removeLoadingCreator({ type: 'GLOBAL' })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
