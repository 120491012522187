import React from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing';
import { Heading } from 'components';
import User from 'components/UserList/User/User';
import { globalPropTypes } from 'helpers';
import { Wrapper, List, Item } from './UserList.styled';

const UserList = ({ title, users }) => {
  return (
    <Wrapper>
      {title && (
        <Margin bottom="16">
          <Heading as="h3" size="h5">
            {title}
          </Heading>
        </Margin>
      )}
      <List>
        {users.map((item, index) => (
          <Item key={index}>
            <User
              name={[item.firstName, item.lastName].filter((el) => !!el).join(' ')}
              avatar={item.photo}
              url={item.url}
              uniqueUrl={item.uniqueUrl}
            />
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

UserList.propTypes = {
  title: PropTypes.string,
  users: PropTypes.arrayOf(globalPropTypes.UserListItemPropsTypes).isRequired,
};

UserList.defaultProps = {
  title: '',
};

export default UserList;
