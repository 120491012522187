import { exact } from 'prop-types';

export const globalRoutes = (translation) => [
  {
    title: translation('navigation.primary.home'),
    path: '/',
    exact: true,
  },
  {
    title: translation('navigation.primary.about'),
    path: '/about',
    exact: false,
    withAuthModal: false,
  },
  {
    title: translation('navigation.primary.courses'),
    path: '/training',
    exact: false,
    withAuthModal: false,
  },
  {
    title: translation('navigation.primary.events'),
    path: '/events',
    exact: false,
    withAuthModal: false,
  },
  {
    title: translation('navigation.primary.ma'),
    path: '/ipma-associations',
    exact: false,
    withAuthModal: false,
  },
  {
    title: translation('navigation.primary.organisations'),
    path: '/organisations',
    exact: false,
    withAuthModal: false,
  },
  {
    title: translation('navigation.primary.yc'),
    path: '/ipma-young-crews',
    exact: false,
    withAuthModal: false,
  },
  {
    title: translation('navigation.primary.projects'),
    path: '/projects',
    exact: false,
    withAuthModal: false,
  },
];

export const userRoutes = (
  translation,
  userOrganizations,
  currentOrganization,
  maInitials,
  ycInitials,
  username,
) => {
  const actions = [];
  let organizations = [];
  if (userOrganizations !== undefined) {
    organizations = userOrganizations.map((item) => ({
      title: item.name,
      path: `/organisation/${item.id}`,
      icon: 'organisation',
      color: '#fff',
      bg: '#026d93',
    }));
  }

  const user = {
    title: translation('navigation.actions.user'),
    path: '/user',
    icon: 'user',
    color: '#fff',
    bg: '#f59c00',
  };

  const ma = {
    title: translation('navigation.actions.ma'),
    path: '/ma',
    icon: 'ma',
    color: '#fff',
    bg: '#552d6b',
  };

  const ipma = {
    title: translation('navigation.actions.ipma'),
    path: '/ipma',
    icon: 'ipma',
    color: '#fff',
    bg: '#005858',
  };
  const yc = {
    title: translation('navigation.actions.yc'),
    path: '/yc',
    icon: 'yc',
    color: '#fff',
    bg: '#8d7Ca2',
  };

  if (maInitials) {
    ma.title = maInitials;
  }
  if (ycInitials) {
    yc.title = ycInitials;
  }
  if (username) {
    user.title = username;
  }

  const userActions = [...organizations, ma, yc, ipma];
  const organizationActions = [user, ...organizations, ma, yc, ipma];
  const maActions = [user, ...organizations, yc, ipma];
  const ipmaActions = [user, ...organizations, ma, yc];
  const ycActions = [user, ...organizations, ma, ipma];

  actions.push(userActions);
  actions.push(organizationActions);
  actions.push(maActions);
  actions.push(ipmaActions);
  actions.push(ycActions);

  const recognisedActions = [];
  const engagedAction = [];
  const improveAction = [];
  const involvedAction = [];
  const aboutAction = [];
  recognisedActions.push({
    title: 'Get recognised with IPMA Certification',
    path: 'https://ipma.world/ipma-certification/',
    icon: '',
    color: 'black',
    bg: '',
  });
  recognisedActions.push({
    title: 'Register of Certified Professionals',
    path: 'https://ipma.world/ipma-certification/4lc/database-of-certified-professionals/',
    icon: '',
    color: 'black',
    bg: '',
  });
  recognisedActions.push({
    title: 'Get recognised with IPMA Global Awards',
    path: 'https://awards.ipma.world/',
    icon: '',
    color: 'black',
    bg: '',
  });
  recognisedActions.push({
    title: 'Become an IPMA Registered Partner',
    path: 'https://reg.ipma.world/reg-application/',
    icon: '',
    color: 'black',
    bg: '',
  });
  recognisedActions.push({
    title: 'Become IPMA Project Excellence Baseline Professional',
    path: 'https://ipma.world/ipma-peb-professionals/',
    icon: '',
    color: 'black',
    bg: '',
  });

  engagedAction.push({
    title: 'IPMA Young Crew',
    path: 'https://ipma.world/ipma-membership/',
    icon: '',
    color: 'black',
    bg: '',
  });

  engagedAction.push({
    title: 'IPMA Standards Development Programme',
    path: 'https://ipma.world/ipma-standards-development-programme/',
    icon: '',
    color: 'black',
    bg: '',
  });

  engagedAction.push({
    title: 'IPMA Kids',
    path: 'https://kids.ipma.world/',
    icon: '',
    color: 'black',
    bg: '',
  });

  engagedAction.push({
    title: 'IPMA Young Crew',
    path: 'https://ipma.world/ipma-young-crew/',
    icon: '',
    color: 'black',
    bg: '',
  });

  engagedAction.push({
    title: 'IPMA Publications',
    path: 'https://publications.ipma.world/',
    icon: '',
    color: 'black',
    bg: '',
  });

  engagedAction.push({
    title: 'IPMA Special Interest Groups',
    path: 'https://sig.ipma.world/',
    icon: '',
    color: 'black',
    bg: '',
  });

  engagedAction.push({
    title: 'IPMA for Researchers',
    path: 'https://ipma.world/ipma-research/',
    icon: '',
    color: 'black',
    bg: '',
  });

  engagedAction.push({
    title: 'Volunteering for IPMA',
    path: 'https://ipma.world/volunteering-for-ipma/',
    icon: '',
    color: 'black',
    bg: '',
  });

  improveAction.push({
    title: 'List of Registered Courses and programmes',
    path: 'https://reg.ipma.world/reg-partners/',
    icon: '',
    color: 'black',
    bg: '',
  });

  involvedAction.push({
    title: 'IPMA Events',
    path: 'https://ipma.world/events',
    icon: '',
    color: 'black',
    bg: '',
  });

  aboutAction.push({
    title: 'Vision and Mission',
    path: 'https://ipma.world/about-us/vision-and-mission/',
    icon: '',
    color: 'black',
    bg: '',
  });

  aboutAction.push({
    title: 'IPMA Governance',
    path: 'https://ipma.world/ipma-governance/',
    icon: '',
    color: 'black',
    bg: '',
  });

  aboutAction.push({
    title: ' Contact Us',
    path: 'https://ipma.world/about-us/contact-us/',
    icon: '',
    color: 'black',
    bg: '',
  });

  return {
    guest: {
      links: [],
      actions: [
        {
          title: translation('global.logIn'),
          path: '/auth/login',
          icon: 'lock',
          color: '#fff',
          bg: '#ec1d24',
        },
        {
          title: translation('global.register'),
          path: '/auth/register',
          icon: 'userCheck',
          color: '#fff',
          bg: '#ec1d24',
        },
      ],
      recognisedLinks: recognisedActions,
      engagedLinks: engagedAction,
      improveLinks: improveAction,
      involvedLinks: involvedAction,
      aboutLinks: aboutAction,
    },
    user: {
      links: [
        {
          title: translation('navigation.user.dashboard'),
          path: '/user',
          exact: true,
        },
        {
          title: translation('navigation.secondary.myProfile'),
          path: '/user/profile',
          exact: true,
        },
        {
          title: translation('navigation.secondary.courses'),
          path: '/user/courses',
          exact: false,
        },
        {
          title: translation('navigation.secondary.certification'),
          path: '/user/certification',
          exact: true,
        },
        {
          title: translation('navigation.secondary.myBadges'),
          path: '/user/my-badges',
          exact: true,
        },
        {
          title: translation('navigation.secondary.myCPD'),
          path: '/user/my-cpd',
          exact: true,
        },
        {
          title: translation('navigation.secondary.ma'),
          path: '/user/my-ma',
          exact: true,
        },
        {
          title: translation('navigation.secondary.yc'),
          path: '/user/my-yc',
          exact: true,
        },
        {
          title: translation('navigation.secondary.myLibrary'),
          path: '/user/my-books',
          exact: true,
        },
      ],
      actions: userActions,
      recognisedLinks: recognisedActions,
      engagedLinks: engagedAction,
      improveLinks: improveAction,
      involvedLinks: involvedAction,
      aboutLinks: aboutAction,
    },
    organisation: {
      links: [
        {
          title: translation('navigation.secondary.dashboard'),
          path: `/organisation/${currentOrganization}`,
          exact: true,
        },
        {
          title: translation('navigation.secondary.profile'),
          path: `/organisation/${currentOrganization}/profile`,
          exact: true,
        },
        {
          title: translation('navigation.secondary.people'),
          path: `/organisation/${currentOrganization}/people`,
          exact: true,
        },
        {
          title: translation('navigation.secondary.courses'),
          path: `/organisation/${currentOrganization}/courses`,
          exact: false,
        },
      ],
      actions: organizationActions,
      recognisedLinks: recognisedActions,
      engagedLinks: engagedAction,
      improveLinks: improveAction,
      involvedLinks: involvedAction,
      aboutLinks: aboutAction,
    },
    ma: {
      links: [
        {
          title: translation('navigation.secondary.dashboard'),
          path: '/ma',
          exact: true,
        },
        {
          title: translation('navigation.secondary.profile'),
          path: '/ma/profile',
          exact: false,
        },
        {
          title: translation('navigation.secondary.people'),
          path: '/ma/people',
          exact: false,
        },
        {
          title: translation('navigation.secondary.maCourses'),
          path: '/ma/courses',
          exact: false,
        },
        {
          title: translation('navigation.secondary.events'),
          path: '/ma/my-events',
          exact: false,
        },
        {
          title: translation('navigation.secondary.reports'),
          path: '/ma/reports',
          exact: false,
        },
        {
          title: translation('navigation.secondary.cpdRequests'),
          path: '/ma/cpd-requests',
          exact: false,
        },
      ],
      actions: maActions,
      recognisedLinks: recognisedActions,
      engagedLinks: engagedAction,
      improveLinks: improveAction,
      involvedLinks: involvedAction,
      aboutLinks: aboutAction,
    },
    ipma: {
      links: [
        {
          title: translation('navigation.secondary.dashboard'),
          path: '/ipma',
          exact: true,
        },
        {
          title: translation('navigation.secondary.profile'),
          path: '/ipma/profile',
          exact: false,
        },
        {
          title: translation('navigation.secondary.people'),
          path: '/ipma/people',
          exact: false,
        },
        {
          title: translation('navigation.secondary.events'),
          path: '/ipma/events',
          exact: false,
        },
        {
          title: translation('navigation.secondary.office'),
          path: '/ipma/reg-office-hub',
          exact: false,
        },
        {
          title: translation('navigation.secondary.awards'),
          path: '/ipma/awards',
          exact: false,
        },
        {
          title: translation('navigation.secondary.reports'),
          path: '/ipma/reports',
          exact: false,
        },
        {
          title: translation('navigation.secondary.projects'),
          path: '/ipma/projects',
          exact: false,
        },
      ],
      actions: ipmaActions,
      recognisedLinks: recognisedActions,
      engagedLinks: engagedAction,
      improveLinks: improveAction,
      involvedLinks: involvedAction,
      aboutLinks: aboutAction,
    },
    yc: {
      links: [
        {
          title: translation('navigation.secondary.dashboard'),
          path: '/yc',
          exact: true,
        },
        {
          title: translation('navigation.secondary.profile'),
          path: '/yc/profile',
          exact: false,
        },
        {
          title: translation('navigation.secondary.people'),
          path: '/yc/people',
          exact: false,
        },
        {
          title: translation('navigation.secondary.events'),
          path: '/yc/events',
          exact: false,
        },
        {
          title: translation('navigation.secondary.projects'),
          path: '/yc/projects',
          exact: false,
        },
      ],
      actions: ycActions,
      recognisedLinks: recognisedActions,
      engagedLinks: engagedAction,
      improveLinks: improveAction,
      involvedLinks: involvedAction,
      aboutLinks: aboutAction,
    },
  };
};
