import React from 'react';
import PropTypes from 'prop-types';
import { Count } from './FieldCounter.styled';

function FieldCounter({ children, limit, value }) {
  return (
    <div className="w-full">
      {children}
      <Count>{`${value ? value.length : 0}/${limit}`}</Count>
    </div>
  );
}

FieldCounter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

FieldCounter.defaultProps = {
  limit: 500,
};

export default FieldCounter;
