import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Pagination,
  FiltersControls,
  BoxList,
  Spinner,
  Alerts,
  ButtonAction,
} from 'components';
import {
  loadUserCoursesCreator,
  setUserCoursesPageCreator,
  setUserCoursesPerPageCreator,
  acceptCourseBadgeCreator,
  downloadCourseDiplomaCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { globalPropTypes, isLoading } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Completed = ({
  loading,
  loadList,
  claimBadge,
  downloadCourseDiploma,
  setPage,
  setPerPage,
  list,
  pendingBadges,
}) => {
  const { t } = useTranslation();

  const { items, page, pages, perPage } = list;

  useEffect(() => {
    setPage(1);
    loadList();
  }, [perPage]);

  const acceptBadge = (courseId) => {
    claimBadge(courseId);
    loadList();
  };

  const downloadDiploma = (courseId) => {
    downloadCourseDiploma(courseId);
  };

  const findCourse = (courseId) => pendingBadges.find((item) => item.course.id === courseId);

  const renderAction = (courseId) => {
    const course = findCourse(courseId);
    switch (course.badgeStatus) {
      case 'STATUS_CLAIMED':
        return (
          <>
            <ButtonAction
              as={Link}
              to="/user/my-badges"
              icon="check"
              title={t('global.badgeClaimed')}
            />
            <ButtonAction
              as={Link}
              icon="download"
              title={t('courses.downloadDiploma')}
              onClick={() => downloadDiploma({ id: courseId, name: course.course.name })}
            />
          </>
        );
      case 'STATUS_NEW':
        return (
          <ButtonAction
            icon="addCicrle"
            title={t('global.claimTheBadgeButton')}
            onClick={() => acceptBadge({ id: courseId, name: course.course.name })}
          />
        );
      case 'STATUS_REJECTED':
        return (
          <ButtonAction
            as={Link}
            to="/user/my-badges"
            icon="close"
            title={t('global.badgeRejected')}
          />
        );
      default:
        return null;
    }
  };

  return (
    <PageContent>
      <Container>
        {loading ? <Spinner /> : null}
        {!loading ? (
          <>
            <Alerts type="user" />
            <FiltersControls
              perPageSelected={perPage}
              perPageOnChange={(payload) => setPerPage(payload.value)}
            />
            <BoxList
              type="course"
              items={items}
              alert={t('global.noResultsCourses')}
              customRenderActions={({ item }) => (
                <>{findCourse(item.id) ? renderAction(item.id) : null}</>
              )}
            />
            <Pagination
              styled
              currentPage={page}
              totalPages={pages}
              onChange={(payload) => {
                setPage(payload);
                loadList();
              }}
            />
          </>
        ) : null}
      </Container>
    </PageContent>
  );
};

Completed.propTypes = {
  loading: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  claimBadge: PropTypes.func.isRequired,
  downloadCourseDiploma: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  list: globalPropTypes.ListPropsTypes.isRequired,
  pendingBadges: PropTypes.shape([]).isRequired,
};

Completed.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ loading, user }) => ({
  loading: isLoading(loading, 'LOAD_USER_COURSES_COMPLETED'),
  pendingBadges: user.pendingCourse.items,
  list: user.courses.completed,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: () => dispatch(loadUserCoursesCreator('completed')),
  claimBadge: (payload) => dispatch(acceptCourseBadgeCreator(payload)),
  downloadCourseDiploma: (payload) => dispatch(downloadCourseDiplomaCreator(payload)),
  setPage: (payload) => dispatch(setUserCoursesPageCreator('completed', payload)),
  setPerPage: (payload) => dispatch(setUserCoursesPerPageCreator('completed', payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Completed);
