import React, { forwardRef } from 'react';
import ReactDatepicker from 'react-datepicker';

import Input, { InputValue, DropdownIndicator } from './Datepicker.styled';

const DatepickerInput = ({ value, placeholder, onClick, disabled, small }, ref) => {
  return (
    <Input type="button" disabled={disabled} onClick={onClick} ref={ref} small={small}>
      <InputValue>{value || placeholder}</InputValue>
      <DropdownIndicator>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.4 18.7">
          <path d="M20.7 1.79h-1.37V.7a.7.7 0 00-.7-.7h-2.18a.7.7 0 00-.7.7v1.09h-3.26V.7a.7.7 0 00-.7-.7H9.61a.7.7 0 00-.7.7v1.09H5.65V.7A.7.7 0 005 0H2.77a.7.7 0 00-.7.7v1.09H.7a.7.7 0 00-.7.7V18a.7.7 0 00.7.7h20a.7.7 0 00.7-.7V2.49a.7.7 0 00-.7-.7zm-3.55-.39h.78v2.09h-.78zm-6.84 0h.78v2.13h-.78zm-6.84 0h.78v2.13h-.78zM20 17.29H1.4V3.19h.67v1a.7.7 0 00.7.7H5a.7.7 0 00.7-.7v-1h3.21v1a.7.7 0 00.7.7h2.18a.7.7 0 00.7-.7v-1h3.26v1a.7.7 0 00.7.7h2.18a.7.7 0 00.7-.7v-1H20zM11.57 6.75v1.44a.2.2 0 01-.2.2H9.92a.2.2 0 01-.2-.2V6.75a.2.2 0 01.2-.2h1.45a.2.2 0 01.2.2zm3.66 0v1.44a.2.2 0 01-.2.2h-1.45a.2.2 0 01-.2-.2V6.75a.2.2 0 01.2-.2H15a.2.2 0 01.23.2zm3.65 0v1.44a.2.2 0 01-.2.2h-1.44a.2.2 0 01-.2-.2V6.75a.2.2 0 01.2-.2h1.44a.2.2 0 01.2.2zM4.25 10.44v1.44a.2.2 0 01-.2.2H2.6a.2.2 0 01-.2-.2v-1.44a.2.2 0 01.2-.2h1.45a.2.2 0 01.2.2zm3.66 0v1.44a.2.2 0 01-.2.2H6.26a.2.2 0 01-.2-.2v-1.44a.2.2 0 01.2-.2h1.45a.2.2 0 01.2.2zm3.66 0v1.44a.2.2 0 01-.2.2H9.92a.2.2 0 01-.2-.2v-1.44a.2.2 0 01.2-.2h1.45a.2.2 0 01.2.2zm3.66 0v1.44a.2.2 0 01-.2.2h-1.45a.2.2 0 01-.2-.2v-1.44a.2.2 0 01.2-.2H15a.2.2 0 01.23.2zm3.65 0v1.44a.2.2 0 01-.2.2h-1.44a.2.2 0 01-.2-.2v-1.44a.2.2 0 01.2-.2h1.44a.2.2 0 01.2.2zM4.25 14.12v1.45a.2.2 0 01-.2.2H2.6a.2.2 0 01-.2-.2v-1.45a.2.2 0 01.2-.2h1.45a.2.2 0 01.2.2zm3.66 0v1.45a.2.2 0 01-.2.2H6.26a.2.2 0 01-.2-.2v-1.45a.2.2 0 01.2-.2h1.45a.2.2 0 01.2.2zm3.66 0v1.45a.2.2 0 01-.2.2H9.92a.2.2 0 01-.2-.2v-1.45a.2.2 0 01.2-.2h1.45a.2.2 0 01.2.2zm3.66 0v1.45a.2.2 0 01-.2.2h-1.45a.2.2 0 01-.2-.2v-1.45a.2.2 0 01.2-.2H15a.2.2 0 01.23.2z" />
        </svg>
      </DropdownIndicator>
    </Input>
  );
};

const EnhancedDatepickerInput = forwardRef(DatepickerInput);

function Datepicker(props) {
  return (
    <ReactDatepicker
      {...props}
      customInput={
        <EnhancedDatepickerInput
          placeholder={props.placeholderText}
          disabled={props.disabled}
          small={props.small}
        />
      }
    />
  );
}

export default Datepicker;
