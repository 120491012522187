export default {
  containerMaxWidths: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '100%',
    xl: '1200px',
  },

  navbarMaxWidths: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '100%',
    xl: '1720px',
  },

  gutter: '32',
};
