import React from 'react';
import PropTypes from 'prop-types';

import { List, ListItem, ListKey, ListValue } from './Details.styled';

function Details({ details }) {
  return (
    <List>
      {details.map(([key, value], index) => (
        <ListItem key={index}>
          <ListKey>{key}</ListKey>
          <ListValue>{value}</ListValue>
        </ListItem>
      ))}
    </List>
  );
}

Details.propTypes = {
  details: PropTypes.arrayOf(PropTypes.array),
};

Details.defaultProps = {
  details: [],
};

export default Details;
