import React from 'react';
import PropTypes from 'prop-types';
import { newLineToBr } from 'helpers';
import { Content } from './Description.styled';

function Description({ text }) {
  return <Content dangerouslySetInnerHTML={{ __html: newLineToBr(text) }} />;
}

Description.propTypes = {
  text: PropTypes.element.isRequired,
};

export default Description;
