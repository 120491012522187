import styled, { css } from 'styled-components';
import { p, pl } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';

export const Content = styled.div`
  ${({ theme }) => theme && css`
    ${breakpoint('md')`
      ${css`
        ${p(32)};
        
        border: 1px solid ${theme.colors.gray[200]};
      `}
    `}
  `}
`;

export const ContentPadding = styled.div`
  ${({ theme }) => theme && css`
    ${breakpoint('md')`
      ${css`
        ${pl(32)};
      `}
    `}
  `}
`;
