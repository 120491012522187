import moment from 'moment';

const getDate = (date) => {
  return moment(date)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('second', 0)
    .set('millisecond', 0);
};

export default (date, after) => {
  const a = getDate(date);
  const b = getDate(after);

  return a.isAfter(b);
};
