import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import * as listService from 'api/services/list';
import {
  getEntity,
  GLOBAL_COUNTRIES,
  GLOBAL_AVAILABLE_MA,
  GLOBAL_AVAILABLE_MA_ALL,
  GLOBAL_AVAILABLE_YC,
  GLOBAL_JOBS_TITLES,
  GLOBAL_PROFILE_PRIVACY,
  GLOBAL_GENDER,
  GLOBAL_LANGUAGES,
  GLOBAL_DELIVERY_MODES,
  GLOBAL_COURSE_TYPES,
  GLOBAL_INSTRUCTION_FORMATS,
  GLOBAL_PRACTICE_COMPETENCIES,
  GLOBAL_PEOPLE_COMPETENCIES,
  GLOBAL_PERSPECTIVE_COMPETENCIES,
  GLOBAL_CLOUD_TAGS,
  GLOBAL_MA_ROLES,
  GLOBAL_CURRENCIES,
  GLOBAL_EVENT_DELIVERY_MODES,
  GLOBAL_TIMEZONE,
  GLOBAL_CONTACT_SUBJECTS,
  GLOBAL_INDUSTRIES,
  GLOBAL_ORGANISATION_ROLES,
  GLOBAL_PROFESSIONAL_TITLES,
  GLOBAL_IPMA_ROLES,
  GLOBAL_YC_ROLES,
  GLOBAL_PROJECT_CAREERLEVEL,
  GLOBAL_PROJECT_SKILLS,
  GLOBAL_PROJECT_ROLES,
  GLOBAL_ABOUT_IPMA,
} from 'api/services/globals';

import {
  LOAD_GLOBAL_AVAILABLE_MA,
  LOAD_GLOBAL_AVAILABLE_YC,
  LOAD_GLOBAL_COUNTRIES,
  LOAD_GLOBAL_JOBS_TITLES,
  LOAD_GLOBAL_PROFILE_PRIVACY,
  LOAD_GLOBAL_GENDER,
  LOAD_GLOBAL_LANGUAGES,
  LOAD_GLOBAL_COURSE_DELIVERY_MODES,
  LOAD_GLOBAL_COURSE_COURSE_TYPES,
  LOAD_GLOBAL_COURSE_INSTRUCTION_FORMATS,
  LOAD_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES,
  LOAD_GLOBAL_COURSE_PEOPLE_COMPETENCIES,
  LOAD_GLOBAL_COURSE_PRACTICE_COMPETENCIES,
  LOAD_GLOBAL_COURSE_CLOUD_TAGS,
  LOAD_GLOBAL_COURSE_ATTRIBUTES,
  LOAD_GLOBAL_MA_ROLES,
  LOAD_GLOBAL_YC_ROLES,
  LOAD_GLOBAL_IPMA_ROLES,
  LOAD_GLOBAL_CURRENCIES,
  LOAD_GLOBAL_EVENT_DELIVERY_MODE,
  LOAD_GLOBAL_EVENT_ATTRIBUTES,
  LOAD_GLOBAL_TIMEZONES,
  LOAD_GLOBAL_CONTACT_SUBJECTS,
  LOAD_GLOBAL_INDUSTRIES,
  LOAD_GLOBAL_ORGANISATION_ROLES,
  LOAD_GLOBAL_PROFESSIONAL_TITLES,
  LOAD_GLOBAL_PROJECT_CAREERLEVEL,
  LOAD_GLOBAL_PROJECT_SKILLS,
  LOAD_GLOBAL_PROJECT_ROLES,
  LOAD_GLOBAL_ABOUT_IPMA,
  LOAD_GLOBAL_AVAILABLE_CB,
} from 'store/constants';
import {
  setGlobalAvailableMACreator,
  setGlobalAvailableYCCreator,
  setGlobalCountriesCreator,
  setGlobalJobsTitlesCreator,
  setGlobalProfilePrivacyCreator,
  setGlobalGenderCreator,
  setNewLoadingCreator,
  removeLoadingCreator,
  setGlobalLanguagesCreator,
  setGlobalCourseDeliveryModesCreator,
  setGlobalCourseCourseTypesCreator,
  setGlobalCourseInstructionFormatsCreator,
  setGlobalCoursePerspectiveCompetenciesCreator,
  setGlobalCoursePeopleCompetenciesCreator,
  setGlobalCoursePracticeCompetenciesCreator,
  setGlobalCourseCloudTagsCreator,
  loadGlobalLanguagesCreator,
  loadGlobalCourseDeliveryModesCreator,
  loadGlobalCourseCourseTypesCreator,
  loadGlobalCourseInstructionFormatsCreator,
  loadGlobalCoursePerspectiveCompetenciesCreator,
  loadGlobalCoursePeopleCompetenciesCreator,
  loadGlobalCoursePracticeCompetenciesCreator,
  loadGlobalCourseCloudTagsCreator,
  loadGlobalCountriesCreator,
  setGlobalMaRolesCreator,
  setGlobalCurrenciesCreator,
  setGlobalEventDeliveryModeCreator,
  loadGlobalCurrenciesCreator,
  loadGlobalEventDeliveryModeCreator,
  setGlobalTimezoneCreator,
  loadGlobalTimezoneCreator,
  loadUserOrganisationPeopleCreator,
  setGlobalContactSubjectsCreator,
  setGlobalIndustriesCreator,
  setGlobalOrganisationRolesCreator,
  setGlobalProfessionalTitlesCreator,
  setGlobalIpmaRolesCreator,
  setGlobalYcRolesCreator,
  setGlobalProjectCareerLevelCreator,
  setGlobalProjectSkillsCreator,
  setGlobalProjectRolesCreator,
  setGlobalAboutIpmaCreator,
  setGlobalAvailableCBCreator,
} from 'store/actionsCreators';
import { map, get } from 'lodash';
import { parsePlace, parseUserList, parseBoxesList } from 'helpers';

function* loadCountries() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_COUNTRIES);
    if (response.ok) {
      const countries = yield response.json();
      yield put(
        setGlobalCountriesCreator(
          countries.results.result.entity.map((item) => ({ label: item.name, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global countries error: ', error);
  }
}

function* loadAvailableMa({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(
      getEntity,
      payload && payload.showAll ? GLOBAL_AVAILABLE_MA_ALL : GLOBAL_AVAILABLE_MA,
    );
    if (response.ok) {
      const availableMa = yield response.json();
      yield put(
        setGlobalAvailableMACreator(
          availableMa.results.result.entity.map((item) => ({ label: item.name, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global available ma error: ', error);
  }
}

function* loadAvailableCb({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(
      getEntity,
      payload && payload.showAll ? GLOBAL_AVAILABLE_MA_ALL : GLOBAL_AVAILABLE_MA,
    );
    if (response.ok) {
      const availableMa = yield response.json();
      yield put(
        setGlobalAvailableCBCreator(
          availableMa.results.result.entity
            .filter((item) => item.cbName)
            .map((item) => ({ label: item.cbName, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global available ma error: ', error);
  }
}

function* loadAvailableYc() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_AVAILABLE_YC);
    if (response.ok) {
      const availableYc = yield response.json();
      yield put(
        setGlobalAvailableYCCreator(
          availableYc.results.result.entity.map((item) => ({ label: item.name, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global available yc error: ', error);
  }
}

function* loadProfessionalTitles() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_PROFESSIONAL_TITLES);

    if (response.ok) {
      const items = yield response.json();

      yield put(
        setGlobalProfessionalTitlesCreator(
          items.results.result.entity.map((item) => ({ label: item.name, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global proffesional titles error: ', error);
  }
}

function* loadJobsTitles() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_JOBS_TITLES);

    if (response.ok) {
      const jobsTitles = yield response.json();
      yield put(
        setGlobalJobsTitlesCreator(
          jobsTitles.results.result.entity.map((item) => ({ label: item.name, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global jobs titles error: ', error);
  }
}

function* loadProfilePrivacy() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_PROFILE_PRIVACY);

    if (response.ok) {
      const profilePrivacy = yield response.json();
      yield put(
        setGlobalProfilePrivacyCreator(
          profilePrivacy.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global profile privacy error: ', error);
  }
}

function* loadGender() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_GENDER);

    if (response.ok) {
      const gender = yield response.json();
      yield put(
        setGlobalGenderCreator(
          gender.results.result.entity.map((item) => ({
            label: item.name,
            value: item.name,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global profile privacy error: ', error);
  }
}

function* loadLanguages() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_LANGUAGES);

    if (response.ok) {
      const languages = yield response.json();
      yield put(
        setGlobalLanguagesCreator(
          languages.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global languages error: ', error);
  }
}

function* loadDeliveryModes() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_DELIVERY_MODES, true);

    if (response.ok) {
      const deliveryModes = yield response.json();
      yield put(
        setGlobalCourseDeliveryModesCreator(
          deliveryModes.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global course delivery modes error: ', error);
  }
}

function* loadCourseTypes() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_COURSE_TYPES, true);

    if (response.ok) {
      const courseTypes = yield response.json();
      yield put(
        setGlobalCourseCourseTypesCreator(
          courseTypes.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
            slug: item.slug,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global course course types error: ', error);
  }
}

function* loadInstructionFormats() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_INSTRUCTION_FORMATS, true);

    if (response.ok) {
      const instructionFormats = yield response.json();
      yield put(
        setGlobalCourseInstructionFormatsCreator(
          instructionFormats.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global course instruction formats error: ', error);
  }
}

function* loadPerspectiveCompetencies() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_PERSPECTIVE_COMPETENCIES, true);

    if (response.ok) {
      const perspectiveCompetencies = yield response.json();
      yield put(
        setGlobalCoursePerspectiveCompetenciesCreator(
          perspectiveCompetencies.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global course perpective competencies error: ', error);
  }
}

function* loadPeopleCompetencies() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_PEOPLE_COMPETENCIES, true);

    if (response.ok) {
      const peopleCompetencies = yield response.json();
      yield put(
        setGlobalCoursePeopleCompetenciesCreator(
          peopleCompetencies.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global course people competencies error: ', error);
  }
}

function* loadPracticeCompetencies() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_PRACTICE_COMPETENCIES, true);

    if (response.ok) {
      const practiceCompetencies = yield response.json();
      yield put(
        setGlobalCoursePracticeCompetenciesCreator(
          practiceCompetencies.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global course practice competencies error: ', error);
  }
}

function* loadCloudTags() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_CLOUD_TAGS, true);

    if (response.ok) {
      const cloudTags = yield response.json();
      yield put(
        setGlobalCourseCloudTagsCreator(
          cloudTags.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global course cloud tags error: ', error);
  }
}

function* loadCourseAttributes() {
  const [
    languages,
    countries,
    {
      deliveryModes,
      courseTypes,
      instructionFormats,
      perspectiveCompetencies,
      peopleCompetencies,
      practiceCompetencies,
      cloudTags,
    },
    timezones,
    expertKeys,
  ] = yield select(({ globals, organisation }) => [
    globals.languages,
    globals.countries,
    globals.course,
    globals.timezones,
    organisation.people.items,
  ]);

  const missingAttributesCreators = [];
  if (!languages.length) {
    missingAttributesCreators.push(put(loadGlobalLanguagesCreator()));
  }

  if (!deliveryModes.length) {
    missingAttributesCreators.push(put(loadGlobalCourseDeliveryModesCreator()));
  }

  if (!courseTypes.length) {
    missingAttributesCreators.push(put(loadGlobalCourseCourseTypesCreator()));
  }

  if (!instructionFormats.length) {
    missingAttributesCreators.push(put(loadGlobalCourseInstructionFormatsCreator()));
  }

  if (!perspectiveCompetencies.length) {
    missingAttributesCreators.push(put(loadGlobalCoursePerspectiveCompetenciesCreator()));
  }

  if (!peopleCompetencies.length) {
    missingAttributesCreators.push(put(loadGlobalCoursePeopleCompetenciesCreator()));
  }

  if (!practiceCompetencies.length) {
    missingAttributesCreators.push(put(loadGlobalCoursePracticeCompetenciesCreator()));
  }

  if (!cloudTags.length) {
    missingAttributesCreators.push(put(loadGlobalCourseCloudTagsCreator()));
  }

  if (!countries.length) {
    missingAttributesCreators.push(put(loadGlobalCountriesCreator()));
  }

  if (!timezones.length) {
    missingAttributesCreators.push(put(loadGlobalTimezoneCreator()));
  }

  if (!expertKeys.length) {
    const currentOrganisation = yield select(({ user }) => user.currentOrganisation);
    missingAttributesCreators.push(put(loadUserOrganisationPeopleCreator(currentOrganisation)));
  }

  yield all(missingAttributesCreators);
}

function* loadRoles() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_MA_ROLES, true);

    if (response.ok) {
      const roles = yield response.json();
      yield put(
        setGlobalMaRolesCreator(
          roles.results.result.map((item) => ({
            label: item.label,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global ma roles error: ', error);
  }
}

function* loadYcRoles() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_YC_ROLES, true);

    if (response.ok) {
      const roles = yield response.json();
      yield put(
        setGlobalYcRolesCreator(
          roles.results.result.map((item) => ({
            label: item.label,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global yc roles error: ', error);
  }
}

function* loadIpmaRoles() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_IPMA_ROLES, true);
    if (response.ok) {
      const roles = yield response.json();
      yield put(
        setGlobalIpmaRolesCreator(
          roles.results.result.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global ipma roles error: ', error);
  }
}

function* loadOrganisationRoles() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_ORGANISATION_ROLES, true);

    if (response.ok) {
      const roles = yield response.json();
      yield put(
        setGlobalOrganisationRolesCreator(
          roles.results.result.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global organisation roles error: ', error);
  }
}

function* loadCurrencies() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_CURRENCIES, false);

    if (response.ok) {
      const currencies = yield response.json();
      yield put(
        setGlobalCurrenciesCreator(
          currencies.results.result.entity.map((item) => ({
            label: `${item.code} ${item.sign ? `(${item.sign})` : ''}`,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global currencies error: ', error);
  }
}

function* loadEventDeliveryModes() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_EVENT_DELIVERY_MODES, true);

    if (response.ok) {
      const eventDeliveryModes = yield response.json();
      yield put(
        setGlobalEventDeliveryModeCreator(
          eventDeliveryModes.results.result.entity.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global event delivery modes error: ', error);
  }
}

function* loadTimezones() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_TIMEZONE, false);

    if (response.ok) {
      const timezones = yield response.json();
      yield put(
        setGlobalTimezoneCreator(
          timezones.results.result.entity.map((item) => ({
            label: item.gmt,
            value: item.id,
          })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global timezones error: ', error);
  }
}

function* loadEventAttributes() {
  const [
    currencies,
    languages,
    countries,
    { deliveryModes },
    cloudTags,
    timezones,
  ] = yield select(({ globals }) => [
    globals.currencies,
    globals.languages,
    globals.countries,
    globals.event,
    globals.course.cloudTags,
    globals.timezones,
  ]);

  const missingEventCreateGlobals = [];
  if (!currencies.length) {
    missingEventCreateGlobals.push(put(loadGlobalCurrenciesCreator()));
  }

  if (!languages.length) {
    missingEventCreateGlobals.push(put(loadGlobalLanguagesCreator()));
  }

  if (!countries.length) {
    missingEventCreateGlobals.push(put(loadGlobalCountriesCreator()));
  }

  if (!deliveryModes.length) {
    missingEventCreateGlobals.push(put(loadGlobalEventDeliveryModeCreator()));
  }

  if (!cloudTags.length) {
    missingEventCreateGlobals.push(put(loadGlobalCourseCloudTagsCreator()));
  }

  if (!timezones.length) {
    missingEventCreateGlobals.push(put(loadGlobalTimezoneCreator()));
  }

  yield all(missingEventCreateGlobals);
}

function* loadContactSubjects() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const [contactSubjects] = yield select(({ globals }) => [globals.contactSubjects]);

    if (!contactSubjects.length) {
      const response = yield call(getEntity, GLOBAL_CONTACT_SUBJECTS, false);

      if (response.ok) {
        const {
          results: {
            result: { entity: data },
          },
        } = yield response.json();

        const subjects =
          map(data, (n) => ({
            label: n.label,
            value: n.id,
          })) || [];

        yield put(setGlobalContactSubjectsCreator(subjects));
      }
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global contact subjects error: ', error);
  }
}

function* loadIndustries() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_INDUSTRIES);
    if (response.ok) {
      const data = yield response.json();
      yield put(
        setGlobalIndustriesCreator(
          data.results.result.entity.map((item) => ({ label: item.name, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global industries error: ', error);
  }
}

function* loadProjectCareerLevel() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_PROJECT_CAREERLEVEL);
    if (response.ok) {
      const careerLevels = yield response.json();
      yield put(
        setGlobalProjectCareerLevelCreator(
          careerLevels.results.result.map((item) => ({ label: item.value, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global projects career levels error: ', error);
  }
}
function* loadProjectSkills() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_PROJECT_SKILLS);
    if (response.ok) {
      const skills = yield response.json();
      yield put(
        setGlobalProjectSkillsCreator(
          skills.results.result.map((item) => ({ label: item.value, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global projects skills error: ', error);
  }
}
function* loadProjectRoles() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(getEntity, GLOBAL_PROJECT_ROLES);
    if (response.ok) {
      const roles = yield response.json();
      yield put(
        setGlobalProjectRolesCreator(
          roles.results.result.map((item) => ({ label: item.value, value: item.id })),
        ),
      );
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] global projects roles error: ', error);
  }
}
function* loadAboutIpma() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const eventsParams = {
      maxResult: 5,
      page: 1,
      'sort-by': 'dateFrom.desc',
      type: 'ipma',
    };

    const response = yield call(getEntity, GLOBAL_ABOUT_IPMA);
    const responseEvents = yield call(listService.getListEvents, eventsParams);

    if (response.ok && responseEvents.ok) {
      const aboutIpma = yield response.json();
      const {
        results: {
          result: { items },
        },
      } = yield responseEvents.json();

      const eventsList = parseBoxesList('events', items);
      const ipmaItem = aboutIpma.results.result.ipma[0];
      const ipma = {
        title: ipmaItem.name || '',
        description: ipmaItem.fullDescription || '',
        shortDescription: ipmaItem.fullDescription || '',
        photo: ipmaItem.photo || '',
        place: parsePlace(
          get(ipmaItem, 'address.city') || '',
          get(ipmaItem, 'address.country.name') || '',
        ),
        lat: get(ipmaItem, 'address.lat') || '',
        lng: get(ipmaItem, 'address.lang') || '',
        phone: ipmaItem.telephone || '',
        email: ipmaItem.email || '',
        website: ipmaItem.website || '',
        events: eventsList,
      };
      const people = parseUserList(aboutIpma.results.result.people);
      if (people && people.length) {
        people.sort((a, b) => b.position - a.position);
      }
      yield put(setGlobalAboutIpmaCreator({ ipma, people }));
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] about ipma error: ', error);
  }
}

export default function* watchGlobalsSaga() {
  yield takeLatest(LOAD_GLOBAL_COUNTRIES, loadCountries);
  yield takeLatest(LOAD_GLOBAL_AVAILABLE_MA, loadAvailableMa);
  yield takeLatest(LOAD_GLOBAL_AVAILABLE_CB, loadAvailableCb);
  yield takeLatest(LOAD_GLOBAL_AVAILABLE_YC, loadAvailableYc);
  yield takeLatest(LOAD_GLOBAL_PROFESSIONAL_TITLES, loadProfessionalTitles);
  yield takeLatest(LOAD_GLOBAL_JOBS_TITLES, loadJobsTitles);
  yield takeLatest(LOAD_GLOBAL_PROFILE_PRIVACY, loadProfilePrivacy);
  yield takeLatest(LOAD_GLOBAL_GENDER, loadGender);
  yield takeLatest(LOAD_GLOBAL_LANGUAGES, loadLanguages);

  yield takeLatest(LOAD_GLOBAL_COURSE_ATTRIBUTES, loadCourseAttributes);
  yield takeLatest(LOAD_GLOBAL_COURSE_DELIVERY_MODES, loadDeliveryModes);
  yield takeLatest(LOAD_GLOBAL_COURSE_COURSE_TYPES, loadCourseTypes);
  yield takeLatest(LOAD_GLOBAL_COURSE_INSTRUCTION_FORMATS, loadInstructionFormats);
  yield takeLatest(LOAD_GLOBAL_COURSE_PERSPECTIVE_COMPETENCIES, loadPerspectiveCompetencies);
  yield takeLatest(LOAD_GLOBAL_COURSE_PEOPLE_COMPETENCIES, loadPeopleCompetencies);
  yield takeLatest(LOAD_GLOBAL_COURSE_PRACTICE_COMPETENCIES, loadPracticeCompetencies);
  yield takeLatest(LOAD_GLOBAL_COURSE_CLOUD_TAGS, loadCloudTags);

  yield takeLatest(LOAD_GLOBAL_MA_ROLES, loadRoles);
  yield takeLatest(LOAD_GLOBAL_IPMA_ROLES, loadIpmaRoles);
  yield takeLatest(LOAD_GLOBAL_YC_ROLES, loadYcRoles);
  yield takeLatest(LOAD_GLOBAL_ORGANISATION_ROLES, loadOrganisationRoles);

  yield takeLatest(LOAD_GLOBAL_CURRENCIES, loadCurrencies);
  yield takeLatest(LOAD_GLOBAL_EVENT_DELIVERY_MODE, loadEventDeliveryModes);
  yield takeLatest(LOAD_GLOBAL_TIMEZONES, loadTimezones);

  yield takeLatest(LOAD_GLOBAL_EVENT_ATTRIBUTES, loadEventAttributes);
  yield takeLatest(LOAD_GLOBAL_CONTACT_SUBJECTS, loadContactSubjects);
  yield takeLatest(LOAD_GLOBAL_INDUSTRIES, loadIndustries);

  yield takeLatest(LOAD_GLOBAL_PROJECT_CAREERLEVEL, loadProjectCareerLevel);
  yield takeLatest(LOAD_GLOBAL_PROJECT_SKILLS, loadProjectSkills);
  yield takeLatest(LOAD_GLOBAL_PROJECT_ROLES, loadProjectRoles);
  yield takeLatest(LOAD_GLOBAL_ABOUT_IPMA, loadAboutIpma);
}
