import { useState } from 'react';

export default function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    (event, overwrite = false) => {
      if (overwrite) {
        setValues({
          ...event,
        });
      } else {
        setValues({
          ...fields,
          [event.target.id]: event.target.value,
        });
      }
    },
  ];
}
