import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSubRoutes, useExactRedirect, usePermissionTabs } from 'hooks';
import { Header, Tabs, Container, PageContent, Alerts, PrivateComponent } from 'components';
import { Margin } from 'styled-components-spacing';
import { globalPropTypes } from 'helpers';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.yc.events',
    url: '/yc/events',
  },
];

const initialTabs = [
  {
    title: 'navigation.tabs.yc.events.ongoing',
    path: '/yc/events/ongoing',
    key: 'ongoing',
  },
  {
    title: 'navigation.tabs.yc.events.completed',
    path: '/yc/events/completed',
    key: 'completed',
  },
  {
    title: 'navigation.tabs.yc.events.cancelled',
    path: '/yc/events/cancelled',
    key: 'cancelled',
  },
  {
    title: 'navigation.tabs.yc.events.duplicated',
    path: '/yc/events/duplicated',
    key: 'duplicate',
  },
];

const Events = ({ routes, bar, userPermissions }) => {
  const { t } = useTranslation();
  const [renderSubRoutes] = useSubRoutes(routes);
  const [tabs, firstTabPath] = usePermissionTabs(initialTabs, routes, userPermissions, bar);
  const [renderExactRedirect] = useExactRedirect('/yc/events', firstTabPath);

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.yc.events.title')}
        renderActions={({ Button: ButtonRender }) => (
          <PrivateComponent
            permissions={{
              roles: ['yc_events_create_event'],
            }}
          >
            <ButtonRender secondary as={Link} to="/yc/event/create">
              {t('page.yc.events.createButton')}
            </ButtonRender>
          </PrivateComponent>
        )}
      />
      <Tabs items={tabs} />
      {renderExactRedirect()}
      <PageContent>
        <Container>
          <Margin bottom={{ md: 48 }}>
            <Alerts type="yc" />
          </Margin>
          {renderSubRoutes()}
        </Container>
      </PageContent>
    </>
  );
};

Events.propTypes = {
  routes: PropTypes.arrayOf(globalPropTypes.RoutePropTypes),
  bar: PropTypes.shape({
    ongoing: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
    cancelled: PropTypes.number.isRequired,
    duplicate: PropTypes.number.isRequired,
  }).isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
};

Events.defaultProps = {
  routes: [],
};

const mapStateToProps = ({ yc, user }) => ({
  bar: yc.events.bar,
  userPermissions: user.userPermissions,
});

export default connect(mapStateToProps, null)(Events);
