import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { Row, Col, Heading, Field } from 'components';
import { Action } from 'components/Modal/Modal.styled';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { acceptIpmaRegOfficeCourseCreator } from 'store/actionsCreators';

import { AcceptContent, AcceptError, CpdRolesWrapper } from './AcceptModal.styled';

ReactModal.setAppElement('#modals');

const AcceptModal = ({ status, open, setOpen, acceptCourse }) => {
  const [error, setError] = useState('');
  const [cpd, setCpd] = useState('');
  const [cpdRoles, setCpdRoles] = useState(null);
  const [regNumber, setRegNumber] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setCpdRoles(
      open && open.cpdRoles
        ? open.cpdRoles.map((cpdItem) => ({
            role: cpdItem.role,
            points: cpdItem.points,
            id: cpdItem.id,
          }))
        : null,
    );
  }, [open]);

  useEffect(() => {
    setError('');
  }, [cpd, cpdRoles]);

  const onClose = () => {
    setOpen(0);
    setCpd('');
    setRegNumber('');
  };

  const submitAccept = () => {
    if (!cpd) {
      setError('Cpd field cannot be empty.');
    } else if (!parseInt(cpd, 10)) {
      setError('Cpd must be a valid number.');
    }

    if (error) return;

    acceptCourse({
      id: open.id,
      type: status,
      cpdNumber: parseInt(cpd, 10),
      regNumber: parseInt(regNumber, 10),
    });
    setOpen(0);
  };

  const handleCpdChange = (item, value) =>
    setCpdRoles(
      cpdRoles.reduce((prev, current) => {
        if (item.id !== current.id) return [...prev, current];

        let val = value;
        if (current.role && parseInt(value, 10) < current.role.minPoints) {
          val = `${current.role.minPoints}`;
        } else if (current.role && parseInt(value, 10) > current.role.maxPoints) {
          val = `${current.role.maxPoints}`;
        }

        return [
          ...prev,
          {
            ...current,
            points: val,
          },
        ];
      }, []),
    );

  const renderUploadModal = () => (
    <AcceptContent>
      <Heading as="h4" noLine>
        {t('page.ipma.office.acceptCourse')}
      </Heading>

      <Margin top="24">
        <Row halign="center">
          <Col>{t('page.ipma.office.acceptCourseDesc')}</Col>
        </Row>
        <Margin top="12">
          <CpdRolesWrapper halign="center">
            <Col size={{ xs: 3 / 4 }}>
              <Field id="cpd-number-field" label="CPD Number" required>
                <input
                  id="cpd-number-input"
                  key="cpd-input"
                  type="text"
                  placeholder="CPD"
                  value={cpd}
                  onChange={(e) => setCpd(e.target.value)}
                />
              </Field>
            </Col>
          </CpdRolesWrapper>
        </Margin>
        <Margin top="12">
          <Row halign="center">
            <Col size={{ xs: 3 / 4 }}>
              <Field id="reg-number-field" label="REG Number">
                <input
                  id="reg-number-input"
                  key="reg-input"
                  type="text"
                  placeholder="REG"
                  value={regNumber}
                  onChange={(e) => setRegNumber(e.target.value)}
                />
              </Field>
            </Col>
          </Row>
        </Margin>
      </Margin>
      <Margin top="16">
        <Row halign="center">
          <AcceptError>{error}</AcceptError>
        </Row>
        <Row halign="center">
          <Col size={{ xs: 1 / 2 }}>
            <Action onClick={submitAccept}>{t('global.upload')}</Action>
          </Col>
          <Col size={{ xs: 1 / 2 }}>
            <Action onClick={onClose} bordered>
              {t('global.closeButton')}
            </Action>
          </Col>
        </Row>
      </Margin>
    </AcceptContent>
  );
  return (
    <ReactModal
      style={{
        content: {
          maxWidth: 600,
          width: '100%',
          top: '50%',
          bottom: 'initial',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          border: 'none',
          boxShadow: '0px 0px 48px 0px rgba(61,62,64,0.24)',
        },
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          zIndex: 40,
        },
      }}
      isOpen={open}
      onRequestClose={onClose}
    >
      {renderUploadModal()}
    </ReactModal>
  );
};

AcceptModal.propTypes = {
  status: PropTypes.string.isRequired,
  open: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
  acceptCourse: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  acceptCourse: (payload) => dispatch(acceptIpmaRegOfficeCourseCreator(payload)),
});

export default connect(null, mapDispatchToProps)(AcceptModal);
