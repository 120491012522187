import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from 'api';
import { connect } from 'react-redux';
import { checkPermissions } from 'helpers';

const PrivateRoute = ({ render, permissions, userPermissions, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLogin()) return <Redirect to="/auth/login" />;

        if (!checkPermissions(permissions, userPermissions)) {
          return <Redirect to="/forbidden" />;
        }

        return render(props);
      }}
    />
  );
};

const mapStateToProps = ({ user }) => ({
  userPermissions: user.userPermissions,
});

export default connect(mapStateToProps, null)(PrivateRoute);
