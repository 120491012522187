import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, ButtonIcon, Search } from 'components';
import Brand from 'components/Navbar/Brand/Brand';
import Menu from 'components/Navbar/Mobile/Menu/Menu';
import { Margin } from 'styled-components-spacing';
import { globalPropTypes } from 'helpers';
import { isLogin } from 'api';
import { Overlay, Wrapper, Content, ButtonIconNotification, SearchMenu } from './Mobile.styled';

const Mobile = ({ appType, globalRoutes, userRoutes, openHelpCenter, openCisModal }) => {
  const [openOverlay, setOpenOverlay] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);

  const handleClickClose = () => {
    setOpenOverlay(false);
    setMenuOpen(false);
    setSearchMenuOpen(false);
  };

  const isLogged = isLogin() && appType !== 'guest';
  return (
    <>
      <Overlay open={openOverlay} onClick={handleClickClose} />
      <Wrapper>
        <Container>
          <Content>
            <Brand as={Link} to="/" />
            <div>
              <Margin right="8" inline>
                <ButtonIconNotification
                  forwardedAs={Link}
                  to="/notifications"
                  icon="notification"
                  notification="true"
                  color="#ec1d24"
                  bg="transparent"
                />
              </Margin>
              <Margin
                as={ButtonIcon}
                right="8"
                inline
                icon="search"
                color="#ec1d24"
                bg="transparent"
                onClick={() => {
                  setOpenOverlay(true);
                  setSearchMenuOpen(true);
                }}
              />
              <ButtonIcon
                icon="hamburger"
                rounded
                onClick={() => {
                  setOpenOverlay(true);
                  setMenuOpen(true);
                }}
              />
            </div>
          </Content>
        </Container>
        <Menu
          appType={appType}
          open={menuOpen}
          closeFn={handleClickClose}
          isLogged={isLogged}
          globalRoutes={globalRoutes}
          userRoutes={userRoutes}
          openHelpCenter={openHelpCenter}
          openCisModal={openCisModal}
        />
        <SearchMenu open={searchMenuOpen}>
          <Search closeFn={handleClickClose} small />
        </SearchMenu>
      </Wrapper>
    </>
  );
};

Mobile.propTypes = {
  globalRoutes: globalPropTypes.GlobalRoutesPropsTypes.isRequired,
  userRoutes: globalPropTypes.UserRoutesPropsTypes.isRequired,
  appType: PropTypes.string.isRequired,
  openHelpCenter: PropTypes.func.isRequired,
  openCisModal: PropTypes.func.isRequired,
};

export default Mobile;
