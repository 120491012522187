import { SET_LOGIN_USER_DATA } from '../constants';

const initialState = {
  login: {
    username: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_USER_DATA:
      return { ...state, login: action.payload };
    default:
      return state;
  }
};
