import styled, { css } from 'styled-components';
import { py, mt } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';
import headerImageMobile from 'assets/images/header_mobile.png';
import headerImageDesktop from 'assets/images/header_desktop.png';

export const Wrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${py(32)};
      
      position: relative;
      background-color: ${theme.colors.tertiary};
      
      > * {
        position: relative;
        z-index: 1;
      }
      
      ${breakpoint('md')`
        ${css`
          ${py(42)};
        `}
      `}
      
      ${({ big }) =>
        big &&
        css`
          &::before {
            content: '';
            width: 100%;
            height: 123px;
            background-image: url(${headerImageMobile});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right top;
            position: absolute;
            z-index: 0;
            left: 0;
            bottom: 0;
          }
      
          ${breakpoint('sm')`
            ${css`
              &::before {
                height: 340px;
                background-image: url(${headerImageDesktop});
              }
            `}
          `}
        ` 
      }
    `
  }
`;

export const Content = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      ${({ big }) =>
        big &&
        css`
          padding-bottom: 120px;

          ${breakpoint('md')`
            ${css`
              ${py(100)}
            `}
          `}
        `
    }
    `
  }
`;

export const Description = styled.p`
  ${({ theme }) =>
    theme &&
    css`
      ${mt(16)};
    
      color: #fff;
      font-size: ${theme.font.size.pixel('17')};
      font-weight: ${theme.font.weight.bold};
      line-height: 18px;
    `
  }
`;