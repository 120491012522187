import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const Flex = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
`;
