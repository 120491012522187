import styled from 'styled-components';
import { Table } from 'components';

export const RegRow = styled(Table.Row)`
  &:nth-of-type(even) {
    background-color: unset;
  }
  background-color: ${({ isOdd }) => (isOdd ? '#fff' : '#fbfbfb')} !important;

  > td {
    vertical-align: middle;
    font-size: 14px !important;
  }
  > td:first-child {
    font-size: 16px !important;
  }
`;

export const RegOrgRow = styled(RegRow)`
  border-top: 1px solid #d3d3d6;
  > td:first-child {
    font-size: 14px !important;
  }
`;
