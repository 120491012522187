import React from 'react';
import { Container, Heading, Cms, Row, Col } from 'components';
import { Margin } from 'styled-components-spacing';
import PropTypes from 'prop-types';
import { Wrapper } from './SeoSection.styled';

const SeoSection = ({ title, children }) => {
  if (!title && !children) return null;

  return (
    <Wrapper>
      <Container>
        {title && (
          <Margin bottom="32">
            <Heading as="h2" color="#fff" center>
              {title}
            </Heading>
          </Margin>
        )}

        {children && (
          <Row halign="center">
            <Col size={{ lg: 2 / 3 }}>
              <Cms>{children}</Cms>
            </Col>
          </Row>
        )}
      </Container>
    </Wrapper>
  );
};

SeoSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

SeoSection.defaultProps = {
  title: '',
  children: null,
};

export default SeoSection;
