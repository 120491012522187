import styled, { css }  from 'styled-components';

export const Wrapper = styled.span`
  ${({ theme }) =>
    theme &&
    css`
      display: inline-flex;
      align-items: center;
      font-size: ${theme.font.size.pixel('14')};
      text-transform: uppercase;
    `}
`;