import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import clearErrors from 'helpers/clearErrors';

import {
  Alerts,
  Button,
  Col,
  Container,
  Field,
  FileInput,
  Header,
  Heading,
  Row,
  PageContent,
  Select,
  Checkbox,
} from 'components';
import { Margin } from 'styled-components-spacing';
import {
  loadGlobalCountriesCreator,
  updateMaProfileCreator,
  setUpdateMaProfileErrorsCreator,
} from 'store/actionsCreators';
import { SameAsContact } from './Edit.styled';

import editReducer, {
  defaultState,
  setEditDataCreator,
  updateFieldCreator,
  setCbContactCreator,
  setYcContactCreator,
} from './reducer';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.editProfile',
    url: '',
  },
];

function Edit({ maProfile, countries, loadGlobalCountries, updateProfile, errors, removeError }) {
  const { t } = useTranslation();
  const [profile, dispatch] = useReducer(editReducer, defaultState);
  const [cbAsContact, setCbAsContact] = useState(false);
  const [ycAsContact, setYcAsContact] = useState(false);

  useEffect(() => {
    if (!countries.length) loadGlobalCountries();
  }, []);

  useEffect(() => {
    if (maProfile) {
      dispatch(setEditDataCreator(maProfile));
      setCbAsContact(maProfile.cbSameAsContactData);
      setYcAsContact(maProfile.ycSameAsContactData);
    }
  }, [maProfile]);

  const handleChangeNative = (name, errorName) => (e) => {
    dispatch(updateFieldCreator({ name, value: e.target.value }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeCustom = (name, errorName) => (value) => {
    dispatch(updateFieldCreator({ name, value }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeFileNative = (name, errorName) => (e) => {
    dispatch(
      updateFieldCreator({
        name,
        value: e.target.files && e.target.files.length ? e.target.files[0] : null,
      }),
    );
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleClearFileNative = (name) => () => {
    dispatch(
      updateFieldCreator({
        name,
        value: null,
      }),
    );
  };

  const handleChangeNestedNative = (group, name, errorName) => (e) => {
    dispatch(
      updateFieldCreator({ name: group, value: { ...profile[group], [name]: e.target.value } }),
    );
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeNestedCustom = (group, name, errorName) => (value) => {
    dispatch(updateFieldCreator({ name: group, value: { ...profile[group], [name]: value } }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleCbAsContact = (value) => {
    setCbAsContact(value);
    dispatch(
      setCbContactCreator({
        cbAddress: {
          line1: value ? profile.address.line1 : '',
          line2: value ? profile.address.line2 : '',
          postCode: value ? profile.address.postCode : '',
          city: value ? profile.address.city : '',
          country: value ? profile.address.country : null,
        },
        cbTelephone: value ? profile.telephone : '',
        cbEmail: value ? profile.email : '',
        cbWebsite: value ? profile.website : '',
      }),
    );
  };

  const handleYcAsContact = (value) => {
    setYcAsContact(value);
    dispatch(
      setYcContactCreator({
        ycAddress: {
          line1: value ? profile.address.line1 : '',
          line2: value ? profile.address.line2 : '',
          postCode: value ? profile.address.postCode : '',
          city: value ? profile.address.city : '',
          country: value ? profile.address.country : null,
        },
        ycTelephone: value ? profile.telephone : '',
        ycEmail: value ? profile.email : '',
        ycWebsite: value ? profile.website : '',
      }),
    );
  };

  const submit = () => {
    const params = new FormData();
    params.append('name', profile.name);
    params.append('initials', profile.initials);
    params.append('country', profile.country ? profile.country.value : '');
    params.append('imageFile', profile.imageFile);
    params.append('address[line1]', profile.address.line1);
    params.append('address[line2]', profile.address.line2);
    params.append('address[postCode]', profile.address.postCode);
    params.append('address[city]', profile.address.city);
    params.append('address[country]', profile.address.country ? profile.address.country.value : '');
    params.append('cbSameAsContactData', cbAsContact);
    params.append('cbAddress[line1]', profile.cbAddress.line1);
    params.append('cbAddress[line2]', profile.cbAddress.line2);
    params.append('cbAddress[postCode]', profile.cbAddress.postCode);
    params.append('cbAddress[city]', profile.cbAddress.city);
    params.append(
      'cbAddress[country]',
      profile.cbAddress.country ? profile.cbAddress.country.value : '',
    );
    params.append('ycSameAsContactData', ycAsContact);
    params.append('ycAddress[line1]', profile.ycAddress.line1);
    params.append('ycAddress[line2]', profile.ycAddress.line2);
    params.append('ycAddress[postCode]', profile.ycAddress.postCode);
    params.append('ycAddress[city]', profile.ycAddress.city);
    params.append(
      'ycAddress[country]',
      profile.ycAddress.country ? profile.ycAddress.country.value : '',
    );
    params.append('telephone', profile.telephone);
    params.append('email', profile.email);
    params.append('website', profile.website);
    params.append('cbName', profile.cbName ? profile.cbName : '');
    params.append('cbInitials', profile.cbInitials ? profile.cbInitials : '');
    params.append('cbTelephone', profile.cbTelephone ? profile.cbTelephone : '');
    params.append('cbEmail', profile.cbEmail ? profile.cbEmail : '');
    params.append('cbWebsite', profile.cbWebsite ? profile.cbWebsite : '');
    params.append('shortDescription', profile.shortDescription ? profile.shortDescription : '');
    params.append('fullDescription', profile.fullDescription ? profile.fullDescription : '');
    params.append(
      'individualMembershipsDescription',
      profile.individualMembershipsDescription ? profile.individualMembershipsDescription : '',
    );
    params.append(
      'individualMembershipsWebsite',
      profile.individualMembershipsWebsite ? profile.individualMembershipsWebsite : '',
    );
    params.append(
      'organisationMembershipsDescription',
      profile.organisationMembershipsDescription ? profile.organisationMembershipsDescription : '',
    );
    params.append(
      'organisationMembershipsWebsite',
      profile.organisationMembershipsWebsite ? profile.organisationMembershipsWebsite : '',
    );

    updateProfile(params);
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ma.editProfile.title')} />
      <Container>
        <PageContent>
          <Alerts type="ma" />

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ma.editProfile.basicInformation')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field label={t('page.ma.editProfile.nameLabel')} required error={errors.name}>
                  <input
                    type="text"
                    placeholder={t('page.ma.editProfile.namePlaceholder')}
                    value={profile.name}
                    onChange={handleChangeNative('name', 'name')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('page.ma.editProfile.initialsLabel')}
                  required
                  error={errors.initials}
                >
                  <input
                    type="text"
                    placeholder={t('page.ma.editProfile.initialsPlaceholder')}
                    value={profile.initials}
                    onChange={handleChangeNative('initials', 'initials')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('global.countryLabel')}
                  required
                  error={errors.country}
                  value={profile.country}
                  clear={() => handleChangeCustom('country', 'country')('')}
                >
                  <Select
                    value={profile.country}
                    options={countries}
                    placeholder={t('global.selectPlaceholder')}
                    onChange={handleChangeCustom('country', 'country')}
                  />
                </Field>
              </Margin>
            </Row>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field
                  label={t('global.logoLabel')}
                  required
                  error={errors.imageFile}
                  description={t('global.uploadImageInfo')}
                >
                  <FileInput
                    placeholder={t('global.uploadPlaceholder')}
                    fileName={profile.imageFile ? profile.imageFile.name : ''}
                    clear={handleClearFileNative('imageFile')}
                    onChange={handleChangeFileNative('imageFile', 'imageFile')}
                  />
                </Field>
              </Margin>
            </Row>
          </Margin>

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ma.editProfile.contactData')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('global.addressLineLabel1')}
                  required
                  error={errors['address.line1']}
                >
                  <input
                    type="text"
                    placeholder={t('global.addressLinePlaceholder1')}
                    value={profile.address.line1}
                    onChange={handleChangeNestedNative('address', 'line1', 'address.line1')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.addressLineLabel2')} error={errors['address.line2']}>
                  <input
                    type="text"
                    placeholder={t('global.addressLinePlaceholder2')}
                    value={profile.address.line2 || ''}
                    onChange={handleChangeNestedNative('address', 'line2', 'address.line2')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.cityLabel')} required error={errors['address.city']}>
                  <input
                    type="text"
                    placeholder={t('global.cityPlaceholder')}
                    value={profile.address.city}
                    onChange={handleChangeNestedNative('address', 'city', 'address.city')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('global.postCodeLabel')}
                  required
                  error={errors['address.postCode']}
                >
                  <input
                    type="text"
                    placeholder={t('global.postCodePlaceholder')}
                    value={profile.address.postCode}
                    onChange={handleChangeNestedNative('address', 'postCode', 'address.postCode')}
                  />
                </Field>
              </Margin>
            </Row>
            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field
                  label={t('global.countryLabel')}
                  required
                  error={errors['address.country']}
                  value={profile.address.country}
                  clear={() =>
                    handleChangeNestedCustom('address', 'country', 'address.country')('')
                  }
                >
                  <Select
                    value={profile.address.country}
                    options={countries}
                    placeholder={t('global.selectPlaceholder')}
                    onChange={handleChangeNestedCustom('address', 'country', 'address.country')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.phoneLabel')} required error={errors.telephone}>
                  <input
                    type="text"
                    placeholder={t('global.phonePlaceholder')}
                    value={profile.telephone}
                    onChange={handleChangeNative('telephone', 'telephone')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.emailLabel')} required error={errors.email}>
                  <input
                    type="text"
                    placeholder={t('global.emailPlaceholder')}
                    value={profile.email}
                    onChange={handleChangeNative('email', 'email')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('global.websiteLabel')} required error={errors.website}>
                  <input
                    type="text"
                    placeholder={t('global.websitePlaceholder')}
                    value={profile.website}
                    onChange={handleChangeNative('website', 'website')}
                  />
                </Field>
              </Margin>
            </Row>
          </Margin>

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ma.editProfile.contactDataCb')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field label={t('page.ma.editProfile.cbNameLabel')} error={errors.cbName}>
                  <input
                    type="text"
                    placeholder={t('page.ma.editProfile.cbNamePlaceholder')}
                    value={profile.cbName}
                    onChange={handleChangeNative('cbName', 'cbName')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Field label={t('page.ma.editProfile.initialsLabel')} error={errors.cbInitials}>
                  <input
                    type="text"
                    placeholder={t('page.ma.editProfile.cbInitialsPlaceholder')}
                    value={profile.cbInitials}
                    onChange={handleChangeNative('cbInitials', 'cbInitials')}
                  />
                </Field>
              </Margin>
            </Row>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                <Checkbox onChange={() => handleCbAsContact(!cbAsContact)} selected={cbAsContact}>
                  {t('page.ma.editProfile.sameAsContact')}
                </Checkbox>
              </Margin>
            </Row>
            <SameAsContact isSame={cbAsContact}>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field label={t('global.addressLineLabel1')} error={errors['cbAddress.line1']}>
                    <input
                      type="text"
                      placeholder={t('global.addressLinePlaceholder1')}
                      value={profile.cbAddress.line1}
                      onChange={handleChangeNestedNative('cbAddress', 'line1', 'cbAddress.line1')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field label={t('global.addressLineLabel2')} error={errors['cbAddress.line2']}>
                    <input
                      type="text"
                      placeholder={t('global.addressLinePlaceholder2')}
                      value={profile.cbAddress.line2 || ''}
                      onChange={handleChangeNestedNative('cbAddress', 'line2', 'cbAddress.line2')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field label={t('global.cityLabel')} error={errors['cbAddress.city']}>
                    <input
                      type="text"
                      placeholder={t('global.cityPlaceholder')}
                      value={profile.cbAddress.city}
                      onChange={handleChangeNestedNative('cbAddress', 'city', 'cbAddress.city')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field label={t('global.postCodeLabel')} error={errors['cbAddress.postCode']}>
                    <input
                      type="text"
                      placeholder={t('global.postCodePlaceholder')}
                      value={profile.cbAddress.postCode}
                      onChange={handleChangeNestedNative(
                        'cbAddress',
                        'postCode',
                        'cbAddress.postCode',
                      )}
                    />
                  </Field>
                </Margin>
              </Row>
              <Row>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field
                    label={t('global.countryLabel')}
                    error={errors['cbAddress.country']}
                    value={profile.cbAddress.country}
                    clear={() =>
                      handleChangeNestedCustom('cbAddress', 'country', 'cbAddress.country')('')
                    }
                  >
                    <Select
                      value={profile.cbAddress.country}
                      options={countries}
                      placeholder={t('global.selectPlaceholder')}
                      onChange={handleChangeNestedCustom(
                        'cbAddress',
                        'country',
                        'cbAddress.country',
                      )}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field label={t('global.phoneLabel')} error={errors.cbTelephone}>
                    <input
                      type="text"
                      placeholder={t('global.phonePlaceholder')}
                      value={profile.cbTelephone}
                      onChange={handleChangeNative('cbTelephone', 'cbTelephone')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field label={t('global.emailLabel')} error={errors.cbEmail}>
                    <input
                      type="text"
                      placeholder={t('global.emailPlaceholder')}
                      value={profile.cbEmail}
                      onChange={handleChangeNative('cbEmail', 'cbEmail')}
                    />
                  </Field>
                </Margin>
                <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
                  <Field label={t('global.websiteLabel')} error={errors.cbWebsite}>
                    <input
                      type="text"
                      placeholder={t('global.websitePlaceholder')}
                      value={profile.cbWebsite}
                      onChange={handleChangeNative('cbWebsite', 'cbWebsite')}
                    />
                  </Field>
                </Margin>
              </Row>
            </SameAsContact>
          </Margin>

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ma.editProfile.description')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field
                  label={t('page.ma.editProfile.descriptionShortLabel')}
                  required
                  error={errors.shortDescription}
                >
                  <textarea
                    placeholder={t('page.ma.editProfile.descriptionShortPlaceholder')}
                    value={profile.shortDescription}
                    onChange={handleChangeNative('shortDescription', 'shortDescription')}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field
                  label={t('page.ma.editProfile.descriptionFullLabel')}
                  error={errors.fullDescription}
                >
                  <textarea
                    placeholder={t('page.ma.editProfile.descriptionFullPlaceholder')}
                    value={profile.fullDescription}
                    onChange={handleChangeNative('fullDescription', 'fullDescription')}
                  />
                </Field>
              </Margin>
            </Row>
          </Margin>

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ma.editProfile.individualMemberships')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field
                  label={t('page.ma.editProfile.individualMembershipsLabel')}
                  error={errors.individualMembershipsDescription}
                >
                  <textarea
                    placeholder={t('page.ma.editProfile.individualMembershipsPlaceholder')}
                    value={profile.individualMembershipsDescription}
                    onChange={handleChangeNative(
                      'individualMembershipsDescription',
                      'individualMembershipsDescription',
                    )}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field label={t('global.websiteLabel')} error={errors.individualMembershipsWebsite}>
                  <input
                    type="text"
                    placeholder={t('global.websitePlaceholder')}
                    value={profile.individualMembershipsWebsite}
                    onChange={handleChangeNative('individualMembershipsWebsite', 'individualMembershipsWebsite')}
                  />
                </Field>
              </Margin>
            </Row>
          </Margin>

          <Margin bottom={{ xs: 24, lg: 38 }}>
            <Margin bottom="24">
              <Heading size="h5">{t('page.ma.editProfile.organisationMemberships')}</Heading>
            </Margin>

            <Row>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field
                  label={t('page.ma.editProfile.organisationMembershipsLabel')}
                  error={errors.organisationMembershipsDescription}
                >
                  <textarea
                    placeholder={t('page.ma.editProfile.organisationMembershipsPlaceholder')}
                    value={profile.organisationMembershipsDescription}
                    onChange={handleChangeNative(
                      'organisationMembershipsDescription',
                      'organisationMembershipsDescription',
                    )}
                  />
                </Field>
              </Margin>
              <Margin bottom="20" as={Col} size={{ lg: 1 / 2 }}>
                <Field label={t('global.websiteLabel')} error={errors.organisationMembershipsWebsite}>
                  <input
                    type="text"
                    placeholder={t('global.websitePlaceholder')}
                    value={profile.organisationMembershipsWebsite}
                    onChange={handleChangeNative('organisationMembershipsWebsite', 'organisationMembershipsWebsite')}
                  />
                </Field>
              </Margin>
            </Row>
          </Margin>

          <Row>
            <Col size={{ md: 1 / 2, xl: 1 / 4 }}>
              <Margin bottom={{ xs: 12, md: 0 }}>
                <Button type="button" onClick={submit}>
                  {t('global.saveButton')}
                </Button>
              </Margin>
            </Col>
          </Row>
        </PageContent>
      </Container>
    </>
  );
}

Edit.propTypes = {
  maProfile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    cbSameAsContactData: PropTypes.bool,
    ycSameAsContactData: PropTypes.bool,
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
    }),
    cbAddress: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      postCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
    }),
    cbName: PropTypes.string,
    cbInitials: PropTypes.string,
    cbTelephone: PropTypes.string,
    cbEmail: PropTypes.string,
    cbWebsite: PropTypes.string,
    ycName: PropTypes.string,
    ycInitials: PropTypes.string,
    ycTelephone: PropTypes.string,
    ycEmail: PropTypes.string,
    ycWebsite: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
    individualMembershipsDescription: PropTypes.string,
    individualMembershipsWebsite: PropTypes.string,
    organisationMembershipsDescription: PropTypes.string,
    organisationMembershipsWebsite: PropTypes.string,
  }),
  countries: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
  ).isRequired,
  updateProfile: PropTypes.func.isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    initials: PropTypes.string,
    country: PropTypes.string,
    imageFile: PropTypes.string,
    'address.line1': PropTypes.string,
    'address.line2': PropTypes.string,
    'address.postCode': PropTypes.string,
    'address.city': PropTypes.string,
    'address.country': PropTypes.string,
    'cbAddress.line1': PropTypes.string,
    'cbAddress.line2': PropTypes.string,
    'cbAddress.postCode': PropTypes.string,
    'cbAddress.city': PropTypes.string,
    'cbAddress.country': PropTypes.string,
    'ycAddress.line1': PropTypes.string,
    'ycAddress.line2': PropTypes.string,
    'ycAddress.postCode': PropTypes.string,
    'ycAddress.city': PropTypes.string,
    'ycAddress.country': PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    cbName: PropTypes.string,
    cbInitials: PropTypes.string,
    cbTelephone: PropTypes.string,
    cbEmail: PropTypes.string,
    cbWebsite: PropTypes.string,
    ycName: PropTypes.string,
    ycInitials: PropTypes.string,
    ycTelephone: PropTypes.string,
    ycEmail: PropTypes.string,
    ycWebsite: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
    individualMembershipsDescription: PropTypes.string,
    individualMembershipsWebsite: PropTypes.string,
    organisationMembershipsDescription: PropTypes.string,
    organisationMembershipsWebsite: PropTypes.string,
  }),
  removeError: PropTypes.func.isRequired,
};

Edit.defaultProps = {
  maProfile: null,
  errors: {},
};

const mapStateToProps = ({ ma, globals }) => ({
  maProfile: ma.profile.data,
  errors: ma.profile.errors,
  countries: globals.countries,
});
const mapDispatchToProps = (dispatch) => ({
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  updateProfile: (payload) => dispatch(updateMaProfileCreator(payload)),
  removeError: (payload) => dispatch(setUpdateMaProfileErrorsCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
