export const defaultState = {
  id: '',
  name: '',
  initials: '',
  country: null,
  imageFile: null,
  address: {
    line1: '',
    line2: '',
    postCode: '',
    city: '',
    country: null,
  },
  telephone: '',
  email: '',
  website: '',
  shortDescription: '',
  fullDescription: '',
  youngCrewMembershipsDescription: '',
  contactPerson: null,
  ycUsers: [],
};

const SET_EDIT_DATA = 'SET_EDIT_DATA';
const UPDATE_FIELD = 'UPDATE_FIELD';

export const setEditDataCreator = (payload) => ({ type: SET_EDIT_DATA, payload });
export const updateFieldCreator = (payload) => ({ type: UPDATE_FIELD, payload });

export default function (state = defaultState, action) {
  if (action.type === SET_EDIT_DATA) {
    const newState = {};
    Object.keys(action.payload).forEach((key) => {
      if (action.payload[key] === 'null') {
        newState[key] = '';
      } else {
        newState[key] = action.payload[key];
      }
    });
    return {
      ...state,
      ...newState,
      address: { ...state.address, ...newState.address },
    };
  }

  if (action.type === UPDATE_FIELD) {
    return { ...state, [action.payload.name]: action.payload.value };
  }

  return state;
}
