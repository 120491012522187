import React from 'react';
import { Link } from 'react-router-dom';
import { Margin } from 'styled-components-spacing';

import { Button, Col, Heading, Row } from 'components';
import { useTranslation } from 'react-i18next';

function SummaryStep() {
  const { t } = useTranslation();
  return (
    <div>
      <Row halign="justify" valign="center">
        <Col size={{ xs: 3 / 4 }}>
          <Heading as="h2">{t('forgot.resetPasswordHeading')}</Heading>
        </Col>
      </Row>
      <Row>
        <Col size={{ xs: 1 / 1 }}>
          <Margin top={{ xs: 24 }} bottom={{ xs: 24 }}>
            <p>
              <strong>{t('forgot.resetPasswordSuccess')}</strong>
            </p>
          </Margin>
        </Col>
      </Row>
      <Row>
        <Col size={{ xs: 1 / 1, md: 1 / 6 }}>
          <Button as={Link} primary to="/auth/login">
            {t('auth.logIn')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default SummaryStep;
