import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing';
import { connect } from 'react-redux';

import clearErrors from 'helpers/clearErrors';

import {
  Alerts,
  Button,
  Col,
  Container,
  Header,
  Heading,
  Field,
  Select,
  Row,
  FileInput,
  PageContent,
} from 'components';

import {
  loadGlobalCountriesCreator,
  createOrganisationCreator,
  loadUserOrganisationCreator,
  setCreateOrganisationErrorsCreator,
} from 'store/actionsCreators';
import { useTranslation } from 'react-i18next';
import { getFileValue } from 'helpers';
import { ProfileEditForm } from './CreateOrganisation.styled';
import reducer, { defaultState, updateFieldCreator, setEditDataCreator } from './reducer';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.user.createOrganisation',
    url: '',
  },
];

const Spacer = ({ double, children }) => (
  <Margin top={{ xs: 12 * (double ? 2 : 1) }}>{children}</Margin>
);

Spacer.propTypes = {
  double: PropTypes.bool,
  children: PropTypes.elementType.isRequired,
};

Spacer.defaultProps = { double: false };

function CreateOrganisation({
  countries,
  loadGlobalCountries,

  organisation,
  createOrganisation,
  loadUserOrganisation,

  errors,
  removeError,

  history,
}) {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, defaultState);
  useEffect(() => {
    if (!countries.length) {
      loadGlobalCountries();
    }
    removeError({});
  }, []);

  const handleChangeNative = (name, errorName) => (e) => {
    dispatch(updateFieldCreator({ name, value: e.target.value }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeCustom = (name, errorName) => (value) => {
    dispatch(updateFieldCreator({ name, value }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeFileNative = (name, errorName) => (e) => {
    dispatch(
      updateFieldCreator({
        name,
        value: e.target.files && e.target.files.length ? e.target.files[0] : null,
      }),
    );
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleClearFileNative = (name) => {
    dispatch(
      updateFieldCreator({
        name,
        value: null,
      }),
    );
  };

  const handleChangeNestedNative = (group, name, errorName) => (e) => {
    dispatch(
      updateFieldCreator({ name: group, value: { ...state[group], [name]: e.target.value } }),
    );
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const handleChangeNestedCustom = (group, name, errorName) => (value) => {
    dispatch(updateFieldCreator({ name: group, value: { ...state[group], [name]: value } }));
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const submit = () => {
    const params = new FormData();
    params.append('name', state.name);
    params.append('country', state.country && state.country.value);
    params.append('imageFile', getFileValue(state.imageFile));
    params.append('address[line1]', state.address.line1);
    params.append('address[line2]', state.address.line2);
    params.append('address[postCode]', state.address.postCode);
    params.append('address[city]', state.address.city);
    params.append('address[country]', state.address.country && state.address.country.value);
    params.append('telephone', state.telephone);
    params.append('email', state.email);
    params.append('website', state.website);
    params.append('shortDescription', state.shortDescription);
    params.append('fullDescription', state.fullDescription);

    createOrganisation(params, history);
  };

  return (
    <>
      <Header title={t('organisations.createHeading')} breadcrumbs={breadcrumbs} />
      <PageContent>
        <Container>
          <Alerts type="organisations" />
          <ProfileEditForm>
            <Heading size="h5">{t('forms.basicInformation')}</Heading>
            <Row>
              <Col size={{ xs: 1 / 1 }}>
                <Spacer>
                  <Field
                    label={t('forms.organisationNameLabel')}
                    id="organisation-name"
                    required
                    error={errors.name}
                  >
                    <input
                      id="organisation-name"
                      name="name"
                      placeholder={t('forms.organisationNamePlaceholder')}
                      value={state.name}
                      onChange={handleChangeNative('name', 'name')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.uploadLogoLabel')}
                    id="logo"
                    error={errors.imageFile}
                    description={t('forms.imageInputInfo')}
                  >
                    <FileInput
                      id="logo"
                      name="imageFile"
                      placeholder={t('forms.uploadLogoPlaceholder')}
                      fileName={state.imageFile ? state.imageFile.name : ''}
                      clear={() => handleClearFileNative('imageFile')}
                      onChange={handleChangeFileNative('imageFile', 'imageFile')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.countryLabel')}
                    id="country"
                    required
                    error={errors.country}
                  >
                    <Select
                      id="country"
                      name="country"
                      value={state.country}
                      onChange={handleChangeCustom('country', 'country')}
                      options={countries}
                      placeholder={t('forms.countryPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Spacer double>
              <Heading size="h5">{t('forms.contactData')}</Heading>
            </Spacer>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('forms.addressLine1Label')}
                    id="address-l1"
                    required
                    error={errors['address.line1']}
                  >
                    <input
                      id="address-l1"
                      name="address_l1"
                      placeholder={t('forms.addressLinePlaceholder')}
                      value={state.address.line1}
                      onChange={handleChangeNestedNative('address', 'line1', 'address.line1')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('forms.addressLine2Label')}
                    id="address-l2"
                    error={errors['address.line2']}
                  >
                    <input
                      id="address-l2"
                      name="address_l2"
                      placeholder={`${t('forms.addressLinePlaceholder')} (${t(
                        'forms.addressSecondLinePlaceholder',
                      )})`}
                      value={state.address.line2}
                      onChange={handleChangeNestedNative('address', 'line2', 'address.line2')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.postCodeLabel')}
                    id="postcode"
                    required
                    error={errors['address.postCode']}
                  >
                    <input
                      id="postcode"
                      name="postCode"
                      placeholder={t('forms.postCodePlaceholder')}
                      value={state.address.postCode}
                      onChange={handleChangeNestedNative('address', 'postCode', 'address.postCode')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.cityLabel')}
                    id="city"
                    required
                    error={errors['address.city']}
                  >
                    <input
                      id="city"
                      name="city"
                      placeholder={t('forms.cityPlaceholder')}
                      value={state.address.city}
                      onChange={handleChangeNestedNative('address', 'city', 'address.city')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('forms.countryLabel')}
                    id="address-country"
                    required
                    error={errors['address.country']}
                  >
                    <Select
                      id="address-country"
                      name="address_country"
                      value={state.address.country}
                      onChange={handleChangeNestedCustom('address', 'country', 'address.country')}
                      options={countries}
                      placeholder={t('forms.countryPlaceholder')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field
                    label={t('global.phoneLabel')}
                    id="telephone"
                    required
                    error={errors.telephone}
                  >
                    <input
                      id="telephone"
                      name="telephone"
                      placeholder={t('global.phonePlaceholder')}
                      value={state.telephone}
                      onChange={handleChangeNative('telephone', 'telephone')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field label={t('forms.emailLabel')} id="email" required error={errors.email}>
                    <input
                      id="email"
                      name="email"
                      placeholder={t('forms.emailPlaceholder')}
                      value={state.email}
                      onChange={handleChangeNative('email', 'email')}
                    />
                  </Field>
                </Spacer>
              </Col>
              <Col size={{ xs: 1 / 1, md: 1 / 3 }}>
                <Spacer>
                  <Field label={t('forms.websiteLabel')} id="website" error={errors.website}>
                    <input
                      id="website"
                      name="website"
                      placeholder={t('forms.websitePlaceholder.organisation')}
                      value={state.website}
                      onChange={handleChangeNative('website', 'website')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Spacer double>
              <Heading size="h5">{t('forms.description')}</Heading>
            </Spacer>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('forms.shortDescriptionLabel')}
                    id="short-desc"
                    error={errors.shortDescription}
                    required
                    countChars={state.shortDescription}
                    maxChars={255}
                  >
                    <textarea
                      id="short-desc"
                      name="shortDescription"
                      placeholder={t('forms.shortDescriptionPlaceholder.organisation')}
                      value={state.shortDescription}
                      onChange={handleChangeNative('shortDescription', 'shortDescription')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 1 / 2 }}>
                <Spacer>
                  <Field
                    label={t('forms.fullDescriptionLabel')}
                    id="full-desc"
                    error={errors.fullDescription}
                    required
                    countChars={state.fullDescription}
                    maxChars={1000}
                  >
                    <textarea
                      id="full-desc"
                      name="fullDescription"
                      placeholder={t('forms.fullDescriptionPlaceholder.organisation')}
                      value={state.fullDescription}
                      onChange={handleChangeNative('fullDescription', 'fullDescription')}
                    />
                  </Field>
                </Spacer>
              </Col>
            </Row>
            <Row>
              <Col size={{ xs: 1 / 1, md: 3 / 6, lg: 2 / 6 }}>
                <Spacer double>
                  <Button type="button" onClick={submit}>
                    {t('organisations.createHeading')}
                  </Button>
                </Spacer>
              </Col>
            </Row>
          </ProfileEditForm>
        </Container>
      </PageContent>
    </>
  );
}

CreateOrganisation.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  loadGlobalCountries: PropTypes.func.isRequired,

  createOrganisation: PropTypes.func.isRequired,
  loadUserOrganisation: PropTypes.func.isRequired,

  errors: PropTypes.shape({
    name: PropTypes.string,
    country: PropTypes.string,
    imageFile: PropTypes.string,
    'address.line1': PropTypes.string,
    'address.line2': PropTypes.string,
    'address.postCode': PropTypes.string,
    'address.city': PropTypes.string,
    'address.country': PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
  }),
  removeError: PropTypes.func.isRequired,

  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

CreateOrganisation.defaultProps = {
  errors: {},
};

const mapStateToProps = ({ user, globals, organisation }) => ({
  user: user.user,

  countries: globals.countries,

  organisation: organisation.profile,
  errors: organisation.errors,
  loading: organisation.loading,
});

const mapDispatchToProps = (dispatch) => ({
  createOrganisation: (payload, history, reset) =>
    dispatch(createOrganisationCreator(payload, history, reset)),
  loadGlobalCountries: () => dispatch(loadGlobalCountriesCreator()),
  loadUserOrganisation: () => dispatch(loadUserOrganisationCreator()),
  removeError: (payload) => dispatch(setCreateOrganisationErrorsCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganisation);
