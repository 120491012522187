import styled, { css } from 'styled-components';
import { mr } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';

export const IconContainer = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      display: inline-flex;
      justify-content: center;

      svg {
        ${mr(4)};

        width: 14px;
        height: 14px;
        fill: ${theme.colors.tertiary};
        pointer-events: none;

        ${({ icon }) =>
          icon === 'checkThin' &&
          css`
            fill: #227211;
          `}

        ${({ icon }) =>
          icon === 'close' &&
          css`
            fill: #7a142c;
          `}
      }

      ${breakpoint('lg')`
      ${css`
        display: flex;
      `}
    `}
    `}
`;
